import React, {useEffect, useRef, useState} from 'react';
//import styles from './_Index.style';
import {Popover, Button, message} from 'antd';
//import {Modal, Space} from 'ant-design/myantd';
import {Modal, Space} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import css from 'styled-jsx/css'

import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import {Field, Form} from "react-final-form";


import UseSessionManager from "../hooks/UseSessionManager";

import '../css/reset.css';
import './_Index.scss';
import UsePageDataManager from "../hooks/UsePageDataManager";
//import Box_Header from "./Box_Header";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";
import {useApp} from "../overmind";

var moment = require('moment');
var lodash = require('lodash');

type Props = {
    PrefixTarget?: string;
    RoutePathname?: string;
    MenuTemplateId?: string;
};
export default function Pg_JumpLogin(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Root';
    let RoutePathname = props.RoutePathname;
    let MenuTemplateId = props.MenuTemplateId;

    const navigate = useNavigate();
    let location = useLocation();
    let {category} = useParams();

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: true});

    //#region PageData

    //#region :PageInit

    //#region PageDataManager
    const {state:appState, actions} = useApp();
    const refDoLoadPageData = appState.refDoLoadPageData;
    let pageDataManager = UsePageDataManager({
        refPageLoad: refDoLoadPageData
    });
    //#endregion

    //#region SessionManager
    let sessionManager = UseSessionManager({
        pageDataManager: pageDataManager,
    });
    //#endregion

    //#region Boot
    useEffect(() => {
        const _loadPageData = async (): Promise<void> => {
            // VALIDATE INPUT
            //const id : any = this.props.match.params.id;
            // const id = 1;
            // if (!id) {
            //     return; //ERROR INPUTS
            // }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1: 1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/pg_jumplogin');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        pageDataManager.merge(payload);
                        pageDataManager.forceRender();
                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const _doLoadPageData = async (): Promise<void> => {


            pageDataManager.restore();
            if (!pageDataManager.isZoneExist(PrefixTarget)) {
                await sessionManager.initSessionAuthAsync();
                await _loadPageData();
            } else {
                forceRender()
            }
        }

        refDoLoadPageData.current = _doLoadPageData;
        _doLoadPageData();
    }, [location.pathname]);
    //#endregion
    //#endregion


    //region :PagePreRender (Ordered)


    //#endregion
    //#endregion


    function RenderBottom() {
        const handleValidate = values => {
            const errors: any = {};

            if (!values.p1) {
                errors.p1 = '*欄位為必填';
            }
            return errors;
        };
        const handleSubmit_clickLogin = (e: any, v: any) => {
            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">現在開始創立世界</div>,
                key: 'loading',
                duration: 0
            });

            //#region Handle SaveUserInfo
            {
                // VALIDATE INPUT
                //const id : any = this.props.match.params.id;
                const 醫院No = 1;
                if (!醫院No) {
                    throw 'error param';
                }

                const 登入名稱 = v.帳號名稱;
                if (!登入名稱) {
                    throw 'error param';
                }

                const 登入密碼 = 1;
                if (!登入密碼) {
                    throw 'error param';
                }

                // WAITING SCREEN
                loadingManager.changeLoadingCount(+1);
                //StaticEvent.LoadingEvent.next(true);

                // POST
                let postData = {
                    醫院No: 醫院No,
                    登入名稱: 登入名稱,
                    登入密碼: 登入密碼,
                    timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
                };
                const postUrl = ApiService.urlMapping('~/pet_api/1/pg_login/doctorlogin');
                const post$ = Static.apiService.post(postUrl, postData);
                post$.subscribe(async (res: any) => {
                        hide();


                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);

                        let data = res.data;
                        let error = data.error || null;
                        let result = error == null;

                        if (result) { // SUCEESS
                            let payload = data.payload;
                            _._debug.warn(payload);

                            message.success({
                                type: 'success',
                                className: 'myMessage',
                                content: <div className="myMessageBody">歡迎來到，恐龍世界</div>,
                                key: '',
                                duration: 3
                            });

                            // message.success({
                            //     type: 'success',
                            //     className: 'myMessage',
                            //     content: <div className="myMessageBody">門就開了</div>,
                            //     key: '',
                            //     duration: 3
                            // });


                            await sessionManager.setLoginData(
                                payload.SessionId,
                                payload.AuthData
                            );

                            navigate({
                                pathname: '/waitingroom',
                                //search: "?sort=name",
                                //hash: "#the-hash",
                                //state: {panelSelection: "f1"}
                            });
                        } else {
                            // AUTH USER EXPIRED
                            if (error.code == 2) {
                                //alert('帳號被登出，請重新登入');
                                //window.location.href = '/login';
                                //this.props.history.push('/login')
                                await sessionManager.setLogout();
                                pageDataManager.clear();
                                pageDataManager.reloadAsync({alwaysLoad: true});
                                alert('時間逾時已被登出');
                                return;
                            }

                            _._debug.error(error);
                            alert(error.message);
                        }
                    }, async (err) => {
                        hide();

                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);
                        _._debug.warn(err);
                        alert('網路連線有問題');
                    }
                );
                return lastValueFrom(
                    post$
                );
            }
            //#endregion
        }

        const handleSubmit_clickLogout = (e: any) => {
            if (e) {
                e.preventDefault();
            }

            // VALIDATE INPUT
            //const id : any = this.props.match.params.id;
            const id = 1;
            if (!id) {
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/pg_jumplogin/clicklogout');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        message.success({
                            type: 'success',
                            className: 'myMessage',
                            content: <div className="myMessageBody">登出完成</div>,
                            key: '',
                            duration: 3
                        });

                        await sessionManager.setLogout();
                        pageDataManager.clear();
                        pageDataManager.reloadAsync({alwaysLoad: true});

                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const identity = value => (value);

        return <React.Fragment>
            <Form
                onSubmit={(v) => handleSubmit_clickLogin(null, v)}
                initialValues={{
                    p1: '1',
                    p2: '',
                }}
                validate={handleValidate}
                render={({
                             submitError,
                             handleSubmit,
                             submitting,
                             pristine,
                             valid,
                             error,
                             values
                         }) => {
                    let it = values;

                    return <React.Fragment>
                        <div className="">
                            <form onSubmit={handleSubmit}>
                                <Field parse={identity} name="帳號名稱">
                                    {
                                        ({input, meta}) => (
                                            <div className="fr row">
                                                <label htmlFor={input.name}>帳號名稱: </label>
                                                <input
                                                    className="imgHeadTextBody" {...input}></input>
                                                <style jsx>{`
                                                                            input { background-color: #dcc;}
                                                                        `}</style>
                                                {meta.touched && meta.error &&
                                                <span
                                                    className="required">{meta.error}</span>}
                                            </div>
                                        )
                                    }
                                </Field>
                            </form>
                            <div>
                                <button className="button btn_active " onClick={handleSubmit}>前往恐龍世界測試
                                </button>
                            </div>

                            {sessionManager.TryForLoggedMember(() => {
                                return <div>
                                    <button className="button btn_active " onClick={handleSubmit_clickLogout}>登出
                                    </button>
                                </div>
                            })}
                        </div>
                    </React.Fragment>
                }}/>
        </React.Fragment>;
    }

    //#region Render
    return (<div className="bg">
        <style jsx>{`
.bg {
  width: 100%;
  height: 100%;
}

.bodyBody {
    width: 100%;
    height: 100%;
}
    `}</style>
        <style jsx global>{`
@import './src/pages/RWD.scss';
            
.ant-popover-inner-content {
  padding: 0;
}

.myClass .ant-modal-body {
  padding: 0;
}

.myClass .ant-modal-confirm-btns {
  display:none;
}

.myClass .ant-modal-confirm-body .anticon {
  display: none;
}

.myClass .ant-modal-confirm-content  {
  margin: 0;
  padding: 0;
}

.myClass {
  padding: 0;
  
    @include media("<tablet") { /* is phone */
        top: 0; 
        margin: 0;
        max-width: 100%;
    }

    @include media(">=tablet", "<desktop") { /* is tablet */
        max-width: 600px;
    }

    @include media(">=desktop", "<xdesktop") { /* is desktop */
        max-width: 600px;
    }

    @include media(">=xdesktop") { /* is xdesktop */
        max-width: 600px;
    }
}
    `}</style>

        <div className="bodyBody">
            <React.Fragment>
                <div className="jumplogin">
                    <div className="empty">
                        <div className="emptyBody">
                            <div className="emptyIcon"></div>
                            <div className="emptyText">
                                <div className="bold">登入測試嗎?...</div>
                                <div>請輸入機碼讓我為您開門吧～</div>

                            </div>
                            <div className="emptyNext hide">
                                <button className="button more" onClick={(e) => navigate({
                                    pathname: "/wall/newest",
                                    //search: "?sort=name",
                                    //hash: "#the-hash",
                                    //state: {panelSelection: "f1"}
                                })}>前往世界測試
                                </button>
                            </div>

                            <div className="emptyNext2">
                                <RenderBottom/>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </div>
    </div>);
    //#endregion
}

Pg_JumpLogin.displayName = 'Pg_JumpLogin';
