import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {
    Form as NForm, Popover, Button, Modal, message, Upload,
    Input, Select, Space, Tabs, Tooltip, Steps, DatePicker, TimePicker, Spin
} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';


import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";

import {
    LoadingOutlined,
    LockOutlined,
    PlusOutlined,
    UploadOutlined,
    CloseOutlined,

} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";


import Partial_Modal_Photogallery from "./Partial_Modal_Photogallery";
import Partial_Appointment__Modal_AddPet from "./Partial_Appointment__Modal_AddPet";
import UseCompState from "../hooks/UseCompState";
import Ln_AvatorImgHref from "./Ln_AvatorImgHref";
import Ln_PictureImgHref from "./Ln_PictureImgHref";


var moment = require('moment');
const {Option} = Select;
const {Search} = Input;
const onSearch = value => console.log(value);
const {TabPane} = Tabs;
const {Step} = Steps;
const {RangePicker} = DatePicker;

const dateFormat = 'YYYY/MM/DD';
const format = 'HH:mm';

const {TextArea} = Input;

const props = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
        authorization: 'authorization-text',
    },
    onChange(info) {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
};

type ViewModelProps = {
    refModal: any,

    Kind: 'Update' | 'Create',

    Kind_Update?: {
        掛號No: any,
    }
    Kind_Create?: {
        寵物No: any,
        飼主No: any,
    }
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any,

    viewModel: ViewModelProps,
};
export default function Partial_Appointment__Modal_EditOrAddRegister(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_Appointment__Modal_EditOrAddRegister';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    const viewModel = props.viewModel;
    const isNew = viewModel.Kind === 'Create';

    const layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 16},
    };


    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    //#region Loading Control
    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});
    //#endregion

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;
    }
    //#endregion

    //#region Boot
    let _refDoLoadPartialData: any = useRef<{ alwaysLoad?: boolean }>(null);
    useEffect(() => {
        const _loadPartialData = async (): Promise<void> => {
            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">讀取中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            const p1: any = viewModel.Kind_Update?.掛號No || null;

            // if (!p1) {
            //     _._debug.error('p1');
            //     return; //ERROR INPUTS
            // }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/partial_appointment/modal_addregister');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        let changeShard = {
                            [PrefixTarget]: {
                                ...payload,
                            }
                        }
                        pageDataManager.merge(changeShard);
                        pageDataManager.apply();
                        forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPartialData = async ({alwaysLoad = false}): Promise<void> => {

            // do not dynamically load data if condition fits.
            if (!alwaysLoad) {
                if (pageDataManager.isZoneExist(PrefixTarget)) {
                    return;
                }
            }

            await _loadPartialData();
        }

        _refDoLoadPartialData.current = doLoadPartialData;
        _refDoLoadPartialData.current({});
    }, [pageDataManager.get(PrefixTarget)]);
    //#endregion

    //#region No Cache
    useEffect(() => {
        return ()=> {
            pageDataManager.emptyZone(PrefixTarget);
            pageDataManager.apply();
        }
    }, []);
    //#endregion

    //#region Set Body
    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
        }

        if (viewModel.refModal || viewModel.refModal.current) {
            viewModel.refModal.current.destroy();
        }
    };

    const Body = () => {
        // if (isNew) {
        //     pageDataManager.emptyZone(PrefixTarget);
        //     pageDataManager.merge({
        //         [PrefixTarget]: {
        //         }
        //     });
        //     pageDataManager.apply();
        // }

        const RegionEmptyShow = false;
        const RegionLoadingShow = true;
        let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;

        if (!PassPrefixTargetLoaded) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <Spin indicator={
                        <LoadingOutlined style={{fontSize: 24}} spin/>
                    }/>
                </React.Fragment>;
            } else {
                return null;
            }
        }


        const Item_掛號 = pageDataManager.get(`${PrefixTarget}.Item_掛號`);
        const List_醫生 = pageDataManager.get(`${PrefixTarget}.List_醫生`);

        let docOptions: any = [];
        {
            if (List_醫生) {
                docOptions = List_醫生.map((r) => {
                    return {
                        key: r.醫生No,
                        value: r.醫生No,
                        label: `${r.名稱} 醫師`,
                    }
                });

                // docOptions.unshift({
                //     key: '0',
                //     value: '',
                //     label: '請選擇',
                // })

                // docOptions.unshift({
                //     key: '1',
                //     value: '',
                //     label: '不指定',
                // })

                // docOptions.unshift({
                //     key: '0',
                //     value: '請選擇',
                //     label: '請選擇',
                // })
            }
        }

        //#region :Comp nsUploader
        if (!state.nsUploader) {
            const obj: any = state.nsUploader = {};
            obj.getBase64 = (file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = error => reject(error);
                });
            }

            obj.uploadButton = ({text}) => (
                <div>
                    {false ? <LoadingOutlined/> : <PlusOutlined/>}
                    <div style={{marginTop: 8}}>{text}</div>
                </div>
            );

            // obj.handleCancel = () => {
            //     zoneState.state = {
            //         ...zoneState.state,
            //         ...{previewVisible: false}
            //     };
            // }

            obj.beforeUpload = (file) => {
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJpgOrPng) {
                    message.error('You can only upload JPG/PNG file!');
                }
                const isLt2M = file.size / 1024 / 1024 < 20;
                if (!isLt2M) {
                    message.error('Image must smaller than 20MB!');
                }
                return isJpgOrPng && isLt2M;
            }

            // obj.previewFile= (file) => {
            //     debugger;
            // };

            obj.handlePreview = async file => {
                let urls = obj.compState.files.filter(r => r.id != file.id).map(r => ApiService.urlImageMapping("~/" + r.url));
                urls.unshift(ApiService.urlImageMapping("~/" + file.url));
                Static.App.setImagesAndOpen(urls);

                //
                // if (!file.url && !file.preview) {
                //     file.preview = await getBase64(file.originFileObj);
                // }
                //
                // obj.compState = {
                //     ...obj.compState,
                //     ...{
                //         previewImage: file.url || file.preview,
                //         previewVisible: true,
                //         previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
                //     }
                // };

            };


            obj.previewFile = async (file) => {
                let src = file.url;
                if (!src) {
                    src = await new Promise(resolve => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file.originFileObj);
                        reader.onload = () => resolve(reader.result);
                    });
                }
                const image = new Image();
                image.src = src;
                const imgWindow = window.open(src);
                if (imgWindow) {
                    imgWindow.document.write(image.outerHTML);
                } else {
                    window.location.href = src;
                }
            };

            obj.handleChange = ({fileList, wrapperOnChange, ...rest}) => {
                obj.compState = {
                    ...obj.compState,
                    ...{
                        fileList
                    }
                };

                //debugger;

                try {
                    if (rest.file.status === 'done') {
                        if (rest.file.response.error) {
                            throw rest.file.response.error;
                        }

                        let serverFileInfo = rest.file.response.files[0];
                        let antFileInfo = {
                            url: serverFileInfo.url,
                            filename: serverFileInfo.name,
                            size: serverFileInfo.size,
                            id: serverFileInfo.id,
                            type: serverFileInfo.type,
                            thumbUrl: serverFileInfo.url,
                        };
                        obj.compState.files = [...obj.compState.files, antFileInfo];
                        //Object.assign(fileList.filter(r=>r.uid == rest.file.uid)[0], antFileInfo);
                        //forceRender();
                        fileList = fileList.map(file => {
                            if (file.response) {
                                // Component will show file.url as link
                                file.url = file.response?.files?.[0]?.url;
                            }
                            return file;
                        });

                        wrapperOnChange(obj.compState.files.map(r => r.id).join(','));
                    }

                    if (rest.file.status === 'removed') {
                        obj.compState.files = obj.compState.files.filter(item => item.id != rest.file.id)
                        wrapperOnChange(obj.compState.files.map(r => r.id).join(','));
                    }
                } catch (error) {
                    // AUTH USER EXPIRED T:2
                    if (error.code == 2) {
                        (async () => {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                        })();
                    } else {
                        alert(error.message);
                    }
                }

                forceRender();
            }

            obj.addData = (file) => {
                return {a: '123'};
            };


            let compState: any = obj.compState = {};
            compState = Object.assign(compState, {
                files: [],
                previewVisible: false,
                previewImage: '',
                previewTitle: '',
                hasSetFileList: false,
                fileList: [
                    // {
                    //     uid: '-1',
                    //     name: 'image.png',
                    //     status: 'done',
                    //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                    // },
                    // {
                    //     uid: '-2',
                    //     name: 'image.png',
                    //     status: 'done',
                    //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                    // },
                    // {
                    //     uid: '-3',
                    //     name: 'image.png',
                    //     status: 'done',
                    //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                    // },
                    // {
                    //     uid: '-4',
                    //     name: 'image.png',
                    //     status: 'done',
                    //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                    // },
                    // {
                    //     uid: '-xxx',
                    //     percent: 50,
                    //     name: 'image.png',
                    //     status: 'uploading',
                    //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                    // },
                    // {
                    //     uid: '-5',
                    //     name: 'image.png',
                    //     status: 'error',
                    // },
                ] as any,
            });


            // if (viewModel.carryData) {
            //     if (viewModel.carryData.type == 'pictures') {
            //         //0: {url: "/FileTransferHandler.ashx?f=4915a7dd3f1c420b9175f38c2a76e4ac.jpg", filename: "4915a7dd3f1c420b9175f38c2a76e4ac.jpg", size: 159626, id: "/Upload/4915a7dd3f1c420b9175f38c2a76e4ac.jpg", type: "image/jpeg"}
            //
            //
            //         let count = 0;
            //         let files: any = [];
            //         state.files = viewModel.carryData.data.pictures;
            //         zoneState.state.fileList = state.files.map((it) => {
            //             count++;
            //
            //             // minimum requirement for antFileInfos [
            //             //     {
            //             //         uid: '-1',
            //             //         name: 'image.png',
            //             //         status: 'done',
            //             //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
            //             //     },
            //             // ];
            //
            //             let antFileInfo = {
            //                 uid: it.id,
            //                 status: 'done',
            //
            //                 url: it.url,
            //                 name: it.filename,
            //                 size: it.size,
            //                 id: it.id,
            //                 type: it.type,
            //             };
            //             return antFileInfo;
            //         });
            //     }
            // }
        }
        //#endregion

        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            if (!values.醫生No_指定醫師) {
                errors.醫生No_指定醫師 = '↑ 欄位為必填';
            } else {
            }
            //
            // if (!values.password) {
            //     errors.password = '↑ 欄位為必填';
            // } else {
            // }

            return errors;
        };


        //#endregion

        const handleSubmit_register = (e: any, v: any) => {
            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            //#region Handle SaveUserInfo
            {
                // VALIDATE INPUT
                const p1 = v;
                if (!p1) {
                    _._debug.error('p1');
                    return; //ERROR INPUTS
                }


                // WAITING SCREEN
                loadingManager.changeLoadingCount(+1);
                //StaticEvent.LoadingEvent.next(true);

                // POST
                let postData = {
                    p1,
                    timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
                };
                const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_modal_register');
                const post$ = Static.apiService.post(postUrl, postData);
                post$.subscribe(async (res: any) => {
                        hide();

                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);

                        let data = res.data;
                        let error = data.error || null;
                        let result = error == null;

                        if (result) { // SUCEESS
                            let payload = data.payload;
                            _._debug.warn(payload);


                            let hide = message.loading({
                                type: 'loading',
                                className: 'myMessage',
                                content: <div className="myMessageBody">修改完成</div>,
                                key: 'loading',
                                duration: 5
                            });

                            pageDataManager.emptyZone(PrefixTarget);
                            pageDataManager.apply();

                            handleClose(null);
                            hide();

                        } else {
                            // AUTH USER EXPIRED
                            if (error.code == 2) {
                                //alert('帳號被登出，請重新登入');
                                //window.location.href = '/login';
                                //this.props.history.push('/login')
                                await sessionManager.setLogout();
                                pageDataManager.clear();
                                pageDataManager.reloadAsync({alwaysLoad: true});
                                alert('時間逾時已被登出');
                                return;
                            }

                            _._debug.error(error);
                            alert(error.message);
                        }
                    }, async (err) => {
                        hide();

                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);
                        _._debug.warn(err);
                        alert('網路連線有問題');
                    }
                );
                return lastValueFrom(
                    post$
                );
            }
            //#endregion
        }


        return (
            <div className="model_wrapper">
                <div className="modal_title">
                    {isNew ?
                        <div className="title_info">
                            <h2>新建掛號</h2>
                        </div> :
                        <div className="title_info">
                            <h2>編輯掛號</h2>
                        </div>
                    }

                    <div className="info_bar"></div>
                    <div className="control_bar">
                        <Tooltip placement="top" title={'關閉'}>
                            <Button type="text" icon={<CloseOutlined/>} onClick={handleClose}/>
                        </Tooltip>
                    </div>
                </div>
                <div className="modal_content">
                    <div className="content_box">
                        <Form
                            onSubmit={(v) => handleSubmit_register(null, v)}
                            initialValues={{
                                //...viewModel.carryData ?? {},
                                ...Item_掛號,
                                ...{
                                    // 掛號Id: state.carryData.掛號Id,
                                    // 掛號No: state.carryData.掛號No,
                                    //
                                    ...(isNew && (viewModel.Kind_Create ?? {}))
                                }
                            }}
                            validate={handleValidate}
                            render={({
                                         submitError,
                                         handleSubmit,
                                         submitting,
                                         pristine,
                                         valid,
                                         error,
                                         values
                                     }) => {

                                // @ts-ignore
                                return <React.Fragment>

                                    <div className="form_body col_3">
                                        <form
                                            onSubmit={handleSubmit}
                                            {...layout}>
                                            <div className="col_group">
                                                <div className="col flexw">
                                                    <Field parse={_.bs.identity} name="醫生No_指定醫師">
                                                        {
                                                            ({input, meta}) => (
                                                                <React.Fragment>
                                                                    <NForm.Item {...input} {...layout}
                                                                                name="醫生No_指定醫師"
                                                                                label="指定醫師:"
                                                                                rules={[{required: true}]}
                                                                    >
                                                                        <Select {...input} placeholder="請選擇指定醫師"
                                                                                defaultValue={""}>
                                                                            {docOptions.map((it => {
                                                                                return <Option
                                                                                    value={it.key}>{it.label}</Option>
                                                                            }))}
                                                                        </Select>
                                                                        {meta.touched && meta.error &&
                                                                        <div className="error">
                                                                            {meta.error}
                                                                        </div>}
                                                                    </NForm.Item>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    </Field>
                                                    {/* <Field parse={_.bs.identity} name="reservationdate">
                                                        {
                                                            ({ input, meta }) => (
                                                                <React.Fragment>
                                                                    <NForm.Item {...input} {...layout}
                                                                        name="reservationdate"
                                                                        label="預約日期:"

                                                                    >
                                                                        <DatePicker format={dateFormat} placeholder="請選擇預約日期" />
                                                                        {meta.touched && meta.error &&
                                                                            <div className="error">
                                                                                {meta.error}
                                                                            </div>}
                                                                    </NForm.Item>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    </Field>
                                                    <Field parse={_.bs.identity} name="designatedphysician">
                                                        {
                                                            ({ input, meta }) => (
                                                                <React.Fragment>
                                                                    <NForm.Item {...input} {...layout}
                                                                        name="designatedphysician"
                                                                        label="預約時段:"

                                                                    >
                                                                        <TimePicker.RangePicker format={format} defaultValue={[moment('09:00', format), moment('09:30', format)]} />
                                                                        {meta.touched && meta.error &&
                                                                            <div className="error">
                                                                                {meta.error}
                                                                            </div>}
                                                                    </NForm.Item>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    </Field> */}

                                                    <Field parse={_.bs.identity} name="診別">
                                                        {
                                                            ({input, meta}) => (
                                                                <React.Fragment>
                                                                    <NForm.Item {...input} {...layout}
                                                                                name="診別"
                                                                                label="診別:"
                                                                    >
                                                                        <Select {...input} placeholder="請選擇診別"
                                                                                defaultValue="非初診">
                                                                            <Option value="">不指定</Option>
                                                                            <Option value="初診">初診</Option>
                                                                            <Option value="非初診">非初診</Option>
                                                                        </Select>
                                                                        {meta.touched && meta.error &&
                                                                        <div className="error">
                                                                            {meta.error}
                                                                        </div>}
                                                                    </NForm.Item>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    </Field>

                                                    <Field parse={_.bs.identity} name="主要處置">
                                                        {
                                                            ({input, meta}) => (
                                                                <React.Fragment>
                                                                    <NForm.Item {...input} {...layout}
                                                                                name="主要處置"
                                                                                label="主要處置:"

                                                                    >
                                                                        <Select {...input} placeholder="請選擇主要處置">
                                                                            <Option value="">不指定</Option>
                                                                            <Option value="手術">手術</Option>
                                                                            <Option value="預防">預防</Option>
                                                                            <Option value="急救">急救</Option>
                                                                        </Select>
                                                                        {meta.touched && meta.error &&
                                                                        <div className="error">
                                                                            {meta.error}
                                                                        </div>}
                                                                    </NForm.Item>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    </Field>

                                                    <Field parse={_.bs.identity} name="看診原因">
                                                        {
                                                            ({input, meta}) => (
                                                                <React.Fragment>
                                                                    <NForm.Item {...input} {...layout}
                                                                                name="看診原因"
                                                                                label="看診原因:"

                                                                    >
                                                                        <Input {...input} placeholder="請輸入看診原因限7字"
                                                                               allowClear max={7}/>
                                                                        {meta.touched && meta.error &&
                                                                        <div className="error">
                                                                            {meta.error}
                                                                        </div>}
                                                                    </NForm.Item>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    </Field>

                                                    <Field parse={_.bs.identity} name="看診描述">
                                                        {
                                                            ({input, meta}) => (
                                                                <React.Fragment>
                                                                    <NForm.Item {...input} {...layout}
                                                                                name="看診描述"
                                                                                label="看診描述:"

                                                                    >
                                                                        <TextArea {...input} rows={1}
                                                                                  placeholder="請輸入描述" {...input}/>
                                                                        {meta.touched && meta.error &&
                                                                        <div className="error">
                                                                            {meta.error}
                                                                        </div>}
                                                                    </NForm.Item>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    </Field>


                                                </div>
                                                <Field parse={_.bs.identity} name="預約症狀照片組">
                                                    {
                                                        ({input, meta}) => {
                                                            if (!state.nsUploader.compState.hasSetFileList) {
                                                                state.nsUploader.compState.hasSetFileList = true;

                                                                let count: number = 0;

                                                                if (input.value && input.value !== '') {
                                                                    state.nsUploader.compState.fileList = (input.value).split(',').map((it) => {
                                                                        count++;

                                                                        // minimum requirement for antFileInfos [
                                                                        //     {
                                                                        //         uid: '-1',
                                                                        //         name: 'image.png',
                                                                        //         status: 'done',
                                                                        //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                                                                        //     },
                                                                        // ];

                                                                        let url = Ln_AvatorImgHref({
                                                                            照片組: it,
                                                                            testMode: false,
                                                                        });
                                                                        let antFileInfo = {
                                                                            uid: count,
                                                                            status: 'done',
                                                                            url: url,
                                                                            name: it,
                                                                            thumbUrl: url,

                                                                            id: it,
                                                                        };
                                                                        return antFileInfo;
                                                                    });

                                                                    state.nsUploader.compState.files = (input.value ?? '').split(',').map((it) => {
                                                                        count++;

                                                                        // minimum requirement for antFileInfos [
                                                                        //     {
                                                                        //         uid: '-1',
                                                                        //         name: 'image.png',
                                                                        //         status: 'done',
                                                                        //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                                                                        //     },
                                                                        // ];
                                                                        let url = Ln_PictureImgHref({
                                                                            照片組: it,
                                                                            testMode: false,
                                                                        });
                                                                        let antFileInfo = {
                                                                            uid: count,
                                                                            status: 'done',
                                                                            url: url,
                                                                            name: it,
                                                                            thumbUrl: url,

                                                                            id: it,
                                                                        };
                                                                        return antFileInfo;
                                                                    });
                                                                }
                                                            }

                                                            return <React.Fragment>
                                                                <div className="col v_line imagebar">
                                                                    <div className="uploadimgbar">
                                                                        <ul>
                                                                            {/*{zoneState.state.fileList.map((file) => {*/}

                                                                            {/*    var file2 = file;*/}
                                                                            {/*    debugger;*/}

                                                                            {/*    return <li onClick={(e) => createModalPhotogallery(e, {})}>*/}
                                                                            {/*        <img src="../static/img/ct001.jpg"*/}
                                                                            {/*             alt="imgagesname"/>*/}
                                                                            {/*        <Button className="del" shape="circle"*/}
                                                                            {/*                icon={<CloseOutlined/>} size='small'/>*/}

                                                                            {/*    </li>;*/}
                                                                            {/*})}*/}

                                                                            {/*<li onClick={(e) => createModalPhotogallery(e, {})}><img*/}
                                                                            {/*    src="../static/img/ct002.jpg" alt="" /><Button*/}
                                                                            {/*        className="del" shape="circle" icon={<CloseOutlined />}*/}
                                                                            {/*        size='small' /></li>*/}

                                                                            {/*<li onClick={(e) => createModalPhotogallery(e, {})}><img*/}
                                                                            {/*    src="../static/img/ct003.jpg" alt="" /><Button*/}
                                                                            {/*        className="del" shape="circle" icon={<CloseOutlined />}*/}
                                                                            {/*        size='small' /></li>*/}
                                                                            {/*<li onClick={(e) => createModalPhotogallery(e, {})}><img*/}
                                                                            {/*    src="../static/img/ct004.jpg" alt="" /><Button*/}
                                                                            {/*        className="del" shape="circle" icon={<CloseOutlined />}*/}
                                                                            {/*        size='small' /></li>*/}

                                                                        </ul>
                                                                    </div>
                                                                    <Upload
                                                                        action={ApiService.urlMapping('~/pet_api/1/_memberupload')}
                                                                        withCredentials={true}
                                                                        listType="picture"
                                                                        fileList={state.nsUploader.compState.fileList}
                                                                        beforeUpload={state.nsUploader.beforeUpload}
                                                                        onPreview={state.nsUploader.handlePreview}
                                                                        //previewFile={state.nsUploader.previewFile}
                                                                        onChange={(params) => state.nsUploader.handleChange({
                                                                            ...params,
                                                                            wrapperOnChange: input.onChange
                                                                        })}
                                                                        multiple={true}
                                                                        data={state.nsUploader.addData}
                                                                        className="upload-list-inline"
                                                                    >
                                                                        <Button icon={<UploadOutlined/>}>上傳症狀照片</Button>
                                                                        {/*<Modal*/}
                                                                        {/*    visible={zoneState.state.previewVisible}*/}
                                                                        {/*    title={zoneState.state.previewTitle}*/}
                                                                        {/*    footer={null}*/}
                                                                        {/*    onCancel={handleCancel}*/}
                                                                        {/*>*/}
                                                                        {/*    <img alt="example"*/}
                                                                        {/*         style={{width: '100%'}}*/}
                                                                        {/*         src={zoneState.state.previewImage}/>*/}
                                                                        {/*</Modal>*/}

                                                                    </Upload>
                                                                </div>
                                                                {meta.touched && meta.error &&
                                                                <div className="error">
                                                                    {meta.error}
                                                                </div>}
                                                            </React.Fragment>
                                                        }
                                                    }
                                                </Field>

                                            </div>

                                            <React.Fragment>
                                                <NForm.Item>
                                                    <div className="form_footer">
                                                        {isNew ?
                                                            <Button type="primary" htmlType="submit"
                                                                    size="large" disabled={submitting}
                                                                    onClick={handleSubmit}>建立</Button> :
                                                            <Button type="primary" htmlType="submit"
                                                                    size="large" disabled={submitting}
                                                                    onClick={handleSubmit}>更新</Button>
                                                        }
                                                    </div>
                                                </NForm.Item>
                                            </React.Fragment>

                                        </form>
                                    </div>
                                </React.Fragment>;
                            }}
                        />
                    </div>
                </div>
                <div className="modal_footer"></div>
            </div>
        );
    };
    //#endregion

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_Appointment__Modal_EditOrAddRegister.displayName = 'Partial_Appointment__Modal_EditOrAddRegister';
