import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'

import {createOvermind} from 'overmind';
import {Provider} from 'overmind-react';
import {config} from './overmind';

export const configstore = createOvermind(config, {
    devtools: false, // defaults to 'localhost:3031'
    //devEnv: 'production'
});

function WrappedApp() {
    return (
        <Provider value={configstore}>
            <div className="App">
                <DndProvider backend={HTML5Backend}>
                    <App/>
                </DndProvider>
            </div>
        </Provider>
    )
}

ReactDOM.render(
    <Provider value={configstore}>
        <App/>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
