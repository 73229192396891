import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {
    Form as NForm,
    Button,
    Modal,
    Statistic,
    message,
    Row,
    Col,
    Typography,
    DatePicker,
    Input,
    Select,
    Space,
    Tabs,
    Tooltip,
    Steps,
    Table,
    Empty,
    Spin
} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';


import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";
import {LoadingOutlined, LockOutlined, EditOutlined, PlusOutlined, CloseOutlined} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_Modal_DealwithItem from "./Partial_Modal_DealwithItem";
import Partial_MedicalRecord__Modal_DealwithAdd from "./Partial_MedicalRecord__Modal_DealwithAdd";
import UseCompState from "../hooks/UseCompState";


var moment = require('moment');
const {Option} = Select;
const {Search} = Input;
const onSearch = value => console.log(value);
const {TabPane} = Tabs;
const {Step} = Steps;
const {RangePicker} = DatePicker;
const dateFormat = 'YYYY/MM/DD';
const today = new Date();
const {Title} = Typography;

type ViewModelProps = {
    refModal: any,

    飼主No: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any,

    viewModel: ViewModelProps,
};
export default function Partial_ClientManage__Modal_ClientAnalysis(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || ('Partial_ClientManage__Modal_ClientAnalysis' + (props.viewModel.飼主No ?? "0"));
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel = props.viewModel;


    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion


    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

        state.DataSource_掛號 = null;
    }
    //#endregion

    //#region No Cache
    useEffect(() => {
        return () => {
            pageDataManager.emptyZone(PrefixTarget);
            pageDataManager.apply();
        }
    }, []);
    //#endregion

    //#region Boot
    let _refDoLoadPartialData: any = useRef<{ alwaysLoad?: boolean }>(null);
    useEffect(() => {
        const _loadPartialData = async (): Promise<void> => {

            // VALIDATE INPUT
            const p1 = viewModel.飼主No;
            if (!p1) {
                alert('p1');
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/partial_clientmanage/modal_clientanalysis');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        let changeShard = {
                            [PrefixTarget]: {
                                ...payload,
                            }
                        }
                        pageDataManager.merge(changeShard);
                        pageDataManager.apply();

                        forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPartialData = async ({alwaysLoad = false}): Promise<void> => {
            // if (!pageDataManager.refPageData.current.Root) {
            //     return;
            // }

            /// do not dynamically load data if condition fits.
            if (!alwaysLoad) {
                if (pageDataManager.isZoneExist(PrefixTarget)) {
                    return;
                }
            }

            await _loadPartialData();
        }

        _refDoLoadPartialData.current = doLoadPartialData;
        _refDoLoadPartialData.current({});
    }, [pageDataManager.get(PrefixTarget)]);
    //#endregion


    //#region Set Body
    const isModalContained = () => {
        return !!viewModel.refModal?.current
    }
    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };
    const Body = () => {
        const RegionEmptyShow = false;
        const RegionLoadingShow = true;
        const RegionInitialLoadingShow = false;

        const PassPageDataInitalized = pageDataManager.refPageData.current != null;
        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;


        let summery = pageDataManager.get(`${PrefixTarget}.summery`);




        //#region Handlers
        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            if (!values.username) {
                errors.username = '↑ 欄位為必填';
            } else {
            }

            if (!values.password) {
                errors.password = '↑ 欄位為必填';
            } else {
            }

            return errors;
        };

        const handleClose = (e) => {
            if (e) {
                e.preventDefault();
            }

            if (viewModel.refModal || viewModel.refModal.current) {
                viewModel.refModal.current.destroy();
            }
        };

        // 消費總覽

        interface overshopItem {
            key: React.Key;
            purchanumber: string;
            totalpamount: string;
        }

        const oshopCol = [
            {
                title: '消費次數',
                dataIndex: 'purchanumber',
            },
            {
                title: '平均消費',
                dataIndex: 'totalpamount',
            },
        ];




        // 消費項目

        interface pshopItem {
            key: React.Key;
            purchasestype: string;
            purchasesnumber: string;
            totalamount: string;
        }

        const pshopCol = [
            {
                title: '消費日期',
                dataIndex: 'paydate',
            },
            {
                title: '寵物名稱',
                dataIndex: 'petname',
            },
            {
                title: '總金額',
                dataIndex: 'totalamount',
            },
        ];

        const pshopData: pshopItem[] = [
            {
                key: 1,
                purchasestype: '美容',
                purchasesnumber: '11',
                totalamount: '$8,800'
            },
            {
                key: 2,
                purchasestype: '看診',
                purchasesnumber: '27',
                totalamount: '$9,450'
            },
            {
                key: 3,
                purchasestype: '售貨',
                purchasesnumber: '75',
                totalamount: '$78,232'
            }
        ];
        //#endregion


        if (!PassPrefixTargetLoaded) {
            if (RegionInitialLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <div>Loading.</div>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        if (loadingManager.getIsLoading()) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <Spin indicator={
                            <LoadingOutlined style={{fontSize: 24}} spin/>
                        }/>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        let DataSource_掛號 = state.DataSource_掛號;
        let List_掛號 = pageDataManager.get(`${PrefixTarget}.List_掛號`);
        if (List_掛號 !== null) {
            let serialCounter = 0;
            DataSource_掛號 = List_掛號.map((Item_掛號) => {
                serialCounter += 1;

                if (serialCounter == 1) {
                }

                return {
                    entity: Item_掛號,
                    key: Item_掛號.掛號No,

                    paydate: Item_掛號.完成出納時間 ? moment(Item_掛號.完成出納時間).format('YYYY-MM-DD hh:mm') : '-',
                    petname: Item_掛號._寵物.寵物名稱,
                    totalamount: '$' + _.util.commaSep(Item_掛號.出納總價金 ?? 0),
                }
            });

            state.DataSource_掛號 = DataSource_掛號;
        }

        const oshopData: overshopItem[] = [
            {
                key: 1,
                purchanumber: summery.消費次數,
                totalpamount: '$' + _.util.commaSep(summery.平均消費 ?? 0),
            },
        ];

        return (
            <div className="model_wrapper">
                <div className="modal_title">
                    <div className="title_info">
                        <h2>飼主喜好分析</h2>
                    </div>
                    <div className="info_bar"></div>
                    <div className="control_bar">
                        <Tooltip placement="top" title={'關閉'}>
                            <Button type="text" icon={<CloseOutlined/>} onClick={handleClose}/>
                        </Tooltip>
                    </div>
                </div>
                <div className="modal_content">
                    <div className="content_box">
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="消費分析" key="1">
                                {/*<div className="box-manage">*/}
                                {/*    <Space>*/}
                                {/*        <RangePicker*/}
                                {/*            defaultValue={[moment('2015/01/01', dateFormat), moment(today, dateFormat)]}*/}
                                {/*            format={dateFormat}*/}
                                {/*            placeholder={['選擇開始日期', '選擇結束日期']}*/}
                                {/*        />*/}
                                {/*        <Button type="primary">整體</Button>*/}
                                {/*    </Space>*/}
                                {/*</div>*/}
                                <div className="page-analysis">
                                    <div className="box-manage">
                                        <Title level={4}>消費總覽</Title>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Statistic title="消費次數" value={summery.消費次數}/>
                                            </Col>
                                            <Col span={12}>
                                                <Statistic title="平均消費" value={'$' + _.util.commaSep(summery.平均消費 ?? 0)}/>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="box-manage">
                                        <Title level={4}>消費項目</Title>
                                        <Table
                                            columns={pshopCol}
                                            dataSource={DataSource_掛號}
                                            scroll={{y: 240}}
                                            size='small'
                                            locale={{
                                                emptyText: <Empty description={
                                                    <span>沒有相關資料～</span>
                                                }/>
                                            }}
                                        />
                                    </div>
                                </div>


                            </TabPane>
                        </Tabs>

                    </div>
                </div>
                <div className="modal_footer"></div>
            </div>
        );
    };

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
    //#endregion
}
Partial_ClientManage__Modal_ClientAnalysis.displayName = 'Partial_ClientManage__Modal_ClientAnalysis';
