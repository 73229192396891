import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {
    Form as NForm,
    Popover,
    Button,
    Modal,
    message,
    Upload,
    Input,
    Space,
    Select,
    Radio,
    DatePicker,
    InputNumber,
    Menu,
    Dropdown,
    Skeleton
} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";

import {
    LoadingOutlined, LockOutlined, PlusOutlined, DownOutlined,
    EllipsisOutlined, GlobalOutlined, PhoneOutlined, SmileFilled, FileImageFilled
} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_Appointment__Modal_Preview from "./Partial_Appointment__Modal_Preview";
import Partial_Modal_Photogallery from "./Partial_Modal_Photogallery";
import Partial_Appointment__Modal_EditOrAddRegister from "./Partial_Appointment__Modal_EditOrAddRegister";
import Pg_MedicalRecord from "./Pg_MedicalRecord";
import Ln_FirstImg from "./Ln_FirstImg";
import Ln_AvatorImg from "./Ln_AvatorImg";
import UseCompState from "../hooks/UseCompState";
import Partial_Appointment__Modal_AddReservation from "./Partial_Appointment__Modal_AddReservation";
import Partial_Appointment__Pane_EditReservation from "./Partial_Appointment__Pane_EditReservation";
import Partial_Appointment__Modal_EditReservation from "./Partial_Appointment__Modal_EditReservation";

var moment = require('moment');
const {Option} = Select;

const iconWaitforCase = require('../assets/images/icon/nocase.svg');

type ViewModelProps = {
    refModal: any,
    Data_掛號: any,
    List_醫生: any,

    isLoading: any,

    Kind?: undefined | 'Kind_WaitingList' | 'Kind_UnfinishedList' | 'Kind_ServingList' | 'Kind_ReservationList' | 'Kind_ReservationUncheckedList';

    Kind_ServingList?: any,  //服務中

    Kind_WaitingList?: any,  //候診中
    Kind_UnfinishedList?: any, //待完成病歷

    Kind_ReservationList?: any, //預約
    Kind_ReservationUncheckedList?: any, //預約未確認

    OnDid?: any;
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any,

    viewModel: ViewModelProps,
};
const dateFormat = 'YYYY/MM/DD';

export default function Partial_CommonCard(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_CommonCard';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    const viewModel = props.viewModel;
    viewModel.Kind = viewModel.Kind ?? "Kind_WaitingList";

    const layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 16},
    };


    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

    }
    //#endregion

    //#region Boot
    const _doLoadPartialData = async (): Promise<void> => {
        if (!pageDataManager.refPageData.current.Root) {
            return;
        }

        /// do not dynamically load data if condition fits.
        if (pageDataManager.isZoneExist(PrefixTarget)) {
            return;
        }
        //await _loadPageData();
    }
    useEffect(() => {
        _doLoadPartialData();
    }, []);
    //#endregion


    //#region :Popup :createModalAddReservationn
    const createModalAddReservation_refModal = useRef<any>(null);
    const createModalAddReservation = (e: any, {寵物_飼主__關係No, tasks}: {
        寵物_飼主__關係No: any,
        tasks?: string[]
    }) => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_Appointment__Modal_AddReservation
                PrefixTarget={`Partial_Appointment__Modal_AddReservation`}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModalAddReservation_refModal,

                    寵物_飼主__關係No,
                }}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '35em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModalAddReservation_refModal.current = Modal.info(config);
    }
    //#endregion

    //#region :Popup :modalEditReservation
    const createModalEditReservation_refModal = useRef<any>(null);
    const createModalEditReservation = (e: any, {掛號No, tasks}: {
        掛號No: any,
        tasks?: string[]
    }): void => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_Appointment__Modal_EditReservation
                PrefixTarget={`Partial_Appointment__Modal_EditReservation`}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModalEditReservation_refModal,
                    掛號No,
                }}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '35em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModalEditReservation_refModal.current = Modal.info(config);
    }
    //#endregion


    //#region :Popup :createModalRegister
    const createModalRegister_refModal = useRef<any>(null);
    const createModalRegister = (e: any, {掛號No, tasks}: {
        掛號No?: any,
        tasks?: string[]
    }): void => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_Appointment__Modal_EditOrAddRegister
                PrefixTarget={`Partial_Appointment__Modal_EditOrAddRegister${掛號No}`}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModalRegister_refModal,

                    Kind: 'Update',
                    Kind_Update: {
                        掛號No: 掛號No,
                    }
                }}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '60em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModalRegister_refModal.current = Modal.info(config);
    }
    //#endregion


    //#region Set Body
    const isModalContained = () => {
        return !!viewModel.refModal?.current
    }

    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };
    const Body = () => {


        //#region Handlers
        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            // if (!values.ownername) {
            //     errors.ownername = '↑ 欄位為必填';
            // } else {
            // }
            //
            // if (!values.cellphone) {
            //     errors.cellphone = '↑ 欄位為必填';
            // } else {
            // }

            return errors;
        };

        const handleSubmit_entercancel = (e: any, {掛號No}: { 掛號No: any }) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }

            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            const p1 = 掛號No;
            if (!p1) {
                _._debug.error(p1);
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_commoncard/entercancel');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        ///handleclose
                        message.success({content: '修改完成!', duration: 2});

                        if (isModalContained()) {
                            handleClose(null);
                        } else {
                            //_refDoLoadPartialData.current({alwaysLoad: true});
                        }

                        viewModel?.OnDid?.(payload);
                        //state.parentState?._refDoLoadPartialData?.current({alwaysLoad: true});
                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const handleSubmit_enterwait = (e: any, {掛號No}: { 掛號No: any }) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }

            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            const p1 = 掛號No;
            if (!p1) {
                _._debug.error(p1);
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_commoncard/enterwait');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);


                        message.success({content: '修改完成!', duration: 2});

                        if (isModalContained()) {
                            handleClose(null);
                        } else {
                            //_refDoLoadPartialData.current({alwaysLoad: true});
                        }

                        viewModel?.OnDid?.(payload);
                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const handleSubmit_enterconfirmreservation = (e: any, {掛號No}: { 掛號No: any }) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }

            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            const p1 = 掛號No;
            if (!p1) {
                _._debug.error(p1);
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_commoncard/enterconfirmreservation');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);


                        message.success({content: '修改完成!', duration: 2});

                        if (isModalContained()) {
                            handleClose(null);
                        } else {
                            //_refDoLoadPartialData.current({alwaysLoad: true});
                        }

                        viewModel?.OnDid?.(payload);
                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const handleSubmit_enterreminddone = (e: any, {掛號No}: { 掛號No: any }) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }

            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            const p1 = 掛號No;
            if (!p1) {
                _._debug.error(p1);
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_commoncard/enterreminddone');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        message.success({content: '修改完成!', duration: 2});

                        if (isModalContained()) {
                            handleClose(null);
                        } else {
                            //_refDoLoadPartialData.current({alwaysLoad: true});
                        }

                        viewModel?.OnDid?.(payload);
                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const handleSubmit_enterstartexam = (e: any, {掛號No}: { 掛號No: any }) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }

            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            const p1 = 掛號No;
            if (!p1) {
                _._debug.error(p1);
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_commoncard/enterstartexam');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);


                        message.success({content: '修改完成!', duration: 2});

                        if (isModalContained()) {
                            handleClose(null);
                        } else {
                            //_refDoLoadPartialData.current({alwaysLoad: true});
                        }

                        viewModel?.OnDid?.(payload);
                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }


//        Partial_Appointment__Pane_EditReservation
        //#endregion

        const menu = <React.Fragment/>;


        /* Skeleton */
        if (viewModel.isLoading || (viewModel.Data_掛號 === null)) {
            return <div className="commoncard">
                <div className="wrapper">
                    <div className="colorbar prevention"></div>
                    <div className="combox">
                        <div className="titlebar">

                            {/* Skeleton waitroomstyle  Start */}
                            <div className="waitroomstyle">
                                <div className="sequence">

                                    <Skeleton.Button style={{width: 40}} active={true} size={'small'}
                                                     shape={'round'}/>

                                </div>
                                <div className="infobar">
                                    <Skeleton.Input style={{width: 130}} active={true} size={'small'}/>
                                </div>
                                <div className="stauts">
                                    <Skeleton.Button active={true} size={'small'} shape={'round'}/>
                                </div>
                            </div>

                            {/* Skeleton waitroomstyle  End */}

                        </div>
                        <div className="comcontent">


                            <div className="basicdata">
                                <div className="avatar">
                                    <Skeleton.Avatar active={true} size={'large'}/>
                                </div>
                                <div className="infocol">
                                    <Skeleton paragraph={{rows: 3}} active={true} title={true}/>
                                </div>
                            </div>
                        </div>
                        <div className="comfooter">
                            <div className="meditem">
                                <Space>
                                    <Skeleton.Button style={{width: 45}} active={true} size={'small'}
                                                     shape={'round'}/>

                                </Space>
                            </div>
                            <div className="funbar">
                                <Space>
                                    <Skeleton.Button style={{width: 65}} active={true} size={'large'}/>
                                    <Skeleton.Button style={{width: 65}} active={true} size={'large'}/>
                                </Space>


                            </div>
                        </div>
                    </div>
                </div>
            </div>;
        }

        if (viewModel.Data_掛號.length === 0) {
            return <React.Fragment>
                <div className="nocase">
                    <img src={iconWaitforCase} alt="coffee"></img>
                    <p className="nocase-title">目前尚無待處理項目</p>
                    <p className="nocase-description">來杯咖啡？或是幫寵物掛號</p>
                    {Static.NavAwayToAppointment(navigate, {}).Link({
                        children: <Button type="primary" size="large">掛號與搜尋</Button>
                    })}
                </div>
            </React.Fragment>;
        } else if (viewModel.Data_掛號.length > 0) {
            return <React.Fragment>
                <div className="listcomponent">
                    {/* Skeleton Start 預約狀態 */}
                    {/*<div className="commoncard">*/}
                    {/*    <div className="wrapper">*/}
                    {/*        <div className="colorbar prevention"></div>*/}
                    {/*        <div className="combox">*/}
                    {/*            <div className="titlebar">*/}


                    {/*                /!* Skeleton reservationstyle  Start*!/*/}
                    {/*                <div className="reservationstyle">*/}
                    {/*                    <div className="sequence">*/}
                    {/*                        <Skeleton.Input style={{ width: 180 }} active={true} size={'small'} />*/}
                    {/*                    </div>*/}
                    {/*                    <div className="stauts">*/}
                    {/*                        <Skeleton.Button active={true} size={'small'} shape={'round'} />*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                /!* Skeleton waitroomstyle  End *!/*/}

                    {/*            </div>*/}
                    {/*            <div className="comcontent">*/}


                    {/*                <div className="basicdata">*/}
                    {/*                    <div className="avatar">*/}
                    {/*                    <Skeleton.Avatar active={true} size={'large'} />*/}

                    {/*                    </div>*/}
                    {/*                    <div className="infocol">*/}
                    {/*                    <Skeleton paragraph={{ rows: 3 }} active={true} />*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="comfooter">*/}
                    {/*                <div className="meditem">*/}
                    {/*                    <Space>*/}
                    {/*                        <Skeleton.Button style={{ width: 45 }} active={true} size={'small'} shape={'round'} />*/}
                    {/*                    </Space>*/}
                    {/*                </div>*/}
                    {/*                <div className="funbar">*/}
                    {/*                    <Space>*/}
                    {/*                        <Skeleton.Button style={{ width: 65 }} active={true} size={'large'} />*/}
                    {/*                        <Skeleton.Button style={{ width: 65 }} active={true} size={'large'} />*/}
                    {/*                    </Space>*/}


                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/* 候診狀態 */}

                    {viewModel.Data_掛號.map((Item_掛號) => {
                        //debugger;

                        let 看診醫師: any = viewModel.List_醫生.filter(r => r.醫生No === Item_掛號.醫生No_看診醫師)?.[0]?.名稱;
                        if (!看診醫師) {
                            看診醫師 = null;
                        } else {
                            看診醫師 = 看診醫師 + ' 醫師';
                        }

                        let 指定醫師: any = viewModel.List_醫生.filter(r => r.醫生No === Item_掛號.醫生No_指定醫師)?.[0]?.名稱;
                        if (!指定醫師) {
                            指定醫師 = null;
                        } else {
                            指定醫師 = 指定醫師 + ' 醫師';
                        }

                        let is預約 = Item_掛號.候診狀態 === '預約' || Item_掛號.候診狀態 === '預約未確認';
                        //let is掛號 = Item_掛號.候診狀態 !== '預約';


                        viewModel.Kind = viewModel.Kind ?? "Kind_WaitingList";

                        if (viewModel.Kind === 'Kind_WaitingList') {
                            return <div className="commoncard" key={Item_掛號.掛號No}>
                                <div className="wrapper">
                                    <div className="colorbar prevention"></div>
                                    <div className="combox">
                                        <div className="titlebar">
                                            <div className="waitroomstyle">
                                                <div className="sequence">
                                                    <div className="num">
                                                        {Item_掛號.serial}
                                                    </div>
                                                </div>
                                                <div className="infobar">
                                                    {moment(Item_掛號.ModifiedAt ?? Item_掛號.CreatedAt).format('YYYY-MM-DD hh:mm')}
                                                </div>
                                                <div className="stauts">
                                                    {Item_掛號.候診狀態} {/* 看診中/待完成 */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="comcontent">
                                            <div className="basicdata">
                                                <div className="avatar">
                                                    <div className="photo">
                                                        {Ln_AvatorImg({
                                                            名稱: Item_掛號._寵物.寵物名稱,
                                                            照片組: Item_掛號._寵物.寵物照片組
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="infocol">
                                                    <div className="h4">{Item_掛號._飼主.主人姓名}</div>
                                                    <div className="link">{Item_掛號._飼主.手機 ?? Item_掛號._飼主.聯絡電話}</div>
                                                    <div className="rop">
                                                        <div>{Item_掛號._寵物.種類}</div>
                                                        ．
                                                        <div>{Item_掛號._寵物.寵物名稱}</div>
                                                    </div>

                                                    <div className="medicaldata">
                                                        <div className="h5">
                                                            {指定醫師}
                                                        </div>
                                                        <div className="cop">{Item_掛號.看診原因 ?? Item_掛號.看診描述} <Button
                                                            type="link"
                                                            onClick={(e) => createModalRegister(e, {
                                                                掛號No: Item_掛號.掛號No,
                                                            })}>...更多</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="comfooter">
                                            <div className="meditem">
                                                {Item_掛號.診別 === "初診" &&
                                                <div className="itembox first">初診</div>
                                                }
                                                {Item_掛號.診別 === "預防" &&
                                                <div className="itembox prevention">預防</div>
                                                }
                                            </div>
                                            <div className="funbar">
                                                <Dropdown overlay={<Menu>
                                                    <Menu.Item>
                                                        <a target="_blank" rel="noopener noreferrer"
                                                           onClick={(e) => createModalRegister(e, {
                                                               掛號No: Item_掛號.掛號No,
                                                           })}>
                                                            編輯
                                                        </a>
                                                    </Menu.Item>
                                                    {/*<Menu.Item>*/}
                                                    {/*    <a target="_blank" rel="noopener noreferrer"*/}
                                                    {/*       href="https://www.aliyun.com">*/}
                                                    {/*        取消*/}
                                                    {/*    </a>*/}
                                                    {/*</Menu.Item>*/}
                                                </Menu>}>
                                                    <Button icon={<EllipsisOutlined/>}/>
                                                </Dropdown>

                                                <React.Fragment>
                                                    <Button type="primary"
                                                            onClick={(e) => handleSubmit_enterstartexam(e, {掛號No: Item_掛號.掛號No})}>看診</Button>
                                                    {/*<Button type="primary"*/}
                                                    {/*        onClick={(e) => handleSubmit_entercancel(e, {掛號No: Item_掛號.掛號No})}>中止</Button>*/}
                                                </React.Fragment>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>;
                        }

                        if (viewModel.Kind === 'Kind_ServingList') {
                            return <div className="commoncard" key={Item_掛號.掛號No}>
                                <div className="wrapper">
                                    <div className="colorbar prevention"></div>
                                    <div className="combox">
                                        <div className="titlebar">
                                            <div className="waitroomstyle">
                                                <div className="sequence">
                                                    <div className="num">
                                                        {Item_掛號.serial}
                                                    </div>
                                                </div>
                                                <div className="infobar">
                                                    {moment(Item_掛號.ModifiedAt ?? Item_掛號.CreatedAt).format('YYYY-MM-DD hh:mm')}
                                                </div>
                                                <div className="stauts">
                                                    {Item_掛號.候診狀態} {/* 看診中/待完成 */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="comcontent">
                                            <div className="basicdata">
                                                <div className="avatar">
                                                    <div className="photo">
                                                        {Ln_AvatorImg({
                                                            名稱: Item_掛號._寵物.寵物名稱,
                                                            照片組: Item_掛號._寵物.寵物照片組
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="infocol">
                                                    <div className="h4">{Item_掛號._飼主.主人姓名}</div>
                                                    <div className="link">{Item_掛號._飼主.手機 ?? Item_掛號._飼主.聯絡電話}</div>
                                                    <div className="rop">
                                                        <div>{Item_掛號._寵物.種類}</div>
                                                        ．
                                                        <div>{Item_掛號._寵物.寵物名稱}</div>
                                                    </div>

                                                    <div className="medicaldata">
                                                        <div className="h5">
                                                            {指定醫師}
                                                        </div>
                                                        <div className="cop">{Item_掛號.看診原因 ?? Item_掛號.看診描述} <Button
                                                            type="link"
                                                            onClick={(e) => createModalRegister(e, {
                                                                掛號No: Item_掛號.掛號No,
                                                            })}>...更多</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="comfooter">
                                            <div className="meditem">
                                                {Item_掛號.診別 === "初診" &&
                                                <div className="itembox first">初診</div>
                                                }
                                                {Item_掛號.診別 === "預防" &&
                                                <div className="itembox prevention">預防</div>
                                                }
                                            </div>
                                            <div className="funbar">
                                                <Dropdown overlay={<Menu>
                                                    <Menu.Item>
                                                        <a target="_blank" rel="noopener noreferrer"
                                                           onClick={(e) => createModalRegister(e, {
                                                               掛號No: Item_掛號.掛號No,
                                                           })}>
                                                            編輯
                                                        </a>
                                                    </Menu.Item>
                                                    {/*<Menu.Item>*/}
                                                    {/*    <a target="_blank" rel="noopener noreferrer"*/}
                                                    {/*       href="https://www.aliyun.com">*/}
                                                    {/*        取消*/}
                                                    {/*    </a>*/}
                                                    {/*</Menu.Item>*/}
                                                </Menu>}>
                                                    <Button icon={<EllipsisOutlined/>}/>
                                                </Dropdown>

                                                <React.Fragment>
                                                    <Button type="primary"
                                                            onClick={Static.NavAwayToRecord(navigate, {No: Item_掛號.寵物No})}>編輯病歷</Button>
                                                    {/*<Button type="primary"*/}
                                                    {/*        onClick={(e) => handleSubmit_enterstartexam(e, {掛號No: Item_掛號.掛號No})}>看診</Button>*/}
                                                    {/*<Button type="primary"*/}
                                                    {/*        onClick={(e) => handleSubmit_entercancel(e, {掛號No: Item_掛號.掛號No})}>中止</Button>*/}
                                                </React.Fragment>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>;
                        }

                        if (viewModel.Kind === 'Kind_UnfinishedList') {
                            return <div className="commoncard" key={Item_掛號.掛號No}>
                                <div className="wrapper">
                                    <div className="colorbar prevention"></div>
                                    <div className="combox">
                                        <div className="titlebar">
                                            <div className="waitroomstyle">
                                                <div className="sequence">
                                                    <div className="num">
                                                        {Item_掛號.serial}
                                                    </div>
                                                </div>
                                                <div className="infobar">
                                                    {moment(Item_掛號.ModifiedAt ?? Item_掛號.CreatedAt).format('YYYY-MM-DD hh:mm')}
                                                </div>
                                                <div className="stauts">
                                                    {Item_掛號.候診狀態} {/* 看診中/待完成 */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="comcontent">
                                            <div className="basicdata">
                                                <div className="avatar">
                                                    <div className="photo">
                                                        {Ln_AvatorImg({
                                                            名稱: Item_掛號._寵物.寵物名稱,
                                                            照片組: Item_掛號._寵物.寵物照片組
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="infocol">
                                                    <div className="h4">{Item_掛號._飼主.主人姓名}</div>
                                                    <div className="link">{Item_掛號._飼主.手機 ?? Item_掛號._飼主.聯絡電話}</div>
                                                    <div className="rop">
                                                        <div>{Item_掛號._寵物.種類}</div>
                                                        ．
                                                        <div>{Item_掛號._寵物.寵物名稱}</div>
                                                    </div>

                                                    <div className="medicaldata">
                                                        <div className="h5">
                                                            {指定醫師}
                                                        </div>
                                                        <div className="cop">{Item_掛號.看診原因 ?? Item_掛號.看診描述} <Button
                                                            type="link"
                                                            onClick={(e) => createModalRegister(e, {
                                                                掛號No: Item_掛號.掛號No,
                                                            })}>...更多</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="comfooter">
                                            <div className="meditem">
                                                {Item_掛號.診別 === "初診" &&
                                                <div className="itembox first">初診</div>
                                                }
                                                {Item_掛號.診別 === "預防" &&
                                                <div className="itembox prevention">預防</div>
                                                }
                                            </div>
                                            <div className="funbar">


                                                <Dropdown overlay={<Menu>
                                                    <Menu.Item>
                                                        <a target="_blank" rel="noopener noreferrer"
                                                           onClick={(e) => createModalRegister(e, {
                                                               掛號No: Item_掛號.掛號No,
                                                           })}>
                                                            編輯
                                                        </a>
                                                    </Menu.Item>
                                                    {/*<Menu.Item>*/}
                                                    {/*    <a target="_blank" rel="noopener noreferrer"*/}
                                                    {/*       href="https://www.aliyun.com">*/}
                                                    {/*        取消*/}
                                                    {/*    </a>*/}
                                                    {/*</Menu.Item>*/}
                                                </Menu>}>
                                                    <Button icon={<EllipsisOutlined/>}/>
                                                </Dropdown>
                                                <Button type="primary"
                                                        onClick={Static.NavAwayToRecord(navigate, {No: Item_掛號.寵物No})}>編輯病歷</Button>
                                                <Button type="primary"
                                                        onClick={(e) => handleSubmit_enterreminddone(e, {掛號No: Item_掛號.掛號No})}>完成</Button>
                                                {/*<Button type="primary"*/}
                                                {/*        onClick={(e) => handleSubmit_entercancel(e, {掛號No: Item_掛號.掛號No})}>中止</Button>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>;
                        }

                        if (viewModel.Kind === 'Kind_ReservationList') {
                            return <div className="commoncard" key={Item_掛號.掛號No}>
                                <div className="wrapper">
                                    <div className="colorbar prevention"></div>
                                    <div className="combox">
                                        <div className="titlebar">
                                            <div className="reservationstyle">
                                                {/*<div className="sequence">*/}
                                                {/*    <span*/}
                                                {/*        className="sm">{moment(Item_掛號.ModifiedAt ?? Item_掛號.CreatedAt).format('YYYY')}</span>{moment(Item_掛號.ModifiedAt ?? Item_掛號.CreatedAt).format('MM-DD hh:mm')}*/}
                                                {/*</div>*/}
                                                <div className="sequence">
                                                    <span
                                                        className="sm">預約時間: {moment(Item_掛號.預約日期).format('YYYY-MM-DD')}</span> {moment(Item_掛號.預約時間).format('hh:mm')}
                                                </div>
                                                <div className="stauts">
                                                    <div className="item tel">
                                                        <PhoneOutlined/> 電話
                                                        {Item_掛號.候診狀態}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="comcontent">
                                            <div className="basicdata">
                                                <div className="avatar">
                                                    <div className="photo">
                                                        {Ln_AvatorImg({
                                                            名稱: Item_掛號._寵物.寵物名稱,
                                                            照片組: Item_掛號._寵物.寵物照片組
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="infocol">
                                                    <div className="h4">{Item_掛號._飼主.主人姓名}</div>
                                                    <div className="link">{Item_掛號._飼主.手機 ?? Item_掛號._飼主.聯絡電話}</div>
                                                    <div className="rop">
                                                        <div>{Item_掛號._寵物.種類}</div>
                                                        ．
                                                        <div>{Item_掛號._寵物.寵物名稱}</div>
                                                    </div>

                                                    <div className="medicaldata">
                                                        <div className="h5">
                                                            {指定醫師}
                                                        </div>
                                                        <div className="cop">{Item_掛號.看診原因 ?? Item_掛號.看診描述} <Button
                                                            type="link"
                                                            onClick={(e) => createModalEditReservation(e, {
                                                                掛號No: Item_掛號.掛號No,
                                                            })}>...編輯預約</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="comfooter">
                                            <div className="meditem">
                                                {Item_掛號.診別 === "初診" &&
                                                <div className="itembox first">初診</div>
                                                }
                                                {Item_掛號.診別 === "預防" &&
                                                <div className="itembox prevention">預防</div>
                                                }
                                            </div>
                                            <div className="funbar">


                                                <Dropdown overlay={<Menu>
                                                    <Menu.Item>
                                                        <a target="_blank" rel="noopener noreferrer"
                                                           onClick={(e) => createModalRegister(e, {
                                                               掛號No: Item_掛號.掛號No,
                                                           })}>
                                                            編輯
                                                        </a>
                                                    </Menu.Item>
                                                    {/*<Menu.Item>*/}
                                                    {/*    <a target="_blank" rel="noopener noreferrer"*/}
                                                    {/*       href="https://www.aliyun.com">*/}
                                                    {/*        取消*/}
                                                    {/*    </a>*/}
                                                    {/*</Menu.Item>*/}
                                                </Menu>}>
                                                    <Button icon={<EllipsisOutlined/>}/>
                                                </Dropdown>
                                                <Button type="primary"
                                                        onClick={(e) => handleSubmit_enterwait(e, {掛號No: Item_掛號.掛號No})}>候診</Button>
                                                <Button type="primary"
                                                        onClick={(e) => handleSubmit_entercancel(e, {掛號No: Item_掛號.掛號No})}>中止</Button>
                                                {/*<Button>確認</Button>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>;
                        }

                        if (viewModel.Kind === 'Kind_ReservationUncheckedList') {
                            return <div className="commoncard" key={Item_掛號.掛號No}>
                                <div className="wrapper">
                                    <div className="colorbar prevention"></div>
                                    <div className="combox">
                                        <div className="titlebar">
                                            <div className="reservationstyle">
                                                <div className="sequence">
                                                    <span
                                                        className="sm">{moment(Item_掛號.ModifiedAt ?? Item_掛號.CreatedAt).format('YYYY')}</span>{moment.utc(Item_掛號.ModifiedAt ?? Item_掛號.CreatedAt).format('MM-DD hh:mm')}
                                                </div>
                                                <div className="stauts">
                                                    <div className="item tel">
                                                        <PhoneOutlined/> 電話
                                                        {Item_掛號.候診狀態}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="comcontent">
                                            <div className="basicdata">
                                                <div className="avatar">
                                                    <div className="photo">
                                                        {Ln_AvatorImg({
                                                            名稱: Item_掛號._寵物.寵物名稱,
                                                            照片組: Item_掛號._寵物.寵物照片組
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="infocol">
                                                    <div className="h4">{Item_掛號._飼主.主人姓名}</div>
                                                    <div className="link">{Item_掛號._飼主.手機 ?? Item_掛號._飼主.聯絡電話}</div>
                                                    <div className="rop">
                                                        <div>{Item_掛號._寵物.種類}</div>
                                                        ．
                                                        <div>{Item_掛號._寵物.寵物名稱}</div>
                                                    </div>

                                                    <div className="medicaldata">
                                                        <div className="h5">
                                                            {指定醫師}
                                                        </div>
                                                        <div className="cop">{Item_掛號.看診原因 ?? Item_掛號.看診描述} <Button
                                                            type="link"
                                                            onClick={(e) => createModalRegister(e, {
                                                                掛號No: Item_掛號.掛號No,
                                                            })}>...更多</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="comfooter">
                                            <div className="meditem">
                                                {Item_掛號.診別 === "初診" &&
                                                <div className="itembox first">初診</div>
                                                }
                                                {Item_掛號.診別 === "預防" &&
                                                <div className="itembox prevention">預防</div>
                                                }
                                            </div>
                                            <div className="funbar">


                                                <Dropdown overlay={<Menu>
                                                    <Menu.Item>
                                                        <a target="_blank" rel="noopener noreferrer"
                                                           onClick={(e) => createModalRegister(e, {
                                                               掛號No: Item_掛號.掛號No,
                                                           })}>
                                                            編輯
                                                        </a>
                                                    </Menu.Item>
                                                    {/*<Menu.Item>*/}
                                                    {/*    <a target="_blank" rel="noopener noreferrer"*/}
                                                    {/*       href="https://www.aliyun.com">*/}
                                                    {/*        取消*/}
                                                    {/*    </a>*/}
                                                    {/*</Menu.Item>*/}
                                                </Menu>}>
                                                    <Button icon={<EllipsisOutlined/>}/>
                                                </Dropdown>
                                                <Button type="primary"
                                                        onClick={(e) => handleSubmit_enterconfirmreservation(e, {掛號No: Item_掛號.掛號No})}>確認</Button>
                                                <Button type="primary"
                                                        onClick={(e) => handleSubmit_entercancel(e, {掛號No: Item_掛號.掛號No})}>中止</Button>
                                                {/*<Button>確認</Button>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>;
                        }
                    })}

                    {/* 預約中 - 已確認 */}
                    {/*<div className="commoncard">*/}
                    {/*    <div className="wrapper">*/}
                    {/*        <div className="colorbar prevention"></div>*/}
                    {/*        <div className="combox">*/}
                    {/*            <div className="titlebar">*/}


                    {/*                <div className="reservationstyle">*/}
                    {/*                    <div className="sequence">*/}
                    {/*                        <span className="sm">2021</span>01-07 09:00~09:15*/}
                    {/*                    </div>*/}
                    {/*                    <div className="stauts">*/}
                    {/*                        <div className="item tel">*/}
                    {/*                            <PhoneOutlined/> 電話*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}

                    {/*            </div>*/}
                    {/*            <div className="comcontent">*/}
                    {/*                <div className="basicdata">*/}
                    {/*                    <div className="avatar">*/}
                    {/*                        <div className="photo">*/}
                    {/*                            <img*/}
                    {/*                                src="https://www.northogdencity.com/sites/default/files/imageattachments/police/page/1971/golden-retriever-puppy.jpg"*/}
                    {/*                                alt=""/>*/}
                    {/*                        </div>*/}

                    {/*                    </div>*/}
                    {/*                    <div className="infocol">*/}
                    {/*                        <div className="h4">阿基拉</div>*/}
                    {/*                        <div className="link">0988234765</div>*/}
                    {/*                        <div className="rop">*/}
                    {/*                            <div>貓</div>*/}
                    {/*                            ．*/}
                    {/*                            <div>Kitty</div>*/}
                    {/*                        </div>*/}

                    {/*                        <div className="medicaldata">*/}
                    {/*                            <div className="h5">*/}
                    {/*                                陳朝欽 醫師*/}
                    {/*                            </div>*/}
                    {/*                            <div className="cop">看診標題7個字<Button type="link">..更多</Button>*/}
                    {/*                            </div>*/}

                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}

                    {/*            </div>*/}
                    {/*            <div className="comfooter">*/}
                    {/*                <div className="meditem">*/}
                    {/*                    <div className="itembox prevention">預防</div>*/}
                    {/*                </div>*/}
                    {/*                <div className="funbar">*/}
                    {/*                    <Dropdown overlay={menu}>*/}
                    {/*                        <Button icon={<EllipsisOutlined/>}/>*/}
                    {/*                    </Dropdown>*/}
                    {/*                    <Button type="primary">候診</Button>*/}

                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/* 預約-網路預約模式-尚未確認 */}
                    {/*<div className="commoncard">*/}
                    {/*    <div className="wrapper">*/}
                    {/*        <div className="colorbar prevention"></div>*/}
                    {/*        <div className="combox">*/}
                    {/*            <div className="titlebar">*/}

                    {/*                <div className="reservationstyle">*/}
                    {/*                    <div className="sequence">*/}
                    {/*                        <span className="sm">2021</span>01-07 09:00~09:15*/}
                    {/*                    </div>*/}
                    {/*                    <div className="stauts">*/}
                    {/*                        <div className="item net">*/}
                    {/*                            <GlobalOutlined/> 網路*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}

                    {/*            </div>*/}
                    {/*            <div className="comcontent">*/}
                    {/*                <div className="basicdata">*/}
                    {/*                    <div className="avatar">*/}
                    {/*                        <div className="photo">*/}
                    {/*                            <img*/}
                    {/*                                src="https://www.northogdencity.com/sites/default/files/imageattachments/police/page/1971/golden-retriever-puppy.jpg"*/}
                    {/*                                alt=""/>*/}
                    {/*                        </div>*/}

                    {/*                    </div>*/}
                    {/*                    <div className="infocol">*/}
                    {/*                        <div className="h4">阿基拉</div>*/}
                    {/*                        <div className="link">0988234765</div>*/}
                    {/*                        <div className="rop">*/}
                    {/*                            <div>貓</div>*/}
                    {/*                            ．*/}
                    {/*                            <div>Kitty</div>*/}
                    {/*                        </div>*/}

                    {/*                        <div className="medicaldata">*/}
                    {/*                            <div className="h5">*/}
                    {/*                                陳朝欽 醫師*/}
                    {/*                            </div>*/}
                    {/*                            <div className="cop">看診標題7個字<Button type="link">..更多</Button>*/}
                    {/*                            </div>*/}

                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}

                    {/*            </div>*/}
                    {/*            <div className="comfooter">*/}
                    {/*                <div className="meditem">*/}
                    {/*                    <div className="itembox first">初診</div>*/}
                    {/*                    <div className="itembox prevention">預防</div>*/}
                    {/*                </div>*/}
                    {/*                <div className="funbar">*/}
                    {/*                    <Dropdown overlay={menu}>*/}
                    {/*                        <Button icon={<EllipsisOutlined/>}/>*/}
                    {/*                    </Dropdown>*/}
                    {/*                    <Button>確認</Button>*/}


                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </React.Fragment>;
        }
    };
    //#endregion

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_CommonCard.displayName = 'Partial_CommonCard';
