import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {Form as NForm, Popover, Button, Modal, message, Upload, Input, Select, Space, Tabs, Tooltip, Steps} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';


import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";

import {LoadingOutlined, LockOutlined, PlusOutlined, RedoOutlined, CloseOutlined} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_Appointment__Pane_AddOwner from "./Partial_Appointment__Pane_AddOwner";
import Partial_Search_Or from "./Partial_Search_Or";


var moment = require('moment');
const {Option} = Select;
const {Search} = Input;
const onSearch = value => console.log(value);
const {TabPane} = Tabs;
const {Step} = Steps;

type ViewModelProps = {
    refModal: any,
    carryData?: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,

    viewModel: ViewModelProps,
};
export default function Partial_Appointment__Modal_Preview(props: Props) {
    const PrefixTarget: string = props.PrefixTarget || 'Partial_Appointment__Modal_Preview';
    const pageDataManager: PageDataManager = props.pageDataManager;
    const sessionManager: SessionManager = props.sessionManager;
    const navigate: NavigateFunction = props.navigate;

    const viewModel = props.viewModel;

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region Boot
    const _doLoadPartialData = async (): Promise<void> => {
        if (!pageDataManager.refPageData.current.Root) {
            return;
        }

        /// do not dynamically load data if condition fits.
        if (pageDataManager.isZoneExist(PrefixTarget)) {
            return;
        }
        //await _loadPageData();
    }
    useEffect(() => {
        _doLoadPartialData();
    }, []);
    //#endregion

    const Body = () => {

        // const PassPageDataInitalized = pageDataManager.refPageData.current != null;
        // const PassHasComponentData = pageDataManager.get(`${PrefixTarget}`) !== null;
        // if (!PassPageDataInitalized) {
        //     return null;
        // }
        // if (!PassHasComponentData) {
        //     return null;
        // }
        //
        // const Item_飼主 = pageDataManager.get(`${PrefixTarget}.Item_飼主`);
        // const Item_寵物 = pageDataManager.get(`${PrefixTarget}.Item_寵物`);

        const Item_飼主 = viewModel.carryData.Partial_Appointment__Pane_AddOwner.Item_飼主;
        const Item_寵物 = viewModel.carryData.Partial_addPet.Item_寵物;


        const handleSubmit_saveRecord = (e: any, v: any) => {
            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            //#region Handle SaveUserInfo
            {
                // VALIDATE INPUT
                //const id : any = this.props.match.params.id;
                const p1 = v.Partial_Appointment__Pane_AddOwner.Token;
                if (!p1) {
                    _._debug.error(p1);
                    return; //ERROR INPUTS
                }
                const p2 = v.Partial_addPet.Token;
                if (!p2) {
                    _._debug.error(p1);
                    return; //ERROR INPUTS
                }

                // WAITING SCREEN
                loadingManager.changeLoadingCount(+1);
                //StaticEvent.LoadingEvent.next(true);

                // POST
                let postData = {
                    p1,
                    p2,
                    timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
                };
                const postUrl = ApiService.urlMapping('~/pet_api/1/_pg_appointment/saverecord');
                const post$ = Static.apiService.post(postUrl, postData);
                post$.subscribe(async (res: any) => {
                        hide();

                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);

                        let data = res.data;
                        let error = data.error || null;
                        let result = error == null;

                        if (result) { // SUCEESS
                            let payload = data.payload;
                            _._debug.warn(payload);


                            let hide = message.loading({
                                type: 'loading',
                                className: 'myMessage',
                                content: <div className="myMessageBody">建立成功</div>,
                                key: 'loading',
                                duration: 5
                            });

                            handleClose(null);


                            Static.NavAwayToAppointment(navigate, {
                                s: '手機號碼',
                                q: payload.Item_飼主.手機,
                            })(null);

                            // let carryData = _._u.update(payload,
                            //     {
                            //         $merge: {
                            //             Tokens: payload?.Tokens,
                            //         },
                            //         Item_飼主: {
                            //             $set: payload?.Item_飼主,
                            //         }
                            //     }
                            // );
                            // carryData.Item_飼主 = p1;
                            // if (onDone) {
                            //     const ret = onDone(carryData);
                            //     if (ret === 'demo') {
                            //         createModalPet(null, {
                            //             carryData,
                            //         })
                            //     }
                            // } else {
                            //     alert('onDone not defined');
                            // }

                            //handleClose(null)

                        } else {
                            // AUTH USER EXPIRED
                            if (error.code == 2) {
                                //alert('帳號被登出，請重新登入');
                                //window.location.href = '/login';
                                //this.props.history.push('/login')
                                await sessionManager.setLogout();
                                pageDataManager.clear();
                                pageDataManager.reloadAsync({alwaysLoad: true});
                                alert('時間逾時已被登出');
                                return;
                            }

                            _._debug.error(error);
                            alert(error.message);
                        }
                    }, async (err) => {
                        hide();

                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);
                        _._debug.warn(err);
                        alert('網路連線有問題');
                    }
                );
                return lastValueFrom(
                    post$
                );
            }
            //#endregion
        }

        const handleClose = (e) => {
            if (e) {
                e.preventDefault();
            }

            if (viewModel.refModal || viewModel.refModal.current) {
                viewModel.refModal.current.destroy();
            }
        };

        return (
            <div className="model_wrapper">
                <div className="modal_title">
                    <div className="title_info">
                        <h2>新建病歷</h2>
                    </div>
                    <div className="info_bar"></div>
                    <div className="control_bar">
                        <Tooltip placement="top" title={'關閉'}>
                            <Button type="text" icon={<CloseOutlined/>} onClick={handleClose}/>
                        </Tooltip>
                    </div>
                </div>
                <div className="modal_content">
                    <Steps current={2}>
                        <Step title="選擇飼主"/>
                        <Step title="建立寵物"/>
                        <Step title="預覽資料"/>
                    </Steps>
                    <div className="content_box">
                        <div className="tablelist">
                            <div className="tg">
                                <div className="th">飼主姓名：</div>
                                <div className="td">{Item_飼主?.主人姓名 ?? 'ERR'}</div>
                            </div>
                            <div className="tg">
                                <div className="th">聯絡手機：</div>
                                <div className="td">{Item_飼主?.手機 ?? 'ERR'}</div>
                            </div>
                            <div className="tg">
                                <div className="th">寵物名稱：</div>
                                <div className="td">{Item_寵物?.寵物名稱 ?? 'ERR'}</div>
                            </div>
                            <div className="tg">
                                <div className="th">種類：</div>
                                <div className="td">{Item_寵物?.種類 ?? 'ERR'}</div>
                            </div>
                            <div className="tg">
                                <div className="th">性別：</div>
                                <div className="td">{Item_寵物?.性別 ?? 'ERR'}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal_footer">
                    <React.Fragment>
                        <NForm.Item>
                            <div className="form_footer">
                                {/*<Button size="large">上一步</Button>*/}
                                <Button type="primary" htmlType="submit" size="large"
                                        onClick={(e) => handleSubmit_saveRecord(e, viewModel.carryData)}>建立</Button>

                            </div>
                        </NForm.Item>
                    </React.Fragment>
                </div>
            </div>
        );
    };

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_Appointment__Modal_Preview.displayName = 'Partial_Appointment__Modal_Preview';
