import React, {Component} from 'react';
import {useState, useEffect} from 'react';
import {Waypoint} from 'react-waypoint';
import {postTimeDisplayFactory} from '../utils/time';
import _ from "../_";
import {ApiService} from "../_/ApiService";

type Props = {
    照片組?: string;

    //#region Option: Generate DefaultImage (Pick-One)
    名稱?: string;
    DefaultImage?: string;
    //#endregion

    testMode?: boolean,
};

export default function Ln_AvatorImg(props: Props) {
    const param_照片組 = props['照片組'] || '';
    const param_名稱 = props['名稱'];
    const param_DefaultImage = props['DefaultImage'];
    const param_TestMode = props['testMode'] || false;

    let firstImg = _.bs.getCoverSafeList(param_照片組)[0];

    if (param_TestMode) {
        return <React.Fragment>
                <img src={`https://ui-avatars.com/api/?background=bfe6ea&name=${encodeURIComponent(param_名稱 || '')}`}/>
        </React.Fragment>;
    }

    if (firstImg == null) {
        if (param_DefaultImage == null && param_名稱 == null) {
            return;
        }

        if (param_DefaultImage) {
            return <React.Fragment>
                    <img src={param_DefaultImage}/>
            </React.Fragment>;
        } else {
            return <React.Fragment>
                    <img src={`https://ui-avatars.com/api/?background=bfe6ea&name=${encodeURIComponent(param_名稱 || '')}`}/>
            </React.Fragment>;
            ;
        }
    } else {
        return <React.Fragment>
                <img src={ApiService.urlImageMapping("~/" + firstImg)}/>
        </React.Fragment>;
    }
}
Ln_AvatorImg.displayName = 'Ln_AvatorImg';
