import React, {useEffect, useRef} from 'react';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import {
    Form, Input, Button, Alert, Checkbox, Result, message, Space,
    Modal, Typography, Tabs, Select, Popconfirm, Table, Empty, Spin, DatePicker, Menu, Dropdown, Tooltip
} from 'antd';
import {useApp} from "../overmind";
import {UserInfo} from "../global";
import {Static} from "../_/Static";
import {ApiService} from "../_/ApiService";
import _ from "../_";
import {
    ReloadOutlined,
    EyeTwoTone,
    UserOutlined,
    LockOutlined,
    RedoOutlined,
    LeftOutlined,
    RightOutlined,
    PrinterOutlined,
    RollbackOutlined,
    ArrowLeftOutlined, LoadingOutlined
} from '@ant-design/icons';

import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import UsePageDataManager from '../hooks/UsePageDataManager';
import UseSessionManager from "../hooks/UseSessionManager";
import UseLoadingManager from "../hooks/UseLoadingManager";
import UseCompState from "../hooks/UseCompState";
import {lastValueFrom} from "rxjs";
import Partial_MedicalRecord__Modal_Prescribe2 from "./Partial_MedicalRecord__Modal_Prescribe2";
import Partial_MedicalRecord__Modal_Prescribe from "./Partial_MedicalRecord__Modal_Prescribe";
import Partial_MedicalRecord__Modal_ProcessBilling from "./Partial_MedicalRecord__Modal_ProcessBilling";


import './Pg_Waitingroom.scss';
import FunctionBtn from './Box_Functionbtn';
import moment from 'moment';
import Partial_MedicalRecord__Tab_BasicData from "./Partial_MedicalRecord__Tab_BasicData";
import Partial_MedicalRecord__Tab_Treatment from "./Partial_MedicalRecord__Tab_Treatment";
import Partial_Waitingroom__Tab_WaitingList from "./Partial_Waitingroom__Tab_WaitingList";
import Partial_Charge__Tab_ChargeList from "./Partial_Charge__Tab_ChargeList";
import Partial_Charge__Tab_ChargeFinishedList from "./Partial_Charge__Tab_ChargeFinishedList";

const iconLighting = require('../assets/images/icon/lighting.svg');
const iconPrice = require('../assets/images/icon/price.svg');

const onSearch = value => console.log(value);
const {Title} = Typography;
const {TabPane} = Tabs;
const {Search} = Input;
const {Option} = Select;
const dateFormat = 'YYYY/MM/DD';
const {RangePicker} = DatePicker;


type Props = {
    PrefixTarget?: string;
    RoutePathname?: string;
    MenuTemplateId?: string;
    parentState?: any;
};
export default function Pg_Charge(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Root';
    let RoutePathname = props.RoutePathname;
    let MenuTemplateId = props.MenuTemplateId;

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    //#region LoadingManager
    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: true});
    //#endregion

//#region PageDataManager
    const refDoLoadPageData = Static.refDoLoadPageData;
    let pageDataManager = UsePageDataManager({
        refPageLoad: refDoLoadPageData
    });
    //#endregion

    //#region SessionManager
    let sessionManager = UseSessionManager({
        pageDataManager: pageDataManager,
    });
    //#endregion

    //#region Boot
    const state = UseCompState({parentState: props.parentState});

    let defaultS = '全部';
    let defaultQ = '';

    if (!state.hasInit) {
        state.hasInit = true;

        state.pageDataManager = pageDataManager;
        state.sessionManager = sessionManager;
        state.loadingManager = loadingManager;
        state.navigate = navigate;
        state.forceRender = forceRender;

        state.subscribed = [];

        state.s = pageDataManager.get(`${PrefixTarget}.s`) ?? defaultS;
        state.q = pageDataManager.get(`${PrefixTarget}.q`) ?? defaultQ;
    }

    useEffect(() => {
        const _loadPageData = async (): Promise<void> => {
            let hide = message.loading({
                type: 'loading',
                class: 'myMessage',
                content: <div className="myMessageBody">搜尋中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            //const id : any = this.props.match.params.id;
            // const id = 1;
            // if (!id) {
            //     return; //ERROR INPUTS
            // }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                //p1: 1,
                s: state.s,
                q: state.q,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/pg_charge');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        pageDataManager.merge({
                            ...payload,
                        });
                        pageDataManager.apply();

                        pageDataManager.forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPageData = async ({alwaysLoad = false}): Promise<void> => {
            if (!alwaysLoad) {
                pageDataManager.restore();
                if (!pageDataManager.isZoneExist(PrefixTarget)) {
                    await sessionManager.initSessionAuthAsync();
                    //#region User Check //TODO: VERTIFY
                    if (!sessionManager.isMemberLoggedIn()) {
                        Static.NavAwayToLogin(pageDataManager, navigate)(null);
                        return;
                    }
                    //#endregion
                    await _loadPageData();
                } else {
                    forceRender()
                }
            } else {
                await _loadPageData();
            }
        }

        refDoLoadPageData.current = doLoadPageData;
        refDoLoadPageData.current({});
    }, [location.pathname]);
    //#endregion

    const iconWaitforCase = require('../assets/images/icon/nocase.svg');
    const [noCase, setNoCase] = useState(false);

    function callback(key) {
        console.log(key);
    }

    function confirm(e) {
        console.log(e);
    }

    function cancel(e) {
        console.log(e)
    }


    //#region :Popup :createModalPrescribeRecord
    const createModalPrescribeRecord_refModal = useRef<any>(null);
    const createModalPrescribeRecord = (e: any, tasks?: string[]): void => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_MedicalRecord__Modal_Prescribe2
                PrefixTarget={'Partial_MedicalRecord__Modal_Prescribe2'}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModalPrescribeRecord_refModal,
                }}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '80em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModalPrescribeRecord_refModal.current = Modal.info(config);
    }
    //#endregion


    //#region :Popup :createModalCharge
    const createModalCharge_refModal = useRef<any>(null);
    const createModalCharge = (e: any, tasks?: string[]): void => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_MedicalRecord__Modal_ProcessBilling
                PrefixTarget={'Partial_MedicalRecord__Modal_ProcessBilling'}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModalCharge_refModal,

                    病歷No: null,
                    掛號No: null,

                    OnDid: () => {
                        pageDataManager.clear();
                        pageDataManager.reloadAsync({alwaysLoad: true});
                        //state._refDoLoadPartialData?.current?.({alwaysLoad: true});
                    }
                }}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '120em',
            height: ' ',
            top: '20px',
            footer: null,
            //prefixCls: '',
        };

        createModalCharge_refModal.current = Modal.info(config);
    }

    //#endregion


    interface PetItem {
        key: React.Key;
        date: string;
        petname: string;
        owner: string;
        phone: string;
        department: string;
        totalAmount: string;
        status: string;
    }

    const petlist = [
        {
            title: '批價時間',
            dataIndex: 'date',
        },
        {
            title: '寵物',
            dataIndex: 'petname',
            render: (text: string) => <a>{text}</a>,
        },
        {
            title: '飼主',
            dataIndex: 'owner',
        },
        {
            title: '聯絡電話',
            dataIndex: 'phone',
        },
        {
            title: '批價部門',
            dataIndex: 'department',
        },
        {
            title: '總金額',
            dataIndex: 'totalAmount',
        },
        {
            title: '狀態',
            dataIndex: 'status',
        },
        {
            title: '執行',
            dataIndex: ' action',
            render: (text, record) => {

                return <div>
                    <Space>
                        <Button className="quick-order" onClick={createModalCharge}
                        >確認</Button>
                        <Button className="quick-order" type="primary" onClick={createModalCharge}
                        >結帳</Button>
                    </Space>
                </div>
            },
        },
        // {
        //   title: '功能',
        //   dataIndex: ' function',
        //   render: (text, record) => {

        //     return <div>
        //       <Space>
        //         <Tooltip placement="top" title="退回重開">
        //           <Button danger icon={<RollbackOutlined />} type="text"/>
        //         </Tooltip>
        //       </Space>


        //     </div>
        //   },
        //},
    ];

    const petData: PetItem[] = [
        {
            key: 1,
            date: '2020/10/28 15:17',
            petname: 'Lucky',
            owner: 'Park',
            phone: '0900168168',
            department: '陳光大 醫師',
            totalAmount: '$1,700',
            status: '未確認',
        }
    ];

    const OperationsSlot = {
        right: <div className="dotor-filter">
            診療醫師:
            <Select
                showSearch
                allowClear={true}
                style={{width: 140}}
                placeholder="不指定"
            >
                <Option value='林峯權 醫師'>林峯權 醫師</Option>
                <Option value='李一封 醫師'>李一封 醫師</Option>
                <Option value='袁和平 醫師'>袁和平 醫師</Option>
            </Select>
        </div>
    };

    interface oldPetItem {
        key: React.Key;
        date: string;
        actiondate: string;
        petname: string;
        owner: string;
        phone: string;
        department: string;
        totalAmount: string;
    }


    const oldpetlist = [
        {
            title: '批價時間',
            dataIndex: 'date',
        },
        {
            title: '結帳時間',
            dataIndex: 'actiondate',
        },
        {
            title: '寵物',
            dataIndex: 'petname',
            render: (text: string) => <a>{text}</a>,
        },
        {
            title: '飼主',
            dataIndex: 'owner',
        },
        {
            title: '聯絡電話',
            dataIndex: 'phone',
        },
        {
            title: '批價部門',
            dataIndex: 'department',
        },
        {
            title: '總金額',
            dataIndex: 'totalAmount',
        },
        {
            title: '檢視',
            dataIndex: 'view',
            render: (text, record) => {

                return <div>
                    <Space>
                        <Button type="link" onClick={createModalCharge}>詳情</Button>
                    </Space>
                </div>
            },
        },
        // {
        //   title: '功能',
        //   dataIndex: ' function',
        //   render: (text, record) => {

        //     return <div>
        //       <Space>
        //         <Tooltip placement="top" title="轉成未結批價">
        //           <Button danger icon={<ArrowLeftOutlined />} type="text"/>
        //         </Tooltip>
        //       </Space>
        //     </div>
        //   },
        // },
    ];

    const oldpetData: oldPetItem[] = [
        {
            key: 1,
            date: '2020/10/28 15:17',
            actiondate: '2020/10/28 15:32',
            petname: 'Butterfly',
            owner: '羅志祥',
            phone: '0921134533',
            department: '售貨部',
            totalAmount: '$400',
        }
    ];

    //#region Set Body
    const Body = () => {
        const RegionEmptyShow = false;
        const RegionLoadingShow = true;
        const RegionInitialLoadingShow = false;

        const PassPageDataInitalized = pageDataManager.refPageData.current != null;
        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;

        if (!PassPrefixTargetLoaded) {
            if (RegionInitialLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <div>Loading.</div>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        if (loadingManager.getIsLoading()) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <Spin indicator={
                            <LoadingOutlined style={{fontSize: 24}} spin/>
                        }/>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        //#region Handlers
        //#endregion

        return (
            <div className="page-appointment page-content">
                <div className="info-list">
                    <div className="info-box">
                        <Title level={2}>收費出納</Title>
                        <div className="pluginfobox">
                            <div className="title">
                                <Tooltip title="刷新">
                                    <Button icon={<ReloadOutlined/>} shape="circle" onClick={(e) => {
                                        ///reload
                                        if (e) {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }
                                        pageDataManager.clear();
                                        pageDataManager.reloadAsync({alwaysLoad: true});
                                    }}/>
                                </Tooltip>
                            </div>
                            <div className="info"></div>
                        </div>

                        <div className="pluginfobox">
                            <div className="title"></div>
                            <div className="info"></div>
                        </div>

                    </div>
                    <div className="function-box">
                        {/*<button onClick={createModalCharge}>*/}
                        {/*    <FunctionBtn iconlink={iconPrice} name="新增批價"/>*/}
                        {/*</button>*/}

                    </div>
                </div>


                <div className="page-waitingroom">
                    <Tabs type="card">
                        <TabPane tab="未結價批" key="1">
                            <Partial_Charge__Tab_ChargeList
                                PrefixTarget={'Partial_Charge__Tab_ChargeList'}
                                pageDataManager={pageDataManager}
                                sessionManager={sessionManager}
                                navigate={navigate}

                                viewModel={{
                                    refModal: null,

                                    OnDid: () => {
                                        pageDataManager.clear();
                                        pageDataManager.reloadAsync({alwaysLoad: true});
                                    }
                                }}
                            />
                            {/*<div className="patient-list">*/}
                            {/*    <div className="schedulesearch">*/}
                            {/*        <Space>*/}
                            {/*            <Button>全部</Button>*/}
                            {/*            <RangePicker*/}
                            {/*                ranges={{*/}
                            {/*                    Today: [moment(), moment()],*/}
                            {/*                    'This Month': [moment().startOf('month'), moment().endOf('month')],*/}
                            {/*                }}*/}
                            {/*                placeholder={["開始日期", "結束日期"]}*/}

                            {/*            />*/}
                            {/*            <Select placeholder="請選擇指定醫師" defaultValue="不指定">*/}
                            {/*                <Option value="不指定">不指定醫師</Option>*/}
                            {/*                <Option value="林志良">林志良 醫師</Option>*/}
                            {/*                <Option value="張黎光">張黎光 醫師</Option>*/}
                            {/*                <Option value="陳光大">陳光大 醫師</Option>*/}
                            {/*                <Option value="藥局">藥局</Option>*/}
                            {/*                <Option value="售貨部">售貨部</Option>*/}
                            {/*            </Select>*/}
                            {/*        </Space>*/}
                            {/*    </div>*/}
                            {/*    <Table*/}
                            {/*        columns={petlist}*/}
                            {/*        dataSource={petData}*/}
                            {/*    />*/}
                            {/*    <Spin>*/}
                            {/*        <div className="patient-box">*/}

                            {/*        </div>*/}
                            {/*        <div className="nocase">*/}
                            {/*            <img src={iconWaitforCase} alt="coffee"></img>*/}
                            {/*            <p className="nocase-title">沒有搜尋條件所要的資料</p>*/}
                            {/*            <p className="nocase-description">請重新設定條件搜尋</p>*/}
                            {/*        </div>*/}
                            {/*    </Spin>*/}
                            {/*</div>*/}
                        </TabPane>
                        <TabPane tab="已結批價" key="2">
                            <Partial_Charge__Tab_ChargeFinishedList
                                PrefixTarget={'Partial_Charge__Tab_ChargeFinishedList'}
                                pageDataManager={pageDataManager}
                                sessionManager={sessionManager}
                                navigate={navigate}

                                viewModel={{
                                    refModal: null,
                                }}
                            />
                            {/*<div className="patient-list">*/}
                            {/*    <div className="schedulesearch">*/}
                            {/*        <Space>*/}
                            {/*            <Button>全部</Button>*/}
                            {/*            <RangePicker*/}
                            {/*                ranges={{*/}
                            {/*                    Today: [moment(), moment()],*/}
                            {/*                    'This Month': [moment().startOf('month'), moment().endOf('month')],*/}
                            {/*                }}*/}
                            {/*                placeholder={["開始日期", "結束日期"]}*/}

                            {/*            />*/}
                            {/*            <Select placeholder="請選擇指定醫師" defaultValue="不指定">*/}
                            {/*                <Option value="不指定">不指定醫師</Option>*/}
                            {/*                <Option value="林志良">林志良 醫師</Option>*/}
                            {/*                <Option value="張黎光">張黎光 醫師</Option>*/}
                            {/*                <Option value="陳光大">陳光大 醫師</Option>*/}
                            {/*                <Option value="藥局">藥局</Option>*/}
                            {/*                <Option value="售貨部">售貨部</Option>*/}
                            {/*            </Select>*/}
                            {/*        </Space>*/}
                            {/*    </div>*/}
                            {/*    <Table*/}
                            {/*        columns={oldpetlist}*/}
                            {/*        dataSource={oldpetData}*/}
                            {/*    />*/}
                            {/*    <Spin>*/}
                            {/*        <div className="patient-box">*/}

                            {/*        </div>*/}
                            {/*        <div className="nocase">*/}
                            {/*            <img src={iconWaitforCase} alt="coffee"></img>*/}
                            {/*            <p className="nocase-title">沒有搜尋條件所要的資料</p>*/}
                            {/*            <p className="nocase-description">請重新設定條件搜尋</p>*/}
                            {/*        </div>*/}
                            {/*    </Spin>*/}
                            {/*</div>*/}
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        )
    };
    //#endregion

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}

Pg_Charge.displayName = 'Pg_Charge';




























