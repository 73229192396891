import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {
    Form as NForm,
    Popover,
    Button,
    Modal,
    message,
    Tag,
    Input,
    Select,
    Space,
    Tabs,
    Tooltip,
    Steps,
    Popconfirm,
    Upload, DatePicker
} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';


import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";

import {
    LoadingOutlined,
    LockOutlined,
    PlusOutlined,
    RedoOutlined,
    CloseOutlined,
    UploadOutlined
} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_addOwnerProxy from "./Partial_addOwnerProxy";
import Partial_Manage_Campaign__Modal_EditOrAddCampaign from "./Partial_Manage_Campaign__Modal_EditOrAddCampaign";
import Ln_CommaListRender from "./Ln_CommaListRender";


var moment = require('moment');
const {Option} = Select;
const {Search} = Input;
const onSearch = value => console.log(value);
const {TabPane} = Tabs;
const {Step} = Steps;

type ViewModelProps = {
    refModal: any,

    票劵No: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,

    viewModel: ViewModelProps,
};
export default function Partial_Manage_Campaign__Modal_ViewCampaign(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_Manage_Campaign__Modal_ViewCampaign';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel: any = props.viewModel;

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region Boot
    let _refDoLoadPartialData: any = useRef<{ alwaysLoad?: boolean }>(null);
    useEffect(() => {
        const _loadPartialData = async (): Promise<void> => {

            // VALIDATE INPUT
            const p1 = viewModel.票劵No;
            if (!p1) {
                alert('p1')
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/partial_manage_campaign/modal_viewCampaign');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        //delete pageDataManager.refPageData.current[TabControlState]?.['MyCollectionTab_data'];

                        pageDataManager.emptyZone(PrefixTarget);
                        let changeShard = {
                            [PrefixTarget]: {
                                ...payload,
                            }
                        }
                        pageDataManager.merge(changeShard);
                        pageDataManager.apply();

                        forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPartialData = async ({alwaysLoad = false}): Promise<void> => {
            // if (!pageDataManager.refPageData.current.Root) {
            //     return;
            // }

            /// do not dynamically load data if condition fits.
            if (!alwaysLoad) {
                if (pageDataManager.isZoneExist(PrefixTarget)) {
                    return;
                }
            }

            // if (pageDataManager.refPageData.current[TabControlState].activeTabName === 'RecentReadTab') {
            //     await _loadRecentReadTabData();
            // }

            if (viewModel.票劵No) {
                await _loadPartialData();
            }
        }


        if (_refDoLoadPartialData.current === null) {
            _refDoLoadPartialData.current = doLoadPartialData;
            _refDoLoadPartialData.current({});
        } else {
            _refDoLoadPartialData.current({});
        }


    }, [pageDataManager.get(PrefixTarget)]);
    //#endregion

    //#region Set Body
    const Body = () => {
        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            if (!values.username) {
                errors.username = '↑ 欄位為必填';
            } else {
            }

            if (!values.password) {
                errors.password = '↑ 欄位為必填';
            } else {
            }

            return errors;
        };

        const handleClose = (e) => {
            if (e) {
                e.preventDefault();
            }

            if (viewModel.refModal || viewModel.refModal.current) {
                viewModel.refModal.current.destroy();
            }
        };

        const handleSubmit_delete = (e: any, 票劵No: any) => {
            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            const p1 = 票劵No;
            if (!p1) {
                alert(p1);
                _._debug.error(p1);
                return; //ERROR INPUTS
            }


            // let pictures = [] as any;
            // state.files.map((v, i) => {
            //     console.log(v)
            //     pictures.push(v.id);
            // });
            // const p2 = pictures.join(',');
            // if (!p2) {
            //     _.debug.error(p1);
            //     return; //ERROR INPUTS
            // }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/_pg_manage_campaign/delete');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        let hide = message.loading({
                            type: 'loading',
                            className: 'myMessage',
                            content: <div className="myMessageBody">修改完成</div>,
                            key: 'loading',
                            duration: 2
                        });


                        // pageDataManager.emptyZone(PrefixTarget);
                        // let changeShard = {
                        //     [PrefixTarget]: {
                        //         ...payload,
                        //     }
                        // }
                        // pageDataManager.merge(changeShard);
                        // pageDataManager.apply();

                        StaticEvent.castUpdateEvent({
                            command: StaticEvent.UpdateFacade.Command.Remove_票劵,
                            Remove_票劵: {
                                票劵No: payload.Item_票劵.票劵No,
                            }
                        });

                        handleClose(null);


                        //_refDoLoadPartialData.current({alwaysLoad: true});

                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }

        const handleSubmit_claim = (e: any, 票劵No: any) => {
            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            const p1 = 票劵No;
            if (!p1) {
                alert(p1);
                _._debug.error(p1);
                return; //ERROR INPUTS
            }


            // let pictures = [] as any;
            // state.files.map((v, i) => {
            //     console.log(v)
            //     pictures.push(v.id);
            // });
            // const p2 = pictures.join(',');
            // if (!p2) {
            //     _.debug.error(p1);
            //     return; //ERROR INPUTS
            // }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/_pg_manage_campaign/claim');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        let hide = message.loading({
                            type: 'loading',
                            className: 'myMessage',
                            content: <div className="myMessageBody">修改完成</div>,
                            key: 'loading',
                            duration: 2
                        });


                        // pageDataManager.emptyZone(PrefixTarget);
                        // let changeShard = {
                        //     [PrefixTarget]: {
                        //         ...payload,
                        //     }
                        // }
                        // pageDataManager.merge(changeShard);
                        // pageDataManager.apply();

                        StaticEvent.castUpdateEvent({
                            command: StaticEvent.UpdateFacade.Command.Remove_票劵,
                            Remove_票劵: {
                                票劵No: payload.Item_票劵.票劵No,
                            }
                        });

                        handleClose(null);


                        //_refDoLoadPartialData.current({alwaysLoad: true});

                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }

        function confirm(e) {
            console.log(e);
        }

        function cancel(e) {
            console.log(e)
        }


        const RegionEmptyShow = true;
        const RegionLoadingShow = true;

        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;

        let Item_票劵 = pageDataManager.get(`${PrefixTarget}.Item_票劵`);

        let its: any;
        if (Item_票劵 === null) {
            its = [null];
        } else if (Item_票劵 !== undefined) {
            its = [Item_票劵];
        }

        if (!PassPrefixTargetLoaded) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <div>Loading.</div>
                    </div>
                </React.Fragment>;
            }
            else {
                return null;
            }
        }

        if (its && its.length > 0) {
            return <React.Fragment>
                {its.map(Item_票劵 => {
                    return <React.Fragment key={Item_票劵?.票劵No ?? "0"}>
                        <div className="model_wrapper">
                            <div className="modal_title">
                                <div className="title_info">
                                    <h2>檢視優惠票券</h2>
                                </div>
                                <div className="info_bar"></div>
                                <div className="control_bar">
                                    <Tooltip placement="top" title={'關閉'}>
                                        <Button type="text" icon={<CloseOutlined/>} onClick={handleClose}/>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="modal_content">
                                <div className="content_box">
                                    <div className="boxcontent">
                                        <div className="systable col_4">
                                            <div className="str cops">
                                                <div className="th">活動名稱</div>
                                                <div className="td">{Item_票劵.活動名稱}</div>
                                            </div>
                                            <div className="str cops">
                                                <div className="th">兌換序號</div>
                                                <div className="td">{Item_票劵.兌換序號}</div>
                                            </div>
                                            <div className="str cops">
                                                <div className="th">標籤</div>
                                                <div className="td">
                                                    {Ln_CommaListRender({
                                                        照片組: Item_票劵.Tag,
                                                        render: (tag) => (
                                                            <Tag color="orange" key={tag}>{tag}</Tag>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            <div className="str cops">
                                                <div className="th">到期日</div>
                                                <div
                                                    className="td">{Item_票劵.到期日 ? moment(Item_票劵.到期日).format('YYYY-MM-DD') : '未定'}</div>
                                            </div>
                                            <div className="str cops">
                                                <div className="th">核銷時間</div>
                                                <div
                                                    className="td">{Item_票劵.核銷時間 ? moment(Item_票劵.核銷時間).format('YYYY-MM-DD hh:mm') : '未定'}</div>
                                            </div>
                                            <div className="str cops">
                                                <div className="th">注意事項</div>
                                                <div className="td">{Item_票劵.注意事項}</div>
                                            </div>
                                            <div className="str cops">
                                                <div className="th">說明</div>
                                                <div className="td">{Item_票劵.說明}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal_footer">
                                <Space>
                                    <Popconfirm
                                        title="確認要刪除此優惠卷?"
                                        onConfirm={(e)=>{handleSubmit_delete(e, Item_票劵.票劵No)}}
                                        onCancel={cancel}
                                        okText="確認"
                                        cancelText="取消"
                                    >
                                        <Button danger>刪除</Button>
                                    </Popconfirm>
                                    <Popconfirm
                                        title="該序號為'票券名稱'確認是否核銷"
                                        onConfirm={(e)=>{handleSubmit_claim(e, Item_票劵.票劵No)}}
                                        onCancel={cancel}
                                        okText="確認"
                                        cancelText="取消"
                                    >
                                        <Button>核銷</Button>
                                    </Popconfirm>
                                </Space>
                            </div>
                        </div>
                    </React.Fragment>
                })}
            </React.Fragment>;
        } else if (its && its.length === 0) {
            if (RegionEmptyShow) {
                return <React.Fragment>
                    <div>Empty.</div>
                </React.Fragment>;
            }
        }

    };
    //#endregion

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_Manage_Campaign__Modal_ViewCampaign.displayName = 'Partial_Manage_Campaign__Modal_ViewCampaign';
