import React, {CSSProperties, useCallback, useEffect, useState} from "react";
import styles from "./ImageViewer.module.scss";

interface IProps {
    src: string[];
    currentIndex?: number;
    backgroundStyle?: CSSProperties;
    onClose?: () => void;
}

const ImageViewer = (props: IProps) => {
    const [currentIndex, setCurrentIndex] = useState(props.currentIndex ?? 0);

    const changeImage = useCallback(
        (delta: number) => {
            let nextIndex = (currentIndex + delta) % props.src.length;
            if (nextIndex < 0) nextIndex = props.src.length - 1;
            setCurrentIndex(nextIndex);
        },
        [currentIndex]
    );

    const handleClick = useCallback(
        (event: any) => {
            if (event.target && event.target.id === "ImageViewer") {
                props.onClose?.();
            }
        },
        [props.onClose]
    );

    const handleKeyDown = useCallback(
        (event: any) => {
            if (event.key === "Escape") {
                props.onClose?.();
            }

            if (["ArrowLeft", "h"].includes(event.key)) {
                changeImage(-1);
            }

            if (["ArrowRight", "l"].includes(event.key)) {
                changeImage(1);
            }
        },
        [props.onClose, changeImage]
    );

    const handleWheel = useCallback(
        (event: any) => {
            if (event.wheelDeltaY > 0) {
                changeImage(-1);
            } else {
                changeImage(1);
            }
        },
        [changeImage]
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        document.addEventListener("wheel", handleWheel);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("wheel", handleWheel);
        };
    }, [handleKeyDown, handleWheel]);

    return (
        <div
            id="ImageViewer"
            className={`${styles.wrapper} imageviewer__modal`}
            onKeyDown={handleKeyDown}
            onClick={handleClick}
            style={props.backgroundStyle}
        >
      <span
          className={`${styles.close} imageviewer__close`}
          onClick={() => props.onClose?.()}
      >
        &times;
      </span>

            {props.src.length > 1 && (
                <span
                    className={`${styles.navigation} ${styles.prev} imageviewer__previous`}
                    onClick={() => changeImage(-1)}
                >
          &#10094;
        </span>
            )}

            {props.src.length > 1 && (
                <span
                    className={`${styles.navigation} ${styles.next} imageviewer__next`}
                    onClick={() => changeImage(1)}
                >
          &#10095;
        </span>
            )}

            <div
                className={`${styles.content} imageviewer__modal-content`}
            >
                <div className={`${styles.slide} imageviewer__slide`}>
                    <img className={styles.image} src={props.src[currentIndex]} alt=""/>
                </div>
            </div>
        </div>
    );
};

export default ImageViewer;
