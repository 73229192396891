import React, { useCallback, useEffect, useRef, useState } from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import { Form as NForm, Popover, Button, Modal, message, Upload, Input, Space, Select, Collapse, DatePicker } from 'antd';

import { ApiService } from "../_/ApiService";
import { Static } from "../_/Static";
import { StaticEvent } from "../_/StaticEvent";
import _ from "../_";

import { Link } from 'react-router-dom';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import { PageDataManager } from "../hooks/UsePageDataManager";

import { Field, Form } from "react-final-form";
import { SessionManager } from "../hooks/UseSessionManager";
import { NavigateFunction } from "react-router";

import { LoadingOutlined, LockOutlined, PlusOutlined, RedoOutlined, UserOutlined, EditOutlined, PlusCircleOutlined, DeleteFilled } from "@ant-design/icons";
import { lastValueFrom } from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_Appointment__Modal_AddPet from "./Partial_Appointment__Modal_AddPet";

var moment = require('moment');
const { Option } = Select;
const { Panel } = Collapse;
const dateFormat = 'YYYY/MM/DD';

const { TextArea } = Input;

type ViewModelProps = {
    refModal: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,

    viewModel: ViewModelProps,
};
export default function Partial_ScheduleTable(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_ScheduleTable';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };

    const layout02 = {
        labelCol: { span: 0 },
        wrapperCol: { span: 25 },
    };

    //#region :Popup :createModalLogin
    const createModalPet_refModal = useRef<any>(null);
    const createModalPet = (e: any, tasks?: string[]): void => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_Appointment__Modal_AddPet
                PrefixTarget={'Partial_Appointment__Modal_AddPet'}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModalPet_refModal,
                }}
            />,
            okButtonProps: { disabled: true, className: "modal-footer-hiden-button" },
            className: 'myClass myModal',
            width: '60em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModalPet_refModal.current = Modal.info(config);
    }

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({ shouldReRenderOnLoading: false });

    //#region Boot
    const _doLoadPartialData = async (): Promise<void> => {
        if (!pageDataManager.refPageData.current.Root) {
            return;
        }

        /// do not dynamically load data if condition fits.
        if (pageDataManager.isZoneExist(PrefixTarget)) {
            return;
        }
        //await _loadPageData();
    }
    useEffect(() => {
        _doLoadPartialData();
    }, []);
    //#endregion


    const Body = () => {

        const popcontent = (
            <div >
                <div className="popbox">
                    <div className="infobar">
                        <div className="time">09:00~12:00</div>
                        <div className="count">預約人數：5</div>
                    </div>
                    <div className="poplist">
                        <div className="li">
                            <div className="time">09:00~09:15</div>
                            <div className="ntxt">
                                <div className="tp">Mibo</div>
                                <div className="scr">陳孝文</div>
                            </div>
                            <div className="tag prevention">預防</div>
                        </div>
                        <div className="li">
                            <div className="time">09:15~09:30</div>
                            <div className="ntxt">
                                <div className="tp">米來旺</div>
                                <div className="scr">林宜君</div>
                            </div>
                            <div className="tag prevention">預防</div>
                        </div>
                        <div className="li">
                            <div className="time">09:30~09:45</div>
                            <div className="ntxt">
                                <div className="tp">白老大</div>
                                <div className="scr">羅文宣</div>
                            </div>
                            <div className="tag prevention">預防</div>
                        </div>
                        <div className="li">
                            <div className="time">09:45~10:15</div>
                            <div className="ntxt">
                                <div className="tp">莫佛如巴蒂</div>
                                <div className="scr">Jason</div>
                            </div>
                            <div className="tag surgery">手術</div>
                        </div>
                        <div className="li">
                            <div className="time">10:30~11:30</div>
                            <div className="ntxt">
                                <div className="tp">來福</div>
                                <div className="scr">張家驊</div>
                            </div>
                            <div className="tag firstaid">急救</div>
                        </div>
                    </div>
                </div>
            </div>
        );
        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            if (!values.ownername) {
                errors.ownername = '↑ 欄位為必填';
            } else {
            }

            if (!values.cellphone) {
                errors.cellphone = '↑ 欄位為必填';
            } else {
            }

            return errors;
        };

        const handleClose = (e) => {
            if (e) {
                e.preventDefault();
            }

            if (viewModel.refModal || viewModel.refModal.current) {
                viewModel.refModal.current.destroy();
            }
        };

        const handleSubmit_clickLogin = (e: any, v: any) => {
            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">現在開始創立世界</div>,
                key: 'loading',
                duration: 0
            });

            //#region Handle SaveUserInfo
            {
                // VALIDATE INPUT
                //const id : any = this.props.match.params.id;
                const id = 1;
                if (!id) {
                    return; //ERROR INPUTS
                }

                // WAITING SCREEN
                loadingManager.changeLoadingCount(+1);
                //StaticEvent.LoadingEvent.next(true);

                // POST
                let postData = {
                    p1: v.p1,
                    p2: v.p2,
                    p3: v.p3,
                    p4: v.p4,
                    p5: v.p5,
                    timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
                };
                const postUrl = ApiService.urlMapping('~/h_api/1/pg_jumplogin/clicklogin');
                const post$ = Static.apiService.post(postUrl, postData);
                post$.subscribe(async (res: any) => {
                    hide();


                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        message.success({
                            type: 'success',
                            className: 'myMessage',
                            content: <div className="myMessageBody">歡迎來到，恐龍世界</div>,
                            key: '',
                            duration: 3
                        });

                        // message.success({
                        //     type: 'success',
                        //     className: 'myMessage',
                        //     content: <div className="myMessageBody">門就開了</div>,
                        //     key: '',
                        //     duration: 3
                        // });


                        await sessionManager.setLoginData(
                            payload.SessionId,
                            payload.AuthData
                        );

                        navigate({
                            pathname: '/wall/newest',
                            //search: "?sort=name",
                            //hash: "#the-hash",
                            //state: {panelSelection: "f1"}
                        });
                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
                );
                return lastValueFrom(
                    post$
                );
            }
            //#endregion
        }

        const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;


        /*
                                                        <input type="text" {...input} placeholder="username"></input>
                                                {meta.touched && meta.error &&
                                                <div className="error">
                                                    {meta.error}
                                                </div>}

                                                <input type="text" {...input} placeholder="password"></input>
                                                {meta.touched && meta.error &&
                                                <div className="error">
                                                    {meta.error}
                                                </div>}

                                                <input type="submit" {...input} placeholder="asfasdfsad"></input>
                                                {meta.touched && meta.error &&
                                                <div className="error">
                                                    {meta.error}
                                                </div>}

         */
        return (
            <div className="scheduleform">
                <div className="sf_header">
                    <div className="title"></div>
                    <div className="datebar">
                        <div className="date_item">
                            <div className="week">一</div>
                            <div className="date">30</div>
                        </div>
                        <div className="date_item">
                            <div className="week">二</div>
                            <div className="date">31</div>
                        </div>
                        <div className="date_item ">
                            <div className="week">三</div>
                            <div className="date">1</div>
                        </div>
                        <div className="date_item active">
                            <div className="week">四</div>
                            <div className="date">2</div>
                        </div>
                        <div className="date_item">
                            <div className="week">五</div>
                            <div className="date">3</div>
                        </div>
                        <div className="date_item">
                            <div className="week">六</div>
                            <div className="date">4</div>
                        </div>
                        <div className="date_item">
                            <div className="week">日</div>
                            <div className="date">5</div>
                        </div>
                    </div>
                </div>
                <div className="sf_group">
                    <div className="sf_body">
                        <div className="title">早上<span>09:00~12:00</span></div>
                        <div className="datebar">
                            <div className="date_item">
                                {/* <div className="shift">早午</div>
                                                    <div className="num">0</div> */}
                            </div>
                            <div className="date_item">
                                {/* <div className="shift">早午</div>
                                                    <div className="num">3</div> */}
                            </div>
                            <div className="date_item ">
                                <div className="shift active">
                                    <div className="name">陳其邁</div>
                                    <div className="num">3</div>
                                </div>
                                <div className="shift active">
                                    <div className="name">林來瘋</div>
                                    <div className="num">5</div>
                                </div>
                                <div className="shift">
                                    <div className="name">張志嘉</div>
                                    <div className="num">0</div>
                                </div>


                            </div>
                            <div className="date_item">
                                <div className="shift active">
                                    <div className="name">陳其邁</div>
                                    <div className="num">3</div>
                                </div>
                                <div className="shift active">
                                    <div className="name">林來瘋</div>
                                    <div className="num">5</div>
                                </div>
                                <div className="shift">
                                    <div className="name">張志嘉</div>
                                    <div className="num">0</div>
                                </div>
                            </div>
                            <div className="date_item ">
                                <div className="shift active">
                                    <div className="name">陳其邁</div>
                                    <div className="num">3</div>
                                </div>
                                <div className="shift active">
                                    <div className="name">林來瘋</div>
                                    <div className="num">5</div>
                                </div>
                                <div className="shift">
                                    <div className="name">張志嘉</div>
                                    <div className="num">0</div>
                                </div>
                            </div>
                            <div className="date_item">
                                <div className="shift active">
                                    <div className="name">陳其邁</div>
                                    <div className="num">3</div>
                                </div>
                                <div className="shift active">
                                    <div className="name">林來瘋</div>
                                    <div className="num">5</div>
                                </div>
                                <div className="shift">
                                    <div className="name">張志嘉</div>
                                    <div className="num">0</div>
                                </div>
                            </div>
                            <div className="date_item ">
                                <div className="shift active">
                                    <div className="name">陳其邁</div>
                                    <div className="num">3</div>
                                </div>
                                <div className="shift active">
                                    <div className="name">林來瘋</div>
                                    <div className="num">5</div>
                                </div>
                                <div className="shift">
                                    <div className="name">張志嘉</div>
                                    <div className="num">0</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sf_body">
                        <div className="title">下午<span>13:00~17:00</span></div>
                        <div className="datebar">
                            <div className="date_item">
                                {/* <div className="shift">早午</div>
                                                    <div className="num">0</div> */}
                            </div>
                            <div className="date_item">
                                {/* <div className="shift">早午</div>
                                                    <div className="num">3</div> */}
                            </div>
                            <div className="date_item">
                                <div className="shift active">
                                    <div className="name">陳其邁</div>
                                    <div className="num">3</div>
                                </div>
                                <div className="shift active">
                                    <div className="name">林來瘋</div>
                                    <div className="num">5</div>
                                </div>
                                <div className="shift">
                                    <div className="name">張志嘉</div>
                                    <div className="num">0</div>
                                </div>
                            </div>
                            <div className="date_item">

                                <div className="shift active">
                                    <div className="name">陳其邁</div>
                                    <div className="num">3</div>
                                </div>
                                <div className="shift active">
                                    <div className="name">林來瘋</div>
                                    <div className="num">5</div>
                                </div>
                                <div className="shift">
                                    <div className="name">張志嘉</div>
                                    <div className="num">0</div>
                                </div>

                            </div>
                            <div className="date_item">
                                <div className="shift active">
                                    <div className="name">陳其邁</div>
                                    <div className="num">3</div>
                                </div>
                                <div className="shift active">
                                    <div className="name">林來瘋</div>
                                    <div className="num">5</div>
                                </div>
                                <div className="shift">
                                    <div className="name">張志嘉</div>
                                    <div className="num">0</div>
                                </div>
                            </div>
                            <div className="date_item">
                                <div className="shift active">
                                    <div className="name">陳其邁</div>
                                    <div className="num">3</div>
                                </div>
                                <div className="shift active">
                                    <div className="name">林來瘋</div>
                                    <div className="num">5</div>
                                </div>
                                <div className="shift">
                                    <div className="name">張志嘉</div>
                                    <div className="num">0</div>
                                </div>
                            </div>
                            <div className="date_item">
                                <div className="shift active">
                                    <div className="name">陳其邁</div>
                                    <div className="num">3</div>
                                </div>
                                <div className="shift active">
                                    <div className="name">林來瘋</div>
                                    <div className="num">5</div>
                                </div>
                                <div className="shift">
                                    <div className="name">張志嘉</div>
                                    <div className="num">0</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sf_body">
                        <div className="title">晚上<span>18:00~21:00</span></div>
                        <div className="datebar">
                            <div className="date_item">
                                {/* <div className="shift">早午</div>
                                                    <div className="num">0</div> */}
                            </div>
                            <div className="date_item">
                                {/* <div className="shift">早午</div>
                                                    <div className="num">3</div> */}
                            </div>
                            <div className="date_item ">
                                <div className="shift active">
                                    <div className="name">陳其邁</div>
                                    <div className="num">3</div>
                                </div>
                                <div className="shift active">
                                    <div className="name">林來瘋</div>
                                    <div className="num">5</div>
                                </div>
                                <div className="shift">
                                    <div className="name">張志嘉</div>
                                    <div className="num">0</div>
                                </div>
                            </div>
                            <div className="date_item">
                                <Popover content={popcontent} title="2021-01-07(三) 陳其邁" placement="left" trigger="click">
                                    <div className="shift active">
                                        <div className="name">陳其邁</div>
                                        <div className="num">3</div>
                                    </div>
                                </Popover>
                                <div className="shift active">
                                    <div className="name">林來瘋</div>
                                    <div className="num">5</div>
                                </div>
                                <div className="shift">
                                    <div className="name">張志嘉</div>
                                    <div className="num">0</div>
                                </div>
                            </div>
                            <div className="date_item">
                                <div className="shift active">
                                    <div className="name">陳其邁</div>
                                    <div className="num">3</div>
                                </div>
                                <div className="shift active">
                                    <div className="name">林來瘋</div>
                                    <div className="num">5</div>
                                </div>
                                <div className="shift">
                                    <div className="name">張志嘉</div>
                                    <div className="num">0</div>
                                </div>
                            </div>
                            <div className="date_item">
                                <div className="shift active">
                                    <div className="name">陳其邁</div>
                                    <div className="num">3</div>
                                </div>
                                <div className="shift active">
                                    <div className="name">林來瘋</div>
                                    <div className="num">5</div>
                                </div>
                                <div className="shift">
                                    <div className="name">張志嘉</div>
                                    <div className="num">0</div>
                                </div>
                            </div>
                            <div className="date_item">
                                <div className="shift active">
                                    <div className="name">陳其邁</div>
                                    <div className="num">3</div>
                                </div>
                                <div className="shift active">
                                    <div className="name">林來瘋</div>
                                    <div className="num">5</div>
                                </div>
                                <div className="shift">
                                    <div className="name">張志嘉</div>
                                    <div className="num">0</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    };

    //#region Render
    return <React.Fragment>
        <Body />
    </React.Fragment>;
    //#endregion
}
Partial_ScheduleTable.displayName = 'Partial_ScheduleTable';
