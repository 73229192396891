import React, {useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {
    Form as NForm,
    Popover,
    Button,
    Modal,
    InputNumber,
    message,
    Tag,
    Table,
    Empty,
    Input,
    Select,
    Space,
    Tabs,
    Tooltip,
    Steps, Spin
} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';


import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";
import {
    PlusCircleOutlined,
    DeleteFilled,
    EditOutlined,
    EyeFilled,
    CloseOutlined,
    LoadingOutlined
} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_inHospital__Modal_AddRoom from "./Partial_inHospital__Modal_AddRoom";
import Partial_inHospital__Modal_ViewRoom from "./Partial_inHospital__Modal_ViewRoom";
import UseCompState from "../hooks/UseCompState";


var moment = require('moment');
const {Option} = Select;
const {Search} = Input;
const onSearch = value => console.log(value);
const {TabPane} = Tabs;
const {Step} = Steps;

type ViewModelProps = {
    refModal: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any,

    viewModel: ViewModelProps,
};
export default function Partial_inHospital__Modal_ListRoom(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_inHospital__Modal_ListRoom';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel = props.viewModel;


    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion


    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

    }
    //#endregion

    //#region Boot
    let _refDoLoadPartialData: any = useRef<{ alwaysLoad?: boolean }>(null);
    useEffect(() => {
        const _loadPartialData = async (): Promise<void> => {
            // VALIDATE INPUT
            //const id : any = this.props.match.params.id;
            // const p1 = viewModel.住院籠子No;
            // if (!p1) {
            //     return; //ERROR INPUTS
            // }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                //p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/partial_inhospital/modal_listroom');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        let changeShard = {
                            [PrefixTarget]: {
                                ...payload,
                            }
                        }
                        pageDataManager.merge(changeShard);
                        pageDataManager.apply();

                        forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPartialData = async ({alwaysLoad = false}): Promise<void> => {
            // if (!pageDataManager.refPageData.current.Root) {
            //     return;
            // }

            /// do not dynamically load data if condition fits.
            if (!alwaysLoad) {
                if (pageDataManager.isZoneExist(PrefixTarget)) {
                    return;
                }
            }

            await _loadPartialData();
        }

        _refDoLoadPartialData.current = doLoadPartialData;
        _refDoLoadPartialData.current({});
    }, [pageDataManager.get(PrefixTarget)]);
    //#endregion

    //#region Set Body
    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };

    const Body = () => {
        const RegionEmptyShow = false;
        const RegionLoadingShow = true;
        const RegionInitialLoadingShow = false;

        const PassPageDataInitalized = pageDataManager.refPageData.current != null;
        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;

        let DataSource_住院籠子 = state.DataSource_住院籠子 = [];
        let List_住院籠子 = pageDataManager.get(`${PrefixTarget}.List_住院籠子`);
        if (List_住院籠子 !== null) {
            let serialCounter = 0;
            DataSource_住院籠子 = List_住院籠子.map((Item_住院籠子) => {
                serialCounter += 1;

                if (serialCounter == 1) {
                }

                if (!Item_住院籠子._掛號) {
                    return {
                        entity: Item_住院籠子,

                        key: Item_住院籠子.住院籠子No,

                        serial: serialCounter,
                        area: Item_住院籠子.區域籠號,
                        IHinfo: ``,
                        stayDay: ``,
                    }
                }


                let 住院期間: any = null;
                let 住院剩餘天: any = null;
                let 住院進行天: any = null;
                let 住院預計天: any = null;
                if (!_.util.nullify(Item_住院籠子._掛號._病歷.__病歷項_最新住院項?.住院_住院開始時間)) {
                    住院期間 = '錯誤:未設置開始住院時間';
                } else {
                    if (!_.util.nullify(Item_住院籠子._掛號._病歷.__病歷項_最新住院項?.住院_住院預計結束時間)) {
                        住院期間 = '錯誤:住院預計結束時間';
                    } else {
                        let startDate = moment(Item_住院籠子._掛號._病歷.__病歷項_最新住院項.住院_住院開始時間);
                        //let moreDays = (+Item_住院籠子._掛號._病歷.__病歷項_最新住院項.住院預計天數);
                        let endDate = moment(Item_住院籠子._掛號._病歷.__病歷項_最新住院項.住院_住院預計結束時間);
                        let totalDays = endDate.diff(startDate, 'days');
                        let now = moment();
                        let remainingDays = endDate.diff(now, 'days');
                        if (remainingDays < 0) {
                            remainingDays = 0;
                        }
                        let runningDays = now.diff(startDate, 'days');
                        if (runningDays < 0) {
                            runningDays = 0;
                        }
                        住院預計天 = totalDays;
                        住院進行天 = runningDays;
                        住院剩餘天 = remainingDays;
                        住院期間 = `${startDate.format('MM.DD')}~${endDate.format('MM.DD')}`;
                    }
                }

                let Item_寵物 = Item_住院籠子?._掛號?._寵物;
                let 年齡: any = null;
                if (!_.util.nullify(Item_寵物?.出生日)) {
                    年齡 = '沒有填出生日';
                } else {
                    try {
                        let birthDate = moment(Item_寵物.出生日);
                        let now = moment();
                        var yearDiff = now.diff(birthDate, "year");
                        var monthDiff = now.diff(birthDate, "month");
                        年齡 = '';
                        if (monthDiff >= 0) {
                            年齡 = `${monthDiff}個月` + 年齡;
                        }
                        if (yearDiff >= 0) {
                            年齡 = `${yearDiff}歲` + 年齡;
                        }
                        if (年齡 === '') {
                            年齡 = `未滿一個月`;
                        }
                    } catch(err) {
                        年齡 = '計算錯誤';
                    }
                }

                let 體重: any = _.util.nullify(Item_寵物?.最後體重) ?? _.util.nullify(Item_寵物?.口述體重);
                if (!體重) {
                    體重 = '沒有測量或告知';
                }


                return {
                    entity: Item_住院籠子,

                    key: Item_住院籠子.住院籠子No,

                    serial: serialCounter,
                    area: Item_住院籠子.區域,
                    IHinfo: `${Item_住院籠子._掛號._寵物.寵物名稱}・${Item_住院籠子._掛號._寵物.種類}・${年齡}・${體重}`,
                    stayDay: `${住院期間}/${住院預計天}天`,
                }
            });

            state.DataSource_住院籠子 = DataSource_住院籠子;
        }

        //#region Handlers
        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            if (!values.username) {
                errors.username = '↑ 欄位為必填';
            } else {
            }

            if (!values.password) {
                errors.password = '↑ 欄位為必填';
            } else {
            }

            return errors;
        };

        //#region :Popup :createModalEditRoom
        const createModalEditRoom_refModal = useRef<any>(null);
        const createModalEditRoom = (e: any, {onDone, tasks}: {
            onDone?: any,
            tasks?: string[]
        }): void => {
            const config = {
                // title: 'test',
                content: <Partial_inHospital__Modal_AddRoom
                    PrefixTarget={'Partial_inHospital__Modal_AddRoom'}
                    pageDataManager={pageDataManager}
                    sessionManager={sessionManager}
                    navigate={navigate}

                    viewModel={{
                        refModal: createModalEditRoom_refModal,

                        onDone: onDone,
                    }}
                />,
                okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
                className: 'myClass myModal',
                width: '50em',
                height: ' ',
                footer: null,
                //prefixCls: '',
            };

            createModalEditRoom_refModal.current = Modal.info(config);
        }
        //#endregion

        //#region :Popup :createModalViewRoom
        const createModalViewRoom_refModal = useRef<any>(null);
        const createModaViewRoom = (e: any, {住院籠子No, tasks, modalMoreProp}: {
            住院籠子No: any,
            modalMoreProp?: object,
            tasks?: string[]
        }): void => {
            if (e) {
                e.preventDefault();
            }
            // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
            //
            // if (!PassPageDataInitalized) {
            //     //empty
            // }

            const config = {
                // title: 'test',
                content: <Partial_inHospital__Modal_ViewRoom
                    PrefixTarget={`Partial_inHospital__Modal_ViewRoom${住院籠子No ?? ''}`}
                    pageDataManager={pageDataManager}
                    sessionManager={sessionManager}
                    navigate={navigate}

                    viewModel={{
                        refModal: createModalViewRoom_refModal,

                        住院籠子No: 住院籠子No,
                    }}
                />,
                okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
                className: 'myClass myModal',
                width: '50em',
                height: ' ',
                footer: null,
                //prefixCls: '',
            };

            createModalViewRoom_refModal.current = Modal.info(config);
        }
        //#endregion

        const IHCageInfoColumn = [
            {
                dataIndex: 'serial',
                title: '序號',
            },
            {
                dataIndex: 'area',
                title: '區域籠號',
            },
            {
                dataIndex: 'IHinfo',
                title: '資訊',
            },

            {
                dataIndex: 'stayDay',
                title: '住院天數',
            },
            {
                dataIndex: 'management',
                title: '管理',
                render: (text, record) => {

                    return <div>
                        <Space>
                            {/* <Tooltip placement="top" title="編輯">
                                <Button icon={<EditOutlined />} />
                            </Tooltip> */}
                            <Button icon={<EyeFilled/>} onClick={(e) => {
                                createModaViewRoom(e, {住院籠子No: record.entity.住院籠子No});
                            }}>檢視</Button>
                            <Tooltip placement="top" title="刪除">
                                <Button danger icon={<DeleteFilled/>}
                                        onClick={(e) => handleSubmit_delete(e, {住院籠子No: record.entity.住院籠子No})}>刪除</Button>
                            </Tooltip>
                        </Space>
                    </div>
                },
            },
        ];

        const handleSubmit_delete = (e: any, v: any) => {
            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            //const id : any = this.props.match.params.id;

            const p1 = v.住院籠子No;
            if (!p1) {
                _._debug.error(p1);
                return; //ERROR INPUTS
            }


            // let pictures = [] as any;
            // state.files.map((v, i) => {
            //     console.log(v)
            //     pictures.push(v.id);
            // });
            // const p2 = pictures.join(',');
            // if (!p2) {
            //     _.debug.error(p1);
            //     return; //ERROR INPUTS
            // }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_inhospital/pane_editoraddroom/delete');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);


                        let hide = message.loading({
                            type: 'loading',
                            className: 'myMessage',
                            content: <div className="myMessageBody">已刪除</div>,
                            key: 'loading',
                            duration: 2
                        });

                        //handleClose(null);

                        // if (viewModel.用戶No === null) { //is insert
                        //     StaticEvent.castUpdateEvent({
                        //         command: StaticEvent.UpdateFacade.Command.Add_用戶,
                        //         Add_用戶: {
                        //             用戶No: payload.Item_用戶.用戶No,
                        //         }
                        //     });
                        // } else {
                        //     StaticEvent.castUpdateEvent({
                        //         command: StaticEvent.UpdateFacade.Command.Update_用戶,
                        //         Update_用戶: {
                        //             用戶No: payload.Item_用戶.用戶No,
                        //         }
                        //     });
                        // }

                        // let carryData = _._u.update(payload,
                        //     {
                        //         $merge: {
                        //             Tokens: payload?.Tokens,
                        //         },
                        //         Item_飼主: {
                        //             $set: payload?.Item_飼主,
                        //         }
                        //     }
                        // );
                        // carryData.Item_飼主 = p1;
                        // if (onDone) {
                        //     const ret = onDone(carryData);
                        //     if (ret === 'demo') {
                        //         createModalPet(null, {
                        //             carryData,
                        //         })
                        //     }
                        // } else {
                        //     alert('onDone not defined');
                        // }

                        _refDoLoadPartialData.current({alwaysLoad: true});
                        //viewModel?.onDone?.(payload);

                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        //#endregion

        if (!PassPrefixTargetLoaded) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <div>Loading.</div>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        if (!PassPrefixTargetLoaded) {
            if (RegionInitialLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <div>Loading.</div>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        if (loadingManager.getIsLoading()) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <Spin indicator={
                            <LoadingOutlined style={{fontSize: 24}} spin/>
                        }/>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        return (
            <div className="model_wrapper">
                <div className="modal_title">
                    <div className="title_info">
                        <h2>住房管理</h2>
                    </div>
                    <div className="info_bar"></div>
                    <div className="control_bar">
                        <Tooltip placement="top" title={'關閉'}>
                            <Button type="text" icon={<CloseOutlined/>} onClick={handleClose}/>
                        </Tooltip>
                    </div>
                </div>
                <div className="modal_content">
                    <div className="content_box">
                        <div className="ptare">
                            <Button icon={<PlusCircleOutlined/>} onClick={(e) => {
                                createModalEditRoom(e, {
                                    onDone: () => {
                                        _refDoLoadPartialData.current({alwaysLoad: true});
                                    }
                                })
                            }}>新增籠子</Button>
                        </div>
                        <Table
                            columns={IHCageInfoColumn}
                            dataSource={DataSource_住院籠子}
                            locale={{
                                emptyText: <Empty description={
                                    <span>沒有搜尋到相關資料～</span>
                                }/>
                            }}
                        />
                    </div>
                </div>
                <div className="modal_footer"></div>
            </div>
        );
    };
    //#endregion

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_inHospital__Modal_ListRoom.displayName = 'Partial_inHospital__Modal_ListRoom';
