import React, {useEffect, useRef} from 'react';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import {
    Form,
    Input,
    Button,
    Alert,
    Checkbox,
    Result,
    message,
    Space,
    Modal,
    Typography,
    Tabs,
    Select,
    Popconfirm,
    Table,
    Empty,
    Spin,
    DatePicker,
    Menu,
    Dropdown,
    Tooltip,
    Upload,
    Form as NForm
} from 'antd';
import {useApp} from "../overmind";
import {UserInfo} from "../global";
import {Static} from "../_/Static";
import {ApiService} from "../_/ApiService";
import _ from "../_";
import {
    EyeInvisibleOutlined,
    DeleteFilled,
    UserOutlined,
    EditOutlined,
    RedoOutlined,
    LeftOutlined,
    RightOutlined,
    PrinterOutlined,
    RollbackOutlined,
    ArrowLeftOutlined, UploadOutlined, ReloadOutlined
} from '@ant-design/icons';

import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import UsePageDataManager from '../hooks/UsePageDataManager';
import UseSessionManager from "../hooks/UseSessionManager";
import UseLoadingManager from "../hooks/UseLoadingManager";
import UseCompState from "../hooks/UseCompState";
import {lastValueFrom} from "rxjs";
import Partial_MedicalRecord__Modal_Prescribe2 from "./Partial_MedicalRecord__Modal_Prescribe2";
import Partial_Manage_Staff__Modal_EditOrAddStaff from "./Partial_Manage_Staff__Modal_EditOrAddStaff";


import './Pg_Waitingroom.scss';
import FunctionBtn from './Box_Functionbtn';
import moment from 'moment';
import {Field} from "react-final-form";
import Partial_Manage_Staff__Info from "./Partial_Manage_Staff__Info";
import {StaticEvent} from "../_/StaticEvent";
import useRefState from "../hooks/useRefState";
import Ln_CommaListRender from "./Ln_CommaListRender";
import Ln_AvatorImg from "./Ln_AvatorImg";

const iconLighting = require('../assets/images/icon/lighting.svg');
const iconWatting = require('../assets/images/icon/new-medical-record.svg');

const onSearch = value => console.log(value);
const {Title} = Typography;
const {TabPane} = Tabs;
const {Search} = Input;
const {Option} = Select;
const dateFormat = 'YYYY/MM/DD';
const {RangePicker} = DatePicker;


type Props = {
    PrefixTarget?: string;
    RoutePathname?: string;
    MenuTemplateId?: string;
    parentState?: any;

    parentComponentState?: any;
};
export default function Pg_Manage_Staff(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Root';
    let RoutePathname = props.RoutePathname;
    let MenuTemplateId = props.MenuTemplateId;

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    let [_refItem_用戶, _setItem_用戶] = useRefState<any>(null);

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    //#region LoadingManager
    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: true});
    //#endregion

//#region PageDataManager
    const refDoLoadPageData = Static.refDoLoadPageData;
    let pageDataManager = UsePageDataManager({
        refPageLoad: refDoLoadPageData
    });
    //#endregion

    //#region SessionManager
    let sessionManager = UseSessionManager({
        pageDataManager: pageDataManager,
    });
    //#endregion

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

        state.forceRender = forceRender;
    }
    //#endregion

    //#region :Event UpdateEventEvent
    useEffect(() => {
        let handle = StaticEvent.UpdateEvent$.subscribe(async (value) => {
            // command switch

            if (value.command === StaticEvent.UpdateFacade.Command.Add_用戶) {
                let values: any = value.Add_用戶 ?? null;
                if (values === null) {
                    throw 'Add_用戶';
                }

                pageDataManager.emptyZone(PrefixTarget);
                pageDataManager.apply();
                refDoLoadPageData?.current();

                forceRender();

            } else if (value.command === StaticEvent.UpdateFacade.Command.Remove_用戶) {
                let values: any = value.Remove_用戶 ?? null;
                if (values === null) {
                    throw 'Remove_用戶';
                }

                let hasWork = false;

                if (values.用戶No == _refItem_用戶?.current?.用戶Id) {
                    _setItem_用戶(null);
                }

                if (state.DataSource_用戶.filter(r => r.entity.用戶No == values.用戶No)) {
                    hasWork = hasWork || true;

                    pageDataManager.emptyZone(PrefixTarget);
                    pageDataManager.apply();
                    refDoLoadPageData?.current();
                }

                if (hasWork) {
                    forceRender();
                }
            } else if (value.command === StaticEvent.UpdateFacade.Command.Update_用戶) {
                let values: any = value.Update_用戶 ?? null;
                if (values === null) {
                    throw 'Update_用戶';
                }

                let hasWork = false;

                if (values.用戶No == _refItem_用戶?.current?.用戶Id) {
                    _setItem_用戶(null);
                }

                if (state.DataSource_用戶.filter(r => r.entity.用戶No == values.用戶No)) {
                    hasWork = hasWork || true;

                    pageDataManager.emptyZone(PrefixTarget);
                    pageDataManager.apply();
                    refDoLoadPageData?.current();
                }

                if (hasWork) {
                    forceRender();
                }
            }
        });

        return function cleanup() {
            handle.unsubscribe();
        };
    }, []);
    //#endregion


    //#region Boot
    useEffect(() => {
        const _loadPageData = async (): Promise<void> => {

            // VALIDATE INPUT
            // const p2: any = paramRouting_ArticleId;
            // if (!p2) {
            //     alert('error inputs');
            //     return; //ERROR INPUTS
            // }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                //p1: p2,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/pg_manage_staff');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        pageDataManager.merge(payload);
                        pageDataManager.apply();
                        pageDataManager.forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        ///TODO: Error Handling
                        alert(error.message);
                        _._debug.error(error);

                    }
                }, async (err) => {

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const _doLoadPageData = async (): Promise<void> => {
            pageDataManager.restore();
            if (!pageDataManager.isZoneExist(PrefixTarget)) {
                await sessionManager.initSessionAuthAsync();
                await _loadPageData();
            } else {
                forceRender()
            }
        }

        refDoLoadPageData.current = _doLoadPageData;
        _doLoadPageData();
    }, [location.pathname]);
    //#endregion



    const iconWaitforCase = require('../assets/images/icon/nocase.svg');
    const [noCase, setNoCase] = useState(false);

    function callback(key) {
        console.log(key);
    }

    function confirm(e) {
        console.log(e);
    }

    function cancel(e) {
        console.log(e)
    }

    //#region :Popup :createModalStaff
    const createModalStaff_refModal = useRef<any>(null);
    const createModalStaff = (e: any, {用戶No, tasks}: {
        用戶No: any,
        tasks?: string[]
    }) => {
        if (e) {
            e.preventDefault();
        }

        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_Manage_Staff__Modal_EditOrAddStaff
                //PrefixTarget={'Partial_Manage_Staff__Modal_EditOrAddStaff'}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModalStaff_refModal,

                    用戶No: 用戶No,
                }}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '80em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModalStaff_refModal.current = Modal.info(config);
    }
    //#endregion


    const columns = [
        {
            title: '頭像',
            dataIndex: ' avatar',
            key: 'avatar',
            render: (text, record) => {
                return <div className="avatar">
                    {Ln_AvatorImg({
                        名稱: record.employee,
                        照片組: record.avatar
                    })}
                </div>

            },
        },
        {
            title: '員工',
            dataIndex: 'employee',
            key: 'employee',
            render: (text) => {
                return <div className="person">{text}</div>
            },
        },
        {
            title: '職稱',
            dataIndex: 'jobtitle',
            key: 'jobtitle',
        },
        {
            title: '權限',
            dataIndex: 'authority',
            key: 'authority',
            render: (text, record) => {
                return <div className="taggp">
                    <div className={record.sns}>{text}</div>
                </div>
            },
            width: 90,
        },
        {
            title: '到職日',
            dataIndex: 'ondutyday',
            key: 'ondutyday',
        },
        {
            title: '登入帳號',
            dataIndex: 'accountname',
            key: 'accountname',
        },
    ];

    const dataSource = [
        {
            key: '1',
            avatar: '/static/img/atr_02.png',
            employee: '蔡英文',
            jobtitle: '會計',
            authority: '院長',
            sns: 'tag sn1',
            ondutyday: '2021-05-20',
            accountname: 'PoorEnglish0530'
        },
        {
            key: '2',
            avatar: '/static/img/atr_01.png',
            employee: '陳時中',
            jobtitle: '獸醫師',
            authority: '高階',
            sns: 'tag sn2',
            ondutyday: '2021-03-23',
            accountname: 'Hsi0323'
        },
        {
            key: '3',
            avatar: '/static/img/avatarpic_01.jpg',
            employee: '柯文哲',
            jobtitle: '藥師',
            authority: '一般',
            sns: 'tag sn3',
            ondutyday: '2021-07-14',
            accountname: 'KePi001'
        },

    ];

    //#region Set Body
    const Body = () => {
        const RegionEmptyShow = true;
        const RegionLoadingShow = true;

        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;

        if (!PassPrefixTargetLoaded) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <div>Loading.</div>
                    </div>
                </React.Fragment>;
            }
            else {
                return null;
            }
        }

        let its: any = null;
        let DataSource_用戶 = state.DataSource_用戶 = null;
        let List_用戶 = pageDataManager.get(`${PrefixTarget}.List_用戶`);
        if (List_用戶 !== null) {
            let serialCounter = 0;
            DataSource_用戶 = List_用戶.map((r) => {
                serialCounter += 1;

                if (serialCounter == 1) {

                }
                // {
                //     key: '1',
                //     avatar: '/static/img/atr_02.png',
                //     employee: '蔡英文',
                //     jobtitle: '會計',
                //     authority: '院長',
                //     sns: 'tag sn1',
                //     ondutyday: '2021-05-20',
                //     accountname: 'PoorEnglish0530'
                // },

                return {
                    entity: r,

                    key: r.用戶No,
                    serial: serialCounter,
                    avatar: r.照片組,
                    employee: r.名稱,
                    jobtitle: r.職稱,
                    authority: r.權限,
                    sns: r.醫LINE碼,
                    ondutyday: r.到職日 ? moment.utc(r.到職日).format('YYYY-MM-DD') : '',
                    accountname: r.登入名稱
                }
            });
            state.DataSource_用戶 = DataSource_用戶;

            its = [DataSource_用戶];
        }


        if (its && its.length > 0) {
            return <React.Fragment>
                {its.map(DataSource_用戶 => {
                    return <React.Fragment key={DataSource_用戶.length ?? "0"}>
                        <div className="infoboxoutside">
                            <div className="infobox">
                                <div className="titlebar">
                                    <h4>員工列表</h4>
                                    <div className="barcontent"></div>
                                    <div className="func">
                                        <Button icon={<UserOutlined/>} type="primary"
                                                onClick={(e) => {
                                                    createModalStaff(e, {用戶No: null});
                                                }}>新建員工</Button>
                                    </div>
                                </div>
                                <div className="boxcontent">
                                    <Table dataSource={DataSource_用戶} columns={columns} bordered
                                           onRow={(record, rowIndex) => {


                                               return {
                                                   onClick: (event) => {
                                                       _setItem_用戶(record.entity);
                                                   }, // click row
                                                   // onDoubleClick: event => {}, // double click row
                                                   // onContextMenu: event => {}, // right button click row
                                                   // onMouseEnter: event => {}, // mouse enter row
                                                   // onMouseLeave: event => {}, // mouse leave row
                                               };
                                           }}
                                    />
                                </div>
                                <div className="boxfooter">

                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                })}
            </React.Fragment>;
        } else if (its && its.length === 0) {
            if (RegionEmptyShow) {
                return <React.Fragment>
                    <div>Empty.</div>
                </React.Fragment>;
            }
        }

        return null;
    };
    //#endregion

    //#region Render
    return (
        <div className="page-appointment page-content">
            <div className="info-list">
                <div className="info-box">
                    <Title level={2}>員工管理</Title>
                    <div className="title"><Tooltip title="刷新">
                        <Button icon={<ReloadOutlined />} shape="circle" onClick={(e) => {
                            ///reload
                            if (e) {
                                e.preventDefault();
                                e.stopPropagation();
                            }
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                        }}/>
                    </Tooltip></div>

                    <div className="pluginfobox">
                        <div className="title"></div>
                        <div className="info"></div>
                    </div>

                </div>
                <div className="function-box">
                    <button>

                    </button>

                </div>
            </div>


            <div className="page-staff">
                <div className="vt-page">
                    <div className="vt-col">
                        {Body()}
                    </div>

                    <div className="vt-col">
                        <Partial_Manage_Staff__Info
                            key={_refItem_用戶?.current?.用戶Id}
                            //PrefixTarget={'Partial_Manage_Staff__Info'}
                            pageDataManager={pageDataManager}
                            sessionManager={sessionManager}
                            navigate={navigate}

                            viewModel={{
                                Item_用戶: _refItem_用戶?.current,
                            }}
                        />

                    </div>
                </div>
            </div>
        </div>
    )
    //#endregion
}

Pg_Manage_Staff.displayName = 'Pg_Manage_Staff';




























