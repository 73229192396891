import React, { useRef } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Form, Input, Button, Alert, Checkbox, Result, message, Space, Radio,
  Modal, Typography, Tabs, Select, Popconfirm, Table, Empty, Spin, DatePicker, Menu, Dropdown, Tooltip
} from 'antd';
import { useApp } from "../overmind";
import { UserInfo } from "../global";
import { Static } from "../_/Static";
import { ApiService } from "../_/ApiService";
import _ from "../_";
import { EyeInvisibleOutlined, DeleteFilled, SearchOutlined, EditOutlined, RedoOutlined, LeftOutlined, RightOutlined, PrinterOutlined, RollbackOutlined, ArrowLeftOutlined } from '@ant-design/icons';

import {
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import UsePageDataManager from '../hooks/UsePageDataManager';
import UseSessionManager from "../hooks/UseSessionManager";
import UseLoadingManager from "../hooks/UseLoadingManager";
import UseCompState from "../hooks/UseCompState";
import { lastValueFrom } from "rxjs";
import Partial_MedicalRecord__Modal_Prescribe2 from "./Partial_MedicalRecord__Modal_Prescribe2";
import Partial_Manage_Staff__Modal_EditOrAddStaff from "./Partial_Manage_Staff__Modal_EditOrAddStaff";


import './Pg_Waitingroom.scss';
import FunctionBtn from './Box_Functionbtn';
import moment from 'moment';

const iconLighting = require('../assets/images/icon/lighting.svg');
const iconWatting = require('../assets/images/icon/new-medical-record.svg');

const onSearch = value => console.log(value);
const { Title } = Typography;
const { TabPane } = Tabs;
const { Search } = Input;
const { Option } = Select;
const dateFormat = 'YYYY/MM/DD';
const { RangePicker } = DatePicker;


type Props = {
  PrefixTarget?: string;
  RoutePathname?: string;
  MenuTemplateId?: string;

  parentComponentState?: any;
};
export default function Pg_Manage_Shifts_(props: Props) {
  let PrefixTarget: string = props.PrefixTarget || 'Root';
  let RoutePathname = props.RoutePathname;
  let MenuTemplateId = props.MenuTemplateId;

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  //#region ForceRender Control
  const [, setForceRender] = useState(false);
  const forceRender = () => {
    setForceRender(s => !s);
  };
  //#endregion

  //#region LoadingManager
  const loadingManager = UseLoadingManager({ shouldReRenderOnLoading: true });
  //#endregion

//#region PageDataManager
  const refDoLoadPageData = Static.refDoLoadPageData;
  let pageDataManager = UsePageDataManager({
    refPageLoad: refDoLoadPageData
  });
  //#endregion

  //#region SessionManager
  let sessionManager = UseSessionManager({
    pageDataManager: pageDataManager,
  });
  //#endregion

  const state = UseCompState({ parentState: props.parentComponentState });
  state.pageDataManager = pageDataManager;
  state.sessionManager = sessionManager;
  state.loadingManager = loadingManager;
  state.navigate = navigate;
  state.forceRender = forceRender;

  const iconWaitforCase = require('../assets/images/icon/nocase.svg');
  const [noCase, setNoCase] = useState(false);

  function callback(key) {
    console.log(key);
  }

  function confirm(e) {
    console.log(e);
  }

  function cancel(e) {
    console.log(e)
  }


  //#region :Popup :createModalPrescribeRecord
  const createModalPrescribeRecord_refModal = useRef<any>(null);
  const createModalPrescribeRecord = (e: any, tasks?: string[]): void => {
    if (e) {
      e.preventDefault();
    }
    // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
    //
    // if (!PassPageDataInitalized) {
    //     //empty
    // }

    const config = {
      // title: 'test',
      content: <Partial_MedicalRecord__Modal_Prescribe2
        PrefixTarget={'Partial_MedicalRecord__Modal_Prescribe2'}
        pageDataManager={pageDataManager}
        sessionManager={sessionManager}
        navigate={navigate}

        viewModel={{
          refModal: createModalPrescribeRecord_refModal,
        }}
      />,
      okButtonProps: { disabled: true, className: "modal-footer-hiden-button" },
      className: 'myClass myModal',
      width: '80em',
      height: ' ',
      footer: null,
      //prefixCls: '',
    };

    createModalPrescribeRecord_refModal.current = Modal.info(config);
  }
  //#endregion


  //#region :Popup :createModalPrescribe
  const createModalStaff_refModal = useRef<any>(null);
  const createModalStaff = (e: any, tasks?: string[]): void => {
    if (e) {
      e.preventDefault();
    }
    // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
    //
    // if (!PassPageDataInitalized) {
    //     //empty
    // }

    const config = {
      // title: 'test',
      content: <Partial_Manage_Staff__Modal_EditOrAddStaff
        PrefixTarget={'Partial_Manage_Staff__Modal_EditOrAddStaff'}
        pageDataManager={pageDataManager}
        sessionManager={sessionManager}
        navigate={navigate}

        viewModel={{
          refModal: createModalStaff_refModal,
        }}
      />,
      okButtonProps: { disabled: true, className: "modal-footer-hiden-button" },
      className: 'myClass myModal',
      width: '80em',
      height: ' ',
      footer: null,
      //prefixCls: '',
    };

    createModalStaff_refModal.current = Modal.info(config);
  }
  //#endregion



  const columns = [
    {
      title: '頭像',
      dataIndex: ' avatar',
      key: 'avatar',
      render: (text, record) => {
        return <div className="avatar">
          {/* initial 為預設 有頭像照片再取代 */}
          <img src={record.avatar} alt="" />
        </div>

      },
    },
    {
      title: '員工',
      dataIndex: 'employee',
      key: 'employee',
      render: (text) => {
        return <div className="person">{text}</div>
      },
    },
    {
      title: '職稱',
      dataIndex: 'jobtitle',
      key: 'jobtitle',
    },
    {
      title: '權限',
      dataIndex: 'authority',
      key: 'authority',
      render: (text, record) => {
        return <div className="taggp">
          <div className={record.sns}>{text}</div>
        </div>
      },
      width: 90,
    },
    {
      title: '到職日',
      dataIndex: 'ondutyday',
      key: 'ondutyday',
    },
    {
      title: '登入帳號',
      dataIndex: 'accountname',
      key: 'accountname',
    },
  ];

  const dataSource = [
    {
      key: '1',
      avatar: '/static/img/atr_02.png',
      employee: '蔡英文',
      jobtitle: '會計',
      authority: '院長',
      sns: 'tag sn1',
      ondutyday: '2021-05-20',
      accountname: 'PoorEnglish0530'
    },
    {
      key: '2',
      avatar: '/static/img/atr_01.png',
      employee: '陳時中',
      jobtitle: '獸醫師',
      authority: '高階',
      sns: 'tag sn2',
      ondutyday: '2021-03-23',
      accountname: 'Hsi0323'
    },
    {
      key: '3',
      avatar: '/static/img/avatarpic_01.jpg',
      employee: '柯文哲',
      jobtitle: '藥師',
      authority: '一般',
      sns: 'tag sn3',
      ondutyday: '2021-07-14',
      accountname: 'KePi001'
    },

  ];




  return (
    <div className="page-appointment page-content">
      <div className="info-list">
        <div className="info-box">
          <Title level={2}>排班表</Title>
          <div className="pluginfobox">
            <div className="title"></div>
            <div className="info"></div>
          </div>

          <div className="pluginfobox">
            <div className="title"></div>
            <div className="info"></div>
          </div>

        </div>
        <div className="function-box">
          <button  >

          </button>

        </div>
      </div>


      <div className="page-shifts">
        <div className="cale_nav">
          <Space>
            <div className="title">7月</div>
            {/* 前一月 */}
            <Button icon={<LeftOutlined />} type="text" />
            {/* 後一月 */}
            <Button icon={<RightOutlined />} type="text" />
            {/* 當日 */}
            <Button>今日</Button>

            <DatePicker format={dateFormat} placeholder='選擇月份' picker="month" />
            <DatePicker format={dateFormat} placeholder='選擇週' picker="week" />
          </Space>
          <Space>
            <Radio.Group value="month" >
              <Radio.Button value="month">月</Radio.Button>
              <Radio.Button value="week">週</Radio.Button>
            </Radio.Group>

          </Space>

        </div>
       <div className="cale_body cale_month">

          <div className="cale_table">
            <div className="cale_th">
              <div className="cale_week">
                <div className="week_item">
                  星期一
                </div>
                <div className="week_item">
                  星期二
                </div>
                <div className="week_item">
                  星期三
                </div>
                <div className="week_item">
                  星期四
                </div>
                <div className="week_item">
                  星期五
                </div>
                <div className="week_item">
                  星期六
                </div>
                <div className="week_item">
                  星期日
                </div>
              </div>
            </div>
            <div className="cale_wrapper">
              <div className="cale_day non_month">
                <div className="num_box">
                  <div className="nums">6月30日</div>
                </div>
                <div className="cale_type">

                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">1</div>
                </div>
                <div className="cale_type">
                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <Tooltip placement="topLeft" title="林隆璇, 李敬三, 吳培福, 王靜家, 劉家安, 余明璋, 游登傑">
                        <span>林隆璇</span>
                        <span>李敬三</span>
                        <span>吳培福</span>
                        <span>王靜家</span>
                        <span>劉家安</span>
                        <span>余明璋</span>
                        <span>游登傑</span>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">2</div>
                </div>
                <div className="cale_type">
                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">3</div>
                </div>
                <div className="cale_type">
                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">4</div>
                </div>
                <div className="cale_type">
                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>



              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">5</div>
                </div>
                <div className="cale_type">
                  <div className="cale_shift">
                    <div className="shth">休診</div>
                    <div className="shlist">

                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">休診</div>
                    <div className="shlist">

                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">休診</div>
                    <div className="shlist">
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">6</div>
                </div>
                <div className="cale_type">
                  <div className="cale_shift">
                    <div className="shth">休診</div>
                    <div className="shlist">

                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">休診</div>
                    <div className="shlist">

                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">休診</div>
                    <div className="shlist">
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day today">
                <div className="num_box">
                  <div className="nums">7</div>
                </div>
                <div className="cale_type">

                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">8</div>
                </div>
                <div className="cale_type">
                  <div className="cale_shift">
                    <div className="shth">分段</div>
                    <div className="shlist">醫師姓名</div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>



              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">9</div>
                </div>
                <div className="cale_type">

                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">10</div>
                </div>
                <div className="cale_type">

                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">11</div>
                </div>
                <div className="cale_type">

                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">12</div>
                </div>
                <div className="cale_type">
                  <div className="cale_shift">
                    <div className="shth">分段</div>
                    <div className="shlist">醫師姓名</div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">13</div>
                </div>
                <div className="cale_type">

                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">14</div>
                </div>
                <div className="cale_type">

                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">15</div>
                </div>
                <div className="cale_type">

                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">16</div>
                </div>
                <div className="cale_type">

                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">17</div>
                </div>
                <div className="cale_type">

                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">18</div>
                </div>
                <div className="cale_type">

                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">19</div>
                </div>
                <div className="cale_type">
                  <div className="cale_shift">
                    <div className="shth">分段</div>
                    <div className="shlist">醫師姓名</div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">20</div>
                </div>
                <div className="cale_type">

                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">21</div>
                </div>
                <div className="cale_type">
                  <div className="cale_shift">
                    <div className="shth">分段</div>
                    <div className="shlist">醫師姓名</div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">22</div>
                </div>
                <div className="cale_type">

                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">23</div>
                </div>
                <div className="cale_type">

                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>



              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">24</div>
                </div>
                <div className="cale_type">

                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">25</div>
                </div>
                <div className="cale_type">

                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">26</div>
                </div>
                <div className="cale_type">

                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">27</div>
                </div>
                <div className="cale_type">

                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">28</div>
                </div>
                <div className="cale_type">

                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">29</div>
                </div>
                <div className="cale_type">

                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">30</div>
                </div>
                <div className="cale_type">

                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day">
                <div className="num_box">
                  <div className="nums">31</div>
                </div>
                <div className="cale_type">

                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day non_month">
                <div className="num_box">
                  <div className="nums">7月1日</div>
                </div>
                <div className="cale_type">

                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day non_month">
                <div className="num_box">
                  <div className="nums">7月2日</div>
                </div>
                <div className="cale_type">

                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_day non_month">
                <div className="num_box">
                  <div className="nums">7月3日</div>
                </div>
                <div className="cale_type">

                  <div className="cale_shift">
                    <div className="shth">早上</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">下午</div>
                    <div className="shlist">
                      <span>余明璋</span>
                      <span>游登傑</span>
                    </div>
                  </div>
                  <div className="cale_shift">
                    <div className="shth">晚上</div>
                    <div className="shlist">
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        {/* <div className="cale_body cale_m_week">

          <div className="cale_table">
            <div className="cale_th">
              <div className="cale_week">
                <div className="week_item shf">
                  時段
                </div>
                <div className="week_item non_month">
                  <div className="w_it">星期一</div>
                  <div className="w_date">6月30日</div>
                </div>
                <div className="week_item">
                  <div className="w_it">星期二</div>
                  <div className="w_date">1</div>
                </div>
                <div className="week_item today">
                  <div className="w_it">星期三</div>
                  <div className="w_date">2</div>
                </div>
                <div className="week_item">
                  <div className="w_it">星期四</div>
                  <div className="w_date">3</div>
                </div>
                <div className="week_item">
                  <div className="w_it">星期五</div>
                  <div className="w_date">4</div>
                </div>
                <div className="week_item">
                  <div className="w_it">星期六</div>
                  <div className="w_date">5</div>
                </div>
                <div className="week_item">
                  <div className="w_it">星期日</div>
                  <div className="w_date">6</div>
                </div>
              </div>
            </div>
            <div className="cale_wrapper_week">
              <div className="cale_week_p">
                <div className="cale_wk_item shf">
                  <div className="txt">早上</div>
                  <div className="shift_time">09:00~12:00</div>
                </div>
                <div className="cale_wk_item non_month">
                  <div className="cale_shift">
                    <div className="shth">設定</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                      <span>吳培福</span>
                      <span>吳培福</span>
                      <span>吳培福</span>
                      <span>吳培福</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
                <div className="cale_wk_item">
                  <div className="cale_shift">
                    <div className="shth">設定</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
                <div className="cale_wk_item">
                  <div className="cale_shift">
                    <div className="shth">設定</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
                <div className="cale_wk_item">
                  <div className="cale_shift">
                    <div className="shth">設定</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
                <div className="cale_wk_item">
                  <div className="cale_shift">
                    <div className="shth">設定</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
                <div className="cale_wk_item">
                  <div className="cale_shift">
                    <div className="shth">設定</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
                <div className="cale_wk_item">
                  <div className="cale_shift">
                    <div className="shth">設定</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_week_p">
                <div className="cale_wk_item shf">
                  <div className="txt">下午</div>
                  <div className="shift_time">13:00~18:00</div>
                </div>
                <div className="cale_wk_item non_month">
                  <div className="cale_shift">
                    <div className="shth">設定</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
                <div className="cale_wk_item">
                  <div className="cale_shift">
                    <div className="shth">設定</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
                <div className="cale_wk_item">
                  <div className="cale_shift">
                    <div className="shth">設定</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
                <div className="cale_wk_item">
                  <div className="cale_shift">
                    <div className="shth">設定</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
                <div className="cale_wk_item">
                  <div className="cale_shift">
                    <div className="shth">設定</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
                <div className="cale_wk_item">
                  <div className="cale_shift">
                    <div className="shth">設定</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
                <div className="cale_wk_item">
                  <div className="cale_shift">
                    <div className="shth">設定</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cale_week_p">
                <div className="cale_wk_item shf">
                  <div className="txt">晚上</div>
                  <div className="shift_time">19:00~21:30</div>
                </div>
                <div className="cale_wk_item non_month">
                  <div className="cale_shift">
                    <div className="shth">設定</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
                <div className="cale_wk_item">
                  <div className="cale_shift">
                    <div className="shth">設定</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
                <div className="cale_wk_item">
                  <div className="cale_shift">
                    <div className="shth">設定</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
                <div className="cale_wk_item">
                  <div className="cale_shift">
                    <div className="shth">設定</div>
                    <div className="shlist">
                      <span>林隆璇</span>
                      <span>李敬三</span>
                      <span>吳培福</span>
                    </div>
                  </div>
                </div>
                <div className="cale_wk_item">
                  <div className="cale_shift">
                    <div className="shth">休診</div>
                    <div className="shlist">

                    </div>
                  </div>
                </div>
                <div className="cale_wk_item">
                  <div className="cale_shift">
                    <div className="shth">休診</div>
                    <div className="shlist">
                    </div>
                  </div>
                </div>
                <div className="cale_wk_item">
                  <div className="cale_shift">
                    <div className="shth">休診</div>
                    <div className="shlist">
                    </div>
                  </div>
                </div>
              </div>
            </div>



          </div>
        </div> */}
      </div>
    </div>

  )
}

Pg_Manage_Shifts_.displayName = 'Pg_Manage_Shifts_';




























