import React from 'react';
import { Button } from 'antd';
import { DeleteFilled } from '@ant-design/icons';

//
// MEDICALREPORT PAGE
//
type TableTitle = {
  title: string;
  dataIndex: string;
  key: string;
}
const basicColum = [
  {
    title: '次別',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: '紀錄時間',
    dataIndex: 'time',
    key: 'time',
  },
  {
    title: '測量人員',
    dataIndex: 'noteTaker',
    key: 'noteTaker',
  },
];
export const columns = (dataColums: TableTitle[])=> {
  let combineColums: any = basicColum.concat(dataColums);
  combineColums.push({
    title: '動作',
    dataIndex: 'delete',
    key: 'delete',
    render: () => <Button type="link" icon={<DeleteFilled />} danger></Button>,
  });

  return combineColums;
};
export const DataColumn = [ 
  [{
    title: 'CRP',
    dataIndex: 'CRP',
    key: 'CRP',
  },
  {
    title: 'WBC',
    dataIndex: 'WBC',
    key: 'WBC',
  }],
  [{
    title: '右眼壓',
    dataIndex: 'righteye',
    key: 'righteye',
  },
  {
    title: '左眼壓',
    dataIndex: 'lefteye',
    key: 'lefteye',
  }],
  [{
    title: '體重',
    dataIndex: 'weight',
    key: 'weight',
  }],
  [{
    title: '體溫',
    dataIndex: 'temperature',
    key: 'temperature',
  }],
  [{
    title: '血糖',
    dataIndex: 'bloodSugar',
    key: 'bloodSugar',
  }],
  [{
    title: '心跳',
    dataIndex: 'heartBeat',
    key: 'heartBeat',
  }],
  [{
    title: '呼吸次數',
    dataIndex: 'breath',
    key: 'breath',
  }],
  [{
    title: '血氧',
    dataIndex: 'bloodOxygen',
    key: 'bloodOxygen',
  }],
  [{
    title: '舒張壓',
    dataIndex: 'dbp',
    key: 'dbp',
  },
  {
    title: '收縮壓',
    dataIndex: 'sbp',
    key: 'sbp',
  },
  {
    title: '平均血壓',
    dataIndex: 'map',
    key: 'map',
  },
  {
    title: '位置',
    dataIndex: 'bodyPart',
    key: 'bodyPart',
  },
  {
    title: 'Cuff',
    dataIndex: 'cuff',
    key: 'cuff',
  }],
  [{
    title: '最低',
    dataIndex: 'lsbp',
    key: 'lsbp',
  },
  {
    title: '最高',
    dataIndex: 'hsbp',
    key: 'hsbp',
  },
  {
    title: '平均',
    dataIndex: 'avsbp',
    key: 'avsbp',
  },
  {
    title: '位置',
    dataIndex: 'bodyPart',
    key: 'bodyPart',
  },
  {
    title: 'Cuff',
    dataIndex: 'cuff',
    key: 'cuff',
  }]
]; 
