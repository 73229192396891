import React, {useEffect, useReducer, Dispatch, useState, useRef} from 'react';
import {Static} from "../_/Static";
import {ApiService} from "../_/ApiService";
import _ from "../_";
import UsePageDataManager, {PageDataManager} from "./UsePageDataManager";

type SessionAuthData = {
    userData: any,
    isLoggedIn: any,
};

export type SessionManager = {
    readonly isMemberLoggedIn: () => (boolean | null),
    readonly setLogout: () => Promise<void>,
    readonly initSessionAuthAsync: () => Promise<void>,
    readonly isMemberEqualToChannelId: (string) => boolean,
    readonly setLoginData: (SessionId, UserDataObj) => Promise<void>,
    readonly getMemberActiveChannelId: () => (string | null),
    readonly getMemberActiveChannel:  () => any,
    readonly hasInitOnce:  () => boolean,


    readonly addMutableListener: any,
    readonly removeMutableListener: any,

    readonly ForNotLoggedMember: (jsxFunc) => any,
    readonly ForNotLoggedOwnerMember: (jsxFunc, nail_號Id) => any,

    readonly TryForLoggedMember: (jsxFunc) => any,
    readonly TryForLoggedOwnerMember: (jsxFunc, nail_號Id) => any,
    readonly TryForLoggedNotOwnerMember: (jsxFunc, nail_號Id) => any,

    readonly getMember: () => any,
}

const PrefixTarget: string = 'Auth';

type Props = {
    pageDataManager: PageDataManager;
};

let mutableListeners:any = [];

export default function UseSessionManager(props: Props) : SessionManager {
    const _refSelfInstance = useRef<SessionManager | null>(null);

    if (_refSelfInstance.current !== null) {
        return _refSelfInstance.current;
    }

    const pageDataManager: PageDataManager = props.pageDataManager;

    const hasInitOnce = () => {
        if (_tryGetAuthDataFromStore()?.sign === 'useSessionManager') {
            return true;
        } else {
            return false;
        }
    }

    const _tryGetAuthDataFromStore = (): any | null => {
        return pageDataManager.refPageData.current?.[PrefixTarget] || null;
    };

    const _setAuthDataFromStore = (newContent: any) => {
        pageDataManager.refPageData.current[PrefixTarget] = newContent;
    }

    const loadAuthFromLocalStorageAsync = async () => {
        // AUTH USER SPEC
        // if (!(await Static.storageService.isLoggedAsync())) {
        //     this._userData = null;
        //     this._isLoggedIn = false;
        //     return;
        // }
        let authDataFromLocalStorage = (await Static.storageService.getLoggedAsync());

        // add computed data
        let newSessionAuthData = {
            ...(_tryGetAuthDataFromStore()),
            ...(authDataFromLocalStorage),
            isLoggedIn: authDataFromLocalStorage !== null,
            sign: 'useSessionManager',
        }
        _setAuthDataFromStore(newSessionAuthData);
    };

    const initSessionAuthAsync = async () => {
        if (_tryGetAuthDataFromStore()?.sign === 'useSessionManager') {
            //already exist
        } else {
            await loadAuthFromLocalStorageAsync();
        }
    }

    const setLoginData = async (SessionId, AuthData) => {
        let userData = (await Static.storageService.setLoggedAsync(SessionId, JSON.stringify(AuthData)));
    }

    const setLogout = async () => {
        await Static.storageService.clearLoggedAsync();
        await loadAuthFromLocalStorageAsync();

        // let newSessionAuthData = {
        //     userData: null,
        //     isLoggedIn: false,
        // }
        // _setAuthDataFromStore(newSessionAuthData);
    }

    const isMemberLoggedIn = function (): boolean | null {
        return _tryGetAuthDataFromStore()?.isLoggedIn ?? null;
    }

    const isMemberEqualToChannelId = function (nail_ChannelId: string): boolean {
        let data = _tryGetAuthDataFromStore();

        if (data === null)
            return false;

        if (data.AuthData?.__Item_號?.號Id == nail_ChannelId) {
            return true;
        }
        return false;
    }

    const getMember = function (): string | null {
        let data = _tryGetAuthDataFromStore();

        if (data === null)
            return null;

        return data.AuthData;
    }

    const getMemberActiveChannelId = function (): string | null {
        let data = _tryGetAuthDataFromStore();

        if (data === null)
            return null;

        return data.AuthData?.__Item_號?.號Id;
    }

    function TryForLoggedMember(jsxFunc) {
        if (isMemberLoggedIn() === true) {
            return jsxFunc();
        }
        return null;
    }

    function ForNotLoggedMember(jsxFunc) {
        if (isMemberLoggedIn() === false) {
            return jsxFunc();
        }
        return null;
    }

    function TryForLoggedOwnerMember(jsxFunc, nail_號Id) {
        if (isMemberLoggedIn() === true) {
            if (_tryGetAuthDataFromStore()?.AuthData?.__Item_號?.號Id === nail_號Id)
                return jsxFunc();
        }
        return null;
    }

    function TryForLoggedNotOwnerMember(jsxFunc, nail_號Id) {
        if (isMemberLoggedIn() === true) {
            if (_tryGetAuthDataFromStore()?.AuthData?.__Item_號?.號Id !== nail_號Id)
                return jsxFunc();
        }
        return null;
    }

    function ForNotLoggedOwnerMember(jsxFunc, nail_號Id) {
        if (isMemberLoggedIn() === true) {
            if (_tryGetAuthDataFromStore()?.AuthData?.__Item_號?.號Id !== nail_號Id)
                return jsxFunc();
        } else {
            return jsxFunc();
        }
        return null;
    }

    function getMemberActiveChannel() {
        if (isMemberLoggedIn() === true) {
            return _tryGetAuthDataFromStore()?.AuthData?.__Item_號 ?? null;
        } else {
            return null;
        }
    }


    function addMutableListener(listner: any) {
        mutableListeners.push(listner);
    }

    function removeMutableListener(listener: any) {
        for( let i = 0; i < mutableListeners.length; i++){
            if ( mutableListeners[i] === listener) {
                mutableListeners.splice(i, 1);
                i--;
            }
        }
    }

    return _refSelfInstance.current = {
        initSessionAuthAsync,
        setLoginData,
        isMemberLoggedIn,
        setLogout,
        isMemberEqualToChannelId,
        getMemberActiveChannelId,
        getMemberActiveChannel,
        hasInitOnce,

        addMutableListener,
        removeMutableListener,

        ForNotLoggedMember: ForNotLoggedMember,
        ForNotLoggedOwnerMember: ForNotLoggedOwnerMember,

        TryForLoggedMember: TryForLoggedMember,
        TryForLoggedOwnerMember: TryForLoggedOwnerMember,
        TryForLoggedNotOwnerMember: TryForLoggedNotOwnerMember,

        getMember,
    } as SessionManager;
}
