import { derived } from 'overmind';
import * as Global from '../global';

type IState = {
  error: any;
  currentUser: any;
  isLoggedIn: boolean;
}

export type State = {
  userSession: Global.UserInfo,
  roomTag: string[],
  colorList: Array<string>,
  specieList: Array<string>,
  breedList: Array<string>,
  timeShift: Array<string[]>,
  emergencyTime: string[],
}

export const state: any = {
  userSession: {
    username: '',
    password: ''
  },
  roomTag: [],
  colorList: [],
  specieList: [],
  breedList: [],
  timeShift: [
    [],[],[],[],[]
  ],
  emergencyTime: []
}
