import _ from './index';
import storage from 'local-storage-fallback';

export type AuthArea = {
    SessionId: string;
    AuthData: any;
}
class StorageService {
    constructor() {
    }

    async isLoggedAsync(): Promise<boolean> {
        let sessionId = await storage.getItem(StorageService.LOGIN_SESSION_KEY);
        if (sessionId) {
            return true;
        }
        return false;
    }

    public static LOGIN_SESSION_KEY = 'login_sessionId';
    public static LOGIN_MEMBERJSON_KEY = 'login_memberJson';

    async setLoggedAsync(sessionId: string, member_json_string: string): Promise<void> {
        await storage.setItem(StorageService.LOGIN_SESSION_KEY, sessionId);
        await storage.setItem(StorageService.LOGIN_MEMBERJSON_KEY, member_json_string);
    }

    async updateMemberAsync(member_json_string: string): Promise<void> {
        await storage.setItem(StorageService.LOGIN_MEMBERJSON_KEY, member_json_string);
    }

    async updateSessionAsync(sessionId: string): Promise<void> {
        await storage.setItem(StorageService.LOGIN_SESSION_KEY, sessionId);
    }

    async setEntLoggedAsync(newState: boolean = true): Promise<void> {
        await storage.setItem('login_ent', JSON.stringify(newState));
    }

    async getLoggedAsync(): Promise<AuthArea | null> {
        let sessionId = await storage.getItem(StorageService.LOGIN_SESSION_KEY);
        if (!sessionId) {
            return null;
        }
        let member_json_string = await storage.getItem(StorageService.LOGIN_MEMBERJSON_KEY);
        if (!member_json_string) {
            return null;
        }

        try {
            return {
                SessionId: sessionId,
                AuthData: JSON.parse(member_json_string),
            };
        } catch (err) {
            await this.clearLoggedAsync();
            return null;
        }
    }

    async clearLoggedAsync(): Promise<void> {
        await storage.removeItem(StorageService.LOGIN_SESSION_KEY);
        await storage.removeItem(StorageService.LOGIN_MEMBERJSON_KEY);
    }
}
export {StorageService}
