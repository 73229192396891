import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {
    Form as NForm, Popover, Button, Modal, message, Upload,
    Input, Select, Space, Tabs, Tooltip, Steps, DatePicker, TimePicker
} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";

import {
    LoadingOutlined,
    LockOutlined,
    PlusOutlined,
    RedoOutlined,
    CloseOutlined,
    UploadOutlined,
    LeftOutlined,
    RightOutlined
} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import moment from 'moment';
import Partial_Appointment__Pane_EditReservation from "./Partial_Appointment__Pane_EditReservation";


type ViewModelProps = {
    refModal: any,

    掛號No: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,

    viewModel: ViewModelProps,
};
export default function Partial_Appointment__Modal_EditReservation(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_Appointment__Modal_EditReservation';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel: any = props.viewModel;

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region Boot
    const _doLoadPartialData = async (): Promise<void> => {
        if (!pageDataManager.refPageData.current.Root) {
            return;
        }

        /// do not dynamically load data if condition fits.
        if (pageDataManager.isZoneExist(PrefixTarget)) {
            return;
        }
        //await _loadPageData();
    }
    useEffect(() => {
        _doLoadPartialData();
    }, []);
    //#endregion

    //#region Set Body
    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
        }

        if (viewModel.refModal || viewModel.refModal.current) {
            viewModel.refModal.current.destroy();
        }
    };
    const Body = () => {
        return (
            <div className="model_wrapper">
                <div className="modal_title">
                    <div className="title_info">
                        <h2>編輯預約</h2>
                    </div>
                    <div className="info_bar"></div>
                    <div className="control_bar">
                        <Tooltip placement="top" title={'關閉'}>
                            <Button type="text" icon={<CloseOutlined/>} onClick={handleClose}/>
                        </Tooltip>
                    </div>
                </div>
                <div className="modal_content">
                    <div className="content_box">
                        <div className="reservationbox">
                            {/*<div className="col s3_2">*/}
                            {/*    <div className="schedule">*/}
                            {/*        <Partial_Search_TimeRangeDoctorFilter*/}
                            {/*            PrefixTarget={'Partial_Reservation__Pane_ReservationUncheckedList'}*/}
                            {/*            pageDataManager={pageDataManager}*/}
                            {/*            sessionManager={sessionManager}*/}
                            {/*            navigate={navigate}*/}

                            {/*            viewModel={{*/}
                            {/*                refModal: null,*/}
                            {/*            }}*/}
                            {/*        />*/}
                            {/*        <Partial_ScheduleTable*/}
                            {/*            PrefixTarget={'Partial_ScheduleTable'}*/}
                            {/*            pageDataManager={pageDataManager}*/}
                            {/*            sessionManager={sessionManager}*/}
                            {/*            navigate={navigate}*/}

                            {/*            viewModel={{*/}
                            {/*                refModal: null,*/}
                            {/*            }}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="col rs_form">
                                <Partial_Appointment__Pane_EditReservation
                                    PrefixTarget={`Partial_Appointment__Pane_EditReservation${viewModel.掛號No}`}
                                    pageDataManager={pageDataManager}
                                    sessionManager={sessionManager}
                                    navigate={navigate}

                                    viewModel={{
                                        refModal: viewModel.refModal,

                                        掛號No: viewModel.掛號No ?? null,
                                    }}
                                />

                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal_footer"></div>
            </div>
        );
    };
    //#endregion

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_Appointment__Modal_EditReservation.displayName = 'Partial_Appointment__Modal_EditReservation';
