import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {
    Form as NForm,
    Tag,
    Button,
    Modal,
    message,
    Checkbox,
    Input,
    Select,
    Row,
    Table,
    Radio,
    Empty,
    InputNumber,
    Col,
    Spin
} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";

import {LoadingOutlined, LockOutlined, PlusOutlined, RedoOutlined, UserOutlined} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";
import UseCompState from "../hooks/UseCompState";

var moment = require('moment');


type ViewModelProps = {
    refModal: any,

    大分類: any,
    病歷No: any,

    OnDid?: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any;

    viewModel: ViewModelProps,
};
export default function Partial_MedicalRecord__Modal_MeasuringAdd_Items(props: Props) {
    const PrefixTarget: string = props.PrefixTarget || ('Partial_MedicalRecord__Modal_MeasuringAdd_Items' + props.viewModel.大分類);
    const pageDataManager: PageDataManager = props.pageDataManager;
    const sessionManager: SessionManager = props.sessionManager;
    const navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel = props.viewModel;

    const {Option} = Select;
    const {Search} = Input;
    const onSearch = value => console.log(value);
    const {TextArea} = Input;

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    //#region LoadingManager
    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});
    //#endregion

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

    }
    //#endregion

    //#region Boot
    let _refDoLoadPartialData: any = useRef<{ alwaysLoad?: boolean }>(null);
    useEffect(() => {
        const _loadPartialData = async (): Promise<void> => {

            // VALIDATE INPUT
            const p1 = viewModel.大分類;
            if (!p1) {
                alert('p1');
                return; //ERROR INPUTS
            }

            const p2 = viewModel.病歷No;
            if (!p2) {
                alert('p2');
                return; //ERROR INPUTS
            }


            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                p2,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/partial_medicalrecord/modal_measuringadd_items');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        let changeShard = {
                            [PrefixTarget]: {
                                ...payload,
                            }
                        }
                        pageDataManager.merge(changeShard);
                        pageDataManager.apply();

                        forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPartialData = async ({alwaysLoad = false}): Promise<void> => {
            // if (!pageDataManager.refPageData.current.Root) {
            //     return;
            // }

            /// do not dynamically load data if condition fits.
            if (!alwaysLoad) {
                if (pageDataManager.isZoneExist(PrefixTarget)) {
                    return;
                }
            }

            await _loadPartialData();
        }

        _refDoLoadPartialData.current = doLoadPartialData;
        _refDoLoadPartialData.current({});
    }, [pageDataManager.get(PrefixTarget)]);
    //#endregion

    //#region Set Body
    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };
    const Body = () => {
        const RegionEmptyShow = false;
        const RegionLoadingShow = true;
        const RegionInitialLoadingShow = false;

        const PassPageDataInitalized = pageDataManager.refPageData.current != null;
        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;

        let DataSource_測量範本 = state.DataSource_測量範本 = [];
        let List_測量範本 = pageDataManager.get(`${PrefixTarget}.List_測量範本`);
        if (List_測量範本 !== null) {
            let serialCounter = 0;
            DataSource_測量範本 = List_測量範本.map((Item_測量範本) => {
                serialCounter += 1;

                if (serialCounter == 1) {
                }

                return {
                    entity: Item_測量範本,
                    key: Item_測量範本.用藥範本No,

                }
            });

            state.DataSource_測量範本 = DataSource_測量範本;
        }

        let DataSource_測量歷史 = state.DataSource_測量歷史 = [];
        let List_測量_測量歷史 = pageDataManager.get(`${PrefixTarget}.List_測量_測量歷史`);
        if (List_測量_測量歷史 !== null) {
            let serialCounter = 0;
            DataSource_測量歷史 = List_測量_測量歷史.map((Item_測量_測量歷史) => {
                serialCounter += 1;

                if (serialCounter == 1) {
                }

                return {
                    entity: Item_測量_測量歷史,
                    key: Item_測量_測量歷史.測量No,

                    times: List_測量_測量歷史.length + 1 - serialCounter,
                    recordtime: Item_測量_測量歷史.時間 ? moment(Item_測量_測量歷史.時間).format('YYYY-MM-DD hh:mm') : '-',
                    supervisor: Item_測量_測量歷史?.__用戶_執行人員?.名稱,
                    data: Item_測量_測量歷史.紀錄數據,
                }
            });

            state.DataSource_測量歷史 = DataSource_測量歷史;
        }

        const List_醫生 = pageDataManager.get(`${PrefixTarget}.List_醫生`);
        let docOptions: any = [];
        {
            if (List_醫生) {
                docOptions = List_醫生.map((r) => {
                    return {
                        key: r.醫生No,
                        value: r.醫生No,
                        label: `${r.名稱} 醫師`,
                    }
                });
                docOptions.unshift({
                    key: '0',
                    value: '',
                    label: '請選擇',
                });
            }
        }

        //#region Handlers
        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            // if (!values.username) {
            //     errors.username = '↑ 欄位為必填';
            // } else {
            // }
            //
            // if (!values.password) {
            //     errors.password = '↑ 欄位為必填';
            // } else {
            // }
            //
            // if (!values.selected) {
            //     errors.selected = '↑ 欄位為必填';
            // }

            return errors;
        };

        const mockTags = ['Magenta', 'Lasvono', 'Jeseiuy'];

        const measureColumns = [
            {
                title: '次別',
                dataIndex: 'times',
            },
            {
                title: '紀錄時間',
                dataIndex: 'recordtime',

            },
            {
                title: '測量人員',
                dataIndex: 'supervisor',
            },
            {
                title: '數據',
                dataIndex: 'data',
            },
        ];

        const measureData = [
            {
                key: '3',
                times: '3',
                recordtime: '2021-5-12 10:22',
                supervisor: '林大發',
                data: '12kg'
            },
            {
                key: '2',
                times: '2',
                recordtime: '2021-4-9 11:27',
                supervisor: '林大發',
                data: '11.8kg'
            },
            {
                key: '1',
                times: '1',
                recordtime: '2021-3-15 10:48',
                supervisor: '李如雲',
                data: '11.5kg'
            },
        ]

        interface DataType {
            key: React.Key;
            name: string;
            id: string;
            cellphone: string;
            tel: string;
        }

        const handleSubmit_bringBack = (e: any, v: any) => {
            if (viewModel.OnDid) {
                const ret = viewModel.OnDid(v);
            } else {
                alert('onDone not defined');
            }
        }

        const key = 'updatable';

        const openMessage = () => {
            message.loading({content: '新增中...', key});
            setTimeout(() => {
                message.success({content: '新增成功!', key, duration: 2});
            }, 1000);
        };
        //#endregion

        if (!PassPrefixTargetLoaded) {
            if (RegionInitialLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <div>Loading.</div>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        if (loadingManager.getIsLoading()) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <Spin indicator={
                            <LoadingOutlined style={{fontSize: 24}} spin/>
                        }/>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        return (

            <Form
                onSubmit={(v) => handleSubmit_bringBack(null, v)}
                initialValues={List_測量範本?.[0]}
                validate={handleValidate}
                render={({
                             submitError,
                             handleSubmit,
                             submitting,
                             pristine,
                             valid,
                             error,
                             values
                         }) => {


                    return <React.Fragment>
                        <div className="form_body">
                            <form onSubmit={handleSubmit}>
                                <Row gutter={20}>
                                    <Col span={8}>
                                        <Field parse={_.bs.identity} name='用戶No_執行人員' validate={_.bs.Form.required}>
                                            {
                                                ({input, meta}) => (
                                                    <React.Fragment>
                                                        <NForm.Item {...input}
                                                                    name="dealwithdoctor"
                                                                    label='執行人員'
                                                        >
                                                            <Select {...input} placeholder="請選擇執行人員">
                                                                {docOptions.map((it => {
                                                                    return <Option
                                                                        value={it.value}>{it.label}</Option>
                                                                }))}
                                                            </Select>
                                                            {meta.touched && meta.error &&
                                                            <div className="error">
                                                                {meta.error}
                                                            </div>}
                                                        </NForm.Item>
                                                    </React.Fragment>
                                                )
                                            }
                                        </Field>
                                    </Col>
                                    <Col span={12}>
                                        <Field parse={_.bs.identity} name='紀錄數據' validate={_.bs.Form.required}>
                                            {
                                                ({input, meta}) => (
                                                    <React.Fragment>
                                                        <NForm.Item {...input}
                                                                    name="measure"
                                                                    label='檢測輸入'
                                                        >
                                                            <Input {...input} placeholder="輸入數值"/>
                                                            {meta.touched && meta.error &&
                                                            <div className="error">
                                                                {meta.error}
                                                            </div>}
                                                        </NForm.Item>
                                                    </React.Fragment>
                                                )
                                            }
                                        </Field>
                                    </Col>
                                </Row>

                            </form>
                            <Table
                                columns={measureColumns}
                                dataSource={DataSource_測量歷史}
                                size='small'

                                locale={{
                                    emptyText: <Empty description={
                                        <span>還沒有相關資料～</span>
                                    }/>
                                }}
                            />

                            <React.Fragment>
                                <NForm.Item>
                                    <div className="form_footer">
                                        <Button type="primary" htmlType="submit" size="large" onClick={handleSubmit}
                                        >新增</Button>
                                    </div>
                                </NForm.Item>
                            </React.Fragment>

                        </div>
                    </React.Fragment>;
                }}
            />
        );
    };
    //#endregion

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_MedicalRecord__Modal_MeasuringAdd_Items.displayName = 'Partial_MedicalRecord__Modal_MeasuringAdd_Items';
