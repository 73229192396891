import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {
    Form as NForm,
    Popover,
    Button,
    Modal,
    InputNumber,
    message,
    Tag,
    Table,
    Upload,
    Input,
    Select,
    Space,
    Tabs,
    Tooltip,
    Steps,
    Spin
} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';


import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";
import {LoadingOutlined, LockOutlined, EditOutlined, PlusOutlined, CloseOutlined} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_Appointment__Pane_AddOwner from "./Partial_Appointment__Pane_AddOwner";
import Partial_MedicalRecord__Modal_ProcessBillingAdd from "./Partial_MedicalRecord__Modal_ProcessBillingAdd";
import Partial_MedicalRecord__Modal_PrescribeAdd from "./Partial_MedicalRecord__Modal_PrescribeAdd";
import UseCompState from "../hooks/UseCompState";
import Partial_Modal_Confirm from "./Partial_Modal_Confirm";


var moment = require('moment');
const {Option} = Select;
const {Search} = Input;
const onSearch = value => console.log(value);
const {TabPane} = Tabs;
const {Step} = Steps;


let ChargeBox: any = (props, ref) => {
    //const inputRef = useRef();

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

        state.forceRender = forceRender;
    }
    //#endregion

    // useEffect(() => {
    //     setInterval(()=> {
    //
    //
    //     }, 3000)
    // }, []);


    useImperativeHandle(ref, () => ({
        calculate: () => {
            let 總金額: number = 0;
            state.parentState.DataSource_病歷項.map((Item) => {
                let 小計: number = (+Item.單價) * (+Item.數量);
                Item.小計 = 小計;
                Item.entity.消費總額 = Item.小計;
                總金額 += 小計;
                return;
            });
            state.parentState.DataSource_病歷項.總金額 = 總金額;

            forceRender();
        }
    }));

    return <div className="chargebox">
        <div className="item total">
            <div className="th">總金額</div>
            <div className="con">
                $ {_.util.commaSep(state.parentState.DataSource_病歷項.總金額 ?? 0)}
            </div>
        </div>

        {/*<div className="item tax">*/}
        {/*    <div className="th">稅金(%)</div>*/}
        {/*    <div className="con">*/}
        {/*        $125*/}
        {/*    </div>*/}
        {/*</div>*/}
        {/*<div className="item saleoff">*/}
        {/*    <div className="th">折扣碼</div>*/}
        {/*    <div className="con">*/}
        {/*        -$450*/}
        {/*    </div>*/}
        {/*</div>*/}
        {/*<div className="item saleoff">*/}
        {/*    <div className="th">折扣碼</div>*/}
        {/*    <div className="con">*/}
        {/*        -$450*/}
        {/*    </div>*/}
        {/*</div>*/}
        {/*<div className="item saleoff">*/}
        {/*    <div className="th">折扣碼</div>*/}
        {/*    <div className="con">*/}
        {/*        -$450*/}
        {/*    </div>*/}
        {/*</div>*/}
        {/*<div className="item func">*/}
        {/*    <Button type="primary" ghost icon={<PlusOutlined/>}>添加折扣/加價</Button>*/}
        {/*</div>*/}
        <div className="item intotal">
            <div className="th">應收</div>
            <div className="con">
                $ {state.parentState.DataSource_病歷項.總金額}
            </div>
        </div>
    </div>
}
ChargeBox = forwardRef(ChargeBox);

type ViewModelProps = {
    refModal: any,

    病歷No: any,
    掛號No: any,

    OnDid?: any;
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any,

    viewModel: ViewModelProps,
};
export default function Partial_MedicalRecord__Modal_ProcessBilling(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_MedicalRecord__Modal_ProcessBilling';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel = props.viewModel;

    let refChargeBox = useRef<any>();


    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion


    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

        state.forceRender = forceRender;
        state.DataSource_病歷項 = null;
        state.List_病歷項 = null;
    }
    //#endregion

    //#region Boot

    //#region No Cache
    useEffect(() => {
        return () => {
            pageDataManager.emptyZone(PrefixTarget);
            pageDataManager.apply();
        }
    }, []);
    //#endregion

    let _refDoLoadPartialData: any = useRef<{ alwaysLoad?: boolean }>(null);
    useEffect(() => {
        const _loadPartialData = async (): Promise<void> => {

            // VALIDATE INPUT
            const p1 = viewModel.掛號No;
            if (!p1) {
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/partial_medicalrecord/modal_processbilling');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        let changeShard = {
                            [PrefixTarget]: {
                                ...payload,
                            }
                        }
                        pageDataManager.merge(changeShard);
                        pageDataManager.apply();

                        forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPartialData = async ({alwaysLoad = false}): Promise<void> => {
            // if (!pageDataManager.refPageData.current.Root) {
            //     return;
            // }

            /// do not dynamically load data if condition fits.
            if (!alwaysLoad) {
                if (pageDataManager.isZoneExist(PrefixTarget)) {
                    return;
                }
            }

            await _loadPartialData();
        }

        _refDoLoadPartialData.current = doLoadPartialData;
        _refDoLoadPartialData.current({});
    }, [pageDataManager.get(PrefixTarget)]);
    //#endregion

    //#region Set Body
    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };
    const Body = () => {
        const RegionEmptyShow = false;
        const RegionLoadingShow = true;
        const RegionInitialLoadingShow = false;

        const PassPageDataInitalized = pageDataManager.refPageData.current != null;
        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;

        let DataSource_病歷項 = state.DataSource_病歷項;
        if (DataSource_病歷項 === null) {
            let List_病歷項 = pageDataManager.get(`${PrefixTarget}.List_病歷項`);
            state.List_病歷項 = List_病歷項;

            if (List_病歷項 !== null) {

                let calc_amount: number = 0;
                let serialCounter = 0;
                DataSource_病歷項 = List_病歷項.filter(r=>r.項目 != '批價').map((Item_病歷項) => {
                    serialCounter += 1;

                    if (serialCounter == 1) {
                    }

                    calc_amount += (Item_病歷項.消費總額 ?? 0);

                    return {
                        entity: Item_病歷項,
                        key: Item_病歷項.病歷項No,

                        // 分類組: Item_用藥.分類組,
                        // 品牌: Item_用藥.品牌,
                        // 品名: Item_用藥.品名,
                        // 庫存量: Item_用藥.庫存量,
                        // 貨品代號: Item_用藥.貨品代號,
                        //
                        // 份量: Item_用藥.份量,
                        // 用量: Item_用藥.用量,
                        // 藥劑處置: Item_用藥.藥劑處置,

                        收費名稱: Item_病歷項.項目,
                        單價: Item_病歷項.消費總額,
                        數量: 1,
                        小計: Item_病歷項.消費總額 ?? 0,
                    }
                });

                DataSource_病歷項.總金額 = calc_amount;
                state.DataSource_病歷項 = DataSource_病歷項;


                // state.List_批價 = [
                //     {
                //         掛號No: viewModel.掛號No,
                //         總金額: calc_amount,
                //     }
                // ]
            } else {
                DataSource_病歷項 = [];
            }
        }

        //#region Handlers


        const handleSubmit_saveRecord = (e: any, v: any) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }

            //#region Handle SaveUserInfo
            {
                if (Number.isNaN(state.DataSource_病歷項.總金額)) {
                    alert('有非數字的項目，請修正');
                    return;
                }


                // VALIDATE INPUT
                const p1 = v.病歷No;
                if (!p1) {
                    _._debug.error('p1');
                    return; //ERROR INPUTS
                }
                const p2 = [
                    {
                        掛號No: viewModel.掛號No,
                        總金額: state.DataSource_病歷項.總金額,
                    }
                ];
                if (!p2) {
                    _._debug.error('p2');
                    return; //ERROR INPUTS
                }
                const p3 = v.掛號No;
                if (!p3) {
                    alert('沒有掛號無法寫入')
                    _._debug.error('p3');
                    return; //ERROR INPUTS
                }

                const p4 = state.List_病歷項;
                if (!p4) {
                    _._debug.error('p4');
                    return; //ERROR INPUTS
                }

                let hide = message.loading({
                    type: 'loading',
                    className: 'myMessage',
                    content: <div className="myMessageBody">驗證中...</div>,
                    key: 'loading',
                    duration: 0
                });

                // WAITING SCREEN
                loadingManager.changeLoadingCount(+1);
                //StaticEvent.LoadingEvent.next(true);

                // POST
                let postData = {
                    p1,
                    p2,
                    p3,
                    p4,
                    timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
                };
                const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_medicalrecord/modal_processbilling/save');
                const post$ = Static.apiService.post(postUrl, postData);
                post$.subscribe(async (res: any) => {
                        hide();

                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);

                        let data = res.data;
                        let error = data.error || null;
                        let result = error == null;

                        if (result) { // SUCEESS
                            let payload = data.payload;
                            _._debug.warn(payload);

                            message.success({content: '已送出納處!', key, duration: 2});
                            // const openMessage = () => {
                            //     message.loading({content: '正努力送往藥局...', key});
                            //     setTimeout(() => {
                            //         message.success({content: '藥局確認了你的藥單!', key, duration: 2});
                            //     }, 1000);
                            // };
                            //
                            // const saveMessage = () => {
                            //     message.loading({content: '儲存中...', key});
                            //     setTimeout(() => {
                            //         message.success({content: '儲存成功!', key, duration: 2});
                            //     }, 1000);
                            // };
                            //
                            //
                            // let hide = message.loading({
                            //     type: 'loading',
                            //     className: 'myMessage',
                            //     content: <div className="myMessageBody">建立成功</div>,
                            //     key: 'loading',
                            //     duration: 5
                            // });

                            handleClose(null);

                            state.parentState?._refDoLoadPartialData?.current({alwaysLoad: true});

                            // Static.NavAwayToAppointment(navigate, {
                            //     s: '手機號碼',
                            //     q: payload.Item_飼主.手機,
                            // })(null);

                            // let carryData = _._u.update(payload,
                            //     {
                            //         $merge: {
                            //             Tokens: payload?.Tokens,
                            //         },
                            //         Item_飼主: {
                            //             $set: payload?.Item_飼主,
                            //         }
                            //     }
                            // );
                            // carryData.Item_飼主 = p1;
                            // if (onDone) {
                            //     const ret = onDone(carryData);
                            //     if (ret === 'demo') {
                            //         createModalPet(null, {
                            //             carryData,
                            //         })
                            //     }
                            // } else {
                            //     alert('onDone not defined');
                            // }

                            //handleClose(null)

                        } else {
                            // AUTH USER EXPIRED
                            if (error.code == 2) {
                                //alert('帳號被登出，請重新登入');
                                //window.location.href = '/login';
                                //this.props.history.push('/login')
                                await sessionManager.setLogout();
                                pageDataManager.clear();
                                pageDataManager.reloadAsync({alwaysLoad: true});
                                alert('時間逾時已被登出');
                                return;
                            }

                            _._debug.error(error);
                            alert(error.message);
                        }
                    }, async (err) => {
                        hide();

                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);
                        _._debug.warn(err);
                        alert('網路連線有問題');
                    }
                );
                return lastValueFrom(
                    post$
                );
            }
            //#endregion
        }

        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            if (!values.username) {
                errors.username = '↑ 欄位為必填';
            } else {
            }

            if (!values.password) {
                errors.password = '↑ 欄位為必填';
            } else {
            }

            return errors;
        };

        const handleClose = (e) => {
            if (e) {
                e.preventDefault();
            }

            if (viewModel.refModal || viewModel.refModal.current) {
                viewModel.refModal.current.destroy();
            }
        };


        //#region :Popup :createModalPrescribeTool
        const createModalPrescribeTool_refModal = useRef<any>(null);
        const createModalPrescribeTool = (e: any, tasks?: string[]): void => {
            if (e) {
                e.preventDefault();
            }
            // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
            //
            // if (!PassPageDataInitalized) {
            //     //empty
            // }

            const config = {
                // title: 'test',
                content: <Partial_MedicalRecord__Modal_PrescribeAdd
                    PrefixTarget={'Partial_MedicalRecord__Modal_PrescribeAdd'}
                    pageDataManager={pageDataManager}
                    sessionManager={sessionManager}
                    navigate={navigate}

                    viewModel={{
                        refModal: createModalPrescribeTool_refModal,

                        ///*Add
                        OnDid: () => {
                            //@own state got changed, then
                            forceRender();
                            handleClose(null);
                        },
                    }}
                />,
                okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
                className: 'myClass myModal',
                width: '80em',
                height: ' ',
                footer: null,
                //prefixCls: '',
            };

            createModalPrescribeTool_refModal.current = Modal.info(config);
        }
        //#endregion

        const mockTags = ['注射', '手術', '預防'];

        const ChargeColumns = [
            {
                dataIndex: 'chargetype',
                title: '收費分類',

                render: () => {
                    return <div>
                        <Select defaultValue="門診">
                            <Option value="門診">門診</Option>
                            <Option value="售貨">售貨</Option>
                            <Option value="美容">美容</Option>
                            <Option value="急診">急診</Option>
                        </Select>
                    </div>
                },
            },
            {
                dataIndex: '收費名稱',
                title: '收費名稱',
                width: '25%',
            },
            {
                dataIndex: '單價',
                title: '單價',
                width: '20%',
                render: (number, record) => {
                    return <div>
                        $ <Input placeholder="請輸入單價" defaultValue={number} onChange={(el) => {
                        let value = el.target.value;
                        record.單價 = value;

                        refChargeBox?.current?.calculate();
                        forceRender();

                        //record.triggerUpdate = (record.triggerUpdate ?? 0) + 1;
                    }}/> X
                    </div>
                },
            },
            {
                dataIndex: '數量',
                title: '數量',

                render: (number, record) => {
                    return <div>
                        <InputNumber min={0} max={10} defaultValue={number} onChange={(value) => {
                            record.數量 = value;

                            refChargeBox?.current?.calculate();
                            forceRender();

                            //record.triggerUpdate = (record.triggerUpdate ?? 0) + 1;
                        }}/>
                    </div>
                },
            },
            {
                dataIndex: '小計',
                title: '小計',
                render: (number, record) => {
                    //let amount: number = record.單價 * record.數量;

                    return <span>{_.util.commaSep(record.小計 ?? 0)}</span>
                },
            },
            {
                dataIndex: '管理',
                title: '管理',
                render: (text, record) => {

                    return <div>
                        <Space>
                            <Tooltip placement="top" title="刪除">
                                <Button icon={<CloseOutlined/>} type="text" danger/>
                            </Tooltip>
                        </Space>
                    </div>
                },
            },
        ];

        const ChargeDate = [
            {
                chargeItem: 'ABC',
                unitPrice: 350,
                quantity: 1,
                subtotal: '$350',
            },
            {
                chargeItem: 'ABC',
                unitPrice: 350,
                quantity: 1,
                subtotal: '$350',
            },
            {
                chargeItem: 'ABC',
                unitPrice: 350,
                quantity: 1,
                subtotal: '$350',
            },
            {
                chargeItem: 'ABC',
                unitPrice: 350,
                quantity: 1,
                subtotal: '$350',
            },
            {
                chargeItem: 'ABC',
                unitPrice: 350,
                quantity: 1,
                subtotal: '$350',
            },
            {
                chargeItem: 'ABC',
                unitPrice: 350,
                quantity: 1,
                subtotal: '$350',
            },
            {
                chargeItem: 'ABC',
                unitPrice: 350,
                quantity: 1,
                subtotal: '$350',
            },
            {
                chargeItem: 'ABC',
                unitPrice: 350,
                quantity: 1,
                subtotal: '$350',
            },

        ];


        const PrescribeHistoryColumn = [
            {
                dataIndex: 'date',
                title: '日期',
            },

            {
                dataIndex: 'doctor',
                title: '開藥醫生'
            },
            {
                dataIndex: 'prescribeAmount',
                title: '開藥項目'
            },
            {
                dataIndex: 'stauts',
                title: '狀態',
            },
            {
                dataIndex: 'view',
                title: '查看',
                render: (text, record) => <a type="link">查看</a>
            }
        ];

        const PrescribeHistoryDate = [
            {
                date: '2021-07-21 15:17',
                doctor: '林隆璇 醫師',
                prescribeAmount: '1',
                stauts: '已配藥',
            },
            {
                date: '2021-08-01 09:30',
                doctor: '王帶鋼 醫師',
                prescribeAmount: '3',
                stauts: '藥局退回',
            },
            {
                date: '2021-08-02 12:42',
                doctor: '游登傑 醫師',
                prescribeAmount: '12',
                stauts: '未配藥',
            },
            {
                date: '2021-08-02 12:42',
                doctor: '游登傑 醫師',
                prescribeAmount: '12',
                stauts: '未配藥',
            },
            {
                date: '2021-08-02 12:42',
                doctor: '游登傑 醫師',
                prescribeAmount: '12',
                stauts: '未配藥',
            },
            {
                date: '2021-08-02 12:42',
                doctor: '游登傑 醫師',
                prescribeAmount: '12',
                stauts: '未配藥',
            },
            {
                date: '2021-08-02 12:42',
                doctor: '游登傑 醫師',
                prescribeAmount: '12',
                stauts: '未配藥',
            },
            {
                date: '2021-08-02 12:42',
                doctor: '游登傑 醫師',
                prescribeAmount: '12',
                stauts: '未配藥',
            },
            {
                date: '2021-08-02 12:42',
                doctor: '游登傑 醫師',
                prescribeAmount: '12',
                stauts: '未配藥',
            }
        ];

        const key = 'updatable';
        const openMessage = () => {
            message.loading({content: '正努力送往藥局...', key});
            setTimeout(() => {
                message.success({content: '藥局確認了你的藥單!', key, duration: 2});
            }, 1000);
        };

        const saveMessage = () => {
            message.loading({content: '儲存中...', key});
            setTimeout(() => {
                message.success({content: '儲存成功!', key, duration: 2});
            }, 1000);
        };

        //#endregion

        if (!PassPrefixTargetLoaded) {
            if (RegionInitialLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <div>Loading.</div>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        if (loadingManager.getIsLoading()) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <Spin indicator={
                            <LoadingOutlined style={{fontSize: 24}} spin/>
                        }/>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        return (
            <div className="model_wrapper">
                <div className="modal_title">
                    <div className="title_info">
                        <h2>批價</h2>
                    </div>
                    <div className="info_bar"></div>
                    <div className="control_bar">
                        <Tooltip placement="top" title={'關閉'}>
                            <Button type="text" icon={<CloseOutlined/>} onClick={handleClose}/>
                        </Tooltip>
                    </div>
                </div>
                <div className="modal_content">
                    <div className="content_box">
                        {/*<div className="infocontent">*/}
                        {/*    <h4>基本資料核對</h4>*/}
                        {/*    <div className="infobox _h">*/}
                        {/*        <div className="info">*/}
                        {/*            <div className="th">寵物名子：</div>*/}
                        {/*            <div className="content">測試動物</div>*/}
                        {/*        </div>*/}
                        {/*        <div className="info">*/}
                        {/*            <div className="th">飼主姓名：</div>*/}
                        {/*            <div className="content">測試員</div>*/}
                        {/*        </div>*/}
                        {/*        <div className="info">*/}
                        {/*            <div className="th">聯絡電話：</div>*/}
                        {/*            <div className="content">0912345678</div>*/}
                        {/*        </div>*/}
                        {/*        <div className="info">*/}
                        {/*            <div className="th">開藥醫師：</div>*/}
                        {/*            <div className="content">劉德華 醫師</div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="topbar_plug">
                            <div className="title _h">
                                {/* <div className="info">
                                    <div className="th">批價編號：</div>
                                    <div className="content">P2108021735001</div>
                                </div>
                                <div className="info">
                                    <div className="th">批價狀態：</div>
                                    <div className="content">未送出</div>
                                </div>
                                <div className="info">
                                    <div className="th">出納回覆：</div>
                                    <div className="content">--</div>
                                </div> */}
                            </div>
                            {/*<div className="func">*/}
                            {/*    <Button icon={<PlusOutlined/>} type="primary" ghost*/}
                            {/*            onClick={createModalPrescribeTool}>添加批價項目</Button>*/}
                            {/*</div>*/}
                        </div>
                        <div className="content_box cd_modal">

                            <div className="boxs2">
                                {/* <div className="boxitem bk_func">


                                    <Partial_MedicalRecord__Modal_ProcessBillingAdd
                                        PrefixTarget={'Partial_MedicalRecord__Modal_ProcessBillingAdd'}
                                        pageDataManager={pageDataManager}
                                        sessionManager={sessionManager}
                                        navigate={navigate}
                                        parentState={state}

                                        viewModel={{
                                            refModal: null,

                                            ///*Add
                                            OnDid: () => {
                                                //@own state got changed, then
                                                forceRender();
                                                handleClose(null);
                                            },
                                        }}
                                    />

                                </div> */}
                                <div className="boxitem flexon">
                                    <h4>批價明細</h4>
                                    <Table
                                        columns={ChargeColumns}
                                        dataSource={DataSource_病歷項}
                                        size="small"
                                        scroll={{y: 34 + 'vh'}}
                                        pagination={false}
                                    />
                                    <ChargeBox ref={refChargeBox} parentState={state}/>

                                    {/*<div className="chargebox">*/}
                                    {/*    <div className="item total">*/}
                                    {/*        <div className="th">總金額</div>*/}
                                    {/*        <div className="con">*/}
                                    {/*            $ {DataSource_病歷項.總金額}*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}

                                    {/*    /!*<div className="item tax">*!/*/}
                                    {/*    /!*    <div className="th">稅金(%)</div>*!/*/}
                                    {/*    /!*    <div className="con">*!/*/}
                                    {/*    /!*        $125*!/*/}
                                    {/*    /!*    </div>*!/*/}
                                    {/*    /!*</div>*!/*/}
                                    {/*    /!*<div className="item saleoff">*!/*/}
                                    {/*    /!*    <div className="th">折扣碼</div>*!/*/}
                                    {/*    /!*    <div className="con">*!/*/}
                                    {/*    /!*        -$450*!/*/}
                                    {/*    /!*    </div>*!/*/}
                                    {/*    /!*</div>*!/*/}
                                    {/*    /!*<div className="item saleoff">*!/*/}
                                    {/*    /!*    <div className="th">折扣碼</div>*!/*/}
                                    {/*    /!*    <div className="con">*!/*/}
                                    {/*    /!*        -$450*!/*/}
                                    {/*    /!*    </div>*!/*/}
                                    {/*    /!*</div>*!/*/}
                                    {/*    /!*<div className="item saleoff">*!/*/}
                                    {/*    /!*    <div className="th">折扣碼</div>*!/*/}
                                    {/*    /!*    <div className="con">*!/*/}
                                    {/*    /!*        -$450*!/*/}
                                    {/*    /!*    </div>*!/*/}
                                    {/*    /!*</div>*!/*/}
                                    {/*    /!*<div className="item func">*!/*/}
                                    {/*    /!*    <Button type="primary" ghost icon={<PlusOutlined/>}>添加折扣/加價</Button>*!/*/}
                                    {/*    /!*</div>*!/*/}
                                    {/*    <div className="item intotal">*/}
                                    {/*        <div className="th">應收</div>*/}
                                    {/*        <div className="con">*/}
                                    {/*            $ {DataSource_病歷項.總金額}*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
                <div className="modal_footer">
                    <Space>
                        <Button onClick={handleClose}>取消</Button>
                        <Button type='primary' size='large' onClick={(e) => {
                            handleSubmit_saveRecord(e, {
                                病歷No: viewModel.病歷No,
                                List_批價: state.List_批價,
                                掛號No: viewModel.掛號No,
                            })
                        }}>送出</Button>
                    </Space>
                </div>
            </div>
        );
    };
    //#endregion

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_MedicalRecord__Modal_ProcessBilling.displayName = 'Partial_MedicalRecord__Modal_ProcessBilling';
