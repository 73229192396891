import React, {useCallback, useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {
    Form as NForm,
    Avatar,
    Button,
    Modal,
    message,
    Popconfirm,
    Input,
    Space,
    Select,
    Collapse,
    DatePicker,
    Comment,
    Tooltip,
    List
} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";

import {
    LoadingOutlined,
    LockOutlined,
    PlusOutlined,
    RedoOutlined,
    UserOutlined,
    EditOutlined,
    UploadOutlined,
    DeleteFilled, MessageFilled
} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";
import UseCompState from "../hooks/UseCompState";

import Partial_MedicalRecord__Modal_IHRecord from "./Partial_MedicalRecord__Modal_IHRecord";
import Ln_AvatorImg from "./Ln_AvatorImg";
import Ln_AvatorImgHref from "./Ln_AvatorImgHref";

var moment = require('moment');


const {Option} = Select;
const {Panel} = Collapse;
const dateFormat = 'YYYY/MM/DD';

const {TextArea} = Input;


const Editor = ({onChange, onSubmit, submitting, value}) => (
    <>
        <NForm.Item>
            <TextArea rows={4} onChange={onChange} value={value}/>
        </NForm.Item>
        <NForm.Item>
            <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
                送出留言
            </Button>
        </NForm.Item>
    </>
);

class Reply extends React.Component<any> {
    state = {
        comments: [],
        submitting: false,
        value: '',
    };


    handleSubmit = () => {
        if (!this.state.value) {
            alert('沒有內容');
            return;
        }

        this.setState({
            submitting: true,
        });

        this.props.onSubmit?.(this.state.value);


        setTimeout(() => {
            this.setState({
                submitting: false,
                value: '',
                comments: [
                    ...this.state.comments,
                    {
                        author: 'Han Solo',
                        avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
                        content: <p>{this.state.value}</p>,
                        datetime: moment().fromNow(),
                    },
                ],
            });
        }, 1000);


    };

    handleChange = e => {
        this.setState({
            value: e.target.value,
        });
    };


    render() {
        const {comments, submitting, value} = this.state;

        return (
            <>

                <Comment
                    avatar={
                        <Avatar
                            src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                            alt="Han Solo"
                        />
                    }
                    content={
                        <Editor
                            onChange={this.handleChange}
                            onSubmit={this.handleSubmit}
                            submitting={submitting}
                            value={value}
                        />
                    }
                />
            </>
        );
    }
}

type ViewModelProps = {
    refModal: any,

    留言板No: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any,

    viewModel: ViewModelProps,
};

export default function Partial_inHospital__Pane_MsgBoard(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || ('Partial_inHospital__Pane_MsgBoard' + props.viewModel.留言板No);
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel = props.viewModel;

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

    }
    //#endregion

    //#region Boot
    let _refDoLoadPartialData: any = useRef<{ alwaysLoad?: boolean }>(null);
    useEffect(() => {
        const _loadPartialData = async (): Promise<void> => {

            // VALIDATE INPUT
            //const id : any = this.props.match.params.id;
            const p1 = viewModel.留言板No;
            if (!p1) {
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,

                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/partial_inhospital/pane_msgboard');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        let changeShard = {
                            [PrefixTarget]: {
                                ...payload,
                            }
                        }
                        pageDataManager.merge(changeShard);
                        pageDataManager.apply();

                        forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPartialData = async ({alwaysLoad = false}): Promise<void> => {
            // if (!pageDataManager.refPageData.current.Root) {
            //     return;
            // }

            /// do not dynamically load data if condition fits.
            if (!alwaysLoad) {
                if (pageDataManager.isZoneExist(PrefixTarget)) {
                    return;
                }
            }

            await _loadPartialData();
        }

        _refDoLoadPartialData.current = doLoadPartialData;
        _refDoLoadPartialData.current({});
    }, [pageDataManager.get(PrefixTarget)]);
    //#endregion

    const layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 16},
    };

    const layout02 = {
        labelCol: {span: 0},
        wrapperCol: {span: 25},
    };

    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };

    const Body = () => {

        const RegionEmptyShow = true;
        const RegionLoadingShow = false;

        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;

        let List_留言板留言 = pageDataManager.get(`${PrefixTarget}.List_留言板留言`);


        const ShowComment = () => {
            let [isOn, setIsOn] = useState(true);
            let toggleState = () => setIsOn(prevState => !prevState);

            let showTitle = () => isOn ? null : <Reply onSubmit={(content) =>
                handleSubmit_reply(null, {
                    留言板No: viewModel.留言板No,
                    留言內容: content,
                })}/>;

            return (
                <div>
                    <Button icon={<MessageFilled/>} type="link" onClick={toggleState}>新增註記</Button>
                    {showTitle()}
                    {/* { showResults ? null : <ShowComment/> } */}
                </div>
            )
        }

        const handleSubmit_reply = (e: any, v: any) => {
            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            const p1 = v.留言板No;
            if (!p1) {
                _._debug.error(p1);
                return; //ERROR INPUTS
            }
            const p2 = v.留言內容;
            if (!p2) {
                _._debug.error(p2);
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                p2,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_inhospital/pane_msgboard/reply');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);


                        let hide = message.loading({
                            type: 'loading',
                            className: 'myMessage',
                            content: <div className="myMessageBody">修改完成</div>,
                            key: 'loading',
                            duration: 2
                        });

                        //handleClose(null);

                        // if (viewModel.用戶No === null) { //is insert
                        //     StaticEvent.castUpdateEvent({
                        //         command: StaticEvent.UpdateFacade.Command.Add_用戶,
                        //         Add_用戶: {
                        //             用戶No: payload.Item_用戶.用戶No,
                        //         }
                        //     });
                        // } else {
                        //     StaticEvent.castUpdateEvent({
                        //         command: StaticEvent.UpdateFacade.Command.Update_用戶,
                        //         Update_用戶: {
                        //             用戶No: payload.Item_用戶.用戶No,
                        //         }
                        //     });
                        // }

                        // let carryData = _._u.update(payload,
                        //     {
                        //         $merge: {
                        //             Tokens: payload?.Tokens,
                        //         },
                        //         Item_飼主: {
                        //             $set: payload?.Item_飼主,
                        //         }
                        //     }
                        // );
                        // carryData.Item_飼主 = p1;
                        // if (onDone) {
                        //     const ret = onDone(carryData);
                        //     if (ret === 'demo') {
                        //         createModalPet(null, {
                        //             carryData,
                        //         })
                        //     }
                        // } else {
                        //     alert('onDone not defined');
                        // }

                        _refDoLoadPartialData.current({alwaysLoad: true});

                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }

        if (!PassPrefixTargetLoaded) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <div>Loading.</div>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }


        if (List_留言板留言 && List_留言板留言.length > 0) {
            return <React.Fragment>
                <div className="conbox">
                    {List_留言板留言.map(Item_留言板留言 => {
                        return <React.Fragment key={Item_留言板留言.留言板留言No ?? 0}>
                            <Comment
                                author={<span>{Item_留言板留言._用戶.名稱}</span>}
                                avatar={
                                    <Avatar
                                        src={Ln_AvatorImgHref({
                                            名稱: Item_留言板留言._用戶.名稱,
                                            照片組: Item_留言板留言._用戶.照片組
                                        })}
                                        alt={Item_留言板留言._用戶.名稱}
                                    />
                                }
                                content={
                                    <p>
                                        {Item_留言板留言.留言內容}
                                    </p>
                                }
                                datetime={
                                    <Tooltip
                                        title={moment(Item_留言板留言.ModifiedAt ?? Item_留言板留言.CreatedAt).format('YYYY-MM-DD HH:mm:ss')}>
                                        <span>{moment(Item_留言板留言.ModifiedAt ?? Item_留言板留言.CreatedAt).fromNow()}</span>
                                    </Tooltip>
                                }
                            />
                        </React.Fragment>
                    })}

                    <ShowComment/>
                </div>
            </React.Fragment>;
        } else if (List_留言板留言 && List_留言板留言.length === 0) {
            if (RegionEmptyShow) {
                return <React.Fragment>
                    <ShowComment/>
                </React.Fragment>;
            }
        }

        return null;
    }

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_inHospital__Pane_MsgBoard.displayName = 'Partial_inHospital__Pane_MsgBoard';
