import React, { useCallback, useEffect, useRef, useState } from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {
    Form as NForm,
    Popover,
    Button,
    Modal,
    message,
    Upload,
    Input,
    Space,
    Select,
    Collapse,
    DatePicker,
    Checkbox, Tooltip, Typography
} from 'antd';

import { ApiService } from "../_/ApiService";
import { Static } from "../_/Static";
import { StaticEvent } from "../_/StaticEvent";
import _ from "../_";

import { Link } from 'react-router-dom';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import { PageDataManager } from "../hooks/UsePageDataManager";

import { Field, Form } from "react-final-form";
import { SessionManager } from "../hooks/UseSessionManager";
import { NavigateFunction } from "react-router";

import { LoadingOutlined, LockOutlined, PlusOutlined, RedoOutlined, UserOutlined, EditOutlined, UploadOutlined, DeleteFilled } from "@ant-design/icons";
import { lastValueFrom } from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";
import UseCompState from "../hooks/UseCompState";

import Partial_Appointment__Modal_AddPet from "./Partial_Appointment__Modal_AddPet";
import ImgCrop from "antd-img-crop";
import Partial_Manage_Staff__Modal_EditOrAddStaff from "./Partial_Manage_Staff__Modal_EditOrAddStaff";
import Ln_AvatorImg from "./Ln_AvatorImg";

var moment = require('moment');
const { Option } = Select;
const { Panel } = Collapse;
const dateFormat = 'YYYY/MM/DD';

const { TextArea } = Input;
const {Title} = Typography;

type ViewModelProps = {
    Item_用戶: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any,
    viewModel: ViewModelProps,
};

export default function Partial_Manage_Staff__Info(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_Manage_Staff__Info';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({ shouldReRenderOnLoading: false });

    //#region Boot
    let _refDoLoadPartialData: any = useRef<{ alwaysLoad?: boolean }>(null);
    useEffect(() => {
        // const _loadPartialData = async (): Promise<void> => {
        //
        //     // VALIDATE INPUT
        //     const moreParams:any = {};
        //     const p1 = viewModel.用戶No;
        //     if (!p1) {
        //         //return; //ERROR INPUTS
        //     } else {
        //         moreParams.p1 = p1;
        //     }
        //
        //     // WAITING SCREEN
        //     loadingManager.changeLoadingCount(+1);
        //     //StaticEvent.LoadingEvent.next(true);
        //
        //     // POST
        //     let postData = {
        //         ...moreParams,
        //         timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
        //     };
        //     const postUrl = ApiService.urlMapping('~/pet_api/1/partial_modalbody_editstaff');
        //     const post$ = Static.apiService.post(postUrl, postData);
        //     post$.subscribe(async (res: any) => {
        //             // CLOSE WAITING SCREEN
        //             loadingManager.changeLoadingCount(-1);
        //             //StaticEvent.LoadingEvent.next(false);
        //
        //             let data = res.data;
        //             let error = data.error || null;
        //             let result = error == null;
        //
        //             if (result) { // SUCEESS
        //                 let payload = data.payload;
        //                 _._debug.warn(payload);
        //
        //                 //delete pageDataManager.refPageData.current[TabControlState]?.['MyCollectionTab_data'];
        //
        //                 pageDataManager.emptyZone(PrefixTarget);
        //                 let changeShard = {
        //                     [PrefixTarget]: {
        //                         ...payload,
        //                     }
        //                 }
        //                 pageDataManager.merge(changeShard);
        //                 pageDataManager.apply();
        //
        //                 forceRender();
        //             } else {
        //                 // AUTH USER EXPIRED T:1
        //                 if (error.code == 2) {
        //                     await sessionManager.setLogout();
        //                     Static.NavAwayToLogin(pageDataManager, navigate)(null);
        //                     return;
        //                 }
        //
        //                 _._debug.error(error);
        //                 alert(error.message);
        //             }
        //         }, async (err) => {
        //
        //             // CLOSE WAITING SCREEN
        //             loadingManager.changeLoadingCount(-1);
        //             //StaticEvent.LoadingEvent.next(false);
        //             _._debug.warn(err);
        //             alert('網路連線有問題');
        //         }
        //     );
        //     return lastValueFrom(
        //         post$
        //     );
        // }
        const doLoadPartialData = async ({alwaysLoad = false}): Promise<void> => {
            // if (!pageDataManager.refPageData.current.Root) {
            //     return;
            // }

            /// do not dynamically load data if condition fits.
            if (!alwaysLoad) {
                if (pageDataManager.isZoneExist(PrefixTarget)) {
                    return;
                }
            }

            // if (pageDataManager.refPageData.current[TabControlState].activeTabName === 'RecentReadTab') {
            //     await _loadRecentReadTabData();
            // }

            //await _loadPartialData();
        }
        if (_refDoLoadPartialData.current === null) {
            _refDoLoadPartialData.current = doLoadPartialData;
            _refDoLoadPartialData.current({});
        } else {
            _refDoLoadPartialData.current({});
        }
    }, [pageDataManager.get(PrefixTarget)]);
    //#endregion

    //#region :Popup :createModalStaff
    const createModalStaff_refModal = useRef<any>(null);
    const createModalStaff = (e: any, {用戶No, tasks}: {
        用戶No: any,
        tasks?: string[]
    }) => {
        if (e) {
            e.preventDefault();
        }

        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_Manage_Staff__Modal_EditOrAddStaff
                key={用戶No}
                //PrefixTarget={'Partial_Manage_Staff__Modal_EditOrAddStaff'}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModalStaff_refModal,

                    用戶No: 用戶No,
                }}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '80em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModalStaff_refModal.current = Modal.info(config);
    }
    //#endregion

    //#region Set Body
    const Body = () => {
        const RegionEmptyShow = true;
        const RegionLoadingShow = true;

        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;

        // let Item_用戶 = pageDataManager.get(`${PrefixTarget}.Item_用戶`);
        // let its: any;
        // if (Item_用戶 === null) { //convert from single to multiple. Item_號 may be specified null, a valid empty state
        //     its = null;
        // } else if (Item_用戶 !== undefined) {
        //     its = [Item_用戶];
        // }

        let Item_用戶: any = viewModel.Item_用戶;

        if (Item_用戶 == null) {
            return <React.Fragment>
                <div className="infoboxoutside">
                    請先選擇員工
                </div>
            </React.Fragment>;
        }


        const handleSubmit_delete = (e: any, v: any) => {
            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            //#region Handle SaveUserInfo
            {
                // VALIDATE INPUT
                const p1 = v.用戶No;
                if (!p1) {
                    _._debug.error(p1);
                    return; //ERROR INPUTS
                }

                // WAITING SCREEN
                loadingManager.changeLoadingCount(+1);
                //StaticEvent.LoadingEvent.next(true);

                // POST
                let postData = {
                    p1,
                    timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
                };
                const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_modalbody_editstaff__delete');
                const post$ = Static.apiService.post(postUrl, postData);
                post$.subscribe(async (res: any) => {
                        hide();

                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);

                        let data = res.data;
                        let error = data.error || null;
                        let result = error == null;

                        if (result) { // SUCEESS
                            let payload = data.payload;
                            _._debug.warn(payload);

                            let hide = message.loading({
                                type: 'loading',
                                className: 'myMessage',
                                content: <div className="myMessageBody">刪除成功</div>,
                                key: 'loading',
                                duration: 5
                            });

                            pageDataManager.emptyZone(PrefixTarget);
                            let changeShard = {
                                [PrefixTarget]: {
                                    ...payload,
                                }
                            }
                            pageDataManager.merge(changeShard);
                            pageDataManager.apply();


                            StaticEvent.castUpdateEvent({
                                command: StaticEvent.UpdateFacade.Command.Remove_用戶,
                                Remove_用戶: {
                                    用戶No: viewModel.Item_用戶.用戶No,
                                }
                            });

                            forceRender();
                        } else {
                            // AUTH USER EXPIRED
                            if (error.code == 2) {
                                //alert('帳號被登出，請重新登入');
                                //window.location.href = '/login';
                                //this.props.history.push('/login')
                                await sessionManager.setLogout();
                                pageDataManager.clear();
                                pageDataManager.reloadAsync({alwaysLoad: true});
                                alert('時間逾時已被登出');
                                return;
                            }

                            _._debug.error(error);
                            alert(error.message);
                        }
                    }, async (err) => {
                        hide();

                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);
                        _._debug.warn(err);
                        alert('網路連線有問題');
                    }
                );
                return lastValueFrom(
                    post$
                );
            }
            //#endregion
        }


        return <div className="infoboxoutside">
            <div className="infobox">
                <div className="headbar">
                    <div className="avatar">
                        {Ln_AvatorImg({
                            名稱: Item_用戶.名稱,
                            照片組: Item_用戶.照片組,
                        })}
                    </div>
                    <div className="infobar">
                        <Title level={3}>{Item_用戶.名稱}</Title>
                        <div className="tag sn1">{Item_用戶.權限 ?? '無權限'}</div>
                    </div>
                    <div className="func">
                        <Space>
                            <Tooltip title="刪除">
                                <Button icon={<DeleteFilled/>} type="primary" danger onClick={(e)=>{handleSubmit_delete(e, Item_用戶)}}/>
                            </Tooltip>
                            <Tooltip title="編輯">
                                <Button icon={<EditOutlined/>} type="primary" onClick={(e)=>{createModalStaff(e, {用戶No: Item_用戶.用戶No})}}/>
                            </Tooltip>
                        </Space>
                    </div>
                </div>
            </div>
            <div className="infobox">
                <div className="titlebar">
                    <h4>基本資料</h4>
                    <div className="barcontent"></div>
                    <div className="func">

                    </div>
                </div>
                <div className="boxcontent">
                    <div className="systable col_4">
                        <div className="str cops">
                            <div className="th">職稱</div>
                            <div className="td">{Item_用戶.職稱}</div>
                        </div>
                        <div className="str cops">
                            <div className="th">權限等級</div>
                            <div className="td">
                                <div className="tag sn1">{Item_用戶.權限}</div>
                            </div>
                        </div>
                        <div className="str cops">
                            <div className="th">到職日</div>
                            <div className="td">{Item_用戶.到職日 ? moment(Item_用戶.到職日).format('YYYY-MM-DD') : ''}</div>
                        </div>
                        <div className="str cops">
                            <div className="th">E-mail</div>
                            <div className="td">{Item_用戶.Email}</div>
                        </div>
                    </div>
                </div>
                <div className="boxfooter">

                </div>
            </div>

            <div className="infobox">
                <div className="titlebar">
                    <h4>帳號相關</h4>
                    <div className="barcontent"></div>
                    <div className="func">
                    </div>
                </div>
                <div className="boxcontent">
                    <div className="systable col_4">
                        <div className="str cops">
                            <div className="th">帳號</div>
                            <div className="td">{Item_用戶.登入名稱}</div>
                        </div>
                        <div className="str cops">
                            <div className="th">密碼</div>
                            <div className="td">********</div>
                        </div>
                    </div>
                    <div className="boxfooter">

                    </div>
                </div>

            </div>
        </div>;


        return null;
    };
    //#endregion

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_Manage_Staff__Info.displayName = 'Partial_Manage_Staff__Info';
