import { AsyncAction, Action } from 'overmind';
import * as Global from '../global';

export const setSession: AsyncAction<Global.UserInfo> = async({state}, UserInfo) => {
  if( UserInfo !== null ){
    state.userSession.username = UserInfo.username;
    state.userSession.password = UserInfo.password;
  }
};

export const cleanSession: AsyncAction = async({state}) => {
  state.userSession.username = '';
  state.userSession.password = '';
};

export const setRefDoLoadPageData = ({ state, effects, actions }, refDoLoadPageData) => {
  state.refDoLoadPageData = refDoLoadPageData;
};

export const setRoomTag: AsyncAction<string[]> = async({state}, RoomTags) => {
  state.roomTag = RoomTags;
  console.log(state.roomTag);
};

export const saveEmergencyTime: Action<string[]> = ({state}, timestring) => {
  state.emergencyTime = timestring;
  console.log(state.emergencyTime);
};

export const pushTimeShift: Action<Array<string[]>> = ({state}, timecard) => {
  state.timeShift = timecard;
  console.log(state.timeShift);
};


