import React from 'react';
import './Pg_Medicalreport.scss';
import { Button, Tabs, Select, Modal, Input, Form, Table, Row, Col } from 'antd';
import { useState, useEffect, useRef, useCallback } from 'react';
import { Line } from '@ant-design/charts';
import { useApp } from '../overmind/index';
import { columns, DataColumn } from '../Constant';
import  RecordCard from '../RecordCard';

type LineData = {
  // key: number;
  title: string;
  time: string;
  type: string;
  value: number;
  noteTaker: string;
}

const { Option } = Select;
const measureInputSty ={
  background: '#E6F7FF',
  paddingBottom: 16,
  paddingTop: 16,
  borderRadius: 6,
  marginBottom: 20
};

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
const lineColor: string[]= ['#F5222D','#1890FF','#A0D911'];

const tabInfo = [
  ['CRP','WBC','normal'],
  ['leftEye','rightEye'],
  ['weight'],
  ['temperature'],
  ['bloodSugar'],
  ['heartBeat'],
  ['breath'],
  ['bloodOxygen'],
  ['dbp','sbp','map'],
  ['ldbp','hdbp','avmap'],
];

const data: LineData[] = [ //資料這邊再請後端排序好囉
  {
    // key: 1,
    title: '第1次',
    type: 'CRP',
    value: 4623,
    time: '2020-10',
    noteTaker: '陳大發',
  },
  {
    // key: 1,
    title: '第1次',
    type: 'WBC',
    value: 2208,
    time: '2020-10',
    noteTaker: '陳大發',
  },
  {
    // key: 1,
    title: '第1次',
    type: '正常值',
    value: 182,
    time: '2020-10',
    noteTaker: '陳大發',
  },
  {
    // key: 2,
    title: '第2次',
    type: 'CRP',
    value: 6145,
    time: '2020-06',
    noteTaker: '陳大發',
  },
  {
    // key: 2,
    title: '第2次',
    type: 'WBC',
    value: 2016,
    time: '2020-06',
    noteTaker: '陳大發',
  },
  {
    // key: 2,
    title: '第2次',
    type: '正常值',
    value: 257,
    time: '2020-06',
    noteTaker: '陳大發',
  },
];

const eyedata: LineData[] = [
  {
    // key: 1,
    title: '第1次',
    type: 'leftEye',
    value: 23,
    time: '2020-10',
    noteTaker: '陳大發',
  },
  {
    // key: 1,
    title: '第1次',
    type: 'rightEye',
    value: 28,
    time: '2020-10',
    noteTaker: '陳大發',
  },
  {
    // key: 2,
    title: '第2次',
    type: 'leftEye',
    value: 10,
    time: '2020-01',
    noteTaker: '陳大發',
  },
  {
    // key: 2,
    title: '第2次',
    type: 'rightEye',
    value: 28,
    time: '2020-01',
    noteTaker: '陳大發',
  },
];

const tableData= [
  {
    key: 1,
    time: '2020-01',
    noteTaker: '陳大發',
    CRP: 257,
    WBC: 300
  }
];

data.map(linedata => {
  // tableData.push({
  //   key: linedata.key,
  //   time: linedata.time,
  //   noteTaker: linedata.noteTaker,
  //   CRP: value,
  //   WBC: WBC
  // });
});

const chartConfig = (data: LineData[], tabinfo: string[]) => {
  return({
    data: data,
    xField: 'time',
    yField: 'value',
    seriesField: 'type',
    color: (_ref: any) => {
      let type: string = _ref.type;
      let color: any = ''; //original is any type

      switch(tabinfo.length){
        case 1:
          color = lineColor[0];
          return color;
        case 2:
          color = (type === tabinfo[0]) ? lineColor[0]: lineColor[1];
          return color;
        case 3:
          color = (type === tabinfo[0]) ? lineColor[0] : type === tabinfo[1] ? lineColor[1] : lineColor[2];
          return color;
      }
    },
    rerender: 'svg',
    tooltip: {
      showTitle: true,
      title: 'title',
    }
  });
};

function Pg_Medicalreport() {
  const { state } = useApp();
  const [measureModal, setMeasureModal] = useState(false);
  // const [symptomModal, setSymptomModal] = useState(false);

  const toggleMeasuredDrawer = ()=> {
    setMeasureModal(!measureModal);
  };
  // const toggleSymptomDrawer = ()=> {
  //   setSymptomModal(!symptomModal);
  // };

  // const SymptomModal = (
  //   <Modal
  //     title="症狀"
  //     width={920}
  //     closable={true}
  //     visible={symptomModal}
  //     // onClose={toggleSymptomDrawer}
  //   >
  //   </Modal>
  // );
  const MeasureModal = (
    <Modal
      title="測量"
      width={920}
      // closable={true}
      visible={measureModal}
      // onClose={toggleMeasuredDrawer}
    >
      <Tabs tabPosition="right">
        <Tabs.TabPane tab="CRP" key="1">
          <div className="record-box">
            <div className="record-input">
              <Form
                {...formItemLayout}
                name=""
                style={measureInputSty}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="noteTaker" label="測量人員" >
                      <Select>
                        <Option value="accountid">陳大發</Option>
                        <Option value="accountid2">Dollar</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="WBC" >
                      <Form.Item name="wbc" noStyle>
                        <Input type="number" placeholder="請輸入數值" />
                      </Form.Item>
                      <span className="ant-form-text">X1000/ul</span>
                    </Form.Item>
                    <Form.Item  label="CRP濃度" >
                      <Form.Item name="crp" className="over" noStyle>
                        <Input type="number" placeholder="請輸入數值" />
                      </Form.Item>
                      <span className="ant-form-text">mg/L</span>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    {/* <div className="record-info">
                      <p>眼壓正常值</p>
                    </div> */}
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
          <div className="measure-chart-box">
            <Line {...chartConfig(data ,tabInfo[0])} />
          </div>
          <Table columns= {columns(DataColumn[0])} dataSource={tableData}/>
        </Tabs.TabPane>
        <Tabs.TabPane tab="眼壓" key="2">
          <div className="record-box">
            <div className="record-input">
              <Form
                {...formItemLayout}
                name=""
                style={measureInputSty}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="noteTaker" label="測量人員" >
                      <Select>
                        <Option value="accountid">陳大發</Option>
                        <Option value="accountid2">Dollar</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="右眼壓" >
                      <Form.Item name="righteye" noStyle>
                        <Input
                          type="number" placeholder="請輸入數值"
                        />
                      </Form.Item>
                      <span className="ant-form-text">mmHg</span>
                    </Form.Item>
                    <Form.Item label="左眼壓" >
                      <Form.Item name="lefteye" noStyle>
                        <Input
                          type="number" placeholder="請輸入數值"
                        />
                      </Form.Item>
                      <span className="ant-form-text">mmHg</span>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <div className="record-info">
                      <p>眼壓正常值</p>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
          <div className="measure-chart-box">
            <Line {...chartConfig(eyedata ,tabInfo[1])} />
          </div>
          <Table  columns={columns(DataColumn[1])}/>
        </Tabs.TabPane>
        <Tabs.TabPane tab="體重" key="3">
          <div className="record-box">
            <div className="record-input">
              <Form
                {...formItemLayout}
                name=""
                style={measureInputSty}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="noteTaker" label="測量人員" >
                      <Select>
                        <Option value="accountid">陳大發</Option>
                        <Option value="accountid2">Dollar</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="現在體重" >
                      <Form.Item name="weight" noStyle>
                        <Input
                          type="number" placeholder="請輸入數值"
                        />
                      </Form.Item>
                      <span className="ant-form-text">kg</span>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    {/* <div className="record-info">
                      <p className="info-title">本寵物建議體重為</p>
                      <p className="info-wording ">0-0kg(依據品種顯示不同建議值)</p>
                    </div> */}
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
          <div className="measure-chart-box">
            <Line {...chartConfig(data ,tabInfo[2])} />
          </div>
          <Table  columns={columns(DataColumn[2])}/>
        </Tabs.TabPane>
        <Tabs.TabPane tab="體溫" key="4">
          <div className="record-box">
            <div className="record-input">
              <Form
                {...formItemLayout}
                name=""
                style={measureInputSty}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="noteTaker" label="測量人員" >
                      <Select>
                        <Option value="accountid">陳大發</Option>
                        <Option value="accountid2">Dollar</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="體溫" >
                      <Form.Item name="temperature" noStyle>
                        <Input
                          type="number" placeholder="請輸入數值"
                        />
                      </Form.Item>
                      <span className="ant-form-text">°C</span>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    {/* <div className="record-info">
                      <p>眼壓正常值</p>
                    </div> */}
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
          <div className="measure-chart-box">
            <Line {...chartConfig(data ,tabInfo[3])} />
          </div>
          <Table  columns={columns(DataColumn[3])}/>
        </Tabs.TabPane>
        <Tabs.TabPane tab="血糖" key="5">
          <div className="record-box">
            <div className="record-input">
              <Form
                {...formItemLayout}
                name=""
                style={measureInputSty}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="noteTaker" label="測量人員" >
                      <Select>
                        <Option value="accountid">陳大發</Option>
                        <Option value="accountid2">Dollar</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="血糖濃度" >
                      <Form.Item name="bloodSugar" noStyle>
                        <Input
                          type="number" placeholder="請輸入數值"
                        />
                      </Form.Item>
                      <span className="ant-form-text">mg/dl</span>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    {/* <div className="record-info">
                      <p>眼壓正常值</p>
                    </div> */}
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
          <div className="measure-chart-box">
            <Line {...chartConfig(data ,tabInfo[4])} />
          </div>
          <Table  columns={columns(DataColumn[4])}/>
        </Tabs.TabPane>
        <Tabs.TabPane tab="心跳數" key="6">
          <div className="record-box">
            <div className="record-input">
              <Form
                {...formItemLayout}
                name=""
                style={measureInputSty}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="noteTaker" label="測量人員" >
                      <Select>
                        <Option value="accountid">陳大發</Option>
                        <Option value="accountid2">Dollar</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="心跳數目" >
                      <Form.Item name="heartBeat" noStyle>
                        <Input
                          type="number" placeholder="請輸入數值"
                        />
                      </Form.Item>
                      <span className="ant-form-text">次/分</span>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <p className="info-title">心跳速率</p>
                    <div>
                      幼犬：70~222 下/分
                      玩具犬：70~180 下/分
                      標準型：70~160 下/分
                      巨型犬：60~40 下/分
                      貓：120~240 下/分
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
          <div className="measure-chart-box">
            <Line {...chartConfig(data ,tabInfo[5])} />
          </div>
          <Table  columns={columns(DataColumn[5])}/>
        </Tabs.TabPane>
        <Tabs.TabPane tab="呼吸" key="7">
          <div className="record-box">
            <div className="record-input">
              <Form
                {...formItemLayout}
                name=""
                style={measureInputSty}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="noteTaker" label="測量人員" >
                      <Select>
                        <Option value="accountid">陳大發</Option>
                        <Option value="accountid2">Dollar</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="呼吸次數" >
                      <Form.Item name="breath" noStyle>
                        <Input
                          type="number" placeholder="請輸入數值"
                        />
                      </Form.Item>
                      <span className="ant-form-text">X1000/ul</span>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <p className="info-title">呼吸</p>
                    <p>
                      狗休息狀態  次/分
                      狗呼吸過速 次/分

                      貓休息狀態 16~30 次/分
                    </p>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
          <div className="measure-chart-box">
            <Line {...chartConfig(data ,tabInfo[6])} />
          </div>
          <Table  columns={columns(DataColumn[6])}/>
        </Tabs.TabPane>
        <Tabs.TabPane tab="血氧" key="8">
          <div className="record-box">
            <div className="record-input">
              <Form
                {...formItemLayout}
                name=""
                style={measureInputSty}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="noteTaker" label="測量人員" >
                      <Select>
                        <Option value="accountid">陳大發</Option>
                        <Option value="accountid2">Dollar</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="血氧濃度" >
                      <Form.Item name="bloodOxygen" noStyle>
                        <Input
                          type="number" placeholder="請輸入數值"
                        />
                      </Form.Item>
                      <span className="ant-form-text">%</span>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <p className="info-title">呼吸</p>
                    <p>
                      狗休息狀態  次/分
                      狗呼吸過速 次/分
                      貓休息狀態 16~30 次/分
                    </p>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
          <div className="measure-chart-box">
            <Line {...chartConfig(data ,tabInfo[7])} />
          </div>
          <Table  columns={columns(DataColumn[7])}/>
        </Tabs.TabPane>
        <Tabs.TabPane tab="血壓" key="9">
          <div className="record-box">
            <div className="record-input">
              <Form
                {...formItemLayout}
                name=""
                style={measureInputSty}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="noteTaker" label="測量人員" >
                      <Select>
                        <Option value="accountid">陳大發</Option>
                        <Option value="accountid2">Dollar</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item name="bodyPart" label="測量位置" >
                      <Select>
                        <Option value="accountid">左前肢</Option>
                        <Option value="accountid2">右前肢</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item name="noteTaker" label="Cuff大小" >
                      <Select>
                        <Option value="accountid">1#</Option>
                        <Option value="accountid2">2#</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="舒張壓" >
                      <Form.Item name="dbp" noStyle>
                        <Input
                          type="number" placeholder="請輸入數值"
                        />
                      </Form.Item>
                      <span className="ant-form-text">mmHg</span>
                    </Form.Item>
                    <Form.Item label="收縮壓" >
                      <Form.Item name="sbp" noStyle>
                        <Input
                          type="number" placeholder="請輸入數值"
                        />
                      </Form.Item>
                      <span className="ant-form-text">mmHg</span>
                    </Form.Item>
                    <Form.Item label="平均血壓" >
                      <Form.Item name="map" noStyle >
                        <Input
                          type="number" placeholder="請輸入數值"
                        />
                      </Form.Item>
                      <span className="ant-form-text">mmHg</span>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <p className="info-title">血壓</p>
                    <div>
                      狗正常收縮壓 120-150 mmHg
                      貓正常收縮壓 120-150 mmHg
                      輕微高血壓 150-160 mmHg
                      中等高血壓 160-180 mmHg
                      嚴重高血壓 180 mmHg
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
          <div className="measure-chart-box">
            <Line {...chartConfig(data ,tabInfo[8])} />
          </div>
          <Table  columns={columns(DataColumn[8])}/>
        </Tabs.TabPane>
        <Tabs.TabPane tab="平均血壓" key="10">
          <div className="record-box">
            <div className="record-input">
              <Form
                {...formItemLayout}
                name=""
                style={measureInputSty}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="noteTaker" label="測量人員" >
                      <Select>
                          <Option value="accountid">陳大發</Option>
                          <Option value="accountid2">Dollar</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="bodyPart" label="測量位置" >
                      <Select>
                          <Option value="accountid">左前肢</Option>
                          <Option value="accountid2">右前肢</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="noteTaker" label="Cuff大小" >
                      <Select>
                          <Option value="accountid">1#</Option>
                          <Option value="accountid2">2#</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="最低收縮壓" >
                      <Form.Item name="lsbp" noStyle>
                        <Input
                          type="number" placeholder="請輸入數值"
                        />
                      </Form.Item>
                      <span className="ant-form-text">mmHg</span>
                    </Form.Item>
                    <Form.Item label="最高收縮壓" >
                      <Form.Item name="hsbp" noStyle>
                        <Input
                          type="number" placeholder="請輸入數值"
                        />
                      </Form.Item>
                      <span className="ant-form-text">mmHg</span>
                    </Form.Item>
                    <Form.Item label="平均收縮壓" >
                      <Form.Item name="avsbp" noStyle>
                        <Input
                          type="number" placeholder="請輸入數值"
                        />
                      </Form.Item>
                      <span className="ant-form-text">mmHg</span>
                    </Form.Item>
                    </Col>
                    <Col span={12}>
                      <p className="info-title">血壓</p>
                      <div>
                        狗正常收縮壓 120-150 mmHg
                        貓正常收縮壓 120-150 mmHg
                        輕微高血壓 150-160 mmHg
                        中等高血壓 160-180 mmHg
                        嚴重高血壓 180 mmHg
                      </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
          <div className="measure-chart-box">
            <Line {...chartConfig(data ,tabInfo[9])} />
          </div>
          <Table  columns={columns(DataColumn[9])}/>
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );

  return (
    <div className="page-medicalreport">
      <div className="page-title">
        看診作業
      </div>
      <div className="medicalreport-box">
        <div className="record-box">
          <div className="basic-info">
            <div className="title">寵物名稱</div>
            <div className="description">母/1996-1-1/American Curl Longhair</div>
            <div className="title">飼主姓名</div>
            <div className="description">0912123456</div>
          </div>
          <div className="record-flow">
            <div className="card-info">
              <div className="date">2021/2/20(三)</div>
              <div className="description">AM 10:30</div>
              <div>doctor</div>
            </div>
            <div className="card-flow">
              <RecordCard />
              <RecordCard />
            </div>
          </div>

        </div>
        <div className="card-box">
          <div className="info-pad">
            <Tabs defaultActiveKey="1" >
              <Tabs.TabPane tab="最近測量數據" key="1">
                2020-02-01  體重 23 kg
                2020-08-01  血壓 100ppi
                2020-04-01  體溫 30 度
              </Tabs.TabPane>
              <Tabs.TabPane tab="寵物備註" key="2">
                Content of Tab Pane 2
              </Tabs.TabPane>
            </Tabs>
          </div>
          <div className="prescription-comands">
            <p className="prescription-title">常用 <Button className="edit-btn" >編輯</Button></p>
            <Button className="prescription-btns"  onClick={toggleMeasuredDrawer}>體重</Button>
            <Button className="prescription-btns"  onClick={toggleMeasuredDrawer}>體溫</Button>
            <Button className="prescription-btns"  onClick={toggleMeasuredDrawer}>血壓</Button>
            <Button className="prescription-btns"  onClick={toggleMeasuredDrawer}>口服用藥</Button>
            <Button className="prescription-btns"  onClick={toggleMeasuredDrawer}>驅蟲</Button>
            <Button className="prescription-btns"  onClick={toggleMeasuredDrawer}>心絲蟲</Button>
          </div>
          <div className="prescription-comands">
            <p className="prescription-title">看診功能</p>
            <Button className="prescription-btns"  onClick={toggleMeasuredDrawer}>過往紀錄</Button>
            <Button className="prescription-btns"  onClick={toggleMeasuredDrawer}>寵物備註</Button>
            <Button className="prescription-btns"  onClick={toggleMeasuredDrawer}>臨床症狀</Button>
            <Button className="prescription-btns"  onClick={toggleMeasuredDrawer}>檢查</Button>
            <Button className="prescription-btns"  onClick={toggleMeasuredDrawer}>診斷</Button>
            <Button className="prescription-btns"  onClick={toggleMeasuredDrawer}>處理</Button>
            <Button className="prescription-btns"  onClick={toggleMeasuredDrawer}>用藥耗材</Button>
            <Button className="prescription-btns"  onClick={toggleMeasuredDrawer}>回診</Button>
            <Button className="prescription-btns"  onClick={toggleMeasuredDrawer}>批價</Button>
          </div>
          <div className="prescription-comands">
            <p className="prescription-title">病歷處置</p>
            <Button className="prescription-btns"  onClick={toggleMeasuredDrawer}>候診</Button>
            <Button className="prescription-btns"  onClick={toggleMeasuredDrawer}>完診歸檔</Button>
          </div>
        </div>
      </div>
        {MeasureModal}
        {/* {SymptomModal} */}
    </div>
  )
}

export default Pg_Medicalreport;
