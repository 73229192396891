import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {Menu} from 'antd';
import './Box_Sidemenu.scss';
import {DeleteOutlined, FileAddFilled, IdcardFilled, EditOutlined, CustomerServiceFilled} from "@ant-design/icons";
import * as CustomIcon from '../IconStorage';
import UseCompState from "../hooks/UseCompState";
import UseLoadingManager from "../hooks/UseLoadingManager";
import {StaticEvent} from "../_/StaticEvent";

const logo = require('../assets/images/logo_vetsion.svg');
const {SubMenu} = Menu;


type ViewModelProps = {
    forceRenderParent: any,
    placeAt?: any,

};
type Props = {
    PrefixTarget?: string;
    parentState?: any,

    MenuTemplateId: any,

    viewModel: ViewModelProps,
};

function Box_Sidemenu(props: Props) {
    const PrefixTarget: string = props.PrefixTarget || 'Root';

    //let [selectedKeys, setSelectedKeys] = useState<any>(['1']);

    const location = useLocation();

    const navigate = useNavigate();

    // @ts-ignore
    let selectedKeys = [props.MenuTemplateId ?? 1];

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    //#region LoadingManager
    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: true});
    //#endregion


    //#region Boot
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

        state.subscribed = [];
    }

    // useEffect(() => {
    //     state.subscribed.push(StaticEvent.Event$_NavAway.subscribe(async (v) => {
    //
    //         //setSelectedKeys(['2']);
    //     }));
    //
    //     return function cleanup() {
    //         state.subscribed.forEach((it) => {
    //             it.unsubscribe();
    //         });
    //         state.subscribed = [];
    //     }
    // }, []);

    useEffect(() => {

        let MenuTemplateId = props.MenuTemplateId;
        forceRender();

        //
        // const PassPageDataInitalized = pageDataManager.refPageData.current != null;
        // const PassHasComponentData = pageDataManager.get(`${PrefixTarget}`) !== null;
        //
        // const Page_文章 = pageDataManager.get(`${PrefixTarget}.Page_文章`);
        // const List_文章 = pageDataManager.get(`${PrefixTarget}.Page_文章.List_文章`);
        // const Dict_號成就 = pageDataManager.get(`${PrefixTarget}.Dict_號成就`);
        // const Dict_號認證 = pageDataManager.get(`${PrefixTarget}.Dict_號認證`);
    }, [location.pathname]);

    //#endregion

    return (
        <div className="sidemenu-box">
            <div className="logo">
                <img src={logo} alt="logo"/>
            </div>
            <Menu
                //selectedKeys={selectedKeys}
                selectedKeys={selectedKeys}
                style={{width: 200, background: '#003A8C', paddingTop: '95px'}}
                mode="vertical"
                theme="dark"
            >
                <Menu.Item key="1" icon={<CustomIcon.WarningIcon/>}>
                    應注意事項
                </Menu.Item>
                <Menu.Item key="4" icon={<CustomIcon.BookingIcon/>}>
                    <Link to="/reservation">預約中心</Link>
                </Menu.Item>

                {!(selectedKeys.includes("16") || selectedKeys.includes("10")) &&
                <Menu.Item key="3" icon={<CustomIcon.AppointmentIcon/>}>
                    <Link to="/appointment">掛號與搜尋</Link>
                </Menu.Item>
                }
                {(selectedKeys.includes("16")) &&
                <SubMenu key="" icon={<CustomIcon.AppointmentIcon/>} title="掛號與搜尋">
                    <Menu.Item icon={<CustomIcon.WaitingRoomIcon/>}>
                        <a onClick={() => {
                            navigate(-1);
                        }}>返回 </a>
                    </Menu.Item>
                    <Menu.Item key="16" icon={<CustomIcon.WaitingRoomIcon/>}>
                        <a>病歷</a>
                        {/*<Link to="/waitingroom">病歷</Link>*/}
                    </Menu.Item>
                </SubMenu>
                }
                {(selectedKeys.includes("10")) &&
                <SubMenu key="" icon={<CustomIcon.AppointmentIcon/>} title="掛號與搜尋">
                    <Menu.Item icon={<CustomIcon.WaitingRoomIcon/>}>
                        <a onClick={() => {
                            navigate(-1);
                        }}>返回 </a>
                    </Menu.Item>
                    <Menu.Item key="10" icon={<CustomIcon.CustomerIcon/>}>
                        <Link to="/clientmanage/:No">顧客管理</Link>
                    </Menu.Item>
                </SubMenu>
                }
                <Menu.Item key="2" icon={<CustomIcon.WaitingRoomIcon/>}>
                    <Link to="/waitingroom">候診及待辦 </Link>
                </Menu.Item>

                <div className="menu-title">部門</div>
                <Menu.Item key="5" icon={<CustomIcon.PharmacyIcon/>}>
                    <Link to="/pharmacy">藥局</Link>
                </Menu.Item>
                <Menu.Item key="7" icon={<CustomIcon.HospitalIcon/>}>
                    <Link to="/inhospital">住院部</Link>
                </Menu.Item>
                <Menu.Item key="8" icon={<CustomIcon.SalonIcon/>}>
                    <Link to="/cosmetic">美容部</Link>
                </Menu.Item>
                {/*<Menu.Item key="6" icon={<CustomIcon.LabIcon />}>*/}
                {/*    檢驗部*/}
                {/*</Menu.Item>*/}
                <div className="menu-title">管理</div>
                <Menu.Item key="9" icon={<CustomIcon.AccoountantIcon/>}>
                    <Link to="/charge">收費出納</Link>
                </Menu.Item>

                <SubMenu key="" icon={<CustomIcon.MedicalBagIcon/>} title="院所管理">
                    <Menu.Item key="13">
                        <Link to="/agency">院所設定</Link>
                    </Menu.Item>
                    <Menu.Item key="14">
                        <Link to="/staff">員工管理</Link>
                    </Menu.Item>
                    <Menu.Item key="15">
                        <Link to="/hours">營業時段管理</Link>
                    </Menu.Item>
                    <Menu.Item key="17">
                        <Link to="/shifts">排班表</Link>
                    </Menu.Item>
                    <Menu.Item key="18">
                        <Link to="/campaign">優惠活動</Link>
                    </Menu.Item>
                    <Menu.Item key="19">
                        <Link to="/opertionstatus">營收狀況分析</Link>
                    </Menu.Item>
                    <Menu.Item key="21">
                        <Link to="/hospital">醫院主帳號管理</Link>
                    </Menu.Item>
                </SubMenu>
                <Menu.Item key='20' icon={<CustomerServiceFilled/>}><a
                    href='https://www.pettalk.tw/doctor_console/login' target='_blank'>線上諮詢</a></Menu.Item>
                <Menu.Item key="11" icon={<CustomIcon.NoticeIcon/>}>
                    公告
                </Menu.Item>
            </Menu>
        </div>
    )
}

Box_Sidemenu.displayName = 'Box_Sidemenu';
const areEqual = (prevProps, nextProps) => {
    if (prevProps.RoutePathname !== nextProps.RoutePathname) {
        return false;
    }
    return true; //force true
}
export default React.memo(Box_Sidemenu, areEqual);


