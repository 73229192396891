import React, {Component} from 'react';
import {useState, useEffect} from 'react';
import {Waypoint} from 'react-waypoint';
import {postTimeDisplayFactory} from '../utils/time';
import _ from "../_";
import {ApiService} from "../_/ApiService";

type Props = {
    照片組?: string;

    //#region Option: Generate DefaultImage (Pick-One)
    名稱?: string;
    DefaultImage?: string;
    //#endregion

    testMode?: boolean,

    emitNullIfEmpty?: boolean
};

export default function Ln_AvatorImgHref(props: Props) {
    const param_照片組 = props['照片組'] || '';
    const param_名稱 = props['名稱'];
    const param_DefaultImage = props['DefaultImage'];
    const param_TestMode = props['testMode'] || false;
    const param_emitNullIfEmpty = props['emitNullIfEmpty'] || false;

    let firstImg = _.bs.getCoverSafeList(param_照片組)[0];

    if (param_TestMode) {
        return `https://ui-avatars.com/api/?name=${encodeURIComponent(param_名稱 || '')}`;
    }

    if (firstImg == null) {
        if (param_DefaultImage == null && param_名稱 == null) {
            return;
        }

        if (param_DefaultImage) {
            return param_DefaultImage;
        } else {
            return `https://ui-avatars.com/api/?name=${encodeURIComponent(param_名稱 || '')}`;
        }
    } else {
        return ApiService.urlImageMapping("~/" + firstImg)
    }
}
