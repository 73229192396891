import React, {useEffect, useRef} from 'react';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import {
    Form, Input, Button, Alert, Checkbox, Result, message, Space,
    Modal, Typography, Tabs, Select, Popconfirm, Table, Empty, Spin, DatePicker, Menu, Row, Col, Tooltip, Statistic,
} from 'antd';
import {useApp} from "../overmind";
import {UserInfo} from "../global";
import {Static} from "../_/Static";
import {ApiService} from "../_/ApiService";
import _ from "../_";
import {
    BarChartOutlined,
    EyeTwoTone,
    UserOutlined,
    LockOutlined,
    RedoOutlined,
    LeftOutlined,
    RightOutlined,
    PrinterOutlined,
    RollbackOutlined,
    ArrowLeftOutlined, LoadingOutlined, ReloadOutlined
} from '@ant-design/icons';

import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import UsePageDataManager from '../hooks/UsePageDataManager';
import UseSessionManager from "../hooks/UseSessionManager";
import UseLoadingManager from "../hooks/UseLoadingManager";
import UseCompState from "../hooks/UseCompState";
import {lastValueFrom} from "rxjs";
import Partial_Modal_ClientMessage from "./Partial_Modal_ClientMessage";
import Partial_ClientManage__Modal_ClientAnalysis from "./Partial_ClientManage__Modal_ClientAnalysis";
import Partial_Operation__Modal_Analysis from "./Partial_Operation__Modal_Analysis";


import './Pg_Waitingroom.scss';
import FunctionBtn from './Box_Functionbtn';
import moment from 'moment';
import Partial_Pharmacy__Tab_PrescribeList from "./Partial_Pharmacy__Tab_PrescribeList";
import Partial_Pharmacy__Tab_PrescribeFinishedList from "./Partial_Pharmacy__Tab_PrescribeFinishedList";

const iconLighting = require('../assets/images/icon/lighting.svg');
const iconWatting = require('../assets/images/icon/new-medical-record.svg');
const iconAnalysis = require('../assets/images/icon/analysis.svg');
const iconProgress = require('../assets/images/icon/progress.svg');
const iconMessage = require('../assets/images/icon/conversationx.svg');

const onSearch = value => console.log(value);
const {Title} = Typography;
const {TabPane} = Tabs;
const {Search} = Input;
const {Option} = Select;
const dateFormat = 'YYYY/MM/DD';
const {RangePicker} = DatePicker;


type Props = {
    PrefixTarget?: string;
    RoutePathname?: string;
    MenuTemplateId?: string;
    parentState?: any;
};
export default function Pg_OperationStatus(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Root';
    let RoutePathname = props.RoutePathname;
    let MenuTemplateId = props.MenuTemplateId;

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    //#region LoadingManager
    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: true});
    //#endregion

    //#region PageDataManager
    const refDoLoadPageData = Static.refDoLoadPageData;
    let pageDataManager = UsePageDataManager({
        refPageLoad: refDoLoadPageData
    });
    //#endregion

    //#region SessionManager
    let sessionManager = UseSessionManager({
        pageDataManager: pageDataManager,
    });
    //#endregion

    //#region No Cache
    useEffect(() => {
        return ()=> {
            pageDataManager.emptyZone(PrefixTarget);
            pageDataManager.apply();
        }
    }, []);
    //#endregion

    //#region Boot
    const state = UseCompState({parentState: props.parentState});

    let defaultS = '全部';
    let defaultQ = '';

    if (!state.hasInit) {
        state.hasInit = true;

        state.pageDataManager = pageDataManager;
        state.sessionManager = sessionManager;
        state.loadingManager = loadingManager;
        state.navigate = navigate;
        state.forceRender = forceRender;

        state.subscribed = [];

        state.s = pageDataManager.get(`${PrefixTarget}.s`) ?? defaultS;
        state.q = pageDataManager.get(`${PrefixTarget}.q`) ?? defaultQ;
    }

    useEffect(() => {
        const _loadPageData = async (): Promise<void> => {
            let hide = message.loading({
                type: 'loading',
                class: 'myMessage',
                content: <div className="myMessageBody">搜尋中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            //const id : any = this.props.match.params.id;
            // const id = 1;
            // if (!id) {
            //     return; //ERROR INPUTS
            // }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1: null,
                //s: state.s,
                //q: state.q,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/pg_operationstatus');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        pageDataManager.merge({
                            ...payload,
                        });
                        pageDataManager.apply();

                        pageDataManager.forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPageData = async ({alwaysLoad = false}): Promise<void> => {
            if (!alwaysLoad) {
                pageDataManager.restore();
                if (!pageDataManager.isZoneExist(PrefixTarget)) {
                    await sessionManager.initSessionAuthAsync();
                    //#region User Check //TODO: VERTIFY
                    if (!sessionManager.isMemberLoggedIn()) {
                        Static.NavAwayToLogin(pageDataManager, navigate)(null);
                        return;
                    }
                    //#endregion
                    await _loadPageData();
                } else {
                    forceRender()
                }
            } else {
                await _loadPageData();
            }
        }

        refDoLoadPageData.current = doLoadPageData;
        refDoLoadPageData.current({});
    }, [location.pathname]);
    //#endregion


    const iconWaitforCase = require('../assets/images/icon/nocase.svg');
    const [noCase, setNoCase] = useState(false);

    function callback(key) {
        console.log(key);
    }

    function confirm(e) {
        console.log(e);
    }

    function cancel(e) {
        console.log(e)
    }

    //#region :Popup :createModalOperationAnalysis
    const createModalOperationAnalysis_refModal = useRef<any>(null);
    const createModalOperationAnalysis = (e: any, tasks?: string[]): void => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_Operation__Modal_Analysis
                PrefixTarget={'Partial_Operation__Modal_Analysis'}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModalOperationAnalysis_refModal,
                }}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '90em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModalOperationAnalysis_refModal.current = Modal.info(config);
    }
    //#endregion


    //#region :Popup :createModalClientMessage
    const createModalClientMessage_refModal = useRef<any>(null);
    const createModalClientMessage = (e: any, tasks?: string[]): void => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_Modal_ClientMessage
                PrefixTarget={'Partial_Modal_ClientMessage'}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModalClientMessage_refModal,
                }}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '30em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModalClientMessage_refModal.current = Modal.info(config);
    }
    //#endregion


    interface PetItem {
        key: React.Key;
        relationship: string;
        petname: string;
        medicalnumber: string;
        type: string;
        gender: string;
        variety: string;
    }

    const petlist = [
        {
            title: '關係',
            dataIndex: 'relationship',
        },
        {
            title: '寵物姓名',
            dataIndex: 'petname',
            render: (text: string) => <Link to='/medicalrecord/1'>{text}</Link>,
        },
        {
            title: '病歷號碼',
            dataIndex: 'medicalnumber',
        },
        {
            title: '種類',
            dataIndex: 'type',
        },
        {
            title: '性別',
            dataIndex: 'gender',
        },
        {
            title: '品種',
            dataIndex: 'variety',
        },
        {
            title: '動作',
            dataIndex: ' function',
            render: (text, record) => {

                return <div>
                    <Button className="quick-order" type="primary" href='/medicalrecord/1'
                    >查看詳細</Button>
                </div>
            },
        },
    ];

    const petData: PetItem[] = [
        {
            key: 1,
            relationship: '主聯絡人',
            petname: 'Lucky',
            medicalnumber: '0058214357',
            type: '狗',
            gender: '公',
            variety: '馬爾濟斯',
        },
        {
            key: 2,
            relationship: '主聯絡人',
            petname: '乖乖',
            medicalnumber: '1234343422',
            type: '貓',
            gender: '母',
            variety: '波斯貓',
        }
    ];


    // 掛號紀錄

    interface registerItem {
        key: React.Key;
        registerdate: string;
        registertime: string;
        title: string;
    }

    const registerCol = [
        {
            title: '日期',
            dataIndex: 'registerdate',
        },
        {
            title: '時間',
            dataIndex: 'registertime',
        },
        {
            title: '看診標題',
            dataIndex: 'title',
        },
    ];

    const registerData: registerItem[] = [
        {
            key: 1,
            registerdate: '2021-01-28',
            registertime: '13:43',
            title: '打預防針'
        },
        {
            key: 2,
            registerdate: '2020-07-17',
            registertime: '10:23',
            title: '預約回診'
        }
    ];


    //#region Set Body
    const Body = () => {
        const RegionEmptyShow = false;
        const RegionLoadingShow = true;
        const RegionInitialLoadingShow = false;

        const PassPageDataInitalized = pageDataManager.refPageData.current != null;
        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;

        let today = pageDataManager.get(`${PrefixTarget}.today`);

        if (!PassPrefixTargetLoaded) {
            if (RegionInitialLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <div>Loading.</div>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        if (loadingManager.getIsLoading()) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <Spin indicator={
                            <LoadingOutlined style={{fontSize: 24}} spin/>
                        }/>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        //#region Handlers
        //#endregion

        return (
            <div className="page-appointment page-content">
                <div className="info-list">
                    <div className="info-box">
                        <Title level={2}>營收狀況分析</Title>
                        <div className="pluginfobox">
                            <div className="title"><Tooltip title="刷新">
                                <Button icon={<ReloadOutlined />} shape="circle" onClick={(e) => {
                                    ///reload
                                    if (e) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }
                                    pageDataManager.clear();
                                    pageDataManager.reloadAsync({alwaysLoad: true});
                                }}/>
                            </Tooltip></div>
                            <div className="info"></div>
                        </div>

                        <div className="pluginfobox">
                            <div className="title"></div>
                            <div className="info"></div>
                        </div>

                    </div>
                    <div className="function-box">

                    </div>
                </div>


                <div className="page-waitingroom">
                    <div className="page-manage page_cm">


                        <div className="box-manage box-3 pb3">
                            <div className="mbt1 mtt3">
                                <Row gutter={16} justify="center">
                                    <Col className="gutter-row" span={6}>
                                        <Statistic title="本日營業額" prefix="$" value={today.本日營業額}/>
                                    </Col>
                                    <Col className="gutter-row" span={4}>
                                        <Statistic title="本日來客數" value={today.本日來客數}/>
                                    </Col>
                                    <Col className="gutter-row" span={4}>
                                        <Statistic title="新客數" value={today.本日新客數}/>
                                    </Col>
                                </Row>
                            </div>
                            <div className="mbt1 mtt3">
                                <Row gutter={16} justify="center">
                                    <Col className="gutter-row" span={6}>
                                        <Statistic title="上週營業額" prefix="$" value={today.上週營業額}/>
                                    </Col>
                                    <Col className="gutter-row" span={4}>
                                        <Statistic title="上週來客數" value={today.上週來客數}/>
                                    </Col>
                                    <Col className="gutter-row" span={4}>
                                        <Statistic title="上週新客數" value={today.上週新客數}/>
                                    </Col>
                                </Row>
                            </div>
                            <div className="mbt1 mtt3">
                                <Row gutter={16} justify="center">
                                    <Col className="gutter-row" span={6}>
                                        <Statistic title="上月營業額" prefix="$" value={today.上月營業額}/>
                                    </Col>
                                    <Col className="gutter-row" span={4}>
                                        <Statistic title="上月來客數" value={today.上月來客數}/>
                                    </Col>
                                    <Col className="gutter-row" span={4}>
                                        <Statistic title="上月新客數" value={today.上月新客數}/>
                                    </Col>
                                </Row>
                            </div>
                            <Space className='space_align_center mtt3'>
                                <Button icon={<BarChartOutlined/>} onClick={createModalOperationAnalysis}>詳細報表</Button>
                            </Space>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
    //#endregion

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}

Pg_OperationStatus.displayName = 'Pg_OperationStatus';




























