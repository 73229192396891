import React from 'react';
import './Pg_Basicdata.scss';
import { Link ,useLocation } from 'react-router-dom';
import { Tabs, Descriptions, Table, Collapse, Input, Select, Form, DatePicker, Button, Upload, InputNumber, Spin, Tooltip } from 'antd';
import { useState, useEffect } from 'react';
import moment from 'moment';
import Modal from "react-modal";
import ImgCrop from "antd-img-crop";
import 'antd/es/modal/style';
import 'antd/es/slider/style';
import { UploadFile } from 'antd/lib/upload/interface';

import FunctionBtn from './Box_Functionbtn';
import SymbolicIcon from '../SymbolicBtn';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useApp } from '../overmind/index';
import { CaseHistory, MedicalRecord, OwnerInfo, PetInfo } from "../global";
import axios from 'axios';

const iconEdit = require('../assets/images/icon/edit.svg');
const iconSalon = require('../assets/images/icon/salon.svg');
const iconWaitingRoom = require('../assets/images/icon/waitingroom.svg');

interface tabledata {
  key: number;
  date: string;
  title: string;
  times: number;
  left: number;
}

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};

const { TextArea } = Input;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const { Option } = Select;
const dateFormat :string = 'YYYY-MM-DD';

const columns = [
  {
    title: '日期',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: '項目',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '第幾次',
    dataIndex: 'times',
    key: 'times',
  },
  {
    title: '尚餘',
    dataIndex: 'left',
    key: 'left',
}];

const ImgCropSetting = {
  aspect: 16 / 9,
  resize: true, //裁剪是否可以调整大小
  resizeAndDrag: true, //裁剪是否可以调整大小、可拖动
  // modalTitle: '上传图片', //弹窗标题
  // modalWidth: 600, //弹窗宽度
};

function Pg_Basicdata() {
  const location = useLocation();
  const petData: any = location.state;
  // console.log('location: ' + JSON.stringify(location) );
  const { state , actions, effects } = useApp();

  const medicalRecordData: MedicalRecord = effects.medicalRecordData.getData();
  const caseHistoryData: CaseHistory = effects.caseHistoryData.getData();
  const medicaldata: tabledata[] = [];
  useEffect(() => {
    //1. 拿取寵物資料
    //2. 拿取飼主資料
    //3. 拿取過往病史
    //4. 拿取實施紀錄
    for (let i = 0; i < medicalRecordData.vaccinated.length ; i++) {
      medicaldata.push({
        key: i+1,
        date: medicalRecordData.vaccinated[i].date,
        title: medicalRecordData.vaccinated[i].content,
        times: medicalRecordData.vaccinated[i].times,
        left: medicalRecordData.vaccinated[i].timeLeft,
      });
    }
  },[]);

  // edit modal
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
    // window.location.reload();
  }

  // imgCrop
  const [fileList, setFileList] = useState<UploadFile[]>([
    {
      uid: "-1",
      name: "image.png",
      size: 2964,
      type: "image/jpeg",
      url: petData.petinfo.piclink
    }
  ]);

  const updatePhoto = (data: any) => {
    const newFileList = data.fileList;
    setFileList(newFileList);
    console.log(newFileList);
  };
  //
  const updateWeight = async(value: any) => {
    // console.log({
    //   Item_寵物: {
    //     寵物No: petData.petinfo.petno.toString(),
    //     最後體重: value
    //   },
    //   testmode: '123'
    // });

    await effects.updatemixed.update({
      Item_寵物: {
        寵物No: petData.petinfo.petno.toString(),
        最後體重: value
      },
      testmode: '123'
    });
  };

  const updateBirth = async(item: any) => {
    // console.log({
    //   Item_寵物: {
    //     寵物No: petData.petinfo.petno.toString(),
    //     出生日: item._d
    //   },
    //   testmode: '123'
    // });
    await effects.updatemixed.update({
      Item_寵物: {
        寵物No: petData.petinfo.petno.toString(),
        最後體重: item._d
      },
      testmode: '123'
    });
  };
  const updateData = async(item: any) => {
    item.寵物No = petData.petinfo.petno.toString();
    console.log(item);
    await effects.updatemixed.update({
      Item_寵物: item,
      testmode: '123'
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const updateStatus = async(status: string) => {
    let result = await effects.updatePetMedcareStatus.update({
      寵物No: petData.petinfo.petno.toString(),
      看診狀態: status,
      testmode: '123'
    });
    console.log(result);
  };

  const queuePet = async() => {
    let result = await effects.queuePet.makeOrder({
      寵物No: petData.petinfo.petno.toString(),
      飼主No: petData.ownerinfo.memberNumber.toString(),
      testmode: '123'
    });
    console.log(result);
  };

  const sendDelete = () => {
    axios.post('http://test-pet.mipoko.com/pet_api/1/deletepet',{
      testmode:'123',
      寵物No: petData.petinfo.petno.toString()
    })
    .then(function (response: any) {
      if (response.data.result === false){
        console.log(response.data.error);
      };

    }).catch(function (error: any) {
      console.log(error);
    });
  };

  return (
    <div className="page-basicdata">
      <div className="info-list">
        <div className="info-box">
          <div className="page-title">基本資料</div>
        </div>
        <div className="function-box">
          <Link to="/medicalreport" onClick={ () => updateStatus('看診中')}>
            {(petData.oncall === '看診中' ? false: true) &&　<FunctionBtn iconlink={iconEdit} name="看診"/>}
          </Link>
          <Link to="/waitingroom" style={petData.oncall === '看診中'  ? { opacity: 0.5}:{}} onClick={queuePet}>
            {petData.oncall === '' && <FunctionBtn iconlink={iconWaitingRoom} name="快速候診"/>}
          </Link>
        </div>
      </div>
      <Spin spinning={petData === {} ? true: false}>
        <div className="basicdata-box">
          <div className="main-info">
            {petData.oncall !== '' &&
              <div className={petData.oncall === '候診中' ? "operation-status status-waiting":"operation-status"} >
                <div className="operation-wording">寵物{petData.oncall}</div>
              </div>
            }
            <div className="pet-info">
              <div className="box-title">寵物資料</div>
              <div className="description">
                PetNo：{petData.petinfo.petno}
              </div>
              <div className="pet-info-top">
                <div className="petpic">
                  <img src={petData.petinfo.piclink} alt={petData.petinfo.name}></img>
                </div>
                <div className="detail-info">
                  <div className="iconlist">
                    <SymbolicIcon iconlink={iconSalon} type="review" />
                    <SymbolicIcon iconlink={iconSalon} type="review" />
                  </div>
                  <div className="detail-link">
                    <Link to="medicalreport">
                      <Button type="primary" >瀏覽病歷</Button>
                    </Link>
                    <Button className="record-link" >掛號紀錄</Button>
                  </div>
                </div>
                <Button className="edit-mode" type="link" onClick={toggleModal}><EditOutlined /></Button>
              </div>
              <div className="info-form">
                <Descriptions size="middle" bordered column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}>
                  <Descriptions.Item label="喜好醫師">{petData.petinfo.doctor === null ? '未選擇':petData.petinfo.doctor}</Descriptions.Item>
                  <Descriptions.Item label="年齡">{petData.petinfo.birthday === null ? '-':moment(petData.petinfo.birthday).format(dateFormat)}</Descriptions.Item>
                  <Descriptions.Item label="寵物姓名">{petData.petinfo.name === null ? '-':petData.petinfo.name}</Descriptions.Item>
                  <Descriptions.Item label="體重">{petData.petinfo.weight === null ? '-':petData.petinfo.weight}kg</Descriptions.Item>
                  <Descriptions.Item label="節育">{(petData.petinfo.neutered === null) ? '未選擇': (petData.petinfo.neutered === true) ? '已結紮' : '未結紮'}</Descriptions.Item>
                  <Descriptions.Item label="毛色">{petData.petinfo.color === null ? '未選擇':petData.petinfo.color}</Descriptions.Item>
                  <Descriptions.Item label="晶片">{petData.petinfo.chipNumber === null ? '未選擇/-':petData.petinfo.chipNumber}</Descriptions.Item>
                  <Descriptions.Item label="性別">{petData.petinfo.gender === null ? '未選擇':petData.petinfo.gender}</Descriptions.Item>
                  <Descriptions.Item label="種類">{petData.petinfo.species}</Descriptions.Item>
                  <Descriptions.Item label="品種">{petData.petinfo.breeden === null ? '-':petData.petinfo.breeden}/{petData.petinfo.breedzh === null ? '-':petData.petinfo.breedzh}</Descriptions.Item>
                </Descriptions>
              </div>
            </div>
            <div className="owner-info">
              <div className="info-box">
                <div className="box-title">飼主資料</div>
                {/* <div className="description">
                  會員編號：{petData.petinfo.petno}
                </div> */}
                <Button className="edit-mode" type="link" onClick={toggleModal}><EditOutlined /></Button>
              </div>
              <Tabs defaultActiveKey="1" >
                <TabPane tab="基本資料" key="1">
                  <div className="info-form">
                  {petData.ownerinfo.map((list: OwnerInfo) => (
                    <Descriptions size="middle" bordered column={{ xxl: 2 , xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}>
                      <Descriptions.Item label="飼主姓名">{list.name}</Descriptions.Item>
                      <Descriptions.Item label="身分證">{list.twId}</Descriptions.Item>
                      <Descriptions.Item label="聯絡電話">{list.cellPhone}</Descriptions.Item>
                      <Descriptions.Item label="市話">{list.classicPhone}</Descriptions.Item>
                      <Descriptions.Item label="醫賴碼">{list.EDNumber}</Descriptions.Item>
                      <Descriptions.Item label="地址">{list.address}</Descriptions.Item>
                      <Descriptions.Item label="E-mail">{list.email}</Descriptions.Item>
                      <Descriptions.Item label="備註">{list.note}</Descriptions.Item>
                    </Descriptions>
                      ))}
                  </div>
                </TabPane>
                <TabPane tab="會員資訊" key="2">
                  Content of Tab Pane 2
                </TabPane>
              </Tabs>
            </div>
            <div className="delete-box">
              <Button size="large" danger ghost onClick={sendDelete}>
                <DeleteOutlined />刪除該寵物
              </Button>
              <div className="delete-wording">刪除該隻寵物將連同病歷紀錄一併刪除，請謹慎操作，點擊後將須輸入此寵物名稱才可刪除。警告文案，此區塊可分權限顯示。</div>
            </div>
          </div>
          <div className="second-info">
            <div className="medical-info">
              <div className="box-title">實施紀錄</div>
              <Tabs defaultActiveKey="1" >
                <TabPane tab="已注射預防(1)" key="1">
                  <Table columns={columns} dataSource={medicaldata} pagination={{pageSize: 3}} />
                </TabPane>
                <TabPane tab="預計實施(2)" key="2">
                  <Table columns={columns} dataSource={medicaldata} pagination={{pageSize: 3}} />
                </TabPane>
                <TabPane tab="已做檢驗(0)" key="3">
                  <Table columns={columns} dataSource={medicaldata} pagination={{pageSize: 3}} />
                </TabPane>
              </Tabs>
            </div>
            <div className="history-info">
              <div className="info-box">
                <div className="box-title">過往病史</div>
                <Button className="edit-mode" type="link" onClick={toggleModal}><EditOutlined /></Button>
              </div>
              <Tabs defaultActiveKey="1" >
                <TabPane tab="保健紀錄" key="1">
                  <div className="info-form">
                    <Descriptions size="middle" bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                      <Descriptions.Item label="絕育紀錄">已絕育</Descriptions.Item>
                      <Descriptions.Item label="每年度預防注射">基礎注射進行中</Descriptions.Item>
                      <Descriptions.Item label="狂犬病預防注射">逾時未注射</Descriptions.Item>
                      <Descriptions.Item label="心絲蟲預防">準時外用滴劑</Descriptions.Item>
                      <Descriptions.Item label="外寄生蟲預防">不定時預防</Descriptions.Item>
                      <Descriptions.Item label="內寄生蟲預防">-/-</Descriptions.Item>
                    </Descriptions>
                  </div>
                </TabPane>
                <TabPane tab="藥物過敏" key="2">
                  <Descriptions size="middle" bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                    <Descriptions.Item label="藥物過敏">{caseHistoryData.allergy.checked === true ? '有': '沒有'}</Descriptions.Item>
                    <Descriptions.Item label="藥物備註">{caseHistoryData.allergy.content}</Descriptions.Item>
                  </Descriptions>
                </TabPane>
                <TabPane tab="重大疾病" key="3">
                  <Descriptions size="middle" bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                    <Descriptions.Item label="過去曾有的重大疾病">{caseHistoryData.disease.checked === true ? '有': '沒有'}</Descriptions.Item>
                    <Descriptions.Item label="疾病備註">{caseHistoryData.disease.content}</Descriptions.Item>
                  </Descriptions>
                </TabPane>
              </Tabs>
            </div>
          </div>

          <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            // ariaHideApp={false}
            contentLabel="edit basic data"
            className="modal-box"
            overlayClassName="modal-overlay"
            closeTimeoutMS={500}
          >
          <div className="modal-close" onClick={toggleModal}></div>
          <div className="modal-title">編輯資料</div>
          <div className="basicdata-edit-modal">
            <div className="pic-editbox">
              <div className="edit-petpic">
                <ImgCrop grid rotate {...ImgCropSetting}>
                  <Upload
                    listType="picture-card"
                    fileList = {fileList}
                    onChange={updatePhoto}
                    // onPreview={onPreview}
                  >
                    {/* {fileList.length >= 1 ? null : "+ Add image"} */}
                    {fileList.length < 1 && "+ Upload"}
                  </Upload>
                </ImgCrop>
              </div>
              <div className="edit-iconbox">
                <div className="title">寵物註記</div>
                <div className="description">請點選按鈕更換註記圖片</div>
                <div className="edit-iconlist">
                  <SymbolicIcon iconlink={iconSalon} type="" />
                  <SymbolicIcon iconlink={iconSalon} type="" />
                </div>
              </div>
            </div>
            <div className="form-editbox">
              <Collapse defaultActiveKey={['1']} >
                <Panel header="寵物基本資料" key="1">
                  <div className="petdata-form">
                    <Form {...layout}
                      name="petinfo"
                      onValuesChange={updateData}
                      onFinishFailed={onFinishFailed}
                    >
                      <Form.Item
                        label="寵物姓名:"
                        name="名稱"
                        rules={[{ required: false, message: '請輸入中英文' }]}
                      >
                        <Input defaultValue={petData.petinfo.name}/>
                      </Form.Item>
                      <Form.Item
                        label="喜愛醫師:"
                        name="醫生No_最後看診醫生"
                        rules={[{ required: false, message: '不指定' }]}
                      >
                        <Select
                          defaultValue={petData.petinfo.doctor === null ? '請選擇':petData.petinfo.doctor}
                        >
                          <Option value="1">測試醫生1</Option>
                          <Option value="2">測試醫生2</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="節育:"
                        name="結紮"
                      >
                        <Select
                          defaultValue= {(petData.petinfo.neutered === null) ? '請選擇': (petData.petinfo.neutered === true) ? '已結紮' : '未結紮'}
                        >
                          <Option value="false">未結紮</Option>
                          <Option value="true">已結紮</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="晶片:"
                        name="晶片種類"
                      >
                        <Select
                          showSearch
                          placeholder="請選擇"
                        >
                          <Option value="A">Cat</Option>
                          <Option value="B">Dog</Option>
                          <Option value="C">Human</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="晶片代碼"
                      >
                        <Input placeholder="請填入晶面代碼"/>
                      </Form.Item>
                      <Form.Item
                        label="性別:"
                        name="性別"
                      >
                        <Select
                          defaultValue = {petData.petinfo.gender === null ? '請選擇':petData.petinfo.gender}
                        >
                          <Option value="公">公</Option>
                          <Option value="母">母</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="毛色:"
                        name="毛色"
                      >
                        <Select
                          defaultValue = {petData.petinfo.color === null ? '請選擇':petData.petinfo.color}
                        >
                          {state.colorList.map(name => (
                            <Option value={name}>{name}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="種類:"
                        name="種類"
                      >
                        <Select
                          defaultValue = {petData.petinfo.species === null ? '請選擇':petData.petinfo.species}
                        >
                          {state.specieList.map(name => (
                            <Option value={name}>{name}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="品種:"
                        name="品種"
                      >
                        <Select
                          showSearch
                          placeholder="Blur Neko"
                        >
                          {state.breedList.map(name => (
                            <Option value={name}>{name}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <div className="ant-row ant-form-item">
                        <label className="ant-col-4 align-label">生日:</label>
                        <DatePicker defaultValue={moment(petData.petinfo.birthday, dateFormat)} format={dateFormat} onChange={updateBirth}/>
                      </div>
                      <Form.Item
                        label="體重:"
                      >
                        <InputNumber
                          min={0}
                          step={0.1}
                          style={{ width: 150 }}
                          defaultValue={petData.petinfo.weight === null ? 0 : petData.petinfo.weight }
                          onChange={updateWeight}
                        />kg
                      </Form.Item>
                    </Form>
                  </div>
                </Panel>
              </Collapse>
            </div>
          </div>
        </Modal>

        </div>
      </Spin>
    </div>
  )
}

export default Pg_Basicdata;
