import React, {useEffect, useLayoutEffect, useRef} from 'react';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import {
    Form, Input, Button, Alert, Checkbox, Result, message, Space,
    Modal, Typography, Tabs, Select, Popconfirm, Table, Empty, Spin, Tag, Tooltip
} from 'antd';
import {useApp} from "../overmind";
import {UserInfo} from "../global";
import {Static} from "../_/Static";
import {ApiService} from "../_/ApiService";
import _ from "../_";
import {
    EyeInvisibleOutlined,
    EyeTwoTone,
    UserOutlined,
    LockOutlined,
    RedoOutlined,
    EyeFilled,
    CalendarFilled, DeleteFilled,
    ReloadOutlined,

} from '@ant-design/icons';

import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import UsePageDataManager from '../hooks/UsePageDataManager';
import UseSessionManager from "../hooks/UseSessionManager";
import UseLoadingManager from "../hooks/UseLoadingManager";
import UseCompState from "../hooks/UseCompState";
import {lastValueFrom} from "rxjs";
import Partial_Appointment__Modal_AddOwner from "./Partial_Appointment__Modal_AddOwner";
import Partial_Appointment__Modal_AddPet from "./Partial_Appointment__Modal_AddPet";
import Partial_Modal_Search from "./Partial_Appointment__Modal_Search";
import Partial_inHospital__Modal_ViewCard from "./Partial_inHospital__Modal_ViewCard";
import Partial_inHospital__Modal_ListRoom from "./Partial_inHospital__Modal_ListRoom";

import './Pg_Waitingroom.scss';
import FunctionBtn from './Box_Functionbtn';
import {StaticEvent} from "../_/StaticEvent";
import moment from "moment";
import Ln_CommaListRender from "./Ln_CommaListRender";
import Partial_Manage_Campaign__Search from "./Partial_Manage_Campaign__Search";
import Ln_AvatorImg from "./Ln_AvatorImg";


const iconLighting = require('../assets/images/icon/lighting.svg');
const iconWatting = require('../assets/images/icon/new-medical-record.svg');

const onSearch = value => console.log(value);
const {Title} = Typography;
const {TabPane} = Tabs;
const {Search} = Input;
const {Option} = Select;


type Props = {
    PrefixTarget?: string;
    RoutePathname?: string;
    MenuTemplateId?: string;
    parentState?: any;
};
export default function Pg_InHospital(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Root';
    let RoutePathname = props.RoutePathname;
    let MenuTemplateId = props.MenuTemplateId;

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    //#region LoadingManager
    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: true});
    //#endregion

    //#region PageDataManager
    const refDoLoadPageData = Static.refDoLoadPageData;
    let pageDataManager = UsePageDataManager({
        refPageLoad: refDoLoadPageData
    });
    //#endregion

    //#region SessionManager
    let sessionManager = UseSessionManager({
        pageDataManager: pageDataManager,
    });
    //#endregion

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

        state.forceRender = forceRender;
    }
    //#endregion

    //#region No Cache
    useEffect(() => {
        return () => {
            pageDataManager.emptyZone(PrefixTarget);
            pageDataManager.apply();
        }
    }, []);
    //#endregion

    //#region Boot
    useEffect(() => {
        const _loadPageData = async (): Promise<void> => {
            let hide = message.loading({
                type: 'loading',
                class: 'myMessage',
                content: <div className="myMessageBody">搜尋中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            //const id : any = this.props.match.params.id;
            // const id = 1;
            // if (!id) {
            //     return; //ERROR INPUTS
            // }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                //p1: 1,
                s: state.s,
                q: state.q,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/pg_inhospital');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        pageDataManager.merge({
                            ...payload,
                        });
                        pageDataManager.apply();
                        pageDataManager.forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }

        const doLoadPageData = async ({alwaysLoad = false}): Promise<void> => {
            if (!alwaysLoad) {
                pageDataManager.restore();
                if (!pageDataManager.isZoneExist(PrefixTarget)) {
                    await sessionManager.initSessionAuthAsync();
                    //#region User Check //TODO: VERTIFY
                    if (!sessionManager.isMemberLoggedIn()) {
                        Static.NavAwayToLogin(pageDataManager, navigate)(null);
                        return;
                    }
                    //#endregion
                    await _loadPageData();
                } else {
                    forceRender()
                }
            } else {
                await _loadPageData();
            }
        }

        refDoLoadPageData.current = doLoadPageData;
        refDoLoadPageData.current({});
    }, [location.pathname]);
    //#endregion

    //#region :Popup :createModalEditRoom_refModal
    const createModalEditRoom_refModal = useRef<any>(null);
    const createModalEditRoom = (e: any, tasks?: string[]): void => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_inHospital__Modal_ListRoom
                PrefixTarget={'Partial_inHospital__Modal_ListRoom'}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModalEditRoom_refModal,
                }}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '70em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModalEditRoom_refModal.current = Modal.info(config);
    }
    //#endregion

    //#region :Popup :createModalRoomDetail_refModal
    const createModalRoomDetail_refModal = useRef<any>(null);
    const createModalRoomDetail = (e: any, {住院籠子No, tasks}: {
        住院籠子No: any,
        tasks?: string[]
    }) => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_inHospital__Modal_ViewCard
                PrefixTarget={'Partial_inHospital__Modal_ViewCard'}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModalRoomDetail_refModal,
                    住院籠子No,
                }}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '60em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModalRoomDetail_refModal.current = Modal.info(config);
    }
    //#endregion

    const info = () => {
        message.info('此籠子為空的，沒有任何寵物入住！');
    };

    //#region Set Body
    const GridBody = () => {
        const RegionEmptyShow = true;
        const RegionLoadingShow = true;

        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;


        let List_住院籠子 = pageDataManager.get(`${PrefixTarget}.List_住院籠子`);
        // let DataSource_票劵 = state.DataSource_票劵 = null; //reset
        // if (List_票劵 !== null) {
        //   let serialCounter = 0;
        //   DataSource_票劵 = List_票劵.map((r) => {
        //     serialCounter += 1;
        //
        //     return {
        //       entity: r,
        //
        //       key: r.用戶No,
        //
        //       編號: serialCounter,
        //       活動名稱: r.活動名稱,
        //       序號: r.序號,
        //       到期日: r.到期日 ? moment.utc(r.到期日).format('YYYY-MM-DD') : '',
        //       核銷日: r.核銷時間 ? moment.utc(r.核銷時間).format('YYYY-MM-DD') : '',
        //       標籤: r.Tag
        //     }
        //   });
        //
        //   state.DataSource_票劵 = DataSource_票劵;
        // }

        if (!PassPrefixTargetLoaded) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <div>Loading.</div>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        if (List_住院籠子 && List_住院籠子.length > 0) {
            return <React.Fragment>
                {List_住院籠子.map(Item_住院籠子 => {
                    if (Item_住院籠子 == null) {
                        return null;
                    }

                    //debugger;

                    if (!(Item_住院籠子?._掛號?._病歷?.__病歷項_最新住院項)) {
                        return <React.Fragment key={`${List_住院籠子.住院籠子Id}_off`}>
                            <div className="roombox" onClick={info}>
                                <div className="titlebar">
                                    <div className="txtgp">
                                        <div className="txt">區籠：<span>{Item_住院籠子.區域籠號}</span></div>
                                    </div>
                                    <div className="txt"><span>--</span></div>
                                </div>
                                <div className="inforbar">
                                    <div className="txt">住院期間：</div>
                                    <div className="txt">剩餘<span>--</span>天</div>
                                </div>
                                <div className="maincontent">
                                    <div className="incol">
                                        <div className="maininfo">
                                            <div className="empty">尚未加入</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>;
                    }

                    let 住院期間: any = null;
                    let 住院剩餘天: any = null;
                    let 住院進行天: any = null;
                    let 住院預計天: any = null;
                    if (!_.util.nullify(Item_住院籠子._掛號._病歷.__病歷項_最新住院項?.住院_住院開始時間)) {
                        住院期間 = '錯誤:未設置開始住院時間';
                    } else {
                        if (!_.util.nullify(Item_住院籠子._掛號._病歷.__病歷項_最新住院項?.住院_住院預計結束時間)) {
                            住院期間 = '錯誤:住院預計結束時間';
                        } else {
                            let startDate = moment(Item_住院籠子._掛號._病歷.__病歷項_最新住院項.住院_住院開始時間);
                            //let moreDays = (+Item_住院籠子._掛號._病歷.__病歷項_最新住院項.住院預計天數);
                            let endDate = moment(Item_住院籠子._掛號._病歷.__病歷項_最新住院項.住院_住院預計結束時間);
                            let totalDays = endDate.diff(startDate, 'days');
                            let now = moment();
                            let remainingDays = endDate.diff(now, 'days');
                            if (remainingDays < 0) {
                                remainingDays = 0;
                            }
                            let runningDays = now.diff(startDate, 'days');
                            if (runningDays < 0) {
                                runningDays = 0;
                            }
                            住院預計天 = totalDays;
                            住院進行天 = runningDays;
                            住院剩餘天 = remainingDays;
                            住院期間 = `${startDate.format('MM-DD')} - ${endDate.format('MM-DD')}`;
                        }
                    }

                    let Item_寵物 = Item_住院籠子?._掛號?._寵物;
                    let 年齡: any = null;
                    if (!_.util.nullify(Item_寵物?.出生日)) {
                        年齡 = '沒有填出生日';
                    } else {
                        try {
                            let birthDate = moment(Item_寵物.出生日);
                            let now = moment();
                            var yearDiff = now.diff(birthDate, "year");
                            var monthDiff = now.diff(birthDate, "month");
                            年齡 = '';
                            if (monthDiff >= 0) {
                                年齡 = `${monthDiff}個月` + 年齡;
                            }
                            if (yearDiff >= 0) {
                                年齡 = `${yearDiff}歲` + 年齡;
                            }
                            if (年齡 === '') {
                                年齡 = `未滿一個月`;
                            }
                        } catch (err) {
                            年齡 = '計算錯誤';
                        }
                    }

                    let 體重: any = _.util.nullify(Item_寵物?.最後體重) ?? _.util.nullify(Item_寵物?.口述體重);
                    if (!體重) {
                        體重 = '沒有測量或告知';
                    }


                    return <React.Fragment key={List_住院籠子.住院籠子Id}>
                        <div className="roombox" onClick={(e) => createModalRoomDetail(e, {住院籠子No: Item_住院籠子.住院籠子No})}>
                            <div className="titlebar">
                                <div className="txtgp">
                                    <div className="txt">區籠：<span>{Item_住院籠子.區域籠號}</span></div>
                                </div>
                                <div className="txt"><span>{Item_住院籠子._掛號.__醫生_看診醫師?.名稱}</span></div>
                            </div>
                            <div className="inforbar">
                                <div className="txt">住院期間：{住院期間}</div>
                                {住院剩餘天 !== null &&
                                <div className="txt">剩餘<span>{住院剩餘天}</span>天</div>}
                            </div>
                            <div className="maincontent">
                                <div className="incol">
                                    <div className="avatar">
                                        {Ln_AvatorImg({
                                            名稱: Item_住院籠子._掛號._寵物.寵物名稱,
                                            照片組: Item_住院籠子._掛號._寵物.寵物照片組
                                        })}
                                    </div>
                                    <div className="maininfo">
                                        <div className="con">{Item_住院籠子._掛號._寵物.品種}<span>．</span>{Item_住院籠子._掛號._寵物.種類}
                                        </div>
                                        <div className="detail">
                                            <div className="info"><span>年齡：</span>{年齡}</div>
                                            <div className="info"><span>體重：</span>{體重}</div>
                                        </div>
                                        <div className="title">
                                            <div className="txt">{Item_住院籠子._掛號._飼主.主人姓名}</div>
                                            <div className="ms"><a
                                                href={'tel:' + Item_住院籠子._掛號._飼主.手機}>{Item_住院籠子._掛號._飼主.手機}</a></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="infobar"><span>[住院原因]</span>{Item_住院籠子._掛號._病歷.__病歷項_最新住院項?.住院_住院原因}
                                </div>
                            </div>
                        </div>
                    </React.Fragment>;
                })}
            </React.Fragment>;
        } else if (List_住院籠子 && List_住院籠子.length === 0) {
            if (RegionEmptyShow) {
                return <React.Fragment>
                    <div className="nocase">
                        <p className="nocase-title">尚無籠子</p>
                        <p className="nocase-description">請先新增籠子</p>
                    </div>
                </React.Fragment>;
            }
        }

        return null;
    };
    //#endregion

    return (
        <div className="page-appointment page-content">
            <div className="info-list">
                <div className="info-box">
                    <Title level={2}>住院</Title>

                    <div className="pluginfobox">
                        <div className="title">
                            <Tooltip title="刷新">
                                <Button icon={<ReloadOutlined/>} shape="circle" onClick={(e) => {
                                    ///reload
                                    if (e) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }
                                    pageDataManager.clear();
                                    pageDataManager.reloadAsync({alwaysLoad: true});
                                }}/>
                            </Tooltip>
                        </div>
                        <div className="info"></div>
                    </div>

                    <div className="pluginfobox">
                        <div className="title"></div>
                        <div className="info"></div>
                    </div>

                </div>
                <div className="function-box">
                    <button onClick={createModalEditRoom}>
                        <FunctionBtn iconlink={iconWatting} name="編輯區域籠號"/>
                    </button>
                </div>
            </div>


            <div className="page-waitingroom">
                <Tabs type="card">
                    <TabPane tab="全部" key="1">
                        <div className="patient-list">
                            <div className="grid-layout" style={{gridTemplateColumns: "1fr 1fr 1fr 1fr"}}>
                                {GridBody()}
                            </div>
                        </div>
                    </TabPane>
                    {/* <TabPane tab="區域2" key="2">
            <div className="patient-list">
              Content of Tab Pane 2
            </div>
          </TabPane>
          <TabPane tab="區域3" key="3">
            <div className="patient-list">
              Content of Tab Pane 3
            </div>
          </TabPane> */}
                </Tabs>
            </div>
        </div>

    )
}

Pg_InHospital.displayName = 'Pg_InHospital';
