import React, {useEffect, useLayoutEffect, useRef} from 'react';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import {
    Form, Input, Button, Alert, Checkbox, Result, message, Space,
    Modal, Typography, Tabs, Select, Popconfirm, Table, Empty, Tag, Tooltip
} from 'antd';
import {useApp} from "../overmind";
import {UserInfo} from "../global";
import {Static} from "../_/Static";
import {ApiService} from "../_/ApiService";
import _ from "../_";
import {
    EyeInvisibleOutlined,
    EyeTwoTone,
    UserOutlined,
    LockOutlined,
    EyeFilled,
    CalendarFilled,
    DeleteFilled, ReloadOutlined
} from '@ant-design/icons';
import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import UsePageDataManager from '../hooks/UsePageDataManager';
import UseSessionManager from "../hooks/UseSessionManager";
import UseLoadingManager from "../hooks/UseLoadingManager";
import UseCompState from "../hooks/UseCompState";
import {lastValueFrom} from "rxjs";
import Partial_Appointment__Modal_AddOwner from "./Partial_Appointment__Modal_AddOwner";
import Partial_Appointment__Modal_AddPet from "./Partial_Appointment__Modal_AddPet";
import Partial_Manage_Campaign__Modal_EditOrAddCampaign from "./Partial_Manage_Campaign__Modal_EditOrAddCampaign";
import Partial_Appointment__Modal_EditOrAddRegister from "./Partial_Appointment__Modal_EditOrAddRegister";
import Partial_Manage_Campaign__Modal_ViewCampaign from "./Partial_Manage_Campaign__Modal_ViewCampaign";
import Partial_Manage_Campaign__Search from "./Partial_Manage_Campaign__Search";

import './Pg_Appointment.scss';
import FunctionBtn from './Box_Functionbtn';
import {StaticEvent} from "../_/StaticEvent";
import Partial_Manage_Staff__Info from "./Partial_Manage_Staff__Info";
import moment from "moment";
import Ln_CommaListRender from "./Ln_CommaListRender";
import Partial_Manage_Hospital__Search from "./Partial_Manage_Hospital__Search";
import Partial_Manage_Hospital__Modal_EditOrAddHospital from "./Partial_Manage_Hospital__Modal_EditOrAddHospital";
import Partial_Manage_Hospital__Modal_UserLog from "./Partial_Manage_Hospital__Modal_UserLog";


const urlParse = require('url-parse');
const queryString = require('query-string');

const iconLighting = require('../assets/images/icon/lighting.svg');
const iconWatting = require('../assets/images/icon/new-medical-record.svg');
const iconCampaine = require('../assets/images/icon/i_coupon.svg');

const onSearch = value => console.log(value);
const {Title} = Typography;
const {TabPane} = Tabs;
const {Search} = Input;
const {Option} = Select;


type Props = {
    PrefixTarget?: string;
    RoutePathname?: string;
    MenuTemplateId?: string;

    parentState?: any;
};
export default function Pg_Manage_Hospital(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Root';
    let RoutePathname = props.RoutePathname;
    let MenuTemplateId = props.MenuTemplateId;

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    //#region LoadingManager
    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});
    //#endregion

//#region PageDataManager
    const refDoLoadPageData = Static.refDoLoadPageData;
    let pageDataManager = UsePageDataManager({
        refPageLoad: refDoLoadPageData
    });
    //#endregion

    //#region SessionManager
    let sessionManager = UseSessionManager({
        pageDataManager: pageDataManager,
    });
    //#endregion

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

        state.forceRender = forceRender;
        state.q = pageDataManager.get(`${PrefixTarget}.q`) ?? null;
    }
    //#endregion

    //#region :Event UpdateEventEvent
    useEffect(() => {
        let handle = StaticEvent.UpdateEvent$.subscribe(async (value) => {
            // command switch


        });

        return function cleanup() {
            handle.unsubscribe();
        };
    }, []);
    //#endregion


    //#region Boot
    useEffect(() => {
        const _loadPageData = async (): Promise<void> => {
            let hide = message.loading({
                type: 'loading',
                class: 'myMessage',
                content: <div className="myMessageBody">搜尋中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            const p1: any = state.q;
            if (!p1) {
                //return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/pg_manage_hospital');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        pageDataManager.merge({
                            ...payload,
                            q: state.q,
                        });
                        pageDataManager.apply();
                        pageDataManager.forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const _doLoadPageData = async (): Promise<void> => {
            //pageDataManager.restore();
            if (!pageDataManager.isZoneExist(PrefixTarget)) {
                await sessionManager.initSessionAuthAsync();
                //#region User Check //TODO: VERTIFY
                if (!sessionManager.isMemberLoggedIn()) {
                    Static.NavAwayToLogin(pageDataManager, navigate)(null);
                    return;
                }
                //#endregion
                await _loadPageData();
            } else {
                forceRender()
            }
        }

        refDoLoadPageData.current = _doLoadPageData;
        _doLoadPageData();
    }, [location.pathname]);
    //#endregion

    //#region :Popup :createModal_EditCampaign
    const createModal_EditCampaign_refModal = useRef<any>(null);
    const createModal_EditCampaign = (e: any, {票劵No, tasks}: {
        票劵No: any,
        tasks?: string[]
    }) => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_Manage_Hospital__Modal_EditOrAddHospital
                //PrefixTarget={'Partial_Manage_Campaign__Modal_EditOrAddCampaign'}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModal_EditCampaign_refModal,

                    票劵No: null,
                }}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '50em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModal_EditCampaign_refModal.current = Modal.info(config);
    }
    //#endregion

    //#region :Popup :createModal_ViewUserLog
    const createModal_ViewUserLog_refModal = useRef<any>(null);
    const createModal_ViewUserLog = (e: any, {醫院No, tasks}: {
        醫院No: any,
        tasks?: string[]
    }): void => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_Manage_Hospital__Modal_UserLog
                PrefixTarget={`Partial_Manage_Hospital__Modal_UserLog${醫院No}`}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModal_ViewUserLog_refModal,

                    醫院No: 醫院No,
                }}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '50em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModal_ViewUserLog_refModal.current = Modal.info(config);
    }


    function callback(key) {
        console.log(key);
    }

    function confirm(e) {
        console.log(e);
    }

    function cancel(e) {
        console.log(e)
    }

    //#endregion


    const Link = Static.NavAwayToRecord(navigate, {No: 1}).Link;

    const mockTags = ['tag1', 'tag2', 'tag3'];


    const campaign_data = [
        {
            編號: 3,
            票券名稱: '測試優惠3',
            序號: 'C23132',
            到期日: '無限制',
            核銷日: '未核銷',
            標籤: ['皮膚病', '狗'],
        },
        {
            編號: 2,
            票券名稱: '測試優惠2',
            序號: 'C521337',
            到期日: '2021-09-21',
            核銷日: '2021-09-22',
            標籤: ['舌頭', '貓', '咽喉'],
        },
        {
            編號: 1,
            票券名稱: '測試優惠1',
            序號: 'K44325',
            到期日: '2021-09-21',
            核銷日: '2021-09-22',
            標籤: ['羽毛', '鳥', '代謝'],
        },
    ]


    //#region Set Body
    const Body = () => {
        const RegionEmptyShow = true;
        const RegionLoadingShow = true;

        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;
        if (!PassPrefixTargetLoaded) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <div>Loading.</div>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        let List_vw醫院帳號開通 = pageDataManager.get(`${PrefixTarget}.List_vw醫院帳號開通`);
        let DataSource_vw醫院帳號開通 = state.DataSource_vw醫院帳號開通 = null; //reset
        if (List_vw醫院帳號開通 !== null) {
            let serialCounter = 0;
            DataSource_vw醫院帳號開通 = List_vw醫院帳號開通.map((r) => {
                serialCounter += 1;

                return {
                    entity: r,

                    key: r.用戶No,

                    編號: serialCounter,
                    負責人: r.負責人,
                    院所名稱: r.院所名稱,
                    登入名稱: r.登入名稱,
                    登入密碼: r.登入密碼,
                    Is啟用: r.Is啟用,
                    CreatedAt: r.CreatedAt ? moment(r.CreatedAt).format('YYYY-MM-DD hh:mm') : '-',
                    ModifiedAt: r.ModifiedAt ? moment(r.ModifiedAt).format('YYYY-MM-DD hh:mm') : '-',
                }
            });

            state.DataSource_vw醫院帳號開通 = DataSource_vw醫院帳號開通;
        }


        // 編號: serialCounter,
        //     負責人: r.負責人,
        //     登入名稱: r.登入名稱,
        //     登入密碼: r.登入密碼,
        //     Is啟用: r.Is啟用,
        //     CreatedAt: r.CreatedAt ? moment(r.CreatedAt).format('YYYY-MM-DD hh:mm') : null,
        //     ModifiedAt: r.ModifiedAt ? moment(r.ModifiedAt).format('YYYY-MM-DD hh:mm') : null,
        const campaign_col = [
            {
                title: '編號',
                dataIndex: '編號',
                key: '編號',
                width: 60,
            },
            // {
            //     title: '活動名稱',
            //     dataIndex: '活動名稱',
            //     key: '2',
            //     render: (name: string, record) => <a type="link"
            //                                          onClick={(e) => {
            //                                              createModal_ViewUserLog(e, {票劵No: record.entity.票劵No})
            //                                          }}>{name}</a>,
            // },
            {
                title: '負責人',
                dataIndex: '負責人',
                key: '負責人',
                width: 120,
            },
            {
                title: '院所名稱',
                dataIndex: '院所名稱',
                key: '院所名稱',
                width: 150,
            },
            {
                title: '登入名稱',
                dataIndex: '登入名稱',
                key: '登入名稱',
                width: 120,
            },
            {
                title: '登入密碼',
                dataIndex: '登入密碼',
                key: '登入密碼',
                width: 120,
            },
            {
                title: '建立時間',
                dataIndex: 'CreatedAt',
                key: 'CreatedAt',
                width: 100,
            },
            {
                title: '修改時間',
                dataIndex: 'ModifiedAt',
                key: 'ModifiedAt',
                width: 100,
            },
            {
                title: '啟用',
                dataIndex: 'Is啟用',
                key: 'Is啟用',
                width: 60,
                render: (text, record) => {
                    if (record) {
                        return 'Active';
                    } else {
                        return 'Deactivated';
                    }
                }

            },
            // {
            //     title: "標籤",
            //     dataIndex: "標籤",
            //     key: "標籤",
            //     render: (tags) => {
            //         return Ln_CommaListRender({
            //             照片組: tags,
            //             render: (tag) => (
            //                 <Tag color="orange" key={tag}>{tag}</Tag>
            //             )
            //         })
            //     }
            // },
            {
                title: '動作',
                dataIndex: '動作',
                key: '6',
                width: 100,
                render: (text, record) => {

                    return <div>
                        <Space>
                            <Button icon={<EyeFilled/>} onClick={(e) => {
                                createModal_ViewUserLog(e, {醫院No: record.entity.醫院No})
                            }}>更新記錄</Button>
                        </Space>
                    </div>
                }
            },
        ];

        return (
            <div className="page-appointment page-content">
                <div className="info-list">
                    <div className="info-box">
                        <Title level={2}>醫院主帳號管理</Title>
                        <div className="pluginfobox">
                            <div className="title"><Tooltip title="刷新">
                                <Button icon={<ReloadOutlined/>} shape="circle" onClick={(e) => {
                                    ///reload
                                    if (e) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }
                                    pageDataManager.clear();
                                    pageDataManager.reloadAsync({alwaysLoad: true});
                                }}/>
                            </Tooltip></div>
                            <div className="info"></div>
                        </div>

                        <div className="pluginfobox">
                            <div className="title"></div>
                            <div className="info"></div>
                        </div>

                    </div>
                    <div className="function-box">
                        <button onClick={(e) => {
                            createModal_EditCampaign(e, {票劵No: null});
                        }}

                        >
                            <FunctionBtn iconlink={iconCampaine} name="新增主帳號"/>
                        </button>
                    </div>
                </div>


                <div className="appointment-box">
                    <div className="appointwrapper">
                        <div className="appointment-filter-box">
                            <Partial_Manage_Hospital__Search
                                //PrefixTarget={'Partial_Modal_Search'}
                                pageDataManager={pageDataManager}
                                sessionManager={sessionManager}
                                navigate={navigate}
                                parentState={state}

                                viewModel={{
                                    refModal: null,
                                }}
                            />
                        </div>
                        <div className="appointment-results">
                            <div className="result-info">
                                <p className="result-title">搜尋結果({state.DataSource_vw醫院帳號開通?.length ?? 0})</p>
                            </div>
                            <Table columns={campaign_col} dataSource={state.DataSource_vw醫院帳號開通}
                                   pagination={{pageSize: 6}}
                                   locale={{
                                       emptyText: <Empty description={
                                           <span>沒有搜尋到相關資料～</span>
                                       }/>
                                   }}/>
                        </div>
                    </div>
                </div>
            </div>

        )
    };
    //#endregion

    //#region Render

    let member: any = null;

    let isAllow: any = false;

    if (sessionManager.isMemberLoggedIn()) {
        member = sessionManager.getMember();
        if (member.Item_用戶.用戶No === "1") {
            isAllow = true;
        }
    }

    return (<React.Fragment>
            {isAllow ? <React.Fragment>
                {Body()}
            </React.Fragment> : <React.Fragment>
                <div className="page-appointment page-content">
                    <div className="info-list">
                        <div className="info-box">
                            <Title level={2}>醫院主帳號管理</Title>
                            <div className="pluginfobox">
                                <div className="title"><Tooltip title="刷新">
                                    <Button icon={<ReloadOutlined/>} shape="circle" onClick={(e) => {
                                        ///reload
                                        if (e) {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }
                                        pageDataManager.clear();
                                        pageDataManager.reloadAsync({alwaysLoad: true});
                                    }}/>
                                </Tooltip></div>
                                <div className="info"></div>
                            </div>

                            <div className="pluginfobox">
                                <div className="title"></div>
                                <div className="info"></div>
                            </div>

                        </div>
                        <div className="function-box">
                        </div>
                    </div>


                    <div className="appointment-box">
                        <div className="appointwrapper">
                            <div className="appointment-results">
                                 <Empty description={
                                               <div>權限不足，請用主管理帳號登入</div>
                                           }/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-appointment page-content">

                </div>
            </React.Fragment>
            }
        </React.Fragment>
    )
    //#endregion
}

Pg_Manage_Hospital.displayName = 'Pg_Manage_Hospital';




























