import React, {useEffect, useLayoutEffect, useRef} from 'react';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import {
    Form, Input, Button, Alert, Checkbox, Result, message, Space,
    Modal, Typography, Tabs, Select, Popconfirm, Table, Empty, Spin, Tooltip
} from 'antd';
import {useApp} from "../overmind";
import {UserInfo} from "../global";
import {Static} from "../_/Static";
import {ApiService} from "../_/ApiService";
import _ from "../_";
import {EyeInvisibleOutlined, EyeTwoTone, UserOutlined, LockOutlined, ReloadOutlined} from '@ant-design/icons';

import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import UsePageDataManager from '../hooks/UsePageDataManager';
import UseSessionManager from "../hooks/UseSessionManager";
import UseLoadingManager from "../hooks/UseLoadingManager";
import UseCompState from "../hooks/UseCompState";
import {lastValueFrom} from "rxjs";
import Partial_Appointment__Modal_AddOwner from "./Partial_Appointment__Modal_AddOwner";
import Partial_Appointment__Modal_AddPet from "./Partial_Appointment__Modal_AddPet";
import Partial_Modal_Search from "./Partial_Appointment__Modal_Search";
import Partial_CommonCard from "./Partial_CommonCard";
import Partial_Waitingroom__Tab_WaitingList from "./Partial_Waitingroom__Tab_WaitingList";

import './Pg_Waitingroom.scss';
import FunctionBtn from './Box_Functionbtn';
import {StaticEvent} from "../_/StaticEvent";
import Partial_Waitingroom__Tab_UnfinishedList from "./Partial_Waitingroom__Tab_UnfinishedList";
import Partial_Waitingroom__Tab_ServingList from "./Partial_Waitingroom__Tab_ServingList";
import Partial_Charge__Tab_ChargeList from "./Partial_Charge__Tab_ChargeList";


const iconLighting = require('../assets/images/icon/lighting.svg');
const iconWatting = require('../assets/images/icon/new-medical-record.svg');

const onSearch = value => console.log(value);
const {Title} = Typography;
const {TabPane} = Tabs;
const {Search} = Input;
const {Option} = Select;


type Props = {
    PrefixTarget?: string;
    RoutePathname?: string;
    MenuTemplateId?: string;
    parentState?: any;
};
export default function Pg_Waitingroom(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Root';
    let RoutePathname = props.RoutePathname;
    let MenuTemplateId = props.MenuTemplateId;

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    //#region LoadingManager
    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: true});
    //#endregion

    //#region PageDataManager
    const refDoLoadPageData = Static.refDoLoadPageData;
    let pageDataManager = UsePageDataManager({
        refPageLoad: refDoLoadPageData
    });
    //#endregion

    //#region SessionManager
    let sessionManager = UseSessionManager({
        pageDataManager: pageDataManager,
    });
    //#endregion


    //#region Boot
    const state = UseCompState({parentState: props.parentState});

    let defaultS = '全部';
    let defaultQ = '';

    if (!state.hasInit) {
        state.hasInit = true;

        state.pageDataManager = pageDataManager;
        state.sessionManager = sessionManager;
        state.loadingManager = loadingManager;
        state.navigate = navigate;
        state.forceRender = forceRender;

        state.subscribed = [];

        state.s = pageDataManager.get(`${PrefixTarget}.s`) ?? defaultS;
        state.q = pageDataManager.get(`${PrefixTarget}.q`) ?? defaultQ;
    }

    useEffect(() => {
        const _loadPageData = async (): Promise<void> => {
            let hide = message.loading({
                type: 'loading',
                class: 'myMessage',
                content: <div className="myMessageBody">搜尋中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            //const id : any = this.props.match.params.id;
            // const id = 1;
            // if (!id) {
            //     return; //ERROR INPUTS
            // }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                //p1: 1,
                s: state.s,
                q: state.q,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/pg_waitingroom');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        pageDataManager.merge({
                            ...payload,
                        });
                        pageDataManager.apply();

                        pageDataManager.forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPageData = async ({alwaysLoad = false}): Promise<void> => {
            if (!alwaysLoad) {
                pageDataManager.restore();
                if (!pageDataManager.isZoneExist(PrefixTarget)) {
                    await sessionManager.initSessionAuthAsync();
                    //#region User Check //TODO: VERTIFY
                    if (!sessionManager.isMemberLoggedIn()) {
                        Static.NavAwayToLogin(pageDataManager, navigate)(null);
                        return;
                    }
                    //#endregion
                    await _loadPageData();
                } else {
                    forceRender()
                }
            } else {
                await _loadPageData();
            }
        }

        refDoLoadPageData.current = doLoadPageData;
        refDoLoadPageData.current({});
    }, [location.pathname]);
    //#endregion


    const [noCase, setNoCase] = useState(false);

    function callback(key) {
        console.log(key);
    }

    function confirm(e) {
        console.log(e);
    }

    function cancel(e) {
        console.log(e)
    }

    const OperationsSlot = {
        right: <div className="dotor-filter">
            診療醫師:
            <Select
                showSearch
                allowClear={true}
                style={{width: 140}}
                placeholder="不指定"
            >
                <Option value='林峯權 醫師'>林峯權 醫師</Option>
                <Option value='李一封 醫師'>李一封 醫師</Option>
                <Option value='袁和平 醫師'>袁和平 醫師</Option>
            </Select>
        </div>
    };


    return (
        <div className="page-appointment page-content">
            <div className="info-list">
                <div className="info-box">
                    <Title level={2}>候診及待辦</Title>
                    <div className="pluginfobox">
                        <div className="title"><Tooltip title="刷新">
                            <Button icon={<ReloadOutlined />} shape="circle" onClick={(e) => {
                                ///reload
                                if (e) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                                pageDataManager.clear();
                                pageDataManager.reloadAsync({alwaysLoad: true});
                            }}/>
                            </Tooltip></div>
                        <div className="info"></div>
                    </div>

                    <div className="pluginfobox">
                        <div className="title"></div>
                        <div className="info"></div>
                    </div>

                </div>
                <div className="function-box">
                    <button>

                    </button>

                </div>
            </div>


            <div className="page-waitingroom">
                <Tabs type="card">
                    <TabPane tab="候診中" key="1">
                        <div className="patient-list">
                            <div className="patient-box">
                                <Partial_Waitingroom__Tab_WaitingList
                                    PrefixTarget={'Partial_Waitingroom__Tab_WaitingList'}
                                    pageDataManager={pageDataManager}
                                    sessionManager={sessionManager}
                                    navigate={navigate}

                                    viewModel={{
                                        refModal: null,
                                    }}
                                />
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab="看診/住院/服務中" key="2">
                        <div className="patient-list">
                            <div className="patient-box">
                                <Partial_Waitingroom__Tab_ServingList
                                    PrefixTarget={'Partial_Waitingroom__Tab_ServingList'}
                                    pageDataManager={pageDataManager}
                                    sessionManager={sessionManager}
                                    navigate={navigate}

                                    viewModel={{
                                        refModal: null,
                                    }}
                                />
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab="待完成病歷" key="3">
                        <div className="patient-list">
                            <Partial_Waitingroom__Tab_UnfinishedList
                                PrefixTarget={'Partial_Waitingroom__Tab_UnfinishedList'}
                                pageDataManager={pageDataManager}
                                sessionManager={sessionManager}
                                navigate={navigate}

                                viewModel={{
                                    refModal: null,
                                }}
                            />
                        </div>
                    </TabPane>
                    {/*<TabPane tab="歷史紀錄" key="3">*/}
                    {/*    <div className="patient-list">*/}
                    {/*        Under construction*/}
                    {/*    </div>*/}
                    {/*</TabPane>*/}
                </Tabs>
            </div>
        </div>
    )
}

Pg_Waitingroom.displayName = 'Pg_Waitingroom';




























