import React, {useCallback, useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {
    Form as NForm,
    Popover,
    Button,
    Modal,
    message,
    Upload,
    Input,
    Select,
    Space,
    Tabs,
    Tooltip,
    Steps,
    Transfer,
    Table,
    Tag
} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';
import difference from 'lodash/difference';


import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";

import {LoadingOutlined, LockOutlined, PlusOutlined, RedoOutlined, CloseOutlined} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_addOwnerProxy from "./Partial_addOwnerProxy";
import Partial_Search_Or from "./Partial_Search_Or";
import UseCompState from "../hooks/UseCompState";


var moment = require('moment');
const {Option} = Select;
const {Search} = Input;
const onSearch = value => console.log(value);
const {TabPane} = Tabs;
const {Step} = Steps;

type ViewModelProps = {
    refModal: any,

    病歷No: any,
    掛號No: any,

    OnDid?: any;
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any,

    viewModel: ViewModelProps,
};
export default function Partial_MedicalRecord__Modal_SymptomAdd(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_MedicalRecord__Modal_SymptomAdd';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel = props.viewModel;

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion


    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

        state.List_臨床症狀 = [];
    }
    //#endregion

    //#region Boot
    let _refDoLoadPartialData: any = useRef<{ alwaysLoad?: boolean }>(null);
    useEffect(() => {
        const _loadPartialData = async (): Promise<void> => {
            // VALIDATE INPUT
            // const p1 = viewModel.住院籠子No;
            // if (!p1) {
            //     return; //ERROR INPUTS
            // }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                //p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/partial_medicalrecord/modal_symptomadd');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        let changeShard = {
                            [PrefixTarget]: {
                                ...payload,
                            }
                        }
                        pageDataManager.merge(changeShard);
                        pageDataManager.apply();

                        forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPartialData = async ({alwaysLoad = false}): Promise<void> => {
            // if (!pageDataManager.refPageData.current.Root) {
            //     return;
            // }

            /// do not dynamically load data if condition fits.
            if (!alwaysLoad) {
                if (pageDataManager.isZoneExist(PrefixTarget)) {
                    return;
                }
            }

            await _loadPartialData();
        }

        _refDoLoadPartialData.current = doLoadPartialData;
        _refDoLoadPartialData.current({});
    }, [pageDataManager.get(PrefixTarget)]);
    //#endregion


    //#region Set Body
    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };

    const Body = () => {
        const RegionEmptyShow = false;
        const RegionLoadingShow = true;
        const RegionInitialLoadingShow = false;

        const PassPageDataInitalized = pageDataManager.refPageData.current != null;
        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;

        let DataSource_臨床症狀範本: any = state.DataSource_臨床症狀範本 = [];
        let List_臨床症狀範本 = pageDataManager.get(`${PrefixTarget}.List_臨床症狀範本`);
        if (List_臨床症狀範本 !== null) {
            let serialCounter = 0;
            DataSource_臨床症狀範本 = List_臨床症狀範本.map((Item_臨床症狀範本) => {
                serialCounter += 1;

                if (serialCounter == 1) {
                }

                if (!Item_臨床症狀範本._掛號) {
                    return {
                        entity: Item_臨床症狀範本,

                        key: Item_臨床症狀範本.臨床症狀範本No,

                        serial: serialCounter,
                        ENtitle: Item_臨床症狀範本.症狀英文,
                        CNtitle: Item_臨床症狀範本.症狀中文,
                        Abbreviation: Item_臨床症狀範本.簡稱,
                        tag: Item_臨床症狀範本.症狀分類組,
                    }
                }
            });

            state.DataSource_臨床症狀範本 = DataSource_臨床症狀範本;
        }

        //#region Handlers
        const handleSubmit_saveRecord = (e: any, data: any) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }

            //#region Handle SaveUserInfo
            {
                // VALIDATE INPUT
                const p1 = data.病歷No;
                if (!p1) {
                    _._debug.error('p1');
                    return; //ERROR INPUTS
                }
                const p2 = state.List_臨床症狀;
                if (!p2) {
                    _._debug.error('p2');
                    return; //ERROR INPUTS
                }
                const p3 = data.掛號No;
                if (!p3) {
                    alert('沒有掛號無法寫入')
                    _._debug.error('p3');
                    return; //ERROR INPUTS
                }

                if (p2.length <= 0) {
                    alert('最少新增一個項目');
                    return;
                }

                let hide = message.loading({
                    type: 'loading',
                    className: 'myMessage',
                    content: <div className="myMessageBody">驗證中...</div>,
                    key: 'loading',
                    duration: 0
                });

                // WAITING SCREEN
                loadingManager.changeLoadingCount(+1);
                //StaticEvent.LoadingEvent.next(true);

                // POST
                let postData = {
                    p1,
                    p2,
                    p3,
                    timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
                };
                const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_medicalrecord/modal_symptomadd/save');
                const post$ = Static.apiService.post(postUrl, postData);
                post$.subscribe(async (res: any) => {
                        hide();

                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);

                        let data = res.data;
                        let error = data.error || null;
                        let result = error == null;

                        if (result) { // SUCEESS
                            let payload = data.payload;
                            _._debug.warn(payload);

                            // message.success({content: '建立成功，同時送往藥局審查!', key, duration: 2});
                            // const openMessage = () => {
                            //     message.loading({content: '正努力送往藥局...', key});
                            //     setTimeout(() => {
                            //         message.success({content: '藥局確認了你的藥單!', key, duration: 2});
                            //     }, 1000);
                            // };
                            //
                            // const saveMessage = () => {
                            //     message.loading({content: '儲存中...', key});
                            //     setTimeout(() => {
                            //         message.success({content: '儲存成功!', key, duration: 2});
                            //     }, 1000);
                            // };
                            //
                            //
                            // let hide = message.loading({
                            //     type: 'loading',
                            //     className: 'myMessage',
                            //     content: <div className="myMessageBody">建立成功</div>,
                            //     key: 'loading',
                            //     duration: 5
                            // });

                            handleClose(null);

                            state.parentState?._refDoLoadPartialData?.current({alwaysLoad: true});

                            // Static.NavAwayToAppointment(navigate, {
                            //     s: '手機號碼',
                            //     q: payload.Item_飼主.手機,
                            // })(null);

                            // let carryData = _._u.update(payload,
                            //     {
                            //         $merge: {
                            //             Tokens: payload?.Tokens,
                            //         },
                            //         Item_飼主: {
                            //             $set: payload?.Item_飼主,
                            //         }
                            //     }
                            // );
                            // carryData.Item_飼主 = p1;
                            // if (onDone) {
                            //     const ret = onDone(carryData);
                            //     if (ret === 'demo') {
                            //         createModalPet(null, {
                            //             carryData,
                            //         })
                            //     }
                            // } else {
                            //     alert('onDone not defined');
                            // }

                            //handleClose(null)

                        } else {
                            // AUTH USER EXPIRED
                            if (error.code == 2) {
                                //alert('帳號被登出，請重新登入');
                                //window.location.href = '/login';
                                //this.props.history.push('/login')
                                await sessionManager.setLogout();
                                pageDataManager.clear();
                                pageDataManager.reloadAsync({alwaysLoad: true});
                                alert('時間逾時已被登出');
                                return;
                            }

                            _._debug.error(error);
                            alert(error.message);
                        }
                    }, async (err) => {
                        hide();

                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);
                        _._debug.warn(err);
                        alert('網路連線有問題');
                    }
                );
                return lastValueFrom(
                    post$
                );
            }
            //#endregion
        }

        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            if (!values.username) {
                errors.username = '↑ 欄位為必填';
            } else {
            }

            if (!values.password) {
                errors.password = '↑ 欄位為必填';
            } else {
            }

            return errors;
        };

        // Customize Table Transfer
        const TableTransfer = ({leftColumns, rightColumns, ...restProps}) => (
            <Transfer {...restProps} showSelectAll={false}>
                {({
                      direction,
                      filteredItems,
                      onItemSelectAll,
                      onItemSelect,
                      selectedKeys: listSelectedKeys,
                      disabled: listDisabled,
                  }) => {
                    const columns = direction === 'left' ? leftColumns : rightColumns;

                    const rowSelection = {
                        getCheckboxProps: item => ({disabled: listDisabled || item.disabled}),
                        onSelectAll(selected, selectedRows) {
                            const treeSelectedKeys = selectedRows
                                .filter(item => !item.disabled)
                                .map(({key}) => key);
                            const diffKeys = selected
                                ? difference(treeSelectedKeys, listSelectedKeys)
                                : difference(listSelectedKeys, treeSelectedKeys);
                            onItemSelectAll(diffKeys, selected);
                        },
                        onSelect({key}, selected) {
                            onItemSelect(key, selected);
                        },
                        selectedRowKeys: listSelectedKeys,
                    };

                    return (
                        <Table
                            rowSelection={rowSelection}
                            columns={columns}
                            dataSource={filteredItems}
                            size="small"
                            onRow={({key, disabled: itemDisabled}) => ({
                                onClick: () => {
                                    if (itemDisabled || listDisabled) return;
                                    onItemSelect(key, !listSelectedKeys.includes(key));
                                },
                            })}
                        />
                    );
                }}
            </Transfer>
        );

        const mockTags = ['分類名稱1', '分類名稱2', '分類名稱3'];
        const simTags = ['tag1', 'tag2', 'tag3'];

        const mockData: any[] = [];
        for (let i = 0; i < 20; i++) {

            mockData.push({
                key: i.toString(),
                ENtitle: `Disease Disease Disease Disease${i + 1}`,
                CNtitle: `病症病症病症病症病症${i + 1}`,
                Abbreviation: `簡稱${i + 1}`,
                tag: mockTags[i % 3],
            });
        }

        //const originTargetKeys = mockData.filter(item => +item.key % 3 > 1).map(item => item.key);
        const originTargetKeys = [];

        const leftTableColumns = [
            {
                dataIndex: 'ENtitle',
                title: '症狀英文',
                width: '30%'
            },
            {
                dataIndex: 'CNtitle',
                title: '症狀中文',
                width: '30%'
            },
            {
                dataIndex: 'tag',
                title: '症狀分類',
                render: tag => <Tag>{tag}</Tag>,
            },
            {
                dataIndex: 'Abbreviation',
                title: '簡稱',
                width: '15%'
            },
        ];
        const rightTableColumns = [
            {
                dataIndex: 'ENtitle',
                title: '症狀英文',
            },
            {
                dataIndex: 'CNtitle',
                title: '症狀中文',
            },
        ];

        class SubApp extends React.Component {
            state = {
                targetKeys: [],
                disabled: false,
                showSearch: false,
            };

            onChange = nextTargetKeys => {
                this.setState({targetKeys: nextTargetKeys});
                let items: any = [];
                DataSource_臨床症狀範本.forEach((r) => {
                    if (nextTargetKeys.includes(r.key)) {
                        items.push(r.entity);
                    }
                });
                state.List_臨床症狀 = items;
            };

            handleSearch = (dir, value) => {
                console.log('search:', dir, value);
            };

            render() {
                const {targetKeys, disabled, showSearch} = this.state;
                return (
                    <>
                        <TableTransfer
                            dataSource={DataSource_臨床症狀範本}
                            targetKeys={targetKeys}
                            disabled={disabled}
                            showSearch
                            onSearch={this.handleSearch}
                            onChange={this.onChange}
                            filterOption={(inputValue, item) =>
                                (item?.ENtitle ?? '').indexOf(inputValue) !== -1 ||
                                (item?.CNtitle ?? '').indexOf(inputValue) !== -1 ||
                                (item?.Abbreviation ?? '').indexOf(inputValue) !== -1 ||
                                (item?.tag ?? '').indexOf(inputValue) !== -1
                            }
                            leftColumns={leftTableColumns}
                            rightColumns={rightTableColumns}
                        />
                    </>
                );
            }
        }

        //#endregion


        return (
            <div className="model_wrapper">
                <div className="modal_title">
                    <div className="title_info">
                        <h2>臨床症狀</h2>
                    </div>
                    <div className="info_bar"></div>
                    <div className="control_bar">
                        <Tooltip placement="top" title={'關閉'}>
                            <Button type="text" icon={<CloseOutlined/>} onClick={handleClose}/>
                        </Tooltip>
                    </div>
                </div>
                <div className="modal_content">
                    <div className="content_box cd_modal">
                        <SubApp/>
                    </div>
                </div>
                <div className="modal_footer">
                    <Space className='bar_right'>
                        <Button onClick={handleClose}>取消</Button>
                        <Button type='primary' onClick={(e) => {
                            handleSubmit_saveRecord(e, {
                                病歷No: viewModel.病歷No,
                                List_用藥: state.List_用藥,
                                掛號No: viewModel.掛號No,
                            })
                        }}>送出</Button>
                    </Space>
                </div>
            </div>
        );
    };

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_MedicalRecord__Modal_SymptomAdd.displayName = 'Partial_MedicalRecord__Modal_SymptomAdd';

