import React from 'react';
import './RecordCard.scss';

function RecordCard() {
  return (
    <div className="record-card">
      {/* <div className="flow-line"></div> */}
      <div className="flow-card">
        <div className="card-title">臨床症狀</div>
        <div className="card-content">
        行為
        </div>
      </div>
    </div>
  )
}

export default RecordCard;
 