import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {
    Form as NForm,
    Popover,
    Button,
    Modal,
    message,
    Upload,
    Input,
    Space,
    Select,
    Radio,
    DatePicker,
    InputNumber,
    Menu,
    Dropdown, Typography, Tabs, Spin, Table, Empty
} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";

import {
    LoadingOutlined, LockOutlined, PlusOutlined, DownOutlined,
    EllipsisOutlined, GlobalOutlined, PhoneOutlined, SmileFilled, FileImageFilled
} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_Appointment__Modal_Preview from "./Partial_Appointment__Modal_Preview";
import Partial_Modal_Photogallery from "./Partial_Modal_Photogallery";
import Partial_Appointment__Modal_EditOrAddRegister from "./Partial_Appointment__Modal_EditOrAddRegister";
import Pg_MedicalRecord from "./Pg_MedicalRecord";
import Partial_CommonCard from "./Partial_CommonCard";
import UseCompState from "../hooks/UseCompState";
import Partial_MedicalRecord__Modal_Prescribe2 from "./Partial_MedicalRecord__Modal_Prescribe2";
import Partial_MedicalRecord__Modal_Prescribe from "./Partial_MedicalRecord__Modal_Prescribe";
import Partial_Modal_Confirm from "./Partial_Modal_Confirm";

const {Title} = Typography;
const {TabPane} = Tabs;
const {Search} = Input;
const {RangePicker} = DatePicker;

var moment = require('moment');
const {Option} = Select;


type ViewModelProps = {
    refModal: any,

    OnDid?: any;
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any;

    viewModel: ViewModelProps,
};
const dateFormat = 'YYYY/MM/DD';

export default function Partial_Charge__Tab_ChargeList(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_Charge__Tab_ChargeList';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    const viewModel = props.viewModel;

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

        state.fromDate = null;
        state.toDate = null;
        state.doctorNo = null;
    }
    //#endregion

    //#region Boot
    let _refDoLoadPartialData: any = useRef<{ alwaysLoad?: boolean }>(null);
    useEffect(() => {
        const _loadPartialData = async (): Promise<void> => {
            let hide = message.loading({
                type: 'loading',
                class: 'myMessage',
                content: <div className="myMessageBody">搜尋中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            //const id : any = this.props.match.params.id;
            // const id = 1;
            // if (!id) {
            //     return; //ERROR INPUTS
            // }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                //p1: 1,
                fromDate: state?.fromDate,
                toDate: state?.toDate,
                doctorNo: state?.doctorNo,

                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/partial_charge/tab_chargelist');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        let changeShard = {
                            [PrefixTarget]: {
                                ...payload,
                            }
                        }
                        pageDataManager.merge(changeShard);
                        pageDataManager.apply();
                        forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }

        const doLoadPartialData = async ({alwaysLoad = false}): Promise<void> => {
            // do not dynamically load data if condition fits.
            if (!alwaysLoad) {
                if (pageDataManager.isZoneExist(PrefixTarget)) {
                    return;
                }
            }

            await _loadPartialData();
        }

        _refDoLoadPartialData.current = doLoadPartialData;
        _refDoLoadPartialData.current({});
    }, [pageDataManager.get(PrefixTarget)]);

    //#endregion


    //#region Set Body
    const handleSubmit_enterchargedone = (e: any, {掛號No}: { 掛號No: any }) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        let hide = message.loading({
            type: 'loading',
            className: 'myMessage',
            content: <div className="myMessageBody">驗證中...</div>,
            key: 'loading',
            duration: 0
        });

        // VALIDATE INPUT
        const p1 = 掛號No;
        if (!p1) {
            _._debug.error(p1);
            return; //ERROR INPUTS
        }

        // WAITING SCREEN
        loadingManager.changeLoadingCount(+1);
        //StaticEvent.LoadingEvent.next(true);

        // POST
        let postData = {
            p1,
            timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
        };
        const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_commoncard/enterchargedone');
        const post$ = Static.apiService.post(postUrl, postData);
        post$.subscribe(async (res: any) => {
                hide();

                // CLOSE WAITING SCREEN
                loadingManager.changeLoadingCount(-1);
                //StaticEvent.LoadingEvent.next(false);

                let data = res.data;
                let error = data.error || null;
                let result = error == null;

                if (result) { // SUCEESS
                    let payload = data.payload;
                    _._debug.warn(payload);

                    message.success({content: '修改完成!', duration: 2});

                    if (isModalContained()) {
                        handleClose(null);
                    } else {
                        _refDoLoadPartialData.current({alwaysLoad: true});
                    }

                    viewModel?.OnDid?.(payload);
                } else {
                    // AUTH USER EXPIRED
                    if (error.code == 2) {
                        //alert('帳號被登出，請重新登入');
                        //window.location.href = '/login';
                        //this.props.history.push('/login')
                        await sessionManager.setLogout();
                        pageDataManager.clear();
                        pageDataManager.reloadAsync({alwaysLoad: true});
                        alert('時間逾時已被登出');
                        return;
                    }

                    _._debug.error(error);
                    alert(error.message);
                }
            }, async (err) => {
                hide();

                // CLOSE WAITING SCREEN
                loadingManager.changeLoadingCount(-1);
                //StaticEvent.LoadingEvent.next(false);
                _._debug.warn(err);
                alert('網路連線有問題');
            }
        );
        return lastValueFrom(
            post$
        );
    }

    interface PetItem {
        key: React.Key;
        date: string;
        petname: string;
        owner: string;
        phone: string;
        doctor: string;
        stauts: string;
    }

    const petlist = [
        {
            title: '開單時間',
            dataIndex: 'date',
        },
        {
            title: '寵物',
            dataIndex: 'petname',
            render: (text: string) => <a>{text}</a>,
        },
        {
            title: '飼主',
            dataIndex: 'owner',
        },
        {
            title: '聯絡電話',
            dataIndex: 'phone',
        },
        {
            title: '開藥醫生',
            dataIndex: 'doctor',
        },
        {
            title: '狀態',
            dataIndex: 'stauts',
        },
        {
            title: '執行',
            dataIndex: ' action',
            render: (text, record) => {

                return null;

                // return <div>
                //     <Button className="quick-order" type="primary"
                //             onClick={(e) => handleSubmit_enterprescribeconfirmdone(e, {掛號No: record.entity.掛號No})}
                //     >配藥</Button>
                // </div>
            },
        },
        // {
        //   title: '功能',
        //   dataIndex: ' function',
        //   render: (text, record) => {

        //     return <div>
        //       <Space>
        //         <Tooltip placement="top" title="列印藥袋">
        //           <Button  icon={<PrinterOutlined />} type="text"/>
        //         </Tooltip>
        //         <Tooltip placement="top" title="退回重開">
        //           <Button danger icon={<RollbackOutlined />} type="text"/>
        //         </Tooltip>
        //       </Space>


        //     </div>
        //   },
        // },
    ];

    const petData: PetItem[] = [
        {
            key: 1,
            date: '2020/10/28 15:17',
            petname: 'Lucky',
            owner: 'Park',
            phone: '0900168168',
            doctor: 'cccc',
            stauts: '未配藥',
        }
    ];


    //#region :Popup :createModalPrescribeRecord
    const createModalPrescribeRecord_refModal = useRef<any>(null);
    const createModalPrescribeRecord = (e: any, tasks?: string[]): void => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_MedicalRecord__Modal_Prescribe2
                PrefixTarget={'Partial_MedicalRecord__Modal_Prescribe2'}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModalPrescribeRecord_refModal,
                }}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '80em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModalPrescribeRecord_refModal.current = Modal.info(config);
    }
    //#endregion

    //#region :Popup :createModalPrescribe
    const createModalPrescribe_refModal = useRef<any>(null);
    const createModalPrescribe = (e: any, tasks?: string[]): void => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_MedicalRecord__Modal_Prescribe
                PrefixTarget={'Partial_MedicalRecord__Modal_Prescribe'}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModalPrescribe_refModal,

                    病歷No: null,
                    掛號No: null,

                    OnDid: () => {
                        pageDataManager.clear();
                        pageDataManager.reloadAsync({alwaysLoad: true});
                        //state._refDoLoadPartialData?.current?.({alwaysLoad: true});
                    }
                }}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '90em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModalPrescribe_refModal.current = Modal.info(config);
    }

    //#endregion

    const iconWaitforCase = require('../assets/images/icon/nocase.svg');

    interface oldPetItem {
        key: React.Key;
        date: string;
        actiondate: string;
        petname: string;
        owner: string;
        phone: string;
        department: string;
        totalAmount: string;
    }


    const oldpetData: oldPetItem[] = [
        {
            key: 1,
            date: '2020/10/28 15:17',
            actiondate: '2020/10/28 15:32',
            petname: 'Butterfly',
            owner: '羅志祥',
            phone: '0921134533',
            department: '售貨部',
            totalAmount: '$400',
        }
    ];

    const isModalContained = () => {
        return !!viewModel.refModal?.current
    }

    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };
    const Body = () => {

        let DataSource_出納 = state.DataSource_出納;
        const List_掛號 = pageDataManager.get(`${PrefixTarget}.List_掛號`);
        if (List_掛號 !== null) {
            let serialCounter = 0;
            DataSource_出納 = List_掛號.map((Item_掛號) => {
                serialCounter += 1;

                if (serialCounter == 1) {
                }

                return {
                    entity: Item_掛號,
                    key: Item_掛號.掛號No,

                    date: Item_掛號.起始出納時間 ? moment(Item_掛號.起始出納時間).format('YYYY-MM-DD hh:mm') : '-',
                    actiondate: Item_掛號.完成出納時間 ? moment(Item_掛號.完成出納時間).format('YYYY-MM-DD hh:mm') : '-',
                    petname: Item_掛號._寵物.寵物名稱,
                    owner: Item_掛號._飼主.主人姓名,
                    phone: Item_掛號._飼主.手機 ?? Item_掛號._飼主.聯絡電話,
                    department: '-',
                    totalAmount: '$' + _.util.commaSep(Item_掛號.出納總價金 ?? 0),
                    stauts: Item_掛號.出納狀態,
                }
            });

            state.DataSource_出納 = DataSource_出納;
        }

        const List_醫生 = pageDataManager.get(`${PrefixTarget}.List_醫生`);
        let docOptions: any = [];
        {
            if (List_醫生) {
                docOptions = List_醫生.map((r) => {
                    return {
                        key: r.醫生No,
                        value: r.醫生No,
                        label: `${r.名稱} 醫師`,
                    }
                });
                docOptions.unshift({
                    key: '0',
                    value: null,
                    label: '不指定',
                })
            }
        }

        //#region Handlers
        const createDetailConfirm_refModal = useRef<any>(null);
        const createDetailConfirm = ({content, 掛號No}): void => {
            //const [modal, contextHolder] = Modal.useModal();

            let PassPageDataInitalized = pageDataManager.refPageData.current != null;

            if (!PassPageDataInitalized) {
                //empty
            } else {
                const config = {
                    //title: 'test',
                    content: <Partial_Modal_Confirm
                        PrefixTarget={'Box_Header'}
                        pageDataManager={pageDataManager}
                        sessionManager={sessionManager}
                        navigate={navigate}

                        viewModel={{
                            refModal: createDetailConfirm_refModal,

                            showBack: true,
                            showClose: true,
                            showCancel: false,
                            OptTitle: (loadingManager, handleClose) => <div>詳細內容</div>,

                            OptOkBtn: (loadingManager, handleClose) => {
                                return <React.Fragment>
                                    <div className="rs_footer righttext">
                                        <Space>
                                            <Button onClick={(e) => {
                                                handleClose();
                                            }}>關閉</Button>
                                            <Button className="quick-order" type="primary"
                                                    onClick={(e) => handleSubmit_enterchargedone(e, {掛號No: 掛號No, OnDid: handleClose})}
                                            >確認收款</Button>
                                        </Space>
                                    </div>
                                </React.Fragment>;
                            },
                            OptContentBody: (loadingManager, handleClose) => {
                                return <div className="col_group">
                                    <div className="col flexw">
                                        <div className="innerpad pretext">{content}</div>
                                    </div>
                                </div>;
                            }
                        }}
                    />,
                     className: 'myClass',
                    // width: ' ',
                    // height: ' ',
                    // footer: null,
                    //prefixCls: 'no-modal',

                    //okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
                    //className: 'myClass myModal',
                    width: '35em',
                    height: ' ',
                    footer: null,
                    prefixCls: '',
                };

                createDetailConfirm_refModal.current = Modal.info(config);
            }
        };

        const handleSubmit_enterchargedone = (e: any, {掛號No, OnDid}: { 掛號No: any, OnDid: any  }) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }

            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            const p1 = 掛號No;
            if (!p1) {
                _._debug.error(p1);
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_commoncard/enterchargedone');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        message.success({content: '修改完成!', duration: 2});

                        if (isModalContained()) {
                            handleClose(null);
                        } else {
                            //_refDoLoadPartialData.current({alwaysLoad: true});
                        }

                        OnDid?.();
                        viewModel.OnDid?.(payload);
                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        //#endregion

        const oldpetlist = [
            {
                title: '批價時間',
                dataIndex: 'date',
            },
            {
                title: '結帳時間',
                dataIndex: 'actiondate',
            },
            {
                title: '寵物',
                dataIndex: 'petname',
                render: (name: string, record) => {
                    const Link = Static.NavAwayToRecord(navigate, {No: record.entity.寵物No}).Link;
                    return <Link>{name}</Link>
                },
            },
            {
                title: '飼主',
                dataIndex: 'owner',
                render: (name: string, record) => {
                    const Link = Static.NavAwayToClientManage(navigate, {No: record.entity.飼主No}).Link;
                    return <Link>{name}</Link>
                },
            },
            {
                title: '聯絡電話',
                dataIndex: 'phone',
            },
            {
                title: '收款狀態',
                dataIndex: 'stauts',
            },
            {
                title: '總金額',
                dataIndex: 'totalAmount',
            },
            {
                title: '動作',
                dataIndex: 'view',
                render: (text, record) => {

                    return <div>
                        <Space>
                            {/*<Button type="link" onClick={(e)=>handleSubmit_enterchargedone(e, {掛號No: record.entity.掛號No})}>收款</Button>*/}

                            <Button className="quick-order" type="primary"
                                //onClick={(e) => handleSubmit_enterchargedone(e, {掛號No: record.entity.掛號No})}
                                    onClick={(e) => createDetailConfirm({
                                        content: record.entity.出納單據,
                                        掛號No: record.entity.掛號No
                                    })}
                            >收款</Button>
                        </Space>
                    </div>
                },
            },
            // {
            //   title: '功能',
            //   dataIndex: ' function',
            //   render: (text, record) => {

            //     return <div>
            //       <Space>
            //         <Tooltip placement="top" title="轉成未結批價">
            //           <Button danger icon={<ArrowLeftOutlined />} type="text"/>
            //         </Tooltip>
            //       </Space>
            //     </div>
            //   },
            // },
        ];


        // <Spin>
        //     <div className="patient-box">
        //
        //     </div>
        //     <div className="nocase">
        //         <img src={iconWaitforCase} alt="coffee"></img>
        //         <p className="nocase-title">沒有搜尋條件所要的資料</p>
        //         <p className="nocase-description">請重新設定條件搜尋</p>
        //     </div>
        // </Spin>

        return (
            <div className="patient-list">
                <div className="schedulesearch">
                    <Space>
                        <Button
                            onClick={() => {
                                pageDataManager.clear();
                                pageDataManager.reloadAsync({alwaysLoad: true});
                            }}
                        >重置</Button>
                        <RangePicker
                            ranges={{
                                Today: [moment(), moment()],
                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                            }}
                            placeholder={["開始日期", "結束日期"]}

                            defaultValue={[state.fromDate ? moment(state.fromDate) : null, state.toDate ? moment(state.toDate) : null]}
                            onChange={(values, ...rest) => {
                                state.fromDate = values?.[0];
                                state.toDate = values?.[1];

                                _refDoLoadPartialData.current({alwaysLoad: true});
                            }}
                        />
                        <Select placeholder="請選擇指定醫師"
                                defaultValue={state?.doctorNo ?? null}
                                onChange={(value, ...rest) => {
                                    state.doctorNo = value;

                                    _refDoLoadPartialData.current({alwaysLoad: true});
                                }}
                        >
                            {docOptions.map((it => {
                                return <Option value={it.value}>{it.label}</Option>
                            }))}
                        </Select>
                    </Space>
                </div>

                <Table
                    columns={oldpetlist}
                    dataSource={DataSource_出納}
                    locale={{
                        emptyText: <React.Fragment>
                            <div className="patient-box">
                            </div>
                            <div className="nocase">
                                <img src={iconWaitforCase} alt="coffee"></img>
                                <p className="nocase-title">沒有搜尋條件所要的資料</p>
                                <p className="nocase-description">請重新設定條件搜尋</p>
                            </div>
                        </React.Fragment>
                    }}/>
            </div>
        );
    }
    //#endregion

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_Charge__Tab_ChargeList.displayName = 'Partial_Charge__Tab_ChargeList';
