import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {
    Form as NForm,
    Popover,
    Button,
    Modal,
    message,
    Upload,
    Input,
    Space,
    Select,
    Collapse,
    DatePicker,
    Spin, Row, Col, Radio
} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";

import {
    LoadingOutlined,
    LockOutlined,
    PlusOutlined,
    RedoOutlined,
    UserOutlined,
    EditOutlined,
    PlusCircleOutlined,
    DeleteFilled
} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_Appointment__Modal_AddPet from "./Partial_Appointment__Modal_AddPet";

var moment = require('moment');
const {Option} = Select;
const {Panel} = Collapse;
const dateFormat = 'YYYY/MM/DD';

const {TextArea} = Input;

type ViewModelProps = {
    refModal: any,

    病歷No: any,

    OnUpdated?: any;
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any,

    viewModel: ViewModelProps,
};
export default function Partial_MedicalRecord__Pane_PastMedicalHistory(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || ('Partial_MedicalRecord__Pane_PastMedicalHistory' + (props.viewModel.病歷No ?? "0"));
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel: any = props.viewModel;

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const layout02 = {
        labelCol: {span: 0},
        wrapperCol: {span: 25},
    };


    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region :Popup :createModalLogin
    const createModalPet_refModal = useRef<any>(null);
    const createModalPet = (e: any, tasks?: string[]): void => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_Appointment__Modal_AddPet
                PrefixTarget={'Partial_Appointment__Modal_AddPet'}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModalPet_refModal,
                }}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '60em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModalPet_refModal.current = Modal.info(config);
    }
    //#endregion


    let [number1, setNumber1] = useState<number>(1);
    let [number2, setNumber2] = useState<number>(1);
    let [number3, setNumber3] = useState<number>(1);

    //#region Boot
    let _refDoLoadPartialData: any = useRef<{ alwaysLoad?: boolean }>(null);
    useEffect(() => {
        const _loadPartialData = async (): Promise<void> => {

            // VALIDATE INPUT
            const p1 = viewModel.病歷No;
            if (!p1) {
                alert('p1')
                _._debug.error('p1');
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/partial_medicalrecord/pane_pastmedicalhistory');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        let changeShard = {
                            [PrefixTarget]: {
                                ...payload,
                            }
                        }
                        pageDataManager.merge(changeShard);
                        pageDataManager.apply();

                        forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPartialData = async ({alwaysLoad = false}): Promise<void> => {
            // if (!pageDataManager.refPageData.current.Root) {
            //     return;
            // }

            /// do not dynamically load data if condition fits.
            if (!alwaysLoad) {
                if (pageDataManager.isZoneExist(PrefixTarget)) {
                    return;
                }
            }

            await _loadPartialData();
        }

        _refDoLoadPartialData.current = doLoadPartialData;
        _refDoLoadPartialData.current({});
    }, []);
    //#endregion

    //#region No Cache
    useEffect(() => {
        return () => {
            pageDataManager.emptyZone(PrefixTarget);
            pageDataManager.apply();
        }
    }, []);
    //#endregion

    //#region Set Body
    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };
    const isModalContained = () => {
        return !!viewModel.refModal?.current
    }
    const Body = () => {
        const RegionEmptyShow = false;
        const RegionLoadingShow = true;
        const RegionInitialLoadingShow = false;

        const PassPageDataInitalized = pageDataManager.refPageData.current != null;
        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;

        let Item_病歷 = pageDataManager.get(`${PrefixTarget}.Item_病歷`);
        let List_病歷: any = Item_病歷 ? [Item_病歷] : [];

        //#region Handlers
        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            // if (!values.ownername) {
            //     errors.ownername = '↑ 欄位為必填';
            // } else {
            // }
            //
            // if (!values.cellphone) {
            //     errors.cellphone = '↑ 欄位為必填';
            // } else {
            // }

            return errors;
        };

        const handleSubmit_save = (e: any, v: any, onDone: any = null) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }

            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            const p2 = v;
            if (!p2) {
                _._debug.error(p2);
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p2,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_medicalrecord/pane_pastmedicalhistory/save');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);


                        // pageDataManager.emptyZone(PrefixTarget);
                        // let changeShard = {
                        //     [PrefixTarget]: {
                        //         ...payload,
                        //     }
                        // }
                        // pageDataManager.merge(changeShard);
                        // pageDataManager.apply();

                        message.success({content: '修改完成!', duration: 2});


                        if (onDone) {
                            onDone();
                        }

                        // if (isModalContained()) {
                        //     handleClose(null);
                        // } else {
                             _refDoLoadPartialData.current({alwaysLoad: true});

                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                        // }

                        viewModel?.OnUpdated?.(payload);
                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }

        const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;
        //#endregion

        if (!PassPrefixTargetLoaded) {
            if (RegionInitialLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <div>Loading.</div>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        if (loadingManager.getIsLoading()) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <Spin indicator={
                            <LoadingOutlined style={{fontSize: 24}} spin/>
                        }/>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        if (List_病歷 && List_病歷.length > 0) {
            return <React.Fragment>
                {List_病歷.map(Item_病歷 => {
                    return <React.Fragment key={Item_病歷?.病歷No ?? "0"}>
                        <Collapse defaultActiveKey={['1']}>
                            <Panel header="保健紀錄" key="1">
                                <div className="panel_ctrl">
                                    <div className="func_bar a_right">
                                        <Button type="link" icon={<EditOutlined/>}
                                                onClick={() => setNumber1(number1 + 1)}>編輯</Button>
                                    </div>
                                    <div className="systable col_2 mw_th">
                                        <div className="str">
                                            <div className="th">絕育狀態</div>
                                            <div
                                                className="td">{Item_病歷.絕育狀態 ?? '未填寫'} ({Item_病歷.絕育狀態時間 ? moment(Item_病歷.絕育狀態時間).format('YYYY-MM-DD') : '-'})
                                            </div>
                                        </div>
                                        <div className="str">
                                            <div className="th">年度預防注射</div>
                                            <div
                                                className="td">{Item_病歷.年度預防注射 ?? '未填寫'} ({Item_病歷.年度預防注射時間 ? moment(Item_病歷.年度預防注射時間).format('YYYY-MM-DD') : '-'})
                                            </div>
                                        </div>
                                        <div className="str">
                                            <div className="th">狂犬病預防注射</div>
                                            <div
                                                className="td">{Item_病歷.狂犬病預防注射 ?? '未填寫'} ({Item_病歷.狂犬病預防注射時間 ? moment(Item_病歷.狂犬病預防注射時間).format('YYYY-MM-DD') : '-'})
                                            </div>
                                        </div>
                                        <div className="str">
                                            <div className="th">心絲蟲預防</div>
                                            <div
                                                className="td">{Item_病歷.心絲蟲預防 ?? '未填寫'} ({Item_病歷.心絲蟲預防時間 ? moment(Item_病歷.心絲蟲預防時間).format('YYYY-MM-DD') : '-'})
                                            </div>
                                        </div>
                                        <div className="str">
                                            <div className="th">外寄生蟲預防</div>
                                            <div
                                                className="td">{Item_病歷.外寄生蟲預防 ?? '未填寫'} ({Item_病歷.外寄生蟲預防時間 ? moment(Item_病歷.外寄生蟲預防時間).format('YYYY-MM-DD') : '-'})
                                            </div>
                                        </div>
                                        <div className="str cops">
                                            <div className="th">內寄生蟲預防</div>
                                            <div
                                                className="td">{Item_病歷.內寄生蟲預防 ?? '未填寫'} ({Item_病歷.內寄生蟲預防時間 ? moment(Item_病歷.內寄生蟲預防時間).format('YYYY-MM-DD') : '-'})
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {number1 % 2 == 0 &&
                                <div className="panel_ctrl">

                                    <Form
                                        onSubmit={(v) => handleSubmit_save(null, v, () => {
                                            setNumber1(1)
                                        })}
                                        initialValues={{
                                            ...Item_病歷,
                                            病歷No: viewModel.病歷No,
                                        }}
                                        validate={handleValidate}
                                        render={({
                                                     submitError,
                                                     handleSubmit,
                                                     submitting,
                                                     pristine,
                                                     valid,
                                                     error,
                                                     values
                                                 }) => {

                                            return <React.Fragment>

                                                <div className="form_body">
                                                    <div className="func_bar a_right">
                                                        <Button type="link" htmlType="submit"
                                                                onClick={handleSubmit}>儲存</Button>
                                                    </div>
                                                    <form
                                                        onSubmit={handleSubmit}
                                                        {...layout}>


                                                        <React.Fragment>
                                                            <NForm.Item  {...layout}
                                                                         label="絕育狀態:"
                                                            >
                                                                <Input.Group compact className='col2'>
                                                                    <Field parse={_.bs.identity} name='絕育狀態'>
                                                                        {
                                                                            ({input, meta}) => (
                                                                                <NForm.Item {...input}
                                                                                            name="絕育狀態"
                                                                                >
                                                                                    <Select {...input}
                                                                                            placeholder="請選擇狀態"
                                                                                    >
                                                                                        <Option value="已絕育">已絕育</Option>
                                                                                        <Option value="未絕育">未絕育</Option>
                                                                                    </Select>
                                                                                    {meta.touched && meta.error &&
                                                                                    <div className="error">
                                                                                        {meta.error}
                                                                                    </div>}
                                                                                </NForm.Item>
                                                                            )
                                                                        }
                                                                    </Field>
                                                                    <Field parse={_.bs.identity} name='絕育狀態時間'>
                                                                        {
                                                                            ({input, meta}) => {
                                                                                const datepickerProps: any = {};
                                                                                if (input.value) {
                                                                                    datepickerProps.defaultValue = moment(input.value);
                                                                                }
                                                                                datepickerProps.onChange = input.onChange;

                                                                                return <NForm.Item {...input}
                                                                                                   name="絕育狀態時間"

                                                                                >
                                                                                    <DatePicker
                                                                                        {...datepickerProps}
                                                                                        format={dateFormat}
                                                                                        placeholder="請選擇日期"/>
                                                                                    {meta.touched && meta.error &&
                                                                                    <div className="error">
                                                                                        {meta.error}
                                                                                    </div>}
                                                                                </NForm.Item>
                                                                            }
                                                                        }
                                                                    </Field>
                                                                </Input.Group>
                                                            </NForm.Item>
                                                        </React.Fragment>


                                                        <React.Fragment>
                                                            <NForm.Item  {...layout}
                                                                         label="年度預防注射:"
                                                            >
                                                                <Input.Group compact className='col2'>
                                                                    <Field parse={_.bs.identity} name='年度預防注射'>
                                                                        {
                                                                            ({input, meta}) => (
                                                                                <NForm.Item {...input}
                                                                                            name="年度預防注射"
                                                                                >
                                                                                    <Select {...input}
                                                                                            placeholder="請選擇狀態"
                                                                                    >
                                                                                        <Option
                                                                                            value="按時注射">按時注射</Option>
                                                                                        <Option
                                                                                            value="未按時注射">未按時注射</Option>
                                                                                    </Select>
                                                                                    {meta.touched && meta.error &&
                                                                                    <div className="error">
                                                                                        {meta.error}
                                                                                    </div>}
                                                                                </NForm.Item>
                                                                            )
                                                                        }
                                                                    </Field>
                                                                    <Field parse={_.bs.identity} name='年度預防注射時間'>
                                                                        {
                                                                            ({input, meta}) => {
                                                                                const datepickerProps: any = {};
                                                                                if (input.value) {
                                                                                    datepickerProps.defaultValue = moment(input.value);
                                                                                }
                                                                                datepickerProps.onChange = input.onChange;

                                                                                return <NForm.Item {...input}
                                                                                                   name="年度預防注射時間"

                                                                                >
                                                                                    <DatePicker
                                                                                        {...datepickerProps}
                                                                                        format={dateFormat}
                                                                                        placeholder="請選擇日期"/>
                                                                                    {meta.touched && meta.error &&
                                                                                    <div className="error">
                                                                                        {meta.error}
                                                                                    </div>}
                                                                                </NForm.Item>
                                                                            }
                                                                        }
                                                                    </Field>

                                                                </Input.Group>
                                                            </NForm.Item>
                                                        </React.Fragment>


                                                        <React.Fragment>
                                                            <NForm.Item  {...layout}
                                                                         label="狂犬病預防注射:"
                                                            >
                                                                <Input.Group compact className='col2'>
                                                                    <Field parse={_.bs.identity} name='狂犬病預防注射'>
                                                                        {
                                                                            ({input, meta}) => (
                                                                                <NForm.Item {...input}
                                                                                            name="狂犬病預防注射"
                                                                                >
                                                                                    <Select {...input}
                                                                                            placeholder="請選擇狀態"
                                                                                    >
                                                                                        <Option value="不清楚">不清楚</Option>
                                                                                        <Option
                                                                                            value="未按時注射">未按時注射</Option>
                                                                                    </Select>
                                                                                    {meta.touched && meta.error &&
                                                                                    <div className="error">
                                                                                        {meta.error}
                                                                                    </div>}
                                                                                </NForm.Item>
                                                                            )
                                                                        }
                                                                    </Field>
                                                                    <Field parse={_.bs.identity} name='狂犬病預防注射時間'>
                                                                        {
                                                                            ({input, meta}) => {
                                                                                const datepickerProps: any = {};
                                                                                if (input.value) {
                                                                                    datepickerProps.defaultValue = moment(input.value);
                                                                                }
                                                                                datepickerProps.onChange = input.onChange;

                                                                                return <NForm.Item {...input}
                                                                                                   name="狂犬病預防注射時間"

                                                                                >
                                                                                    <DatePicker
                                                                                        {...datepickerProps}
                                                                                        format={dateFormat}
                                                                                        placeholder="請選擇日期"/>
                                                                                    {meta.touched && meta.error &&
                                                                                    <div className="error">
                                                                                        {meta.error}
                                                                                    </div>}
                                                                                </NForm.Item>
                                                                            }
                                                                        }
                                                                    </Field>


                                                                </Input.Group>
                                                            </NForm.Item>
                                                        </React.Fragment>


                                                        <React.Fragment>
                                                            <NForm.Item  {...layout}
                                                                         label="心絲蟲預防:"
                                                            >
                                                                <Input.Group compact className='col2'>
                                                                    <Field parse={_.bs.identity} name='心絲蟲預防'>
                                                                        {
                                                                            ({input, meta}) => (
                                                                                <NForm.Item {...input}
                                                                                            name="心絲蟲預防"
                                                                                >
                                                                                    <Select {...input}
                                                                                            placeholder="請選擇狀態"
                                                                                    >
                                                                                        <Option
                                                                                            value="按月口服">按月口服</Option>
                                                                                        <Option
                                                                                            value="未按時注射">未按時注射</Option>
                                                                                    </Select>
                                                                                    {meta.touched && meta.error &&
                                                                                    <div className="error">
                                                                                        {meta.error}
                                                                                    </div>}
                                                                                </NForm.Item>
                                                                            )
                                                                        }
                                                                    </Field>
                                                                    <Field parse={_.bs.identity} name='心絲蟲預防時間'>
                                                                        {
                                                                            ({input, meta}) => {
                                                                                const datepickerProps: any = {};
                                                                                if (input.value) {
                                                                                    datepickerProps.defaultValue = moment(input.value);
                                                                                }
                                                                                datepickerProps.onChange = input.onChange;

                                                                                return <NForm.Item {...input}
                                                                                                   name="心絲蟲預防時間"

                                                                                >
                                                                                    <DatePicker
                                                                                        {...datepickerProps}
                                                                                        format={dateFormat}
                                                                                        placeholder="請選擇日期"/>
                                                                                    {meta.touched && meta.error &&
                                                                                    <div className="error">
                                                                                        {meta.error}
                                                                                    </div>}
                                                                                </NForm.Item>
                                                                            }
                                                                        }
                                                                    </Field>

                                                                </Input.Group>
                                                            </NForm.Item>
                                                        </React.Fragment>


                                                        <React.Fragment>
                                                            <NForm.Item  {...layout}
                                                                         label="外寄生蟲預防:"
                                                            >
                                                                <Input.Group compact className='col2'>
                                                                    <Field parse={_.bs.identity} name='外寄生蟲預防'>
                                                                        {
                                                                            ({input, meta}) => (
                                                                                <NForm.Item {...input}
                                                                                            name="外寄生蟲預防"
                                                                                >
                                                                                    <Select {...input}
                                                                                            placeholder="請選擇狀態"
                                                                                    >
                                                                                        <Option
                                                                                            value="定時預防">定時預防</Option>
                                                                                        <Option
                                                                                            value="未按時注射">未按時注射</Option>
                                                                                    </Select>
                                                                                    {meta.touched && meta.error &&
                                                                                    <div className="error">
                                                                                        {meta.error}
                                                                                    </div>}
                                                                                </NForm.Item>
                                                                            )
                                                                        }
                                                                    </Field>
                                                                    <Field parse={_.bs.identity} name='外寄生蟲預防時間'>
                                                                        {
                                                                            ({input, meta}) => {
                                                                                const datepickerProps: any = {};
                                                                                if (input.value) {
                                                                                    datepickerProps.defaultValue = moment(input.value);
                                                                                }
                                                                                datepickerProps.onChange = input.onChange;

                                                                                return <NForm.Item {...input}
                                                                                                   name="外寄生蟲預防時間"

                                                                                >
                                                                                    <DatePicker
                                                                                        {...datepickerProps}
                                                                                        format={dateFormat}
                                                                                        placeholder="請選擇日期"/>
                                                                                    {meta.touched && meta.error &&
                                                                                    <div className="error">
                                                                                        {meta.error}
                                                                                    </div>}
                                                                                </NForm.Item>
                                                                            }
                                                                        }
                                                                    </Field>

                                                                </Input.Group>
                                                            </NForm.Item>
                                                        </React.Fragment>


                                                        <React.Fragment>
                                                            <NForm.Item  {...layout}
                                                                         label="內寄生蟲預防:"
                                                            >
                                                                <Input.Group compact className='col2'>
                                                                    <Field parse={_.bs.identity} name='內寄生蟲預防'>
                                                                        {
                                                                            ({input, meta}) => (
                                                                                <NForm.Item {...input}
                                                                                            name="內寄生蟲預防"
                                                                                >
                                                                                    <Select {...input}
                                                                                            placeholder="請選擇狀態"
                                                                                    >
                                                                                        <Option
                                                                                            value="定時預防">定時預防</Option>
                                                                                        <Option
                                                                                            value="未定時預防">未定時預防</Option>
                                                                                    </Select>
                                                                                    {meta.touched && meta.error &&
                                                                                    <div className="error">
                                                                                        {meta.error}
                                                                                    </div>}
                                                                                </NForm.Item>
                                                                            )
                                                                        }
                                                                    </Field>
                                                                    <Field parse={_.bs.identity} name='內寄生蟲預防時間'>
                                                                        {
                                                                            ({input, meta}) => {
                                                                                const datepickerProps: any = {};
                                                                                if (input.value) {
                                                                                    datepickerProps.defaultValue = moment(input.value);
                                                                                }
                                                                                datepickerProps.onChange = input.onChange;

                                                                                return <NForm.Item {...input}
                                                                                                   name="內寄生蟲預防時間"

                                                                                >
                                                                                    <DatePicker
                                                                                        {...datepickerProps}
                                                                                        format={dateFormat}
                                                                                        placeholder="請選擇日期"/>
                                                                                    {meta.touched && meta.error &&
                                                                                    <div className="error">
                                                                                        {meta.error}
                                                                                    </div>}
                                                                                </NForm.Item>
                                                                            }
                                                                        }
                                                                    </Field>

                                                                </Input.Group>
                                                            </NForm.Item>
                                                        </React.Fragment>

                                                    </form>
                                                </div>
                                            </React.Fragment>;
                                        }}
                                    />
                                </div>
                                }
                            </Panel>
                            <Panel header="藥物過敏" key="2">
                                <div className="panel_ctrl">
                                    <div className="func_bar a_right">
                                        <Button type="link" icon={<EditOutlined/>}
                                                onClick={() => setNumber2(number2 + 1)}>編輯</Button>
                                    </div>
                                    <div className="systable col_2 mw_th">
                                        <div className="str">
                                            <div className="th">藥物類過敏</div>
                                            <div className="td">{Item_病歷.藥物類過敏說明組 ?? '-'}</div>
                                        </div>
                                        <div className="str">
                                            <div className="th">食物類過敏</div>
                                            <div className="td">{Item_病歷.食物類過敏說明組 ?? '-'}</div>
                                        </div>
                                        <div className="str">
                                            <div className="th">皮膚接觸過敏</div>
                                            <div className="td">{Item_病歷.皮膚接觸過敏說明組 ?? '-'}</div>
                                        </div>
                                    </div>
                                </div>

                                {number2 % 2 == 0 &&
                                <div className="panel_ctrl">
                                    <Form
                                        onSubmit={(v) => handleSubmit_save(null, v, () => {
                                            setNumber2(1)
                                        })}
                                        initialValues={{
                                            ...Item_病歷,
                                            病歷No: viewModel.病歷No,
                                        }}
                                        validate={handleValidate}
                                        render={({
                                                     submitError,
                                                     handleSubmit,
                                                     submitting,
                                                     pristine,
                                                     valid,
                                                     error,
                                                     values
                                                 }) => {

                                            return <React.Fragment>
                                                <div className="form_body">
                                                    <form
                                                        onSubmit={handleSubmit}
                                                        {...layout}>
                                                        <React.Fragment>
                                                            <div className="func_bar a_right">
                                                                <Button type="link" htmlType="submit"
                                                                        onClick={handleSubmit}>儲存</Button>
                                                            </div>
                                                            <NForm.Item  {...layout}
                                                                         label="藥物類過敏:"
                                                            >
                                                                <Input.Group compact className='col2'>
                                                                    <Field parse={_.bs.identity} name='藥物類過敏說明組'>
                                                                        {
                                                                            ({input, meta}) => (
                                                                                <React.Fragment>

                                                                                    <NForm.Item
                                                                                    >
                                                                                        <TextArea {...input} rows={6}
                                                                                                  placeholder="藥物類過敏說明"/>
                                                                                    </NForm.Item>
                                                                                </React.Fragment>
                                                                            )
                                                                        }
                                                                    </Field>
                                                                </Input.Group>
                                                            </NForm.Item>

                                                            <NForm.Item  {...layout}
                                                                         label="食物類過敏:"
                                                            >
                                                                <Input.Group compact className='col2'>
                                                                    <Field parse={_.bs.identity} name='食物類過敏說明組'>
                                                                        {
                                                                            ({input, meta}) => (
                                                                                <React.Fragment>

                                                                                    <NForm.Item
                                                                                    >
                                                                                        <TextArea {...input} rows={6}
                                                                                                  placeholder="食物類過敏說明"/>
                                                                                    </NForm.Item>
                                                                                </React.Fragment>
                                                                            )
                                                                        }
                                                                    </Field>
                                                                </Input.Group>
                                                            </NForm.Item>

                                                            <NForm.Item  {...layout}
                                                                         label="皮膚接觸過敏:"
                                                            >
                                                                <Input.Group compact className='col2'>
                                                                    <Field parse={_.bs.identity} name='皮膚接觸過敏說明組'>
                                                                        {
                                                                            ({input, meta}) => (
                                                                                <React.Fragment>

                                                                                    <NForm.Item
                                                                                    >
                                                                                        <TextArea {...input} rows={6}
                                                                                                  placeholder="皮膚接觸過敏說明"/>
                                                                                    </NForm.Item>
                                                                                </React.Fragment>
                                                                            )
                                                                        }
                                                                    </Field>
                                                                </Input.Group>
                                                            </NForm.Item>
                                                        </React.Fragment>

                                                        {/*<React.Fragment>*/}
                                                        {/*    <Field parse={_.bs.identity} name='過敏說明組'>*/}
                                                        {/*        {*/}
                                                        {/*            ({input, meta}) => {*/}

                                                        {/*                let fields: string[] = input.value.split(',') ?? [];*/}
                                                        {/*                let blankFields: any[] = [];*/}


                                                        {/*                const removeHandler = (e, type, val) => {*/}
                                                        {/*                    if (e) {*/}
                                                        {/*                        e.preventDefault();*/}
                                                        {/*                        e.stopPropagation();*/}
                                                        {/*                    }*/}

                                                        {/*                    fields = fields.filter(r => r != val);*/}
                                                        {/*                    input.onChange(fields.join(','));*/}
                                                        {/*                }*/}

                                                        {/*                const saveHandler = (e) => {*/}
                                                        {/*                    if (e) {*/}
                                                        {/*                        e.preventDefault();*/}
                                                        {/*                        e.stopPropagation();*/}
                                                        {/*                    }*/}


                                                        {/*                    //reset all*/}
                                                        {/*                    blankFields = [];*/}
                                                        {/*                }*/}


                                                        {/*                const addBlankHandler = (e) => {*/}
                                                        {/*                    debugger;*/}

                                                        {/*                    if (e) {*/}
                                                        {/*                        e.preventDefault();*/}
                                                        {/*                        e.stopPropagation();*/}
                                                        {/*                    }*/}

                                                        {/*                    blankFields.push({});*/}
                                                        {/*                    forceRender();*/}
                                                        {/*                }*/}


                                                        {/*                return <React.Fragment>*/}
                                                        {/*                    <div className="func_bar a_right">*/}
                                                        {/*                        <Button type="link"*/}
                                                        {/*                                icon={<PlusCircleOutlined/>}*/}
                                                        {/*                                onClick={(e) => addBlankHandler(e)}>新增過敏源</Button>*/}
                                                        {/*                        <Button type="link" htmlType="submit"*/}
                                                        {/*                                onClick={(e) => saveHandler(e)}>儲存</Button>*/}
                                                        {/*                    </div>*/}

                                                        {/*                    {blankFields.map(r => (*/}
                                                        {/*                        <NForm.Item  {...layout02}*/}
                                                        {/*                        >*/}
                                                        {/*                            <Input.Group compact*/}
                                                        {/*                                         className='col3 r2'>*/}
                                                        {/*                                <NForm.Item*/}
                                                        {/*                                            name="allergy"*/}
                                                        {/*                                >*/}
                                                        {/*                                    <Select*/}
                                                        {/*                                            placeholder="請選擇過敏類別">*/}
                                                        {/*                                        <Option*/}
                                                        {/*                                            value="藥物類過敏">藥物類過敏</Option>*/}
                                                        {/*                                        <Option*/}
                                                        {/*                                            value="食物類過敏">食物類過敏</Option>*/}
                                                        {/*                                        <Option*/}
                                                        {/*                                            value="皮膚接觸過敏">皮膚接觸過敏</Option>*/}
                                                        {/*                                    </Select>*/}
                                                        {/*                                </NForm.Item>*/}
                                                        {/*                                <NForm.Item*/}
                                                        {/*                                            name="diseasedescription"*/}
                                                        {/*                                >*/}
                                                        {/*                                    <Input placeholder="請輸入描述"/>*/}
                                                        {/*                                </NForm.Item>*/}
                                                        {/*                                <NForm.Item*/}
                                                        {/*                                            name="contrller"*/}
                                                        {/*                                >*/}
                                                        {/*                                    <Button*/}
                                                        {/*                                        onClick={(e) => removeHandler(e, 'a', '')}*/}
                                                        {/*                                        type="link" danger*/}
                                                        {/*                                        icon={<DeleteFilled/>}/>*/}
                                                        {/*                                </NForm.Item>*/}
                                                        {/*                            </Input.Group>*/}
                                                        {/*                        </NForm.Item>*/}
                                                        {/*                    ))}*/}
                                                        {/*                </React.Fragment>;*/}
                                                        {/*            }*/}
                                                        {/*        }*/}
                                                        {/*    </Field>*/}
                                                        {/*</React.Fragment>*/}

                                                    </form>
                                                </div>
                                            </React.Fragment>;
                                        }}
                                    />
                                </div>
                                }
                            </Panel>
                            <Panel header="重大疾病" key="3">

                                <div className="panel_ctrl">
                                    <div className="func_bar a_right">
                                        <Button type="link" icon={<EditOutlined/>}
                                                onClick={() => setNumber3(number3 + 1)}>編輯</Button>
                                    </div>
                                    <div className="partext">
                                        {Item_病歷.重大疾病說明 ?? '-'}
                                    </div>

                                    {number3 % 2 == 0 &&
                                    <div className="panel_ctrl">
                                        <Form
                                            onSubmit={(v) => handleSubmit_save(null, v, () => {
                                                setNumber3(1)
                                            })}
                                            initialValues={{
                                                ...Item_病歷,
                                                病歷No: viewModel.病歷No,
                                            }}
                                            validate={handleValidate}
                                            render={({
                                                         submitError,
                                                         handleSubmit,
                                                         submitting,
                                                         pristine,
                                                         valid,
                                                         error,
                                                         values
                                                     }) => {

                                                return <React.Fragment>
                                                    <div className="form_body">
                                                        <form
                                                            onSubmit={handleSubmit}
                                                            {...layout}>
                                                            <div className="func_bar a_right">
                                                                <Button type="link" htmlType="submit"
                                                                        onClick={handleSubmit}>儲存</Button>
                                                            </div>
                                                            <Field parse={_.bs.identity} name='重大疾病說明'>
                                                                {
                                                                    ({input, meta}) => (
                                                                        <React.Fragment>

                                                                            <NForm.Item
                                                                            >
                                                                                <TextArea {...input} rows={6}
                                                                                          placeholder="請輸入內容"/>
                                                                            </NForm.Item>
                                                                        </React.Fragment>
                                                                    )
                                                                }
                                                            </Field>
                                                        </form>
                                                    </div>
                                                </React.Fragment>;
                                            }}
                                        />
                                    </div>
                                    }
                                </div>
                            </Panel>
                        </Collapse>
                    </React.Fragment>
                })}
            </React.Fragment>;
        } else if (List_病歷 && List_病歷.length === 0) {
            if (RegionEmptyShow) {
                return <React.Fragment>
                    <div>Empty.</div>
                </React.Fragment>;
            }
        }

        return null;
    };

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_MedicalRecord__Pane_PastMedicalHistory.displayName = 'Partial_MedicalRecord__Pane_PastMedicalHistory';
