import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {Form as NForm, Popover, Button, Modal, message, Upload, Input, Space, Select} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";

import {LoadingOutlined, LockOutlined, PlusOutlined, RedoOutlined, UserOutlined} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_Appointment__Modal_AddPet from "./Partial_Appointment__Modal_AddPet";

var moment = require('moment');
const {Option} = Select;

type ViewModelProps = {
    refModal: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,

    viewModel: ViewModelProps,
};
export default function Partial_addOwnerProxy(props: Props) {
    const PrefixTarget: string = props.PrefixTarget || 'Partial_addOwnerProxy';
    const pageDataManager: PageDataManager = props.pageDataManager;
    const sessionManager: SessionManager = props.sessionManager;
    const navigate: NavigateFunction = props.navigate;

    const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);

    const layout = {
        labelCol: {span: 4},
        wrapperCol: {span: 20},
    };

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region Boot
    const _doLoadPartialData = async (): Promise<void> => {
        if (!pageDataManager.refPageData.current.Root) {
            return;
        }

        /// do not dynamically load data if condition fits.
        if (pageDataManager.isZoneExist(PrefixTarget)) {
            return;
        }
        //await _loadPageData();
    }
    useEffect(() => {
        _doLoadPartialData();
    }, []);
    //#endregion


    const Body = () => {
        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            // if (!values.ownername) {
            //     errors.ownername = '↑ 欄位為必填';
            // } else {
            // }
            //
            // if (!values.cellphone) {
            //     errors.cellphone = '↑ 欄位為必填';
            // } else {
            // }

            return errors;
        };

        const handleClose = (e) => {
            if (e) {
                e.preventDefault();
            }

            if (viewModel.refModal || viewModel.refModal.current) {
                viewModel.refModal.current.destroy();
            }
        };

        const handleSubmit_clickLogin = (e: any, v: any) => {
            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">現在開始創立世界</div>,
                key: 'loading',
                duration: 0
            });

            //#region Handle SaveUserInfo
            {
                // VALIDATE INPUT
                //const id : any = this.props.match.params.id;
                const id = 1;
                if (!id) {
                    return; //ERROR INPUTS
                }

                // WAITING SCREEN
                loadingManager.changeLoadingCount(+1);
                //StaticEvent.LoadingEvent.next(true);

                // POST
                let postData = {
                    p1: v.p1,
                    p2: v.p2,
                    p3: v.p3,
                    p4: v.p4,
                    p5: v.p5,
                    timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
                };
                const postUrl = ApiService.urlMapping('~/h_api/1/pg_jumplogin/clicklogin');
                const post$ = Static.apiService.post(postUrl, postData);
                post$.subscribe(async (res: any) => {
                        hide();


                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);

                        let data = res.data;
                        let error = data.error || null;
                        let result = error == null;

                        if (result) { // SUCEESS
                            let payload = data.payload;
                            _._debug.warn(payload);

                            message.success({
                                type: 'success',
                                className: 'myMessage',
                                content: <div className="myMessageBody">歡迎來到，恐龍世界</div>,
                                key: '',
                                duration: 3
                            });

                            // message.success({
                            //     type: 'success',
                            //     className: 'myMessage',
                            //     content: <div className="myMessageBody">門就開了</div>,
                            //     key: '',
                            //     duration: 3
                            // });


                            await sessionManager.setLoginData(
                                payload.SessionId,
                                payload.AuthData
                            );

                            navigate({
                                pathname: '/wall/newest',
                                //search: "?sort=name",
                                //hash: "#the-hash",
                                //state: {panelSelection: "f1"}
                            });
                        } else {
                            // AUTH USER EXPIRED
                            if (error.code == 2) {
                                //alert('帳號被登出，請重新登入');
                                //window.location.href = '/login';
                                //this.props.history.push('/login')
                                await sessionManager.setLogout();
                                pageDataManager.clear();
                                pageDataManager.reloadAsync({alwaysLoad: true});
                                alert('時間逾時已被登出');
                                return;
                            }

                            _._debug.error(error);
                            alert(error.message);
                        }
                    }, async (err) => {
                        hide();

                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);
                        _._debug.warn(err);
                        alert('網路連線有問題');
                    }
                );
                return lastValueFrom(
                    post$
                );
            }
            //#endregion
        }

        /*
                                                        <input type="text" {...input} placeholder="username"></input>
                                                {meta.touched && meta.error &&
                                                <div className="error">
                                                    {meta.error}
                                                </div>}

                                                <input type="text" {...input} placeholder="password"></input>
                                                {meta.touched && meta.error &&
                                                <div className="error">
                                                    {meta.error}
                                                </div>}

                                                <input type="submit" {...input} placeholder="asfasdfsad"></input>
                                                {meta.touched && meta.error &&
                                                <div className="error">
                                                    {meta.error}
                                                </div>}

         */
        return (
            <Form
                onSubmit={(v) => handleSubmit_clickLogin(null, v)}
                initialValues={{
                    隱私: '公開',
                    內容: '',
                }}
                validate={handleValidate}
                render={({
                             submitError,
                             handleSubmit,
                             submitting,
                             pristine,
                             valid,
                             error,
                             values
                         }) => {

                    return <React.Fragment>
                        <div className="form_body">
                            <form
                                onSubmit={handleSubmit}
                                {...layout}>

                                <Field parse={_.bs.identity} name="ownername">
                                    {
                                        ({input, meta}) => (
                                            <React.Fragment>
                                                <NForm.Item {...input} {...layout}
                                                            name="ownername"
                                                            label="飼主姓名"
                                                            rules={[{required: true, message: '請輸入姓名'}]}
                                                >

                                                    <Input {...input} placeholder="請輸入中英文" allowClear/>
                                                    {meta.touched && meta.error &&
                                                    <div className="error">
                                                        {meta.error}
                                                    </div>}
                                                </NForm.Item>
                                            </React.Fragment>
                                        )
                                    }
                                </Field>

                                <Field parse={_.bs.identity} name="cellphone">
                                    {
                                        ({input, meta}) => (
                                            <React.Fragment>
                                                <NForm.Item {...input} {...layout}
                                                            name="cellphone"
                                                            label="連絡手機"
                                                            rules={[{required: true, message: '請輸入正確格式'}]}
                                                >
                                                    <Input {...input}
                                                           placeholder="請輸入手機號碼" allowClear/>
                                                    {meta.touched && meta.error &&
                                                    <div className="error">
                                                        {meta.error}
                                                    </div>}
                                                </NForm.Item>
                                            </React.Fragment>
                                        )
                                    }
                                </Field>

                                <Field parse={_.bs.identity} name="ownerid">
                                    {
                                        ({input, meta}) => (
                                            <React.Fragment>
                                                <NForm.Item {...input} {...layout}
                                                            name="ownerid"
                                                            label="身分證號碼"

                                                >
                                                    <Input {...input}
                                                           placeholder="請輸入英文+9位數" allowClear/>
                                                    {meta.touched && meta.error &&
                                                    <div className="error">
                                                        {meta.error}
                                                    </div>}
                                                </NForm.Item>
                                            </React.Fragment>
                                        )
                                    }
                                </Field>

                                <Field parse={_.bs.identity} name="ownertel">
                                    {
                                        ({input, meta}) => (
                                            <React.Fragment>
                                                <NForm.Item {...input} {...layout}
                                                            name="ownertel"
                                                            label="市話"

                                                >
                                                    <Input {...input}
                                                           placeholder="請輸入電話號碼" allowClear/>
                                                    {meta.touched && meta.error &&
                                                    <div className="error">
                                                        {meta.error}
                                                    </div>}
                                                </NForm.Item>
                                            </React.Fragment>
                                        )
                                    }
                                </Field>

                                <Field parse={_.bs.identity} name="relationship">
                                    {
                                        ({input, meta}) => (
                                            <React.Fragment>
                                                <NForm.Item {...input} {...layout}
                                                            name="relationship"
                                                            label="關係"
                                                >
                                                    <Select {...input} placeholder="請選擇關係" defaultValue="x">
                                                        <Option value="v">主飼主</Option>
                                                        <Option value="x">代理飼主</Option>
                                                    </Select>

                                                    {meta.touched && meta.error &&
                                                    <div className="error">
                                                        {meta.error}
                                                    </div>}
                                                </NForm.Item>
                                            </React.Fragment>
                                        )
                                    }
                                </Field>


                                <React.Fragment>
                                    <NForm.Item>
                                        <div className="form_footer">

                                            <Button type="primary" htmlType="submit" size="large">新建</Button>

                                        </div>
                                    </NForm.Item>
                                </React.Fragment>
                            </form>
                        </div>
                    </React.Fragment>;
                }}
            />
        );
    };

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_addOwnerProxy.displayName = 'Partial_addOwnerProxy';
