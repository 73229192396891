import React, {useCallback, useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {
    Form as NForm,
    Popover,
    Button,
    Modal,
    message,
    Upload,
    Input,
    Select,
    Space,
    Tabs,
    Tooltip,
    Steps,
    Transfer,
    Table,
    Tag
} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';
import difference from 'lodash/difference';


import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";

import {LoadingOutlined, LockOutlined, PlusOutlined, RedoOutlined, CloseOutlined} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_addOwnerProxy from "./Partial_addOwnerProxy";
import Partial_MedicalRecord__Pane_EditOrAddClient from "./Partial_MedicalRecord__Pane_EditOrAddClient";
import UseCompState from "../hooks/UseCompState";
import Partial_Manage_Staff__Pane_EditOrAddStaff from "./Partial_Manage_Staff__Pane_EditOrAddStaff";
import Partial_MedicalRecord__Pane_EditOrAddPet from "./Partial_MedicalRecord__Pane_EditOrAddPet";


var moment = require('moment');
const {Option} = Select;
const {Search} = Input;
const onSearch = value => console.log(value);
const {TabPane} = Tabs;
const {Step} = Steps;

type ViewModelProps = {
    refModal: any,

    寵物No?: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any,

    viewModel: ViewModelProps,
};
export default function Partial_MedicalRecord__Modal_EditOrAddPet(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_MedicalRecord__Modal_EditOrAddPet';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel: any = props.viewModel;
    const isNew = !viewModel.寵物No;

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

    }
    //#endregion

    // Customize Table Transfer
    const TableTransfer = ({leftColumns, rightColumns, ...restProps}) => (
        <Transfer {...restProps} showSelectAll={false}>
            {({
                  direction,
                  filteredItems,
                  onItemSelectAll,
                  onItemSelect,
                  selectedKeys: listSelectedKeys,
                  disabled: listDisabled,
              }) => {
                const columns = direction === 'left' ? leftColumns : rightColumns;

                const rowSelection = {
                    getCheckboxProps: item => ({disabled: listDisabled || item.disabled}),
                    onSelectAll(selected, selectedRows) {
                        const treeSelectedKeys = selectedRows
                            .filter(item => !item.disabled)
                            .map(({key}) => key);
                        const diffKeys = selected
                            ? difference(treeSelectedKeys, listSelectedKeys)
                            : difference(listSelectedKeys, treeSelectedKeys);
                        onItemSelectAll(diffKeys, selected);
                    },
                    onSelect({key}, selected) {
                        onItemSelect(key, selected);
                    },
                    selectedRowKeys: listSelectedKeys,
                };

                return (
                    <Table
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={filteredItems}
                        size="small"
                        onRow={({key, disabled: itemDisabled}) => ({
                            onClick: () => {
                                if (itemDisabled || listDisabled) return;
                                onItemSelect(key, !listSelectedKeys.includes(key));
                            },
                        })}
                    />
                );
            }}
        </Transfer>
    );

    const mockTags = ['分類名稱1', '分類名稱2', '分類名稱3'];

    const mockData: any[] = [];
    for (let i = 0; i < 20; i++) {

        mockData.push({
            key: i.toString(),
            ENtitle: `Disease Disease Disease Disease${i + 1}`,
            CNtitle: `病症病症病症病症病症${i + 1}`,
            Abbreviation: `簡稱${i + 1}`,
            tag: mockTags[i % 3],
        });
    }

    const originTargetKeys = mockData.filter(item => +item.key % 3 > 1).map(item => item.key);

    const leftTableColumns = [
        {
            dataIndex: 'ENtitle',
            title: 'Disease',
            width: '30%'
        },
        {
            dataIndex: 'CNtitle',
            title: '症狀',
            width: '30%'
        },
        {
            dataIndex: 'tag',
            title: '症狀分類',
            render: tag => <Tag>{tag}</Tag>,
        },
        {
            dataIndex: 'Abbreviation',
            title: '簡稱',
            width: '15%'
        },
    ];
    const rightTableColumns = [
        {
            dataIndex: 'ENtitle',
            title: 'Disease',
        },
        {
            dataIndex: 'CNtitle',
            title: '症狀',
        },
    ];


    class App extends React.Component {
        state = {
            targetKeys: originTargetKeys,
            disabled: false,
            showSearch: false,
        };

        onChange = nextTargetKeys => {
            this.setState({targetKeys: nextTargetKeys});
        };

        handleSearch = (dir, value) => {
            console.log('search:', dir, value);
        };

        render() {
            const {targetKeys, disabled, showSearch} = this.state;
            return (
                <>
                    <TableTransfer
                        dataSource={mockData}
                        targetKeys={targetKeys}
                        disabled={disabled}
                        showSearch
                        onSearch={this.handleSearch}
                        onChange={this.onChange}
                        filterOption={(inputValue, item) =>
                            item.title.indexOf(inputValue) !== -1 || item.tag.indexOf(inputValue) !== -1
                        }
                        leftColumns={leftTableColumns}
                        rightColumns={rightTableColumns}
                    />
                </>
            );
        }
    }

    //#region Set Body
    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };
    const isModalContained = () => {
        return !!viewModel.refModal?.current;
    }
    const Body = () => {

        return (
            <div className="model_wrapper">
                <div className="modal_title">
                    <div className="title_info">
                        <h2>編輯寵物資訊</h2>
                    </div>
                    <div className="info_bar"></div>
                    <div className="control_bar">
                        <Tooltip placement="top" title={'關閉'}>
                            <Button type="text" icon={<CloseOutlined/>} onClick={handleClose}/>
                        </Tooltip>
                    </div>
                </div>
                <div className="modal_content">
                    <div className="content_box cd_modal">
                        {isNew ?
                            <Partial_MedicalRecord__Pane_EditOrAddPet
                                key="new"
                                //PrefixTarget={'Partial_Manage_Staff__Pane_EditOrAddStaff'}
                                pageDataManager={pageDataManager}
                                sessionManager={sessionManager}
                                navigate={navigate}

                                viewModel={{
                                    refModal: viewModel.refModal,

                                    寵物No: null,

                                    OnUpdated: () => {
                                        state.parentState?._refDoLoadPartialData?.current?.({alwaysLoad: true});
                                    }
                                }}
                            /> :
                            <Partial_MedicalRecord__Pane_EditOrAddPet
                                //PrefixTarget={'Partial_Manage_Staff__Pane_EditOrAddStaff'}
                                pageDataManager={pageDataManager}
                                sessionManager={sessionManager}
                                navigate={navigate}

                                viewModel={{
                                    refModal: viewModel.refModal,

                                    寵物No: viewModel.寵物No,

                                    OnUpdated: () => {
                                        state.parentState?._refDoLoadPartialData?.current?.({alwaysLoad: true});
                                    }
                                }}
                            />
                        }
                    </div>
                </div>
                <div className="modal_footer">

                </div>
            </div>
        );
    };
    //#endregion

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_MedicalRecord__Modal_EditOrAddPet.displayName = 'Partial_MedicalRecord__Modal_EditOrAddPet';

