import React, {useEffect, useLayoutEffect, useRef} from 'react';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import {
    Form, Input, Button, Alert, Checkbox, Result, message, Space,
    Modal, Typography, Tabs, Select, Popconfirm, Table, Empty, Tooltip
} from 'antd';
import {useApp} from "../overmind";
import {UserInfo} from "../global";
import {Static} from "../_/Static";
import {ApiService} from "../_/ApiService";
import _ from "../_";
import {
    EyeInvisibleOutlined,
    EyeTwoTone,
    UserOutlined,
    LockOutlined,
    ReloadOutlined,
    CalendarFilled
} from '@ant-design/icons';
import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import UsePageDataManager from '../hooks/UsePageDataManager';
import UseSessionManager from "../hooks/UseSessionManager";
import UseLoadingManager from "../hooks/UseLoadingManager";
import UseCompState from "../hooks/UseCompState";
import {lastValueFrom} from "rxjs";
import Partial_Appointment__Modal_AddOwner from "./Partial_Appointment__Modal_AddOwner";
import Partial_Appointment__Modal_AddPet from "./Partial_Appointment__Modal_AddPet";
import Partial_Modal_Search from "./Partial_Appointment__Modal_Search";
import Partial_Appointment__Modal_EditOrAddRegister from "./Partial_Appointment__Modal_EditOrAddRegister";
import Partial_Appointment__Modal_AddReservation from "./Partial_Appointment__Modal_AddReservation";

import './Pg_Appointment.scss';
import FunctionBtn from './Box_Functionbtn';
import {StaticEvent} from "../_/StaticEvent";

const urlParse = require('url-parse');
const queryString = require('query-string');

const iconLighting = require('../assets/images/icon/lighting.svg');
const iconWatting = require('../assets/images/icon/new-medical-record.svg');

const onSearch = value => console.log(value);
const {Title} = Typography;
const {TabPane} = Tabs;
const {Search} = Input;
const {Option} = Select;


type Props = {
    PrefixTarget?: string;
    RoutePathname?: string;
    MenuTemplateId?: string;

    parentState?: any;
};
export default function Pg_Appointment(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Root';
    let RoutePathname = props.RoutePathname;
    let MenuTemplateId = props.MenuTemplateId;

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    //#region LoadingManager
    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});
    //#endregion

//#region PageDataManager
    const refDoLoadPageData = Static.refDoLoadPageData;
    let pageDataManager = UsePageDataManager({
        refPageLoad: refDoLoadPageData
    });
    //#endregion

    //#region SessionManager
    let sessionManager = UseSessionManager({
        pageDataManager: pageDataManager,
    });
    //#endregion

    //#region :State CompState
    let defaultS = '全部';
    let defaultQ = '';

    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

        state.pageDataManager = pageDataManager;
        state.sessionManager = sessionManager;
        state.loadingManager = loadingManager;
        state.navigate = navigate;
        state.forceRender = forceRender;

        state.subscribed = [];

        state.s = pageDataManager.get(`${PrefixTarget}.s`) ?? defaultS;
        state.q = pageDataManager.get(`${PrefixTarget}.q`) ?? defaultQ;
    }
    //#endregion

    //#region :Popup :createModalLogin
    const createModalOwner_refModal = useRef<any>(null);
    const createModalOwner = (e: any, tasks?: string[]): void => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_Appointment__Modal_AddOwner
                PrefixTarget={'Partial_Appointment__Modal_AddOwner'}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModalOwner_refModal,
                }}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '60em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModalOwner_refModal.current = Modal.info(config);
    }
    //#endregion

    //#region :Popup :createModalRegister
    const createModalRegister_refModal = useRef<any>(null);
    const createModalRegister = (e: any, {飼主No, 寵物No, tasks, modalAddonProp}: {
        modalAddonProp?: object,
        飼主No?: any,
        寵物No?: any,
        tasks?: string[]
    }): void => {
        if (e) {
            e.preventDefault();
        }

        const config = {
            // title: 'test',
            content: <Partial_Appointment__Modal_EditOrAddRegister
                PrefixTarget={`Partial_Appointment__Modal_EditOrAddRegister${飼主No ?? 0}-${寵物No ?? 0}`}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModalRegister_refModal,

                    Kind: 'Create',
                    Kind_Create: {
                        寵物No: 寵物No,
                        飼主No: 飼主No,
                    }
                }}

                {...modalAddonProp}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '60em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModalRegister_refModal.current = Modal.info(config);
    }
    //#endregion

    //#region :Popup :createModalReservationn
    const createModalReservation_refModal = useRef<any>(null);
    const createModalReservation = (e: any, {寵物_飼主__關係No, tasks}: {
        寵物_飼主__關係No: any,
        tasks?: string[]
    }) => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_Appointment__Modal_AddReservation
                PrefixTarget={`Partial_Appointment__Modal_AddReservation${寵物_飼主__關係No}`}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModalReservation_refModal,

                    寵物_飼主__關係No,
                }}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '35em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModalReservation_refModal.current = Modal.info(config);
    }


    function callback(key) {
        console.log(key);
    }

    function confirm(e) {
        console.log(e);
    }

    function cancel(e) {
        console.log(e)
    }

    //#endregion

    //#region Boot
    useEffect(() => {
        const _loadPageData = async (): Promise<void> => {
            let hide = message.loading({
                type: 'loading',
                class: 'myMessage',
                content: <div className="myMessageBody">搜尋中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            //const id : any = this.props.match.params.id;
            // const id = 1;
            // if (!id) {
            //     return; //ERROR INPUTS
            // }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                //p1: 1,
                s: state.s,
                q: state.q,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/pg_appointment');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        pageDataManager.merge({
                            ...payload,
                            q: state.q,
                            s: state.s,
                        });
                        pageDataManager.apply();

                        pageDataManager.forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPageData = async ({alwaysLoad = false}): Promise<void> => {
            if (!alwaysLoad) {
                pageDataManager.restore();
                if (!pageDataManager.isZoneExist(PrefixTarget)) {
                    await sessionManager.initSessionAuthAsync();
                    //#region User Check //TODO: VERTIFY
                    if (!sessionManager.isMemberLoggedIn()) {
                        Static.NavAwayToLogin(pageDataManager, navigate)(null);
                        return;
                    }
                    //#endregion
                    await _loadPageData();
                } else {
                    forceRender()
                }
            } else {
                await _loadPageData();
            }
        }

            refDoLoadPageData.current = doLoadPageData;
            refDoLoadPageData.current({});
    }, [location.pathname]);
    //#endregion

    const columns = [
        {
            title: '寵物名稱',
            dataIndex: '寵物名稱',
            key: '',
            width: 100,
            render: (name: string, record) => {
                const Link = Static.NavAwayToRecord(navigate, {No: record.寵物No}).Link;
                return <Link>{name}</Link>
            },
        },
        {
            title: '主人姓名',
            dataIndex: '主人姓名',
            key: '2',
            width: 100,
            render: (name: string, record) => {
                const Link = Static.NavAwayToClientManage(navigate, {No: record.飼主No}).Link;
                return <Link>{name}</Link>
            },
        },
        {
            title: '身分證',
            dataIndex: '身分證',
            key: '3',
            width: 120,
        },
        {
            title: '手機',
            dataIndex: '手機',
            key: '4',
            width: 120,
        },
        {
            title: '聯絡電話',
            dataIndex: '聯絡電話',
            key: '5',
            width: 130,
        },
        {
            title: '喜愛醫師',
            dataIndex: '喜愛醫師',
            key: '6',
            width: 100,
        },
        {
            title: '醫賴碼',
            dataIndex: '醫賴碼',
            key: '7',
            width: 100,
        },
        {
            title: '預約',
            dataIndex: '預約',
            key: '8',
            width: 100,
            render: (text, record) => {
                return <div>
                    <Button className="quick-order" icon={<CalendarFilled/>}
                            onClick={(e) => createModalReservation(e, {寵物_飼主__關係No: record.寵物_飼主__關係No})}>預約</Button>
                    {/* <Button class={disable[record.count] !== true ? "quick-order": ""} disabled={disable[record.count]}　onClick={() => makeOrder(record, record.key, record.ownerno, record.count)} ><img src={iconLighting}/>快速候診</Button> */}
                </div>
            },
        },
        {
            title: '候診',
            dataIndex: '候診',
            key: '9',
            width: 100,
            render: (text, record) => {

                return <div>
                    <Button className="quick-order" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        createModalRegister(e, {
                            飼主No: record.飼主No,
                            寵物No: record.寵物No,
                        });
                    }}><img src={iconLighting}/>候診</Button>
                    {/* <Button class={disable[record.count] !== true ? "quick-order": ""} disabled={disable[record.count]}　onClick={() => makeOrder(record, record.key, record.ownerno, record.count)} ><img src={iconLighting}/>快速候診</Button> */}
                </div>;
            }
        }
    ];


    const List_寵物 = pageDataManager.get(`${PrefixTarget}.List_寵物`);

    let DataSource_寵物 = (List_寵物 ?? []).map((r) => {

        return {
            寵物_飼主__關係No: r._寵物_飼主__關係.寵物_飼主__關係No,

            寵物名稱: r.寵物名稱,
            主人姓名: r._飼主.主人姓名,
            身分證: r._飼主.身分證,
            手機: r._飼主.手機,
            聯絡電話: r._飼主.聯絡電話,
            喜愛醫師: r._飼主.喜愛醫師,
            醫賴碼: r._飼主.醫賴碼,

            飼主No: r._飼主.飼主No,
            寵物No: r.寵物No,
        }
    })


    return (
        <div className="page-appointment page-content">
            <div className="info-list">
                <div className="info-box">
                    <Title level={2}>掛號與搜尋</Title>
                    <div className="pluginfobox">
                        <div className="title">
                            <Tooltip title="刷新">
                                <Button icon={<ReloadOutlined/>} shape="circle"/>
                            </Tooltip>
                        </div>
                        <div className="info"></div>
                    </div>

                    <div className="pluginfobox">
                        <div className="title"></div>
                        <div className="info"></div>
                    </div>

                </div>
                <div className="function-box">
                    <button onClick={createModalOwner}>
                        <FunctionBtn iconlink={iconWatting} name="新建病歷"/>
                    </button>
                    <button onClick={(e) => {
                        navigate({
                            pathname: `/appointment`,
                            search: `?1=&s=手機號碼&q=ssss`,
                            //hash: "#the-hash",
                            //state: {panelSelection: "f1"}
                        });
                    }}>
                        {/*<FunctionBtn iconlink={iconWatting} name="test"/>*/}
                    </button>
                </div>
            </div>


            <div className="appointment-box">
                <div className="appointwrapper">
                    <div className="appointment-filter-box">
                        <Partial_Modal_Search
                            //PrefixTarget={'Partial_Modal_Search'}
                            pageDataManager={pageDataManager}
                            sessionManager={sessionManager}
                            navigate={navigate}
                            parentState={state}

                            viewModel={{
                                refModal: null,
                            }}
                        />
                    </div>
                    <div className="appointment-results">
                        <div className="result-info">
                            <p className="result-title">搜尋結果({DataSource_寵物.length})</p>
                        </div>
                        <Table columns={columns} dataSource={DataSource_寵物} pagination={{pageSize: 6}} locale={{
                            emptyText: <Empty description={
                                <span>沒有搜尋到相關資料～</span>
                            }/>
                        }}/>
                    </div>
                </div>
            </div>
        </div>

    )
}

Pg_Appointment.displayName = 'Pg_Appointment';

