import React, { useCallback, useEffect, useRef, useState } from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import { Form as NForm, Popover, Button, Modal, message, Upload, Input, Select, Space, Table, Radio, Empty, InputNumber } from 'antd';

import { ApiService } from "../_/ApiService";
import { Static } from "../_/Static";
import { StaticEvent } from "../_/StaticEvent";
import _ from "../_";

import { Link } from 'react-router-dom';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import { PageDataManager } from "../hooks/UsePageDataManager";

import { Field, Form } from "react-final-form";
import { SessionManager } from "../hooks/UseSessionManager";
import { NavigateFunction } from "react-router";

import { LoadingOutlined, LockOutlined, PlusOutlined, RedoOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { lastValueFrom } from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";
import UseCompState from "../hooks/UseCompState";

var moment = require('moment');

type ViewModelProps = {
    refModal: any,

    病歷No: any,
    掛號No: any,

    OnDid?: any;
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any,

    viewModel: ViewModelProps,
};
export default function Partial_MedicalRecord__Modal_NoteAdd(props: Props) {
    const PrefixTarget: string = props.PrefixTarget || 'Partial_MedicalRecord__Modal_NoteAdd';
    const pageDataManager: PageDataManager = props.pageDataManager;
    const sessionManager: SessionManager = props.sessionManager;
    const navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel = props.viewModel;

    const { Option } = Select;
    const { Search } = Input;
    const onSearch = value => console.log(value);
    const { TextArea } = Input;

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    //#region LoadingManager
    const loadingManager = UseLoadingManager({ shouldReRenderOnLoading: false });
    //#endregion


    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

    }
    //#endregion

    //#region Set Body
    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };
    const Body = () => {
        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            // if (!values.username) {
            //     errors.username = '↑ 欄位為必填';
            // } else {
            // }
            //
            // if (!values.password) {
            //     errors.password = '↑ 欄位為必填';
            // } else {
            // }
            return errors;
        };



        //#region Handlers
        const handleSubmit_saveRecord = (e: any, data: any) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }

            //#region Handle SaveUserInfo
            {
                // VALIDATE INPUT
                const p1 = data.病歷No;
                if (!p1) {
                    _._debug.error('p1');
                    return; //ERROR INPUTS
                }
                const p2 = data.Item_病歷項;
                if (!p2) {
                    _._debug.error('p2');
                    return; //ERROR INPUTS
                }
                const p3 = data.掛號No;
                if (!p3) {
                    alert('沒有掛號無法寫入')
                    _._debug.error('p3');
                    return; //ERROR INPUTS
                }

                let hide = message.loading({
                    type: 'loading',
                    className: 'myMessage',
                    content: <div className="myMessageBody">驗證中...</div>,
                    key: 'loading',
                    duration: 0
                });

                // WAITING SCREEN
                loadingManager.changeLoadingCount(+1);
                //StaticEvent.LoadingEvent.next(true);

                // POST
                let postData = {
                    p1,
                    p2,
                    p3,
                    timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
                };
                const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_medicalrecord/modal_noteadd/save');
                const post$ = Static.apiService.post(postUrl, postData);
                post$.subscribe(async (res: any) => {
                        hide();

                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);

                        let data = res.data;
                        let error = data.error || null;
                        let result = error == null;

                        if (result) { // SUCEESS
                            let payload = data.payload;
                            _._debug.warn(payload);

                            let hide = message.success({
                                type: 'success',
                                className: 'myMessage',
                                content: <div className="myMessageBody">新增完成</div>,
                                key: 'success',
                                duration: 5
                            });


                            handleClose(null);

                            viewModel.OnDid?.();



                            // Static.NavAwayToAppointment(navigate, {
                            //     s: '手機號碼',
                            //     q: payload.Item_飼主.手機,
                            // })(null);

                            // let carryData = _._u.update(payload,
                            //     {
                            //         $merge: {
                            //             Tokens: payload?.Tokens,
                            //         },
                            //         Item_飼主: {
                            //             $set: payload?.Item_飼主,
                            //         }
                            //     }
                            // );
                            // carryData.Item_飼主 = p1;
                            // if (onDone) {
                            //     const ret = onDone(carryData);
                            //     if (ret === 'demo') {
                            //         createModalPet(null, {
                            //             carryData,
                            //         })
                            //     }
                            // } else {
                            //     alert('onDone not defined');
                            // }

                            //handleClose(null)

                        } else {
                            // AUTH USER EXPIRED
                            if (error.code == 2) {
                                //alert('帳號被登出，請重新登入');
                                //window.location.href = '/login';
                                //this.props.history.push('/login')
                                await sessionManager.setLogout();
                                pageDataManager.clear();
                                pageDataManager.reloadAsync({alwaysLoad: true});
                                alert('時間逾時已被登出');
                                return;
                            }

                            _._debug.error(error);
                            alert(error.message);
                        }
                    }, async (err) => {
                        hide();

                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);
                        _._debug.warn(err);
                        alert('網路連線有問題');
                    }
                );
                return lastValueFrom(
                    post$
                );
            }
            //#endregion
        }

        const key = 'updatable';

        const openMessage = () => {
            message.loading({ content: '添加中...', key });
            setTimeout(() => {
                message.success({ content: '添加成功!', key, duration: 2 });
            }, 1000);
        };
        //#endregion



        return (

            <Form
                onSubmit={(v) => handleSubmit_saveRecord(null, v)}
                initialValues={{
                    病歷No: viewModel.病歷No,
                    掛號No: viewModel.掛號No,
                    Item_病歷項: {},
                }}
                validate={handleValidate}
                render={({
                    submitError,
                    handleSubmit,
                    submitting,
                    pristine,
                    valid,
                    error,
                    values
                }) => {

                    return <React.Fragment>
                        <div className="form_body">
                            <form onSubmit={handleSubmit}>

                                <Field parse={_.bs.identity} name='Item_病歷項.備註_內容'>
                                    {
                                        ({ input, meta }) => (
                                            <React.Fragment>
                                                <NForm.Item
                                                    name="notearea"
                                                >
                                                    <TextArea rows={12} {...input}></TextArea>
                                                </NForm.Item>
                                            </React.Fragment>
                                        )
                                    }
                                </Field>
                                <React.Fragment>
                                    <NForm.Item>
                                        <div className="form_footer">
                                            <Button onClick={handleClose}>取消</Button>
                                            <Button type="primary" htmlType="submit" size="large" onClick={handleSubmit}>送出</Button>
                                        </div>
                                    </NForm.Item>
                                </React.Fragment>
                            </form>
                        </div>
                    </React.Fragment>;
                }}
            />
        );
    };
    //#endregion

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_MedicalRecord__Modal_NoteAdd.displayName = 'Partial_MedicalRecord__Modal_NoteAdd';
