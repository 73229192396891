import { IConfig } from 'overmind';
import { createActionsHook, createEffectsHook, createHook, createStateHook } from 'overmind-react';
import { state } from './state';
import { onInitialize } from './onInitialize';
import * as actions from './actions';
import * as effects from './effects';

export const config = {
  onInitialize,
  state,
  actions,
  effects,
};

export const useApp = createHook<typeof config>();
// export const useStates = createStateHook<typeof config>();
// export const useActions = createActionsHook<typeof config>();
// export const useEffects = createEffectsHook<typeof config>();
// export const useReaction = createActionsHook<typeof config>();
declare module 'overmind' {
  interface Config extends IConfig<typeof config> {}
}
