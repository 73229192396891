import React, { ReactElement } from 'react'
import './SymbolicBtn.scss';

interface Props {
  iconlink :string;
  type :string;
}

function SymbolicBtn(props: Props): ReactElement {
  return (
    <div className={props.type === "review" ? "symbolic-btn symbolic-review" : "symbolic-btn"}>
      <img src={props.iconlink} alt="symbolic icon"></img>
    </div>
  )
}

export default SymbolicBtn;
