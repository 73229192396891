import React, {useEffect, useLayoutEffect, useRef} from 'react';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import {
    Form, Input, Button, Alert, Checkbox, Result, message, Space,
    Modal, Typography, Tabs, Select, Popconfirm, Table, Empty, Spin, Tooltip
} from 'antd';
import {useApp} from "../overmind";
import {UserInfo} from "../global";
import {Static} from "../_/Static";
import {ApiService} from "../_/ApiService";
import _ from "../_";
import {EyeInvisibleOutlined, EyeTwoTone, UserOutlined, FieldTimeOutlined, EditOutlined} from '@ant-design/icons';

import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import UsePageDataManager from '../hooks/UsePageDataManager';
import UseSessionManager from "../hooks/UseSessionManager";
import UseLoadingManager from "../hooks/UseLoadingManager";
import UseCompState from "../hooks/UseCompState";
import {lastValueFrom} from "rxjs";
import Partial_Appointment__Modal_AddOwner from "./Partial_Appointment__Modal_AddOwner";
import Partial_Appointment__Modal_AddPet from "./Partial_Appointment__Modal_AddPet";
import Partial_Modal_Search from "./Partial_Appointment__Modal_Search";
import Partial_CommonCard from "./Partial_CommonCard";
import Partial_Modal_BusinessHours from "./Partial_Modal_BusinessHours";

import './Pg_Waitingroom.scss';
import FunctionBtn from './Box_Functionbtn';
import {StaticEvent} from "../_/StaticEvent";

const {Title} = Typography;


type Props = {
    PrefixTarget?: string;
    RoutePathname?: string;
    MenuTemplateId?: string;
    parentState?: any;
};
export default function Pg_Manage_Shifts(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Root';
    let RoutePathname = props.RoutePathname;
    let MenuTemplateId = props.MenuTemplateId;

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    //#region LoadingManager
    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: true});
    //#endregion

    //#region PageDataManager
    const refDoLoadPageData = Static.refDoLoadPageData;
    let pageDataManager = UsePageDataManager({
        refPageLoad: refDoLoadPageData
    });
    //#endregion

    //#region SessionManager
    let sessionManager = UseSessionManager({
        pageDataManager: pageDataManager,
    });
    //#endregion

    //#region Boot
    useEffect(() => {

        const _doLoadPageData = async (): Promise<void> => {
            pageDataManager.restore();
            if (!pageDataManager.isZoneExist(PrefixTarget)) {
                await sessionManager.initSessionAuthAsync();
                //#region User Check //TODO: VERTIFY
                if (!sessionManager.isMemberLoggedIn()) {
                    Static.NavAwayToLogin(pageDataManager, navigate)(null);
                    return;
                }
                //#endregion

                forceRender();
            } else {
                forceRender()
            }
        }

        refDoLoadPageData.current = _doLoadPageData;
        _doLoadPageData();
    }, [location.pathname]);
    //#endregion


    const RegionLoadingShow = true;


    let member: any = null;
    if (sessionManager.isMemberLoggedIn()) {
        member = sessionManager.getMember().Item_用戶;
    }

    // const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;
    if (member === null) {
        if (RegionLoadingShow) {
            return <React.Fragment>
                <div className="d3">
                    <div>Loading.</div>
                </div>
            </React.Fragment>;
        } else {
            return null;
        }
    }

    let iframeString: string = `<iframe src="https://www.pettalk.tw/vetsion/schedule/table/${member.醫院No}" width="100%" style="height: calc(100vh);"> </iframe>`;
    return (
        <div className="page-appointment page-content">
            <div dangerouslySetInnerHTML={{__html: iframeString}}/>
        </div>

    )
}

Pg_Manage_Shifts.displayName = 'Pg_Manage_Shifts';
