import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
import './Partial_MedicalRecord__Tab_BasicData.scss';
//import styles from './_Index.style';

import {
    Form as NForm, Popover, Button, Modal,
    message, Upload, Input, Space, Tooltip, Tabs, Table, Popconfirm, Spin, Select
} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";

import {DeleteOutlined, FileAddFilled, IdcardFilled, EditOutlined, LoadingOutlined} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_Modal_addProxy from "./Partial_Modal_addProxy";
import Partial_MedicalRecord__Modal_PastMedicalHistory from "./Partial_MedicalRecord__Modal_PastMedicalHistory";
import Partial_MedicalRecord__Modal_EditOrAddClient from "./Partial_MedicalRecord__Modal_EditOrAddClient";
import Partial_Module__Modal_AddPet from "./Partial_Module__Modal_AddPet";
import Ln_AvatorImg from "./Ln_AvatorImg";
import Partial_MedicalRecord__Modal_EditOrAddPet from "./Partial_MedicalRecord__Modal_EditOrAddPet";
import UseCompState from "../hooks/UseCompState";

const {Option} = Select;

var moment = require('moment');

type ViewModelProps = {
    refModal: any,

    寵物No: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any,

    viewModel: ViewModelProps,
};


export default function Partial_MedicalRecord__Tab_BasicData(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_MedicalRecord__Tab_BasicData';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel = props.viewModel;

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});


    //#region Boot
    let _refDoLoadPartialData: any = useRef<{ alwaysLoad?: boolean }>(null);
    useEffect(() => {
        const _loadPartialData = async (): Promise<void> => {

            // VALIDATE INPUT
            const p1 = viewModel.寵物No;
            if (!p1) {
                alert('p1');
                _._debug.error('p1')
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/partial_medicalrecord/tab_basicdata');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        let changeShard = {
                            [PrefixTarget]: {
                                ...payload,
                            }
                        }
                        pageDataManager.merge(changeShard);
                        pageDataManager.apply();

                        forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPartialData = async ({alwaysLoad = false}): Promise<void> => {
            // if (!pageDataManager.refPageData.current.Root) {
            //     return;
            // }

            /// do not dynamically load data if condition fits.
            if (!alwaysLoad) {
                if (pageDataManager.isZoneExist(PrefixTarget)) {
                    return;
                }
            }
            await _loadPartialData();

        }

        _refDoLoadPartialData.current = doLoadPartialData;
        _refDoLoadPartialData.current({});
    }, [pageDataManager.get(PrefixTarget)]);
    //#endregion

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

        state._refDoLoadPartialData = _refDoLoadPartialData;
    }
    //#endregion

    //#region Set Body
    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };
    //#region :Popup :createModaleditPet
    const createModaleditPet_refModal = useRef<any>(null);
    const createModaleditPet = (e: any, {寵物No, tasks}: {
        寵物No?: any,
        tasks?: string[]
    }): void => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_MedicalRecord__Modal_EditOrAddPet
                PrefixTarget={'Partial_MedicalRecord__Modal_EditOrAddPet'}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}
                parentState={state}


                viewModel={{
                    refModal: createModaleditPet_refModal,

                    寵物No,
                }}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '50em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModaleditPet_refModal.current = Modal.info(config);
    }
    //#endregion
    //#region :Popup :createModalLogin
    const createModalProxy_refModal = useRef<any>(null);
    const createModalProxy = (e: any, tasks?: string[]): void => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_Modal_addProxy
                PrefixTarget={'Partial_Modal_addProxy'}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModalProxy_refModal,
                }}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '60em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModalProxy_refModal.current = Modal.info(config);
    }
    //#endregion
    //#region :Popup :createModaleditClient
    const createModaleditClient_refModal = useRef<any>(null);
    const createModaleditClient = (e: any, {飼主No, tasks}: {
        飼主No: any,
        tasks?: string[]
    }) => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_MedicalRecord__Modal_EditOrAddClient
                PrefixTarget={'Partial_MedicalRecord__Modal_EditOrAddClient'}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}
                parentState={state}

                viewModel={{
                    refModal: createModaleditClient_refModal,

                    飼主No,
                }}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '50em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModaleditClient_refModal.current = Modal.info(config);
    }
    //#endregion
    //#region :Popup :createModalLogin
    const createModal_RH_refModal = useRef<any>(null);
    const createModal_RH = (e: any, {病歷No, tasks}: {
        病歷No: any,
        tasks?: string[]
    }) => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_MedicalRecord__Modal_PastMedicalHistory
                PrefixTarget={'Partial_MedicalRecord__Modal_PastMedicalHistory'}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModal_RH_refModal,

                    病歷No,
                }}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '60em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModal_RH_refModal.current = Modal.info(config);
    }

    //#endregion
    const Body = () => {
        const RegionEmptyShow = false;
        const RegionLoadingShow = true;
        const RegionInitialLoadingShow = true;

        const PassPageDataInitalized = pageDataManager.refPageData.current != null;
        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;

        const Item_寵物 = pageDataManager.get(`${PrefixTarget}.Item_寵物`);
        const List_飼主 = pageDataManager.get(`${PrefixTarget}.List_飼主`);
        const List_候診 = pageDataManager.get(`${PrefixTarget}.List_候診`);
        const Item_病歷 = pageDataManager.get(`${PrefixTarget}.Item_病歷`);
        const List_病歷項 = pageDataManager.get(`${PrefixTarget}.List_病歷項`);
        const List_醫生 = pageDataManager.get(`${PrefixTarget}.List_醫生`);

        if (!PassPrefixTargetLoaded) {
            if (RegionInitialLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <Spin indicator={
                            <LoadingOutlined style={{fontSize: 24}} spin/>
                        }/>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        if (loadingManager.getIsLoading()) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <Spin indicator={
                            <LoadingOutlined style={{fontSize: 24}} spin/>
                        }/>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        if (Item_寵物 === null) {
            if (RegionEmptyShow) {
                return <React.Fragment>
                    <div className="d3">
                        <div>Empty.</div>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        //#region Handlers
        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //


            return errors;
        };


        function confirm(e) {
            console.log(e);
            message.success('已刪除此筆資料！');
        }

        function cancel(e) {
            console.log(e);
        }

        //#endregion

        const columns = [
            {
                title: '日期',
                dataIndex: 'date',
                key: 'date',
                width: 70,
            },
            {
                title: '項目',
                dataIndex: 'item',
                key: 'item',
                width: 100,
            },
            {
                title: '次數',
                dataIndex: 'times',
                key: 'times',
                width: 40,
            },
            {
                title: '尚餘',
                dataIndex: 'remainder',
                key: 'remainder',
                width: 40,

            },
        ];

        const dataSource = [
            {
                key: '1',
                date: '2021/02/28',
                item: '心絲蟲預防',
                times: '3',
                remainder: '2',
            },
            {
                key: '2',
                date: '2021/01/15',
                item: '皮膚診療',
                times: '3',
                remainder: '5',
            },
            {
                key: '3',
                date: '2021/01/12',
                item: '全身洗淨',
                times: '3',
                remainder: '1',
            },
            {
                key: '4',
                date: '2020/11/05',
                item: '晶片檢查',
                times: '2',
                remainder: '1',
            },
            {
                key: '5',
                date: '2020/11/05',
                item: '晶片檢查',
                times: '2',
                remainder: '1',
            },
            {
                key: '6',
                date: '2020/11/05',
                item: '晶片檢查',
                times: '2',
                remainder: '1',
            },
            {
                key: '7',
                date: '2020/11/05',
                item: '晶片檢查',
                times: '2',
                remainder: '1',
            },
        ];


        const Maincontact = () => {
            if (List_飼主.length <= 0) {
                return <div className="infobox">
                    <div className="titlebar">
                        <h4>主聯絡人</h4>
                        <div className="barcontent"></div>
                        <div className="func">
                        </div>
                    </div>
                    <div className="boxcontent">
                        空
                    </div>
                    <div className="boxfooter">
                    </div>
                </div>
            } else {

                let Item_飼主: any = List_飼主[0];

                return <div className="infobox">
                    <div className="titlebar">
                        <h4>主聯絡人</h4>
                        <div className="barcontent"></div>
                        <div className="func">
                            {/* <Tooltip title="查看聯絡人詳細資訊">
                            <Button icon={<IdcardFilled />} size='small' type="link" />
                        </Tooltip> */}
                            <Tooltip title="編輯">
                                <Button icon={<EditOutlined/>} size='small' type="link"
                                        onClick={(e) => createModaleditClient(e, {飼主No: Item_飼主.飼主No})}/>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="boxcontent">
                        <div className="systable col_4">
                            <div className="str">
                                <div className="th">姓名</div>
                                <div className="td">{Item_飼主.主人姓名 ?? "-"}</div>
                            </div>
                            <div className="str">
                                <div className="th">性別</div>
                                <div className="td">{Item_飼主.主人性別 ?? "-"}</div>
                            </div>
                            <div className="str">
                                <div className="th">身分證</div>
                                <div className="td">{Item_飼主.身分證 ?? "-"}</div>
                            </div>
                            <div className="str">
                                <div className="th">醫賴碼</div>
                                <div className="td">{Item_飼主.醫賴碼 ? <React.Fragment>
                                    {Item_飼主.醫賴碼 ?? "-"} <Button size='small'
                                                          href={`https://www.pettalk.tw/vetsion/msg_center/login?token=b6931ef02231a8326a40a2f9e541de47e8af98b9&evcode=${Item_飼主.醫賴碼}`}
                                                          target='_blank' ghost type='primary'>醫Line碼</Button>
                                    <Button size='small'
                                            href={`https://www.pettalk.tw/vetmanager/owner_petinfo?token=b6931ef02231a8326a40a2f9e541de47e8af98b9&evcode=${Item_飼主.醫賴碼}`}
                                            target='_blank' ghost type='primary'>健康追蹤</Button>
                                </React.Fragment> : <React.Fragment>
                                    {Item_飼主.醫賴碼  ?? "-"}
                                </React.Fragment>
                                } </div>
                            </div>
                            <div className="str">
                                <div className="th">手機</div>
                                <div className="td">{Item_飼主.手機  ?? "-"}</div>
                            </div>
                            <div className="str">
                                <div className="th">聯絡電話</div>
                                <div className="td">{Item_飼主.聯絡電話 ?? "-"}</div>
                            </div>

                            <div className="str cops">
                                <div className="th">E-mail</div>
                                <div className="td">{Item_飼主.Email ?? "-"}</div>
                            </div>
                            <div className="str cops">
                                <div className="th">地址</div>
                                <div className="td">{Item_飼主.地址 ?? "-"}</div>
                            </div>
                            <div className="str cops">
                                <div className="th">備註</div>
                                <div className="td">{Item_飼主.主人備註 ?? "-"}</div>
                            </div>
                        </div>
                    </div>
                    <div className="boxfooter">

                    </div>
                </div>
            }
        }

        const Proxycontact = () => (
            <div className="infobox">
                <div className="titlebar">
                    <h4>代理人</h4>
                    <div className="barcontent"></div>
                    <div className="func">
                        {/* <Tooltip title="查看聯絡人詳細資訊">
                            <Button icon={<IdcardFilled />} size='small' type="link" />
                        </Tooltip> */}
                        <Tooltip title="編輯">
                            <Button icon={<EditOutlined/>} size='small' type="link"/>
                        </Tooltip>
                    </div>
                </div>
                <div className="boxcontent">
                    <div className="systable col_4">
                        <div className="str">
                            <div className="th">姓名</div>
                            <div className="td">李伊妮</div>
                        </div>
                        <div className="str">
                            <div className="th">性別</div>
                            <div className="td">女性</div>
                        </div>
                        <div className="str">
                            <div className="th">身分證</div>
                            <div className="td">A216816888</div>
                        </div>
                        <div className="str">
                            <div className="th">醫賴碼</div>
                            <div className="td">178168168</div>
                        </div>
                        <div className="str">
                            <div className="th">手機</div>
                            <div className="td">0957168168</div>
                        </div>
                        <div className="str">
                            <div className="th">聯絡電話</div>
                            <div className="td">02-73238548</div>
                        </div>

                        <div className="str cops">
                            <div className="th">E-mail</div>
                            <div className="td">wangwang@vetsion.tw</div>
                        </div>
                        <div className="str cops">
                            <div className="th">地址</div>
                            <div className="td">旺財市財源里大發路168號8樓</div>
                        </div>

                        <div className="str cops">
                            <div className="th">備註</div>
                            <div className="td">會員VVIP</div>
                        </div>
                    </div>
                </div>
                <div className="boxfooter">

                </div>
            </div>
        )

        const {TabPane} = Tabs;

        const ActionTabs = () => (
            <Tabs defaultActiveKey="1">
                <TabPane tab="已注射預防針(1)" key="1">
                    <Table size='small' dataSource={dataSource} columns={columns} scroll={{y: 210}}/>
                </TabPane>
                <TabPane tab="預計實施(2)" key="2">
                    Content of Tab Pane 2
                </TabPane>
                <TabPane tab="已做檢驗(0)" key="3">
                    Content of Tab Pane 3
                </TabPane>
            </Tabs>
        );

        const BasicTabs = () => (
            <Tabs defaultActiveKey="1">
                <TabPane tab="基本資料" key="1">
                    <Maincontact/>
                    {/*<Proxycontact/>*/}
                </TabPane>
                {/*<TabPane tab="會員資訊" key="2">*/}
                {/*    Content of Tab Pane 2*/}
                {/*</TabPane>*/}
            </Tabs>
        );

        const PastMedicalHistoryContent = (Item_病歷) => (
            <Tabs defaultActiveKey="1">
                <TabPane tab="保健紀錄" key="1">
                    <div className="systable col_2 mw_th">
                        <div className="str">
                            <div className="th">絕育狀態</div>
                            <div
                                className="td">{Item_病歷.絕育狀態 ?? '未填寫'} ({Item_病歷.絕育狀態時間 ? moment(Item_病歷.絕育狀態時間).format('YYYY-MM-DD') : '-'})
                            </div>
                        </div>
                        <div className="str">
                            <div className="th">年度預防注射</div>
                            <div
                                className="td">{Item_病歷.年度預防注射 ?? '未填寫'} ({Item_病歷.年度預防注射時間 ? moment(Item_病歷.年度預防注射時間).format('YYYY-MM-DD') : '-'})
                            </div>
                        </div>
                        <div className="str">
                            <div className="th">狂犬病預防注射</div>
                            <div
                                className="td">{Item_病歷.狂犬病預防注射 ?? '未填寫'} ({Item_病歷.狂犬病預防注射時間 ? moment(Item_病歷.狂犬病預防注射時間).format('YYYY-MM-DD') : '-'})
                            </div>
                        </div>
                        <div className="str">
                            <div className="th">心絲蟲預防</div>
                            <div
                                className="td">{Item_病歷.心絲蟲預防 ?? '未填寫'} ({Item_病歷.心絲蟲預防時間 ? moment(Item_病歷.心絲蟲預防時間).format('YYYY-MM-DD') : '-'})
                            </div>
                        </div>
                        <div className="str">
                            <div className="th">外寄生蟲預防</div>
                            <div
                                className="td">{Item_病歷.外寄生蟲預防 ?? '未填寫'} ({Item_病歷.外寄生蟲預防時間 ? moment(Item_病歷.外寄生蟲預防時間).format('YYYY-MM-DD') : '-'})
                            </div>
                        </div>
                        <div className="str cops">
                            <div className="th">內寄生蟲預防</div>
                            <div
                                className="td">{Item_病歷.內寄生蟲預防 ?? '未填寫'} ({Item_病歷.內寄生蟲預防時間 ? moment(Item_病歷.內寄生蟲預防時間).format('YYYY-MM-DD') : '-'})
                            </div>
                        </div>
                    </div>
                </TabPane>
                <TabPane tab="藥物過敏" key="2">
                    <div className="systable col_2 mw_th">
                        <div className="str">
                            <div className="th">藥物類過敏</div>
                            <div className="td">{Item_病歷.藥物類過敏說明組 ?? '-'}</div>
                        </div>
                        <div className="str">
                            <div className="th">食物類過敏</div>
                            <div className="td">{Item_病歷.食物類過敏說明組 ?? '-'}</div>
                        </div>
                        <div className="str">
                            <div className="th">皮膚接觸過敏</div>
                            <div className="td">{Item_病歷.皮膚接觸過敏說明組 ?? '-'}</div>
                        </div>
                    </div>
                </TabPane>
                <TabPane tab="重大疾病" key="3">
                    <div className="partext">
                        {Item_病歷.重大疾病說明 ?? '-'}
                    </div>
                </TabPane>
            </Tabs>
        );

        let 年齡: any = null;
        if (!_.util.nullify(Item_寵物?.出生日)) {
            年齡 = '沒有填出生日';
        } else {
            try {
                let birthDate = moment(Item_寵物.出生日);
                let now = moment();
                var yearDiff = now.diff(birthDate, "year");
                var monthDiff = now.diff(birthDate, "month");
                年齡 = '';
                if (monthDiff >= 0) {
                    年齡 = `${monthDiff}個月` + 年齡;
                }
                if (yearDiff >= 0) {
                    年齡 = `${yearDiff}歲` + 年齡;
                }
                if (年齡 === '') {
                    年齡 = `未滿一個月`;
                }
            } catch (err) {
                年齡 = '計算錯誤';
            }
        }

        let 體重: any = _.util.nullify(Item_寵物?.最後體重) ?? _.util.nullify(Item_寵物?.口述體重);
        if (!體重) {
            體重 = '沒有測量或告知';
        }

        let 喜愛醫師: any = List_醫生.filter(r => r.醫生No === Item_寵物?.醫生No_喜愛醫師)?.[0]?.名稱;
        if (!喜愛醫師) {
            喜愛醫師 = null;
        } else {
            喜愛醫師 = 喜愛醫師 + ' 醫師';
        }

        return (
            <div className="partial_tab_mr_basicdata">
                <div className="wrapper gridlayerout">


                    {/* 寵物資料 */}
                    <div className="infoboxoutside">
                        <div className="infobox">
                            <div className="titlebar">
                                <h3>寵物資料</h3>
                                <div className="barcontent">
                                    <div className="plugtext">Pet:{Item_寵物.寵物No}</div>
                                </div>
                                <div className="func">
                                    <Space>
                                        <Tooltip title="編輯">
                                            <Button icon={<EditOutlined/>} size='small' type="link"
                                                    onClick={(e) => createModaleditPet(e, {寵物No: Item_寵物.寵物No})}/>
                                        </Tooltip>
                                    </Space>
                                </div>
                            </div>
                            <div className="boxcontent">
                                <div className="avatar">
                                    <div className="photo">
                                        {Ln_AvatorImg({
                                            名稱: Item_寵物.寵物名稱,
                                            照片組: Item_寵物.寵物照片組
                                        })}
                                    </div>
                                    <div className="markbar">
                                        {/*<ul>*/}
                                        {/*    <li><img src="/static/img/caty.png" alt="cat"/></li>*/}
                                        {/*    <li><img src="/static/img/tree.png" alt="tree"/></li>*/}
                                        {/*    <li><img src="/static/img/ill.png" alt="emr"/></li>*/}
                                        {/*    <li><img src="/static/img/eye.png" alt="eye"/></li>*/}
                                        {/*</ul>*/}
                                        <NForm.Item
                                            name="寵物勳章"
                                            label=""
                                            rules={[{required: true, message: '請輸入正確格式'}]}
                                        >
                                            <Select placeholder="請選擇種類" defaultValue={Item_寵物.寵物勳章} showArrow={false} disabled={true}>
                                                <Option value="">不指定</Option>
                                                <Option value="友善">😺 友善</Option>
                                                <Option value="特別友善">😸 特別友善</Option>
                                                <Option value="易受驚嚇">🙀 易受驚嚇</Option>
                                                <Option value="兇暴">😾 兇暴</Option>
                                                <Option value="容易哀嚎">😿 容易哀嚎</Option>
                                            </Select>
                                        </NForm.Item>

                                        <Button className='ant-btn-primary-outline'>掛號紀錄</Button>

                                    </div>
                                </div>

                                <div className="systable col_4">
                                    <div className="str">
                                        <div className="th">喜愛醫師</div>
                                        <div className="td">{喜愛醫師 ?? "-"}</div>
                                    </div>
                                    <div className="str">
                                        <div className="th">年齡</div>
                                        <div className="td">{年齡 ?? "-"}</div>
                                    </div>
                                    <div className="str">
                                        <div className="th">寵物姓名</div>
                                        <div className="td">{Item_寵物.寵物名稱 ?? "-"}</div>
                                    </div>
                                    <div className="str">
                                        <div className="th">體重</div>
                                        <div className="td">{體重 ?? "-"}</div>
                                    </div>
                                    <div className="str">
                                        <div className="th">節育</div>
                                        <div className="td">{Item_寵物.節育 ?? "-"}</div>
                                    </div>
                                    <div className="str">
                                        <div className="th">毛色</div>
                                        <div className="td">{Item_寵物.毛色 ?? "-"}</div>
                                    </div>
                                    <div className="str">
                                        <div className="th">晶片</div>
                                        <div className="td">{Item_寵物.晶片號碼 ?? "-"}</div>
                                    </div>
                                    <div className="str">
                                        <div className="th">性別</div>
                                        <div className="td">{Item_寵物.性別 ?? "-"}</div>
                                    </div>
                                    <div className="str">
                                        <div className="th">種類</div>
                                        <div className="td">{Item_寵物.種類 ?? "-"}</div>
                                    </div>
                                    <div className="str">
                                        <div className="th">品種</div>
                                        <div className="td">{Item_寵物.品種 ?? "-"}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="boxfooter">

                            </div>
                        </div>
                    </div>


                    {/* 動作執行 */}
                    {/*<div className="infoboxoutside flw_h">*/}
                    {/*    <div className="infobox">*/}
                    {/*        <div className="titlebar"></div>*/}
                    {/*        <div className="boxcontent">*/}
                    {/*            <ActionTabs />*/}
                    {/*        </div>*/}
                    {/*        <div className="boxfooter"></div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}


                    {/* 過往病史 */}
                    <div className="infoboxoutside">
                        <div className="infobox">
                            <div className="titlebar">
                                <h3>過往病史</h3>
                                <div className="barcontent">
                                    {/*<Button size='small' className='ant-btn-primary-outline'>檢視備份病史</Button>*/}
                                </div>
                                <div className="func">
                                    <Tooltip title="編輯">
                                        <Button icon={<EditOutlined/>} size='small' type="link"
                                                onClick={(e) => createModal_RH(e, {病歷No: Item_病歷.病歷No})}/>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="boxcontent">
                                {PastMedicalHistoryContent(Item_病歷)}
                            </div>
                            <div className="boxfooter">

                            </div>
                        </div>
                    </div>

                    {/* 飼主資料 */}
                    <div className="infoboxoutside">
                        <div className="infobox">
                            <div className="titlebar">
                                <h3>飼主資料</h3>
                                <div className="barcontent"></div>
                                <div className="func">

                                </div>
                            </div>
                            <div className="boxcontent">
                                {BasicTabs()}
                            </div>

                            {/*<div className="boxfooter">*/}
                            {/*    <Button className='ant-btn-primary-outline' icon={<FileAddFilled/>}*/}
                            {/*            onClick={createModalProxy}>新增代理人</Button>*/}
                            {/*</div>*/}
                        </div>
                    </div>


                    <div className="contralbar">
                        <div className="func">
                            <Popconfirm
                                title="確認是否刪除該寵物？"
                                onConfirm={confirm}
                                onCancel={cancel}
                                okText="確定刪除"
                                cancelText="取消"
                            >
                                <Tooltip title="如果刪除將無法恢復資料" placement="left">
                                    <Button icon={<DeleteOutlined/>} danger size='large'>刪除該寵物</Button>
                                </Tooltip>
                            </Popconfirm>
                        </div>

                        <div className="info">
                            刪除該隻寵物將連同病歷紀錄一併刪除，請謹慎操作，點擊後將須輸入此寵物名稱才可刪除。
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return <React.Fragment>
        {Body()}
    </React.Fragment>;

}
Partial_MedicalRecord__Tab_BasicData.displayName = 'Partial_MedicalRecord__Tab_BasicData';
