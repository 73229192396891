import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {
    Form as NForm,
    Popover,
    Button,
    Modal,
    message,
    Radio,
    Input,
    Space,
    Select,
    Row,
    Col,
    Spin,
    Upload, DatePicker
} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";

import {
    LoadingOutlined,
    LockOutlined,
    PlusOutlined,
    RedoOutlined,
    UploadOutlined,
    UserOutlined
} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_Appointment__Modal_AddPet from "./Partial_Appointment__Modal_AddPet";

var moment = require('moment');
const {Option} = Select;

type ViewModelProps = {
    refModal: any,

    飼主No?: any,

    OnUpdated?: any;
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any,

    viewModel: ViewModelProps,
};
export default function Partial_MedicalRecord__Pane_EditOrAddClient(props: Props) {
    const PrefixTarget: string = props.PrefixTarget || ('Partial_MedicalRecord__Pane_EditOrAddClient' + (props.viewModel.飼主No ?? "0"));
    const pageDataManager: PageDataManager = props.pageDataManager;
    const sessionManager: SessionManager = props.sessionManager;
    const navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel: any = props.viewModel;
    const isNew = !viewModel.飼主No;

    const layout = {
        labelCol: {span: 7},
        wrapperCol: {span: 17},
    };
    const layout2 = {
        labelCol: {span: 4},
        wrapperCol: {span: 20},
    };

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region :Popup :createModalPet
    const createModalPet_refModal = useRef<any>(null);
    const createModalPet = (e: any, {carryData, tasks}: {
        carryData?: any,
        tasks?: string[]
    }): void => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_Appointment__Modal_AddPet
                PrefixTarget={'Partial_Appointment__Modal_AddPet'}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModalPet_refModal,
                    carryData: carryData,
                }}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '60em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModalPet_refModal.current = Modal.info(config);
    }
    //#endregion

    //#region Boot
    let _refDoLoadPartialData: any = useRef<{ alwaysLoad?: boolean }>(null);
    useEffect(() => {
        const _loadPartialData = async (): Promise<void> => {

            // VALIDATE INPUT
            const p1 = viewModel.飼主No;
            if (!p1) {
                alert('p1')
                _._debug.error('p1');
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/partial_medicalrecord/pane_editoraddclient');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        //delete pageDataManager.refPageData.current[TabControlState]?.['MyCollectionTab_data'];

                        pageDataManager.emptyZone(PrefixTarget);
                        let changeShard = {
                            [PrefixTarget]: {
                                ...payload,
                            }
                        }
                        pageDataManager.merge(changeShard);
                        pageDataManager.apply();

                        forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPartialData = async ({alwaysLoad = false}): Promise<void> => {
            // if (!pageDataManager.refPageData.current.Root) {
            //     return;
            // }

            /// do not dynamically load data if condition fits.
            if (!alwaysLoad) {
                if (pageDataManager.isZoneExist(PrefixTarget)) {
                    return;
                }
            }

            if (!isNew) {
                await _loadPartialData();
            } else {
                pageDataManager.emptyZone(PrefixTarget);
                pageDataManager.merge({
                    [PrefixTarget]: {}
                });
                pageDataManager.apply();

                forceRender();
            }
        }

        _refDoLoadPartialData.current = doLoadPartialData;
        _refDoLoadPartialData.current({});
    }, []);
    //#endregion


    //#region Set Body
    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };
    const isModalContained = () => {
        return !!viewModel.refModal?.current
    }
    const Body = () => {
        const RegionEmptyShow = false;
        const RegionLoadingShow = true;
        const RegionInitialLoadingShow = false;

        const PassPageDataInitalized = pageDataManager.refPageData.current != null;
        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;

        let Item_飼主 = pageDataManager.get(`${PrefixTarget}.Item_飼主`);
        let List_飼主: any = Item_飼主 ? [Item_飼主] : [null];

        //#region Handlers
        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //

            //
            // if (!values.姓名) {
            //     errors.姓名 = '↑ 欄位為必填';
            // } else {
            // }
            //
            //
            // if (!values.手機) {
            //     errors.手機 = '↑ 欄位為必填';
            // } else {
            // }


            return errors;
        };

        const {TextArea} = Input;

        const handleSubmit_save = (e: any, v: any) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }

            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            const p1 = v;
            if (!p1) {
                _._debug.error(p1);
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_medicalrecord/pane_editoraddclient/save');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        let changeShard = {
                            [PrefixTarget]: {
                                ...payload,
                            }
                        }
                        pageDataManager.merge(changeShard);
                        pageDataManager.apply();

                        if (isNew) {
                            message.success({content: '新增完成!', duration: 2});
                        } else {
                            message.success({content: '修改完成!', duration: 2});
                        }

                        if (isModalContained()) {
                            handleClose(null);
                        } else {
                            _refDoLoadPartialData.current({alwaysLoad: true});
                        }

                        viewModel?.OnUpdated?.(payload);
                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }

        const genderoptions = [
            {label: '男性', value: 'Male'},
            {label: '女性', value: 'Female'},
        ];
        //#endregion

        if (!PassPrefixTargetLoaded) {
            if (RegionInitialLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <div>Loading.</div>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        if (loadingManager.getIsLoading()) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <Spin indicator={
                            <LoadingOutlined style={{fontSize: 24}} spin/>
                        }/>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        if (List_飼主 && List_飼主.length > 0) {
            return <React.Fragment>
                {List_飼主.map(Item_飼主 => {
                    return <React.Fragment key={Item_飼主?.飼主No ?? "0"}>
                        <Form
                            onSubmit={(v) => handleSubmit_save(null, v)}
                            initialValues={Item_飼主}
                            validate={handleValidate}
                            render={({
                                         submitError,
                                         handleSubmit,
                                         submitting,
                                         pristine,
                                         valid,
                                         error,
                                         values
                                     }) => {

                                return <React.Fragment>
                                    <div className="form_body col_3">
                                        <form
                                            onSubmit={handleSubmit}
                                            {...layout}>
                                            <Row>
                                                <Col span={12}>
                                                    <Field parse={_.bs.identity} name="主人姓名">
                                                        {
                                                            ({input, meta}) => (
                                                                <React.Fragment>
                                                                    <NForm.Item {...input} {...layout}
                                                                                name="姓名"
                                                                                label="姓名"
                                                                                rules={[{
                                                                                    required: true,
                                                                                    message: '請輸入姓名'
                                                                                }]}
                                                                    >

                                                                        <Input {...input} placeholder="請輸入中英文"
                                                                               allowClear/>
                                                                        {meta.touched && meta.error &&
                                                                        <div className="error">
                                                                            {meta.error}
                                                                        </div>}
                                                                    </NForm.Item>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    </Field>
                                                </Col>
                                                <Col span={12}>
                                                    <Field parse={_.bs.identity} name="主人性別">
                                                        {
                                                            ({input, meta}) =>
                                                                (
                                                                <React.Fragment>
                                                                    <NForm.Item {...input} {...layout}
                                                                                name="性別"
                                                                                label="性別"
                                                                    >

                                                                        <Radio.Group
                                                                            options={genderoptions}
                                                                            optionType="button"
                                                                            {...input}
                                                                        />
                                                                        {meta.touched && meta.error &&
                                                                        <div className="error">
                                                                            {meta.error}
                                                                        </div>}
                                                                    </NForm.Item>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    </Field>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={12}>
                                                    <Field parse={_.bs.identity} name="身分證">
                                                        {
                                                            ({input, meta}) => (
                                                                <React.Fragment>
                                                                    <NForm.Item {...input} {...layout}
                                                                                name="身分證"
                                                                                label="身分證"

                                                                    >
                                                                        <Input {...input}
                                                                               placeholder="請輸入身分證" allowClear/>
                                                                        {meta.touched && meta.error &&
                                                                        <div className="error">
                                                                            {meta.error}
                                                                        </div>}
                                                                    </NForm.Item>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    </Field></Col>
                                                <Col span={12}>
                                                    <Field parse={_.bs.identity} name="醫賴碼">
                                                        {
                                                            ({input, meta}) => (
                                                                <React.Fragment>
                                                                    <NForm.Item {...input} {...layout}
                                                                                name="醫賴碼"
                                                                                label="醫賴碼"

                                                                    >
                                                                        <Input {...input}
                                                                               placeholder="請輸入醫賴碼" allowClear/>
                                                                        {meta.touched && meta.error &&
                                                                        <div className="error">
                                                                            {meta.error}
                                                                        </div>}
                                                                    </NForm.Item>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    </Field>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={12}>
                                                    <Field parse={_.bs.identity} name="手機">
                                                        {
                                                            ({input, meta}) => (
                                                                <React.Fragment>
                                                                    <NForm.Item {...input} {...layout}
                                                                                name="手機"
                                                                                label="手機"
                                                                                rules={[{
                                                                                    required: true,
                                                                                    message: '請輸入正確格式'
                                                                                }]}
                                                                    >
                                                                        <Input {...input}
                                                                               placeholder="請輸入手機號碼" allowClear/>
                                                                        {meta.touched && meta.error &&
                                                                        <div className="error">
                                                                            {meta.error}
                                                                        </div>}
                                                                    </NForm.Item>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    </Field>

                                                </Col>
                                                <Col span={12}>
                                                    <Field parse={_.bs.identity} name="聯絡電話">
                                                        {
                                                            ({input, meta}) => (
                                                                <React.Fragment>
                                                                    <NForm.Item {...input} {...layout}
                                                                                name="聯絡電話"
                                                                                label="聯絡電話"
                                                                    >
                                                                        <Input {...input}
                                                                               placeholder="請輸入聯絡電話" allowClear/>
                                                                        {meta.touched && meta.error &&
                                                                        <div className="error">
                                                                            {meta.error}
                                                                        </div>}
                                                                    </NForm.Item>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    </Field>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col span={24}>
                                                    <Field parse={_.bs.identity} name="Email">
                                                        {
                                                            ({input, meta}) => (
                                                                <React.Fragment>
                                                                    <NForm.Item {...input} {...layout2}
                                                                                name="E-mail"
                                                                                label="E-mail"

                                                                    >
                                                                        <Input {...input}
                                                                               placeholder="請輸入E-mail" allowClear/>
                                                                        {meta.touched && meta.error &&
                                                                        <div className="error">
                                                                            {meta.error}
                                                                        </div>}
                                                                    </NForm.Item>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    </Field>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={24}>
                                                    <Field parse={_.bs.identity} name="地址">
                                                        {
                                                            ({input, meta}) => (
                                                                <React.Fragment>
                                                                    <NForm.Item {...input} {...layout2}
                                                                                name="地址"
                                                                                label="地址"

                                                                    >
                                                                        <Input {...input}
                                                                               placeholder="請輸入地址" allowClear/>
                                                                        {meta.touched && meta.error &&
                                                                        <div className="error">
                                                                            {meta.error}
                                                                        </div>}
                                                                    </NForm.Item>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    </Field>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={24}>
                                                    <Field parse={_.bs.identity} name="主人備註">
                                                        {
                                                            ({input, meta}) => (
                                                                <React.Fragment>
                                                                    <NForm.Item {...input} {...layout2}
                                                                                name="備註"
                                                                                label="備註"

                                                                    >
                                                                        <TextArea {...input} rows={3}/>
                                                                        {meta.touched && meta.error &&
                                                                        <div className="error">
                                                                            {meta.error}
                                                                        </div>}
                                                                    </NForm.Item>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    </Field>
                                                </Col>
                                            </Row>


                                            <React.Fragment>
                                                <NForm.Item>
                                                    <div className="form_footer">

                                                        <Button type="primary" htmlType="submit" size="large"
                                                                onClick={handleSubmit}>儲存</Button>

                                                    </div>
                                                </NForm.Item>
                                            </React.Fragment>
                                        </form>
                                    </div>
                                </React.Fragment>;
                            }}
                        />
                    </React.Fragment>
                })}
            </React.Fragment>;
        } else if (List_飼主 && List_飼主.length === 0) {
            if (RegionEmptyShow) {
                return <React.Fragment>
                    <div>Empty.</div>
                </React.Fragment>;
            }
        }

        return null;
    };

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_MedicalRecord__Pane_EditOrAddClient.displayName = 'Partial_MedicalRecord__Pane_EditOrAddClient';
