
import * as Global from '../global';
import axios from 'axios';

export const loginApi = {
  getUsers() {

  },
  getPetColorData(){
    let colorData: string[] = ['黑', '白', '橘', '灰', '斑點'];
    return colorData;
  },
};

export const waitingPageApi = {
  async getPgData(){
    let DoctorData: Array<any> | undefined;
    let DoctorList: Global.DoctorInfo[] = [];
    let RoomTagData: string;
    let RoomTagList: Global.RoomTags[] = [];
    let PatientData: Array<any> | undefined;
    let PatientList: Array<Global.PatientCardInfo> = [];

    await axios.post('http://test-pet.mipoko.com/pet_api/1/pg_waitingroom',{
      testmode:'123'
    })
    .then(function (response: any) {
      PatientData = response.data.payload.Root.List_候診;
      DoctorData = response.data.payload.Box_Doctors.List_醫生;
      RoomTagData = response.data.payload.Root.候診標籤Json;
      if(DoctorData !== undefined && DoctorData !== []){
        DoctorData.map(item => (
          DoctorList.push({
            name: item.名稱,
            id: item.醫生Id
          })
        ));
      }

      if(RoomTagData !== "{}"){
        let roomtagdata: Global.RoomTags[] = JSON.parse(RoomTagData);
        roomtagdata.map(item => (
          RoomTagList.push({
            name: item.name,
            id: item.id
          })
        ));
      }
      if(PatientData !== undefined && PatientData !== []){
        PatientData.map(item => (
          PatientList.push({
            orderid: item.候診No,
            oncall: item._寵物.看診狀態 !== '看診中' ? '': '看診中',
            doctor: item.醫生No_喜好醫生 === null ? '': item.醫生No_喜好醫生,
            roomtag: item.標籤 === null ? '' : RoomTagList[parseInt(item.標籤, 10) -1].name,
            deleted: item.Is刪除,
            petinfo: {
              petno: item._寵物.寵物No === null ? 0: parseInt(item._寵物.寵物No, 10),
              name: item._寵物.名稱 === null ? '': item._寵物.名稱,
              piclink: item._寵物.照片組 === null? '': item._寵物.照片組,
              birthday: item._寵物.出生日=== null ? '':item._寵物.出生日,
              weight: item._寵物.最後體重=== null ? 0:item._寵物.最後體重,
              color: item._寵物.毛色=== null ? '':item._寵物.毛色,
              gender: item._寵物.性別=== null ? '':item._寵物.性別,
              chipNumber: item._寵物.識別晶片=== null ? '':item._寵物.識別晶片,
              neutered: item._寵物.結紮=== null ? false:item._寵物.結紮,
              species: item._寵物.種類=== null ? '':item._寵物.種類,
              breedzh: item._寵物.品種=== null ? '':item._寵物.品種,
              breeden: null,
              lastedDoctorId: item.醫生No_最後看診醫生=== null ? 0:item.醫生No_最後看診醫生,
              food: null,
              tags: item._寵物.寵物備註=== null ? '':item._寵物.寵物備註
            },
            ownerinfo: {
              memberNumber: item._飼主.飼主No=== null ? 0:item._飼主.飼主No,
              name: item._飼主.主人=== null ? '':item._飼主.主人,
              twId: item._飼主.身分證=== null ? '':item._飼主.身分證,
              cellPhone: item._飼主.大哥大=== null ? '':item._飼主.大哥大,
              classicPhone: item._飼主.電話=== null? '':item._飼主.電話,
              EDNumber: item._飼主.醫賴碼=== null ? '':item._飼主.醫賴碼,
              address: item._飼主.地址=== null ? '':item._飼主.地址,
              email: item._飼主.Email=== null ? '':item._飼主.Email,
              note: item._飼主.主人備註=== null? '':item._飼主.主人備註,
            }
          })
        ));
      }
      // console.log(PatientList);
      // console.log(RoomTagList);
      // console.log(DoctorList);
    }).catch(function (error: any) {
      console.log(error);
    });

    return {PatientList: PatientList, RoomtagData: RoomTagList, DoctorList: DoctorList};
  },
};

export const appointmentPageApi = {
  async getPgData(data: any){
    let PetData: Array<any>;
    let PetList: Array<Global.SearchedPetList> = [];

    await axios.post('http://test-pet.mipoko.com/pet_api/1/pg_appointment', data)
    .then(function (response: any) {
      PetData = response.data.payload.Root.List_寵物;

      if(PetData !== undefined && PetData !== []){
        PetData.map(item => {
          PetList.push({
            petno: item.寵物No === null ? 0: item.寵物No,
            oncall: (item.看診狀態 === '看診中' || item.看診狀態 === '候診中')? true: false,
            petname: item.名稱 === null ? '': item.名稱,
            ownerno: item._飼主.飼主No=== null ? 0:item._飼主.飼主No,
            ownername: item._飼主.主人=== null ? '':item._飼主.主人,
            doctor: item.醫生No_最後看診醫生 === null ? '': item.醫生No_最後看診醫生,
            ownerphone: item._飼主.大哥大=== null ? '':item._飼主.大哥大,
            //classicPhone: item._飼主.電話=== null? '':item._飼主.電話,
            edNumber: item._飼主.醫賴碼=== null ? '':item._飼主.醫賴碼,
          });
        });
      }
      // console.log(PetData);
    }).catch(function (error: any) {
      console.log(error);
    });
    return PetList;
  }
}
export const basicPageApi = {
  async getPgData(data: any){
    let DoctorData: Array<any> | undefined;
    let DoctorList: Global.DoctorInfo[] = [];

    type PetInfo = {
      oncall: string | null;
      doctor: string | null;
      petinfo: {},
      ownerinfo: Array<Global.OwnerInfo>
    }
    let PetDataInfo:　PetInfo = {
      oncall: '',
      doctor: '',
      petinfo: {},
      ownerinfo: []
    };

    await axios.post('http://test-pet.mipoko.com/pet_api/1/pg_basicdata', data)
    .then(function (response: any) {
      let PetData: any = response.data.payload.Root.Item_寵物;

      // DoctorData = response.data.payload.Box_Doctors.List_醫生;
      // if(DoctorData !== undefined && DoctorData !== []){
      //   DoctorData.map(item => (
      //     DoctorList.push({
      //       name: item.名稱,
      //       id: item.醫生Id
      //     })
      //   ));
      // }

      if(PetData !== undefined && PetData !== {}){
        console.log(PetData);
        PetDataInfo.oncall = (PetData.看診狀態 === null) ? '': PetData.看診狀態;
        PetDataInfo.doctor = PetData.醫生No_喜好醫生 === null ? '': PetData.醫生No_喜好醫生;
        PetDataInfo.petinfo = {
          petno: PetData.寵物No === null ? 0: parseInt(PetData.寵物No, 10),
          name: PetData.名稱 === null ? '': PetData.名稱,
          piclink: PetData.照片組 === null? '': PetData.照片組,
          birthday: PetData.出生日=== null ? '':PetData.出生日,
          weight: PetData.最後體重=== null ? 0:PetData.最後體重,
          color: PetData.毛色=== null ? '':PetData.毛色,
          gender: PetData.性別=== null ? '':PetData.性別,
          chipNumber: PetData.識別晶片=== null ? '':PetData.識別晶片,
          neutered: PetData.結紮=== null ? false:PetData.結紮,
          species: PetData.種類=== null ? '':PetData.種類,
          breedzh: PetData.品種=== null ? '':PetData.品種,
          breeden: null,
          lastedDoctorId: PetData.醫生No_最後看診醫生=== null ? 0:PetData.醫生No_最後看診醫生,
          food: null,
          tags: PetData.寵物備註=== null ? '':PetData.寵物備註
        };

        response.data.payload.Root.List_飼主.map( (item: { 飼主No: number | null; 主人: string | null; 身分證: string | null; 大哥大: string | null; 電話: string | null; 醫賴碼: string | null; 地址: string | null; Email: string | null; 主人備註: string | null; }) => {
          PetDataInfo.ownerinfo.push({
            memberNumber: item.飼主No === null ? 0:item.飼主No,
            name: item.主人=== null ? '':item.主人,
            twId: item.身分證=== null ? '':item.身分證,
            cellPhone: item.大哥大=== null ? '':item.大哥大,
            classicPhone: item.電話=== null? '':item.電話,
            EDNumber: item.醫賴碼=== null ? '':item.醫賴碼,
            address: item.地址=== null ? '':item.地址,
            email: item.Email=== null ? '':item.Email,
            note: item.主人備註=== null? '':item.主人備註,
          });
        });
      }

      console.log(PetDataInfo);
    }).catch(function (error: any) {
      console.log(error);
    });
    return PetDataInfo;
  }
}

export const queuePet = {
  async makeOrder(data: any) {
    await axios.post('http://test-pet.mipoko.com/pet_api/1/queuepet', data)
    .then(function (response: any){
      return response.data.result;
    })
    .catch(function (error: any) {
      console.log(error);
    });
  },
};

export const updatePetMedcareStatus = {
  async update(data: any) {
    await axios.post('http://test-pet.mipoko.com/pet_api/1/updatepet_medcarestatus', data)
    .then(function (response: any){
      // if(response.data.result === false){
        return response.data.result;
        // console.log(response.data.error);
      // }
    })
    .catch(function (error: any) {
      console.log(error);
    });
  },
}

export const updatemixed = {
  async update(data: any) {
    await axios.post('http://test-pet.mipoko.com/pet_api/1/updatemixed', data)
    .then(function (response: any){
      // if(response.data.result === false){
        console.log(response.data);
        // console.log(response.data.error);
      // }
    })
    .catch(function (error: any) {
      console.log(error);
    });
  },
};


export const speciestorage = {
  importData() {
    let speciesData: string[] = ['貓', '狗', '鼠類', '外星生命', '蝸科'];
    return speciesData;
  },
};
export const breedstorage = {
  //這裡要抓上面的屬性來打，或是一開始都先拉完也是種選擇
  importData() {
    let breedsData: string[] = ['柴犬', '土狗', '臘腸狗', '黃金獵犬', '柯基'];
    return breedsData;
  },
};

export const caseHistoryData = {
  getData() {
    let caseHistoryData: Global.CaseHistory = {
      healthRecord: [
        {title:'絕育紀錄', content:'已絕育', date:'2021-01-01'},
        {title:'每年度預防注射', content:'基礎注射進行中', date:'2021-01-01'},
        {title:'狂犬病預防注射', content:'逾時未注射', date:'2021-01-01'},
        {title:'心絲蟲預防', content:'準時外用滴劑', date:'2021-01-01'},
        {title:'外寄生蟲預防', content:'不定時預防', date:'2021-01-01'},
        {title:'內寄生蟲預防', content:'', date:''}
      ],
      allergy: {
        checked: true,
        content: ''
      },
      disease: {
        checked: false,
        content: 'xxxxxx'
      }
    }
    return caseHistoryData;
  },
};

export const medicalRecordData = {
  getData() {
    let medicalRecordData: Global.MedicalRecord = {
      vaccinated :[
        {date:'2020/10/28',content:'心絲蟲預防',times: 1, timeLeft: 2}
      ],
      bookedVaccine:[
        {date:'2020/10/28',content:'心絲蟲預防',times: 1, timeLeft: 2}
      ],
      physical:[
        {date:'2020/10/28',content:'心絲蟲預防',times: 1, timeLeft: 2}
      ]
    }
    return medicalRecordData;
  }
}
