import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Form, Input, Button, Alert, Checkbox, Result, message, Space,
  Modal, Typography, Tabs, Select, Popconfirm, Table, Empty, Spin,Tooltip
} from 'antd';
import { useApp } from "../overmind";
import { UserInfo } from "../global";
import { Static } from "../_/Static";
import { ApiService } from "../_/ApiService";
import _ from "../_";
import { ReloadOutlined, EyeTwoTone, UserOutlined, LockOutlined, RedoOutlined } from '@ant-design/icons';

import {
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import UsePageDataManager from '../hooks/UsePageDataManager';
import UseSessionManager from "../hooks/UseSessionManager";
import UseLoadingManager from "../hooks/UseLoadingManager";
import UseCompState from "../hooks/UseCompState";
import { lastValueFrom } from "rxjs";
import Partial_Appointment__Modal_AddOwner from "./Partial_Appointment__Modal_AddOwner";
import Partial_Appointment__Modal_AddPet from "./Partial_Appointment__Modal_AddPet";
import Partial_Modal_Search from "./Partial_Appointment__Modal_Search";
import Partial_inHospital__Modal_ViewCard from "./Partial_inHospital__Modal_ViewCard";
import Partial_inHospital__Modal_ListRoom from "./Partial_inHospital__Modal_ListRoom";

import './Pg_Waitingroom.scss';
import FunctionBtn from './Box_Functionbtn';
import { StaticEvent } from "../_/StaticEvent";


const iconLighting = require('../assets/images/icon/lighting.svg');
const iconWatting = require('../assets/images/icon/new-medical-record.svg');

const onSearch = value => console.log(value);
const { Title } = Typography;
const { TabPane } = Tabs;
const { Search } = Input;
const { Option } = Select;


type Props = {
  PrefixTarget?: string;
  RoutePathname?: string;
  MenuTemplateId?: string;
  parentState?: any;
};
export default function Pg_Cosmetic(props: Props) {
  let PrefixTarget: string = props.PrefixTarget || 'Root';
  let RoutePathname = props.RoutePathname;
  let MenuTemplateId = props.MenuTemplateId;

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  //#region ForceRender Control
  const [, setForceRender] = useState(false);
  const forceRender = () => {
    setForceRender(s => !s);
  };
  //#endregion

  //#region :Popup :createModalEditRoom_refModal
  const createModalEditRoom_refModal = useRef<any>(null);
  const createModalEditRoom = (e: any, tasks?: string[]): void => {
    if (e) {
      e.preventDefault();
    }
    // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
    //
    // if (!PassPageDataInitalized) {
    //     //empty
    // }

    const config = {
      // title: 'test',
      content: <Partial_inHospital__Modal_ListRoom
        PrefixTarget={'Partial_inHospital__Modal_ListRoom'}
        pageDataManager={pageDataManager}
        sessionManager={sessionManager}
        navigate={navigate}

        viewModel={{
          refModal: createModalEditRoom_refModal,
        }}
      />,
      okButtonProps: { disabled: true, className: "modal-footer-hiden-button" },
      className: 'myClass myModal',
      width: '70em',
      height: ' ',
      footer: null,
      //prefixCls: '',
    };

    createModalEditRoom_refModal.current = Modal.info(config);
  }
  //#endregion

  //#region :Popup :createModalRoomDetail_refModal
  const createModalRoomDetail_refModal = useRef<any>(null);
  const createModalRoomDetail = (e: any, tasks?: string[]): void => {
    if (e) {
      e.preventDefault();
    }
    // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
    //
    // if (!PassPageDataInitalized) {
    //     //empty
    // }

    const config = {
      // title: 'test',
      content: <Partial_inHospital__Modal_ViewCard
        PrefixTarget={'Partial_inHospital__Modal_ViewCard'}
        pageDataManager={pageDataManager}
        sessionManager={sessionManager}
        navigate={navigate}

        viewModel={{
          refModal: createModalRoomDetail_refModal,
          住院籠子No: 1,
        }}
      />,
      okButtonProps: { disabled: true, className: "modal-footer-hiden-button" },
      className: 'myClass myModal',
      width: '60em',
      height: ' ',
      footer: null,
      //prefixCls: '',
    };

    createModalRoomDetail_refModal.current = Modal.info(config);
  }
  //#endregion

  //#region LoadingManager
  const loadingManager = UseLoadingManager({ shouldReRenderOnLoading: true });
  //#endregion

//#region PageDataManager
  const refDoLoadPageData = Static.refDoLoadPageData;
  let pageDataManager = UsePageDataManager({
    refPageLoad: refDoLoadPageData
  });
  //#endregion

  //#region SessionManager
  let sessionManager = UseSessionManager({
    pageDataManager: pageDataManager,
  });
  //#endregion


  //#region Boot
  const state = UseCompState({ parentState: props.parentState });

  let defaultS = '全部';
  let defaultQ = '';

  if (!state.hasInit) {
    state.hasInit = true;

    state.pageDataManager = pageDataManager;
    state.sessionManager = sessionManager;
    state.loadingManager = loadingManager;
    state.navigate = navigate;
    state.forceRender = forceRender;

    state.subscribed = [];

    state.s = pageDataManager.get(`${PrefixTarget}.s`) ?? defaultS;
    state.q = pageDataManager.get(`${PrefixTarget}.q`) ?? defaultQ;
  }

  useEffect(() => {
    const _loadPageData = async (): Promise<void> => {
      let hide = message.loading({
        type: 'loading',
        class: 'myMessage',
        content: <div className="myMessageBody">搜尋中...</div>,
        key: 'loading',
        duration: 0
      });

      // VALIDATE INPUT
      //const id : any = this.props.match.params.id;
      // const id = 1;
      // if (!id) {
      //     return; //ERROR INPUTS
      // }

      // WAITING SCREEN
      loadingManager.changeLoadingCount(+1);
      //StaticEvent.LoadingEvent.next(true);

      // POST
      let postData = {
        //p1: 1,
        s: state.s,
        q: state.q,
        timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
      };
      const postUrl = ApiService.urlMapping('~/pet_api/1/pg_waitingroom');
      const post$ = Static.apiService.post(postUrl, postData);
      post$.subscribe(async (res: any) => {
        hide();

        // CLOSE WAITING SCREEN
        loadingManager.changeLoadingCount(-1);
        //StaticEvent.LoadingEvent.next(false);

        let data = res.data;
        let error = data.error || null;
        let result = error == null;

        if (result) { // SUCEESS
          let payload = data.payload;
          _._debug.warn(payload);

          pageDataManager.emptyZone(PrefixTarget);
          pageDataManager.merge({
            ...payload,
          });
          pageDataManager.apply();

          pageDataManager.forceRender();
        } else {
          // AUTH USER EXPIRED T:1
          if (error.code == 2) {
            await sessionManager.setLogout();
            Static.NavAwayToLogin(pageDataManager, navigate)(null);
            return;
          }

          _._debug.error(error);
          alert(error.message);
        }
      }, async (err) => {
        // CLOSE WAITING SCREEN
        loadingManager.changeLoadingCount(-1);
        //StaticEvent.LoadingEvent.next(false);
        _._debug.warn(err);
        alert('網路連線有問題');
      }
      );
      return lastValueFrom(
        post$
      );
    }
    const doLoadPageData = async ({alwaysLoad = false}): Promise<void> => {
      if (!alwaysLoad) {
        pageDataManager.restore();
        if (!pageDataManager.isZoneExist(PrefixTarget)) {
          await sessionManager.initSessionAuthAsync();
          //#region User Check //TODO: VERTIFY
          if (!sessionManager.isMemberLoggedIn()) {
            Static.NavAwayToLogin(pageDataManager, navigate)(null);
            return;
          }
          //#endregion
          await _loadPageData();
        } else {
          forceRender()
        }
      } else {
        await _loadPageData();
      }
    }

    refDoLoadPageData.current = doLoadPageData;
    refDoLoadPageData.current({});
  }, [location.pathname]);
  //#endregion

  const segmentation = {
    'grid-template-columns': '4'

  }
  const [noCase, setNoCase] = useState(false);

  function callback(key) {
    console.log(key);
  }


  function confirm(e) {
    console.log(e);
  }

  function cancel(e) {
    console.log(e)
  }

  const info = () => {
    message.info('此籠子為空的，沒有任何寵物入住！');
  };




  return (
    <div className="page-appointment page-content">
      <div className="info-list">
        <div className="info-box">
          <Title level={2}>美容</Title>
          <div className="pluginfobox">
            <div className="title">
            <Tooltip title="刷新">
                <Button icon={<ReloadOutlined />} shape="circle" onClick={(e) => {
                  ///reload
                  if (e) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                  pageDataManager.clear();
                  pageDataManager.reloadAsync({alwaysLoad: true});
                }}/>
              </Tooltip>
            </div>
            <div className="info"></div>
          </div>

          <div className="pluginfobox">
            <div className="title"></div>
            <div className="info"></div>
          </div>

        </div>
        <div className="function-box">
          <button onClick={createModalEditRoom}>
            <FunctionBtn iconlink={iconWatting} name="編輯區域及籠數" />
          </button>
        </div>
      </div>


      <div className="page-waitingroom">
        <Tabs type="card">
          <TabPane tab="區域1" key="1">
            <div className="patient-list">
              <div className="grid-layout t_orange" style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr" }}>
                <div className="roombox" onClick={createModalRoomDetail}>
                  <div className="titlebar">
                    <div className="txtgp">
                      <div className="txt">區域：<span>1</span></div>
                      <div className="txt">籠號：<span>1</span></div>
                    </div>
                    <div className="txt"><span>姚昱凱</span></div>
                  </div>
                  <div className="inforbar">
                    <div className="txt">住院期間：02.01 - 02.03</div>
                    <div className="txt">剩餘<span>2</span>天</div>
                  </div>
                  <div className="maincontent">
                    <div className="incol">
                      <div className="avatar">
                        <img src="https://www.northogdencity.com/sites/default/files/imageattachments/police/page/1971/golden-retriever-puppy.jpg" alt="" />
                      </div>
                      <div className="maininfo">
                        <div className="con">烏拉其<span>．</span>狗</div>
                        <div className="detail">
                          <div className="info"><span>年齡：</span> 3歲5個月</div>
                          <div className="info"><span>體重：</span> 12Kg</div>
                        </div>
                        <div className="title">
                          <div className="txt">郭明裕</div>
                          <div className="ms"><a href="tel:0922333221">0922333221</a> </div>
                        </div>
                      </div>
                    </div>
                    <div className="infobar"><span>[住院原因]</span> 嘔吐、皮膚養</div>
                  </div>
                </div>
                <div className="roombox" onClick={info}>
                  <div className="titlebar">
                    <div className="txtgp">
                      <div className="txt">區域：<span>1</span></div>
                      <div className="txt">籠號：<span>2</span></div>
                    </div>
                    <div className="txt"><span>--</span></div>
                  </div>
                  <div className="inforbar">
                    <div className="txt">住院期間：</div>
                    <div className="txt">剩餘<span>--</span>天</div>
                  </div>
                  <div className="maincontent">
                    <div className="incol">
                      <div className="maininfo">
                        <div className="empty">尚未加入</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="roombox" onClick={createModalRoomDetail}>
                  <div className="titlebar">
                    <div className="txtgp">
                      <div className="txt">區域：<span>1</span></div>
                      <div className="txt">籠號：<span>3</span></div>
                    </div>
                    <div className="txt"><span>姚昱凱</span></div>
                  </div>
                  <div className="inforbar">
                    <div className="txt">住院期間：02.01 - 02.03</div>
                    <div className="txt">剩餘<span>2</span>天</div>
                  </div>

                  <div className="maincontent">
                    <div className="incol">
                      <div className="avatar">
                        <img src="https://www.northogdencity.com/sites/default/files/imageattachments/police/page/1971/golden-retriever-puppy.jpg" alt="" />
                      </div>
                      <div className="maininfo">
                        <div className="con">烏拉其<span>．</span>狗</div>
                        <div className="detail">
                          <div className="info"><span>年齡：</span> 3歲5個月</div>
                          <div className="info"><span>體重：</span> 12Kg</div>
                        </div>
                        <div className="title">
                          <div className="txt">郭明裕</div>
                          <div className="ms"><a href="tel:0922333221">0922333221</a> </div>
                        </div>
                      </div>
                    </div>
                    <div className="infobar"><span>[住院原因]</span> 嘔吐、皮膚養</div>
                  </div>
                </div>

                <div className="roombox" onClick={createModalRoomDetail}>
                  <div className="titlebar">
                    <div className="txtgp">
                      <div className="txt">區域：<span>1</span></div>
                      <div className="txt">籠號：<span>4</span></div>
                    </div>
                    <div className="txt"><span>姚昱凱</span></div>
                  </div>
                  <div className="inforbar">
                    <div className="txt">住院期間：02.01 - 02.03</div>
                    <div className="txt">剩餘<span>2</span>天</div>
                  </div>
                  <div className="maincontent">
                    <div className="incol">
                      <div className="avatar">
                        <img src="https://www.northogdencity.com/sites/default/files/imageattachments/police/page/1971/golden-retriever-puppy.jpg" alt="" />
                      </div>
                      <div className="maininfo">
                        <div className="con">烏拉其<span>．</span>狗</div>
                        <div className="detail">
                          <div className="info"><span>年齡：</span> 3歲5個月</div>
                          <div className="info"><span>體重：</span> 12Kg</div>
                        </div>
                        <div className="title">
                          <div className="txt">郭明裕</div>
                          <div className="ms"><a href="tel:0922333221">0922333221</a> </div>
                        </div>
                      </div>
                    </div>
                    <div className="infobar"><span>[住院原因]</span> 嘔吐、皮膚養</div>
                  </div>
                </div>

                <div className="roombox" onClick={createModalRoomDetail}>
                  <div className="titlebar">
                    <div className="txtgp">
                      <div className="txt">區域：<span>1</span></div>
                      <div className="txt">籠號：<span>5</span></div>
                    </div>
                    <div className="txt"><span>姚昱凱</span></div>
                  </div>
                  <div className="inforbar">
                    <div className="txt">住院期間：02.01 - 02.03</div>
                    <div className="txt">剩餘<span>2</span>天</div>
                  </div>
                  <div className="maincontent">
                    <div className="incol">
                      <div className="avatar">
                        <img src="https://www.northogdencity.com/sites/default/files/imageattachments/police/page/1971/golden-retriever-puppy.jpg" alt="" />
                      </div>
                      <div className="maininfo">
                        <div className="con">烏拉其<span>．</span>狗</div>
                        <div className="detail">
                          <div className="info"><span>年齡：</span> 3歲5個月</div>
                          <div className="info"><span>體重：</span> 12Kg</div>
                        </div>
                        <div className="title">
                          <div className="txt">郭明裕</div>
                          <div className="ms"><a href="tel:0922333221">0922333221</a> </div>
                        </div>
                      </div>
                    </div>
                    <div className="infobar"><span>[住院原因]</span> 嘔吐、皮膚養</div>
                  </div>
                </div>

                <div className="roombox" onClick={createModalRoomDetail}>
                  <div className="titlebar">
                    <div className="txtgp">
                      <div className="txt">區域：<span>1</span></div>
                      <div className="txt">籠號：<span>6</span></div>
                    </div>
                    <div className="txt"><span>姚昱凱</span></div>
                  </div>
                  <div className="inforbar">
                    <div className="txt">住院期間：02.01 - 02.03</div>
                    <div className="txt">剩餘<span>2</span>天</div>
                  </div>
                  <div className="maincontent">
                    <div className="incol">
                      <div className="avatar">
                        <img src="https://www.northogdencity.com/sites/default/files/imageattachments/police/page/1971/golden-retriever-puppy.jpg" alt="" />
                      </div>
                      <div className="maininfo">
                        <div className="con">烏拉其<span>．</span>狗</div>
                        <div className="detail">
                          <div className="info"><span>年齡：</span> 3歲5個月</div>
                          <div className="info"><span>體重：</span> 12Kg</div>
                        </div>
                        <div className="title">
                          <div className="txt">郭明裕</div>
                          <div className="ms"><a href="tel:0922333221">0922333221</a> </div>
                        </div>
                      </div>
                    </div>
                    <div className="infobar"><span>[住院原因]</span> 嘔吐、皮膚養</div>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
          {/* <TabPane tab="區域2" key="2">
            <div className="patient-list">
              Content of Tab Pane 2
            </div>
          </TabPane>
          <TabPane tab="區域3" key="3">
            <div className="patient-list">
              Content of Tab Pane 3
            </div>
          </TabPane> */}
        </Tabs>
      </div>
    </div>

  )
}

Pg_Cosmetic.displayName = 'Pg_Cosmetic';




























