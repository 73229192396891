import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {Form as NForm, Popover, Button, Modal, message, Upload, Input, Space, Select, Collapse, Checkbox} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';
import ImgCrop from 'antd-img-crop';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";

import {
    LoadingOutlined,
    LockOutlined,
    PlusOutlined,
    RedoOutlined,
    UserOutlined,
    EditOutlined,
    UploadOutlined,
    DeleteFilled
} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";
import UseCompState from "../hooks/UseCompState";

import Ln_PictureImgHref from "./Ln_PictureImgHref";

var moment = require('moment');
const {Option} = Select;
const {Panel} = Collapse;
const dateFormat = 'YYYY/MM/DD';

const {TextArea} = Input;

type ViewModelProps = {
    refModal: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any,

    viewModel: ViewModelProps,
};

export default function Partial_Manage_Agency__Pane_EditOrAddAgency(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_Manage_Agency__Pane_EditOrAddAgency';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel = props.viewModel;

    const layout = {
        labelCol: {span: 0},
        wrapperCol: {span: 25},
    };

    const layout02 = {
        labelCol: {span: 0},
        wrapperCol: {span: 25},
    };

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

    }
    //#endregion

    //#region Boot
    let _refDoLoadPartialData: any = useRef<{ alwaysLoad?: boolean }>(null);
    useEffect(() => {
        const _loadPartialData = async (): Promise<void> => {

            // VALIDATE INPUT
            //const id : any = this.props.match.params.id;
            // const id = param_會員Id;
            // if (!id) {
            //     return; //ERROR INPUTS
            // }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                //p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/partial_modalbody_editagency');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        //delete pageDataManager.refPageData.current[TabControlState]?.['MyCollectionTab_data'];

                        pageDataManager.emptyZone(PrefixTarget);
                        let changeShard = {
                            [PrefixTarget]: {
                                ...payload,
                            }
                        }
                        pageDataManager.merge(changeShard);
                        pageDataManager.apply();

                        forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPartialData = async ({alwaysLoad = false}): Promise<void> => {
            // if (!pageDataManager.refPageData.current.Root) {
            //     return;
            // }

            /// do not dynamically load data if condition fits.
            if (!alwaysLoad) {
                if (pageDataManager.isZoneExist(PrefixTarget)) {
                    return;
                }
            }

            // if (pageDataManager.refPageData.current[TabControlState].activeTabName === 'RecentReadTab') {
            //     await _loadRecentReadTabData();
            // }

            await _loadPartialData();
        }
        if (_refDoLoadPartialData.current === null) {
            _refDoLoadPartialData.current = doLoadPartialData;
            _refDoLoadPartialData.current({});
        } else {
            _refDoLoadPartialData.current({});
        }
    }, [pageDataManager.get(PrefixTarget)]);
    //#endregion

    //#region Set Body
    const isModalContained = () => {
        return !!viewModel.refModal?.current;
    }

    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };

    const Body = () => {
        const RegionEmptyShow = true;
        const RegionLoadingShow = true;

        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;

        let Item_醫院 = pageDataManager.get(`${PrefixTarget}.Item_醫院`);
        let its: any;
        if (Item_醫院 === null) { //convert from single to multiple. Item_號 may be specified null, a valid empty state
            its = null;
        } else if (Item_醫院 !== undefined) {
            its = [Item_醫院];
        }

        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            // if (!values.ownername) {
            //     errors.ownername = '↑ 欄位為必填';
            // } else {
            // }
            //
            // if (!values.cellphone) {
            //     errors.cellphone = '↑ 欄位為必填';
            // } else {
            // }
            return errors;
        };

        const handleSubmit_save = (e: any, v: any) => {
            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            //const id : any = this.props.match.params.id;

            const p1 = v;
            if (!p1) {
                _._debug.error(p1);
                return; //ERROR INPUTS
            }


            // let pictures = [] as any;
            // state.files.map((v, i) => {
            //     console.log(v)
            //     pictures.push(v.id);
            // });
            // const p2 = pictures.join(',');
            // if (!p2) {
            //     _.debug.error(p1);
            //     return; //ERROR INPUTS
            // }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_modalbody_editagency');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);


                        let hide = message.loading({
                            type: 'loading',
                            className: 'myMessage',
                            content: <div className="myMessageBody">修改完成</div>,
                            key: 'loading',
                            duration: 5
                        });

                        handleClose(null);
                        hide();


                        // let carryData = _._u.update(payload,
                        //     {
                        //         $merge: {
                        //             Tokens: payload?.Tokens,
                        //         },
                        //         Item_飼主: {
                        //             $set: payload?.Item_飼主,
                        //         }
                        //     }
                        // );
                        // carryData.Item_飼主 = p1;
                        // if (onDone) {
                        //     const ret = onDone(carryData);
                        //     if (ret === 'demo') {
                        //         createModalPet(null, {
                        //             carryData,
                        //         })
                        //     }
                        // } else {
                        //     alert('onDone not defined');
                        // }

                        //handleClose(null)

                        //_refDoLoadPartialData.current({alwaysLoad: true});
                        //pageDataManager.emptyZone('Root');
                        pageDataManager.clear();
                        pageDataManager.apply();
                        pageDataManager.forceRender();

                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }

        //#region :Comp nsUploader
        if (!state.nsUploader) {
            const obj: any = state.nsUploader = {};
            obj.getBase64 = (file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = error => reject(error);
                });
            }

            obj.uploadButton = ({text}) => (
                <div>
                    {false ? <LoadingOutlined/> : <PlusOutlined/>}
                    <div style={{marginTop: 8}}>{text}</div>
                </div>
            );

            // obj.handleCancel = () => {
            //     zoneState.state = {
            //         ...zoneState.state,
            //         ...{previewVisible: false}
            //     };
            // }

            obj.beforeUpload = (file) => {
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJpgOrPng) {
                    message.error('You can only upload JPG/PNG file!');
                }
                const isLt2M = file.size / 1024 / 1024 < 20;
                if (!isLt2M) {
                    message.error('Image must smaller than 20MB!');
                }
                return isJpgOrPng && isLt2M;
            }

            // obj.previewFile= (file) => {
            //     debugger;
            // };

            obj.handlePreview = async file => {
                let urls = obj.compState.files.filter(r => r.id != file.id).map(r => ApiService.urlImageMapping("~/" + r.url));
                urls.unshift(ApiService.urlImageMapping("~/" + file.url));
                Static.App.setImagesAndOpen(urls);

                //
                // if (!file.url && !file.preview) {
                //     file.preview = await getBase64(file.originFileObj);
                // }
                //
                // obj.compState = {
                //     ...obj.compState,
                //     ...{
                //         previewImage: file.url || file.preview,
                //         previewVisible: true,
                //         previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
                //     }
                // };

            };


            obj.previewFile = async (file) => {
                let src = file.url;
                if (!src) {
                    src = await new Promise(resolve => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file.originFileObj);
                        reader.onload = () => resolve(reader.result);
                    });
                }
                const image = new Image();
                image.src = src;
                const imgWindow = window.open(src);
                if (imgWindow) {
                    imgWindow.document.write(image.outerHTML);
                } else {
                    window.location.href = src;
                }
            };

            obj.handleChange = ({fileList, wrapperOnChange, ...rest}) => {
                obj.compState = {
                    ...obj.compState,
                    ...{
                        fileList
                    }
                };

                //debugger;

                try {
                    if (rest.file.status === 'done') {
                        if (rest.file.response.error) {
                            throw rest.file.response.error;
                        }

                        let serverFileInfo = rest.file.response.files[0];
                        let antFileInfo = {
                            url: serverFileInfo.url,
                            filename: serverFileInfo.name,
                            size: serverFileInfo.size,
                            id: serverFileInfo.id,
                            type: serverFileInfo.type,
                            thumbUrl: serverFileInfo.url,
                        };
                        obj.compState.files = [...obj.compState.files, antFileInfo];
                        //Object.assign(fileList.filter(r=>r.uid == rest.file.uid)[0], antFileInfo);
                        //forceRender();
                        fileList = fileList.map(file => {
                            if (file.response) {
                                // Component will show file.url as link
                                file.url = file.response?.files?.[0]?.url;
                            }
                            return file;
                        });

                        wrapperOnChange(obj.compState.files.map(r => r.id).join(','));
                    }

                    if (rest.file.status === 'removed') {
                        obj.compState.files = obj.compState.files.filter(item => item.id != rest.file.id)
                        wrapperOnChange(obj.compState.files.map(r => r.id).join(','));
                    }
                } catch (error) {
                    // AUTH USER EXPIRED T:2
                    if (error.code == 2) {
                        (async () => {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                        })();
                    } else {
                        alert(error.message);
                    }
                }

                forceRender();
            }

            obj.addData = (file) => {
                return {a: '123'};
            };


            let compState: any = obj.compState = {};
            compState = Object.assign(compState, {
                files: [],
                previewVisible: false,
                previewImage: '',
                previewTitle: '',
                hasSetFileList: false,
                fileList: [
                    // {
                    //     uid: '-1',
                    //     name: 'image.png',
                    //     status: 'done',
                    //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                    // },
                    // {
                    //     uid: '-2',
                    //     name: 'image.png',
                    //     status: 'done',
                    //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                    // },
                    // {
                    //     uid: '-3',
                    //     name: 'image.png',
                    //     status: 'done',
                    //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                    // },
                    // {
                    //     uid: '-4',
                    //     name: 'image.png',
                    //     status: 'done',
                    //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                    // },
                    // {
                    //     uid: '-xxx',
                    //     percent: 50,
                    //     name: 'image.png',
                    //     status: 'uploading',
                    //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                    // },
                    // {
                    //     uid: '-5',
                    //     name: 'image.png',
                    //     status: 'error',
                    // },
                ] as any,
            });


            // if (viewModel.carryData) {
            //     if (viewModel.carryData.type == 'pictures') {
            //         //0: {url: "/FileTransferHandler.ashx?f=4915a7dd3f1c420b9175f38c2a76e4ac.jpg", filename: "4915a7dd3f1c420b9175f38c2a76e4ac.jpg", size: 159626, id: "/Upload/4915a7dd3f1c420b9175f38c2a76e4ac.jpg", type: "image/jpeg"}
            //
            //
            //         let count = 0;
            //         let files: any = [];
            //         state.files = viewModel.carryData.data.pictures;
            //         zoneState.state.fileList = state.files.map((it) => {
            //             count++;
            //
            //             // minimum requirement for antFileInfos [
            //             //     {
            //             //         uid: '-1',
            //             //         name: 'image.png',
            //             //         status: 'done',
            //             //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
            //             //     },
            //             // ];
            //
            //             let antFileInfo = {
            //                 uid: it.id,
            //                 status: 'done',
            //
            //                 url: it.url,
            //                 name: it.filename,
            //                 size: it.size,
            //                 id: it.id,
            //                 type: it.type,
            //             };
            //             return antFileInfo;
            //         });
            //     }
            // }
        }
        //#endregion

        //#region :Comp nsUploader2 :VER-1
        if (!state.nsUploader2) {
            const obj: any = state.nsUploader2 = {};
            obj.getBase64 = (file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = error => reject(error);
                });
            }

            obj.uploadButton = ({text}) => (
                <div>
                    {false ? <LoadingOutlined/> : <PlusOutlined/>}
                    <div style={{marginTop: 8}}>{text}</div>
                </div>
            );

            // obj.handleCancel = () => {
            //     zoneState.state = {
            //         ...zoneState.state,
            //         ...{previewVisible: false}
            //     };
            // }

            obj.beforeUpload = (file) => {
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJpgOrPng) {
                    message.error('You can only upload JPG/PNG file!');
                }
                const isLt2M = file.size / 1024 / 1024 < 20;
                if (!isLt2M) {
                    message.error('Image must smaller than 20MB!');
                }
                return isJpgOrPng && isLt2M;
            }

            // obj.previewFile= (file) => {
            //     debugger;
            // };

            obj.handlePreview = async file => {
                let urls = obj.compState.files.filter(r => r.id != file.id).map(r => ApiService.urlImageMapping("~/" + r.url));
                urls.unshift(ApiService.urlImageMapping("~/" + file.url));
                Static.App.setImagesAndOpen(urls);

                //
                // if (!file.url && !file.preview) {
                //     file.preview = await getBase64(file.originFileObj);
                // }
                //
                // obj.compState = {
                //     ...obj.compState,
                //     ...{
                //         previewImage: file.url || file.preview,
                //         previewVisible: true,
                //         previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
                //     }
                // };

                // const onPreview = async file => {
                //     let src = file.url;
                //     if (!src) {
                //         src = await new Promise(resolve => {
                //             const reader = new FileReader();
                //             reader.readAsDataURL(file.originFileObj);
                //             reader.onload = () => resolve(reader.result);
                //         });
                //     }
                //     const image = new Image();
                //     image.src = src;
                //     const imgWindow = window.open(src);
                //     if (imgWindow) {
                //         imgWindow.document.write(image.outerHTML);
                //     } else {
                //         window.location.href = src;
                //     }
                // };
            };

            obj.handleChange = ({fileList, wrapperOnChange, ...rest}) => {
                obj.compState = {
                    ...obj.compState,
                    ...{
                        fileList
                    }
                };

                //debugger;

                try {
                    if (rest.file.status === 'done') {
                        if (rest.file.response.error) {
                            throw rest.file.response.error;
                        }

                        let serverFileInfo = rest.file.response.files[0];
                        let antFileInfo = {
                            url: serverFileInfo.url,
                            filename: serverFileInfo.name,
                            size: serverFileInfo.size,
                            id: serverFileInfo.id,
                            type: serverFileInfo.type,
                            thumbUrl: serverFileInfo.url,
                        };
                        obj.compState.files = [...obj.compState.files, antFileInfo];
                        //Object.assign(fileList.filter(r=>r.uid == rest.file.uid)[0], antFileInfo);
                        //forceRender();
                        fileList = fileList.map(file => {
                            if (file.response) {
                                // Component will show file.url as link
                                file.url = file.response?.files?.[0]?.url;
                            }
                            return file;
                        });

                        wrapperOnChange(obj.compState.files.map(r => r.id).join(','));
                    }

                    if (rest.file.status === 'removed') {
                        obj.compState.files = obj.compState.files.filter(item => item.id != rest.file.id)
                        wrapperOnChange(obj.compState.files.map(r => r.id).join(','));
                    }
                } catch (error) {
                    // AUTH USER EXPIRED T:2
                    if (error.code == 2) {
                        (async () => {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                        })();
                    } else {
                        alert(error.message);
                    }
                }

                forceRender();
            }

            obj.addData = (file) => {
                return {a: '123'};
            };


            let compState: any = obj.compState = {};
            compState = Object.assign(compState, {
                files: [],
                previewVisible: false,
                previewImage: '',
                previewTitle: '',
                hasSetFileList: false,
                fileList: [
                    // {
                    //     uid: '-1',
                    //     name: 'image.png',
                    //     status: 'done',
                    //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                    // },
                    // {
                    //     uid: '-2',
                    //     name: 'image.png',
                    //     status: 'done',
                    //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                    // },
                    // {
                    //     uid: '-3',
                    //     name: 'image.png',
                    //     status: 'done',
                    //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                    // },
                    // {
                    //     uid: '-4',
                    //     name: 'image.png',
                    //     status: 'done',
                    //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                    // },
                    // {
                    //     uid: '-xxx',
                    //     percent: 50,
                    //     name: 'image.png',
                    //     status: 'uploading',
                    //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                    // },
                    // {
                    //     uid: '-5',
                    //     name: 'image.png',
                    //     status: 'error',
                    // },
                ] as any,
            });


            // if (viewModel.carryData) {
            //     if (viewModel.carryData.type == 'pictures') {
            //         //0: {url: "/FileTransferHandler.ashx?f=4915a7dd3f1c420b9175f38c2a76e4ac.jpg", filename: "4915a7dd3f1c420b9175f38c2a76e4ac.jpg", size: 159626, id: "/Upload/4915a7dd3f1c420b9175f38c2a76e4ac.jpg", type: "image/jpeg"}
            //
            //
            //         let count = 0;
            //         let files: any = [];
            //         state.files = viewModel.carryData.data.pictures;
            //         zoneState.state.fileList = state.files.map((it) => {
            //             count++;
            //
            //             // minimum requirement for antFileInfos [
            //             //     {
            //             //         uid: '-1',
            //             //         name: 'image.png',
            //             //         status: 'done',
            //             //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
            //             //     },
            //             // ];
            //
            //             let antFileInfo = {
            //                 uid: it.id,
            //                 status: 'done',
            //
            //                 url: it.url,
            //                 name: it.filename,
            //                 size: it.size,
            //                 id: it.id,
            //                 type: it.type,
            //             };
            //             return antFileInfo;
            //         });
            //     }
            // }
        }
        //#endregion

        if (!PassPrefixTargetLoaded) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <div>Loading.</div>
                    </div>
                </React.Fragment>;
            }
            else {
                return null;
            }
        }

        if (its && its.length > 0) {
            return <React.Fragment>
                {its.map(Item_醫院 => {

                    return <React.Fragment key={Item_醫院.醫院No ?? "0"}>
                        <Form
                            onSubmit={(v) => handleSubmit_save(null, v)}
                            initialValues={{
                                ...Item_醫院
                            }}
                            validate={handleValidate}
                            render={({
                                         submitError,
                                         handleSubmit,
                                         submitting,
                                         pristine,
                                         valid,
                                         error,
                                         values
                                     }) => {

                                return <React.Fragment>
                                    <form
                                        onSubmit={handleSubmit}
                                        {...layout}>
                                        <div className="page-agency formintable">
                                            <div className="vt-page">
                                                <div className="vt-col ">

                                                    <div className="infoboxoutside vs_scroll">
                                                        <div className="infobox">
                                                            <div className="titlebar">
                                                                <h4>基本資料</h4>
                                                                <div className="barcontent"></div>
                                                                <div className="func">

                                                                </div>
                                                            </div>
                                                            <div className="boxcontent">
                                                                <div className="systable col_4">
                                                                    <div className="str cops">
                                                                        <div className="th">院所名稱</div>
                                                                        <div className="td">
                                                                            <Field parse={_.bs.identity} name='院所名稱'>
                                                                                {
                                                                                    ({input, meta}) => (
                                                                                        <React.Fragment>
                                                                                            <NForm.Item {...layout}
                                                                                                        name="agencyname"
                                                                                            >
                                                                                                <Input {...input}
                                                                                                       placeholder="請輸入中英文"/>
                                                                                                {meta.touched && meta.error &&
                                                                                                <div className="error">
                                                                                                    {meta.error}
                                                                                                </div>}
                                                                                            </NForm.Item>
                                                                                        </React.Fragment>
                                                                                    )
                                                                                }
                                                                            </Field>
                                                                        </div>
                                                                    </div>
                                                                    <div className="str cops">
                                                                        <div className="th">院所電話</div>
                                                                        <div className="td">
                                                                            <Field parse={_.bs.identity} name='院所電話'>
                                                                                {
                                                                                    ({input, meta}) => (
                                                                                        <React.Fragment>
                                                                                            <NForm.Item  {...layout}
                                                                                                         name="agencytel"
                                                                                            >
                                                                                                <Input {...input}
                                                                                                       placeholder="請輸入數字"/>
                                                                                                {meta.touched && meta.error &&
                                                                                                <div className="error">
                                                                                                    {meta.error}
                                                                                                </div>}
                                                                                            </NForm.Item>
                                                                                        </React.Fragment>
                                                                                    )
                                                                                }
                                                                            </Field>
                                                                        </div>
                                                                    </div>
                                                                    <div className="str cops">
                                                                        <div className="th">院所地址</div>
                                                                        <div className="td">
                                                                            <Input.Group compact className='flex_h'>
                                                                                <Field parse={_.bs.identity}
                                                                                       name='院所地址_城市'>
                                                                                    {
                                                                                        ({input, meta}) => (
                                                                                            <React.Fragment>
                                                                                                <NForm.Item name="city"
                                                                                                            style={{width: 110}}
                                                                                                >
                                                                                                    <Select {...input}
                                                                                                            placeholder="縣市"
                                                                                                            style={{width: 110}}>
                                                                                                        <Option
                                                                                                            value="基隆市">基隆市</Option>
                                                                                                        <Option
                                                                                                            value="台北市">台北市</Option>
                                                                                                        <Option
                                                                                                            value="新北市">新北市</Option>
                                                                                                    </Select>
                                                                                                    {meta.touched && meta.error &&
                                                                                                    <div
                                                                                                        className="error">
                                                                                                        {meta.error}
                                                                                                    </div>}
                                                                                                </NForm.Item>
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    }
                                                                                </Field>
                                                                                <Field parse={_.bs.identity}
                                                                                       name='院所地址_區域'>
                                                                                    {
                                                                                        ({input, meta}) => (
                                                                                            <React.Fragment>
                                                                                                <NForm.Item
                                                                                                    name="district"
                                                                                                    style={{width: 110}}
                                                                                                >
                                                                                                    <Select {...input}
                                                                                                            placeholder="行政區">
                                                                                                        <Option
                                                                                                            value="大安區">大安區</Option>
                                                                                                        <Option
                                                                                                            value="板橋區">板橋區</Option>
                                                                                                        <Option
                                                                                                            value="暖暖區">暖暖區</Option>
                                                                                                    </Select>
                                                                                                    {meta.touched && meta.error &&
                                                                                                    <div
                                                                                                        className="error">
                                                                                                        {meta.error}
                                                                                                    </div>}
                                                                                                </NForm.Item>
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    }
                                                                                </Field>
                                                                                <Field parse={_.bs.identity}
                                                                                       name='院所地址_其他'>
                                                                                    {
                                                                                        ({input, meta}) => (
                                                                                            <React.Fragment>
                                                                                                <NForm.Item
                                                                                                    name="sterilizationdate"
                                                                                                    style={{flex: 1}}
                                                                                                >
                                                                                                    <Input {...input}
                                                                                                           placeholder="詳細地址"/>
                                                                                                    {meta.touched && meta.error &&
                                                                                                    <div
                                                                                                        className="error">
                                                                                                        {meta.error}
                                                                                                    </div>}
                                                                                                </NForm.Item>
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    }
                                                                                </Field>
                                                                            </Input.Group>
                                                                        </div>
                                                                    </div>
                                                                    <div className="str cops">
                                                                        <div className="th">統一編號</div>
                                                                        <div className="td">
                                                                            <Field parse={_.bs.identity} name='統一編號'>
                                                                                {
                                                                                    ({input, meta}) => (
                                                                                        <React.Fragment>
                                                                                            <NForm.Item  {...layout}
                                                                                                         name="agencyname"
                                                                                            >
                                                                                                <Input {...input}
                                                                                                       placeholder="請輸入完整統一編號"/>
                                                                                                {meta.touched && meta.error &&
                                                                                                <div className="error">
                                                                                                    {meta.error}
                                                                                                </div>}
                                                                                            </NForm.Item>
                                                                                        </React.Fragment>
                                                                                    )
                                                                                }
                                                                            </Field>
                                                                        </div>
                                                                    </div>
                                                                    <div className="str cops">
                                                                        <div className="th">負責人</div>
                                                                        <div className="td">
                                                                            <Field parse={_.bs.identity} name='負責人'>
                                                                                {
                                                                                    ({input, meta}) => (
                                                                                        <React.Fragment>
                                                                                            <NForm.Item  {...layout}
                                                                                                         name="agencyprincipal"
                                                                                            >
                                                                                                <Input {...input}
                                                                                                       placeholder="請輸入中英文"/>
                                                                                                {meta.touched && meta.error &&
                                                                                                <div className="error">
                                                                                                    {meta.error}
                                                                                                </div>}
                                                                                            </NForm.Item>
                                                                                        </React.Fragment>
                                                                                    )
                                                                                }
                                                                            </Field>
                                                                        </div>
                                                                    </div>
                                                                    <div className="str cops">
                                                                        <div className="th">連絡電話</div>
                                                                        <div className="td">
                                                                            <Field parse={_.bs.identity} name='連絡電話'>
                                                                                {
                                                                                    ({input, meta}) => (
                                                                                        <React.Fragment>
                                                                                            <NForm.Item  {...layout}
                                                                                                         name="agencyprincipaltel"
                                                                                            >
                                                                                                <Input {...input}
                                                                                                       placeholder="請輸入數字"/>
                                                                                                {meta.touched && meta.error &&
                                                                                                <div className="error">
                                                                                                    {meta.error}
                                                                                                </div>}
                                                                                            </NForm.Item>
                                                                                        </React.Fragment>
                                                                                    )
                                                                                }
                                                                            </Field>
                                                                        </div>
                                                                    </div>

                                                                    <div className="str cops">
                                                                        <div className="th">交通資訊</div>
                                                                        <div className="td">
                                                                            <Field parse={_.bs.identity} name='交通資訊'>
                                                                                {
                                                                                    ({input, meta}) => (
                                                                                        <React.Fragment>
                                                                                            <NForm.Item  {...layout}
                                                                                                         name="agencyprincipaltel"
                                                                                            >
                                                                                                <TextArea {...input}
                                                                                                          rows={2}
                                                                                                          placeholder="請輸入交通資訊"/>

                                                                                                {meta.touched && meta.error &&
                                                                                                <div className="error">
                                                                                                    {meta.error}
                                                                                                </div>}
                                                                                            </NForm.Item>
                                                                                        </React.Fragment>
                                                                                    )
                                                                                }
                                                                            </Field>
                                                                        </div>
                                                                    </div>

                                                                    <div className="str cops">
                                                                        <div className="th">停車資訊</div>
                                                                        <div className="td">
                                                                            <Field parse={_.bs.identity} name='停車資訊'>
                                                                                {
                                                                                    ({input, meta}) => (
                                                                                        <React.Fragment>
                                                                                            <NForm.Item  {...layout}
                                                                                                         name="agencyprincipaltel"
                                                                                            >
                                                                                                <TextArea {...input}
                                                                                                          rows={2}
                                                                                                          placeholder="請輸入停車資訊"/>
                                                                                                {meta.touched && meta.error &&
                                                                                                <div className="error">
                                                                                                    {meta.error}
                                                                                                </div>}
                                                                                            </NForm.Item>
                                                                                        </React.Fragment>
                                                                                    )
                                                                                }
                                                                            </Field>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="boxfooter">

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="vt-col vs_scroll">

                                                    <div className="infoboxoutside">
                                                        <div className="infobox">
                                                            <div className="titlebar">
                                                                <h4>服務設定</h4>
                                                                <div className="barcontent"></div>
                                                                <div className="func">
                                                                </div>
                                                            </div>
                                                            <div className="boxcontent">
                                                                <div className="systable col_4">
                                                                    <div className="str cops">
                                                                        <div className="th">24H急診</div>
                                                                        <div className="td ruletag">
                                                                            <Field parse={_.bs.identity}
                                                                                   name='服務介紹_24H急診'>
                                                                                {
                                                                                    ({input, meta}) => (
                                                                                        <React.Fragment>
                                                                                            <NForm.Item  {...layout}
                                                                                                         name="serviceitems"
                                                                                            >
                                                                                                <Checkbox
                                                                                                    defaultChecked={input.value == true}
                                                                                                    onChange={(e) => {
                                                                                                        input.onChange(e.target.checked)
                                                                                                    }}
                                                                                                    type="checkbox"
                                                                                                />
                                                                                                {meta.touched && meta.error &&
                                                                                                <div className="error">
                                                                                                    {meta.error}
                                                                                                </div>}
                                                                                            </NForm.Item>
                                                                                        </React.Fragment>
                                                                                    )
                                                                                }
                                                                            </Field>
                                                                        </div>
                                                                    </div>
                                                                    <div className="str cops">
                                                                        <div className="th">服務項目</div>
                                                                        <div className="td ruletag">
                                                                            <Field parse={_.bs.identity}
                                                                                   name='服務介紹_服務項目'>
                                                                                {
                                                                                    ({input, meta}) => {
                                                                                        let specProp: any = {};
                                                                                        if (!input.value) {
                                                                                            specProp.defaultValue = [];
                                                                                        } else {
                                                                                            let values = input.value.split(',');
                                                                                            specProp.defaultValue = values;
                                                                                        }
                                                                                        specProp.onChange = (arr) => {
                                                                                            input.onChange(Array.prototype.join.call(arr));
                                                                                        }

                                                                                        return <React.Fragment>
                                                                                            <NForm.Item  {...layout}
                                                                                                         name="serviceitems"
                                                                                            >
                                                                                                <Select
                                                                                                    {...specProp}
                                                                                                    mode="tags"
                                                                                                    style={{width: '100%'}}
                                                                                                    placeholder="可自行輸入符合的項目">
                                                                                                    <Option
                                                                                                        value='一般診療'>一般診療</Option>
                                                                                                    <Option
                                                                                                        value='急診急救'>急診急救</Option>
                                                                                                    <Option
                                                                                                        value='美容'>美容</Option>
                                                                                                    <Option
                                                                                                        value='健康檢查'>健康檢查</Option>
                                                                                                    <Option
                                                                                                        value='X光'>X光</Option>
                                                                                                    <Option
                                                                                                        value='流浪動物中途'>流浪動物中途</Option>
                                                                                                </Select>
                                                                                                {meta.touched && meta.error &&
                                                                                                <div className="error">
                                                                                                    {meta.error}
                                                                                                </div>}
                                                                                            </NForm.Item>
                                                                                        </React.Fragment>
                                                                                    }
                                                                                }
                                                                            </Field>
                                                                        </div>
                                                                    </div>
                                                                    <div className="str cops">
                                                                        <div className="th">服務科別</div>
                                                                        <div className="td ruletag">
                                                                            <Field parse={_.bs.identity}
                                                                                   name='服務介紹_服務科別'>
                                                                                {
                                                                                    ({input, meta}) => {
                                                                                        let specProp: any = {};
                                                                                        if (!input.value) {
                                                                                            specProp.defaultValue = [];
                                                                                        } else {
                                                                                            let values = input.value.split(',');
                                                                                            specProp.defaultValue = values;
                                                                                        }
                                                                                        specProp.onChange = (arr) => {
                                                                                            input.onChange(Array.prototype.join.call(arr));
                                                                                        }

                                                                                        return <React.Fragment>
                                                                                            <NForm.Item {...layout}
                                                                                                        name="servicetype"
                                                                                            >
                                                                                                <Select {...specProp}
                                                                                                        mode="tags"
                                                                                                        style={{width: '100%'}}
                                                                                                        placeholder="可自行輸入符合的項目">
                                                                                                    <Option
                                                                                                        value='一般內/外科'>一般內/外科</Option>
                                                                                                    <Option
                                                                                                        value='心臟科'>心臟科</Option>
                                                                                                    <Option
                                                                                                        value='行為學科'>行為學科</Option>
                                                                                                    <Option
                                                                                                        value='皮膚科'>皮膚科</Option>
                                                                                                    <Option
                                                                                                        value='特寵'>特寵</Option>
                                                                                                    <Option
                                                                                                        value='流浪動物中途'>流浪動物中途</Option>
                                                                                                </Select>
                                                                                                {meta.touched && meta.error &&
                                                                                                <div className="error">
                                                                                                    {meta.error}
                                                                                                </div>}
                                                                                            </NForm.Item>
                                                                                        </React.Fragment>
                                                                                    }
                                                                                }
                                                                            </Field>
                                                                        </div>
                                                                    </div>
                                                                    <div className="str cops">
                                                                        <div className="th">特殊儀器</div>
                                                                        <div className="td ruletag">
                                                                            <Field parse={_.bs.identity}
                                                                                   name='服務介紹_特殊儀器'>
                                                                                {
                                                                                    ({input, meta}) => {
                                                                                        let specProp: any = {};
                                                                                        if (!input.value) {
                                                                                            specProp.defaultValue = [];
                                                                                        } else {
                                                                                            let values = input.value.split(',');
                                                                                            specProp.defaultValue = values;
                                                                                        }
                                                                                        specProp.onChange = (arr) => {
                                                                                            input.onChange(Array.prototype.join.call(arr));
                                                                                        }

                                                                                        return <React.Fragment>
                                                                                            <NForm.Item  {...layout}
                                                                                                         name="serviceitems"
                                                                                            >
                                                                                                <Select {...specProp}
                                                                                                        mode="tags"
                                                                                                        style={{width: '100%'}}
                                                                                                        placeholder="可自行輸入符合的項目">
                                                                                                    <Option
                                                                                                        value='一般診療'>一般診療</Option>
                                                                                                    <Option
                                                                                                        value='急診急救'>急診急救</Option>
                                                                                                    <Option
                                                                                                        value='美容'>美容</Option>
                                                                                                    <Option
                                                                                                        value='健康檢查'>健康檢查</Option>
                                                                                                    <Option
                                                                                                        value='X光'>X光</Option>
                                                                                                    <Option
                                                                                                        value='流浪動物中途'>流浪動物中途</Option>
                                                                                                </Select>
                                                                                                {meta.touched && meta.error &&
                                                                                                <div className="error">
                                                                                                    {meta.error}
                                                                                                </div>}
                                                                                            </NForm.Item>
                                                                                        </React.Fragment>
                                                                                    }
                                                                                }
                                                                            </Field>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="boxfooter">

                                                            </div>
                                                        </div>

                                                        <div className="infobox">
                                                            <Field parse={_.bs.identity} name='服務介紹_證書和執照'>
                                                                {
                                                                    ({input, meta}) => {
                                                                        if (!state.nsUploader2.compState.hasSetFileList) {
                                                                            state.nsUploader2.compState.hasSetFileList = true;

                                                                            let count: number = 0;

                                                                            if (input.value && input.value !== '') {
                                                                                state.nsUploader2.compState.fileList = (input.value).split(',').map((it) => {
                                                                                    count++;

                                                                                    // minimum requirement for antFileInfos [
                                                                                    //     {
                                                                                    //         uid: '-1',
                                                                                    //         name: 'image.png',
                                                                                    //         status: 'done',
                                                                                    //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                                                                                    //     },
                                                                                    // ];

                                                                                    let url = Ln_PictureImgHref({
                                                                                        照片組: it,
                                                                                        testMode: false,
                                                                                    });
                                                                                    let antFileInfo = {
                                                                                        uid: count,
                                                                                        status: 'done',
                                                                                        url: url,
                                                                                        name: it,
                                                                                        thumbUrl: url,

                                                                                        id: it,
                                                                                    };
                                                                                    return antFileInfo;
                                                                                });

                                                                                state.nsUploader2.compState.files = (input.value ?? '').split(',').map((it) => {
                                                                                    count++;

                                                                                    // minimum requirement for antFileInfos [
                                                                                    //     {
                                                                                    //         uid: '-1',
                                                                                    //         name: 'image.png',
                                                                                    //         status: 'done',
                                                                                    //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                                                                                    //     },
                                                                                    // ];
                                                                                    let url = Ln_PictureImgHref({
                                                                                        照片組: it,
                                                                                        testMode: false,
                                                                                    });
                                                                                    let antFileInfo = {
                                                                                        uid: count,
                                                                                        status: 'done',
                                                                                        url: url,
                                                                                        name: it,
                                                                                        thumbUrl: url,

                                                                                        id: it,
                                                                                    };
                                                                                    return antFileInfo;
                                                                                });
                                                                            }
                                                                        }

                                                                        return <React.Fragment>
                                                                            <div className="photouploadbox enintro">
                                                                                <div className="topic">
                                                                                    <h3>證書和執照</h3>
                                                                                    <span
                                                                                        className="hint">最多可上傳5張，每張不超過1MB。</span>
                                                                                </div>
                                                                                <div className="barcontent"></div>
                                                                                <div className="func">
                                                                                </div>
                                                                            </div>
                                                                            <div className="boxcontent">
                                                                                <div className="photoupload">

                                                                                    <ImgCrop
                                                                                        modalTitle='裁切照片'
                                                                                        rotate
                                                                                        grid
                                                                                        aspect={16 / 10}
                                                                                        modalOk="確認"
                                                                                        modalCancel="取消"
                                                                                    >
                                                                                        <Upload
                                                                                            action={ApiService.urlMapping('~/pet_api/1/_memberupload')}
                                                                                            withCredentials={true}
                                                                                            listType="picture"
                                                                                            fileList={state.nsUploader2.compState.fileList}
                                                                                            beforeUpload={state.nsUploader2.beforeUpload}
                                                                                            onPreview={state.nsUploader2.handlePreview}
                                                                                            //previewFile={state.nsUploader.previewFile}
                                                                                            onChange={(params) => state.nsUploader2.handleChange({
                                                                                                ...params,
                                                                                                wrapperOnChange: input.onChange
                                                                                            })}
                                                                                            multiple={true}
                                                                                            data={state.nsUploader2.addData}
                                                                                            className="upload-list-inline"
                                                                                        >
                                                                                            <Button icon={
                                                                                                <UploadOutlined/>}>上傳證書照片</Button>
                                                                                        </Upload>
                                                                                    </ImgCrop>
                                                                                </div>
                                                                            </div>
                                                                            <div className="boxfooter">

                                                                            </div>
                                                                        </React.Fragment>;
                                                                    }
                                                                }
                                                            </Field>
                                                        </div>
                                                        <div className="infobox">
                                                            <Field parse={_.bs.identity} name='服務介紹_環境'>
                                                                {
                                                                    ({input, meta}) => {
                                                                        if (!state.nsUploader.compState.hasSetFileList) {
                                                                            state.nsUploader.compState.hasSetFileList = true;

                                                                            let count: number = 0;

                                                                            if (input.value && input.value !== '') {
                                                                                state.nsUploader.compState.fileList = (input.value).split(',').map((it) => {
                                                                                    count++;

                                                                                    // minimum requirement for antFileInfos [
                                                                                    //     {
                                                                                    //         uid: '-1',
                                                                                    //         name: 'image.png',
                                                                                    //         status: 'done',
                                                                                    //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                                                                                    //     },
                                                                                    // ];

                                                                                    let url = Ln_PictureImgHref({
                                                                                        照片組: it,
                                                                                        testMode: false,
                                                                                    });
                                                                                    let antFileInfo = {
                                                                                        uid: count,
                                                                                        status: 'done',
                                                                                        url: url,
                                                                                        name: it,
                                                                                        thumbUrl: url,

                                                                                        id: it,
                                                                                    };
                                                                                    return antFileInfo;
                                                                                });

                                                                                state.nsUploader.compState.files = (input.value ?? '').split(',').map((it) => {
                                                                                    count++;

                                                                                    // minimum requirement for antFileInfos [
                                                                                    //     {
                                                                                    //         uid: '-1',
                                                                                    //         name: 'image.png',
                                                                                    //         status: 'done',
                                                                                    //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                                                                                    //     },
                                                                                    // ];
                                                                                    let url = Ln_PictureImgHref({
                                                                                        照片組: it,
                                                                                        testMode: false,
                                                                                    });
                                                                                    let antFileInfo = {
                                                                                        uid: count,
                                                                                        status: 'done',
                                                                                        url: url,
                                                                                        name: it,
                                                                                        thumbUrl: url,

                                                                                        id: it,
                                                                                    };
                                                                                    return antFileInfo;
                                                                                });
                                                                            }
                                                                        }

                                                                        return <React.Fragment>
                                                                            <div className="photouploadbox enintro">
                                                                                <div className="topic">
                                                                                    <h3>院所環境</h3>
                                                                                    <span
                                                                                        className="hint">最多可上傳5張，每張不超過1MB。</span>
                                                                                </div>
                                                                                <div className="barcontent"></div>
                                                                                <div className="func">
                                                                                </div>
                                                                            </div>
                                                                            <div className="boxcontent">
                                                                                <div className="photoupload">

                                                                                    <ImgCrop
                                                                                        modalTitle='裁切照片'
                                                                                        rotate
                                                                                        grid
                                                                                        aspect={16 / 10}
                                                                                        modalOk="確認"
                                                                                        modalCancel="取消"
                                                                                    >
                                                                                        <Upload
                                                                                            action={ApiService.urlMapping('~/pet_api/1/_memberupload')}
                                                                                            withCredentials={true}
                                                                                            listType="picture"
                                                                                            fileList={state.nsUploader.compState.fileList}
                                                                                            // defaultFileList={
                                                                                            //     [
                                                                                            //         {
                                                                                            //             uid: '-1',
                                                                                            //             name: 'image1.png',
                                                                                            //             status: 'done',
                                                                                            //             url: '../static/img/ct001.jpg',
                                                                                            //             type: '',
                                                                                            //             size: 1,
                                                                                            //         },
                                                                                            //         {
                                                                                            //             uid: '-2',
                                                                                            //             name: 'image2.png',
                                                                                            //             status: 'done',
                                                                                            //             url: '../static/img/ct002.jpg',
                                                                                            //             type: '',
                                                                                            //             size: 1,
                                                                                            //         },
                                                                                            //         {
                                                                                            //             uid: '-4',
                                                                                            //             name: 'image4.png',
                                                                                            //             status: 'done',
                                                                                            //             url: '../static/img/ct003.jpg',
                                                                                            //             type: '',
                                                                                            //             size: 1,
                                                                                            //         },
                                                                                            //         {
                                                                                            //             uid: '-4',
                                                                                            //             name: 'image4.png',
                                                                                            //             status: 'uploading',
                                                                                            //             url: '../static/img/ct004.jpg',
                                                                                            //             type: '',
                                                                                            //             size: 1,
                                                                                            //         },
                                                                                            //         {
                                                                                            //             uid: '-5',
                                                                                            //             name: 'image.png',
                                                                                            //             status: 'error',
                                                                                            //             type: '',
                                                                                            //             size: 1,
                                                                                            //         }
                                                                                            //     ]
                                                                                            // }
                                                                                            beforeUpload={state.nsUploader.beforeUpload}
                                                                                            onPreview={state.nsUploader.handlePreview}
                                                                                            //previewFile={state.nsUploader.previewFile}
                                                                                            onChange={(params) => state.nsUploader.handleChange({
                                                                                                ...params,
                                                                                                wrapperOnChange: input.onChange
                                                                                            })}
                                                                                            multiple={true}
                                                                                            data={state.nsUploader.addData}
                                                                                            className="upload-list-inline"
                                                                                        >
                                                                                            <Button icon={
                                                                                                <UploadOutlined/>}>上傳環境照片</Button>
                                                                                        </Upload>
                                                                                    </ImgCrop>
                                                                                </div>
                                                                            </div>
                                                                            <div className="boxfooter">

                                                                            </div>
                                                                        </React.Fragment>;
                                                                    }
                                                                }
                                                            </Field>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>

                                            <div className="page_footer">
                                                <div
                                                    className="hint">最後更新時間：{moment(Item_醫院.ModifiedAt ?? Item_醫院.CreatedAt).format('YYYY-MM-DD hh:mm:ss')}</div>
                                                <Space>
                                                    {isModalContained() && <React.Fragment>
                                                        <Button type='primary' danger ghost
                                                                onClick={(e) => handleClose(e)}>放棄編輯</Button>
                                                    </React.Fragment>
                                                    }
                                                    <Button type='primary' htmlType="submit"
                                                        //disabled={pristine || (submitting || !valid)}
                                                            disabled={(submitting)}
                                                            onClick={handleSubmit}>儲存</Button>
                                                </Space>
                                            </div>
                                        </div>


                                    </form>
                                </React.Fragment>
                            }}
                        />
                    </React.Fragment>
                })}
            </React.Fragment>;
        }
        else if (its && its.length === 0) {
            if (RegionEmptyShow) {
                return <React.Fragment>
                    <div>Empty.</div>
                </React.Fragment>;
            }
        }

        return null;
    }
    //#endregion

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_Manage_Agency__Pane_EditOrAddAgency.displayName = 'Partial_Manage_Agency__Pane_EditOrAddAgency';
