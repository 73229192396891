import {Dispatch, MutableRefObject, SetStateAction, useRef, useState} from 'react';
import _ from "../_";
import {Static} from "../_/Static";

var lodash = require('lodash');

declare var window;

export type PageDataManager = {
    //specific zone management
    readonly isZoneExist: (zoneName: any) => boolean,
    readonly tryCreateOrGetEmptyZone: (zoneName: string, initalValue: any) => any,
    readonly emptyZone: (zoneName: string) => void

    //history based
    readonly apply: () => any,
    readonly restore: () => any,
    readonly replaceHash: (hashStrWithoutSymbol: string) => void,

    //page states
    readonly refPageData: MutableRefObject<any>,
    readonly get: (path: string) => any,
    readonly state: () => any,
    readonly clear: () => any,
    readonly clearIncludeAuth: () => any,
    readonly merge: (payload: object) => any,
    readonly mergeWithConcatArray: (payload: object) => any,

    //supportive features
    readonly executor: () => any,
    readonly reloadAsync: (arg) => Promise<any>,
    readonly forceRender: any,

    readonly saveScrollPos: () => any,
}

type Props = {
    refPageLoad: any;
};
export default function UsePageDataManager(props: Props) : PageDataManager {
    const refPageLoad: any = props.refPageLoad;

    const refPageData = useRef(window.history.state || {});

    const refSelfInstance = useRef<PageDataManager | null>(null);

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    window.requestAnimationFrame(function() {
        const event = new Event('load');
        window.dispatchEvent(event);
    });
    //#endregion


    if (refSelfInstance.current !== null) {
        return refSelfInstance.current;
    }

    function invariantAssure() {
        if (refPageData.current === null)
            throw 'refPageData is in invalid NULL state';
    }

    function assure() {
        if (refPageLoad.current === null)
            throw '_doPageDataLoad not set before usage';
    }

    function restore() {
        invariantAssure();
        assure();

        refPageData.current = window.history.state;
        // if (historyState?.usr) {
        //     refPageData.current = historyState;
        // }

        return refPageData.current;
    }

    function apply() {
        invariantAssure();
        assure();

        refPageData.current.usr = true;
        window.history.replaceState(refPageData.current, '');

        return refPageData.current;
    }

    function customizer(objValue, srcValue) {
        if (lodash.isArray(objValue)) {
            return objValue.concat(srcValue);
        }
    }


    function mergeWithConcatArray(payload: object, shouldUpdateView = true) {
        invariantAssure();
        assure();

        lodash.assign(refPageData.current, lodash.mergeWith(refPageData.current, payload, customizer));
        return refPageData.current;
    }

    function merge(payload: object, shouldUpdateView = true) {
        invariantAssure();
        assure();

        lodash.assign(refPageData.current, lodash.merge(refPageData.current, payload));
        return refPageData.current;
    }

    function clear() {
        invariantAssure();
        assure();

        let oldPageData = refPageData.current;

        refPageData.current = {
            ...(oldPageData.Auth && {Auth: oldPageData.Auth}),
            key: oldPageData.key,
            idx: oldPageData.idx,
            usr: (oldPageData.Auth && true),
        };
        apply();
    }

    function clearIncludeAuth() {
        invariantAssure();
        assure();

        let oldPageData = refPageData.current;

        refPageData.current = {
            key: oldPageData.key,
            idx: oldPageData.idx,
        };
        apply();
    }

    async function reloadAsync(arg) {
        await refPageLoad.current?.(arg);
    }

    function isZoneExist(zoneName: string) {
        invariantAssure();

        //return _.utilManager.isObject(refPageData.current?.[zoneName]);
        return get(zoneName) !== null;
    }

    function tryCreateOrGetEmptyZone(zoneName: string, initalValue: any) {
        invariantAssure();

        if (!isZoneExist(zoneName)) {
            refPageData.current[zoneName] = initalValue;
        }

        return refPageData.current[zoneName]; //return existing or newly created zone
    }

    function emptyZone(zoneName: string) {
        invariantAssure();

        delete refPageData.current[zoneName];
    }

    function replaceHash(hashStrWithoutSymbol: string) {
        if (hashStrWithoutSymbol === '') {
            window.history.replaceState(refPageData.current, '', '#');
        } else {
            window.history.replaceState(refPageData.current, '', '#' + hashStrWithoutSymbol);
        }
    }

    function get(path: string) {
        if (refPageData.current) {
            let mapperPath = _.util.existGet(refPageData.current, `__Mapper.${path}`);
            if (mapperPath !== null && (typeof mapperPath === 'string' || mapperPath instanceof String)) {
                return _.util.existGet(refPageData.current, mapperPath);
            } else {
                return _.util.existGet(refPageData.current, path);
            }
        } else {
            return null;
        }
    }

    // function set(path: string, newValue:any) {
    //     if (
    //     _.utilManager.namespaceSet(refPageData.current, path, newValue))
    // }

    function state() {
        if (refPageData.current) {
            return refPageData.current.__State ?? null;
        } else {
            return null;
        }
    }

    function executor() {
        if (refPageData.current) {
            return refPageData.current.__Executor ?? null;
        } else {
            return null;
        }
    }


    // function createOrReplaceZone(zoneObj) {
    //     return _.utilManager.isObject(refPageData.current?.[zoneName]);
    // }

    function saveScrollPos() {
        let pos = getScrollPosition(window);

    }

    return refSelfInstance.current = {
        refPageData,
        merge,
        mergeWithConcatArray,

        apply,
        restore,

        reloadAsync,
        clear,
        clearIncludeAuth,
        isZoneExist,
        tryCreateOrGetEmptyZone,
        emptyZone,
        replaceHash,
        forceRender,

        get,
        state,
        executor,

        saveScrollPos,
    };
}

function getScrollPosition(target) {
    if (target instanceof window.Window) {
        return { x: target.scrollX, y: target.scrollY }
    }

    return { x: target.scrollLeft, y: target.scrollTop }
}
