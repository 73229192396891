import React, {useCallback, useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {
    Form as NForm,
    Avatar,
    Button,
    Modal,
    message,
    Popconfirm,
    Input,
    Space,
    Select,
    Collapse,
    DatePicker,
    Comment,
    Tooltip,
    List
} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";

import {
    LoadingOutlined,
    LockOutlined,
    PlusOutlined,
    RedoOutlined,
    UserOutlined,
    EditOutlined,
    UploadOutlined,
    DeleteFilled
} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";
import UseCompState from "../hooks/UseCompState";

import Partial_MedicalRecord__Modal_IHRecord from "./Partial_MedicalRecord__Modal_IHRecord";
import Ln_AvatorImg from "./Ln_AvatorImg";
import Partial_inHospital__Pane_MsgBoard from "./Partial_inHospital__Pane_MsgBoard";
import Ln_AvatorImgHref from "./Ln_AvatorImgHref";

var moment = require('moment');


const {Option} = Select;
const {Panel} = Collapse;
const dateFormat = 'YYYY/MM/DD';

const {TextArea} = Input;

type ViewModelProps = {
    refModal: any,

    住院籠子No: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any,

    viewModel: ViewModelProps,
};

export default function Partial_inHospital__Pane_ViewCard(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_inHospital__Pane_ViewCard';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel = props.viewModel;

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

    }
    //#endregion

    //#region No Cache
    useEffect(() => {
        return () => {
            pageDataManager.emptyZone(PrefixTarget);
            pageDataManager.apply();
        }
    }, []);
    //#endregion

    //#region Boot
    let _refDoLoadPartialData: any = useRef<{ alwaysLoad?: boolean }>(null);
    useEffect(() => {
        const _loadPartialData = async (): Promise<void> => {

            // VALIDATE INPUT
            //const id : any = this.props.match.params.id;
            const p1 = viewModel.住院籠子No;
            if (!p1) {
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/partial_inhospital/pane_viewcard');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        let changeShard = {
                            [PrefixTarget]: {
                                ...payload,
                            }
                        }
                        pageDataManager.merge(changeShard);
                        pageDataManager.apply();

                        forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPartialData = async ({alwaysLoad = false}): Promise<void> => {
            // if (!pageDataManager.refPageData.current.Root) {
            //     return;
            // }

            /// do not dynamically load data if condition fits.
            if (!alwaysLoad) {
                if (pageDataManager.isZoneExist(PrefixTarget)) {
                    return;
                }
            }

            await _loadPartialData();
        }

            _refDoLoadPartialData.current = doLoadPartialData;
            _refDoLoadPartialData.current({});
    }, [pageDataManager.get(PrefixTarget)]);
    //#endregion

    const layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 16},
    };

    const layout02 = {
        labelCol: {span: 0},
        wrapperCol: {span: 25},
    };

    //#region :Popup :createModalIHRecord_refModal
    const createModalIHRecord_refModal = useRef<any>(null);
    const createModalIHRecord = (e: any,{病歷No, tasks}: {
        病歷No: any,
        tasks?: string[]
    }) => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_MedicalRecord__Modal_IHRecord
                PrefixTarget={'Partial_MedicalRecord__Modal_IHRecord'}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModalIHRecord_refModal,

                    病歷No,
                }}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '60em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModalIHRecord_refModal.current = Modal.info(config);
    }
    //#endregion


    //#region SetBody
    const isModalContained = () => {
        return !!viewModel.refModal?.current;
    }
    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };

    const 基本資料_Body = () => {

        const RegionEmptyShow = true;
        const RegionLoadingShow = false;

        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;

        let Item_住院籠子 = pageDataManager.get(`${PrefixTarget}.Item_住院籠子`);

        let its: any;
        if (Item_住院籠子 === null) {
            its = [null];
        } else if (Item_住院籠子 !== undefined) {
            its = [Item_住院籠子];
        }

        if (!PassPrefixTargetLoaded) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <div>Loading.</div>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        if (its && its.length > 0) {
            return <React.Fragment>
                {its.map(Item_住院籠子 => {

                    let 住院期間: any = null;
                    let 住院剩餘天: any = null;
                    let 住院進行天: any = null;
                    let 住院預計天: any = null;
                    if (!_.util.nullify(Item_住院籠子._掛號?._病歷?.__病歷項_最新住院項?.住院_住院開始時間)) {
                        住院期間 = '錯誤:未設置開始住院時間';
                    } else {
                        if (!_.util.nullify(Item_住院籠子._掛號?._病歷?.__病歷項_最新住院項?.住院_住院預計結束時間)) {
                            住院期間 = '錯誤:住院預計結束時間';
                        } else {
                            let startDate = moment(Item_住院籠子._掛號._病歷.__病歷項_最新住院項.住院_住院開始時間);
                            //let moreDays = (+Item_住院籠子._掛號._病歷.__病歷項_最新住院項.住院預計天數);
                            let endDate = moment(Item_住院籠子._掛號._病歷.__病歷項_最新住院項.住院_住院預計結束時間);
                            let totalDays = endDate.diff(startDate, 'days');
                            let now = moment();
                            let remainingDays = endDate.diff(now, 'days');
                            if (remainingDays < 0) {
                                remainingDays = 0;
                            }
                            let runningDays = now.diff(startDate, 'days');
                            if (runningDays < 0) {
                                runningDays = 0;
                            }
                            住院預計天 = totalDays;
                            住院進行天 = runningDays;
                            住院剩餘天 = remainingDays;
                            住院期間 = `${startDate.format('MM-DD')} - ${endDate.format('MM-DD')}`;
                        }
                    }

                    let Item_寵物 = Item_住院籠子?._掛號?._寵物;
                    let 年齡: any = null;
                    if (!_.util.nullify(Item_寵物?.出生日)) {
                        年齡 = '沒有填出生日';
                    } else {
                        try {
                            let birthDate = moment(Item_寵物.出生日);
                            let now = moment();
                            var yearDiff = now.diff(birthDate, "year");
                            var monthDiff = now.diff(birthDate, "month");
                            年齡 = '';
                            if (monthDiff >= 0) {
                                年齡 = `${monthDiff}個月` + 年齡;
                            }
                            if (yearDiff >= 0) {
                                年齡 = `${yearDiff}歲` + 年齡;
                            }
                            if (年齡 === '') {
                                年齡 = `未滿一個月`;
                            }
                        } catch(err) {
                            年齡 = '計算錯誤';
                        }
                    }

                    let 體重: any = _.util.nullify(Item_寵物?.最後體重) ?? _.util.nullify(Item_寵物?.口述體重);
                    if (!體重) {
                        體重 = '沒有測量或告知';
                    }


                    return <React.Fragment key={Item_住院籠子.住院籠子No ?? 0}>
                        <div className="vt-col">
                            <div className="inbox onitem">
                                <h3>基本資料</h3>
                                <div className="infocontent">
                                    <div className="infobox">
                                        <h4>寵物</h4>
                                        <div className="avatar">
                                            {Ln_AvatorImg({
                                                名稱: Item_住院籠子._掛號._寵物.寵物名稱,
                                                照片組: Item_住院籠子._掛號._寵物.寵物照片組
                                            })}
                                        </div>
                                        <div className="info">
                                            <div className="th">名稱：</div>
                                            <div className="content">{Item_住院籠子._掛號._寵物.寵物名稱}</div>
                                        </div>
                                        <div className="info">
                                            <div className="th">種類：</div>
                                            <div className="content">{Item_住院籠子._掛號._寵物.種類}</div>
                                        </div>
                                        <div className="info">
                                            <div className="th">品種：</div>
                                            <div className="content">{Item_住院籠子._掛號._寵物.品種}</div>
                                        </div>
                                        <div className="info">
                                            <div className="th">年齡：</div>
                                            <div className="content">{年齡}</div>
                                        </div>
                                        <div className="info">
                                            <div className="th">體重：</div>
                                            <div className="content">{體重}</div>
                                        </div>

                                        <h4>飼主</h4>
                                        <div className="info">
                                            <div className="th">姓名：</div>
                                            <div className="content">{Item_住院籠子._掛號._飼主.主人姓名}</div>
                                        </div>
                                        <div className="info">
                                            <div className="th">手機：</div>
                                            <div className="content">{Item_住院籠子._掛號._飼主.手機}</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                })}
            </React.Fragment>;
        } else if (its && its.length === 0) {
            if (RegionEmptyShow) {
                return <React.Fragment>
                    <div>Empty.</div>
                </React.Fragment>;
            }
        }

        return null;
    }

    const 住院管理_Body = () => {

        const RegionEmptyShow = true;
        const RegionLoadingShow = false;

        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;

        let Item_住院籠子 = pageDataManager.get(`${PrefixTarget}.Item_住院籠子`);

        let its: any;
        if (Item_住院籠子 === null) {
            its = [null];
        } else if (Item_住院籠子 !== undefined) {
            its = [Item_住院籠子];
        }

        let List_住院籠子 = pageDataManager.get(`${PrefixTarget}.List_住院籠子`);

        //#region Handlers
        const handleSubmit_discharge = (e: any, {掛號No}: { 掛號No: any }) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }

            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            const p1 = 掛號No;
            if (!p1) {
                _._debug.error(p1);
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_inhospital/pane_viewcard/discharge');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        message.success({content: '修改完成!', duration: 2});

                        if (isModalContained()) {
                            handleClose(null);
                        } else {
                            _refDoLoadPartialData.current({alwaysLoad: true});
                        }

                        //pageDataManager.emptyZone('Root');
                        //pageDataManager.apply();
                        pageDataManager.clear();
                        pageDataManager.reloadAsync({alwaysLoad: true});
                        //viewModel?.OnDid?.(payload);
                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            if (!values.inhospitaldate) {
                errors.inhospitaldate = '↑ 此欄位為必填';
            } else {
            }

            if (!values.outhospitaldate) {
                errors.outhospitaldate = '↑ 此欄位為必填';
            } else {
            }

            return errors;
        };

        const handleSubmit_clickLogin = (e: any, v: any) => {

        }
        //#endregion

        if (!PassPrefixTargetLoaded) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <div>Loading.</div>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        if (its && its.length > 0) {
            return <React.Fragment>
                {its.map(Item_住院籠子 => {


                    return <React.Fragment key={Item_住院籠子.住院籠子No ?? 0}>
                        <Form
                            onSubmit={(v) => handleSubmit_clickLogin(null, v)}
                            initialValues={{
                                病歷項: Item_住院籠子._掛號._病歷.__病歷項_最新住院項,
                                住院籠子: Item_住院籠子,
                            }}
                            validate={handleValidate}
                            render={({
                                         submitError,
                                         handleSubmit,
                                         submitting,
                                         pristine,
                                         valid,
                                         error,
                                         values
                                     }) => {


                                let content_inHospital:any = null;
                                if (values.病歷項) {

                                    let 住院期間: any = null;
                                    let 住院剩餘天: any = null;
                                    let 住院進行天: any = null;
                                    let 住院預計天: any = null;
                                    if (!_.util.nullify(Item_住院籠子._掛號._病歷.__病歷項_最新住院項?.住院_住院開始時間)) {
                                        住院期間 = '錯誤:未設置開始住院時間';
                                    } else {
                                        if (!_.util.nullify(Item_住院籠子._掛號._病歷.__病歷項_最新住院項?.住院_住院預計結束時間)) {
                                            住院期間 = '錯誤:住院預計結束時間';
                                        } else {
                                            let startDate = moment(Item_住院籠子._掛號._病歷.__病歷項_最新住院項.住院_住院開始時間);
                                            //let moreDays = (+Item_住院籠子._掛號._病歷.__病歷項_最新住院項.住院預計天數);
                                            let endDate = moment(Item_住院籠子._掛號._病歷.__病歷項_最新住院項.住院_住院預計結束時間);
                                            let totalDays = endDate.diff(startDate, 'days');
                                            let now = moment();
                                            let remainingDays = endDate.diff(now, 'days');
                                            if (remainingDays < 0) {
                                                remainingDays = 0;
                                            }
                                            let runningDays = now.diff(startDate, 'days');
                                            if (runningDays < 0) {
                                                runningDays = 0;
                                            }
                                            住院預計天 = totalDays;
                                            住院進行天 = runningDays;
                                            住院剩餘天 = remainingDays;
                                            住院期間 = `${startDate.format('MM-DD')} - ${endDate.format('MM-DD')}`;
                                        }
                                    }
                                    content_inHospital = <React.Fragment>
                                        <Field parse={_.bs.identity} name='病歷項.住院_住院開始時間'>
                                            {
                                                ({input, meta}) => {
                                                    const datepickerProps: any = {};
                                                    if (input.value) {
                                                        //props.defaultValue =moment.utc(input.value).format('YYYY-MM-DD');
                                                        datepickerProps.defaultValue = moment(input.value);
                                                    }
                                                    datepickerProps.onChange = input.onChange;

                                                    return <React.Fragment>
                                                        <NForm.Item  {...layout} {...input}
                                                                     label="住院日:"
                                                                     name="inhospitaldate"
                                                                     rules={[{
                                                                         required: true,
                                                                         message: '此為必填欄位'
                                                                     }]}
                                                        >
                                                            <DatePicker {...datepickerProps}
                                                                //defaultValue={moment('2021/08/01', dateFormat)}
                                                                        format={dateFormat} disabled/>
                                                            {meta.touched && meta.error &&
                                                            <div className="error">
                                                                {meta.error}
                                                            </div>}
                                                        </NForm.Item>
                                                    </React.Fragment>;
                                                }
                                            }
                                        </Field>
                                        <Field parse={_.bs.identity} name='病歷項.住院_住院預計結束時間'>
                                            {
                                                ({input, meta}) => {
                                                    const datepickerProps: any = {};
                                                    if (input.value) {
                                                        //props.defaultValue =moment.utc(input.value).format('YYYY-MM-DD');
                                                        datepickerProps.defaultValue = moment(input.value);
                                                    }
                                                    datepickerProps.onChange = input.onChange;

                                                    return <React.Fragment>
                                                        <NForm.Item  {...layout} {...input}
                                                                     label="預計出院日:"
                                                                     name="outhospitaldate"
                                                                     rules={[{
                                                                         required: true,
                                                                         message: '此為必填欄位'
                                                                     }]}
                                                        >
                                                            <DatePicker {...datepickerProps}
                                                                //defaultValue={moment('2021/08/22', dateFormat)}
                                                                        format={dateFormat} disabled/>
                                                            {meta.touched && meta.error &&
                                                            <div className="error">
                                                                {meta.error}
                                                            </div>}
                                                        </NForm.Item>
                                                    </React.Fragment>;
                                                }
                                            }
                                        </Field>
                                        <div className="colrule">
                                            <div className="info">
                                                <div className="th">住院天數：</div>
                                                <div className="content sphint">
                                                    <span>{住院進行天}</span>/{住院預計天}</div>
                                            </div>
                                            <div className="info">
                                                <div className="th">住院原因：</div>
                                                <div className="content ">{values.病歷項?.住院_住院原因}</div>
                                            </div>
                                            {/* <div className="info">
                                                    <div className="th">實際出院日期：</div>
                                                    <div className="content">--</div>
                                                </div> */}
                                        </div>
                                    </React.Fragment>;
                                } else {
                                    content_inHospital = <React.Fragment>
                                        <div>ERR:無住院病歷 住院資料是否被刪除</div>
                                    </React.Fragment>;
                                }


                                return <form
                                    onSubmit={handleSubmit}
                                    {...layout}>
                                    <div className="vt-col">
                                        <h3>住院管理</h3>
                                        <Space className='mbt1'>
                                            <Button onClick={(e)=> {
                                                createModalIHRecord(e, {
                                                    病歷No: Item_住院籠子._掛號._病歷.病歷No,
                                                });
                                            }}>住院紀錄</Button>
                                        </Space>
                                        <div className="formbox">
                                            <Field parse={_.bs.identity} name='住院籠子.區域籠號'>
                                                {
                                                    ({input, meta}) => {
                                                        let specProp: any = {};
                                                        if (!input.value) {
                                                            specProp.defaultValue = [];
                                                        } else {
                                                            let values = input.value.split(',');
                                                            specProp.defaultValue = values;
                                                        }
                                                        specProp.onChange = (arr) => {
                                                            input.onChange(Array.prototype.join.call(arr));
                                                        }
                                                        return <React.Fragment>
                                                            <NForm.Item  {...layout} {...input}
                                                                         label="區域籠號:"
                                                                         name="area"
                                                            >
                                                                <Select {...specProp}>
                                                                    {List_住院籠子?.map(Item_住院籠子 => {
                                                                        return <Option
                                                                            value={Item_住院籠子.區域籠號}>{Item_住院籠子.區域籠號}</Option>;
                                                                    })}
                                                                </Select>
                                                                {meta.touched && meta.error &&
                                                                <div className="error">
                                                                    {meta.error}
                                                                </div>}
                                                            </NForm.Item>
                                                        </React.Fragment>;
                                                    }
                                                }
                                            </Field>

                                            {content_inHospital}
                                            <React.Fragment>
                                                <NForm.Item>
                                                    <div className="form_footer">
                                                        {Item_住院籠子.掛號No &&
                                                        <Popconfirm title="你確定出院？若確定將無法回復" okText="確定"
                                                                    cancelText="取消" onConfirm={(e) => handleSubmit_discharge(e, {掛號No: Item_住院籠子.掛號No})}>
                                                            <Button>出院</Button>
                                                        </Popconfirm>
                                                        }
                                                        {/*<Button type='primary' htmlType="submit"*/}
                                                        {/*        disabled={submitting} onClick={handleSubmit}>儲存</Button>*/}
                                                    </div>
                                                </NForm.Item>
                                            </React.Fragment>
                                        </div>

                                    </div>
                                </form>;
                            }}
                        />
                    </React.Fragment>
                })}
            </React.Fragment>;
        } else if (its && its.length === 0) {
            if (RegionEmptyShow) {
                return <React.Fragment>
                    <div>Empty.</div>
                </React.Fragment>;
            }
        }

        return null;
    }

    const 住院留言板_Body = () => {
        const RegionEmptyShow = true;
        const RegionLoadingShow = false;

        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;

        let Item_住院籠子 = pageDataManager.get(`${PrefixTarget}.Item_住院籠子`);

        let its: any;
        if (Item_住院籠子 === null) {
            its = [null];
        } else if (Item_住院籠子 !== undefined) {
            its = [Item_住院籠子];
        }

        //#region Handlers
        const handleSubmit_discharge = (e: any, {掛號No}: { 掛號No: any }) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }

            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            const p1 = 掛號No;
            if (!p1) {
                _._debug.error(p1);
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_inhospital/pane_viewcard/discharge');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        message.success({content: '修改完成!', duration: 2});

                        if (isModalContained()) {
                            handleClose(null);
                        } else {
                            //_refDoLoadPartialData.current({alwaysLoad: true});
                        }

                        pageDataManager.emptyZone('Root');
                        pageDataManager.apply();

                        pageDataManager.reloadAsync({alwaysLoad: true});
                        //viewModel?.OnDid?.(payload);
                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            if (!values.inhospitaldate) {
                errors.inhospitaldate = '↑ 此欄位為必填';
            } else {
            }

            if (!values.outhospitaldate) {
                errors.outhospitaldate = '↑ 此欄位為必填';
            } else {
            }

            return errors;
        };

        const handleSubmit_clickLogin = (e: any, v: any) => {

        }
        //#endregion

        if (!PassPrefixTargetLoaded) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <div>Loading.</div>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        if (its && its.length > 0) {
            return <React.Fragment>
                {its.map(Item_住院籠子 => {
                    if (!Item_住院籠子._掛號._病歷.__病歷項_最新住院項) {
                        return <div>ERR:無住病歷項 無法顯示留言板</div>;
                    }
                    return <React.Fragment key={Item_住院籠子.住院籠子No ?? 0}>
                        <div className="ht">
                            <h3>住院留言板</h3>
                            <Partial_inHospital__Pane_MsgBoard
                                PrefixTarget={'Partial_inHospital__Pane_MsgBoard'}
                                pageDataManager={pageDataManager}
                                sessionManager={sessionManager}
                                navigate={navigate}

                                viewModel={{
                                    refModal: null,
                                    留言板No: Item_住院籠子._掛號._病歷.__病歷項_最新住院項.留言板No_住院留言板
                                }}
                            />
                        </div>
                    </React.Fragment>
                })}
            </React.Fragment>;
        } else if (its && its.length === 0) {
            if (RegionEmptyShow) {
                return <React.Fragment>
                    <div>Empty.</div>
                </React.Fragment>;
            }
        }

        return null;
    }
    const Body = () => {

        return <React.Fragment>
            <div className="">
                <div className="vt-page m_c2table">
                    {基本資料_Body()}
                    {住院管理_Body()}
                </div>

                {住院留言板_Body()}



            </div>
        </React.Fragment>;
    }
    //#endregion

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_inHospital__Pane_ViewCard.displayName = 'Partial_inHospital__Pane_ViewCard';

