import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Form, Input, Button, Alert, Checkbox, Result, message, Space,
  Modal, Typography, Tabs, Select, Popconfirm, Table, Empty, Spin, Tooltip
} from 'antd';
import { useApp } from "../overmind";
import { UserInfo } from "../global";
import { Static } from "../_/Static";
import { ApiService } from "../_/ApiService";
import _ from "../_";
import { EyeInvisibleOutlined, EyeTwoTone, UserOutlined, FieldTimeOutlined, EditOutlined } from '@ant-design/icons';

import {
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import UsePageDataManager from '../hooks/UsePageDataManager';
import UseSessionManager from "../hooks/UseSessionManager";
import UseLoadingManager from "../hooks/UseLoadingManager";
import UseCompState from "../hooks/UseCompState";
import { lastValueFrom } from "rxjs";
import Partial_Appointment__Modal_AddOwner from "./Partial_Appointment__Modal_AddOwner";
import Partial_Appointment__Modal_AddPet from "./Partial_Appointment__Modal_AddPet";
import Partial_Modal_Search from "./Partial_Appointment__Modal_Search";
import Partial_CommonCard from "./Partial_CommonCard";
import Partial_Modal_BusinessHours from "./Partial_Modal_BusinessHours";

import './Pg_Waitingroom.scss';
import FunctionBtn from './Box_Functionbtn';
import { StaticEvent } from "../_/StaticEvent";


const iconLighting = require('../assets/images/icon/lighting.svg');
const iconWatting = require('../assets/images/icon/new-medical-record.svg');

const onSearch = value => console.log(value);
const { Title } = Typography;
const { TabPane } = Tabs;
const { Search } = Input;
const { Option } = Select;


type Props = {
  PrefixTarget?: string;
  RoutePathname?: string;
  MenuTemplateId?: string;
  parentState?: any;
};
export default function Pg_Manage_Hours_(props: Props) {
  let PrefixTarget: string = props.PrefixTarget || 'Root';
  let RoutePathname = props.RoutePathname;
  let MenuTemplateId = props.MenuTemplateId;

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  //#region ForceRender Control
  const [, setForceRender] = useState(false);
  const forceRender = () => {
    setForceRender(s => !s);
  };
  //#endregion

  //#region LoadingManager
  const loadingManager = UseLoadingManager({ shouldReRenderOnLoading: true });
  //#endregion

//#region PageDataManager
  const refDoLoadPageData = Static.refDoLoadPageData;
  let pageDataManager = UsePageDataManager({
    refPageLoad: refDoLoadPageData
  });
  //#endregion

  //#region SessionManager
  let sessionManager = UseSessionManager({
    pageDataManager: pageDataManager,
  });
  //#endregion

  //#region :Popup :createModalBusinessHours
  const createModal_BusinessHours_refModal = useRef<any>(null);
  const createModal_BusinessHours = (e: any, tasks?: string[]): void => {
      if (e) {
          e.preventDefault();
      }
      // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
      //
      // if (!PassPageDataInitalized) {
      //     //empty
      // }

      const config = {
          // title: 'test',
          content: <Partial_Modal_BusinessHours
              PrefixTarget={'Partial_Modal_BusinessHours'}
              pageDataManager={pageDataManager}
              sessionManager={sessionManager}
              navigate={navigate}

              viewModel={{
                  refModal: createModal_BusinessHours_refModal,
              }}
          />,
          okButtonProps: { disabled: true, className: "modal-footer-hiden-button" },
          className: 'myClass myModal',
          width: '40em',
          height: ' ',
          footer: null,
          //prefixCls: '',
      };

      createModal_BusinessHours_refModal.current = Modal.info(config);
  }


  //#region Boot
  const state = UseCompState({ parentState: props.parentState });

  let defaultS = '全部';
  let defaultQ = '';

  if (!state.hasInit) {
    state.hasInit = true;

    state.pageDataManager = pageDataManager;
    state.sessionManager = sessionManager;
    state.loadingManager = loadingManager;
    state.navigate = navigate;
    state.forceRender = forceRender;

    state.subscribed = [];

    state.s = pageDataManager.get(`${PrefixTarget}.s`) ?? defaultS;
    state.q = pageDataManager.get(`${PrefixTarget}.q`) ?? defaultQ;
  }

  useEffect(() => {
    const _loadPageData = async (): Promise<void> => {
      let hide = message.loading({
        type: 'loading',
        class: 'myMessage',
        content: <div className="myMessageBody">搜尋中...</div>,
        key: 'loading',
        duration: 0
      });

      // VALIDATE INPUT
      //const id : any = this.props.match.params.id;
      // const id = 1;
      // if (!id) {
      //     return; //ERROR INPUTS
      // }

      // WAITING SCREEN
      loadingManager.changeLoadingCount(+1);
      //StaticEvent.LoadingEvent.next(true);

      // POST
      let postData = {
        //p1: 1,
        s: state.s,
        q: state.q,
        timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
      };
      const postUrl = ApiService.urlMapping('~/pet_api/1/pg_waitingroom');
      const post$ = Static.apiService.post(postUrl, postData);
      post$.subscribe(async (res: any) => {
        hide();

        // CLOSE WAITING SCREEN
        loadingManager.changeLoadingCount(-1);
        //StaticEvent.LoadingEvent.next(false);

        let data = res.data;
        let error = data.error || null;
        let result = error == null;

        if (result) { // SUCEESS
          let payload = data.payload;
          _._debug.warn(payload);

          pageDataManager.emptyZone(PrefixTarget);
          pageDataManager.merge({
            ...payload,
          });
          pageDataManager.apply();

          pageDataManager.forceRender();
        } else {
          // AUTH USER EXPIRED T:1
          if (error.code == 2) {
            await sessionManager.setLogout();
            Static.NavAwayToLogin(pageDataManager, navigate)(null);
            return;
          }

          _._debug.error(error);
          alert(error.message);
        }
      }, async (err) => {
        // CLOSE WAITING SCREEN
        loadingManager.changeLoadingCount(-1);
        //StaticEvent.LoadingEvent.next(false);
        _._debug.warn(err);
        alert('網路連線有問題');
      }
      );
      return lastValueFrom(
        post$
      );
    }
    const _doLoadPageData = async (): Promise<void> => {
      pageDataManager.restore();
      if (!pageDataManager.isZoneExist(PrefixTarget)) {
        await sessionManager.initSessionAuthAsync();
        //#region User Check //TODO: VERTIFY
        if (!sessionManager.isMemberLoggedIn()) {
          Static.NavAwayToLogin(pageDataManager, navigate)(null);
          return;
        }
        //#endregion
        await _loadPageData();
      } else {
        forceRender()
      }
    }

    refDoLoadPageData.current = _doLoadPageData;

    // StaticEvent.Event_NavAway.next({
    //   MenuTemplateId: props.MenuTemplateId
    // });

    _doLoadPageData();
  }, [location.pathname]);
  //#endregion


  const [noCase, setNoCase] = useState(false);






  return (
    <div className="page-appointment page-content">
      <div className="info-list">
        <div className="info-box">
          <Title level={2}>營業時段管理</Title>
          <div className="pluginfobox">
            <div className="title"></div>
            <div className="info"></div>
          </div>

          <div className="pluginfobox">
            <div className="title"></div>
            <div className="info"></div>
          </div>

        </div>
        <div className="function-box">
          <button  >

          </button>

        </div>
      </div>


      <div className="page-manage conpage">
        <div className="wrapper column_2 temp_64">
          <div className="lefside">
            <div className="infoboxoutside">
              <div className="infobox">
                <div className="titlebar">
                  <h3>營業時間</h3>
                  <div className="barcontent">
                  </div>
                  <div className="func">
                    <Tooltip title="編輯">
                      <Button icon={<EditOutlined />} size='small' type="link" onClick={createModal_BusinessHours} />
                    </Tooltip>
                  </div>
                </div>
                <div className="boxcontent shift_box">
                  <div className="shift">
                    <div className="num">3</div>
                    <div className="txt">班制</div>
                  </div>
                  <div className="shift_itemlist">
                    <div className="shift_item">
                      <div className="th">早上</div>
                      <FieldTimeOutlined />
                      <div className="con">09:00~12:00</div>
                    </div>
                    <div className="shift_item">
                      <div className="th">下午</div>
                      <FieldTimeOutlined />
                      <div className="con">13:00~18:00</div>
                    </div>
                    <div className="shift_item">
                      <div className="th">晚上</div>
                      <FieldTimeOutlined />
                      <div className="con">19:00~21:00</div>
                    </div>
                    <div className="shift_item">
                      <div className="th">急診</div>
                      <FieldTimeOutlined />
                      <div className="con">09:00~21:00</div>
                    </div>
                  </div>
                  <div className="notebar">
                    <Alert
                      message="此為預設班制"
                      description="目前設定為系統預設班制，可以透過編輯調整成更符合的診所的營業時間."
                      type="info"
                      showIcon
                      closable
                    />

                  </div>
                </div>
                <div className="boxfooter"></div>
              </div>

              <div className="infobox mt1">
                <div className="titlebar">
                  <h3>排班表</h3>
                  <div className="barcontent">
                  </div>
                  <div className="func">

                  </div>
                </div>
                <div className="boxcontent">
                  <div className="mainbtn large i_calendar block">
                    <div className="icon"></div>
                    <div className="txt">本月份排班表</div>
                  </div>
                  <div className="notebar">
                    <Alert
                      message="班表還沒有任何安排"
                      description="班表還沒有任何安排，你可以將人員安排到適合的時間."
                      type="warning"
                      showIcon
                      closable
                    />

                  </div>
                </div>
                <div className="boxfooter"></div>
              </div>
            </div>
          </div>
          <div className="rightside">
            <div className="infoboxoutside">

            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

Pg_Manage_Hours_.displayName = 'Pg_Manage_Hours_';




























