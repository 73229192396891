import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {Popover, Button, Modal, message, Upload} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";
import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

var moment = require('moment');

type ViewModelProps = {
    refModal: any,

    showBack?: boolean,
    showClose?: boolean,
    showCancel?: boolean,

    OptTitle?: (loadingManager, handleClose) => any,
    OptOkBtn?: (loadingManager, handleClose) => any,
    OptContentBody?: (loadingManager, handleClose) => any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,

    viewModel: ViewModelProps,
};
export default function Partial_Modal_Confirm(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_Modal_Confirm';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);

    viewModel.showBack = viewModel.showBack ?? false;
    viewModel.showClose = viewModel.showClose ?? false;
    viewModel.showCancel = viewModel.showCancel ?? false;

    viewModel.OptTitle = viewModel.OptTitle ?? undefined; //<div>會員獨享</div>;
    viewModel.OptOkBtn = viewModel.OptOkBtn ?? undefined; //<button className="button saveAct red" type="submit">送出</button>;
    viewModel.OptContentBody = viewModel.OptContentBody ?? undefined; //請登入會員，立即收藏喜愛文章！

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region PageData

    //#region :BoxInit
    //#region Boot
    const _doLoadPartialData = async (): Promise<void> => {
        if (!pageDataManager.refPageData.current.Root) {
            return;
        }

        /// do not dynamically load data if condition fits.
        if (pageDataManager.isZoneExist(PrefixTarget)) {
            return;
        }
        //await _loadPageData();
    }

    useEffect(() => {
        _doLoadPartialData();
    }, []);
    //#endregion
    //#endregion

    //region :BoxPreRender (Ordered)


    //#region Set ContentItem
    const ContentItem: any = (() => {
        {
            //#region Handlers
            const handleClose = (e) => {
                if (e) {
                    e.preventDefault();
                }

                if (viewModel.refModal || viewModel.refModal.current) {
                    viewModel.refModal.current.destroy();
                }
            };
            //#endregion

            return <React.Fragment>
                <div className="genericModalView modalConfirm">
                    <div className="t1">
                        <div className="t1Body">
                            <div className="d1">
                                {viewModel.showBack &&
                                <button className="backButton"
                                        onClick={(e) => handleClose(e)}/>
                                }
                                {viewModel.OptTitle && viewModel.OptTitle(loadingManager, handleClose)}
                                {viewModel.showClose &&
                                <button className="closeButton"
                                        onClick={(e) => handleClose(e)}/>
                                }
                            </div>

                            <div className="d2">
                                <div className="d2d1">
                                    {viewModel.OptContentBody && viewModel.OptContentBody(loadingManager, handleClose)}
                                </div>
                                <div className="d2d2">
                                </div>
                                <div className="d2d4">
                                    <div className="buttons">
                                        {viewModel.showCancel && <button className="button cancelAct" onClick={(e) => handleClose(e)}>取消</button>}
                                        {viewModel.OptOkBtn && viewModel.OptOkBtn(loadingManager, handleClose)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>;
        }
        return null;
    });
    //#endregion

    //#endregion

    //#endregion

    //#region Render
    return <React.Fragment>
        <ContentItem/>
    </React.Fragment>;
    //#endregion
}

Partial_Modal_Confirm.displayName = 'Partial_Modal_Confirm';
