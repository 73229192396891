import sharedConfig from "./config";
import update from "immutability-helper";

declare var window: any;

const util = {
    randId: () => {
        return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
    },

    commaSep: (num: any) => {
        return (+(num ?? 0)).toLocaleString('en-US', {maximumFractionDigits: 2});
    },
    removeTailingSlash: (item: string) => {
        return item.replace(/\/$/, "");
    },

    nullify: (value: string | null | undefined | Date) => {
        if (value instanceof Date) {
            return value;
        }
        if (value) {
            if (value.length == 0)
                return null;
        }
        return value;
    },

    sleepAsync: (time) => {
        return new Promise((resolve) => setTimeout(resolve, time));
    },

    coalesce: (...args) => {
        for (let v of args) {
            if (v === undefined || v === null || v === 0) {
                /* do nothing */
            } else {
                return v;
            }
        }
    },

    randomStringID: (length, chars) => {
        let result = '';
        if (chars == undefined)
            chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    },


    /**
     * Construct given namespace on variable
     * for example:
     *  namespace(objA, 'propA.propB') ,  objA.propA ,  objA,propB will be created if not already exist
     * @param variable
     * @param ns  namespace path
     */
    namespace: (variable, ns) => {
        let sofar = variable;

        ns.split('.').forEach(function (e, i) {
            if (typeof sofar[e] === "undefined") {
                sofar[e] = {};
            }
            sofar = sofar[e];
        });

        return sofar;
    },


    namespaceSet: (variable, ns, newValue) => {
        let sofar = variable;
        let parts = ns.split('.');

        if (parts.length > 1) {
            let lastPart = parts[parts.length - 1];
            parts.slice(0, -1).forEach(function (e, i) {
                if (typeof sofar[e] === "undefined") {
                    sofar[e] = {};
                }
                sofar = sofar[e];
            });

            if (Object.is(sofar[lastPart], newValue)) {
                return false;
            }
            if (sofar[lastPart] === newValue) {
                return false;
            }
            sofar[lastPart] = newValue;
            return true;
        } else {
            if (Object.is(variable[ns], newValue)) {
                return false;
            }
            if (variable[ns] === newValue) {
                return false;
            }
            return true;
        }
        return false;
    },

    /**
     * Check variable defines given namspace path
     * for example:
     *  exist(objA, 'propA.propB') checkes whether objA.propA.propB defined
     * @param variable
     * @param ns  namespace path
     */
    exist: (variable, ns) => {
        if (variable === null) {
            return false;
        }
        if (variable === undefined) {
            return false;
        }

        let sofar = variable;

        let splits = ns.split('.');
        for (let i = 0; i < splits.length; ++i) {
            if (sofar === null) {
                return false;
            }
            if (typeof sofar[splits[i]] === "undefined") {
                return false;
            }
            sofar = sofar[splits[i]];
        }
        return true;
    },

    /**
     * Check variable defines given namspace path
     * for example:
     *  exist(objA, 'propA.propB') checkes whether objA.propA.propB defined
     * @param variable
     * @param ns  namespace path
     */
    existGet: (variable, ns, defaultValue = null) => {
        if (variable === null) {
            return defaultValue;
        }
        if (variable === undefined) {
            return defaultValue;
        }

        let sofar = variable;

        let splits = ns.split('.');
        for (let i = 0; i < splits.length; ++i) {
            if (sofar === null) {
                return defaultValue;
            }
            if (typeof sofar[splits[i]] === "undefined") {
                return defaultValue;
            }
            sofar = sofar[splits[i]];
        }
        return sofar;
    },


    isIn: (array, element) => {
        for (let i = 0; i < array.length; i++) {
            if (array[i] == element) {
                return true;
            }
        }
        return false;
    },

    /**
     * Remove first occurrence
     * @param array Only array is supported
     * @param element
     */
    removeFirst: (array, element) => {
        if (array === null) {
            return array;
        }

        if (!util.isArray(array)) {
            for (let i = 0; i < array.length; i++) {
                if (array[i] == element) {
                    return array.splice(i, 1);
                }
            }
        } else {
            throw 'unsupported';
        }
    },

    /**
     * Remove and return all occurrences
     * @param array Only array is supported
     * @param element
     */
    remove: (array, element) => {
        if (array === null) {
            return array;
        }

        if (!util.isArray(array)) {
            let removed: object[] = [];

            for (let i = 0; i < array.length;) {

                if (array[i] == element) {
                    removed.push(array.splice(i, 1));
                    continue;
                }

                i++;
            }
            return removed;
        } else {
            throw 'unsupported';
        }
    },

    /**
     * Return true if value is an empty array, or an empty string, return given value otherwise
     * @param value  Array or String
     */
    isEmpty: (value) => {
        if (value == null)
            return null;

        if (util.isArray(value)) {
            if (value.length === 0) {
                return true;
            } else {
                return false;
            }
        }

        if (util.isString(value)) {
            if (value.length === 0) {
                return true;
            } else {
                return false;
            }
        }

        return value;
    },

    isString: (obj) => {
        return (typeof obj === 'string' || obj instanceof String)
    },

    isArray: (obj) => {
        return Array.isArray(obj);
    },

    isObject: (obj) => {
        let type = typeof obj;
        return type === 'function' || type === 'object' && !!obj;
    },

    /**
     * This does a deep cloned copy without checking for circular references.
     * @param src
     */
    iterationCopy: (src) => {
        let target = {};
        for (let prop in src) {
            if (src.hasOwnProperty(prop)) {
                // if the value is a nested object, recursively copy all it's properties
                if (util.isObject(src[prop])) {
                    target[prop] = util.iterationCopy(src[prop]);
                } else {
                    target[prop] = src[prop];
                }
            }
        }
        return target;
    }
};


const debug = {
    error: (errors) => {
        if (sharedConfig.APP_MODE === 'dev' || sharedConfig.APP_MODE === 'prod') {
            console.error(errors);
        } else {
            // nothing
        }
    },
    warn: (warning) => {
        if (sharedConfig.APP_MODE === 'dev' || sharedConfig.APP_MODE === 'prod') {
            console.warn(warning);
        } else {
            // nothing
        }
    },
    log: (logs) => {
        if (sharedConfig.APP_MODE === 'dev' || sharedConfig.APP_MODE === 'prod') {
            console.log(logs);
        } else {
            // nothing
        }
    },
};


const G = Function('return this')();
//G.test.history = history;
G.sharedStateManager = sharedConfig;
window.G = G;

// var _global = (0, eval)('this');
// _global.location = {};
// _global.window.location = {};

const bs = {
    getCoverSafeList: (照片組: string) => {
        const re = /\s*(?:,|$)\s*/;
        const photoList = [...((照片組 ?? '') + (",")).split(',').filter(r => r !== '' || null || undefined), null];
        return photoList;
    },

    getCommaList: (照片組: string) => {
        const re = /\s*(?:,|$)\s*/;
        const photoList = [...((照片組 ?? '') + (",")).split(',').filter(r => r !== '' || null || undefined)];
        return photoList;
    },

    identity: (value) => (value),


    determineRoute: () => {


    },
    Form: {
        required: value => {
            if (value) {
                if (value === "") {
                    return "此為必填欄位";
                }
            } else {
                return "此為必填欄位";
            }

            return undefined;
        },
        mustBeNumber: value => (isNaN(value) ? "Must be a number" : undefined),
        minValue: min => value =>
            isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`,
        composeValidators: (...validators) => value =>
            validators.reduce((error, validator) => error || validator(value), undefined),
        composeForwarders: (...forwarders) => value =>
            forwarders.reduce((last, forwarder) => {
                if (last) {
                    return last && forwarder(value);
                } else {
                    return last;
                }
            }, true),
    },
}

const _u = {
    update: update,
}
export default {
    G,
    sharedState: sharedConfig,
    util: util,
    _debug: debug,
    bs,

    _u,
};
