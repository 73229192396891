import React, { useCallback, useEffect, useRef, useState } from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import { Form as NForm, Popover, Button, Modal, message, Upload, Input, Tooltip, Select } from 'antd';

import { ApiService } from "../_/ApiService";
import { Static } from "../_/Static";
import { StaticEvent } from "../_/StaticEvent";
import _ from "../_";

import { Link } from 'react-router-dom';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import { PageDataManager } from "../hooks/UsePageDataManager";

import { Field, Form } from "react-final-form";
import { SessionManager } from "../hooks/UseSessionManager";
import { NavigateFunction } from "react-router";

import { LoadingOutlined, LockOutlined, PlusOutlined, CloseOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { lastValueFrom } from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_Appointment__Modal_AddPet from "./Partial_Appointment__Modal_AddPet";
import UseCompState from "../hooks/UseCompState";

var moment = require('moment');
const { Option } = Select;

type ViewModelProps = {
    refModal: any,

    住院籠子No: any,
    onDone?: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any,

    viewModel: ViewModelProps,
};
export default function Partial_inHospital__Pane_EditOrAddRoom(props: Props) {
    const PrefixTarget: string = props.PrefixTarget || 'Partial_inHospital__Pane_EditOrAddRoom';
    const pageDataManager: PageDataManager = props.pageDataManager;
    const sessionManager: SessionManager = props.sessionManager;
    const navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel = props.viewModel;

    const layout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 19 },
    };

    //#region :Popup :createModalPet
    const createModalPet_refModal = useRef<any>(null);
    const createModalPet = (e: any, { carryData, tasks }: {
        carryData?: any,
        tasks?: string[]
    }): void => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_Appointment__Modal_AddPet
                PrefixTarget={'Partial_Appointment__Modal_AddPet'}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModalPet_refModal,
                    carryData: carryData,
                }}
            />,
            okButtonProps: { disabled: true, className: "modal-footer-hiden-button" },
            className: 'myClass myModal',
            width: '60em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModalPet_refModal.current = Modal.info(config);
    }
    //#endregion

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({ shouldReRenderOnLoading: false });

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

        if (viewModel.住院籠子No == null) {
            pageDataManager.emptyZone(PrefixTarget);
            let changeShard = {
                [PrefixTarget]: {}
            }
            pageDataManager.merge(changeShard);
            pageDataManager.apply();
        }
    }
    //#endregion

    //#region Boot
    let _refDoLoadPartialData: any = useRef<{ alwaysLoad?: boolean }>(null);
    useEffect(() => {
        const _loadPartialData = async (): Promise<void> => {

            // VALIDATE INPUT
            //const id : any = this.props.match.params.id;
            const p1 = viewModel.住院籠子No;
            if (!p1) {
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/partial_inhospital/pane_viewcard');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        let changeShard = {
                            [PrefixTarget]: {
                                ...payload,
                            }
                        }
                        pageDataManager.merge(changeShard);
                        pageDataManager.apply();

                        forceRender();

                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPartialData = async ({alwaysLoad = false}): Promise<void> => {
            // if (!pageDataManager.refPageData.current.Root) {
            //     return;
            // }

            /// do not dynamically load data if condition fits.
            if (!alwaysLoad) {
                if (pageDataManager.isZoneExist(PrefixTarget)) {
                    return;
                }
            }

            if (viewModel.住院籠子No !== null) {
                await _loadPartialData();
            }
        }

        if (_refDoLoadPartialData.current === null) {
            _refDoLoadPartialData.current = doLoadPartialData;
            _refDoLoadPartialData.current({});
        } else {
            _refDoLoadPartialData.current({});
        }
    }, [pageDataManager.get(PrefixTarget)]);
    //#endregion

    //#region Set Body
    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };
    const isModalContained = () => {
        return !!viewModel.refModal?.current;
    }
    const Body = () => {

        const RegionEmptyShow = true;
        const RegionLoadingShow = true;

        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;

        let Item_住院籠子 = pageDataManager.get(`${PrefixTarget}.Item_住院籠子`);

        //#region Handlers
        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            return errors;
        };

        const handleSubmit_save = (e: any, v: any) => {
            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            //const id : any = this.props.match.params.id;

            const p1 = v;
            if (!p1) {
                _._debug.error(p1);
                return; //ERROR INPUTS
            }


            // let pictures = [] as any;
            // state.files.map((v, i) => {
            //     console.log(v)
            //     pictures.push(v.id);
            // });
            // const p2 = pictures.join(',');
            // if (!p2) {
            //     _.debug.error(p1);
            //     return; //ERROR INPUTS
            // }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_inhospital/pane_editoraddroom/save');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);


                        let hide = message.loading({
                            type: 'loading',
                            className: 'myMessage',
                            content: <div className="myMessageBody">修改完成</div>,
                            key: 'loading',
                            duration: 2
                        });

                        handleClose(null);

                        // if (viewModel.用戶No === null) { //is insert
                        //     StaticEvent.castUpdateEvent({
                        //         command: StaticEvent.UpdateFacade.Command.Add_用戶,
                        //         Add_用戶: {
                        //             用戶No: payload.Item_用戶.用戶No,
                        //         }
                        //     });
                        // } else {
                        //     StaticEvent.castUpdateEvent({
                        //         command: StaticEvent.UpdateFacade.Command.Update_用戶,
                        //         Update_用戶: {
                        //             用戶No: payload.Item_用戶.用戶No,
                        //         }
                        //     });
                        // }

                        // let carryData = _._u.update(payload,
                        //     {
                        //         $merge: {
                        //             Tokens: payload?.Tokens,
                        //         },
                        //         Item_飼主: {
                        //             $set: payload?.Item_飼主,
                        //         }
                        //     }
                        // );
                        // carryData.Item_飼主 = p1;
                        // if (onDone) {
                        //     const ret = onDone(carryData);
                        //     if (ret === 'demo') {
                        //         createModalPet(null, {
                        //             carryData,
                        //         })
                        //     }
                        // } else {
                        //     alert('onDone not defined');
                        // }

                        _refDoLoadPartialData.current({alwaysLoad: true});
                        viewModel?.onDone?.(payload);

                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }

        const errorop = () => {
            message.error('此區域內還有資料，無法刪除');
        };
        //#endregion

        if (!PassPrefixTargetLoaded) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <div>Loading.</div>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        // if (Item_住院籠子 === null) {
        //     if (RegionEmptyShow) {
        //         return <React.Fragment>
        //             <div className="d3">
        //                 <div>Empty.</div>
        //             </div>
        //         </React.Fragment>;
        //     }
        // }
        const children = [] as any;
        return (
            <Form
                onSubmit={(v) => handleSubmit_save(null, v)}
                initialValues={{
                    ...Item_住院籠子,
                }}
                validate={handleValidate}
                render={({
                    submitError,
                    handleSubmit,
                    submitting,
                    pristine,
                    valid,
                    error,
                    values
                }) => {

                    return <React.Fragment>
                        <div className="form_set">
                            <form
                                onSubmit={handleSubmit}
                                {...layout}>



                                <Field parse={_.bs.identity} name="區域籠號" validate={_.bs.Form.required}>
                                    {
                                        ({ input, meta }) => {
                                            let specProp: any = {};
                                            if (!input.value) {
                                                specProp.defaultValue = [];
                                            } else {
                                                let values = input.value.split(',');
                                                specProp.defaultValue = values;
                                            }
                                            specProp.onChange = (arr) => {
                                                input.onChange(Array.prototype.join.call(arr));
                                            }

                                            return <React.Fragment>
                                                <NForm.Item {...input} {...layout}
                                                    name="cagearea"
                                                    label="區域籠號"
                                                >
                                                    <Select {...specProp}
                                                            mode="tags"
                                                            style={{width: '100%'}} placeholder="請輸入區域"></Select>
                                                </NForm.Item>
                                                {meta.touched && meta.error &&
                                                <div className="error">
                                                    {meta.error}
                                                </div>}
                                            </React.Fragment>
                                        }
                                    }
                                </Field>

                                <React.Fragment>
                                    <NForm.Item>
                                        <div className="form_footer">
                                            {isModalContained() && <React.Fragment>
                                                <Button type='primary' danger ghost
                                                        onClick={(e) => handleClose(e)}>放棄編輯</Button>
                                            </React.Fragment>
                                            }
                                            <Button type="primary" htmlType="submit" size="large"
                                                onClick={handleSubmit} disabled={(submitting)}>確認</Button>
                                        </div>
                                    </NForm.Item>
                                </React.Fragment>
                            </form>
                        </div>
                    </React.Fragment>;
                }}
            />
        );
    };
    //#endregion

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_inHospital__Pane_EditOrAddRoom.displayName = 'Partial_inHospital__Pane_EditOrAddRoom';
