import React, {useEffect, useRef} from 'react';
import {useState} from 'react';
import './Box_Pagetitle.scss';
import {Avatar, Menu, Dropdown, message} from 'antd';
import {Link, useNavigate} from 'react-router-dom';
import {useApp} from "../overmind";
import UseLoadingManager from "../hooks/UseLoadingManager";
import UsePageDataManager from "../hooks/UsePageDataManager";
import UseSessionManager from "../hooks/UseSessionManager";
import {Static} from "../_/Static";
import {ApiService} from "../_/ApiService";
import _ from "../_";
import {lastValueFrom} from "rxjs";

interface Props {
    copmanyname: string;
}

const UserList = ['U', 'Lucy', 'Tom', 'Edward'];
const ColorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];

function Box_Pagetitle(props: Props) {
    const navigate = useNavigate();

    const {state, actions} = useApp();
    const [user, setUser] = useState(UserList[0]);

    const logout = () => {
        localStorage.removeItem('lguser');
        actions.cleanSession();
        // window.location.reload();
    }

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region PageDataManager
    const refDoLoadPageData = Static.refDoLoadPageData;
    let pageDataManager = UsePageDataManager({
        refPageLoad: refDoLoadPageData
    });
    //#endregion

    //#region SessionManager
    let sessionManager = UseSessionManager({
        pageDataManager: pageDataManager,
    });
    //#endregion

    useEffect(() => {
        // (async () => {
        //     debugger;
        //     if (!sessionManager.hasInitOnce()) {
        //         await sessionManager.initSessionAuthAsync();
        //         forceRender();
        //     }
        // })();
        forceRender();
    }, [pageDataManager.get('Box_Header')]);


    const handle_ClickLogout = async (e: any) => {
        // if (e) {
        //     e.preventDefault();
        // }

        let hide = message.success({
            type: 'success',
            className: 'myMessage',
            content: <div className="myMessageBody">登出完成</div>,
            key: 'success',
            duration: 5
        });

        await sessionManager.setLogout();
        pageDataManager.clearIncludeAuth();
        pageDataManager.apply();

        Static.NavAwayToLogin(pageDataManager, navigate)(null);

        //await pageDataManager.reloadAsync({alwaysLoad: true});
        //forceRender();
    }
    //#endregion

    const menu = (
        <Menu>
            {/*<Menu.Item>*/}
            {/*    <Link to="/membercenter">*/}
            {/*        設定*/}
            {/*    </Link>*/}
            {/*</Menu.Item>*/}
            {/*<Menu.Item>*/}
            {/*    <a target="_blank" rel="noopener noreferrer" href="www.pettalk.tw">*/}
            {/*        PetTalk*/}
            {/*    </a>*/}
            {/*</Menu.Item>*/}
            <Menu.Item danger onClick={handle_ClickLogout}>登出</Menu.Item>
        </Menu>
    );


    const Item_用戶 = pageDataManager.get(`Box_Header.Item_用戶`);

    let contentMemberBox: any = null;
    if (Item_用戶) {
        contentMemberBox = <div className="member-box" key={Item_用戶.用戶Id}>
            <Dropdown overlay={menu}>
            <a>
                {Item_用戶.名稱} {Item_用戶.職稱}
                <Avatar
                    size="large"
                    style={{backgroundColor: '#1890FF'}}
                >
                    {user}
                </Avatar>
            </a>
            </Dropdown>
        </div>;
    } else {
        contentMemberBox = <div className="member-box" key="0">
        </div>;
    }

    return (
        <div className="page-title-box">
            <div className="clinic-name">
                &nbsp;
                {/*{props.copmanyname}*/}
            </div>
            {contentMemberBox}
        </div>
    )
}

export default Box_Pagetitle;
