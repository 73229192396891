import React, { useCallback, useEffect, useRef, useState } from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import { Form as NForm, Popover, Button, Modal, message, Upload, Input, Select, Space, Tabs, Tooltip, Steps } from 'antd';

import { ApiService } from "../_/ApiService";
import { Static } from "../_/Static";
import { StaticEvent } from "../_/StaticEvent";
import _ from "../_";

import { Link } from 'react-router-dom';


import {
    useParams,
    useNavigate
} from "react-router-dom";
import { PageDataManager } from "../hooks/UsePageDataManager";

import { Field, Form } from "react-final-form";
import { SessionManager } from "../hooks/UseSessionManager";
import { NavigateFunction } from "react-router";

import { LoadingOutlined, LockOutlined, PlusOutlined, RedoOutlined, CloseOutlined } from "@ant-design/icons";
import { lastValueFrom } from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_addOwnerProxy from "./Partial_addOwnerProxy";
import Partial_Manage_Campaign__Pane_EditOrAddCampaign from "./Partial_Manage_Campaign__Pane_EditOrAddCampaign";
import Partial_Manage_Staff__Pane_EditOrAddStaff from "./Partial_Manage_Staff__Pane_EditOrAddStaff";


var moment = require('moment');
const { Option } = Select;
const { Search } = Input;
const onSearch = value => console.log(value);
const { TabPane } = Tabs;
const { Step } = Steps;

type ViewModelProps = {
    refModal: any,

    票劵No: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,

    viewModel: ViewModelProps,
};
export default function Partial_Manage_Campaign__Modal_EditOrAddCampaign(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_Manage_Campaign__Modal_EditOrAddCampaign';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    const viewModel: any = props.viewModel;


    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion


    const loadingManager = UseLoadingManager({ shouldReRenderOnLoading: false });

    //#region Boot
    const _doLoadPartialData = async (): Promise<void> => {
        if (!pageDataManager.refPageData.current.Root) {
            return;
        }

        /// do not dynamically load data if condition fits.
        if (pageDataManager.isZoneExist(PrefixTarget)) {
            return;
        }
        //await _loadPageData();
    }
    useEffect(() => {
        _doLoadPartialData();
    }, []);
    //#endregion

    //#region Set Body
    const isModalContained = () => {
        return !!viewModel.refModal?.current;
    }

    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };

    const Body = () => {
        return (
            <div className="model_wrapper">
                <div className="modal_title">
                    {viewModel.票劵No === null ? <React.Fragment>
                        <div className="title_info">
                            <h2>新增優惠票券</h2>
                        </div>
                    </React.Fragment> : <React.Fragment>
                        <div className="title_info">
                            <h2>編輯優惠票券</h2>
                        </div>
                    </React.Fragment>
                    }

                    <div className="info_bar"></div>
                    <div className="control_bar">
                        <Tooltip placement="top" title={'關閉'}>
                            <Button type="text" icon={<CloseOutlined/>} onClick={handleClose}/>
                        </Tooltip>
                    </div>
                </div>
                <div className="modal_content">
                    <div className="content_box">
                        {viewModel.票劵No === null ?
                            <Partial_Manage_Campaign__Pane_EditOrAddCampaign
                                key='new'
                                //PrefixTarget={'Partial_Search_Or'}
                                pageDataManager={pageDataManager}
                                sessionManager={sessionManager}
                                navigate={navigate}

                                viewModel={{
                                    refModal: viewModel.refModal,

                                    票劵No: viewModel.票劵No,
                                }}
                            /> :
                            <Partial_Manage_Campaign__Pane_EditOrAddCampaign
                                //PrefixTarget={'Partial_Search_Or'}
                                pageDataManager={pageDataManager}
                                sessionManager={sessionManager}
                                navigate={navigate}

                                viewModel={{
                                    refModal: viewModel.refModal,

                                    票劵No: viewModel.票劵No,
                                }}
                            />
                        }
                    </div>
                </div>
                <div className="modal_footer"></div>
            </div>
        );
    };
    //#endregion

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_Manage_Campaign__Modal_EditOrAddCampaign.displayName = 'Partial_Manage_Campaign__Modal_EditOrAddCampaign';
