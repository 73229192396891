import React, { useCallback, useEffect, useRef, useState } from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import { Form as NForm, Popover, Button, Modal, message, Upload, Input, Select, Space, Tabs, Tooltip, Steps } from 'antd';

import { ApiService } from "../_/ApiService";
import { Static } from "../_/Static";
import { StaticEvent } from "../_/StaticEvent";
import _ from "../_";

import { Link } from 'react-router-dom';


import {
    useParams,
    useNavigate
} from "react-router-dom";
import { PageDataManager } from "../hooks/UsePageDataManager";

import { Field, Form } from "react-final-form";
import { SessionManager } from "../hooks/UseSessionManager";
import { NavigateFunction } from "react-router";

import { LoadingOutlined, LockOutlined, PlusOutlined, RedoOutlined, CloseOutlined } from "@ant-design/icons";
import { lastValueFrom } from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_Appointment__Pane_AddOwner from "./Partial_Appointment__Pane_AddOwner";
import Partial_Search_Or from "./Partial_Search_Or";
import Partial_Appointment__Modal_AddPet from "./Partial_Appointment__Modal_AddPet";
import Partial_Appointment__Pane_AddPet from "./Partial_Appointment__Pane_AddPet";


var moment = require('moment');
const { Option } = Select;
const { Search } = Input;
const onSearch = value => console.log(value);
const { TabPane } = Tabs;
const { Step } = Steps;

type ViewModelProps = {
    refModal: any,
    carryData?: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,

    viewModel: ViewModelProps,
};
export default function Partial_Appointment__Modal_AddOwner(props: Props) {
    const PrefixTarget: string = props.PrefixTarget || 'Partial_Appointment__Modal_AddOwner';
    const pageDataManager: PageDataManager = props.pageDataManager;
    const sessionManager: SessionManager = props.sessionManager;
    const navigate: NavigateFunction = props.navigate;

    const viewModel = props.viewModel;

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({ shouldReRenderOnLoading: false });

    //#region Boot
    const _doLoadPartialData = async (): Promise<void> => {
        if (!pageDataManager.refPageData.current.Root) {
            return;
        }

        /// do not dynamically load data if condition fits.
        if (pageDataManager.isZoneExist(PrefixTarget)) {
            return;
        }
        //await _loadPageData();
    }
    useEffect(() => {
        _doLoadPartialData();
    }, []);
    //#endregion

    const Body = () => {
        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            // if (!values.username) {
            //     errors.username = '↑ 欄位為必填';
            // } else {
            // }
            //
            // if (!values.password) {
            //     errors.password = '↑ 欄位為必填';
            // } else {
            // }

            return errors;
        };

        const handleClose = (e) => {
            if (e) {
                e.preventDefault();
            }

            if (viewModel.refModal || viewModel.refModal.current) {
                viewModel.refModal.current.destroy();
            }
        };


        //#region :Popup :createModalPet
        const createModalPet_refModal = useRef<any>(null);
        const createModalPet = (e: any, {carryData, tasks, modalAddonProp}: {
            modalAddonProp?:object,
            carryData?: any,
            tasks?: string[]
        }): void => {
            if (e) {
                e.preventDefault();
            }
            // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
            //
            // if (!PassPageDataInitalized) {
            //     //empty
            // }

            const config = {
                // title: 'test',
                content: <Partial_Appointment__Modal_AddPet
                    PrefixTarget={'Partial_Appointment__Modal_AddPet'}
                    pageDataManager={pageDataManager}
                    sessionManager={sessionManager}
                    navigate={navigate}

                    viewModel={{
                        refModal: createModalPet_refModal,
                        carryData: carryData,
                    }}

                    {...modalAddonProp}
                />,
                okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
                className: 'myClass myModal',
                width: '60em',
                height: ' ',
                footer: null,
                //prefixCls: '',
            };

            createModalPet_refModal.current = Modal.info(config);
        }
        //#endregion

        const onDone_keepCreateOwner = (data) => {
            let carryData = _._u.update(viewModel.carryData ?? {},
                {
                    $merge: data
                }
            );
            handleClose(null);

            createModalPet(null, {
                carryData,
            });
        }

        const onDone_bringOwner = (data) => {
            let carryData = _._u.update(viewModel.carryData ?? {},
                {
                    $merge: data
                }
            );

            handleClose(null);

            createModalPet(null, {
                carryData,
            });
        }

        return (
            <div className="model_wrapper">
                <div className="modal_title">
                    <div className="title_info">
                        <h2>步驟一：選擇飼主</h2>
                    </div>
                    <div className="info_bar"></div>
                    <div className="control_bar">
                        <Tooltip placement="top" title={'關閉'}>
                            <Button type="text" icon={<CloseOutlined/>} onClick={handleClose}/>
                        </Tooltip>
                    </div>
                </div>
                <div className="modal_content">
                    <Steps  >
                        <Step title="選擇飼主" />
                        <Step title="建立寵物" />
                        <Step title="預覽資料" />
                    </Steps>
                    <div className="content_box">
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="新建飼主" key="1">
                            <Partial_Appointment__Pane_AddOwner
                                PrefixTarget={'Partial_Appointment__Pane_AddOwner'}
                                pageDataManager={pageDataManager}
                                sessionManager={sessionManager}
                                navigate={navigate}

                                onDone={onDone_keepCreateOwner}
                            />
                            </TabPane>
                            <TabPane tab="帶入飼主" key="2">
                            <Partial_Search_Or
                                PrefixTarget={'Partial_Appointment__Pane_AddOwner'}
                                pageDataManager={pageDataManager}
                                sessionManager={sessionManager}
                                navigate={navigate}

                                onDone={onDone_bringOwner}
                            />
                        </TabPane>
                        </Tabs>
                    </div>
                </div>
                <div className="modal_footer"></div>
            </div>
        );
    };

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_Appointment__Modal_AddOwner.displayName = 'Partial_Appointment__Modal_AddOwner';
