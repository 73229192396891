import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {
    Form as NForm,
    Popover,
    Button,
    Modal,
    InputNumber,
    message,
    Tag,
    Table,
    Upload,
    Input,
    Select,
    Space,
    Tabs,
    Tooltip,
    Steps, Spin
} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';


import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";
import {LoadingOutlined, LockOutlined, EditOutlined, PlusOutlined, CloseOutlined} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_Appointment__Pane_AddOwner from "./Partial_Appointment__Pane_AddOwner";
import Partial_Search_Or from "./Partial_Search_Or";
import Partial_MedicalRecord__Modal_PrescribeAdd from "./Partial_MedicalRecord__Modal_PrescribeAdd";
import UseCompState from "../hooks/UseCompState";
import Ln_CommaListRender from "./Ln_CommaListRender";


var moment = require('moment');
const {Option} = Select;
const {Search} = Input;
const onSearch = value => console.log(value);
const {TabPane} = Tabs;
const {Step} = Steps;

type ViewModelProps = {
    refModal: any,

    病歷No: any,
    掛號No: any,

    OnDid?: any;
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any,

    viewModel: ViewModelProps,
};
export default function Partial_MedicalRecord__Modal_Prescribe(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_MedicalRecord__Modal_Prescribe';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel = props.viewModel;


    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion


    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

        state.forceRender = forceRender;
        state.DataSource_用藥 = [];
        state.List_用藥 = null;
    }
    //#endregion

    //#region Boot
    let _refDoLoadPartialData: any = useRef<{ alwaysLoad?: boolean }>(null);
    useEffect(() => {
        const _loadPartialData = async (): Promise<void> => {

            // VALIDATE INPUT
            //const id : any = this.props.match.params.id;
            // const p1 = viewModel.住院籠子No;
            // if (!p1) {
            //     return; //ERROR INPUTS
            // }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                //p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/partial_medicalrecord/modal_prescribe');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        let changeShard = {
                            [PrefixTarget]: {
                                ...payload,
                            }
                        }
                        pageDataManager.merge(changeShard);
                        pageDataManager.apply();

                        forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPartialData = async ({alwaysLoad = false}): Promise<void> => {
            // if (!pageDataManager.refPageData.current.Root) {
            //     return;
            // }

            /// do not dynamically load data if condition fits.
            if (!alwaysLoad) {
                if (pageDataManager.isZoneExist(PrefixTarget)) {
                    return;
                }
            }

            await _loadPartialData();
        }

        _refDoLoadPartialData.current = doLoadPartialData;
        _refDoLoadPartialData.current({});
    }, [pageDataManager.get(PrefixTarget)]);
    //#endregion

    //#region Set Body
    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };
    const Body = () => {
        const RegionEmptyShow = false;
        const RegionLoadingShow = true;
        const RegionInitialLoadingShow = false;

        const PassPageDataInitalized = pageDataManager.refPageData.current != null;
        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;

        let DataSource_用藥 = state.DataSource_用藥;
        let List_用藥 = state.List_用藥; //pageDataManager.get(`${PrefixTarget}.List_處理`);
        if (List_用藥 !== null) {
            let serialCounter = 0;
            DataSource_用藥 = List_用藥.map((Item_用藥) => {
                serialCounter += 1;

                if (serialCounter == 1) {
                }

                return {
                    entity: Item_用藥,
                    key: Item_用藥.用藥範本No,

                    分類組: Item_用藥.分類組,
                    品牌: Item_用藥.品牌,
                    品名: Item_用藥.品名,
                    庫存量: Item_用藥.庫存量,
                    貨品代號: Item_用藥.貨品代號,

                    份量: Item_用藥.份量,
                    用量: Item_用藥.用量,
                    藥劑處置: Item_用藥.藥劑處置,
                }
            });

            state.DataSource_用藥 = DataSource_用藥;
        }

        //#region Handlers
        const handleSubmit_saveRecord = (e: any, v: any) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }

            //#region Handle SaveUserInfo
            {
                // VALIDATE INPUT
                const p1 = v.病歷No;
                if (!p1) {
                    _._debug.error('p1');
                    return; //ERROR INPUTS
                }
                const p2 = v.List_用藥;
                if (!p2) {
                    _._debug.error('p2');
                    return; //ERROR INPUTS
                }
                const p3 = v.掛號No;
                if (!p3) {
                    alert('沒有掛號無法寫入')
                    _._debug.error('p3');
                    return; //ERROR INPUTS
                }

                let hide = message.loading({
                    type: 'loading',
                    className: 'myMessage',
                    content: <div className="myMessageBody">驗證中...</div>,
                    key: 'loading',
                    duration: 0
                });

                // WAITING SCREEN
                loadingManager.changeLoadingCount(+1);
                //StaticEvent.LoadingEvent.next(true);

                // POST
                let postData = {
                    p1,
                    p2,
                    p3,
                    timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
                };
                const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_medicalrecord/modal_prescribe/save');
                const post$ = Static.apiService.post(postUrl, postData);
                post$.subscribe(async (res: any) => {
                        hide();

                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);

                        let data = res.data;
                        let error = data.error || null;
                        let result = error == null;

                        if (result) { // SUCEESS
                            let payload = data.payload;
                            _._debug.warn(payload);

                            message.success({content: '建立成功，同時送往藥局審查!', key, duration: 2});
                            // const openMessage = () => {
                            //     message.loading({content: '正努力送往藥局...', key});
                            //     setTimeout(() => {
                            //         message.success({content: '藥局確認了你的藥單!', key, duration: 2});
                            //     }, 1000);
                            // };
                            //
                            // const saveMessage = () => {
                            //     message.loading({content: '儲存中...', key});
                            //     setTimeout(() => {
                            //         message.success({content: '儲存成功!', key, duration: 2});
                            //     }, 1000);
                            // };
                            //
                            //
                            // let hide = message.loading({
                            //     type: 'loading',
                            //     className: 'myMessage',
                            //     content: <div className="myMessageBody">建立成功</div>,
                            //     key: 'loading',
                            //     duration: 5
                            // });

                            handleClose(null);

                            state.parentState?._refDoLoadPartialData?.current({alwaysLoad: true});

                            // Static.NavAwayToAppointment(navigate, {
                            //     s: '手機號碼',
                            //     q: payload.Item_飼主.手機,
                            // })(null);

                            // let carryData = _._u.update(payload,
                            //     {
                            //         $merge: {
                            //             Tokens: payload?.Tokens,
                            //         },
                            //         Item_飼主: {
                            //             $set: payload?.Item_飼主,
                            //         }
                            //     }
                            // );
                            // carryData.Item_飼主 = p1;
                            // if (onDone) {
                            //     const ret = onDone(carryData);
                            //     if (ret === 'demo') {
                            //         createModalPet(null, {
                            //             carryData,
                            //         })
                            //     }
                            // } else {
                            //     alert('onDone not defined');
                            // }

                            //handleClose(null)

                        } else {
                            // AUTH USER EXPIRED
                            if (error.code == 2) {
                                //alert('帳號被登出，請重新登入');
                                //window.location.href = '/login';
                                //this.props.history.push('/login')
                                await sessionManager.setLogout();
                                pageDataManager.clear();
                                pageDataManager.reloadAsync({alwaysLoad: true});
                                alert('時間逾時已被登出');
                                return;
                            }

                            _._debug.error(error);
                            alert(error.message);
                        }
                    }, async (err) => {
                        hide();

                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);
                        _._debug.warn(err);
                        alert('網路連線有問題');
                    }
                );
                return lastValueFrom(
                    post$
                );
            }
            //#endregion
        }

        const handle_deleteRecord = (e: any, v: any) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }

            const p1 = v.用藥範本No;
            if (!p1) {
                alert('p1');
                _._debug.error('p1');
            }

            state.List_用藥 = state.List_用藥.filter(r=>{
                return p1 !== r.用藥範本No;
            });
            forceRender();
        }

        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            if (!values.username) {
                errors.username = '↑ 欄位為必填';
            } else {
            }

            if (!values.password) {
                errors.password = '↑ 欄位為必填';
            } else {
            }

            return errors;
        };

        //#region :Popup :createModalPrescribeTool
        const createModalPrescribeTool_refModal = useRef<any>(null);
        const createModalPrescribeTool = (e: any, tasks?: string[]): void => {
            if (e) {
                e.preventDefault();
            }
            // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
            //
            // if (!PassPageDataInitalized) {
            //     //empty
            // }

            const config = {
                // title: 'test',
                content: <Partial_MedicalRecord__Modal_PrescribeAdd
                    PrefixTarget={'Partial_MedicalRecord__Modal_PrescribeAdd'}
                    pageDataManager={pageDataManager}
                    sessionManager={sessionManager}
                    navigate={navigate}
                    parentState={state}

                    viewModel={{
                        refModal: createModalPrescribeTool_refModal,

                        ///*Add
                        OnDid: () => {
                            //@own state got changed, then
                            forceRender();
                            handleClose(null);
                        },
                    }}
                />,
                okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
                className: 'myClass myModal',
                width: '80em',
                height: ' ',
                footer: null,
                //prefixCls: '',
            };

            createModalPrescribeTool_refModal.current = Modal.info(config);
        }
        //#endregion

        const mockTags = ['注射', '手術', '預防'];

        const PrescribeColumns = [
            {
                title: '分類',
                dataIndex: '分類組',
                render: (text, record) => Ln_CommaListRender({
                    照片組: text,
                    render: (tag) => (
                        <Tag key={tag}>{tag}</Tag>
                    )
                })
            },
            {
                dataIndex: '品牌',
                title: '品牌'
            },
            {
                dataIndex: '品名',
                title: '藥名',
                width: '20%',
            },
            {
                dataIndex: '份量',
                title: '份量',
            },
            {
                dataIndex: '用量',
                title: '用量',
            },
            {
                dataIndex: '藥劑處置',
                title: '藥劑處置'
            },
            {
                dataIndex: 'management',
                title: '管理',
                render: (text, record) => {

                    return <div>
                        <Space>
                            <Tooltip placement="right" title="刪除">
                                <Button icon={<CloseOutlined />} type="text" onClick={(e)=>handle_deleteRecord(e, {
                                    用藥範本No: record.entity.用藥範本No,
                                })}></Button>
                            </Tooltip>
                        </Space>
                    </div>
                },
            },
        ];

        const PrescribeDate = [
            {
                time: '15:17',
                tag: mockTags[0],
                brand: 'Linking Park',
                drugName: 'Follow your heart',
                quantity: '1',
                usageAmount: '2ml',
                process: '打斷腿就站起來',
            },
            {
                time: '09:17',
                tag: mockTags[2],
                brand: '輝瑞',
                drugName: '要你命3000',
                quantity: '1',
                usageAmount: '0.5ml',
                process: '千萬要打',
            },
            {
                time: '09:17',
                tag: mockTags[2],
                brand: '輝瑞',
                drugName: '要你命3000',
                quantity: '1',
                usageAmount: '0.5ml',
                process: '千萬要打',
            },
            {
                time: '09:17',
                tag: mockTags[2],
                brand: '輝瑞',
                drugName: '要你命3000',
                quantity: '1',
                usageAmount: '0.5ml',
                process: '千萬要打',
            },
            {
                time: '09:17',
                tag: mockTags[2],
                brand: '輝瑞',
                drugName: '要你命3000',
                quantity: '1',
                usageAmount: '0.5ml',
                process: '千萬要打',
            },
            {
                time: '09:17',
                tag: mockTags[2],
                brand: '輝瑞',
                drugName: '要你命3000',
                quantity: '1',
                usageAmount: '0.5ml',
                process: '千萬要打',
            },
            {
                time: '09:17',
                tag: mockTags[2],
                brand: '輝瑞',
                drugName: '要你命3000',
                quantity: '1',
                usageAmount: '0.5ml',
                process: '千萬要打',
            },
            {
                time: '09:17',
                tag: mockTags[2],
                brand: '輝瑞',
                drugName: '要你命3000',
                quantity: '1',
                usageAmount: '0.5ml',
                process: '千萬要打',
            },
            {
                time: '09:17',
                tag: mockTags[2],
                brand: '輝瑞',
                drugName: '要你命3000',
                quantity: '1',
                usageAmount: '0.5ml',
                process: '千萬要打',
            },
            {
                time: '09:17',
                tag: mockTags[2],
                brand: '輝瑞',
                drugName: '要你命3000',
                quantity: '1',
                usageAmount: '0.5ml',
                process: '千萬要打',
            }
        ];

        const PrescribeHistoryColumn = [
            {
                dataIndex: 'date',
                title: '日期',
            },

            {
                dataIndex: 'doctor',
                title: '開藥醫生'
            },
            {
                dataIndex: 'prescribeAmount',
                title: '開藥項目'
            },
            {
                dataIndex: 'stauts',
                title: '狀態',
            },
            {
                dataIndex: 'view',
                title: '查看',
                render: (text, record) => <a type="link">查看</a>
            }
        ];

        const PrescribeHistoryDate = [
            {
                date: '2021-07-21 15:17',
                doctor: '林隆璇 醫師',
                prescribeAmount: '1',
                stauts: '已配藥',
            },
            {
                date: '2021-08-01 09:30',
                doctor: '王帶鋼 醫師',
                prescribeAmount: '3',
                stauts: '藥局退回',
            },
            {
                date: '2021-08-02 12:42',
                doctor: '游登傑 醫師',
                prescribeAmount: '12',
                stauts: '未配藥',
            },
            {
                date: '2021-08-02 12:42',
                doctor: '游登傑 醫師',
                prescribeAmount: '12',
                stauts: '未配藥',
            },
            {
                date: '2021-08-02 12:42',
                doctor: '游登傑 醫師',
                prescribeAmount: '12',
                stauts: '未配藥',
            },
            {
                date: '2021-08-02 12:42',
                doctor: '游登傑 醫師',
                prescribeAmount: '12',
                stauts: '未配藥',
            },
            {
                date: '2021-08-02 12:42',
                doctor: '游登傑 醫師',
                prescribeAmount: '12',
                stauts: '未配藥',
            },
            {
                date: '2021-08-02 12:42',
                doctor: '游登傑 醫師',
                prescribeAmount: '12',
                stauts: '未配藥',
            },
            {
                date: '2021-08-02 12:42',
                doctor: '游登傑 醫師',
                prescribeAmount: '12',
                stauts: '未配藥',
            }
        ];

        const key = 'updatable';
        const openMessage = () => {
            message.loading({content: '正努力送往藥局...', key});
            setTimeout(() => {
                message.success({content: '藥局確認了你的藥單!', key, duration: 2});
            }, 1000);
        };

        const saveMessage = () => {
            message.loading({content: '儲存中...', key});
            setTimeout(() => {
                message.success({content: '儲存成功!', key, duration: 2});
            }, 1000);
        };

        //#endregion

        if (!PassPrefixTargetLoaded) {
            if (RegionInitialLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <div>Loading.</div>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        if (loadingManager.getIsLoading()) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <Spin indicator={
                            <LoadingOutlined style={{fontSize: 24}} spin/>
                        }/>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        return (
            <div className="model_wrapper">
                <div className="modal_title">
                    <div className="title_info">
                        <h2>開藥</h2>
                    </div>
                    <div className="info_bar"></div>
                    <div className="control_bar">
                        <Tooltip placement="top" title={'關閉'}>
                            <Button type="text" icon={<CloseOutlined/>} onClick={handleClose}/>
                        </Tooltip>
                    </div>
                </div>
                <div className="modal_content">
                    <div className="content_box">
                        {/*<div className="infocontent">*/}
                        {/*    <h4>基本資料核對</h4>*/}
                        {/*    <div className="infobox _h">*/}
                        {/*        <div className="info">*/}
                        {/*            <div className="th">寵物名子：</div>*/}
                        {/*            <div className="content">測試動物</div>*/}
                        {/*        </div>*/}
                        {/*        <div className="info">*/}
                        {/*            <div className="th">飼主姓名：</div>*/}
                        {/*            <div className="content">測試員</div>*/}
                        {/*        </div>*/}
                        {/*        <div className="info">*/}
                        {/*            <div className="th">聯絡電話：</div>*/}
                        {/*            <div className="content">0912345678</div>*/}
                        {/*        </div>*/}
                        {/*        <div className="info">*/}
                        {/*            <div className="th">開藥醫師：</div>*/}
                        {/*            <div className="content">劉德華 醫師</div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="topbar_plug">
                            <div className="title _h">
                                {/* <div className="info">
                                        <div className="th">藥單編號：</div>
                                        <div className="content">P2108021735001</div>  P + YYMMDDhhmm###
                                    </div>
                                    <div className="info">
                                        <div className="th">藥單狀態：</div>
                                        <div className="content">未送出</div>
                                    </div>
                                    <div className="info">
                                        <div className="th">藥局回覆：</div>
                                        <div className="content">--</div>
                                    </div> */}
                            </div>
                            <div className="func">
                                <Button icon={<PlusOutlined/>} type="primary" ghost
                                        onClick={createModalPrescribeTool}>添加用藥項目</Button>
                            </div>
                        </div>
                        <div className="content_box cd_modal">


                            <Table
                                columns={PrescribeColumns}
                                dataSource={DataSource_用藥}
                                size="small"
                                scroll={{y: 35 + 'vh'}}
                                pagination={false}
                            />
                        </div>

                    </div>
                </div>
                <div className="modal_footer">
                    <Space>
                        <Button onClick={handleClose}>取消</Button>
                        <Button type="primary" htmlType="submit" size="large" onClick={(e)=>{handleSubmit_saveRecord(e, {
                            病歷No: viewModel.病歷No,
                            List_用藥: state.List_用藥,
                            掛號No: viewModel.掛號No,
                        })}}>送出</Button>
                    </Space>
                </div>
            </div>
        );
    };
    //#endregion

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_MedicalRecord__Modal_Prescribe.displayName = 'Partial_MedicalRecord__Modal_Prescribe';
