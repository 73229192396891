import React, { useCallback, useEffect, useRef, useState } from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import { Form as NForm, Popover, Button, Modal, InputNumber, message, Tag, Table, Upload, Input, Select, Space, Tabs, Tooltip, Steps } from 'antd';

import { ApiService } from "../_/ApiService";
import { Static } from "../_/Static";
import { StaticEvent } from "../_/StaticEvent";
import _ from "../_";

import { Link } from 'react-router-dom';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import { PageDataManager } from "../hooks/UsePageDataManager";

import { Field, Form } from "react-final-form";
import { SessionManager } from "../hooks/UseSessionManager";
import { NavigateFunction } from "react-router";
import { LoadingOutlined, CheckOutlined, EditOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";
import { lastValueFrom } from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_Appointment__Pane_AddOwner from "./Partial_Appointment__Pane_AddOwner";
import Partial_Search_Or from "./Partial_Search_Or";
import Partial_MedicalRecord__Modal_DealwithAdd from "./Partial_MedicalRecord__Modal_DealwithAdd";


var moment = require('moment');
const { Option } = Select;
const { Search } = Input;
const onSearch = value => console.log(value);
const { TabPane } = Tabs;
const { Step } = Steps;

type ViewModelProps = {
    refModal: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,

    viewModel: ViewModelProps,
};
export default function Partial_Modal_DealwithItem(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_Modal_DealwithItem';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);


    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion


    const loadingManager = UseLoadingManager({ shouldReRenderOnLoading: false });

    //#region Boot
    const _doLoadPartialData = async (): Promise<void> => {
        if (!pageDataManager.refPageData.current.Root) {
            return;
        }

        /// do not dynamically load data if condition fits.
        if (pageDataManager.isZoneExist(PrefixTarget)) {
            return;
        }
        //await _loadPageData();
    }
    useEffect(() => {
        _doLoadPartialData();
    }, []);
    //#endregion


    const Body = () => {
        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            if (!values.username) {
                errors.username = '↑ 欄位為必填';
            } else {
            }

            if (!values.password) {
                errors.password = '↑ 欄位為必填';
            } else {
            }

            return errors;
        };

        const handleClose = (e) => {
            if (e) {
                e.preventDefault();
            }

            if (viewModel.refModal || viewModel.refModal.current) {
                viewModel.refModal.current.destroy();
            }
        };


        const handleSubmit_clickLogin = (e: any, v: any) => {
            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">現在開始創立世界</div>,
                key: 'loading',
                duration: 0
            });

            //#region Handle SaveUserInfo
            {
                // VALIDATE INPUT
                //const id : any = this.props.match.params.id;
                const id = 1;
                if (!id) {
                    return; //ERROR INPUTS
                }

                // WAITING SCREEN
                loadingManager.changeLoadingCount(+1);
                //StaticEvent.LoadingEvent.next(true);

                // POST
                let postData = {
                    p1: v.p1,
                    p2: v.p2,
                    p3: v.p3,
                    p4: v.p4,
                    p5: v.p5,
                    timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
                };
                const postUrl = ApiService.urlMapping('~/h_api/1/pg_jumplogin/clicklogin');
                const post$ = Static.apiService.post(postUrl, postData);
                post$.subscribe(async (res: any) => {
                    hide();


                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        message.success({
                            type: 'success',
                            className: 'myMessage',
                            content: <div className="myMessageBody">歡迎來到，恐龍世界</div>,
                            key: '',
                            duration: 3
                        });

                        // message.success({
                        //     type: 'success',
                        //     className: 'myMessage',
                        //     content: <div className="myMessageBody">門就開了</div>,
                        //     key: '',
                        //     duration: 3
                        // });


                        await sessionManager.setLoginData(
                            payload.SessionId,
                            payload.AuthData
                        );

                        navigate({
                            pathname: '/wall/newest',
                            //search: "?sort=name",
                            //hash: "#the-hash",
                            //state: {panelSelection: "f1"}
                        });
                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
                );
                return lastValueFrom(
                    post$
                );
            }
            //#endregion
        }

        //#region :Popup :createModalDealwithTool
        const createModalDealwithTool_refModal = useRef<any>(null);
        const createModalDealwithTool = (e: any, tasks?: string[]): void => {
            if (e) {
                e.preventDefault();
            }
            // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
            //
            // if (!PassPageDataInitalized) {
            //     //empty
            // }

            const config = {
                // title: 'test',
                content: <Partial_MedicalRecord__Modal_DealwithAdd
                    PrefixTarget={'Partial_MedicalRecord__Modal_DealwithAdd'}
                    pageDataManager={pageDataManager}
                    sessionManager={sessionManager}
                    navigate={navigate}

                    viewModel={{
                        refModal: createModalDealwithTool_refModal,
                    }}
                />,
                okButtonProps: { disabled: true, className: "modal-footer-hiden-button" },
                className: 'myClass myModal',
                width: '80em',
                height: ' ',
                footer: null,
                //prefixCls: '',
            };

            createModalDealwithTool_refModal.current = Modal.info(config);
        }
        //#endregion


        const mockTags = ['注射', '手術', '預防'];


        const DealwithDetailColumns = [
            {
                dataIndex: 'tag',
                title: '處理分類',

                render: (text, record) => <Tag>{text}</Tag>,
            },
            {
                dataIndex: 'dealwithmethodinEnglish',
                title: '處理方法(英)'
            },
            {
                dataIndex: 'dealwithmethodinChinese',
                title: '處理方法(中)）',
            },
            {
                dataIndex: 'delivery',
                title: '外送',
                render: (boolean) => {
                    return <div >
                     {boolean? <CheckOutlined className='greencheck' /> : '' }
                </div>
                    }
            },
            {
                dataIndex: 'returnNotify',
                title: '回診通知',
                render: (boolean) => {
                    return <div>
                        {boolean? <CheckOutlined className='greencheck' /> : '' }
                    </div>
                },
            },
            {
                dataIndex: 'management',
                title: '管理',
                render: (text, record) => {

                    return <div>
                        <Space>
                        <Tooltip placement="top" title="編輯">
                                <Button icon={<EditOutlined />} type="text" onClick={createModalDealwithTool}/>
                            </Tooltip>
                            <Tooltip placement="top" title="刪除">
                                <Button icon={<CloseOutlined />} type="text" />
                            </Tooltip>
                        </Space>
                    </div>
                },
            },
        ];

        const DealwithDetailDate = [
            {
                tag: mockTags[0],
                dealwithmethodinEnglish: 'dichorobenzene',
                dealwithmethodinChinese: '二氯苯,霖',
                delivery: true,
                returnNotify: false,
            },
            {
                tag: mockTags[2],
                dealwithmethodinEnglish: 'dichorobenzene',
                dealwithmethodinChinese: '二氯苯,霖',
                delivery: true,
                returnNotify: true,
            },
            {
                tag: mockTags[1],
                dealwithmethodinEnglish: 'dichorobenzene',
                dealwithmethodinChinese: '二氯苯,霖',
                delivery: false,
                returnNotify: false,
            },

        ];





        const key = 'updatable';
        const openMessage = () => {
            message.loading({ content: '正努力送往藥局...', key });
            setTimeout(() => {
              message.success({ content: '藥局確認了你的藥單!', key, duration: 2 });
            }, 1000);
          };

          const saveMessage = () => {
            message.loading({ content: '儲存中...', key });
            setTimeout(() => {
              message.success({ content: '儲存成功!', key, duration: 2 });
            }, 1000);
          };

        /*
                                                        <input type="text" {...input} placeholder="username"></input>
                                                {meta.touched && meta.error &&
                                                <div className="error">
                                                    {meta.error}
                                                </div>}

                                                <input type="text" {...input} placeholder="password"></input>
                                                {meta.touched && meta.error &&
                                                <div className="error">
                                                    {meta.error}
                                                </div>}

                                                <input type="submit" {...input} placeholder="asfasdfsad"></input>
                                                {meta.touched && meta.error &&
                                                <div className="error">
                                                    {meta.error}
                                                </div>}

         */
        return (
            <div className="model_wrapper">
                <div className="modal_title">
                    <div className="title_info">
                        <h2>處理明細</h2>
                    </div>
                    <div className="info_bar"></div>
                    <div className="control_bar">
                        <Tooltip placement="top" title={'關閉'}>
                            <Button type="text" icon={<CloseOutlined/>} onClick={handleClose}/>
                        </Tooltip>
                    </div>
                </div>
                <div className="modal_content">
                    <div className="content_box">
                        <div className="infocontent">
                            <h4>基本資料核對</h4>
                            <div className="infobox _h">
                                <div className="info">
                                    <div className="th">寵物名子：</div>
                                    <div className="content">測試動物</div>
                                </div>
                                <div className="info">
                                    <div className="th">飼主姓名：</div>
                                    <div className="content">測試員</div>
                                </div>
                                <div className="info">
                                    <div className="th">聯絡電話：</div>
                                    <div className="content">0912345678</div>
                                </div>
                                <div className="info">
                                    <div className="th">處理開單醫師：</div>
                                    <div className="content">劉德華 醫師</div>
                                </div>
                            </div>
                        </div>
                        <div className="topbar_plug">
                                <div className="title _h">
                                    <div className="info">
                                        <div className="th">開單日期：</div>
                                        <div className="content">2021-07-21 15:17</div>
                                    </div>
                                </div>
                                <div className="func">
                                    <Button icon={<PlusOutlined />} type="primary" ghost onClick={createModalDealwithTool}>添加處理項目</Button>
                                </div>
                            </div>
                        <div className="content_box cd_modal">


                            <Table
                                columns={DealwithDetailColumns}
                                dataSource={DealwithDetailDate}
                                size="small"
                                scroll={{ y: 35 + 'vh' }}
                                pagination={false}
                            />
                        </div>

                    </div>
                </div>
                <div className="modal_footer">
                    <Space>
                        {/* <Button size='large' onClick={saveMessage}>列印</Button> */}
                        <Button type='primary' size='large' onClick={openMessage}>送出</Button>
                    </Space>
                </div>
            </div>
        );
    };

    //#region Render
    return <React.Fragment>
        <Body />
    </React.Fragment>;
    //#endregion
}
Partial_Modal_DealwithItem.displayName = 'Partial_Modal_DealwithItem';
