import {mergeAll, mergeMap, timeout, delay, map, retryWhen, retry, catchError, switchMap} from 'rxjs/operators';
import {of, from, defer, Observable, Subject, EMPTY} from 'rxjs';

import _ from './index';

const axios = require('axios');


export class ApiService {

    constructor() {
        //_.sharedStateManager.APP_MODE = 'DEV';
        //_.sharedStateManager.APP_MODE = 'STAGE';
        //_.sharedStateManager.APP_MODE = 'RELEASE';
    }

    static urlMapping(url) {
        if (url == null)
            return null;
        if (url.indexOf("~/") == 0) {
            if (_.sharedState.APP_MODE === 'dev') {
                url = url.replace(/^~[/]+/, "//localhost:9999/");
            } else if (_.sharedState.APP_MODE === 'prod') {
                url = url.replace(/^~[/]+/, "/");
            } else {
                url = url.replace(/^~[/]+/, "//error/");
            }
        }
        return url;
    }


    static urlImageMapping(url) {
        if (url == null)
            return null;
        if (url.indexOf("~/") == 0) {
            if (_.sharedState.APP_MODE === 'dev') {
                url = url.replace(/^~[/]+/, "//localhost:9999/");
            } else if (_.sharedState.APP_MODE === 'prod') {
                url = url.replace(/^~[/]+/, "/");
            } else {
                url = url.replace(/^~[/]+/, "//error/");
            }
        }
        return url;
    }

    static urlSmartImageMapping(url, width, height) {
        if (url == null)
            return null;
        if (url.indexOf("~/") == 0) {
            if (_.sharedState.APP_MODE === 'dev') {
                url = `/download_image.ashx?width=${width}&height=${height}&mode=crop&file=${encodeURIComponent(url.replace(/^~[/]+/, '/'))}`;
            } else if (_.sharedState.APP_MODE === 'prod') {
                url = `/download_image.ashx?width=${width}&height=${height}&mode=crop&file=${encodeURIComponent(url.replace(/^~[/]+/, '/'))}`;
            } else {
                url = url.replace(/~[/]+/, "//error/");
            }
        }
        return url;
    }

    postFormData(apiUrl: string, data: any = null) {
        _._debug.log(`1 :attempt connecting: ${apiUrl}`);

        const options = {
            method: 'POST',
            headers: {},
            data: data == null ? null : data,
            withCredentials: true,
            url: apiUrl,
        };

        var retry = from(axios(options))
            .pipe(
                timeout(15 * 1000)
            )
            .pipe(
                catchError
                ((err, source$) => {
                    _._debug.error(err);
                    let capture_source$ = source$;

                    // @ts-ignore
                    return from(
                        new Promise((resolve, reject) => {
                            if (window.confirm('Encounter network connectivity issues. Do you want to retry?')) {
                                resolve();
                            } else {
                                reject(err);
                            }
                        })
                    ).pipe(
                        switchMap(v => {
                            return retry;
                            //return capture_source$;
                        })
                    )
                })
            );
    }


    post(apiUrl: string, data: any = null) {
        _._debug.log(`2 :attempt connecting: ${apiUrl}`);

        const options = {
            method: 'POST',
            headers: {},
            data: data == null ? null : data,
            withCredentials: true,
            url: apiUrl,
        };

        let hasPrompt = false;
        let doRetry = () => {
            return from(axios(options))
                .pipe(
                    timeout(15 * 1000) //15 seconds
                )
                .pipe(
                    catchError((err, source$) => {
                        _._debug.error(err);
                        let capture_source$ = source$;

                        // @ts-ignore
                        return from(
                            new Promise((resolve, reject) => {
                                //resolve(true);
                                if (window.confirm('Encounter network connectivity issues. Do you want to retry?')) {
                                    resolve(true);
                                    hasPrompt = true;
                                } else {
                                    reject(err);
                                    hasPrompt = false;
                                }
                            })
                        ).pipe(
                            switchMap(() => doRetry())
                        );
                    })
                );
        };
        return doRetry();
    }
}
