import React, {useEffect, useRef} from 'react';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import {
    Form, Input, Button, Alert, Checkbox, Result, message, Space,
    Modal, Typography, Tabs, Select, Popconfirm, Tooltip, Spin
} from 'antd';
import {useApp} from "../overmind";
import {UserInfo} from "../global";
import {Static} from "../_/Static";
import {ApiService} from "../_/ApiService";
import _ from "../_";
import {
    ReloadOutlined,
    EyeTwoTone,
    UserOutlined,
    FileSearchOutlined,
    RedoOutlined,
    LoadingOutlined
} from '@ant-design/icons';

import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import UsePageDataManager from '../hooks/UsePageDataManager';
import UseSessionManager from "../hooks/UseSessionManager";
import UseLoadingManager from "../hooks/UseLoadingManager";
import UseCompState from "../hooks/UseCompState";
import {lastValueFrom} from "rxjs";
import Partial_MedicalRecord__Tab_BasicData from "./Partial_MedicalRecord__Tab_BasicData";
import Partial_MedicalRecord__Tab_Treatment from "./Partial_MedicalRecord__Tab_Treatment";
import Partial_Modal_Login from "./Partial_Modal_Login";

import './Pg_MedicalRecord.scss';
import FunctionBtn from './Box_Functionbtn';
import Partial_Appointment__Modal_EditReservation from "./Partial_Appointment__Modal_EditReservation";
import Partial_Appointment__Modal_EditOrAddRegister from "./Partial_Appointment__Modal_EditOrAddRegister";
import Partial_Appointment__Modal_AddReservation from "./Partial_Appointment__Modal_AddReservation";


const iconNewRecord = require('../assets/images/icon/new-medical-record.svg');
const iconWatting = require('../assets/images/icon/sofa.svg');
const iconTocompelet = require('../assets/images/icon/writing.svg');
const iconSaving = require('../assets/images/icon/floppy-disk.svg');
const iconReservation = require('../assets/images/icon/calendarsp.svg');


const onSearch = value => console.log(value);
const {Title} = Typography;
const {TabPane} = Tabs;
const {Search} = Input;
const {Option} = Select;


type Props = {
    PrefixTarget?: string;
    RoutePathname?: string;
    MenuTemplateId?: string;

    parentState?: any;


};
export default function Pg_MedicalRecord(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Root';
    let RoutePathname = props.RoutePathname;
    let MenuTemplateId = props.MenuTemplateId;

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    //#region LoadingManager
    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: true});
    //#endregion

//#region PageDataManager
    const refDoLoadPageData = Static.refDoLoadPageData;
    let pageDataManager = UsePageDataManager({
        refPageLoad: refDoLoadPageData
    });
    //#endregion

    //#region SessionManager
    let sessionManager = UseSessionManager({
        pageDataManager: pageDataManager,
    });
    //#endregion

    //#region :State CompState
    let param_寵物No = params.No;
    let defaultS = '全部';
    let defaultQ = '';

    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

        //state.病歷No = param_病歷No;

        state.掛號No = pageDataManager.get(`${PrefixTarget}.Item_掛號`)?.掛號No;
        state.飼主No = pageDataManager.get(`${PrefixTarget}.Item_掛號`)?.飼主No;

        state.寵物No = param_寵物No;
        state.病歷No = pageDataManager.get(`${PrefixTarget}.Item_病歷`)?.病歷No;


        state.Item_掛號 = pageDataManager.get(`${PrefixTarget}.Item_掛號`);
    }
    //#endregion


    //#region Boot

    useEffect(() => {
        const _loadPageData = async (): Promise<void> => {
            let hide = message.loading({
                type: 'loading',
                class: 'myMessage',
                content: <div className="myMessageBody">搜尋中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            const p1: any = param_寵物No;
            if (!p1) {
                _._debug.error('p1')
                alert('p1');
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                s: state.s,
                q: state.q,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/pg_medicalrecord');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        pageDataManager.merge(payload);
                        pageDataManager.apply();


                        state.Item_掛號 = pageDataManager.get(`${PrefixTarget}.Item_掛號`); //病歷是否被掛號，若有檢查 "寵物" 驗證view，交代飼主

                        state.掛號No = pageDataManager.get(`${PrefixTarget}.Item_掛號`)?.掛號No;
                        state.飼主No = pageDataManager.get(`${PrefixTarget}.Item_掛號`)?.飼主No;
                        state.寵物No = param_寵物No;

                        state.病歷No = pageDataManager.get(`${PrefixTarget}.Item_病歷`)?.病歷No;


                        pageDataManager.forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPageData = async ({alwaysLoad = false}): Promise<void> => {
            if (!alwaysLoad) {
                pageDataManager.restore();
                if (!pageDataManager.isZoneExist(PrefixTarget)) {
                    await sessionManager.initSessionAuthAsync();
                    //#region User Check //TODO: VERTIFY
                    if (!sessionManager.isMemberLoggedIn()) {
                        Static.NavAwayToLogin(pageDataManager, navigate)(null);
                        return;
                    }
                    //#endregion
                    await _loadPageData();
                } else {
                    forceRender()
                }
            } else {
                await _loadPageData();
            }
        }

        refDoLoadPageData.current = doLoadPageData;
        refDoLoadPageData.current({});
    }, [location.pathname]);

    //#endregion


    function callback(key) {
        console.log(key);

        setTabValue(key);
    }


    function cancel(e) {
        console.log(e)
    }

    //#region Set Body
    const [tabValue, setTabValue] = useState<string>("1");


    const Body = () => {
        const RegionEmptyShow = false;
        const RegionLoadingShow = true;
        const RegionInitialLoadingShow = false;

        const PassPageDataInitalized = pageDataManager.refPageData.current != null;
        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;


        //#region Handlers
        const handleSubmit_entercomplete = (e: any, {掛號No}: { 掛號No: any }) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }

            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            const p1 = 掛號No;
            if (!p1) {
                _._debug.error(p1);
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_medicalrecord/entercomplete');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);


                        let hide = message.loading({
                            type: 'loading',
                            className: 'myMessage',
                            content: <div className="myMessageBody">修改完成</div>,
                            key: 'loading',
                            duration: 2
                        });

                        //handleClose(null);

                        // if (viewModel.用戶No === null) { //is insert
                        //     StaticEvent.castUpdateEvent({
                        //         command: StaticEvent.UpdateFacade.Command.Add_用戶,
                        //         Add_用戶: {
                        //             用戶No: payload.Item_用戶.用戶No,
                        //         }
                        //     });
                        // } else {
                        //     StaticEvent.castUpdateEvent({
                        //         command: StaticEvent.UpdateFacade.Command.Update_用戶,
                        //         Update_用戶: {
                        //             用戶No: payload.Item_用戶.用戶No,
                        //         }
                        //     });
                        // }

                        pageDataManager.reloadAsync({alwaysLoad: true});
                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const handleSubmit_makeremind = (e: any, {掛號No}: { 掛號No: any }) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }

            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            const p1 = 掛號No;
            if (!p1) {
                _._debug.error(p1);
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_medicalrecord/makeremind');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);


                        let hide = message.success({
                            type: 'success',
                            className: 'myMessage',
                            content: <div className="myMessageBody">已增加至待完成渠列</div>,
                            key: 'success',
                            duration: 2
                        });

                        pageDataManager.reloadAsync({alwaysLoad: true});

                        //handleClose(null);

                        // if (viewModel.用戶No === null) { //is insert
                        //     StaticEvent.castUpdateEvent({
                        //         command: StaticEvent.UpdateFacade.Command.Add_用戶,
                        //         Add_用戶: {
                        //             用戶No: payload.Item_用戶.用戶No,
                        //         }
                        //     });
                        // } else {
                        //     StaticEvent.castUpdateEvent({
                        //         command: StaticEvent.UpdateFacade.Command.Update_用戶,
                        //         Update_用戶: {
                        //             用戶No: payload.Item_用戶.用戶No,
                        //         }
                        //     });
                        // }

                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }

        //#region :Popup :createModalRegister
        const createModalRegister_refModal = useRef<any>(null);
        const createModalRegister = (e: any, {飼主No, 寵物No, tasks, modalAddonProp}: {
            modalAddonProp?: object,
            飼主No?: any,
            寵物No?: any,
            tasks?: string[]
        }): void => {
            if (e) {
                e.preventDefault();
            }

            const config = {
                // title: 'test',
                content: <Partial_Appointment__Modal_EditOrAddRegister
                    PrefixTarget={`Partial_Appointment__Modal_EditOrAddRegister${飼主No ?? 0}-${寵物No ?? 0}`}
                    pageDataManager={pageDataManager}
                    sessionManager={sessionManager}
                    navigate={navigate}

                    viewModel={{
                        refModal: createModalRegister_refModal,

                        Kind: 'Create',
                        Kind_Create: {
                            寵物No: 寵物No,
                            飼主No: 飼主No,
                        }
                    }}

                    {...modalAddonProp}
                />,
                okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
                className: 'myClass myModal',
                width: '60em',
                height: ' ',
                footer: null,
                //prefixCls: '',
            };

            createModalRegister_refModal.current = Modal.info(config);
        }
        //#endregion
        const handleSubmit_makeregister = (e: any, {掛號No}: { 掛號No: any }) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }

            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            const p1 = 掛號No;
            if (!p1) {
                _._debug.error(p1);
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_medicalrecord/makeregister');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);


                        let hide = message.loading({
                            type: 'success',
                            className: 'myMessage',
                            content: <div className="myMessageBody">修改完成</div>,
                            key: 'success',
                            duration: 2
                        });

                        debugger;
                        createModalRegister(null, {
                            寵物No: payload.Item_寵物_飼主__關係.寵物No,
                            飼主No: payload.Item_寵物_飼主__關係.飼主No,
                        });

                        //handleClose(null);

                        // if (viewModel.用戶No === null) { //is insert
                        //     StaticEvent.castUpdateEvent({
                        //         command: StaticEvent.UpdateFacade.Command.Add_用戶,
                        //         Add_用戶: {
                        //             用戶No: payload.Item_用戶.用戶No,
                        //         }
                        //     });
                        // } else {
                        //     StaticEvent.castUpdateEvent({
                        //         command: StaticEvent.UpdateFacade.Command.Update_用戶,
                        //         Update_用戶: {
                        //             用戶No: payload.Item_用戶.用戶No,
                        //         }
                        //     });
                        // }

                        pageDataManager.reloadAsync({alwaysLoad: true});
                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }


        //#region :Popup :createModalAddReservationn
        const createModalAddReservation_refModal = useRef<any>(null);
        const createModalAddReservation = (e: any, {寵物_飼主__關係No, tasks}: {
            寵物_飼主__關係No: any,
            tasks?: string[]
        }) => {
            if (e) {
                e.preventDefault();
            }
            // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
            //
            // if (!PassPageDataInitalized) {
            //     //empty
            // }

            const config = {
                // title: 'test',
                content: <Partial_Appointment__Modal_AddReservation
                    PrefixTarget={`Partial_Appointment__Modal_AddReservation`}
                    pageDataManager={pageDataManager}
                    sessionManager={sessionManager}
                    navigate={navigate}

                    viewModel={{
                        refModal: createModalAddReservation_refModal,

                        寵物_飼主__關係No,
                    }}
                />,
                okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
                className: 'myClass myModal',
                width: '35em',
                height: ' ',
                footer: null,
                //prefixCls: '',
            };

            createModalAddReservation_refModal.current = Modal.info(config);
        }
        //#endregion

        const handleSubmit_makereservation = (e: any, {掛號No}: { 掛號No: any }) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }

            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            const p1 = 掛號No;
            if (!p1) {
                _._debug.error(p1);
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_medicalrecord/makereservation');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        // let hide = message.loading({
                        //     type: 'success',
                        //     className: 'myMessage',
                        //     content: <div className="myMessageBody">修改完成</div>,
                        //     key: 'success',
                        //     duration: 2
                        // });

                        debugger;
                        createModalAddReservation(null, {寵物_飼主__關係No: payload.Item_寵物_飼主__關係.寵物_飼主__關係No});

                        //handleClose(null);

                        // if (viewModel.用戶No === null) { //is insert
                        //     StaticEvent.castUpdateEvent({
                        //         command: StaticEvent.UpdateFacade.Command.Add_用戶,
                        //         Add_用戶: {
                        //             用戶No: payload.Item_用戶.用戶No,
                        //         }
                        //     });
                        // } else {
                        //     StaticEvent.castUpdateEvent({
                        //         command: StaticEvent.UpdateFacade.Command.Update_用戶,
                        //         Update_用戶: {
                        //             用戶No: payload.Item_用戶.用戶No,
                        //         }
                        //     });
                        // }

                        pageDataManager.reloadAsync({alwaysLoad: true});
                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        //#endregion

        const TabsBox = () => {
            if (!PassPrefixTargetLoaded) {
                if (RegionInitialLoadingShow) {
                    return <React.Fragment>
                        <div className="d3">
                            <div>Loading.</div>
                        </div>
                    </React.Fragment>;
                } else {
                    return null;
                }
            }

            if (loadingManager.getIsLoading()) {
                if (RegionLoadingShow) {
                    return <React.Fragment>
                        <div className="d3">
                            <Spin indicator={
                                <LoadingOutlined style={{fontSize: 24}} spin/>
                            }/>
                        </div>
                    </React.Fragment>;
                } else {
                    return null;
                }
            }

            return <Tabs defaultActiveKey={tabValue} onChange={callback} className="maintab">
                <TabPane tab="基本資料" key="1">
                    <Partial_MedicalRecord__Tab_BasicData
                        PrefixTarget={'Partial_MedicalRecord__Tab_BasicData'}
                        pageDataManager={pageDataManager}
                        sessionManager={sessionManager}
                        navigate={navigate}
                        parentState={state}

                        viewModel={{
                            refModal: null,

                            寵物No: state.寵物No,
                        }}
                    />

                </TabPane>
                <TabPane tab="病歷處置" key="2">
                    <Partial_MedicalRecord__Tab_Treatment
                        PrefixTarget={'Partial_MedicalRecord__Tab_Treatment'}
                        pageDataManager={pageDataManager}
                        sessionManager={sessionManager}
                        navigate={navigate}

                        viewModel={{
                            refModal: null,

                            病歷No: state.病歷No,
                            掛號No: state.掛號No,
                        }}
                    />
                </TabPane>
            </Tabs>
        };

        return (
            <div className="page-medicalrecord page-content">
                <div className="info-list">
                    <div className="info-box">
                        <Title level={2}>病歷</Title>
                        <div className="pluginfobox">
                            <div className="title">
                                <Space>
                                    <Tooltip title="刷新">
                                        <Button icon={<ReloadOutlined/>} shape="circle"
                                                onClick={(e) => {
                                                    ///reload
                                                    if (e) {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                    }
                                                    pageDataManager.clear();
                                                    pageDataManager.reloadAsync({alwaysLoad: true});
                                                }}/>
                                    </Tooltip>
                                </Space></div>
                            <div className="info">
                                <Space>{state.掛號No ? <React.Fragment>
                                    <span></span>
                                    <Alert message={`有掛號，${state.Item_掛號.候診狀態}`} type="warning"/>
                                </React.Fragment> : <Alert message="唯讀"/>}
                                </Space>

                            </div>
                        </div>

                        <div className="pluginfobox">
                            <div className="title"></div>
                            <div className="info"></div>
                        </div>

                    </div>
                    <div className="function-box">
                        <Popconfirm
                            title="確定要關閉「病歷處置紀錄」嗎?"
                            onConfirm={async (e) => {
                                await handleSubmit_entercomplete(e, {掛號No: state.掛號No});
                            }}
                            onCancel={cancel}
                            okText="確定"
                            cancelText="取消"
                        >
                            <button className={state.掛號No ? '' : 'disabled'}>
                                <FunctionBtn iconlink={iconSaving} name="完診歸檔"/>
                            </button>
                        </Popconfirm>
                        <Popconfirm
                            title="確定要將「病歷處置紀錄」送至待完成嗎?"
                            onConfirm={async (e) => {
                                await handleSubmit_makeremind(e, {掛號No: state.掛號No});
                            }}
                            onCancel={cancel}
                            okText="確定"
                            cancelText="取消"
                        >
                            <button className={state.掛號No && state.Item_掛號.待完成狀態 !== '待完成' ? '' : 'disabled'}>
                                <FunctionBtn iconlink={iconTocompelet} name="待完成"/>
                            </button>
                        </Popconfirm>

                        <button className={state.掛號No ? '' : 'disabled'} onClick={(e) => handleSubmit_makereservation(e, {掛號No: state.掛號No})}>
                            <FunctionBtn iconlink={iconReservation} name="預約"/>
                        </button>

                        <button className={state.掛號No ? '' : 'disabled'} onClick={(e) => handleSubmit_makeregister(e, {掛號No: state.掛號No})}>
                            <FunctionBtn iconlink={iconWatting} name="掛號"/>
                        </button>

                    </div>
                </div>
                <TabsBox/>
            </div>
        )
    };
    //#endregion

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}

Pg_MedicalRecord.displayName = 'Pg_MedicalRecord';




























