import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {Form as NForm, Popover, Button, Modal, message, Upload, Input, Select, Space} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";

import {LoadingOutlined, LockOutlined, PlusOutlined, RedoOutlined, UserOutlined} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";
import UseCompState from "../hooks/UseCompState";

const urlParse = require('url-parse');
const queryString = require('query-string');

var moment = require('moment');
const {Option} = Select;
const {Search} = Input;

type ViewModelProps = {
    refModal: any,

};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any,

    viewModel: ViewModelProps,
};

function Partial_Manage_Campaign__Search(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_Campaign_Search';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel = props.viewModel;


    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region Boot
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

        //do something
    }

    const _doLoadPartialData = async (): Promise<void> => {
        if (!pageDataManager.refPageData.current.Root) {
            return;
        }

        /// do not dynamically load data if condition fits.
        if (pageDataManager.isZoneExist(PrefixTarget)) {
            return;
        }
        //await _loadPageData();
    }
    useEffect(() => {
        _doLoadPartialData();
    }, []);
    //#endregion


    const Body = () => {
        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            // if (!values.username) {
            //     errors.username = '↑ 欄位為必填';
            // } else {
            // }
            //
            // if (!values.password) {
            //     errors.password = '↑ 欄位為必填';
            // } else {
            // }

            return errors;
        };

        const handleClose = (e) => {
            if (e) {
                e.preventDefault();
            }

            if (viewModel.refModal || viewModel.refModal.current) {
                viewModel.refModal.current.destroy();
            }
        };

        const handleSubmit_clickSearch = (e: any, v: any) => {
            state.parentState.s = v.searchType;
            state.parentState.q = v.searchTerm;

            pageDataManager.emptyZone('Root');
            pageDataManager.apply();

            pageDataManager.reloadAsync({alwaysLoad: true});
        }

        return (
            <Form
                onSubmit={(v) => handleSubmit_clickSearch(null, v)}
                initialValues={{
                    searchType: state.parentState.s,
                    searchTerm: state.parentState.q,
                }}
                validate={handleValidate}
                render={({
                             submitError,
                             handleSubmit,
                             submitting,
                             pristine,
                             valid,
                             error,
                             values
                         }) => {


                    let parsedUrl = urlParse(window.location.href, true);
                    //let query = queryString.parse(parsedUrl.query);
                    let query = parsedUrl.query;
                    let hasQueryPulled = false;
                    if (query?.s) {
                        values.searchType = query.s;
                        hasQueryPulled = hasQueryPulled || true;
                    }
                    if (query?.q) {
                        values.searchTerm = query.q;
                        hasQueryPulled = hasQueryPulled || true;
                    }
                    if (hasQueryPulled) {
                        window.history.replaceState({}, document.title, parsedUrl.pathname);
                        handleSubmit();
                    }

                    return <React.Fragment>
                        <form onSubmit={handleSubmit}>

                            <Space>

                                <Field parse={_.bs.identity} name="searchTerm">
                                    {
                                        ({input, meta}) => (
                                            <React.Fragment>
                                                <NForm.Item name="searchTerm"
                                                    // rules={[{ required: true, message: '請輸入正確密碼' }]}
                                                >
                                                    <Search
                                                        placeholder="請輸入票券名稱、序號、標籤搜尋..."
                                                        allowClear
                                                        enterButton
                                                        onSearch={(value, event) => {
                                                            if (value === '') {
                                                                values.searchType = '全部';
                                                            }

                                                            input.onChange(value);
                                                            handleSubmit();
                                                        }}
                                                        style={{width: 400}}
                                                        {...input}
                                                        size="large"
                                                        // loading
                                                        defaultValue={values.searchTerm}
                                                    />
                                                    {meta.touched && meta.error &&
                                                    <div className="error">
                                                        {meta.error}
                                                    </div>}
                                                </NForm.Item>
                                            </React.Fragment>
                                        )
                                    }
                                </Field>
                            </Space>
                        </form>
                    </React.Fragment>;
                }}
            />
        );
    };

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}

Partial_Manage_Campaign__Search.displayName = 'Partial_Campaign_Search';
const areEqual = (prevProps, nextProps) => {
    return false; //force true
}
export default React.memo(Partial_Manage_Campaign__Search, areEqual);
