import {BehaviorSubject, Observable, Subject} from "rxjs";
import {ApiService} from "./ApiService";


//#region DropdownFacade
export class DropdownFacade {
    public Command: DropdownEvent_Command = _DropdownEvent_CommandInstance;
}

type CastDropdownEventProps = {
    command: string,
    CloseAll?: CastDropdownEvent_Command_CloseAll,
}
type CastDropdownEvent_Command_CloseAll = {
}
const castDropdownEvent = (castDropdownEventProps: CastDropdownEventProps) => {
    StaticEvent.DropdownEvent.next(castDropdownEventProps);
}

export class DropdownEvent_Command {
    public CloseAll: string = 'close-all';
}
let _DropdownEvent_CommandInstance = new DropdownEvent_Command();
//#endregion

//#region ModalFacade
export class ModalFacade {
    public Command: ModalEvent_Command = _ModalEvent_CommandInstance;
}

type CastModalEventProps = {
    command: string,
    CloseAll?: CastModalEvent_Command_CloseAll,
}
type CastModalEvent_Command_CloseAll = {
}
const castModalEvent = (castModelEventProps: CastModalEventProps) => {
    StaticEvent.ModalEvent.next(castModelEventProps);
}

export class ModalEvent_Command {
    public CloseAll: string = 'close-all';
}
let _ModalEvent_CommandInstance = new ModalEvent_Command();
//#endregion

//#region UpdateFacade
export class UpdateFacade {
    public Command: UpdateEvent_Command = _UpdateEvent_CommandInstance;
}

const castUpdateEvent = (castUpdateEventProps: CastUpdateEventProps) => {
    StaticEvent.UpdateEvent.next(castUpdateEventProps);
}

export class UpdateEvent_Command {
    public Update_用戶: string = 'update-user';
    public Add_用戶: string = 'add-user';
    public Remove_用戶: string = 'remove-user';
    public Remove_票劵: string = 'remove-ticket';
    public Add_票劵: string = 'add-ticket';
    public Update_票劵: string = 'update-ticket';
}
type CastUpdateEventProps = {
    command: string,

    Update_用戶?: CastUpdatingEvent_Command_Update_用戶,
    Add_用戶?: CastUpdatingEvent_Command_Add_用戶,
    Remove_用戶?: CastUpdatingEvent_Command_Remove_用戶,
    Remove_票劵?: CastUpdatingEvent_Command_Remove_票劵,
    Add_票劵?: CastUpdatingEvent_Command_Add_票劵,
    Update_票劵?: CastUpdatingEvent_Command_Update_票劵,
}
type CastUpdatingEvent_Command_Update_用戶 = {
    用戶No: any;
}
type CastUpdatingEvent_Command_Add_用戶 = {
    用戶No: any;
}
type CastUpdatingEvent_Command_Remove_用戶 = {
    用戶No: any;
}
type CastUpdatingEvent_Command_Remove_票劵 = {
    票劵No: any;
}
type CastUpdatingEvent_Command_Add_票劵 = {
    票劵No: any;
}
type CastUpdatingEvent_Command_Update_票劵 = {
    票劵No: any;
}
let _UpdateEvent_CommandInstance = new UpdateEvent_Command();
//#endregion

export class StaticEvent {
    public static SocketConnectEvent: Subject<any> = new BehaviorSubject<boolean>(false);
    public static SocketConnectEvent$: Observable<any> = StaticEvent.SocketConnectEvent.asObservable();

    public static LoadingEvent: Subject<any> = new Subject();
    public static LoadingEvent$: Observable<any> = StaticEvent.LoadingEvent.asObservable();

    public static DropdownEvent: Subject<any> = new Subject();
    public static DropdownEvent$: Observable<any> = StaticEvent.DropdownEvent.asObservable();
    public static castDropdownEvent = castDropdownEvent;
    public static DropdownFacade = (() => {
        return new DropdownFacade();
    })();

    public static ModalEvent: Subject<any> = new Subject();
    public static ModalEvent$: Observable<any> = StaticEvent.ModalEvent.asObservable();
    public static castModalEvent = castModalEvent;
    public static ModalFacade = (() => {
        return new ModalFacade();
    })();

    public static UpdateEvent: Subject<CastUpdateEventProps> = new Subject();
    public static UpdateEvent$: Observable<CastUpdateEventProps> = StaticEvent.UpdateEvent.asObservable();
    public static castUpdateEvent = castUpdateEvent;
    public static UpdateFacade = (() => {
        return new UpdateFacade();
    })();
}
