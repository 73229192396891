import {useRef, useState} from 'react';
import {PageDataManager} from "./UsePageDataManager";
import _ from "../_";



type UsePageDataManagerProps = {
  pageDataManager: PageDataManager;
  dataPath: string;
};

export default function useRefState<T>(value:any, usePageDataManager?: UsePageDataManagerProps) {
  let initValue = value;
  if (usePageDataManager) {
    let storedValue = usePageDataManager.pageDataManager.get(usePageDataManager.dataPath);
    if (storedValue !== null) {
      initValue = storedValue;
    }
  }
  const ref = useRef<T>(initValue);
  const [, forceRender] = useState(false);

  function updateState(newState) {
    if (usePageDataManager) {
      let stored = usePageDataManager.pageDataManager.refPageData.current;
      if (stored !== null) {
        if (_.util.namespaceSet(stored, usePageDataManager.dataPath, newState))
        usePageDataManager.pageDataManager.apply();
      }
    }

    if (!Object.is(ref.current, newState)) {
      ref.current = newState;
      forceRender(s => !s);
      return;
    }
  }

  return [ref, updateState] as const;
}
