import React, {useEffect, useRef, useState} from 'react';

import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";

import '../css/reset.css';
import './_Index.scss';

type Props = {
    PrefixTarget?: string;
    RoutePathname?: string;
    MenuTemplateId?: string;
};
export default function Pg_Root(props: Props) {
    const navigate = useNavigate();
    let location = useLocation();

    //#region PageData

    //#region :PageInit

    //#region Boot
    useEffect(() => {
        const _redirect = async (): Promise<void> => {
            navigate({
                pathname: '/login'
                //search: "?sort=name",
                //hash: "#the-hash",
                //state: {panelSelection: "f1"}
            }, {replace: true});

        }
        const _doLoadPageData = async (): Promise<void> => {
            await _redirect();
        }

        _doLoadPageData();
    }, [location.pathname]);
    //#endregion
    //#endregion


    //region :PagePreRender (Ordered)


    //#endregion
    //#endregion

    //#region Render
    return (<div className="bg">
        <div className="bodyBody">
            <React.Fragment>
            </React.Fragment>
        </div>
    </div>);
    //#endregion
}

Pg_Root.displayName = 'Pg_Root';
