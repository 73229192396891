import React, {useRef} from 'react';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import {Form, Input, Button, Alert, Checkbox, Result, message, Space, Modal} from 'antd';
import './Pg_Login.scss';
import {useApp} from "../overmind";
import {UserInfo} from "../global";
import {Static} from "../_/Static";
import {ApiService} from "../_/ApiService";
import _ from "../_";
import {EyeInvisibleOutlined, EyeTwoTone, UserOutlined, LockOutlined, RedoOutlined} from '@ant-design/icons';

import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import UsePageDataManager from '../hooks/UsePageDataManager';
import UseSessionManager from "../hooks/UseSessionManager";
import UseLoadingManager from "../hooks/UseLoadingManager";
import UseCompState from "../hooks/UseCompState";
import {lastValueFrom} from "rxjs";
import Partial_Modal_Login from "./Partial_Modal_Login";


type Props = {
    PrefixTarget?: string;
    RoutePathname?: string;
    MenuTemplateId?: string;

    parentComponentState?: any;
};
export default function Pg_Login(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Root';
    let RoutePathname = props.RoutePathname;
    let MenuTemplateId = props.MenuTemplateId;

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: true});

//#region PageDataManager
    const refDoLoadPageData = Static.refDoLoadPageData;
    let pageDataManager = UsePageDataManager({
        refPageLoad: refDoLoadPageData
    });
    //#endregion

    //#region SessionManager
    let sessionManager = UseSessionManager({
        pageDataManager: pageDataManager,
    });
    //#endregion

    const state = UseCompState({parentState: props.parentComponentState});
    state.forceRender = forceRender;


    //#region :Popup :createModalLogin
    const createModalLogin_refModal = useRef<any>(null);
    const createModalLogin = (e: any, tasks?: string[]): void => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            //title: 'test',
            content: <Partial_Modal_Login
                PrefixTarget={'Partial_Modal_Login'}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModalLogin_refModal,
                }}
            />,
            className: 'myClass',
            width: ' ',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModalLogin_refModal.current = Modal.info(config);
    }
    //#endregion

    return (
        <div className="page-login">
            <div className="loginwrapper">
                <div className="loginbox">
                    <div className="loginpanel">
                        <div className="login">
                            <h3>歡迎使用 Vetsion！</h3>

                            <Partial_Modal_Login
                                PrefixTarget={'Partial_Modal_Login'}
                                pageDataManager={pageDataManager}
                                sessionManager={sessionManager}
                                navigate={navigate}

                                viewModel={{
                                    refModal: null,
                                }}
                            />

                        </div>

                    </div>
                </div>
                <div className="brandbox">
                    <div className="boxgroup">
                        <div className="logo"></div>
                        <div className="slogn">獸醫師的好朋友</div>
                        <div className="version">V.0.0.0 -2021</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

Pg_Login.displayName = 'Pg_Login';
