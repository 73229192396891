import React, {useEffect, useRef} from 'react';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import {
    Form, Input, Button, Alert, Checkbox, Result, message, Space,
    Modal, Typography, Tabs, Select, Popconfirm, Table, Empty, Spin, Badge, DatePicker,Tooltip
} from 'antd';
import {useApp} from "../overmind";
import {UserInfo} from "../global";
import {Static} from "../_/Static";
import {ApiService} from "../_/ApiService";
import _ from "../_";
import {
    EyeInvisibleOutlined,
    EyeTwoTone,
    UserOutlined,
    LockOutlined,
    SearchOutlined,
    LeftOutlined,
    ReloadOutlined
} from '@ant-design/icons';

import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import UsePageDataManager from '../hooks/UsePageDataManager';
import UseSessionManager from "../hooks/UseSessionManager";
import UseLoadingManager from "../hooks/UseLoadingManager";
import UseCompState from "../hooks/UseCompState";
import {lastValueFrom} from "rxjs";
import Partial_Appointment__Modal_AddOwner from "./Partial_Appointment__Modal_AddOwner";
import Partial_Appointment__Modal_AddPet from "./Partial_Appointment__Modal_AddPet";
import Partial_Modal_Search from "./Partial_Appointment__Modal_Search";
import Partial_CommonCard from "./Partial_CommonCard";
import Partial_Modal_Schedule from "./Partial_Modal_Schedule";
import Partial_Reservation__Pane_ReservationList from "./Partial_Reservation__Pane_ReservationList";
import Partial_Reservation__Pane_ReservationUncheckedList from "./Partial_Reservation__Pane_ReservationUncheckedList";
import Partial_Search_TimeRangeDoctorFilter from "./Partial_Search_TimeRangeDoctorFilter";

import './Pg_Waitingroom.scss';
import FunctionBtn from './Box_Functionbtn';


const iconLighting = require('../assets/images/icon/lighting.svg');
const iconWatting = require('../assets/images/icon/new-medical-record.svg');
const iconReservation = require('../assets/images/icon/calendarsp.svg');

const onSearch = value => console.log(value);
const {Title} = Typography;
const {TabPane} = Tabs;
const {Search} = Input;
const {Option} = Select;
const dateFormat = 'YYYY/MM/DD';


type Props = {
    PrefixTarget?: string;
    RoutePathname?: string;
    MenuTemplateId?: string;

    parentState?: any;
};
export default function Pg_Reservation(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Root';
    let RoutePathname = props.RoutePathname;
    let MenuTemplateId = props.MenuTemplateId;

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    //#region LoadingManager
    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: true});
    //#endregion

//#region PageDataManager
    const refDoLoadPageData = Static.refDoLoadPageData;
    let pageDataManager = UsePageDataManager({
        refPageLoad: refDoLoadPageData
    });
    //#endregion

    //#region SessionManager
    let sessionManager = UseSessionManager({
        pageDataManager: pageDataManager,
    });
    //#endregion

    //#region :State CompState
    let defaultS = '全部';
    let defaultQ = '';

    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

        state.pageDataManager = pageDataManager;
        state.sessionManager = sessionManager;
        state.loadingManager = loadingManager;
        state.navigate = navigate;
        state.forceRender = forceRender;

        state.subscribed = [];

        state.s = pageDataManager.get(`${PrefixTarget}.s`) ?? defaultS;
        state.q = pageDataManager.get(`${PrefixTarget}.q`) ?? defaultQ;
    }
    //#endregion

    const iconWaitforCase = require('../assets/images/icon/nocase.svg');

    //#region :Popup :createModalSchedule
    const createModalSchedule_refModal = useRef<any>(null);
    const createModalSchedule = (e: any, tasks?: string[]): void => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_Modal_Schedule
                PrefixTarget={'Partial_Modal_Schedule'}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModalSchedule_refModal,
                }}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '80em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModalSchedule_refModal.current = Modal.info(config);
    }
    //#endregion

    //#region Boot
    useEffect(() => {
        const _loadPageData = async (): Promise<void> => {
            let hide = message.loading({
                type: 'loading',
                class: 'myMessage',
                content: <div className="myMessageBody">搜尋中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            //const id : any = this.props.match.params.id;
            // const id = 1;
            // if (!id) {
            //     return; //ERROR INPUTS
            // }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                //p1: 1,
                s: state.s,
                q: state.q,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/pg_reservation');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        pageDataManager.merge({
                            ...payload,
                            q: state.q,
                            s: state.s,
                        });
                        pageDataManager.apply();

                        pageDataManager.forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPageData = async ({alwaysLoad = false}): Promise<void> => {
            if (!alwaysLoad) {
                pageDataManager.restore();
                if (!pageDataManager.isZoneExist(PrefixTarget)) {
                    await sessionManager.initSessionAuthAsync();
                    //#region User Check //TODO: VERTIFY
                    if (!sessionManager.isMemberLoggedIn()) {
                        Static.NavAwayToLogin(pageDataManager, navigate)(null);
                        return;
                    }
                    //#endregion
                    await _loadPageData();
                } else {
                    forceRender()
                }
            } else {
                await _loadPageData();
            }
        }

        refDoLoadPageData.current = doLoadPageData;
        refDoLoadPageData.current({});
    }, [location.pathname]);
    //#endregion


    return (
        <div className="page-appointment page-content">
            <div className="info-list">
                <div className="info-box">
                    <Title level={2}>預約中心</Title>
                    <div className="pluginfobox">
                        <div className="title">
                        <Tooltip title="刷新">
                            <Button icon={<ReloadOutlined />} shape="circle" onClick={(e) => {
                                ///reload
                                if (e) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                                pageDataManager.clear();
                                pageDataManager.reloadAsync({alwaysLoad: true});
                            }}/>
                            </Tooltip>
                        </div>
                        <div className="info"></div>
                    </div>


                </div>
                <div className="function-box">
                    {/*<Button onClick={createModalSchedule}>*/}
                    {/*    <FunctionBtn iconlink={iconReservation} name="預約總覽"/>*/}
                    {/*</Button>*/}

                </div>
            </div>


            <div className="page-waitingroom">
                <Tabs type="card">
                    <TabPane tab="預約列表" key="1">

                        <div className="patient-list">
                            {/*<Partial_Search_TimeRangeDoctorFilter*/}
                            {/*    PrefixTarget={'Partial_Reservation__Pane_ReservationUncheckedList'}*/}
                            {/*    pageDataManager={pageDataManager}*/}
                            {/*    sessionManager={sessionManager}*/}
                            {/*    navigate={navigate}*/}

                            {/*    viewModel={{*/}
                            {/*        refModal: null,*/}
                            {/*    }}*/}
                            {/*/>*/}

                            <Partial_Reservation__Pane_ReservationList
                                PrefixTarget={'Partial_Reservation__Pane_ReservationList'}
                                pageDataManager={pageDataManager}
                                sessionManager={sessionManager}
                                navigate={navigate}

                                viewModel={{
                                    refModal: null,
                                }}
                            />
                        </div>
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                <Space>
                                未確認
                                    {/*<Badge count={25}/>*/}
                                </Space>
                            </span>
                        } key="2"
                    >
                        <div className="patient-list">
                            {/*<Partial_Search_TimeRangeDoctorFilter*/}
                            {/*    PrefixTarget={'Partial_Reservation__Pane_ReservationUncheckedList'}*/}
                            {/*    pageDataManager={pageDataManager}*/}
                            {/*    sessionManager={sessionManager}*/}
                            {/*    navigate={navigate}*/}

                            {/*    viewModel={{*/}
                            {/*        refModal: null,*/}
                            {/*    }}*/}
                            {/*/>*/}
                            <div className="patient-box">
                                <Partial_Reservation__Pane_ReservationUncheckedList
                                    PrefixTarget={'Partial_Reservation__Pane_ReservationUncheckedList'}
                                    pageDataManager={pageDataManager}
                                    sessionManager={sessionManager}
                                    navigate={navigate}

                                    viewModel={{
                                        refModal: null,
                                    }}
                                />
                            </div>
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}

Pg_Reservation.displayName = 'Pg_Reservation';




























