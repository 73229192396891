import React from 'react';
import './Box_Functionbtn.scss';

interface Props {
  iconlink :string;
  name :string;
}

function Box_Functionbtn(props:Props) {
  return (
    <div className="function-btn">
      <div className="btn">
        <img src={props.iconlink}  alt="icon"/>
      </div>
      <div className="btn-name">{props.name}</div>
    </div>
  )
}

export default Box_Functionbtn;
