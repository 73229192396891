import React, {useEffect, useRef} from 'react';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import {
    Form, Input, Button, Alert, Checkbox, Result, message, Space,
    Modal, Typography, Tabs, Select, Popconfirm, Table, Empty, Spin, DatePicker, Menu, Dropdown, Tooltip
} from 'antd';
import {useApp} from "../overmind";
import {UserInfo} from "../global";
import {Static} from "../_/Static";
import {ApiService} from "../_/ApiService";
import _ from "../_";
import {
    EyeInvisibleOutlined,
    EyeTwoTone,
    EditOutlined,
    IdcardFilled,
    RedoOutlined,
    LeftOutlined,
    RightOutlined,
    PrinterOutlined,
    RollbackOutlined,
    ArrowLeftOutlined, ReloadOutlined
} from '@ant-design/icons';

import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import UsePageDataManager from '../hooks/UsePageDataManager';
import UseSessionManager from "../hooks/UseSessionManager";
import UseLoadingManager from "../hooks/UseLoadingManager";
import UseCompState from "../hooks/UseCompState";
import {lastValueFrom} from "rxjs";

import Partial_Manage_Agency__Pane_EditOrAddAgency from "./Partial_Manage_Agency__Pane_EditOrAddAgency";


import './Pg_Waitingroom.scss';
import FunctionBtn from './Box_Functionbtn';
import moment from 'moment';

const iconLighting = require('../assets/images/icon/lighting.svg');
const iconWatting = require('../assets/images/icon/new-medical-record.svg');

const onSearch = value => console.log(value);
const {Title} = Typography;
const {TabPane} = Tabs;
const {Search} = Input;
const {Option} = Select;
const dateFormat = 'YYYY/MM/DD';
const {RangePicker} = DatePicker;


type Props = {
    PrefixTarget?: string;
    RoutePathname?: string;
    MenuTemplateId?: string;

    parentState?: any;
};
export default function Pg_Manage_Agency(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Root';
    let RoutePathname = props.RoutePathname;
    let MenuTemplateId = props.MenuTemplateId;

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    //#region LoadingManager
    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: true});
    //#endregion

//#region PageDataManager
    const refDoLoadPageData = Static.refDoLoadPageData;
    let pageDataManager = UsePageDataManager({
        refPageLoad: refDoLoadPageData
    });
    //#endregion

    //#region SessionManager
    let sessionManager = UseSessionManager({
        pageDataManager: pageDataManager,
    });
    //#endregion


    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

        state.forceRender = forceRender;
    }
    //#endregion

    //#region Boot
    useEffect(() => {
        // const _loadPageData = async (): Promise<void> => {
        //
        //     // VALIDATE INPUT
        //     const p2: any = paramRouting_ArticleId;
        //     if (!p2) {
        //         alert('error inputs');
        //         return; //ERROR INPUTS
        //     }
        //
        //     // WAITING SCREEN
        //     loadingManager.changeLoadingCount(+1);
        //     //StaticEvent.LoadingEvent.next(true);
        //
        //     // POST
        //     let postData = {
        //         p2: p2,
        //         timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
        //     };
        //     const postUrl = ApiService.urlMapping('~/h_api/1/pg_article');
        //     const post$ = Static.apiService.post(postUrl, postData);
        //     post$.subscribe(async (res: any) => {
        //
        //             // CLOSE WAITING SCREEN
        //             loadingManager.changeLoadingCount(-1);
        //             //StaticEvent.LoadingEvent.next(false);
        //
        //             let data = res.data;
        //             let error = data.error || null;
        //             let result = error == null;
        //
        //             if (result) { // SUCEESS
        //                 let payload = data.payload;
        //                 _._debug.warn(payload);
        //
        //
        //                 if (payload[PrefixTarget].Item_文章 != null) {
        //                     payload[PrefixTarget].Item_文章.__留言_IsActive = true;
        //
        //                 } else {
        //                     //payload[PrefixTarget].error = 'error';
        //                     // pageDataManager.merge({
        //                     //     [PrefixTarget]: {
        //                     //         Error: error.message,
        //                     //     }
        //                     // });
        //                     // pageDataManager.apply();
        //                     // pageDataManager.forceRender();
        //                 }
        //
        //                 pageDataManager.emptyZone(PrefixTarget);
        //                 pageDataManager.merge(payload);
        //                 pageDataManager.apply();
        //                 pageDataManager.forceRender();
        //             } else {
        //                 // AUTH USER EXPIRED T:1
        //                 if (error.code == 2) {
        //                     await sessionManager.setLogout();
        //                     Static.NavAwayToLogin(pageDataManager, navigate)(null);
        //                     return;
        //                 }
        //
        //                 ///TODO: Error Handling
        //                 alert(error.message);
        //                 _._debug.error(error);
        //
        //             }
        //         }, async (err) => {
        //
        //             // CLOSE WAITING SCREEN
        //             loadingManager.changeLoadingCount(-1);
        //             //StaticEvent.LoadingEvent.next(false);
        //             _._debug.warn(err);
        //             alert('網路連線有問題');
        //         }
        //     );
        //     return lastValueFrom(
        //         post$
        //     );
        // }
        const _doLoadPageData = async (): Promise<void> => {
            pageDataManager.restore();
            if (!pageDataManager.isZoneExist(PrefixTarget)) {
                await sessionManager.initSessionAuthAsync();
                //#region User Check //TODO: VERTIFY
                if (!sessionManager.isMemberLoggedIn()) {
                    Static.NavAwayToLogin(pageDataManager, navigate)(null);
                    return;
                }
                //#endregion
                //await _loadPageData();
            } else {
                forceRender()
            }
        }

        refDoLoadPageData.current = _doLoadPageData;
        refDoLoadPageData.current();
    }, [location.pathname]);
    //#endregion


    const iconWaitforCase = require('../assets/images/icon/nocase.svg');
    const [noCase, setNoCase] = useState(false);

    function callback(key) {
        console.log(key);
    }

    function confirm(e) {
        console.log(e);
    }

    function cancel(e) {
        console.log(e)
    }


    return (
        <div className="page-appointment page-content">
            <div className="info-list">
                <div className="info-box">
                    <Title level={2}>院所設定</Title>
                    <div className="title"><Tooltip title="刷新">
                        <Button icon={<ReloadOutlined />} shape="circle" onClick={(e) => {
                            ///reload
                            if (e) {
                                e.preventDefault();
                                e.stopPropagation();
                            }
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                        }}/>
                    </Tooltip></div>

                    <div className="pluginfobox">
                        <div className="title"></div>
                        <div className="info"></div>
                    </div>

                </div>
                <div className="function-box">
                    <button>

                    </button>

                </div>
            </div>
            <Partial_Manage_Agency__Pane_EditOrAddAgency
                PrefixTarget={'Partial_Manage_Agency__Pane_EditOrAddAgency'}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: null,
                }}
            />
        </div>
    )
}

Pg_Manage_Agency.displayName = 'Pg_Manage_Agency';




























