import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
import './Partial_MedicalRecord__Tab_Treatment.scss';
//import styles from './_Index.style';

import {
    Form as NForm, Popover, Button, Modal, Switch,
    message, Upload, Input, Space, Tooltip, Tabs, Table, Popconfirm, DatePicker, Menu, Dropdown, Spin
} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";

import {DeleteFilled, MessageFilled, DownloadOutlined, EditOutlined, LoadingOutlined} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";
import moment from 'moment';

import Box_MRbtn from './Box_MRbtn';
import Partial_MedicalRecord__Modal_PastMedicalHistory from "./Partial_MedicalRecord__Modal_PastMedicalHistory";
import Partial_MedicalRecord__Modal_ClinicalAdd from "./Partial_MedicalRecord__Modal_ClinicalAdd";
import Partial_MedicalRecord__Modal_Prescribe2 from "./Partial_MedicalRecord__Modal_Prescribe2";
import Partial_MedicalRecord__Modal_Dealwith from "./Partial_MedicalRecord__Modal_Dealwith";
import Partial_MedicalRecord__Modal_TransferToHospital from "./Partial_MedicalRecord__Modal_TransferToHospital";
import Partial_MedicalRecord__Modal_ProcessBilling from "./Partial_MedicalRecord__Modal_ProcessBilling";
import Partial_MedicalRecord__Modal_Measuring from "./Partial_MedicalRecord__Modal_Measuring";
import Partial_MedicalRecord__Modal_Note from "./Partial_MedicalRecord__Modal_Note";
import Partial_MedicalRecord__Modal_SymptomAdd from "./Partial_MedicalRecord__Modal_SymptomAdd";
import Partial_MedicalRecord__Modal_Prescribe from "./Partial_MedicalRecord__Modal_Prescribe";
import Partial_Appointment__Modal_AddReservation from "./Partial_Appointment__Modal_AddReservation";
import UseCompState from "../hooks/UseCompState";
import Partial_inHospital__Pane_MsgBoard from "./Partial_inHospital__Pane_MsgBoard";
import {ReactComponent} from "*.svg";


const iconBandage = require('../assets/images/icon/bandage.svg');
const iconGrooming = require('../assets/images/icon/grooming.svg');
const iconDropper = require('../assets/images/icon/dropper.svg');
const iconLamp = require('../assets/images/icon/lamp.svg');

const iconGridicons_print = require('../assets/images/icon/gridicons_print.svg');
const iconCalendar = require('../assets/images/icon/calendar.svg');

const iconMoneybag = require('../assets/images/icon/moneybag.svg');

const iconPaw = require('../assets/images/icon/paw.svg');
const iconPills = require('../assets/images/icon/pills.svg');
const iconSyringe = require('../assets/images/icon/syringe.svg');
const iconStethoscope = require('../assets/images/icon/stethoscope.svg');
const iconPassport = require('../assets/images/icon/passport.svg');
const iconMicroscope = require('../assets/images/icon/microscope.svg');
const iconMedicalhistory = require('../assets/images/icon/medical history.svg');
const iconElizabethan_collar = require('../assets/images/icon/elizabethan_collar.svg');

const iconWaitforCase = require('../assets/images/icon/nocase.svg');

const {TextArea} = Input;

type ViewModelProps = {
    refModal: any,

    病歷No: any,
    掛號No: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any,

    viewModel: ViewModelProps,
};


export default function Partial_MedicalRecord__Tab_Treatment(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_MedicalRecord__Tab_Treatment';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel = props.viewModel;

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region Boot
    let _refDoLoadPartialData: any = useRef<{ alwaysLoad?: boolean }>(null);
    useEffect(() => {
        const _loadPartialData = async (): Promise<void> => {

            // VALIDATE INPUT
            const p1 = viewModel.病歷No;
            if (!p1) {
                alert('p1');
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/partial_medicalrecord/tab_treatment');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        let changeShard = {
                            [PrefixTarget]: {
                                ...payload,
                            }
                        }
                        pageDataManager.merge(changeShard);
                        pageDataManager.apply();

                        forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPartialData = async ({alwaysLoad = false}): Promise<void> => {
            // if (!pageDataManager.refPageData.current.Root) {
            //     return;
            // }

            /// do not dynamically load data if condition fits.
            if (!alwaysLoad) {
                if (pageDataManager.isZoneExist(PrefixTarget)) {
                    return;
                }
            }

            await _loadPartialData();
        }

        _refDoLoadPartialData.current = doLoadPartialData;
        _refDoLoadPartialData.current({});
    }, [pageDataManager.get(PrefixTarget)]);
    //#endregion

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

        state._refDoLoadPartialData = _refDoLoadPartialData;

        state.掛號No = viewModel.掛號No;
        state.病歷No = viewModel.病歷No;
    }
    //#endregion

    const Body = () => {
        const RegionEmptyShow = false;
        const RegionLoadingShow = true;
        const RegionInitialLoadingShow = true;

        const PassPageDataInitalized = pageDataManager.refPageData.current != null;
        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;

        let Item_病歷 = pageDataManager.get(`${PrefixTarget}.Item_病歷`);
        let List_病歷項 = Item_病歷?.__病歷項_全部;

        //#region Handlers
        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            // if (!values.username) {
            //     errors.username = '↑ 欄位為必填';
            // } else {
            // }
            //
            // if (!values.password) {
            //     errors.password = '↑ 欄位為必填';
            // } else {
            // }

            return errors;
        };

        //#region :Popup :createModal_Module_AddInHospital

        const createModal_Module_AddInHospital_refModal = useRef<any>(null);
        const createModal_Module_AddInHospital = (e: any, tasks?: string[]): void => {
            if (e) {
                e.preventDefault();
            }
            // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
            //
            // if (!PassPageDataInitalized) {
            //     //empty
            // }

            const config = {
                // title: 'test',
                content: <Partial_MedicalRecord__Modal_TransferToHospital
                    PrefixTarget={'Partial_MedicalRecord__Modal_TransferToHospital'}
                    pageDataManager={pageDataManager}
                    sessionManager={sessionManager}
                    navigate={navigate}

                    viewModel={{
                        refModal: createModal_Module_AddInHospital_refModal,

                        病歷No: viewModel.病歷No,
                        掛號No: viewModel.掛號No,

                        OnDid: () => {
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            //state._refDoLoadPartialData?.current?.({alwaysLoad: true});
                        }
                    }}
                />,
                okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
                className: 'myClass myModal',
                width: '80em',
                height: ' ',
                footer: null,
                //prefixCls: '',
            };

            createModal_Module_AddInHospital_refModal.current = Modal.info(config);
        }
        //#endregion

        //#region :Popup :createModalReservationn
        const createModalReservation_refModal = useRef<any>(null);
        const createModalReservation = (e: any, {寵物_飼主__關係No, tasks}: {
            寵物_飼主__關係No: any,
            tasks?: string[]
        }) => {
            if (e) {
                e.preventDefault();
            }
            // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
            //
            // if (!PassPageDataInitalized) {
            //     //empty
            // }

            const config = {
                // title: 'test',
                content: <Partial_Appointment__Modal_AddReservation
                    PrefixTarget={`Partial_Appointment__Modal_EditOrAddReservation${寵物_飼主__關係No}`}
                    pageDataManager={pageDataManager}
                    sessionManager={sessionManager}
                    navigate={navigate}

                    viewModel={{
                        refModal: createModalReservation_refModal,

                        寵物_飼主__關係No,
                    }}
                />,
                okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
                className: 'myClass myModal',
                width: '35em',
                height: ' ',
                footer: null,
                //prefixCls: '',
            };

            createModalReservation_refModal.current = Modal.info(config);
        }
        //#endregion

        //#region :Popup :createModalClinicalDiagnosis
        const createModal_CD_refModal = useRef<any>(null);
        const createModal_CD = (e: any, tasks?: string[]): void => {
            if (e) {
                e.preventDefault();
            }
            // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
            //
            // if (!PassPageDataInitalized) {
            //     //empty
            // }

            const config = {
                // title: 'test',
                content: <Partial_MedicalRecord__Modal_ClinicalAdd
                    PrefixTarget={'Partial_MedicalRecord__Modal_ClinicalAdd'}
                    pageDataManager={pageDataManager}
                    sessionManager={sessionManager}
                    navigate={navigate}
                    parentState={state}

                    viewModel={{
                        refModal: createModal_CD_refModal,

                        病歷No: viewModel.病歷No,
                        掛號No: viewModel.掛號No,

                        OnDid: () => {
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            //state._refDoLoadPartialData?.current?.({alwaysLoad: true});
                        }
                    }}
                />,
                okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
                className: 'myClass myModal',
                width: '100em',
                height: ' ',
                footer: null,
                //prefixCls: '',
            };

            createModal_CD_refModal.current = Modal.info(config);
        }
        //#endregion

        //#region :Popup :createModalPrescribeRecord
        const createModalPrescribe_refModal = useRef<any>(null);
        const createModalPrescribe = (e: any, tasks?: string[]): void => {
            if (e) {
                e.preventDefault();
            }
            // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
            //
            // if (!PassPageDataInitalized) {
            //     //empty
            // }

            //<Partial_MedicalRecord__Modal_Prescribe

            const config = {
                // title: 'test',
                content: <Partial_MedicalRecord__Modal_Prescribe
                    PrefixTarget={'Partial_MedicalRecord__Modal_Prescribe'}
                    pageDataManager={pageDataManager}
                    sessionManager={sessionManager}
                    navigate={navigate}
                    parentState={state}

                    viewModel={{
                        refModal: createModalPrescribe_refModal,

                        病歷No: viewModel.病歷No,
                        掛號No: viewModel.掛號No,

                        OnDid: () => {
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            //state._refDoLoadPartialData?.current?.({alwaysLoad: true});
                        }
                    }}
                />,
                okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
                className: 'myClass myModal',
                width: '80em',
                height: ' ',
                footer: null,
                //prefixCls: '',
            };

            createModalPrescribe_refModal.current = Modal.info(config);
        }
        //#endregion

        //#region :Popup :createModalProcessBilling
        const createModalCharge_refModal = useRef<any>(null);
        const createModalCharge = (e: any, tasks?: string[]): void => {
            if (e) {
                e.preventDefault();
            }
            // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
            //
            // if (!PassPageDataInitalized) {
            //     //empty
            // }

            const config = {
                // title: 'test',
                content: <Partial_MedicalRecord__Modal_ProcessBilling
                    PrefixTarget={'Partial_MedicalRecord__Modal_ProcessBilling'}
                    pageDataManager={pageDataManager}
                    sessionManager={sessionManager}
                    navigate={navigate}
                    parentState={state}

                    viewModel={{
                        refModal: createModalCharge_refModal,

                        病歷No: viewModel.病歷No,
                        掛號No: viewModel.掛號No,

                        OnDid: () => {
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            //state._refDoLoadPartialData?.current?.({alwaysLoad: true});
                        }
                    }}
                />,
                okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
                className: 'myClass myModal',
                width: '110em',
                height: ' ',
                footer: null,
                //prefixCls: '',
            };

            createModalCharge_refModal.current = Modal.info(config);
        }
        //#endregion

        //#region :Popup :createModalDealwith
        const createModal_Dealwith_refModal = useRef<any>(null);
        const createModal_Dealwith = (e: any, tasks?: string[]): void => {
            if (e) {
                e.preventDefault();
            }
            // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
            //
            // if (!PassPageDataInitalized) {
            //     //empty
            // }

            const config = {
                // title: 'test',
                content: <Partial_MedicalRecord__Modal_Dealwith
                    PrefixTarget={'Partial_MedicalRecord__Modal_Dealwith'}
                    pageDataManager={pageDataManager}
                    sessionManager={sessionManager}
                    navigate={navigate}
                    parentState={state}

                    viewModel={{
                        refModal: createModal_Dealwith_refModal,

                        病歷No: viewModel.病歷No,
                        掛號No: viewModel.掛號No,

                        OnDid: () => {
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            //state._refDoLoadPartialData?.current?.({alwaysLoad: true});
                        }
                    }}
                />,
                okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
                className: 'myClass myModal',
                width: '80em',
                height: ' ',
                footer: null,
                //prefixCls: '',
            };

            createModal_Dealwith_refModal.current = Modal.info(config);
        }
        //#endregion

        //#region :Popup :createModalMeasuring
        const createModal_Measuring_refModal = useRef<any>(null);
        const createModal_Measuring = (e: any, tasks?: string[]): void => {
            if (e) {
                e.preventDefault();
            }
            // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
            //
            // if (!PassPageDataInitalized) {
            //     //empty
            // }

            const config = {
                // title: 'test',
                content: <Partial_MedicalRecord__Modal_Measuring
                    PrefixTarget={'Partial_MedicalRecord__Modal_Measuring'}
                    pageDataManager={pageDataManager}
                    sessionManager={sessionManager}
                    navigate={navigate}
                    parentState={state}

                    viewModel={{
                        refModal: createModal_Measuring_refModal,

                        病歷No: viewModel.病歷No,
                        掛號No: viewModel.掛號No,

                        OnDid: () => {
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            //state._refDoLoadPartialData?.current?.({alwaysLoad: true});
                        }
                    }}
                />,
                okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
                className: 'myClass myModal',
                width: '60em',
                height: ' ',
                footer: null,
                //prefixCls: '',
            };

            createModal_Measuring_refModal.current = Modal.info(config);
        }
        //#endregion

        //#region :Popup :createModalNote
        const createModalNote_refModal = useRef<any>(null);
        const createModalNote = (e: any, tasks?: string[]): void => {
            if (e) {
                e.preventDefault();
            }
            // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
            //
            // if (!PassPageDataInitalized) {
            //     //empty
            // }

            const config = {
                // title: 'test',
                content: <Partial_MedicalRecord__Modal_Note
                    PrefixTarget={'Partial_MedicalRecord__Modal_Note'}
                    pageDataManager={pageDataManager}
                    sessionManager={sessionManager}
                    navigate={navigate}
                    parentState={state}

                    viewModel={{
                        refModal: createModalNote_refModal,

                        病歷No: viewModel.病歷No,
                        掛號No: viewModel.掛號No,

                        OnDid: () => {
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            //state._refDoLoadPartialData?.current?.({alwaysLoad: true});
                        }
                    }}
                />,
                okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
                className: 'myClass myModal',
                width: '80em',
                height: ' ',
                footer: null,
                //prefixCls: '',
            };

            createModalNote_refModal.current = Modal.info(config);
        }
        //#endregion

        //#region :Popup :createModalSymptom
        const createModalSymptom_refModal = useRef<any>(null);
        const createModalSymptom = (e: any, tasks?: string[]): void => {
            if (e) {
                e.preventDefault();
            }
            // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
            //
            // if (!PassPageDataInitalized) {
            //     //empty
            // }

            const config = {
                // title: 'test',
                content: <Partial_MedicalRecord__Modal_SymptomAdd
                    PrefixTarget={'Partial_MedicalRecord__Modal_SymptomAdd'}
                    pageDataManager={pageDataManager}
                    sessionManager={sessionManager}
                    navigate={navigate}
                    parentState={state}

                    viewModel={{
                        refModal: createModalSymptom_refModal,

                        病歷No: viewModel.病歷No,
                        掛號No: viewModel.掛號No,

                        OnDid: () => {
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            //state._refDoLoadPartialData?.current?.({alwaysLoad: true});
                        }
                    }}
                />,
                okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
                className: 'myClass myModal',
                width: '80em',
                height: ' ',
                footer: null,
                //prefixCls: '',
            };

            createModalSymptom_refModal.current = Modal.info(config);
        }
        //#endregion


        const handleSubmit_delete = (e: any, v: any) => {
            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            const p1 = v.病歷項No;
            if (!p1) {
                _._debug.error(p1);
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_medicalrecord/tab_treatment/delete');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        message.success('已刪除此筆資料！');
                        _refDoLoadPartialData.current({alwaysLoad: true});
                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }


        const handleClose = (e) => {
            if (e) {
                e.preventDefault();
            }

            if (viewModel.refModal || viewModel.refModal.current) {
                viewModel.refModal.current.destroy();
            }
        };

        const handleSubmit_clickLogin = (e: any, v: any) => {
            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">正在讓狗狗貓貓們回到自己的位子</div>,
                key: 'loading',
                duration: 0
            });

            //#region Handle SaveUserInfo
            {
                // VALIDATE INPUT
                //const id : any = this.props.match.params.id;
                const id = 1;
                if (!id) {
                    return; //ERROR INPUTS
                }

                // WAITING SCREEN
                loadingManager.changeLoadingCount(+1);
                //StaticEvent.LoadingEvent.next(true);

                // POST
                let postData = {
                    p1: v.p1,
                    p2: v.p2,
                    p3: v.p3,
                    p4: v.p4,
                    p5: v.p5,
                    timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
                };
                const postUrl = ApiService.urlMapping('~/h_api/1/pg_jumplogin/clicklogin');
                const post$ = Static.apiService.post(postUrl, postData);
                post$.subscribe(async (res: any) => {
                        hide();


                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);

                        let data = res.data;
                        let error = data.error || null;
                        let result = error == null;

                        if (result) { // SUCEESS
                            let payload = data.payload;
                            _._debug.warn(payload);

                            message.success({
                                type: 'success',
                                className: 'myMessage',
                                content: <div className="myMessageBody">歡迎來到，Vetsion</div>,
                                key: '',
                                duration: 3
                            });

                            // message.success({
                            //     type: 'success',
                            //     className: 'myMessage',
                            //     content: <div className="myMessageBody">門就開了</div>,
                            //     key: '',
                            //     duration: 3
                            // });


                            await sessionManager.setLoginData(
                                payload.SessionId,
                                payload.AuthData
                            );

                            navigate({
                                pathname: '/wall/newest',
                                //search: "?sort=name",
                                //hash: "#the-hash",
                                //state: {panelSelection: "f1"}
                            });
                        } else {
                            // AUTH USER EXPIRED
                            if (error.code == 2) {
                                //alert('帳號被登出，請重新登入');
                                //window.location.href = '/login';
                                //this.props.history.push('/login')
                                await sessionManager.setLogout();
                                pageDataManager.clear();
                                pageDataManager.reloadAsync({alwaysLoad: true});
                                alert('時間逾時已被登出');
                                return;
                            }

                            _._debug.error(error);
                            alert(error.message);
                        }
                    }, async (err) => {
                        hide();

                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);
                        _._debug.warn(err);
                        alert('網路連線有問題');
                    }
                );
                return lastValueFrom(
                    post$
                );
            }
            //#endregion
        }


        function confirm(e) {
            console.log(e);
            message.success('已刪除此筆資料！');
        }

        function cancel(e) {
            console.log(e);
        }

        //#endregion

        const ShowComment = () => {
            let [isOn, setIsOn] = useState(true);
            let toggleState = () => setIsOn(prevState => !prevState);

            let showTitle = () => isOn ? null : <Comment/>;


            return (
                <div>
                    <Button icon={<MessageFilled/>} type="link" onClick={toggleState}>新增註記</Button>
                    {showTitle()}
                    {/* { showResults ? null : <ShowComment/> } */}
                </div>
            )
        }

        const Comment = () => (
            <div className="comment">
                <div className="usr">
                    <div className="usrcube">
                        <div className="avatertext">陳</div>
                    </div>

                    <div className="inputarea">

                        <Form
                            onSubmit={(v) => handleSubmit_clickLogin(null, v)}
                            initialValues={{
                                隱私: '公開',
                                內容: '',
                            }}
                            validate={handleValidate}
                            render={({
                                         submitError,
                                         handleSubmit,
                                         submitting,
                                         pristine,
                                         valid,
                                         error,
                                         values
                                     }) => {

                                return <React.Fragment>
                                    <form onSubmit={handleSubmit}>

                                        <div>
                                            <Field parse={_.bs.identity} name="comment">
                                                {
                                                    ({input, meta}) => (
                                                        <React.Fragment>
                                                            <NForm.Item {...input}
                                                                        name="comment"

                                                            >
                                                                <TextArea {...input} rows={2}
                                                                          placeholder='可輸入要對這次處置的備忘或說明'/>
                                                                {meta.touched && meta.error &&
                                                                <div className="error">
                                                                    {meta.error}
                                                                </div>}
                                                            </NForm.Item>
                                                        </React.Fragment>
                                                    )
                                                }
                                            </Field>
                                            <Button icon={<MessageFilled/>} className='ant-btn-primary-outline'
                                                    type="link" htmlType="submit">送出註記</Button>
                                        </div>
                                    </form>
                                </React.Fragment>;
                            }}
                        />

                    </div>
                </div>

            </div>
        )

        const FunctionNote = (Item_病歷項: any) => {


            let Content: any = () => {
                return <div className="mr_card f_blue">
                    <div className="titlebar">
                        <h4>備註</h4>
                        <div className="infobar">
                            <div
                                className="times">{Item_病歷項.CreatedAt ? moment(Item_病歷項.CreatedAt).format('YYYY-MM-DD hh:mm') : '-'}</div>
                            <div className="peo">
                                {Item_病歷項.__醫生_建檔醫師 ? <React.Fragment>
                                    <div className="avatertext">{Item_病歷項.__醫生_建檔醫師.名稱?.charAt(0)}</div>
                                    <div className="namecode">{Item_病歷項.__醫生_建檔醫師.名稱} 醫師</div>
                                </React.Fragment> : <React.Fragment>
                                    <div className="namecode">沒有建檔醫生</div>
                                </React.Fragment>}
                            </div>
                        </div>
                        <div className="func">
                            <Popconfirm
                                title="刪除整張卡片後，所有卡片內的動作項目
                            將一併清除，是否確認刪除? "
                                onConfirm={() => {
                                    handleSubmit_delete(null, {病歷項No: Item_病歷項.病歷項No})
                                }}
                                onCancel={cancel}
                                okText="刪除"
                                cancelText="取消"
                                okButtonProps={{danger: true}}
                            >
                                <Tooltip title="刪除" placement="right">
                                    <Button icon={<DeleteFilled/>} type="link"/>
                                </Tooltip>
                            </Popconfirm>
                        </div>
                    </div>
                    <div className="cardcontent">
                        <div className='note_serial'>
                            <div className="note_box">
                                <div className="item">
                                    <div className="type">醫療備註</div>
                                    <div
                                        className="times">{Item_病歷項.CreatedAt ? moment(Item_病歷項.CreatedAt).format('YYYY-MM-DD hh:mm') : '-'}</div>
                                </div>
                                <div className="plus_con">
                                    <div className="title">{Item_病歷項.備註_內容}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cardfooter">
                        <Partial_inHospital__Pane_MsgBoard
                            key={Item_病歷項.病歷項No}
                            //PrefixTarget={`Partial_inHospital__Pane_MsgBoard`}
                            pageDataManager={pageDataManager}
                            sessionManager={sessionManager}
                            navigate={navigate}

                            viewModel={{
                                refModal: null,
                                留言板No: Item_病歷項.留言板No_住院留言板
                            }}
                        />
                    </div>
                </div>;
            }
            return Content();
        };

        const FunctionRecord = () => (
            <div className="mr_card f_blue">
                <div className="titlebar">
                    <h4>過往紀錄</h4>
                    <div className="infobar">
                        <div className="times">10:30:00</div>
                        <div className="peo">
                            <div className="avatertext">陳</div>
                            <div className="namecode">陳大發 醫師</div>
                        </div>
                    </div>
                    <div className="func">
                        <Popconfirm
                            title="刪除整張卡片後，所有卡片內的動作項目
                            將一併清除，是否確認刪除? "
                            onConfirm={confirm}
                            onCancel={cancel}
                            okText="刪除"
                            cancelText="取消"
                            okButtonProps={{danger: true}}
                        >
                            <Tooltip title="刪除" placement="right">
                                <Button icon={<DeleteFilled/>} type="link"/>
                            </Tooltip>
                        </Popconfirm>
                    </div>
                </div>
                <div className="cardcontent">
                    <div className='note_serial'>
                        <div className="note_box">
                            <div className="type">預約回診</div>
                            <div className="times">10:34:00</div>
                            <div className="content">
                                <div className="iteminfo">
                                    <div className="item_type">編輯過往病史-</div>
                                    <div className="item_title">保健紀錄</div>
                                </div>
                                <div className="cont">絕育狀態：已節育(2019-12-23)
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='note_serial'>
                        <div className="note_box">
                            <div className="type">過往病史</div>
                            <div className="times">10:34:00</div>
                            <div className="content">
                                <div className="iteminfo">
                                    <div className="item_type">編輯過往病史-</div>
                                    <div className="item_title">重大疾病</div>
                                </div>
                                <div className="cont">藥物過敏：紅斑狼瘡
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cardfooter">
                    <div className="extra_note">


                    </div>
                    <ShowComment/>
                </div>
            </div>
        );

        const FunctionReservation = () => (
            <div className="mr_card f_yellow">
                <div className="titlebar">
                    <h4>預約回診</h4>
                    <div className="infobar">
                        <div className="times">10:30:00</div>
                        <div className="peo">
                            <div className="avatertext">陳</div>
                            <div className="namecode">陳大發 醫師</div>
                        </div>
                    </div>
                    <div className="func">
                        <Popconfirm
                            title="刪除整張卡片後，所有卡片內的動作項目
                            將一併清除，是否確認刪除? "
                            onConfirm={confirm}
                            onCancel={cancel}
                            okText="刪除"
                            cancelText="取消"
                            okButtonProps={{danger: true}}
                        >
                            <Tooltip title="刪除" placement="right">
                                <Button icon={<DeleteFilled/>} type="link"/>
                            </Tooltip>
                        </Popconfirm>
                    </div>
                </div>
                <div className="cardcontent">
                    <div className='note_serial'>
                        <div className="note_box">
                            <div className="type">預約回診</div>
                            <div className="times">10:34:00</div>
                            <div className="content">
                                <div className="iteminfo">
                                    <div className="item_type"></div>
                                    <div className="item_title"></div>
                                </div>
                                <div className="cont">
                                    與飼主名稱預訂了YYYY-MM-DD TT:TT回診<br/>
                                    回診事項:預防注射<br/>
                                    喜愛醫師:不指定<br/>
                                    其他說明<br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cardfooter">
                    <div className="extra_note">


                    </div>
                    <ShowComment/>
                </div>
            </div>
        );

        const FunctionHospitalized = (Item_病歷項: any) => {
            // if (!Item_病歷項.__臨床症狀) {
            //     return <div>ERR:{Item_病歷項.病歷項No}-臨床症狀</div>;
            // }

            let Content: any = () => (
                <div className="mr_card f_green">
                    <div className="titlebar">
                        <h4>住院</h4>
                        <div className="infobar">
                            <div
                                className="times">{Item_病歷項.CreatedAt ? moment(Item_病歷項.CreatedAt).format('YYYY-MM-DD hh:mm') : '-'}</div>
                            <div className="peo">
                                {Item_病歷項.__醫生_建檔醫師 ? <React.Fragment>
                                    <div className="avatertext">{Item_病歷項.__醫生_建檔醫師.名稱?.charAt(0)}</div>
                                    <div className="namecode">{Item_病歷項.__醫生_建檔醫師.名稱} 醫師</div>
                                </React.Fragment> : <React.Fragment>
                                    <div className="namecode">沒有建檔醫生</div>
                                </React.Fragment>}
                            </div>
                        </div>
                        <div className="func">
                            <Popconfirm
                                title="刪除整張卡片後，所有卡片內的動作項目
                            將一併清除，是否確認刪除? "
                                onConfirm={() => {
                                    if (!Item_病歷項.住院_住院結束時間) {
                                        alert('籠子還有寵物，請先至籠子管理出院才能刪除');
                                        return;
                                    }
                                    handleSubmit_delete(null, {病歷項No: Item_病歷項.病歷項No})
                                }}
                                onCancel={cancel}
                                okText="刪除"
                                cancelText="取消"
                                okButtonProps={{danger: true}}
                            >
                                <Tooltip title="刪除" placement="right">
                                    <Button icon={<DeleteFilled/>} type="link"/>
                                </Tooltip>
                            </Popconfirm>
                        </div>
                    </div>
                    <div className="cardcontent">
                        <div className='note_serial'>
                            <div className="note_box">
                                <div className="item">
                                    <div className="type">住院</div>
                                    <div
                                        className="times">{Item_病歷項.CreatedAt ? moment(Item_病歷項.CreatedAt).format('YYYY-MM-DD hh:mm') : '-'}</div>
                                </div>
                                <div className="plus_con">
                                    <div className="title">區域 / 籠位</div>
                                    <div
                                        className="disc">住院日:{Item_病歷項.住院_住院開始時間 ? moment(Item_病歷項.住院_住院開始時間).format('YYYY-MM-DD') : '-'} /
                                        預計出院日:{Item_病歷項.住院_住院預計結束時間 ? moment(Item_病歷項.住院_住院預計結束時間).format('YYYY-MM-DD') : '-'}</div>
                                    <div className="note">
                                        住院原因/執行項目: {Item_病歷項.住院_住院原因}
                                    </div>
                                </div>
                            </div>

                            {Item_病歷項.住院_住院結束時間 &&
                            <div className="note_box">
                                <div className="item">
                                    <div className="type">出院</div>
                                    <div className="times">{moment(Item_病歷項.住院_住院結束時間).format('YYYY-MM-DD hh:mm')}</div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    <div className="cardfooter">
                        <Partial_inHospital__Pane_MsgBoard
                            key={Item_病歷項.病歷項No}
                            //PrefixTarget={`Partial_inHospital__Pane_MsgBoard`}
                            pageDataManager={pageDataManager}
                            sessionManager={sessionManager}
                            navigate={navigate}

                            viewModel={{
                                refModal: null,
                                留言板No: Item_病歷項.留言板No_住院留言板
                            }}
                        />
                        {/*<div className="extra_note">*/}
                        {/*    <ul>*/}
                        {/*        <li>*/}
                        {/*            <div className="main_th"><span>陳大發</span> 2021-10-21 14:32</div>*/}
                        {/*            <p>額外註記額外註記額外註記，額外註記額外註記額外註記，額外註記額外註記</p>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <div className="main_th"><span>王清泉</span> 2021-10-22 11:17</div>*/}
                        {/*            <p>額外註記額外註記額外註記，額外註記額外註記額外註記，額外註記額外註記額外註記額外註記額外註記，額外註記額外註記額外註記，額外註記額外註記</p>*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}
                        {/*</div>*/}
                        {/*<ShowComment/>*/}
                    </div>
                </div>
            );
            return Content();
        };


        const FunctionMeasuring = (Item_病歷項: any) => {
            if (!Item_病歷項.__測量) {
                return <div>ERR:{Item_病歷項.病歷項No}-測量</div>;
            }

            let Content: any = () => {
                return <div className="mr_card f_blue">
                    <div className="titlebar">
                        <h4>檢測及測量</h4>
                        <div className="infobar">
                            <div
                                className="times">{Item_病歷項.CreatedAt ? moment(Item_病歷項.CreatedAt).format('YYYY-MM-DD hh:mm') : '-'}</div>
                            <div className="peo">
                                {Item_病歷項.__醫生_建檔醫師 ? <React.Fragment>
                                    <div className="avatertext">{Item_病歷項.__醫生_建檔醫師.名稱?.charAt(0)}</div>
                                    <div className="namecode">{Item_病歷項.__醫生_建檔醫師.名稱} 醫師</div>
                                </React.Fragment> : <React.Fragment>
                                    <div className="namecode">沒有建檔醫生</div>
                                </React.Fragment>}
                            </div>
                        </div>
                        <div className="func">
                            <Popconfirm
                                title="刪除整張卡片後，所有卡片內的動作項目
                            將一併清除，是否確認刪除? "
                                onConfirm={() => {
                                    handleSubmit_delete(null, {病歷項No: Item_病歷項.病歷項No})
                                }}
                                onCancel={cancel}
                                okText="刪除"
                                cancelText="取消"
                                okButtonProps={{danger: true}}
                            >
                                <Tooltip title="刪除" placement="right">
                                    <Button icon={<DeleteFilled/>} type="link"/>
                                </Tooltip>
                            </Popconfirm>
                        </div>
                    </div>
                    <div className="cardcontent">
                        <div className='note_serial'>
                            {Item_病歷項.__測量.map((Item_測量) => {
                                return <div className="note_box" key={Item_測量.測量No}>
                                    <div className="item">
                                        <div className="type">{Item_測量.大分類}</div>
                                        <div
                                            className="times">{Item_測量.CreatedAt ? moment(Item_測量.CreatedAt).format('YYYY-MM-DD hh:mm') : '-'}</div>
                                        <div className="content">{Item_測量.紀錄數據}</div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                    <div className="cardfooter">
                        <Partial_inHospital__Pane_MsgBoard
                            key={Item_病歷項.病歷項No}
                            //PrefixTarget={`Partial_inHospital__Pane_MsgBoard`}
                            pageDataManager={pageDataManager}
                            sessionManager={sessionManager}
                            navigate={navigate}

                            viewModel={{
                                refModal: null,
                                留言板No: Item_病歷項.留言板No_住院留言板
                            }}
                        />
                    </div>
                </div>;
            }
            return Content();
        };

        const FunctionPrescribe = (Item_病歷項: any) => {
            if (!Item_病歷項.__用藥) {
                return <div>ERR:{Item_病歷項.病歷項No}-用藥</div>;
            }

            let Content: any = () => {
                return <div className="mr_card f_blue">
                    <div className="titlebar">
                        <h4>用藥</h4>
                        <div className="infobar">
                            <div
                                className="times">{Item_病歷項.CreatedAt ? moment(Item_病歷項.CreatedAt).format('YYYY-MM-DD hh:mm') : '-'}</div>
                            <div className="peo">
                                {Item_病歷項.__醫生_建檔醫師 ? <React.Fragment>
                                    <div className="avatertext">{Item_病歷項.__醫生_建檔醫師.名稱?.charAt(0)}</div>
                                    <div className="namecode">{Item_病歷項.__醫生_建檔醫師.名稱} 醫師</div>
                                </React.Fragment> : <React.Fragment>
                                    <div className="namecode">沒有建檔醫生</div>
                                </React.Fragment>}
                            </div>
                        </div>
                        <div className="func">
                            <Popconfirm
                                title="刪除整張卡片後，所有卡片內的動作項目
                            將一併清除，是否確認刪除? "
                                onConfirm={() => {
                                    handleSubmit_delete(null, {病歷項No: Item_病歷項.病歷項No})
                                }}
                                onCancel={cancel}
                                okText="刪除"
                                cancelText="取消"
                                okButtonProps={{danger: true}}
                            >
                                <Tooltip title="刪除" placement="right">
                                    <Button icon={<DeleteFilled/>} type="link"/>
                                </Tooltip>
                            </Popconfirm>
                        </div>
                    </div>
                    <div className="cardcontent">
                        <div className='note_serial'>
                            {Item_病歷項.__用藥.map((Item_用藥) => {
                                return <div className="note_box" key={Item_用藥.用藥No}>
                                    <div className="item">
                                        <div className="type">{Item_用藥.大分類}</div>
                                        <div
                                            className="times">{Item_用藥.CreatedAt ? moment(Item_用藥.CreatedAt).format('YYYY-MM-DD hh:mm') : '-'}</div>
                                    </div>
                                    <div className="plus_con">
                                        <div className="title">{Item_用藥.品名} x {Item_用藥.份量} {Item_用藥.用量}</div>
                                        <div className="disc"></div>
                                        <div className="note">
                                            {Item_用藥.藥劑處置}
                                        </div>
                                    </div>
                                </div>;
                            })}
                        </div>
                    </div>
                    <div className="cardfooter">
                        <Partial_inHospital__Pane_MsgBoard
                            key={Item_病歷項.病歷項No}
                            //PrefixTarget={`Partial_inHospital__Pane_MsgBoard`}
                            pageDataManager={pageDataManager}
                            sessionManager={sessionManager}
                            navigate={navigate}

                            viewModel={{
                                refModal: null,
                                留言板No: Item_病歷項.留言板No_住院留言板
                            }}
                        />
                    </div>
                </div>;
            }

            return Content();
        }

        const FunctionCashier = (Item_病歷項: any) => {
            // if (!Item_病歷項.__臨床症狀) {
            //     return <div>ERR:{Item_病歷項.病歷項No}-臨床症狀</div>;
            // }

            let Content: any = () => (
                <div className="mr_card f_purple">
                    <div className="titlebar">
                        <h4>收費出納</h4>
                        <div className="infobar">
                            <div
                                className="times">{Item_病歷項.CreatedAt ? moment(Item_病歷項.CreatedAt).format('YYYY-MM-DD hh:mm') : '-'}</div>
                            <div className="peo">
                                {Item_病歷項.__醫生_建檔醫師 ? <React.Fragment>
                                    <div className="avatertext">{Item_病歷項.__醫生_建檔醫師.名稱?.charAt(0)}</div>
                                    <div className="namecode">{Item_病歷項.__醫生_建檔醫師.名稱} 醫師</div>
                                </React.Fragment> : <React.Fragment>
                                    <div className="namecode">沒有建檔醫生</div>
                                </React.Fragment>}
                            </div>
                        </div>
                        <div className="func">
                            <Popconfirm
                                title="刪除整張卡片後，所有卡片內的動作項目
                            將一併清除，是否確認刪除? "
                                onConfirm={() => {
                                    handleSubmit_delete(null, {病歷項No: Item_病歷項.病歷項No})
                                }}
                                onCancel={cancel}
                                okText="刪除"
                                cancelText="取消"
                                okButtonProps={{danger: true}}
                            >
                                <Tooltip title="刪除" placement="right">
                                    <Button icon={<DeleteFilled/>} type="link"/>
                                </Tooltip>
                            </Popconfirm>
                        </div>
                    </div>
                    <div className="cardcontent">
                        <div className='note_serial'>
                            <div className="note_box">
                                <div className="item">
                                    <div className="type">批價</div>
                                    <div
                                        className="times">{Item_病歷項.CreatedAt ? moment(Item_病歷項.CreatedAt).format('YYYY-MM-DD hh:mm') : '-'}</div>
                                </div>
                                <div className="plus_con">
                                    <div className="title">建立批價單，未結帳金額 ${_.util.commaSep(Item_病歷項.消費總額 ?? 0)} 元整</div>
                                </div>

                            </div>
                            {/*<div className="note_box">*/}
                            {/*    <div className="item">*/}
                            {/*        <div className="type">結帳狀態</div>*/}
                            {/*        <div*/}
                            {/*            className="times">{Item_病歷項.CreatedAt ? moment(Item_病歷項.CreatedAt).format('YYYY-MM-DD hh:mm') : '-'}</div>*/}
                            {/*    </div>*/}
                            {/*    <div className="plus_con">*/}
                            {/*        <div className="title">(未連結掛號系統)</div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className="cardfooter">
                        <Partial_inHospital__Pane_MsgBoard
                            key={Item_病歷項.病歷項No}
                            //PrefixTarget={`Partial_inHospital__Pane_MsgBoard`}
                            pageDataManager={pageDataManager}
                            sessionManager={sessionManager}
                            navigate={navigate}

                            viewModel={{
                                refModal: null,
                                留言板No: Item_病歷項.留言板No_住院留言板
                            }}
                        />
                        {/*<div className="extra_note">*/}
                        {/*    <ul>*/}
                        {/*        <li>*/}
                        {/*            <div className="main_th"><span>陳大發</span> 2021-10-21 14:32</div>*/}
                        {/*            <p>額外註記額外註記額外註記，額外註記額外註記額外註記，額外註記額外註記</p>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <div className="main_th"><span>王清泉</span> 2021-10-22 11:17</div>*/}
                        {/*            <p>額外註記額外註記額外註記，額外註記額外註記額外註記，額外註記額外註記額外註記額外註記額外註記，額外註記額外註記額外註記，額外註記額外註記</p>*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}
                        {/*</div>*/}
                        {/*<ShowComment/>*/}
                    </div>
                </div>

            );
            return Content();
        };

        const FunctionClinical = (Item_病歷項: any) => {
            if (!Item_病歷項.__臨床診斷) {
                return <div>ERR:{Item_病歷項.病歷項No}-臨床診斷</div>;
            }

            let Content: any = () => {
                return <div className="mr_card f_blue">
                    <div className="titlebar">
                        <h4>臨床診斷</h4>
                        <div className="infobar">
                            <div
                                className="times">{Item_病歷項.CreatedAt ? moment(Item_病歷項.CreatedAt).format('YYYY-MM-DD hh:mm') : '-'}</div>
                            <div className="peo">
                                {Item_病歷項.__醫生_建檔醫師 ? <React.Fragment>
                                    <div className="avatertext">{Item_病歷項.__醫生_建檔醫師.名稱?.charAt(0)}</div>
                                    <div className="namecode">{Item_病歷項.__醫生_建檔醫師.名稱} 醫師</div>
                                </React.Fragment> : <React.Fragment>
                                    <div className="namecode">沒有建檔醫生</div>
                                </React.Fragment>}
                            </div>
                        </div>
                        <div className="func">
                            <Popconfirm
                                title="刪除整張卡片後，所有卡片內的動作項目
                            將一併清除，是否確認刪除? "
                                onConfirm={() => {
                                    handleSubmit_delete(null, {病歷項No: Item_病歷項.病歷項No})
                                }}
                                onCancel={cancel}
                                okText="刪除"
                                cancelText="取消"
                                okButtonProps={{danger: true}}
                            >
                                <Tooltip title="刪除" placement="right">
                                    <Button icon={<DeleteFilled/>} type="link"/>
                                </Tooltip>
                            </Popconfirm>
                        </div>
                    </div>
                    <div className="cardcontent">
                        <div className='note_serial'>
                            {Item_病歷項.__臨床診斷.map((Item_臨床診斷) => {
                                return <div className="note_box" key={Item_臨床診斷.臨床診斷No}>
                                    <div className="item">
                                        <div className="type">{Item_臨床診斷.症狀分類組}</div>
                                        <div className="content">{Item_臨床診斷.症狀中文} / {Item_臨床診斷.症狀英文}</div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                    <div className="cardfooter">
                        <Partial_inHospital__Pane_MsgBoard
                            key={Item_病歷項.病歷項No}
                            //PrefixTarget={`Partial_inHospital__Pane_MsgBoard`}
                            pageDataManager={pageDataManager}
                            sessionManager={sessionManager}
                            navigate={navigate}

                            viewModel={{
                                refModal: null,
                                留言板No: Item_病歷項.留言板No_住院留言板
                            }}
                        />
                        {/*<div className="extra_note">*/}
                        {/*    <ul>*/}
                        {/*        <li>*/}
                        {/*            <div className="main_th"><span>陳大發</span> 2021-10-21 14:32</div>*/}
                        {/*            <p>額外註記額外註記額外註記，額外註記額外註記額外註記，額外註記額外註記</p>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <div className="main_th"><span>王清泉</span> 2021-10-22 11:17</div>*/}
                        {/*            <p>額外註記額外註記額外註記，額外註記額外註記額外註記，額外註記額外註記額外註記額外註記額外註記，額外註記額外註記額外註記，額外註記額外註記</p>*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}
                        {/*</div>*/}
                        {/*<ShowComment/>*/}
                    </div>
                </div>

                return <div className="mr_card f_blue">
                    <div className="titlebar">
                        <h4>處理</h4>
                        <div className="infobar">
                            <div
                                className="times">{Item_病歷項.CreatedAt ? moment(Item_病歷項.CreatedAt).format('YYYY-MM-DD hh:mm') : '-'}</div>
                            {/*<div className="peo">*/}
                            {/*    {Item_病歷項.__醫生_建檔醫師 ? <React.Fragment>*/}
                            {/*        <div className="avatertext">{Item_病歷項.__醫生_建檔醫師.名稱?.charAt(0)}</div>*/}
                            {/*        <div className="namecode">{Item_病歷項.__醫生_建檔醫師.名稱} 醫師</div>*/}
                            {/*    </React.Fragment> : <React.Fragment>*/}
                            {/*        <div className="namecode">沒有建檔醫生</div>*/}
                            {/*    </React.Fragment>}*/}
                            {/*</div>*/}
                        </div>
                        <div className="func">
                            <Popconfirm
                                title="刪除整張卡片後，所有卡片內的動作項目
                            將一併清除，是否確認刪除? "
                                onConfirm={() => {
                                    handleSubmit_delete(null, {病歷項No: Item_病歷項.病歷項No})
                                }}
                                onCancel={cancel}
                                okText="刪除"
                                cancelText="取消"
                                okButtonProps={{danger: true}}
                            >
                                <Tooltip title="刪除" placement="right">
                                    <Button icon={<DeleteFilled/>} type="link"/>
                                </Tooltip>
                            </Popconfirm>
                        </div>
                    </div>
                    <div className="cardcontent">
                        <div className='note_serial'>

                        </div>
                    </div>
                    <div className="cardfooter">
                        <Partial_inHospital__Pane_MsgBoard
                            key={Item_病歷項.病歷項No}
                            //PrefixTarget={`Partial_inHospital__Pane_MsgBoard`}
                            pageDataManager={pageDataManager}
                            sessionManager={sessionManager}
                            navigate={navigate}

                            viewModel={{
                                refModal: null,
                                留言板No: Item_病歷項.留言板No_住院留言板
                            }}
                        />
                        {/*<div className="extra_note">*/}
                        {/*    <ul>*/}
                        {/*        <li>*/}
                        {/*            <div className="main_th"><span>陳大發</span> 2021-10-21 14:32</div>*/}
                        {/*            <p>額外註記額外註記額外註記，額外註記額外註記額外註記，額外註記額外註記</p>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <div className="main_th"><span>王清泉</span> 2021-10-22 11:17</div>*/}
                        {/*            <p>額外註記額外註記額外註記，額外註記額外註記額外註記，額外註記額外註記額外註記額外註記額外註記，額外註記額外註記額外註記，額外註記額外註記</p>*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}
                        {/*</div>*/}
                        {/*<ShowComment/>*/}
                    </div>
                </div>;
            }
            return Content();
        };

        const FunctionSymptom = (Item_病歷項: any) => {
            if (!Item_病歷項.__臨床症狀) {
                return <div>ERR:{Item_病歷項.病歷項No}-臨床症狀</div>;
            }

            let Content: any = () => {
                return <div className="mr_card f_blue">
                    <div className="titlebar">
                        <h4>臨床症狀</h4>
                        <div className="infobar">
                            <div
                                className="times">{Item_病歷項.CreatedAt ? moment(Item_病歷項.CreatedAt).format('YYYY-MM-DD hh:mm') : '-'}</div>
                            <div className="peo">
                                {Item_病歷項.__醫生_建檔醫師 ? <React.Fragment>
                                    <div className="avatertext">{Item_病歷項.__醫生_建檔醫師.名稱?.charAt(0)}</div>
                                    <div className="namecode">{Item_病歷項.__醫生_建檔醫師.名稱} 醫師</div>
                                </React.Fragment> : <React.Fragment>
                                    <div className="namecode">沒有建檔醫生</div>
                                </React.Fragment>}
                            </div>
                        </div>
                        <div className="func">
                            <Popconfirm
                                title="刪除整張卡片後，所有卡片內的動作項目
                            將一併清除，是否確認刪除? "
                                onConfirm={() => {
                                    handleSubmit_delete(null, {病歷項No: Item_病歷項.病歷項No})
                                }}
                                onCancel={cancel}
                                okText="刪除"
                                cancelText="取消"
                                okButtonProps={{danger: true}}
                            >
                                <Tooltip title="刪除" placement="right">
                                    <Button icon={<DeleteFilled/>} type="link"/>
                                </Tooltip>
                            </Popconfirm>
                        </div>
                    </div>
                    <div className="cardcontent">
                        <div className='note_serial'>
                            {Item_病歷項.__臨床症狀.map((Item_臨床症狀) => {
                                return <div className="note_box" key={Item_臨床症狀.臨床症狀No}>
                                    <div className="item">
                                        <div className="type">{Item_臨床症狀.症狀分類組}</div>
                                        <div className="content">{Item_臨床症狀.症狀中文} / {Item_臨床症狀.症狀英文}</div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                    <div className="cardfooter">
                        <Partial_inHospital__Pane_MsgBoard
                            key={Item_病歷項.病歷項No}
                            //PrefixTarget={`Partial_inHospital__Pane_MsgBoard`}
                            pageDataManager={pageDataManager}
                            sessionManager={sessionManager}
                            navigate={navigate}

                            viewModel={{
                                refModal: null,
                                留言板No: Item_病歷項.留言板No_住院留言板
                            }}
                        />
                        {/*<div className="extra_note">*/}
                        {/*    <ul>*/}
                        {/*        <li>*/}
                        {/*            <div className="main_th"><span>陳大發</span> 2021-10-21 14:32</div>*/}
                        {/*            <p>額外註記額外註記額外註記，額外註記額外註記額外註記，額外註記額外註記</p>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <div className="main_th"><span>王清泉</span> 2021-10-22 11:17</div>*/}
                        {/*            <p>額外註記額外註記額外註記，額外註記額外註記額外註記，額外註記額外註記額外註記額外註記額外註記，額外註記額外註記額外註記，額外註記額外註記</p>*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}
                        {/*</div>*/}
                        {/*<ShowComment/>*/}
                    </div>
                </div>;
            }
            return Content();
        };

        const FunctionDealwith = (Item_病歷項: any) => {
            if (!Item_病歷項.__處理) {
                return <div>ERR:{Item_病歷項.病歷項No}-處理</div>;
            }

            let Content: any = () => {
                return <div className="mr_card f_blue">
                    <div className="titlebar">
                        <h4>處理</h4>
                        <div className="infobar">
                            <div
                                className="times">{Item_病歷項.CreatedAt ? moment(Item_病歷項.CreatedAt).format('YYYY-MM-DD hh:mm') : '-'}</div>
                            <div className="peo">
                                {Item_病歷項.__醫生_建檔醫師 ? <React.Fragment>
                                    <div className="avatertext">{Item_病歷項.__醫生_建檔醫師.名稱?.charAt(0)}</div>
                                    <div className="namecode">{Item_病歷項.__醫生_建檔醫師.名稱} 醫師</div>
                                </React.Fragment> : <React.Fragment>
                                    <div className="namecode">沒有建檔醫生</div>
                                </React.Fragment>}
                            </div>
                        </div>
                        <div className="func">
                            <Popconfirm
                                title="刪除整張卡片後，所有卡片內的動作項目
                            將一併清除，是否確認刪除? "
                                onConfirm={() => {
                                    handleSubmit_delete(null, {病歷項No: Item_病歷項.病歷項No})
                                }}
                                onCancel={cancel}
                                okText="刪除"
                                cancelText="取消"
                                okButtonProps={{danger: true}}
                            >
                                <Tooltip title="刪除" placement="right">
                                    <Button icon={<DeleteFilled/>} type="link"/>
                                </Tooltip>
                            </Popconfirm>
                        </div>
                    </div>
                    <div className="cardcontent">
                        <div className='note_serial'>
                            {Item_病歷項.__處理.map((Item_處理) => {
                                return <div className="note_box" key={Item_處理.處理No}>
                                    <div className="item">
                                        <div className="type">{Item_處理.大分類}</div>
                                        <div className="content">{Item_處理.處理方法_中} / {Item_處理.處理方法_英}</div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                    <div className="cardfooter">
                        <Partial_inHospital__Pane_MsgBoard
                            key={Item_病歷項.病歷項No}
                            //PrefixTarget={`Partial_inHospital__Pane_MsgBoard`}
                            pageDataManager={pageDataManager}
                            sessionManager={sessionManager}
                            navigate={navigate}

                            viewModel={{
                                refModal: null,
                                留言板No: Item_病歷項.留言板No_住院留言板
                            }}
                        />
                        {/*<div className="extra_note">*/}
                        {/*    <ul>*/}
                        {/*        <li>*/}
                        {/*            <div className="main_th"><span>陳大發</span> 2021-10-21 14:32</div>*/}
                        {/*            <p>額外註記額外註記額外註記，額外註記額外註記額外註記，額外註記額外註記</p>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <div className="main_th"><span>王清泉</span> 2021-10-22 11:17</div>*/}
                        {/*            <p>額外註記額外註記額外註記，額外註記額外註記額外註記，額外註記額外註記額外註記額外註記額外註記，額外註記額外註記額外註記，額外註記額外註記</p>*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}
                        {/*</div>*/}
                        {/*<ShowComment/>*/}
                    </div>
                </div>;
            }
            return Content();
        };


        const notemenu = (
            <Menu>
                <Menu.Item key="0">
                    <a>醫療備註</a>
                </Menu.Item>
                <Menu.Divider/>
                <Menu.Item key="1">
                    <a href="https://www.aliyun.com">初診紀錄過往病史</a>
                </Menu.Item>

            </Menu>
        );


        const PartLine = (props: any) => (
            <div className="partition">
                <div className="info">
                    <h4>{props.label}</h4>
                    {/*<div className="infocont">11:30:00 / 陳大發醫師</div>*/}
                </div>
            </div>
        );


        const dateFormat = 'YYYY/MM/DD';

        if (!PassPrefixTargetLoaded) {
            if (RegionInitialLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <Spin indicator={
                            <LoadingOutlined style={{fontSize: 24}} spin/>
                        }/>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        if (loadingManager.getIsLoading()) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <Spin indicator={
                            <LoadingOutlined style={{fontSize: 24}} spin/>
                        }/>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        let isFirst: boolean = true;
        let last掛號No: any = null;

        return (
            <div className="partial_tab_mr_main">
                <div className="wrapper gridlayerout">



                    {/* 病歷表 */}
                    <div className="infoboxoutside">
                        <div className="infobox sg_page">
                            {/*<div className="titlebar">*/}
                            {/*    <h3>2020-01-02(三)</h3>*/}
                            {/*    <div className="barcontent">*/}
                            {/*        <div className="plugtext"></div>*/}
                            {/*    </div>*/}
                            {/*    <div className="func">*/}
                            {/*        <DatePicker defaultValue={moment('2020/01/02', dateFormat)} format={dateFormat}/>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="boxcontent">
                                {List_病歷項.length === 0 && <React.Fragment>
                                    <div className="nocase">
                                        <p className="nocase-title">目前病歷處置為空</p>
                                        <p className="nocase-description">欲增加資料，請開始看診</p>
                                    </div>
                                </React.Fragment>}


                                {List_病歷項.map((Item_病歷項) => {
                                    let sepHolder: any = null;
                                    if (isFirst) {
                                        isFirst = false;
                                        last掛號No = Item_病歷項.掛號No;

                                        if (last掛號No === viewModel.掛號No) {
                                            sepHolder = <React.Fragment>
                                                <PartLine label="目前掛號"/>
                                            </React.Fragment>;
                                        } else {
                                            sepHolder = <React.Fragment>
                                                <PartLine label="上次掛號"/>
                                            </React.Fragment>;
                                        }
                                    } else {
                                        if (last掛號No !== Item_病歷項.掛號No) {
                                            last掛號No = Item_病歷項.掛號No;
                                            sepHolder = <React.Fragment>
                                                <PartLine label="掛號分界線"/>
                                            </React.Fragment>;
                                        } else {
                                            sepHolder = null;
                                        }
                                    }

                                    if (Item_病歷項.項目 === '處理') {
                                        return <React.Fragment key={Item_病歷項.病歷項No}>
                                            {sepHolder}
                                            {FunctionDealwith(Item_病歷項)}
                                        </React.Fragment>;
                                    } else if (Item_病歷項.項目 === '測量') {
                                        return <React.Fragment key={Item_病歷項.病歷項No}>
                                            {sepHolder}
                                            {FunctionMeasuring(Item_病歷項)}
                                        </React.Fragment>;
                                    } else if (Item_病歷項.項目 === '用藥') {
                                        return <React.Fragment key={Item_病歷項.病歷項No}>
                                            {sepHolder}
                                            {FunctionPrescribe(Item_病歷項)}
                                        </React.Fragment>;
                                    } else if (Item_病歷項.項目 === '備註') {
                                        return <React.Fragment key={Item_病歷項.病歷項No}>
                                            {sepHolder}
                                            {FunctionNote(Item_病歷項)}
                                        </React.Fragment>;
                                    } else if (Item_病歷項.項目 === '臨床診斷') {
                                        return <React.Fragment key={Item_病歷項.病歷項No}>
                                            {sepHolder}
                                            {FunctionClinical(Item_病歷項)}
                                        </React.Fragment>;
                                    } else if (Item_病歷項.項目 === '臨床症狀') {
                                        return <React.Fragment key={Item_病歷項.病歷項No}>
                                            {sepHolder}
                                            {FunctionSymptom(Item_病歷項)}
                                        </React.Fragment>;
                                    } else if (Item_病歷項.項目 === '住院') {
                                        return <React.Fragment key={Item_病歷項.病歷項No}>
                                            {sepHolder}
                                            {FunctionHospitalized(Item_病歷項)}
                                        </React.Fragment>;
                                    } else if (Item_病歷項.項目 === '批價') {
                                        return <React.Fragment key={Item_病歷項.病歷項No}>
                                            {sepHolder}
                                            {FunctionCashier(Item_病歷項)}
                                        </React.Fragment>;
                                    } else {
                                        return <React.Fragment key={Item_病歷項.病歷項No}>
                                            {sepHolder}
                                            <div>ERR: 無法配對的病歷項No={Item_病歷項.病歷項No}</div>
                                        </React.Fragment>;
                                    }
                                })}

                                {/*<PartLine/>*/}
                                {/*<PartLine/>*/}
                            </div>
                            <div className="boxfooter"></div>
                        </div>
                    </div>


                    {/* 功能列 */}
                    <div className="infoboxoutside stickybar">
                        <div className="infobox">
                            <div className="titlebar">
                                <h3>看診功能</h3>
                                <div className="barcontent"></div>
                                <div className="func"></div>
                            </div>
                            <div className="boxcontent">
                                <ul className="funcbtn mainfunc">
                                    {/* <li><div className="blue_btn" onClick={createModal_RH}><Box_MRbtn iconlink={iconPassport} className='blue_btn' name="過往紀錄" /></div></li> */}
                                    <li>
                                        <div className={'' + (state.掛號No ? ' blue_btn' : ' disabled')}
                                             onClick={createModalNote}><Box_MRbtn
                                            iconlink={iconPaw} className='blue_btn' name="備註"/></div>
                                    </li>
                                    <li>
                                        <div className={'' + (state.掛號No ? ' blue_btn' : ' disabled')}
                                             onClick={createModalSymptom}><Box_MRbtn
                                            iconlink={iconStethoscope} className='disabled' name="臨床症狀"/></div>
                                    </li>
                                    <li>
                                        <div className={'' + (state.掛號No ? ' blue_btn' : ' disabled')}
                                             onClick={createModal_Measuring}><Box_MRbtn
                                            iconlink={iconMicroscope} className='blue_btn' name="檢查及測量"/></div>
                                    </li>
                                    <li>
                                        <div className={'' + (state.掛號No ? ' blue_btn' : ' disabled')}
                                             onClick={createModal_CD}><Box_MRbtn
                                            iconlink={iconMedicalhistory} className='blue_btn' name="臨床診斷"/></div>
                                    </li>
                                    <li>
                                        <div className={'' + (state.掛號No ? ' blue_btn' : ' disabled')}
                                             onClick={createModal_Dealwith}><Box_MRbtn
                                            iconlink={iconElizabethan_collar} className='blue_btn' name="處理"/></div>
                                    </li>
                                    <li>
                                        <div className={'' + (state.掛號No ? ' blue_btn' : ' disabled')}
                                             onClick={createModalPrescribe}><Box_MRbtn
                                            iconlink={iconPills} className='blue_btn' name="用藥"/></div>
                                    </li>
                                    {/* <li><div className="blue_btn"><Box_MRbtn iconlink={iconSyringe} className='blue_btn' name="預防" /></div></li> */}
                                </ul>

                                {/*<div className="shortbar">*/}
                                {/*    <h5>飼主通知</h5>*/}
                                {/*</div>*/}
                                {/*<ul className="funcbtn notify">*/}

                                {/*    /!* <li><div className="yellow_btn"><Box_MRbtn iconlink={iconGridicons_print} className='blue_btn' name="列印" /></div></li> *!/*/}
                                {/*    <li>*/}
                                {/*        <div className="yellow_btn"><Box_MRbtn iconlink={iconCalendar}*/}
                                {/*                                               className='blue_btn' name="預約回診"/></div>*/}
                                {/*    </li>*/}
                                {/*</ul>*/}


                                <div className="shortbar">
                                    <h5>轉移部門</h5>
                                </div>
                                <ul className="funcbtn store">
                                    <li>
                                        <div className={'' + (state.掛號No ? ' green_btn' : ' disabled')}
                                             onClick={createModal_Module_AddInHospital}>
                                            <Box_MRbtn iconlink={iconDropper}
                                                       className={'' + (state.掛號No ? ' blue_btn' : ' disabled')}
                                                       name="住院"/>
                                        </div>
                                    </li>
                                    {/*<li>*/}
                                    {/*    <div className={'' + (state.掛號No ? ' green_btn' : ' disabled')} >*/}
                                    {/*        <Box_MRbtn iconlink={iconGrooming} className={'' + (state.掛號No ? ' blue_btn' : ' disabled')} name="美容"/>*/}
                                    {/*    </div>*/}
                                    {/*</li>*/}
                                    {/* <li><div className="green_btn"><Box_MRbtn iconlink={iconBandage} className='blue_btn' name="復健" /></div></li>
                                    <li><div className="green_btn"><Box_MRbtn iconlink={iconLamp} className='blue_btn' name="留觀" /></div></li> */}
                                </ul>

                                <div className="shortbar">
                                    <h5>會計相關</h5>
                                </div>
                                <ul className="funcbtn rate">
                                    <li>
                                        <div className={'' + (state.掛號No ? ' purple_btn' : ' disabled')}
                                             onClick={createModalCharge}>
                                            <Box_MRbtn iconlink={iconMoneybag} name="收費出納"/>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div className="boxfooter">

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        );
    };

    return <React.Fragment>
        {Body()}
    </React.Fragment>;

}
Partial_MedicalRecord__Tab_Treatment.displayName = 'Partial_MedicalRecord__Tab_Treatment';
