import {Dispatch, MutableRefObject, SetStateAction, useRef, useState} from 'react';

type Props = {
    parentState?: any,
};
export default function UseCompState(props: Props): any {
    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const refSelfInstance = useRef<any | null>(null);
    if (refSelfInstance.current !== null) {
        return refSelfInstance.current;
    }

    //const shouldReRenderOnLoading = props.shouldReRenderOnLoading !== undefined ? props.shouldReRenderOnLoading : true;
    const parentState = props.parentState || null;

    let state: any = null;
    function initState() {
        state = {};
        state.parentState = parentState;
        state.forceRender = forceRender;
    }

    function getState() {
        if (state === null) {
            initState();
        }
        return state;
    }

    return refSelfInstance.current = getState();
}

