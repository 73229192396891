import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {Form as NForm, Popover, Button, Modal, message, Radio, Input, Space, Select, Row, Col, DatePicker} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";

import {LoadingOutlined, LockOutlined, PlusOutlined, FileTextOutlined, UserOutlined} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_Appointment__Modal_AddPet from "./Partial_Appointment__Modal_AddPet";
import Partial_MedicalRecord__Modal_IHRecord from "./Partial_MedicalRecord__Modal_IHRecord";

var moment = require('moment');
const {Option} = Select;

type ViewModelProps = {
    refModal: any,

    病歷No: any,
    掛號No: any,

    OnDid?: any;
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,

    viewModel: ViewModelProps,
};
export default function Partial_MedicalRecord__Pane_TransferToHospital(props: Props) {
    const PrefixTarget: string = props.PrefixTarget || 'Partial_MedicalRecord__Pane_TransferToHospital';
    const pageDataManager: PageDataManager = props.pageDataManager;
    const sessionManager: SessionManager = props.sessionManager;
    const navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel = props.viewModel;

    const dateFormat = 'YYYY/MM/DD';

    const layout = {
        labelCol: {span: 7},
        wrapperCol: {span: 17},
    };
    const layout2 = {
        labelCol: {span: 4},
        wrapperCol: {span: 20},
    };

    //#region :Popup :createModalIHR
    const createModalIHR_refModal = useRef<any>(null);
    const createModalIHR = (e: any, {病歷No, tasks}: {
        病歷No: any,
        tasks?: string[]
    }) => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_MedicalRecord__Modal_IHRecord
                PrefixTarget={'Partial_MedicalRecord__Modal_IHRecord'}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModalIHR_refModal,

                    病歷No,
                }}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '80em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModalIHR_refModal.current = Modal.info(config);
    }
    //#endregion

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region No Cache
    useEffect(() => {
        return () => {
            pageDataManager.emptyZone(PrefixTarget);
            pageDataManager.apply();
        }
    }, []);
    //#endregion

    //#region Boot
    let _refDoLoadPartialData: any = useRef<{ alwaysLoad?: boolean }>(null);
    useEffect(() => {
        const _loadPartialData = async (): Promise<void> => {

            // VALIDATE INPUT
            // const p1 = viewModel.OnDid;
            // if (!p1) {
            //     alert('p1');
            //     return; //ERROR INPUTS
            // }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                //p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/partial_medicalrecord/pane_transfertohospital');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        let changeShard = {
                            [PrefixTarget]: {
                                ...payload,
                            }
                        }
                        pageDataManager.merge(changeShard);
                        pageDataManager.apply();

                        forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPartialData = async ({alwaysLoad = false}): Promise<void> => {
            // if (!pageDataManager.refPageData.current.Root) {
            //     return;
            // }

            /// do not dynamically load data if condition fits.
            if (!alwaysLoad) {
                if (pageDataManager.isZoneExist(PrefixTarget)) {
                    return;
                }
            }

            await _loadPartialData();
        }

        _refDoLoadPartialData.current = doLoadPartialData;
        _refDoLoadPartialData.current({});
    }, [pageDataManager.get(PrefixTarget)]);
    //#endregion

    //#region Set Body
    const isModalContained = () => {
        return !!viewModel.refModal?.current;
    }
    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };
    const Body = () => {

        const List_住院籠子 = pageDataManager.get(`${PrefixTarget}.List_住院籠子`);
        let caseOptions: any = [];
        {
            if (List_住院籠子) {
                caseOptions = List_住院籠子.filter((r) => {
                    return !r.掛號No
                }).map((r) => {
                    return {
                        key: r.住院籠子No,
                        value: r.住院籠子No,
                        label: `${r.區域籠號}`,
                    }
                });
                caseOptions.unshift({
                    key: '0',
                    value: '',
                    label: '請選擇',
                });
            }
        }


        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //


            // if (!values.區域籠號) {
            //     errors.住院區 = '↑ 欄位為必填';
            // } else {
            // }
            //
            // if (!values.住院日) {
            //     errors.住院日 = '↑ 欄位為必填';
            // } else {
            // }
            //
            // if (!values.預計出院日) {
            //     errors.預計出院日 = '↑ 欄位為必填';
            // } else {
            // }

            return errors;
        };

        const {TextArea} = Input;

        const Today = Date();

        //#region Handlers
        const handleSubmit_saveRecord = (e: any, v: any) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }

            //#region Handle SaveUserInfo
            {
                // VALIDATE INPUT
                const p1 = v.病歷No;
                if (!p1) {
                    _._debug.error('p1');
                    return; //ERROR INPUTS
                }
                const p2 = v.Item_病歷項;
                if (!p2) {
                    _._debug.error('p2');
                    return; //ERROR INPUTS
                }
                const p3 = v.掛號No;
                if (!p3) {
                    alert('沒有掛號無法寫入')
                    _._debug.error('p3');
                    return; //ERROR INPUTS
                }
                const p4 = v.住院籠子No;
                if (!p4) {
                    alert('沒有掛號無法寫入')
                    _._debug.error('p3');
                    return; //ERROR INPUTS
                }

                let hide = message.loading({
                    type: 'loading',
                    className: 'myMessage',
                    content: <div className="myMessageBody">驗證中...</div>,
                    key: 'loading',
                    duration: 0
                });

                // WAITING SCREEN
                loadingManager.changeLoadingCount(+1);
                //StaticEvent.LoadingEvent.next(true);

                // POST
                let postData = {
                    p1,
                    p2,
                    p3,
                    p4,
                    timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
                };
                const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_medicalrecord/pane_transfertohospital/save');
                const post$ = Static.apiService.post(postUrl, postData);
                post$.subscribe(async (res: any) => {
                        hide();

                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);

                        let data = res.data;
                        let error = data.error || null;
                        let result = error == null;

                        if (result) { // SUCEESS
                            let payload = data.payload;
                            _._debug.warn(payload);

                            ///handleclose-template
                            message.success({content: '修改完成!', duration: 2});

                            if (isModalContained()) {
                                handleClose(null);
                            } else {
                                //_refDoLoadPartialData.current({alwaysLoad: true});
                            }

                            viewModel?.OnDid?.(payload);
                            //state.parentState?._refDoLoadPartialData?.current({alwaysLoad: true});

                            // Static.NavAwayToAppointment(navigate, {
                            //     s: '手機號碼',
                            //     q: payload.Item_飼主.手機,
                            // })(null);

                            // let carryData = _._u.update(payload,
                            //     {
                            //         $merge: {
                            //             Tokens: payload?.Tokens,
                            //         },
                            //         Item_飼主: {
                            //             $set: payload?.Item_飼主,
                            //         }
                            //     }
                            // );
                            // carryData.Item_飼主 = p1;
                            // if (onDone) {
                            //     const ret = onDone(carryData);
                            //     if (ret === 'demo') {
                            //         createModalPet(null, {
                            //             carryData,
                            //         })
                            //     }
                            // } else {
                            //     alert('onDone not defined');
                            // }

                            //handleClose(null)

                        } else {
                            // AUTH USER EXPIRED
                            if (error.code == 2) {
                                //alert('帳號被登出，請重新登入');
                                //window.location.href = '/login';
                                //this.props.history.push('/login')
                                await sessionManager.setLogout();
                                pageDataManager.clear();
                                pageDataManager.reloadAsync({alwaysLoad: true});
                                alert('時間逾時已被登出');
                                return;
                            }

                            _._debug.error(error);
                            alert(error.message);
                        }
                    }, async (err) => {
                        hide();

                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);
                        _._debug.warn(err);
                        alert('網路連線有問題');
                    }
                );
                return lastValueFrom(
                    post$
                );
            }
            //#endregion
        }
        //#endregion

        const genderoptions = [
            {label: '男性', value: 'Male'},
            {label: '女性', value: 'Female'},
        ];

        return (
            <Form
                onSubmit={(v) => handleSubmit_saveRecord(null, v)}
                initialValues={{
                    病歷No: viewModel.病歷No,
                    掛號No: viewModel.掛號No,
                    住院籠子No: null,
                    Item_病歷項: {},
                }}
                validate={handleValidate}
                render={({
                             submitError,
                             handleSubmit,
                             submitting,
                             pristine,
                             valid,
                             error,
                             values
                         }) => {

                    return <React.Fragment>
                        <div className="form_body col_3">
                            <form
                                onSubmit={handleSubmit}
                                {...layout}>
                                <Space className='s_right'>
                                    <Button onClick={(e) => {
                                        createModalIHR(e, {
                                            病歷No: viewModel.病歷No
                                        })
                                    }}><FileTextOutlined/>住院紀錄</Button>
                                </Space>

                                <Row>
                                    <Col span={12}>
                                        <Field parse={_.bs.identity} name="住院籠子No" validate={_.bs.Form.required}>
                                            {
                                                ({input, meta}) => {
                                                    return <React.Fragment>
                                                        <NForm.Item {...input} {...layout}
                                                                    name="cagearea"
                                                                    label="區域籠號"
                                                                    rules={[{required: true, message: '請選擇住院區'}]}
                                                        >
                                                            <Select {...input}
                                                                    style={{width: '100%'}} placeholder="請選擇區域籠號">
                                                                {caseOptions.map((it => {
                                                                    return <Option value={it.value}>{it.label}</Option>
                                                                }))}
                                                            </Select>
                                                        </NForm.Item>
                                                        {meta.touched && meta.error &&
                                                        <div className="error">
                                                            {meta.error}
                                                        </div>}
                                                    </React.Fragment>
                                                }
                                            }
                                        </Field>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Field parse={_.bs.identity} name="Item_病歷項.住院_住院開始時間" validate={_.bs.Form.required}>
                                            {
                                                ({input, meta}) => {
                                                    const datepickerProps: any = {};
                                                    if (input.value) {
                                                        //props.defaultValue =moment.utc(input.value).format('YYYY-MM-DD');
                                                        datepickerProps.defaultValue = moment(input.value);
                                                    } else {
                                                        datepickerProps.defaultValue = moment();
                                                    }
                                                    //input.onChange(datepickerProps.defaultValue);

                                                    return <React.Fragment>
                                                        <NForm.Item {...layout}
                                                                    name="住院日"
                                                                    label="住院日"
                                                                    rules={[{required: true, message: '請選擇住院日'}]}
                                                        >
                                                            <DatePicker {...input} {...datepickerProps} placeholder="選擇日期"
                                                                        format={dateFormat} {...datepickerProps}/>
                                                            {meta.touched && meta.error &&
                                                            <div className="error">
                                                                {meta.error}
                                                            </div>}

                                                        </NForm.Item>
                                                    </React.Fragment>;
                                                }
                                            }
                                        </Field></Col>
                                    <Col span={12}>
                                        <Field parse={_.bs.identity} name="Item_病歷項.住院_住院預計結束時間" validate={_.bs.Form.required}>
                                            {
                                                ({input, meta}) => {
                                                    const datepickerProps: any = {};
                                                    if (input.value) {
                                                        //props.defaultValue =moment.utc(input.value).format('YYYY-MM-DD');
                                                        datepickerProps.defaultValue = moment(input.value);
                                                    } else {
                                                        datepickerProps.defaultValue = moment();
                                                    }
                                                    //input.onChange(datepickerProps.defaultValue);

                                                    return <React.Fragment>
                                                        <NForm.Item {...layout}
                                                                    name="預計出院日"
                                                                    label="預計出院日"
                                                                    rules={[{required: true, message: '請選擇出院日'}]}

                                                        >
                                                            <DatePicker {...input} {...datepickerProps}  placeholder="選擇日期"
                                                                        format={dateFormat} {...datepickerProps}/>
                                                            {meta.touched && meta.error &&
                                                            <div className="error">
                                                                {meta.error}
                                                            </div>}
                                                        </NForm.Item>
                                                    </React.Fragment>;
                                                }
                                            }
                                        </Field>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Field parse={_.bs.identity} name="Item_病歷項.住院_住院原因">
                                            {
                                                ({input, meta}) => (
                                                    <React.Fragment>
                                                        <NForm.Item {...input} {...layout2}
                                                                    name="住院_住院原因"
                                                                    label="住院原因/執行項目"

                                                        >
                                                            <TextArea rows={3}/>
                                                        </NForm.Item>
                                                    </React.Fragment>
                                                )
                                            }
                                        </Field>
                                    </Col>
                                </Row>

                                <React.Fragment>
                                    <NForm.Item>
                                        <div className="form_footer">
                                            <Button onClick={handleClose}>取消</Button>
                                            <Button type="primary" htmlType="submit" size="large"
                                                    onClick={handleSubmit}>送出</Button>
                                        </div>
                                    </NForm.Item>
                                </React.Fragment>
                            </form>
                        </div>
                    </React.Fragment>;
                }}
            />
        );
    };
    //#endregion

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_MedicalRecord__Pane_TransferToHospital.displayName = 'Partial_MedicalRecord__Pane_TransferToHospital';
