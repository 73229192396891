import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {
    Form as NForm,
    Popover,
    Button,
    Modal,
    InputNumber,
    message,
    Tag,
    Table,
    Upload,
    Input,
    Select,
    Space,
    Tabs,
    Tooltip,
    Steps
} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';


import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";
import {LoadingOutlined, LockOutlined, EditOutlined, PlusOutlined, CloseOutlined} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_MedicalRecord__Modal_Prescribe from "./Partial_MedicalRecord__Modal_Prescribe";
import Partial_MedicalRecord__Modal_PrescribeAdd_Items from "./Partial_MedicalRecord__Modal_PrescribeAdd_Items";
import Partial_MedicalRecord__Modal_DealwithAdd_Items from "./Partial_MedicalRecord__Modal_DealwithAdd_Items";
import UseCompState from "../hooks/UseCompState";


var moment = require('moment');
const {Option} = Select;
const {Search} = Input;
const onSearch = value => console.log(value);
const {TabPane} = Tabs;
const {Step} = Steps;

type ViewModelProps = {
    refModal: any,

    OnDid?: any;
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any,

    viewModel: ViewModelProps,
};
export default function Partial_MedicalRecord__Modal_DealwithAdd(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_MedicalRecord__Modal_DealwithAdd';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel = props.viewModel;

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion


    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

    }
    //#endregion

    // //#region Boot
    // let _refDoLoadPartialData: any = useRef<{ alwaysLoad?: boolean }>(null);
    // useEffect(() => {
    //     const _loadPartialData = async (): Promise<void> => {
    //
    //         // VALIDATE INPUT
    //         //const id : any = this.props.match.params.id;
    //         // const p1 = viewModel.住院籠子No;
    //         // if (!p1) {
    //         //     return; //ERROR INPUTS
    //         // }
    //
    //         // WAITING SCREEN
    //         loadingManager.changeLoadingCount(+1);
    //         //StaticEvent.LoadingEvent.next(true);
    //
    //         // POST
    //         let postData = {
    //             //p1,
    //             timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
    //         };
    //         const postUrl = ApiService.urlMapping('~/pet_api/1/partial_inhospital/modal_listroom');
    //         const post$ = Static.apiService.post(postUrl, postData);
    //         post$.subscribe(async (res: any) => {
    //                 // CLOSE WAITING SCREEN
    //                 loadingManager.changeLoadingCount(-1);
    //                 //StaticEvent.LoadingEvent.next(false);
    //
    //                 let data = res.data;
    //                 let error = data.error || null;
    //                 let result = error == null;
    //
    //                 if (result) { // SUCEESS
    //                     let payload = data.payload;
    //                     _._debug.warn(payload);
    //
    //                     pageDataManager.emptyZone(PrefixTarget);
    //                     let changeShard = {
    //                         [PrefixTarget]: {
    //                             ...payload,
    //                         }
    //                     }
    //                     pageDataManager.merge(changeShard);
    //                     pageDataManager.apply();
    //
    //                     forceRender();
    //                 } else {
    //                     // AUTH USER EXPIRED T:1
    //                     if (error.code == 2) {
    //                         await sessionManager.setLogout();
    //                         Static.NavAwayToLogin(pageDataManager, navigate)(null);
    //                         return;
    //                     }
    //
    //                     _._debug.error(error);
    //                     alert(error.message);
    //                 }
    //             }, async (err) => {
    //
    //                 // CLOSE WAITING SCREEN
    //                 loadingManager.changeLoadingCount(-1);
    //                 //StaticEvent.LoadingEvent.next(false);
    //                 _._debug.warn(err);
    //                 alert('網路連線有問題');
    //             }
    //         );
    //         return lastValueFrom(
    //             post$
    //         );
    //     }
    //     const doLoadPartialData = async ({alwaysLoad = false}): Promise<void> => {
    //         // if (!pageDataManager.refPageData.current.Root) {
    //         //     return;
    //         // }
    //
    //         /// do not dynamically load data if condition fits.
    //         if (!alwaysLoad) {
    //             if (pageDataManager.isZoneExist(PrefixTarget)) {
    //                 return;
    //             }
    //         }
    //
    //         await _loadPartialData();
    //     }
    //
    //     _refDoLoadPartialData.current = doLoadPartialData;
    //     _refDoLoadPartialData.current({});
    // }, [pageDataManager.get(PrefixTarget)]);
    // //#endregion

    //#region Set Body
    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };
    const Body = () => {
        // const RegionEmptyShow = false;
        // const RegionLoadingShow = true;
        // const RegionInitialLoadingShow = false;
        //
        // const PassPageDataInitalized = pageDataManager.refPageData.current != null;
        // const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;
        //
        // let DataSource_處置 = state.DataSource_處置 = [];
        // let List_處置 = pageDataManager.get(`${PrefixTarget}.List_處置`);
        // if (List_處置 !== null) {
        //     let serialCounter = 0;
        //     DataSource_處置 = List_處置.map((Item_處置) => {
        //         serialCounter += 1;
        //
        //         if (serialCounter == 1) {
        //         }
        //
        //         return {
        //             entity: Item_處置,
        //             key: Item_處置.處理範本No,
        //
        //             大分類: Item_處置.大分類,
        //             處理方法_中: Item_處置.處理方法_中,
        //             處理方法_英: Item_處置.處理方法_英,
        //             分類組: Item_處置.分類組,
        //             學科組: Item_處置.學科組,
        //         }
        //     });
        //
        //     state.DataSource_處置 = DataSource_處置;
        // }

        //#region Handlers
        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            if (!values.username) {
                errors.username = '↑ 欄位為必填';
            } else {
            }

            if (!values.password) {
                errors.password = '↑ 欄位為必填';
            } else {
            }

            return errors;
        };

        const handleClose = (e) => {
            if (e) {
                e.preventDefault();
            }

            if (viewModel.refModal || viewModel.refModal.current) {
                viewModel.refModal.current.destroy();
            }
        };

        const mockTags = ['注射', '手術', '預防'];

        const PrescribeColumns = [
            {
                dataIndex: 'time',
                title: '時間',
            },
            {
                dataIndex: 'tag',
                title: '分類',

                render: (text, record) => <Tag>{text}</Tag>,
            },
            {
                dataIndex: 'brand',
                title: '品牌'
            },
            {
                dataIndex: 'drugName',
                title: '藥名',
                width: '20%',
            },
            {
                dataIndex: 'quantity',
                title: '份量',
                render: () => {
                    return <div>
                        <InputNumber min={1} max={10} defaultValue={1}/>
                    </div>
                },
            },
            {
                dataIndex: 'usageAmount',
                title: '用量',
            },
            {
                dataIndex: 'process',
                title: '藥劑處置'
            },
            {
                dataIndex: 'orderInfo',
                title: '醫囑',
                render: (text, record) => <a type="link">醫囑</a>,
            },
            {
                dataIndex: 'management',
                title: '管理',
                render: (text, record) => {

                    return <div>
                        <Space>
                            <Tooltip placement="top" title="編輯">
                                <Button icon={<EditOutlined/>}/>
                            </Tooltip>
                            <Tooltip placement="top" title="刪除">
                                <Button icon={<CloseOutlined/>}/>
                            </Tooltip>
                        </Space>
                    </div>
                },
            },
        ];

        const PrescribeDate = [
            {
                time: '15:17',
                tag: mockTags[0],
                brand: 'Linking Park',
                drugName: 'Follow your heart',
                usageAmount: '2ml',
                process: '打斷腿就站起來',
            },
            {
                time: '09:17',
                tag: mockTags[2],
                brand: '輝瑞',
                drugName: '要你命3000',
                usageAmount: '0.5ml',
                process: '千萬要打',
            }
        ];

        const PrescribeHistoryColumn = [
            {
                dataIndex: 'date',
                title: '日期',
            },

            {
                dataIndex: 'doctor',
                title: '開藥醫生'
            },
            {
                dataIndex: 'prescribeAmount',
                title: '開藥項目'
            },
            {
                dataIndex: 'stauts',
                title: '開單狀態',
            },
            {
                dataIndex: 'pharmacyStauts',
                title: '藥局回應',
            },
            {
                dataIndex: 'view',
                title: '查看',
                render: (text, record) => <a type="link">查看</a>
            }
        ];

        const PrescribeHistoryDate = [
            {
                date: '2021-07-21 15:17',
                doctor: '林隆璇 醫師',
                prescribeAmount: '1',
                stauts: '已送出',
                pharmacyStauts: '已配藥',
            },
            {
                date: '2021-08-01 09:30',
                doctor: '王帶鋼 醫師',
                prescribeAmount: '3',
                stauts: '已送出',
                pharmacyStauts: '藥局退回',
            },
            {
                date: '2021-08-02 12:42',
                doctor: '游登傑 醫師',
                prescribeAmount: '12',
                stauts: '未送出',
                pharmacyStauts: '未配藥',
            }
        ];
        //#endregion

        return (
            <div className="model_wrapper">
                <div className="modal_title">
                    <div className="title_info">
                        <h2>新增處理項目</h2>
                    </div>
                    <div className="info_bar"></div>
                    <div className="control_bar">
                        <Tooltip placement="top" title={'關閉'}>
                            <Button type="text" icon={<CloseOutlined/>} onClick={handleClose}/>
                        </Tooltip>
                    </div>
                </div>
                <div className="modal_content">
                    <div className="content_box cd_modal">
                        <Tabs defaultActiveKey="1" tabPosition="right">
                            <TabPane tab="手術與處理" key="1">
                                <Partial_MedicalRecord__Modal_DealwithAdd_Items
                                    //PrefixTarget={'Partial_MedicalRecord__Modal_DealwithAdd_Items'}
                                    pageDataManager={pageDataManager}
                                    sessionManager={sessionManager}
                                    navigate={navigate}

                                    viewModel={{
                                        refModal: null,
                                        大分類: '手術與處理',
                                        OnDid: (v)=> {
                                            v.selected.map((r)=> {
                                                if (!state.parentState.List_處理) {
                                                    state.parentState.List_處理 = [];
                                                }
                                                state.parentState.List_處理.unshift(r);
                                                state.parentState.forceRender();
                                            })

                                            handleClose(null);
                                        },
                                    }}
                                />
                            </TabPane>
                            <TabPane tab="電腦斷層" key="2">
                                <Partial_MedicalRecord__Modal_DealwithAdd_Items
                                    //PrefixTarget={'Partial_MedicalRecord__Modal_DealwithAdd_Items'}
                                    pageDataManager={pageDataManager}
                                    sessionManager={sessionManager}
                                    navigate={navigate}

                                    viewModel={{
                                        refModal: null,
                                        大分類: '電腦斷層',
                                        OnDid: (v)=> {
                                            v.selected.map((r)=> {
                                                if (!state.parentState.List_處理) {
                                                    state.parentState.List_處理 = [];
                                                }
                                                state.parentState.List_處理.unshift(r);
                                                state.parentState.forceRender();
                                            })

                                            handleClose(null);
                                        },
                                    }}
                                />
                            </TabPane>
                            <TabPane tab="病材檢驗" key="3">
                                <Partial_MedicalRecord__Modal_DealwithAdd_Items
                                    //PrefixTarget={'Partial_MedicalRecord__Modal_DealwithAdd_Items'}
                                    pageDataManager={pageDataManager}
                                    sessionManager={sessionManager}
                                    navigate={navigate}

                                    viewModel={{
                                        refModal: null,
                                        大分類: '病材檢驗',
                                        OnDid: (v)=> {
                                            v.selected.map((r)=> {
                                                if (!state.parentState.List_處理) {
                                                    state.parentState.List_處理 = [];
                                                }
                                                state.parentState.List_處理.unshift(r);
                                                state.parentState.forceRender();
                                            })

                                            handleClose(null);
                                        },
                                    }}
                                />
                            </TabPane>
                            <TabPane tab="遺體處理" key="4">
                                <Partial_MedicalRecord__Modal_DealwithAdd_Items
                                    //PrefixTarget={'Partial_MedicalRecord__Modal_DealwithAdd_Items'}
                                    pageDataManager={pageDataManager}
                                    sessionManager={sessionManager}
                                    navigate={navigate}

                                    viewModel={{
                                        refModal: null,
                                        大分類: '遺體處理',
                                        OnDid: (v)=> {
                                            v.selected.map((r)=> {
                                                if (!state.parentState.List_處理) {
                                                    state.parentState.List_處理 = [];
                                                }
                                                state.parentState.List_處理.unshift(r);
                                                state.parentState.forceRender();
                                            })

                                            handleClose(null);
                                        },
                                    }}
                                />
                            </TabPane>
                            <TabPane tab="定期施行" key="5">
                                <Partial_MedicalRecord__Modal_DealwithAdd_Items
                                    //PrefixTarget={'Partial_MedicalRecord__Modal_DealwithAdd_Items'}
                                    pageDataManager={pageDataManager}
                                    sessionManager={sessionManager}
                                    navigate={navigate}

                                    viewModel={{
                                        refModal: null,
                                        大分類: '定期施行',
                                        OnDid: (v)=> {
                                            v.selected.map((r)=> {
                                                if (!state.parentState.List_處理) {
                                                    state.parentState.List_處理 = [];
                                                }
                                                state.parentState.List_處理.unshift(r);
                                                state.parentState.forceRender();
                                            })

                                            handleClose(null);
                                        },
                                    }}
                                />
                            </TabPane>
                            <TabPane tab="保險給付" key="6">
                                <Partial_MedicalRecord__Modal_DealwithAdd_Items
                                    //PrefixTarget={'Partial_MedicalRecord__Modal_DealwithAdd_Items'}
                                    pageDataManager={pageDataManager}
                                    sessionManager={sessionManager}
                                    navigate={navigate}

                                    viewModel={{
                                        refModal: null,
                                        大分類: '保險給付',
                                        OnDid: (v)=> {
                                            v.selected.map((r)=> {
                                                if (!state.parentState.List_處理) {
                                                    state.parentState.List_處理 = [];
                                                }
                                                state.parentState.List_處理.unshift(r);
                                                state.parentState.forceRender();
                                            })

                                            handleClose(null);
                                        },
                                    }}
                                />
                            </TabPane>
                            <TabPane tab="追蹤檢疫" key="7">
                                <Partial_MedicalRecord__Modal_DealwithAdd_Items
                                    //PrefixTarget={'Partial_MedicalRecord__Modal_DealwithAdd_Items'}
                                    pageDataManager={pageDataManager}
                                    sessionManager={sessionManager}
                                    navigate={navigate}

                                    viewModel={{
                                        refModal: null,
                                        大分類: '追蹤檢疫',
                                        OnDid: (v)=> {
                                            v.selected.map((r)=> {
                                                if (!state.parentState.List_處理) {
                                                    state.parentState.List_處理 = [];
                                                }
                                                state.parentState.List_處理.unshift(r);
                                                state.parentState.forceRender();
                                            })

                                            handleClose(null);
                                        },
                                    }}
                                />
                            </TabPane>
                            <TabPane tab="急診" key="8">
                                <Partial_MedicalRecord__Modal_DealwithAdd_Items
                                    //PrefixTarget={'Partial_MedicalRecord__Modal_DealwithAdd_Items'}
                                    pageDataManager={pageDataManager}
                                    sessionManager={sessionManager}
                                    navigate={navigate}

                                    viewModel={{
                                        refModal: null,
                                        大分類: '急診',
                                        OnDid: (v)=> {
                                            v.selected.map((r)=> {
                                                if (!state.parentState.List_處理) {
                                                    state.parentState.List_處理 = [];
                                                }
                                                state.parentState.List_處理.unshift(r);
                                                state.parentState.forceRender();
                                            })

                                            handleClose(null);
                                        },
                                    }}
                                />
                            </TabPane>
                            <TabPane tab="毒物" key="9">
                                <Partial_MedicalRecord__Modal_DealwithAdd_Items
                                    //PrefixTarget={'Partial_MedicalRecord__Modal_DealwithAdd_Items'}
                                    pageDataManager={pageDataManager}
                                    sessionManager={sessionManager}
                                    navigate={navigate}

                                    viewModel={{
                                        refModal: null,
                                        大分類: '毒物',
                                        OnDid: (v)=> {
                                            v.selected.map((r)=> {
                                                if (!state.parentState.List_處理) {
                                                    state.parentState.List_處理 = [];
                                                }
                                                state.parentState.List_處理.unshift(r);
                                                state.parentState.forceRender();
                                            })

                                            handleClose(null);
                                        },
                                    }}
                                />
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
                <div className="modal_footer"></div>
            </div>
        );
    };
    //#endregion

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_MedicalRecord__Modal_DealwithAdd.displayName = 'Partial_MedicalRecord__Modal_DealwithAdd';
