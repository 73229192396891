import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {Form as NForm, Popover, Button, Modal, message, Upload, Input, Tooltip, Select, DatePicker} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";

import {
    LoadingOutlined,
    LockOutlined,
    PlusOutlined,
    CloseOutlined,
    PlusCircleOutlined,
    UploadOutlined
} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_Appointment__Modal_AddPet from "./Partial_Appointment__Modal_AddPet";

var moment = require('moment');
const {Option} = Select;
const {TextArea} = Input;
const dateFormat = 'YYYY/MM/DD';

type ViewModelProps = {
    refModal: any,

    票劵No: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,

    viewModel: ViewModelProps,
};
export default function Partial_Manage_Hospital__Pane_EditOrAddHospital(props: Props) {
    const PrefixTarget: string = props.PrefixTarget || 'Partial_Manage_Hospital__Pane_EditOrAddHospital';
    const pageDataManager: PageDataManager = props.pageDataManager;
    const sessionManager: SessionManager = props.sessionManager;
    const navigate: NavigateFunction = props.navigate;

    const viewModel: any = props.viewModel;

    const layout = {
        labelCol: {span: 4},
        wrapperCol: {span: 20},
    };

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region Boot
    let _refDoLoadPartialData: any = useRef<{ alwaysLoad?: boolean }>(null);
    useEffect(() => {
        const _loadPartialData = async (): Promise<void> => {

            // VALIDATE INPUT
            const p1 = viewModel.票劵No;
            if (!p1) {
                alert('p1')
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/partial_manage_campaign/pane_editcampaign');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        //delete pageDataManager.refPageData.current[TabControlState]?.['MyCollectionTab_data'];

                        pageDataManager.emptyZone(PrefixTarget);
                        let changeShard = {
                            [PrefixTarget]: {
                                ...payload,
                            }
                        }
                        pageDataManager.merge(changeShard);
                        pageDataManager.apply();

                        forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPartialData = async ({alwaysLoad = false}): Promise<void> => {
            // if (!pageDataManager.refPageData.current.Root) {
            //     return;
            // }

            /// do not dynamically load data if condition fits.
            if (!alwaysLoad) {
                if (pageDataManager.isZoneExist(PrefixTarget)) {
                    return;
                }
            }

            // if (pageDataManager.refPageData.current[TabControlState].activeTabName === 'RecentReadTab') {
            //     await _loadRecentReadTabData();
            // }

            if (viewModel.票劵No) {
                await _loadPartialData();
            } else {
                pageDataManager.emptyZone(PrefixTarget);
                let changeShard = {
                    [PrefixTarget]: {}
                }
                pageDataManager.merge(changeShard);
                pageDataManager.apply();
            }
        }


        if (_refDoLoadPartialData.current === null) {
            _refDoLoadPartialData.current = doLoadPartialData;
            _refDoLoadPartialData.current({});
        } else {
            _refDoLoadPartialData.current({});
        }


    }, [pageDataManager.get(PrefixTarget)]);
    //#endregion

    //#region Set Body
    const isModalContained = () => {
        return !!viewModel.refModal?.current;
    }

    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };
    const Body = () => {
        if (viewModel.票劵No === null) {
            pageDataManager.emptyZone(PrefixTarget);
            pageDataManager.merge({
                [PrefixTarget]: {}
            });
            pageDataManager.apply();
        }

        const RegionEmptyShow = true;
        const RegionLoadingShow = true;

        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;

        let Item_票劵 = pageDataManager.get(`${PrefixTarget}.Item_票劵`);

        let its: any;
        if (Item_票劵 === null) {
            its = [null];
        } else if (Item_票劵 !== undefined) {
            its = [Item_票劵];
        }


        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //


            // if (!values.票券名稱) {
            //     errors.票券名稱 = '↑ 欄位為必填';
            // } else {
            // }
            //
            // if (!values.到期日) {
            //     errors.到期日 = '↑ 欄位為必填';
            // } else {
            // }
            //
            // if (!values.標籤) {
            //     errors.標籤 = '↑ 欄位為必填';
            // } else {
            // }


            return errors;
        };

        const errorop = () => {
            message.error('此區域內還有資料，無法刪除');
        };

        const children = [] as any;

        // for (let i = 10; i < 36; i++) {
        //     children.push(<Option value={i.toString(36) + i}>{i.toString(36) + i}</Option>);
        // }


        const handleSubmit_save = (e: any, v: any) => {
            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            //const id : any = this.props.match.params.id;

            const p2 = v;
            if (!p2) {
                _._debug.error(p2);
                alert(p2);
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p2,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_manage_hospital/pane_edithospital/save');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);


                        let hide = message.loading({
                            type: 'loading',
                            className: 'myMessage',
                            content: <div className="myMessageBody">修改完成</div>,
                            key: 'loading',
                            duration: 2
                        });

                        handleClose(null);

                        // if (viewModel.用戶No === null) { //is insert
                        //     StaticEvent.castUpdateEvent({
                        //         command: StaticEvent.UpdateFacade.Command.Add_票劵,
                        //         Add_票劵: {
                        //             票劵No: payload.Item_票劵.票劵No,
                        //         }
                        //     });
                        // } else {
                        //     StaticEvent.castUpdateEvent({
                        //         command: StaticEvent.UpdateFacade.Command.Update_票劵,
                        //         Update_票劵: {
                        //             票劵No: payload.Item_票劵.票劵No,
                        //         }
                        //     });
                        // }

                        pageDataManager.clear();
                        pageDataManager.reloadAsync({alwaysLoad: true});

                        //_refDoLoadPartialData.current({alwaysLoad: true});

                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }

        if (!PassPrefixTargetLoaded) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <div>Loading.</div>
                    </div>
                </React.Fragment>;
            }
            else {
                return null;
            }
        }

        if (its && its.length > 0) {
            return <React.Fragment>
                {its.map(Item_票劵 => {
                    return <React.Fragment key={Item_票劵?.票劵No ?? "0"}>
                        <Form
                            onSubmit={(v) => handleSubmit_save(null, v)}
                            initialValues={{
                                Is啟用: true,

                                ...Item_票劵,
                            }}
                            validate={handleValidate}
                            render={({
                                         submitError,
                                         handleSubmit,
                                         submitting,
                                         pristine,
                                         valid,
                                         error,
                                         values
                                     }) => {

                                return <React.Fragment>
                                    <div className="form_set">
                                        <form
                                            onSubmit={handleSubmit}
                                            {...layout}>
                                            <Field parse={_.bs.identity} name="登入名稱" validate={_.bs.Form.required}>
                                                {
                                                    ({input, meta}) => (
                                                        <React.Fragment>
                                                            <NForm.Item {...input} {...layout}
                                                                        name="登入名稱"
                                                                        label="登入名稱"
                                                                        rules={[{required: true, message: '請輸入必要條件'}]}
                                                            >

                                                                <Input placeholder="登入名稱" allowClear/>
                                                                {meta.touched && meta.error &&
                                                                <div className="error">
                                                                    {meta.error}
                                                                </div>}
                                                            </NForm.Item>
                                                        </React.Fragment>
                                                    )
                                                }
                                            </Field>
                                            <Field parse={_.bs.identity} name="登入密碼" validate={_.bs.Form.required}>
                                                {
                                                    ({input, meta}) => (
                                                        <React.Fragment>
                                                            <NForm.Item {...input} {...layout}
                                                                        name="登入密碼"
                                                                        label="登入密碼"
                                                                        rules={[{required: true, message: '請輸入必要條件'}]}
                                                            >

                                                                <Input placeholder="登入密碼" allowClear/>
                                                                {meta.touched && meta.error &&
                                                                <div className="error">
                                                                    {meta.error}
                                                                </div>}
                                                            </NForm.Item>
                                                        </React.Fragment>
                                                    )
                                                }
                                            </Field>
                                            <Field parse={_.bs.identity} name="負責人" validate={_.bs.Form.required}>
                                                {
                                                    ({input, meta}) => (
                                                        <React.Fragment>
                                                            <NForm.Item {...input} {...layout}
                                                                        name="負責人"
                                                                        label="負責人"
                                                                        rules={[{required: true, message: '請輸入必要條件'}]}
                                                            >

                                                                <Input placeholder="負責人" allowClear/>
                                                                {meta.touched && meta.error &&
                                                                <div className="error">
                                                                    {meta.error}
                                                                </div>}
                                                            </NForm.Item>
                                                        </React.Fragment>
                                                    )
                                                }
                                            </Field>
                                            <Field parse={_.bs.identity} name="院所名稱" validate={_.bs.Form.required}>
                                                {
                                                    ({input, meta}) => (
                                                        <React.Fragment>
                                                            <NForm.Item {...input} {...layout}
                                                                        name="院所名稱"
                                                                        label="院所名稱"
                                                                        rules={[{required: true, message: '請輸入必要條件'}]}
                                                            >

                                                                <Input placeholder="院所名稱" allowClear/>
                                                                {meta.touched && meta.error &&
                                                                <div className="error">
                                                                    {meta.error}
                                                                </div>}
                                                            </NForm.Item>
                                                        </React.Fragment>
                                                    )
                                                }
                                            </Field>
                                            <Field parse={_.bs.identity} name="Is啟用" validate={_.bs.Form.required}>
                                                {
                                                    ({input, meta}) => (
                                                        <React.Fragment>
                                                            <NForm.Item {...input} {...layout}
                                                                        name="啟用"
                                                                        label="啟用"
                                                                        rules={[{required: true, message: '請輸入必要條件'}]}
                                                            >

                                                                <Input placeholder="啟用" defaultValue="True" allowClear/>
                                                                {meta.touched && meta.error &&
                                                                <div className="error">
                                                                    {meta.error}
                                                                </div>}
                                                            </NForm.Item>
                                                        </React.Fragment>
                                                    )
                                                }
                                            </Field>


                                            <React.Fragment>
                                                <NForm.Item>
                                                    <div className="form_footer">
                                                        {isModalContained() && <React.Fragment>
                                                            <Button type='primary' danger ghost
                                                                    onClick={(e) => handleClose(e)}>放棄編輯</Button>
                                                        </React.Fragment>
                                                        }

                                                        <Button type="primary" htmlType="submit" size="large"
                                                                onClick={handleSubmit}
                                                                disabled={(submitting)}>新增</Button>
                                                    </div>
                                                </NForm.Item>
                                            </React.Fragment>
                                        </form>
                                    </div>
                                </React.Fragment>;
                            }}
                        />
                    </React.Fragment>
                })}
            </React.Fragment>;
        } else if (its && its.length === 0) {
            if (RegionEmptyShow) {
                return <React.Fragment>
                    <div>Empty.</div>
                </React.Fragment>;
            }
        }

        return null;
    }
    //#endregion

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_Manage_Hospital__Pane_EditOrAddHospital.displayName = 'Partial_Manage_Hospital__Pane_EditOrAddHospital';
