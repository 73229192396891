import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {
    Form as NForm,
    Popover,
    Button,
    Modal,
    InputNumber,
    message,
    Tag,
    Table,
    Upload,
    Input,
    Select,
    Space,
    Tabs,
    Tooltip,
    Steps
} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';


import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";
import {LoadingOutlined, LockOutlined, EditOutlined, PlusOutlined, CloseOutlined} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_Appointment__Pane_AddOwner from "./Partial_Appointment__Pane_AddOwner";

import Partial_MedicalRecord__Modal_PrescribeAdd from "./Partial_MedicalRecord__Modal_PrescribeAdd";
import Partial_inHospital__Pane_ViewCard from "./Partial_inHospital__Pane_ViewCard";
import UseCompState from "../hooks/UseCompState";


var moment = require('moment');
const {Option} = Select;
const {Search} = Input;
const onSearch = value => console.log(value);
const {TabPane} = Tabs;
const {Step} = Steps;

type ViewModelProps = {
    refModal: any,
    OnDid?: any;

    住院籠子No: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any,

    viewModel: ViewModelProps,
};
export default function Partial_inHospital__Modal_ViewCard(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_inHospital__Modal_ViewCard';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel = props.viewModel;


    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion


    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

    }
    //#endregion

    //#region Boot
    let _refDoLoadPartialData: any = useRef<{ alwaysLoad?: boolean }>(null);
    useEffect(() => {
        // const _loadPartialData = async (): Promise<void> => {
        //
        //     // VALIDATE INPUT
        //     //const id : any = this.props.match.params.id;
        //     const p1 = viewModel.住院籠子No;
        //     if (!p1) {
        //         return; //ERROR INPUTS
        //     }
        //
        //     // WAITING SCREEN
        //     loadingManager.changeLoadingCount(+1);
        //     //StaticEvent.LoadingEvent.next(true);
        //
        //     // POST
        //     let postData = {
        //         p1,
        //         timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
        //     };
        //     const postUrl = ApiService.urlMapping('~/pet_api/1/partial_inhospital/pane_viewcard');
        //     const post$ = Static.apiService.post(postUrl, postData);
        //     post$.subscribe(async (res: any) => {
        //             // CLOSE WAITING SCREEN
        //             loadingManager.changeLoadingCount(-1);
        //             //StaticEvent.LoadingEvent.next(false);
        //
        //             let data = res.data;
        //             let error = data.error || null;
        //             let result = error == null;
        //
        //             if (result) { // SUCEESS
        //                 let payload = data.payload;
        //                 _._debug.warn(payload);
        //
        //                 pageDataManager.emptyZone(PrefixTarget);
        //                 let changeShard = {
        //                     [PrefixTarget]: {
        //                         ...payload,
        //                     }
        //                 }
        //                 pageDataManager.merge(changeShard);
        //                 pageDataManager.apply();
        //
        //                 forceRender();
        //             } else {
        //                 // AUTH USER EXPIRED T:1
        //                 if (error.code == 2) {
        //                     await sessionManager.setLogout();
        //                     Static.NavAwayToLogin(pageDataManager, navigate)(null);
        //                     return;
        //                 }
        //
        //                 _._debug.error(error);
        //                 alert(error.message);
        //             }
        //         }, async (err) => {
        //
        //             // CLOSE WAITING SCREEN
        //             loadingManager.changeLoadingCount(-1);
        //             //StaticEvent.LoadingEvent.next(false);
        //             _._debug.warn(err);
        //             alert('網路連線有問題');
        //         }
        //     );
        //     return lastValueFrom(
        //         post$
        //     );
        // }
        const doLoadPartialData = async ({alwaysLoad = false}): Promise<void> => {
            // if (!pageDataManager.refPageData.current.Root) {
            //     return;
            // }

            /// do not dynamically load data if condition fits.
            if (!alwaysLoad) {
                if (pageDataManager.isZoneExist(PrefixTarget)) {
                    return;
                }
            }

            //await _loadPartialData();
        }

        _refDoLoadPartialData.current = doLoadPartialData;
        _refDoLoadPartialData.current({});
    }, [pageDataManager.get(PrefixTarget)]);
    //#endregion

    //#region Set Body
    const isModalContained = () => {
        return !!viewModel.refModal?.current
    }
    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };
    const Body = () => {
        const key = 'updatable';
        const openMessage = () => {
            message.loading({content: '正努力送往藥局...', key});
            setTimeout(() => {
                message.success({content: '藥局確認了你的藥單!', key, duration: 2});
            }, 1000);
        };

        const saveMessage = () => {
            message.loading({content: '儲存中...', key});
            setTimeout(() => {
                message.success({content: '儲存成功!', key, duration: 2});
            }, 1000);
        };


        //#region Handlers

        //#endregion

        return (
            <div className="model_wrapper">
                <div className="modal_title">
                    <div className="title_info">
                        <h2>住院管理</h2>
                    </div>
                    <div className="info_bar"></div>
                    <div className="control_bar">
                        <Tooltip placement="top" title={'關閉'}>
                            <Button type="text" icon={<CloseOutlined/>} onClick={handleClose}/>
                        </Tooltip>
                    </div>
                </div>
                <div className="modal_content">
                    <div className="content_box">
                        <Partial_inHospital__Pane_ViewCard
                            //PrefixTarget={'Partial_inHospital__Pane_ViewCard'}
                            pageDataManager={pageDataManager}
                            sessionManager={sessionManager}
                            navigate={navigate}

                            viewModel={{
                                refModal: viewModel.refModal,

                                住院籠子No: viewModel.住院籠子No
                            }}
                        />
                    </div>
                </div>
                <div className="modal_footer">
                </div>
            </div>
        );
    };
    //#endregion

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_inHospital__Modal_ViewCard.displayName = 'Partial_inHospital__Modal_ViewCard';
