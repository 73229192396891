import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {Form as NForm, Popover, Button, Modal, message, Upload, Input, Select, Space, Table, Radio, Empty} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";

import {LoadingOutlined, LockOutlined, PlusOutlined, RedoOutlined, UserOutlined} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";
import UseCompState from "../hooks/UseCompState";

var moment = require('moment');
const {Option} = Select;
const {Search} = Input;
const onSearch = value => console.log(value);

type ViewModelProps = {};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any;

    onDone?: any,
};
export default function Partial_Search_Or(props: Props) {
    const PrefixTarget: string = props.PrefixTarget || 'Partial_Search_Or';
    const pageDataManager: PageDataManager = props.pageDataManager;
    const sessionManager: SessionManager = props.sessionManager;
    const navigate: NavigateFunction = props.navigate;

    const onDone = props.onDone;

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    //#region LoadingManager
    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});
    //#endregion


    //#region Boot
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

        state.pageDataManager = pageDataManager;
        state.sessionManager = sessionManager;
        state.loadingManager = loadingManager;
        state.navigate = navigate;
        state.forceRender = forceRender;

        state.subscribed = [];

        state.s = '全部';
        state.q = pageDataManager.get(`${PrefixTarget}.q`) ?? '';
    }


    useEffect(() => {
        const _loadPageData = async (): Promise<void> => {
            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">搜尋中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            //const id : any = this.props.match.params.id;
            // const id = 1;
            // if (!id) {
            //     return; //ERROR INPUTS
            // }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                //p1: 1,
                q: state.q,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/partial_search_or');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);

                        let changeShard = {
                            [PrefixTarget]: {
                                ...payload,
                                q: state.q,
                            }
                        }
                        pageDataManager.merge(changeShard);
                        pageDataManager.apply();

                        forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const _doLoadPartialData = async (): Promise<void> => {
            if (!pageDataManager.refPageData.current.Root) {
                return;
            }

            if (pageDataManager.isZoneExist(PrefixTarget)) {
                return;
            } else {
                await _loadPageData();
            }
        }

        _doLoadPartialData();
    }, [pageDataManager.refPageData.current?.[PrefixTarget]]);
    //#endregion


    const Body = () => {
        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            // if (!values.username) {
            //     errors.username = '↑ 欄位為必填';
            // } else {
            // }
            //
            // if (!values.password) {
            //     errors.password = '↑ 欄位為必填';
            // } else {
            // }

            if (!values.selected) {
                errors.selected = '↑ 欄位為必填';
            }

            return errors;
        };

        const columns = [
            {
                title: '主人姓名',
                dataIndex: '主人姓名',
                key: '1',
            },
            {
                title: '身分證',
                dataIndex: '身分證',
                key: '2',
            },
            {
                title: '手機',
                dataIndex: '手機',
                key: '3',
            },
            {
                title: '聯絡電話',
                dataIndex: '聯絡電話',
                key: '4',
            },
        ];

        const List_飼主 = pageDataManager.get(`${PrefixTarget}.List_飼主`);

        const Data_飼主 = (List_飼主 ?? []).map((r) => {
            return {
                key: r.飼主Id,
                主人姓名: r.主人姓名,
                身分證: r.身分證,
                手機: r.手機,
                聯絡電話: r.聯絡電話,
                data: r,
            }
        })


        interface DataType {
            key: React.Key;
            name: string;
            id: string;
            cellphone: string;
            tel: string;
        }

        const data: DataType[] = [
            {
                key: '1',
                name: '陳忠志',
                id: 'A121345678',
                cellphone: '0922343676',
                tel: '02-27334387',
            },
            {
                key: '2',
                name: '蔡贏安',
                id: 'A221345678',
                cellphone: '0922343676',
                tel: '02-27334387',
            },
            {
                key: '3',
                name: '余瑞妍',
                id: 'A221345678',
                cellphone: '0922343676',
                tel: '02-27334387',
            },
            {
                key: '4',
                name: '王芯儀',
                id: 'A221345678',
                cellphone: '0922343676',
                tel: '02-27334387',
            },
        ];

        const handleSubmit_bringBack = (e: any, v: any) => {
            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            //#region Handle SaveUserInfo
            {
                // VALIDATE INPUT
                //const id : any = this.props.match.params.id;
                const p1 = v.selected;
                if (!p1) {
                    _._debug.error(p1);
                    return; //ERROR INPUTS
                }

                // WAITING SCREEN
                loadingManager.changeLoadingCount(+1);
                //StaticEvent.LoadingEvent.next(true);

                // POST
                let postData = {
                    p1,
                    timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
                };
                const postUrl = ApiService.urlMapping('~/pet_api/1/_pg_appointment/keepowner');
                const post$ = Static.apiService.post(postUrl, postData);
                post$.subscribe(async (res: any) => {
                        hide();

                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);

                        let data = res.data;
                        let error = data.error || null;
                        let result = error == null;

                        if (result) { // SUCEESS
                            let payload = data.payload;
                            _._debug.warn(payload);


                            let carryData = Object.assign({},
                                {
                                    [PrefixTarget]: payload
                                }
                            );
                            if (onDone) {
                                const ret = onDone(carryData);
                            } else {
                                alert('onDone not defined');
                            }

                        } else {
                            // AUTH USER EXPIRED
                            if (error.code == 2) {
                                //alert('帳號被登出，請重新登入');
                                //window.location.href = '/login';
                                //this.props.history.push('/login')
                                await sessionManager.setLogout();
                                pageDataManager.clear();
                                pageDataManager.reloadAsync({alwaysLoad: true});
                                alert('時間逾時已被登出');
                                return;
                            }

                            _._debug.error(error);
                            alert(error.message);
                        }
                    }, async (err) => {
                        hide();

                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);
                        _._debug.warn(err);
                        alert('網路連線有問題');
                    }
                );
                return lastValueFrom(
                    post$
                );
            }
            //#endregion
        }

        const handleSubmit_applyNewSearchCondition = (e: any, v: any) => {
            state.q = v.q;

            pageDataManager.emptyZone(PrefixTarget);
            pageDataManager.apply();

            forceRender();
            //pageDataManager.reloadAsync({alwaysLoad: true});
        }

        return (
            <Form
                onSubmit={(v) => handleSubmit_bringBack(null, v)}
                initialValues={{
                    q: state.q,
                }}
                validate={handleValidate}
                render={({
                             submitError,
                             handleSubmit,
                             submitting,
                             pristine,
                             valid,
                             error,
                             values
                         }) => {


                    return <React.Fragment>
                        <div className="form_body">
                            <form onSubmit={handleSubmit}>

                                <Field parse={_.bs.identity} name="q">
                                    {
                                        ({input, meta}) => (
                                            <React.Fragment>
                                                <NForm.Item {...input}
                                                            name="q"
                                                    // rules={[{ required: true, message: '請輸入正確密碼' }]}
                                                >
                                                    <Search
                                                        defaultValue={values.q}
                                                        placeholder="搜尋飼主姓名、手機、市話"
                                                        allowClear
                                                        onSearch={(value, event) => {
                                                            input.onChange(value);
                                                            handleSubmit_applyNewSearchCondition(null, {
                                                                ...values,
                                                                q: value
                                                            })
                                                        }}
                                                        style={{width: 100 + '%'}}
                                                        {...input}
                                                        size="large"
                                                    />
                                                    {meta.touched && meta.error &&
                                                    <div className="error">
                                                        {meta.error}
                                                    </div>}
                                                </NForm.Item>
                                            </React.Fragment>
                                        )
                                    }
                                </Field>
                            </form>
                            <div className="searchresult">
                                <Field parse={_.bs.identity} name="selected">
                                    {
                                        ({input, meta}) => (
                                            <Table
                                                rowSelection={{
                                                    type: 'radio',
                                                    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
                                                        console.log(`selectedRowKeys: ${selectedRowKeys}`, ',selectedRows: ', selectedRows);
                                                        if (selectedRows.length === 1) {
                                                            // @ts-ignore
                                                            input.onChange(selectedRows[0].data);
                                                        } else {
                                                            input.onChange(null);
                                                        }
                                                    },
                                                    getCheckboxProps: (record: DataType) => ({
                                                        disabled: record.name === 'Disabled User', // Column configuration not to be checked
                                                        name: record.name,
                                                    })
                                                }}
                                                columns={columns}
                                                dataSource={Data_飼主}
                                                locale={{
                                                    emptyText: <Empty description={
                                                        <span>沒有搜尋到相關資料～</span>
                                                    }/>
                                                }}
                                            />
                                        )
                                    }
                                </Field>
                            </div>
                            <React.Fragment>
                                <NForm.Item>
                                    <div className="form_footer">
                                        <Button type="primary" htmlType="submit" size="large"
                                                disabled={pristine || !valid} onClick={handleSubmit}>下一步</Button>

                                    </div>
                                </NForm.Item>
                            </React.Fragment>

                        </div>
                    </React.Fragment>;
                }}
            />
        );
    };

//#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
//#endregion
}
Partial_Search_Or.displayName = 'Partial_Search_Or';
