import React, { useCallback, useEffect, useRef, useState } from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import { Form as NForm, Popover, Button, Modal, message, Upload, Input, Select, Space, Tabs, Tooltip, Steps } from 'antd';

import { ApiService } from "../_/ApiService";
import { Static } from "../_/Static";
import { StaticEvent } from "../_/StaticEvent";
import _ from "../_";

import { Link } from 'react-router-dom';


import {
    useParams,
    useNavigate
} from "react-router-dom";
import { PageDataManager } from "../hooks/UsePageDataManager";

import { Field, Form } from "react-final-form";
import { SessionManager } from "../hooks/UseSessionManager";
import { NavigateFunction } from "react-router";

import { LoadingOutlined, LockOutlined, PlusOutlined, RedoOutlined, CloseOutlined } from "@ant-design/icons";
import { lastValueFrom } from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_Appointment__Pane_AddOwner from "./Partial_Appointment__Pane_AddOwner";
import Partial_Search_Or from "./Partial_Search_Or";
import Partial_MedicalRecord__Pane_PastMedicalHistory from "./Partial_MedicalRecord__Pane_PastMedicalHistory";
import Partial_Manage_Staff__Pane_EditOrAddStaff from "./Partial_Manage_Staff__Pane_EditOrAddStaff";
import UseCompState from "../hooks/UseCompState";


var moment = require('moment');
const { Option } = Select;
const { Search } = Input;
const onSearch = value => console.log(value);
const { TabPane } = Tabs;
const { Step } = Steps;

type ViewModelProps = {
    refModal: any,

    病歷No:any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any,

    viewModel: ViewModelProps,
};
export default function Partial_MedicalRecord__Modal_PastMedicalHistory(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_MedicalRecord__Modal_PastMedicalHistory';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel: any = props.viewModel;

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({ shouldReRenderOnLoading: false });

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

    }
    //#endregion

    //#region Set Body
    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };
    const isModalContained = () => {
        return !!viewModel.refModal?.current;
    }
    const Body = () => {

        return (
            <div className="model_wrapper">
                <div className="modal_title">
                    <div className="title_info">
                        <h2>過往病史</h2>
                    </div>
                    <div className="info_bar"></div>
                    <div className="control_bar">
                        <Tooltip placement="top" title={'關閉'}>
                            <Button type="text" icon={<CloseOutlined/>} onClick={handleClose}/>
                        </Tooltip>
                    </div>
                </div>
                <div className="modal_content">
                    <div className="content_box">
                        <Partial_MedicalRecord__Pane_PastMedicalHistory
                            //PrefixTarget={'Partial_Manage_Staff__Pane_EditOrAddStaff'}
                            pageDataManager={pageDataManager}
                            sessionManager={sessionManager}
                            navigate={navigate}

                            viewModel={{
                                refModal: viewModel.refModal,

                                病歷No: viewModel.病歷No,

                                OnUpdated: ()=> {
                                    state.parentState?.forceRender?.({alwaysLoad: true});
                                }
                            }}
                        />
                        {/* <Tabs defaultActiveKey="1" tabPosition="right">
                            <TabPane tab="過往病史" key="1">


                            </TabPane>
                            <TabPane tab="住院紀錄" key="2">
                                <Partial_Search_Or
                                    PrefixTarget={'Partial_Search_Or'}
                                    pageDataManager={pageDataManager}
                                    sessionManager={sessionManager}
                                    navigate={navigate}

                                />
                            </TabPane>
                            <TabPane tab="美容紀錄" key="3">
                                3
                            </TabPane>
                            <TabPane tab="藥局紀錄" key="4">
                                4
                            </TabPane>
                            <TabPane tab="看診紀錄" key="5">
                                5
                            </TabPane>
                            <TabPane tab="售貨紀錄" key="6">
                                6
                            </TabPane>
                            <TabPane tab="結帳紀錄" key="7">
                                7
                            </TabPane>
                            <TabPane tab="外送檢驗紀錄" key="8">
                                8
                            </TabPane>
                        </Tabs> */}
                    </div>
                </div>
                <div className="modal_footer"></div>
            </div>
        );
    };
    //#endregion

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_MedicalRecord__Modal_PastMedicalHistory.displayName = 'Partial_MedicalRecord__Modal_PastMedicalHistory';
