import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {
    Form as NForm,
    Popover,
    Button,
    Modal,
    InputNumber,
    message,
    Tag,
    Table,
    Upload,
    Input,
    Select,
    Space,
    Tabs,
    Tooltip,
    Steps
} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';


import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";
import {LoadingOutlined, LockOutlined, EditOutlined, PlusOutlined, CloseOutlined} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_MedicalRecord__Modal_Prescribe from "./Partial_MedicalRecord__Modal_Prescribe";
import UseCompState from "../hooks/UseCompState";


var moment = require('moment');
const {Option} = Select;
const {Search} = Input;
const onSearch = value => console.log(value);
const {TabPane} = Tabs;
const {Step} = Steps;

type ViewModelProps = {
    refModal: any,

    病歷No: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any;

    viewModel: ViewModelProps,
};
export default function Partial_MedicalRecord__Modal_IHRecord(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_MedicalRecord__Modal_IHRecord';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel = props.viewModel;

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    //#region LoadingManager
    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});
    //#endregion

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

    }
    //#endregion

    //#region No Cache
    useEffect(() => {
        return () => {
            pageDataManager.emptyZone(PrefixTarget);
            pageDataManager.apply();
        }
    }, []);
    //#endregion

    //#region Boot
    let _refDoLoadPartialData: any = useRef<{ alwaysLoad?: boolean }>(null);
    useEffect(() => {
        const _loadPartialData = async (): Promise<void> => {

            // VALIDATE INPUT
            const p1 = viewModel.病歷No;
            if (!p1) {
                alert('p1');
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/partial_medicalrecord/modal_ihrecord');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        let changeShard = {
                            [PrefixTarget]: {
                                ...payload,
                            }
                        }
                        pageDataManager.merge(changeShard);
                        pageDataManager.apply();

                        forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPartialData = async ({alwaysLoad = false}): Promise<void> => {
            // if (!pageDataManager.refPageData.current.Root) {
            //     return;
            // }

            /// do not dynamically load data if condition fits.
            if (!alwaysLoad) {
                if (pageDataManager.isZoneExist(PrefixTarget)) {
                    return;
                }
            }

            await _loadPartialData();
        }

        _refDoLoadPartialData.current = doLoadPartialData;
        _refDoLoadPartialData.current({});
    }, [pageDataManager.get(PrefixTarget)]);
    //#endregion

    //#region Set Body
    const isModalContained = () => {
        return !!viewModel.refModal?.current;
    }
    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };
    const Body = () => {
        const handleClose = (e) => {
            if (e) {
                e.preventDefault();
            }

            if (viewModel.refModal || viewModel.refModal.current) {
                viewModel.refModal.current.destroy();
            }
        };

        //#region :Popup :createModalPrescribe
        const createModalPrescribe_refModal = useRef<any>(null);
        const createModalPrescribe = (e: any, tasks?: string[]): void => {
            if (e) {
                e.preventDefault();
            }
            // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
            //
            // if (!PassPageDataInitalized) {
            //     //empty
            // }

            const config = {
                // title: 'test',
                content: <Partial_MedicalRecord__Modal_Prescribe
                    PrefixTarget={'Partial_MedicalRecord__Modal_Prescribe'}
                    pageDataManager={pageDataManager}
                    sessionManager={sessionManager}
                    navigate={navigate}
                    //parentState={state}

                    viewModel={{
                        refModal: createModalPrescribe_refModal,

                        病歷No: null,
                        掛號No: null,

                        OnDid: () => {
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            //state._refDoLoadPartialData?.current?.({alwaysLoad: true});
                        }
                    }}
                />,
                okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
                className: 'myClass myModal',
                width: '90em',
                height: ' ',
                footer: null,
                //prefixCls: '',
            };

            createModalPrescribe_refModal.current = Modal.info(config);
        }
        //#endregion


        const List_醫生 = pageDataManager.get(`${PrefixTarget}.List_醫生`);
        let docOptions: any = [];
        {
            if (List_醫生) {
                docOptions = List_醫生.map((r) => {
                    return {
                        key: r.醫生No,
                        value: r.醫生No,
                        label: `${r.名稱} 醫師`,
                    }
                });
                // docOptions.unshift({
                //     key: '0',
                //     value: '',
                //     label: '不指定',
                // })
            }
        }

        let DataSource_病歷項 = state.DataSource_病歷項;
        const List_病歷項 = pageDataManager.get(`${PrefixTarget}.List_病歷項`);
        if (List_病歷項 !== null) {
            let serialCounter = 0;
            DataSource_病歷項 = List_病歷項.map((Item_病歷項) => {
                serialCounter += 1;

                if (serialCounter == 1) {
                }

                let 住院期間: any = null;
                let 住院剩餘天: any = null;
                let 住院進行天: any = null;
                let 住院預計天: any = null;
                if (!_.util.nullify(Item_病歷項.住院_住院開始時間)) {
                    住院期間 = '錯誤:未設置開始住院時間';
                } else {
                    if (!_.util.nullify(Item_病歷項.住院_住院預計結束時間)) {
                        住院期間 = '錯誤:住院預計結束時間';
                    } else {
                        let startDate = moment(Item_病歷項.住院_住院開始時間);
                        let endDate = moment(Item_病歷項.住院_住院預計結束時間);
                        let totalDays = endDate.diff(startDate, 'days');
                        let now = moment();
                        let remainingDays = endDate.diff(now, 'days');
                        if (remainingDays < 0) {
                            remainingDays = 0;
                        }
                        let runningDays = now.diff(startDate, 'days');
                        if (runningDays < 0) {
                            runningDays = 0;
                        }
                        住院預計天 = totalDays;
                        住院進行天 = runningDays;
                        住院剩餘天 = remainingDays;
                        住院期間 = `${startDate.format('MM.DD')}~${endDate.format('MM.DD')}`;
                    }
                }

                return {
                    entity: Item_病歷項,
                    key: Item_病歷項.病歷項No,

                    inHDate: Item_病歷項.住院_住院開始時間 ? moment(Item_病歷項.住院_住院開始時間).format('YYYY-MM-DD hh:mm') : '-',
                    outHDate: Item_病歷項.住院_住院預計結束時間 ? moment(Item_病歷項.住院_住院預計結束時間).format('YYYY-MM-DD hh:mm') : '-',
                    ihday: (Item_病歷項.住院_住院開始時間 && Item_病歷項.住院_住院預計結束時間) ? `${住院預計天}天` : '-',
                    doctor: `${Item_病歷項.__醫生_建檔醫師.名稱} 醫師`,
                    careStaff: `${Item_病歷項.__醫生_建檔醫師.名稱}`,
                    ihreason: Item_病歷項.住院_住院原因,

                }
            });

            state.DataSource_病歷項 = DataSource_病歷項;
        }

        const IHHistoryColumn = [
            {
                dataIndex: 'inHDate',
                title: '住院日期',
            },
            {
                dataIndex: 'outHDate',
                title: '出院日期',
            },

            {
                dataIndex: 'ihday',
                title: '住院天數',
            },
            {
                dataIndex: 'doctor',
                title: '開單醫生'
            },
            {
                dataIndex: 'careStaff',
                title: '照顧人員'
            },
            {
                dataIndex: 'ihreason',
                title: '住院原因',
            },
            // {
            //     dataIndex: 'view',
            //     title: '查看',
            //     render: (text, record) => <a type="link" onClick={createModalPrescribe}>查看</a>
            // }
        ];

        const ihHistoryDate = [
            {
                inHDate: '2021-07-10',
                outHDate: '2021-07-21',
                ihday: '11天',
                doctor: '林隆璇 醫師',
                careStaff: '林溪泉',
                ihreason: '流行性感冒',
            },
            {
                inHDate: '2021-03-1',
                outHDate: '2021-04-2',
                ihday: '30天',
                doctor: '林隆璇 醫師',
                careStaff: '林溪泉',
                ihreason: '食物中毒',
            },
            {
                inHDate: '2020-09-10',
                outHDate: '2021-09-15',
                ihday: '5天',
                doctor: '林隆璇 醫師',
                careStaff: '林溪泉',
                ihreason: '皮膚潰爛',
            },
        ];


        return (
            <div className="model_wrapper">
                <div className="modal_title">
                    <div className="title_info">
                        <h2>過往住院紀錄</h2>
                    </div>
                    <div className="info_bar"></div>
                    <div className="control_bar">
                        <Tooltip placement="top" title={'關閉'}>
                            <Button type="text" icon={<CloseOutlined/>} onClick={handleClose}/>
                        </Tooltip>
                    </div>
                </div>
                <div className="modal_content">
                    <div className="content_box">
                        <div className="topbar_plug">
                            <div className="title"></div>
                            <div className="func">

                            </div>
                        </div>
                        <Table
                            columns={IHHistoryColumn}
                            dataSource={DataSource_病歷項}
                        />
                    </div>
                </div>
                <div className="modal_footer"></div>
            </div>
        );
    };
    //#endregion

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_MedicalRecord__Modal_IHRecord.displayName = 'Partial_MedicalRecord__Modal_IHRecord';
