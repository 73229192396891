import React, { useCallback, useEffect, useRef, useState } from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import { Form as NForm, Button, Modal, Statistic, message, Row, Col, Typography, DatePicker, Input, Select, Space, Tabs, Tooltip, Steps, Table, Empty } from 'antd';

import { ApiService } from "../_/ApiService";
import { Static } from "../_/Static";
import { StaticEvent } from "../_/StaticEvent";
import _ from "../_";

import { Link } from 'react-router-dom';


import {
    useParams,
    useNavigate
} from "react-router-dom";
import { PageDataManager } from "../hooks/UsePageDataManager";

import { Field, Form } from "react-final-form";
import { SessionManager } from "../hooks/UseSessionManager";
import { NavigateFunction } from "react-router";
import { LoadingOutlined, LockOutlined, EditOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";
import { lastValueFrom } from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_Modal_DealwithItem from "./Partial_Modal_DealwithItem";
import Partial_MedicalRecord__Modal_DealwithAdd from "./Partial_MedicalRecord__Modal_DealwithAdd";



var moment = require('moment');
const { Option } = Select;
const { Search } = Input;
const onSearch = value => console.log(value);
const { TabPane } = Tabs;
const { Step } = Steps;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';
const today = new Date();
const { Title } = Typography;

type ViewModelProps = {
    refModal: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,

    viewModel: ViewModelProps,
};
export default function Partial_Modal_ClientMessage(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_Modal_ClientMessage';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);


    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion


    const loadingManager = UseLoadingManager({ shouldReRenderOnLoading: false });

    //#region Boot
    const _doLoadPartialData = async (): Promise<void> => {
        if (!pageDataManager.refPageData.current.Root) {
            return;
        }

        /// do not dynamically load data if condition fits.
        if (pageDataManager.isZoneExist(PrefixTarget)) {
            return;
        }
        //await _loadPageData();
    }
    useEffect(() => {
        _doLoadPartialData();
    }, []);
    //#endregion


    const Body = () => {
        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            if (!values.username) {
                errors.username = '↑ 欄位為必填';
            } else {
            }

            if (!values.password) {
                errors.password = '↑ 欄位為必填';
            } else {
            }

            return errors;
        };

        const handleClose = (e) => {
            if (e) {
                e.preventDefault();
            }

            if (viewModel.refModal || viewModel.refModal.current) {
                viewModel.refModal.current.destroy();
            }
        };

        //#region :Popup :createModalDealwithItem
        const createModalDealwithItem_refModal = useRef<any>(null);
        const createModalDealwithItem = (e: any, tasks?: string[]): void => {
            if (e) {
                e.preventDefault();
            }
            // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
            //
            // if (!PassPageDataInitalized) {
            //     //empty
            // }

            const config = {
                // title: 'test',
                content: <Partial_Modal_DealwithItem
                    PrefixTarget={'Partial_Modal_DealwithItem'}
                    pageDataManager={pageDataManager}
                    sessionManager={sessionManager}
                    navigate={navigate}

                    viewModel={{
                        refModal: createModalDealwithItem_refModal,
                    }}
                />,
                okButtonProps: { disabled: true, className: "modal-footer-hiden-button" },
                className: 'myClass myModal',
                width: '90em',
                height: ' ',
                footer: null,
                //prefixCls: '',
            };

            createModalDealwithItem_refModal.current = Modal.info(config);
        }
        //#endregion


        //#region :Popup :createModalDealwithTool
        const createModalDealwithTool_refModal = useRef<any>(null);
        const createModalDealwithTool = (e: any, tasks?: string[]): void => {
            if (e) {
                e.preventDefault();
            }
            // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
            //
            // if (!PassPageDataInitalized) {
            //     //empty
            // }

            const config = {
                // title: 'test',
                content: <Partial_MedicalRecord__Modal_DealwithAdd
                    PrefixTarget={'Partial_MedicalRecord__Modal_DealwithAdd'}
                    pageDataManager={pageDataManager}
                    sessionManager={sessionManager}
                    navigate={navigate}

                    viewModel={{
                        refModal: createModalDealwithTool_refModal,
                    }}
                />,
                okButtonProps: { disabled: true, className: "modal-footer-hiden-button" },
                className: 'myClass myModal',
                width: '90em',
                height: ' ',
                footer: null,
                //prefixCls: '',
            };

            createModalDealwithTool_refModal.current = Modal.info(config);
        }
        //#endregion



        const handleSubmit_clickLogin = (e: any, v: any) => {
            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">現在開始創立世界</div>,
                key: 'loading',
                duration: 0
            });

            //#region Handle SaveUserInfo
            {
                // VALIDATE INPUT
                //const id : any = this.props.match.params.id;
                const id = 1;
                if (!id) {
                    return; //ERROR INPUTS
                }

                // WAITING SCREEN
                loadingManager.changeLoadingCount(+1);
                //StaticEvent.LoadingEvent.next(true);

                // POST
                let postData = {
                    p1: v.p1,
                    p2: v.p2,
                    p3: v.p3,
                    p4: v.p4,
                    p5: v.p5,
                    timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
                };
                const postUrl = ApiService.urlMapping('~/h_api/1/pg_jumplogin/clicklogin');
                const post$ = Static.apiService.post(postUrl, postData);
                post$.subscribe(async (res: any) => {
                    hide();


                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        message.success({
                            type: 'success',
                            className: 'myMessage',
                            content: <div className="myMessageBody">歡迎來到，恐龍世界</div>,
                            key: '',
                            duration: 3
                        });

                        // message.success({
                        //     type: 'success',
                        //     className: 'myMessage',
                        //     content: <div className="myMessageBody">門就開了</div>,
                        //     key: '',
                        //     duration: 3
                        // });


                        await sessionManager.setLoginData(
                            payload.SessionId,
                            payload.AuthData
                        );

                        navigate({
                            pathname: '/wall/newest',
                            //search: "?sort=name",
                            //hash: "#the-hash",
                            //state: {panelSelection: "f1"}
                        });
                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
                );
                return lastValueFrom(
                    post$
                );
            }
            //#endregion
        }


        // 消費總覽

        interface overshopItem {
            key: React.Key;
            purchanumber: string;
            totalpamount: string;
        }

        const oshopCol = [
            {
                title: '消費次數',
                dataIndex: 'purchanumber',
            },
            {
                title: '平均消費',
                dataIndex: 'totalpamount',
            },
        ];

        const oshopData: overshopItem[] = [
            {
                key: 1,
                purchanumber: '12',
                totalpamount: '$8,800',
            },
        ];



        // 消費項目

        interface pshopItem {
            key: React.Key;
            purchasestype: string;
            purchasesnumber: string;
            totalamount: string;
        }

        const pshopCol = [
            {
                title: '消費分類',
                dataIndex: 'purchasestype',
            },
            {
                title: '消費次數',
                dataIndex: 'purchasesnumber',
            },
            {
                title: '總金額',
                dataIndex: 'totalamount',
            },
        ];

        const pshopData: pshopItem[] = [
            {
                key: 1,
                purchasestype: '美容',
                purchasesnumber: '11',
                totalamount: '$8,800'
            },
            {
                key: 2,
                purchasestype: '看診',
                purchasesnumber: '27',
                totalamount: '$9,450'
            },
            {
                key: 3,
                purchasestype: '售貨',
                purchasesnumber: '75',
                totalamount: '$78,232'
            }
        ];



        return (
            <div className="model_wrapper">
                <div className="modal_title">
                    <div className="title_info">
                        <h2>傳訊息</h2>
                    </div>
                    <div className="info_bar"></div>
                    <div className="control_bar">
                        <Tooltip placement="top" title={'關閉'}>
                            <Button type="text" icon={<CloseOutlined/>} onClick={handleClose}/>
                        </Tooltip>
                    </div>
                </div>
                <div className="modal_content">
                    <div className="content_box">
                        <div className="systable col_4">
                            <div className="str cops">
                                <div className="th">飼主姓名</div>
                                <div className="td">李伊妮</div>
                            </div>
                            <div className="str cops">
                                <div className="th">寵物名稱</div>
                                <div className="td">小乖</div>
                            </div>
                            <div className="str cops">
                                <div className="th">醫賴碼</div>
                                <div className="td">W123424238</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal_footer">
                    <Button block type="primary">傳訊息</Button>
                </div>
            </div>
        );
    };

    //#region Render
    return <React.Fragment>
        <Body />
    </React.Fragment>;
    //#endregion
}
Partial_Modal_ClientMessage.displayName = 'Partial_Modal_ClientMessage';
