import React, {useEffect, useRef} from 'react';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import {
    Form, Input, Button, Alert, Checkbox, Result, message, Space,
    Modal, Typography, Tabs, Select, Popconfirm, Table, Empty, Spin, DatePicker, Menu, Row, Col, Tooltip
} from 'antd';
import {useApp} from "../overmind";
import {UserInfo} from "../global";
import {Static} from "../_/Static";
import {ApiService} from "../_/ApiService";
import _ from "../_";
import {
    EyeInvisibleOutlined,
    EyeTwoTone,
    UserOutlined,
    LockOutlined,
    RedoOutlined,
    LeftOutlined,
    RightOutlined,
    PrinterOutlined,
    RollbackOutlined,
    ArrowLeftOutlined,
    LoadingOutlined, ReloadOutlined
} from '@ant-design/icons';

import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import UsePageDataManager from '../hooks/UsePageDataManager';
import UseSessionManager from "../hooks/UseSessionManager";
import UseLoadingManager from "../hooks/UseLoadingManager";
import UseCompState from "../hooks/UseCompState";
import {lastValueFrom} from "rxjs";
import Partial_Modal_ClientMessage from "./Partial_Modal_ClientMessage";
import Partial_ClientManage__Modal_ClientAnalysis from "./Partial_ClientManage__Modal_ClientAnalysis";


import './Pg_Waitingroom.scss';
import FunctionBtn from './Box_Functionbtn';
import moment from 'moment';
import Partial_MedicalRecord__Tab_BasicData from "./Partial_MedicalRecord__Tab_BasicData";
import Partial_MedicalRecord__Tab_Treatment from "./Partial_MedicalRecord__Tab_Treatment";

const iconLighting = require('../assets/images/icon/lighting.svg');
const iconWatting = require('../assets/images/icon/new-medical-record.svg');
const iconAnalysis = require('../assets/images/icon/analysis.svg');
const iconProgress = require('../assets/images/icon/progress.svg');
const iconMessage = require('../assets/images/icon/conversationx.svg');

const onSearch = value => console.log(value);
const {Title} = Typography;
const {TabPane} = Tabs;
const {Search} = Input;
const {Option} = Select;
const dateFormat = 'YYYY/MM/DD';
const {RangePicker} = DatePicker;


type Props = {
    PrefixTarget?: string;
    RoutePathname?: string;
    MenuTemplateId?: string;

    parentState?: any;
};
export default function Pg_ClientManage(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Root';
    let RoutePathname = props.RoutePathname;
    let MenuTemplateId = props.MenuTemplateId;

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    //#region LoadingManager
    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: true});
    //#endregion

//#region PageDataManager
    const refDoLoadPageData = Static.refDoLoadPageData;
    let pageDataManager = UsePageDataManager({
        refPageLoad: refDoLoadPageData
    });
    //#endregion

    //#region SessionManager
    let sessionManager = UseSessionManager({
        pageDataManager: pageDataManager,
    });
    //#endregion

    //#region :State CompState
    let param_病歷No = params.No;
    let defaultS = '全部';
    let defaultQ = '';

    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

        state.飼主No = param_病歷No;

    }
    //#endregion

    //#region Boot

    useEffect(() => {
        const _loadPageData = async (): Promise<void> => {
            let hide = message.loading({
                type: 'loading',
                class: 'myMessage',
                content: <div className="myMessageBody">搜尋中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            const p1: any = state.飼主No;
            if (!p1) {
                _._debug.error('p1')
                alert('p1');
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/pg_clientmanage');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        pageDataManager.merge(payload);
                        pageDataManager.apply();

                        pageDataManager.forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPageData = async ({alwaysLoad = false}): Promise<void> => {
            if (!alwaysLoad) {
                pageDataManager.restore();
                if (!pageDataManager.isZoneExist(PrefixTarget)) {
                    await sessionManager.initSessionAuthAsync();
                    //#region User Check //TODO: VERTIFY
                    if (!sessionManager.isMemberLoggedIn()) {
                        Static.NavAwayToLogin(pageDataManager, navigate)(null);
                        return;
                    }
                    //#endregion
                    await _loadPageData();
                } else {
                    forceRender()
                }
            } else {
                await _loadPageData();
            }
        }

        refDoLoadPageData.current = doLoadPageData;
        refDoLoadPageData.current({});
    }, [location.pathname]);

    //#endregion


    const iconWaitforCase = require('../assets/images/icon/nocase.svg');
    const [noCase, setNoCase] = useState(false);

    function callback(key) {
        console.log(key);
    }

    function confirm(e) {
        console.log(e);
    }

    function cancel(e) {
        console.log(e)
    }


    //#region :Popup :createModalClientMessage
    const createModalClientMessage_refModal = useRef<any>(null);
    const createModalClientMessage = (e: any, tasks?: string[]): void => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_Modal_ClientMessage
                PrefixTarget={'Partial_Modal_ClientMessage'}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModalClientMessage_refModal,
                }}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '30em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModalClientMessage_refModal.current = Modal.info(config);
    }
    //#endregion
    //#region :Popup :createModalClientAnalysis
    const createModalClientAnalysis_refModal = useRef<any>(null);
    const createModalClientAnalysis = (e: any, {飼主No, tasks}: {
        飼主No: any,
        tasks?: string[]
    }): void => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_ClientManage__Modal_ClientAnalysis
                PrefixTarget={'Partial_ClientManage__Modal_ClientAnalysis'}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModalClientAnalysis_refModal,
                    飼主No,
                }}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '90em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModalClientAnalysis_refModal.current = Modal.info(config);
    }

    //#endregion

    interface PetItem {
        key: React.Key;
        relationship: string;
        petname: string;
        medicalnumber: string;
        type: string;
        gender: string;
        variety: string;
    }

    const petlist = [
        {
            title: '關係',
            dataIndex: 'relationship',
        },
        {
            title: '寵物姓名',
            dataIndex: 'petname',
            render: (text: string) => <Link to='/medicalrecord/1'>{text}</Link>,
        },
        {
            title: '病歷號碼',
            dataIndex: 'medicalnumber',
        },
        {
            title: '種類',
            dataIndex: 'type',
        },
        {
            title: '性別',
            dataIndex: 'gender',
        },
        {
            title: '品種',
            dataIndex: 'variety',
        },
        {
            title: '動作',
            dataIndex: ' function',
            render: (text, record) => {
                return <div>
                    <Button className="quick-order" type="primary"
                            onClick={Static.NavAwayToRecord(navigate, {No: record.寵物No})}
                    >打開病歷</Button>
                </div>
            },
        },
    ];

    const petData: PetItem[] = [
        {
            key: 1,
            relationship: '主聯絡人',
            petname: 'Lucky',
            medicalnumber: '0058214357',
            type: '狗',
            gender: '公',
            variety: '馬爾濟斯',
        },
        {
            key: 2,
            relationship: '主聯絡人',
            petname: '乖乖',
            medicalnumber: '1234343422',
            type: '貓',
            gender: '母',
            variety: '波斯貓',
        }
    ];


    // 掛號紀錄

    interface registerItem {
        key: React.Key;
        registerdate: string;
        registertime: string;
        title: string;
    }

    const registerCol = [
        {
            title: '日期',
            dataIndex: 'registerdate',
        },
        {
            title: '時間',
            dataIndex: 'registertime',
        },
        {
            title: '看診原因',
            dataIndex: 'reason',
        },
    ];

    const registerData: registerItem[] = [
        {
            key: 1,
            registerdate: '2021-01-28',
            registertime: '13:43',
            title: '打預防針'
        },
        {
            key: 2,
            registerdate: '2020-07-17',
            registertime: '10:23',
            title: '預約回診'
        }
    ];


    //#region Set Body
    const Body = () => {
        const RegionEmptyShow = false;
        const RegionLoadingShow = true;
        const RegionInitialLoadingShow = false;

        const PassPageDataInitalized = pageDataManager.refPageData.current != null;
        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;

        const List_掛號 = pageDataManager.get(`${PrefixTarget}.List_掛號`);
        const Item_飼主 = pageDataManager.get(`${PrefixTarget}.Item_飼主`);
        const List_寵物_飼主__關係 = pageDataManager.get(`${PrefixTarget}.List_寵物_飼主__關係`);

        let DataSource_掛號 = state.DataSource_掛號 = [];
        if (List_掛號 !== null) {
            let serialCounter = 0;
            DataSource_掛號 = List_掛號.map((Item_掛號) => {
                serialCounter += 1;

                if (serialCounter == 1) {
                }

                return {
                    entity: Item_掛號,
                    key: Item_掛號.掛號No,

                    registerdate: moment(Item_掛號.CreatedAt).format('YYYY-MM-DD'),
                    registertime: moment(Item_掛號.CreatedAt).format('hh:mm'),
                    reason: Item_掛號.看診原因 ?? "-",

                    // 大分類: Item_處理範本.大分類,
                    // 處理方法_中: Item_處理範本.處理方法_中,
                    // 處理方法_英: Item_處理範本.處理方法_英,
                    // 分類組: Item_處理範本.分類組,
                    // 學科組: Item_處理範本.學科組,
                }
            });

            state.DataSource_掛號 = DataSource_掛號;
        }

        let DataSource_寵物_飼主__關係 = state.DataSource_寵物_飼主__關係 = [];
        if (List_寵物_飼主__關係 !== null) {
            let serialCounter = 0;
            DataSource_寵物_飼主__關係 = List_寵物_飼主__關係.map((Item_寵物_飼主__關係) => {
                serialCounter += 1;

                if (serialCounter == 1) {
                }

                return {
                    entity: Item_寵物_飼主__關係,
                    key: Item_寵物_飼主__關係.寵物_飼主__關係No,

                    relationship: Item_寵物_飼主__關係.關係 ?? "-",
                    petname: Item_寵物_飼主__關係._寵物.寵物名稱 ?? "-",
                    medicalnumber: Item_寵物_飼主__關係._飼主.醫賴碼 ?? "-",
                    type: Item_寵物_飼主__關係._寵物.種類 ?? "-",
                    gender: Item_寵物_飼主__關係._寵物.性別 ?? "-",
                    variety: Item_寵物_飼主__關係._寵物.品種 ?? "-",

                    寵物No:  Item_寵物_飼主__關係.寵物No,
                    飼主No:  Item_寵物_飼主__關係.飼主No,
                }
            });

            state.DataSource_寵物_飼主__關係 = DataSource_寵物_飼主__關係;
        }


        if (!PassPrefixTargetLoaded) {
            if (RegionInitialLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <div>Loading.</div>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        if (loadingManager.getIsLoading()) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <Spin indicator={
                            <LoadingOutlined style={{fontSize: 24}} spin/>
                        }/>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        //#region Handlers
        //#endregion

        return (
            <div className="page-appointment page-content">
                <div className="info-list">
                    <div className="info-box">
                        <Title level={2}>顧客管理</Title>
                        <div className="pluginfobox">
                            <div className="title"></div>
                            <div className="info"></div>
                        </div>

                        <div className="pluginfobox">
                            <div className="title"></div>
                            <div className="info"></div>
                        </div>

                    </div>
                    <div className="function-box">
                        <button onClick={(e)=>{createModalClientAnalysis(e, {飼主No: Item_飼主.飼主No} )}}>
                            <FunctionBtn iconlink={iconProgress} name="會員分析"/>
                        </button>
                        {/*<button onClick={createModalClientMessage}>*/}
                        {/*    <FunctionBtn iconlink={iconMessage} name="傳訊息"/>*/}
                        {/*</button>*/}

                    </div>
                </div>


                <div className="page-waitingroom">
                    <div className="page-manage page_cm">
                        <div className="box-manage box-1 ">
                            <Title level={4}>飼主基本資料</Title>
                            <div className="systable col_4">
                                <div className="str">
                                    <div className="th">飼主資料</div>
                                    <div className="td">{Item_飼主.主人姓名  ?? "-"}</div>
                                </div>
                                <div className="str">
                                    <div className="th">身分證</div>
                                    <div className="td">{Item_飼主.身分證  ?? "-"}</div>
                                </div>
                                <div className="str">
                                    <div className="th">聯絡電話</div>
                                    <div className="td">{Item_飼主.聯絡電話  ?? "-"}</div>
                                </div>
                                <div className="str">
                                    <div className="th">手機</div>
                                    <div className="td">{Item_飼主.手機  ?? "-"}</div>
                                </div>
                                <div className="str cops">
                                    <div className="th">醫賴碼</div>
                                    <div className="td">{Item_飼主.醫賴碼  ?? "-"}</div>
                                </div>
                                <div className="str cops">
                                    <div className="th">Email</div>
                                    <div className="td">{Item_飼主.Email  ?? "-"}</div>
                                </div>
                                <div className="str cops">
                                    <div className="th">地址</div>
                                    <div className="td">{Item_飼主.地址  ?? "-"}</div>
                                </div>

                                <div className="str cops">
                                    <div className="th">備註</div>
                                    <div className="td">{Item_飼主.主人備註 ?? "-"}</div>
                                </div>
                            </div>
                        </div>
                        <div className="box-manage box-2">
                            <Space className='flex-col-between'>
                                <Title level={4}>掛號紀錄</Title>
                                {/*<Button type='link'>更多</Button>*/}
                            </Space>
                            <Table
                                columns={registerCol}
                                dataSource={DataSource_掛號}
                                scroll={{y: 130}}
                                size='small'
                                locale={{
                                    emptyText: <Empty description={
                                        <span>沒有相關資料～</span>
                                    }/>
                                }}
                            />
                        </div>
                        <div className="box-manage box-3">
                            <Title level={4}>寵物列表</Title>
                            <Table
                                columns={petlist}
                                dataSource={DataSource_寵物_飼主__關係}
                                scroll={{y: 130}}
                                size='small'
                                locale={{
                                    emptyText: <Empty description={
                                        <span>沒有相關資料～</span>
                                    }/>
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    };
    //#endregion

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}

Pg_ClientManage.displayName = 'Pg_ClientManage';










