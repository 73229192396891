import React, {useCallback, useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {
    Form as NForm,
    Popover,
    Button,
    Modal,
    message,
    Upload,
    Input,
    Select,
    Space,
    Tabs,
    Tooltip,
    Steps,
    Transfer,
    Table,
    Tag
} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';
import difference from 'lodash/difference';


import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";

import {LoadingOutlined, LockOutlined, PlusOutlined, RedoOutlined, CloseOutlined} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_addOwnerProxy from "./Partial_addOwnerProxy";
import Partial_Manage_Staff__Pane_EditOrAddStaff from "./Partial_Manage_Staff__Pane_EditOrAddStaff";
import UseCompState from "../hooks/UseCompState";


var moment = require('moment');
const {Option} = Select;
const {Search} = Input;
const onSearch = value => console.log(value);
const {TabPane} = Tabs;
const {Step} = Steps;

type ViewModelProps = {
    refModal: any,

    用戶No?: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,

    viewModel: ViewModelProps,
};
export default function Partial_Manage_Staff__Modal_EditOrAddStaff(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_Manage_Staff__Modal_EditOrAddStaff';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel: any = props.viewModel;

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region Set Body
    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };
    const isModalContained = () => {
        return !!viewModel.refModal?.current;
    }
    const Body = () => {

        return (
            <div className="model_wrapper">
                <div className="modal_title">
                    <div className="title_info">
                        <h2>編輯員工</h2>
                    </div>
                    <div className="info_bar"></div>
                    <div className="control_bar">
                        <Tooltip placement="top" title={'關閉'}>
                            <Button type="text" icon={<CloseOutlined/>} onClick={handleClose}/>
                        </Tooltip>
                    </div>
                </div>
                <div className="modal_content">
                    <div className="content_box cd_modal">
                        {viewModel.用戶No === null ?
                            <Partial_Manage_Staff__Pane_EditOrAddStaff
                                key="new"
                                //PrefixTarget={'Partial_Manage_Staff__Pane_EditOrAddStaff'}
                                pageDataManager={pageDataManager}
                                sessionManager={sessionManager}
                                navigate={navigate}

                                viewModel={{
                                    refModal: viewModel.refModal,

                                    用戶No: null,
                                }}
                            /> :
                            <Partial_Manage_Staff__Pane_EditOrAddStaff
                                //PrefixTarget={'Partial_Manage_Staff__Pane_EditOrAddStaff'}
                                pageDataManager={pageDataManager}
                                sessionManager={sessionManager}
                                navigate={navigate}

                                viewModel={{
                                    refModal: viewModel.refModal,

                                    用戶No: viewModel.用戶No,
                                }}
                            />
                        }
                    </div>
                </div>
                <div className="modal_footer">

                </div>
            </div>
        );
    };
    //#endregion

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_Manage_Staff__Modal_EditOrAddStaff.displayName = 'Partial_Manage_Staff__Modal_EditOrAddStaff';


