import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {Form as NForm, Popover, Button, Modal, message, Upload, Input, Space, Select} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";

import {LoadingOutlined, LockOutlined, PlusOutlined, RedoOutlined, UserOutlined} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_Appointment__Modal_AddPet from "./Partial_Appointment__Modal_AddPet";

var moment = require('moment');
const {Option} = Select;


type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,

    onDone?: any,
};
export default function Partial_Appointment__Pane_AddOwner(props: Props) {
    const PrefixTarget: string = props.PrefixTarget || 'Partial_Appointment__Pane_AddOwner';
    const pageDataManager: PageDataManager = props.pageDataManager;
    const sessionManager: SessionManager = props.sessionManager;
    const navigate: NavigateFunction = props.navigate;

    const layout = {
        labelCol: {span: 4},
        wrapperCol: {span: 20},
    };

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region Boot
    //#endregion

    const Body = () => {
        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //


            if (!values.主人姓名) {
                errors.主人姓名 = '↑ 欄位為必填';
            } else {
            }

            if (!values.手機) {
                errors.手機 = '↑ 欄位為必填';
            } else {
            }


            return errors;
        };

        const handleSubmit_keepOwner = (e: any, v: any) => {
            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            //#region Handle SaveUserInfo
            {
                // VALIDATE INPUT
                //const id : any = this.props.match.params.id;
                const p1 = v;
                if (!p1) {
                    _._debug.error(p1);
                    return; //ERROR INPUTS
                }

                // WAITING SCREEN
                loadingManager.changeLoadingCount(+1);
                //StaticEvent.LoadingEvent.next(true);

                // POST
                let postData = {
                    p1,
                    timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
                };
                const postUrl = ApiService.urlMapping('~/pet_api/1/_pg_appointment/keepowner');
                const post$ = Static.apiService.post(postUrl, postData);
                post$.subscribe(async (res: any) => {
                        hide();

                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);

                        let data = res.data;
                        let error = data.error || null;
                        let result = error == null;

                        if (result) { // SUCEESS
                            let payload = data.payload;
                            _._debug.warn(payload);


                            let carryData = Object.assign({},
                                {
                                    [PrefixTarget]: payload
                                }
                            );
                            if (props.onDone) {
                                const ret = props.onDone(carryData);
                            } else {
                                alert('onDone not defined');
                            }

                        } else {
                            // AUTH USER EXPIRED
                            if (error.code == 2) {
                                //alert('帳號被登出，請重新登入');
                                //window.location.href = '/login';
                                //this.props.history.push('/login')
                                await sessionManager.setLogout();
                                pageDataManager.clear();
                                pageDataManager.reloadAsync({alwaysLoad: true});
                                alert('時間逾時已被登出');
                                return;
                            }

                            _._debug.error(error);
                            alert(error.message);
                        }
                    }, async (err) => {
                        hide();

                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);
                        _._debug.warn(err);
                        alert('網路連線有問題');
                    }
                );
                return lastValueFrom(
                    post$
                );
            }
            //#endregion
        }

        return (
            <Form
                onSubmit={(v) => handleSubmit_keepOwner(null, v)}
                initialValues={{}}
                validate={handleValidate}
                render={({
                             submitError,
                             handleSubmit,
                             submitting,
                             pristine,
                             valid,
                             error,
                             values
                         }) => {

                    return <React.Fragment>
                        <div className="form_body">
                            <form
                                onSubmit={handleSubmit}
                                {...layout}>

                                <Field parse={_.bs.identity} name="主人姓名">
                                    {
                                        ({input, meta}) => (
                                            <React.Fragment>
                                                <NForm.Item {...input} {...layout}
                                                            name="主人姓名"
                                                            label="主人姓名"
                                                            rules={[{required: true, message: '請輸入姓名'}]}
                                                >

                                                    <Input {...input} placeholder="請輸入中英文" allowClear/>
                                                    {meta.touched && meta.error &&
                                                    <div className="error">
                                                        {meta.error}
                                                    </div>}
                                                </NForm.Item>
                                            </React.Fragment>
                                        )
                                    }
                                </Field>

                                <Field parse={_.bs.identity} name="手機">
                                    {
                                        ({input, meta}) => (
                                            <React.Fragment>
                                                <NForm.Item {...input} {...layout}
                                                            name="手機"
                                                            label="手機"
                                                            rules={[{required: true, message: '請輸入正確格式'}]}
                                                >
                                                    <Input {...input}
                                                           placeholder="請輸入手機號碼" allowClear/>
                                                    {meta.touched && meta.error &&
                                                    <div className="error">
                                                        {meta.error}
                                                    </div>}
                                                </NForm.Item>
                                            </React.Fragment>
                                        )
                                    }
                                </Field>

                                <Field parse={_.bs.identity} name="身分證">
                                    {
                                        ({input, meta}) => (
                                            <React.Fragment>
                                                <NForm.Item {...input} {...layout}
                                                            name="身分證"
                                                            label="身分證"

                                                >
                                                    <Input {...input}
                                                           placeholder="請輸入英文+9位數" allowClear/>
                                                    {meta.touched && meta.error &&
                                                    <div className="error">
                                                        {meta.error}
                                                    </div>}
                                                </NForm.Item>
                                            </React.Fragment>
                                        )
                                    }
                                </Field>

                                <Field parse={_.bs.identity} name="聯絡電話">
                                    {
                                        ({input, meta}) => (
                                            <React.Fragment>
                                                <NForm.Item {...input} {...layout}
                                                            name="聯絡電話"
                                                            label="聯絡電話"

                                                >
                                                    <Input {...input}
                                                           placeholder="請輸入電話號碼" allowClear/>
                                                    {meta.touched && meta.error &&
                                                    <div className="error">
                                                        {meta.error}
                                                    </div>}
                                                </NForm.Item>
                                            </React.Fragment>
                                        )
                                    }
                                </Field>
                                {/*
                                <Field parse={_.bs.identity} name="relationship">
                                    {
                                        ({ input, meta }) => (
                                            <React.Fragment>
                                                <NForm.Item {...input} {...layout}
                                                    name="relationship"
                                                    label="關係"
                                                >
                                                    <Select {...input} placeholder="請選擇關係" defaultValue="主飼主">
                                                        <Option value="主飼主">主飼主</Option>
                                                        <Option value="代理飼主">代理飼主</Option>
                                                    </Select>

                                                    {meta.touched && meta.error &&
                                                        <div className="error">
                                                            {meta.error}
                                                        </div>}
                                                </NForm.Item>
                                            </React.Fragment>
                                        )
                                    }
                                </Field> */}


                                <React.Fragment>
                                    <NForm.Item>
                                        <div className="form_footer">

                                            <Button type="primary" htmlType="submit" size="large"
                                                    onClick={handleSubmit}>下一步</Button>

                                        </div>
                                    </NForm.Item>
                                </React.Fragment>
                            </form>
                        </div>
                    </React.Fragment>;
                }}
            />
        );
    };

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_Appointment__Pane_AddOwner.displayName = 'Partial_Appointment__Pane_AddOwner';
