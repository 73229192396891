import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {
    Form as NForm,
    Popover,
    Button,
    Modal,
    message,
    Upload,
    Input,
    Select,
    Space,
    Table,
    Radio,
    Empty,
    InputNumber,
    Spin, Tag
} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";

import {LoadingOutlined, LockOutlined, PlusOutlined, RedoOutlined, UserOutlined} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";
import UseCompState from "../hooks/UseCompState";
import Ln_CommaListRender from "./Ln_CommaListRender";

var moment = require('moment');


type ViewModelProps = {
    refModal: any,

    大分類: any,

    OnDid?: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any;

    viewModel: ViewModelProps,
};
export default function Partial_MedicalRecord__Modal_PrescribeAdd_Items(props: Props) {
    const PrefixTarget: string = props.PrefixTarget || ('Partial_MedicalRecord__Modal_PrescribeAdd_Items' + props.viewModel.大分類);
    const pageDataManager: PageDataManager = props.pageDataManager;
    const sessionManager: SessionManager = props.sessionManager;
    const navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel = props.viewModel;

    const {Option} = Select;
    const {Search} = Input;
    const onSearch = value => console.log(value);
    const {TextArea} = Input;

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    //#region LoadingManager
    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});
    //#endregion

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

    }
    //#endregion

    //#region Boot
    let _refDoLoadPartialData: any = useRef<{ alwaysLoad?: boolean }>(null);
    useEffect(() => {
        const _loadPartialData = async (): Promise<void> => {

            // VALIDATE INPUT
            const p1 = viewModel.大分類;
            if (!p1) {
                alert('p1');
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/partial_medicalrecord/modal_prescribeadd');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        let changeShard = {
                            [PrefixTarget]: {
                                ...payload,
                            }
                        }
                        pageDataManager.merge(changeShard);
                        pageDataManager.apply();

                        forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPartialData = async ({alwaysLoad = false}): Promise<void> => {
            // if (!pageDataManager.refPageData.current.Root) {
            //     return;
            // }

            /// do not dynamically load data if condition fits.
            if (!alwaysLoad) {
                if (pageDataManager.isZoneExist(PrefixTarget)) {
                    return;
                }
            }

            await _loadPartialData();
        }

        _refDoLoadPartialData.current = doLoadPartialData;
        _refDoLoadPartialData.current({});
    }, [pageDataManager.get(PrefixTarget)]);
    //#endregion

    //#region Set Body
    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };
    const Body = () => {
        const RegionEmptyShow = false;
        const RegionLoadingShow = true;
        const RegionInitialLoadingShow = false;

        const PassPageDataInitalized = pageDataManager.refPageData.current != null;
        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;

        let DataSource_用藥範本 = state.DataSource_用藥範本 = [];
        let List_用藥範本 = pageDataManager.get(`${PrefixTarget}.List_用藥範本`);
        if (List_用藥範本 !== null) {
            let serialCounter = 0;
            DataSource_用藥範本 = List_用藥範本.map((Item_用藥範本) => {
                serialCounter += 1;

                if (serialCounter == 1) {
                }

                return {
                    entity: Item_用藥範本,
                    key: Item_用藥範本.用藥範本No,

                    分類組: Item_用藥範本.分類組,
                    品牌: Item_用藥範本.品牌,
                    品名: Item_用藥範本.品名,
                    庫存量: Item_用藥範本.庫存量,
                    貨品代號: Item_用藥範本.貨品代號,

                    份量: Item_用藥範本.份量,
                    用量: Item_用藥範本.用量,
                    藥劑處置: Item_用藥範本.藥劑處置,
                }
            });

            state.DataSource_用藥範本 = DataSource_用藥範本;
        }


        //#region Handlers
        const handleSubmit_bringBack = (e: any, v: any) => {
            // let hide = message.loading({
            //     type: 'loading',
            //     className: 'myMessage',
            //     content: <div className="myMessageBody">驗證中...</div>,
            //     key: 'loading',
            //     duration: 0
            // });

            let selected = v?.selected;

            if (selected?.length == 1) {
                selected[0] = {
                    ...selected[0],
                    份量: v.份量,
                    用量: v.用量,
                    藥劑處置: v.藥劑處置,
                }

                if (viewModel.OnDid) {
                    const ret = viewModel.OnDid(v);
                } else {
                    alert('onDone not defined');
                }
            } else {
                alert('錯誤選取，請選擇一項');
            }

            // //#region Handle SaveUserInfo
            // {
            //     // VALIDATE INPUT
            //     //const id : any = this.props.match.params.id;
            //     const p1 = v.selected;
            //     if (!p1) {
            //         _._debug.error(p1);
            //         return; //ERROR INPUTS
            //     }
            //
            //     // WAITING SCREEN
            //     loadingManager.changeLoadingCount(+1);
            //     //StaticEvent.LoadingEvent.next(true);
            //
            //     // POST
            //     let postData = {
            //         p1,
            //         timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            //     };
            //     const postUrl = ApiService.urlMapping('~/pet_api/1/_pg_appointment/keepowner');
            //     const post$ = Static.apiService.post(postUrl, postData);
            //     post$.subscribe(async (res: any) => {
            //             hide();
            //
            //             // CLOSE WAITING SCREEN
            //             loadingManager.changeLoadingCount(-1);
            //             //StaticEvent.LoadingEvent.next(false);
            //
            //             let data = res.data;
            //             let error = data.error || null;
            //             let result = error == null;
            //
            //             if (result) { // SUCEESS
            //                 let payload = data.payload;
            //                 _._debug.warn(payload);
            //
            //
            //                 let carryData = Object.assign({},
            //                     {
            //                         [PrefixTarget]: payload
            //                     }
            //                 );
            //                 if (viewModel.onDone) {
            //                     const ret = viewModel.onDone(carryData);
            //                 } else {
            //                     alert('onDone not defined');
            //                 }
            //
            //             } else {
            //                 // AUTH USER EXPIRED
            //                 if (error.code == 2) {
            //                     //alert('帳號被登出，請重新登入');
            //                     //window.location.href = '/login';
            //                     //this.props.history.push('/login')
            //                     await sessionManager.setLogout();
            //                     pageDataManager.clear();
            //                     pageDataManager.reloadAsync({alwaysLoad: true});
            //                     alert('時間逾時已被登出');
            //                     return;
            //                 }
            //
            //                 _._debug.error(error);
            //                 alert(error.message);
            //             }
            //         }, async (err) => {
            //             hide();
            //
            //             // CLOSE WAITING SCREEN
            //             loadingManager.changeLoadingCount(-1);
            //             //StaticEvent.LoadingEvent.next(false);
            //             _._debug.warn(err);
            //             alert('網路連線有問題');
            //         }
            //     );
            //     return lastValueFrom(
            //         post$
            //     );
            // }
            // //#endregion
        }

        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            // if (!values.username) {
            //     errors.username = '↑ 欄位為必填';
            // } else {
            // }
            //
            // if (!values.password) {
            //     errors.password = '↑ 欄位為必填';
            // } else {
            // }

            if (!values.用量) {
                errors.用量 = '↑ 欄位為必填';
            }

            if (!values.份量) {
                errors.份量 = '↑ 欄位為必填';
            }

            return errors;
        };

        const assistColumns = [
            {
                title: '分類',
                dataIndex: '分類組',
                render: (text, record) => Ln_CommaListRender({
                    照片組: text,
                    render: (tag) => (
                        <Tag key={tag}>{tag}</Tag>
                    )
                })
            },
            {
                title: '品牌',
                dataIndex: '品牌',
            },
            {
                title: '品名',
                dataIndex: '品名',
            },
            {
                title: '庫存量',
                dataIndex: '庫存量',
            },
            {
                title: '貨品代號',
                dataIndex: '貨品代號',
            },
        ];

        const productData = [
            {
                key: '1',
                tag: '營養補充',
                brand: '康德',
                product: '魷魚絲',
                inventory: '5',
                productID: 'DSC09023451'
            },
            {
                key: '2',
                tag: '營養補充',
                brand: '康德',
                product: '魷魚絲',
                inventory: '5',
                productID: 'DSC09023451'
            },
            {
                key: '3',
                tag: '營養補充',
                brand: '康德',
                product: '魷魚絲',
                inventory: '5',
                productID: 'DSC09023451'
            },
            {
                key: '4',
                tag: '營養補充',
                brand: '康德',
                product: '魷魚絲',
                inventory: '5',
                productID: 'DSC09023451'
            },
        ]

        interface DataType {
            key: React.Key;
            name: string;
            id: string;
            cellphone: string;
            tel: string;
        }

        const data: DataType[] = [
            {
                key: '1',
                name: '陳忠志',
                id: 'A121345678',
                cellphone: '0922343676',
                tel: '02-27334387',
            },
            {
                key: '2',
                name: '蔡贏安',
                id: 'A221345678',
                cellphone: '0922343676',
                tel: '02-27334387',
            },
            {
                key: '3',
                name: '余瑞妍',
                id: 'A221345678',
                cellphone: '0922343676',
                tel: '02-27334387',
            },
            {
                key: '4',
                name: '王芯儀',
                id: 'A221345678',
                cellphone: '0922343676',
                tel: '02-27334387',
            },
        ];

        const handleSubmit_applyNewSearchCondition = (e: any, v: any) => {
            state.q = v.q;

            pageDataManager.emptyZone(PrefixTarget);
            pageDataManager.apply();

            forceRender();
            //pageDataManager.reloadAsync({alwaysLoad: true});
        }
        const layout = {
            labelCol: {span: 3},
            wrapperCol: {span: 25},
        };

        const key = 'updatable';

        const openMessage = () => {
            message.loading({content: '添加中...', key});
            setTimeout(() => {
                message.success({content: '添加成功!', key, duration: 2});
            }, 1000);
        };
        //#endregion


        if (!PassPrefixTargetLoaded) {
            if (RegionInitialLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <div>Loading.</div>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        if (loadingManager.getIsLoading()) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <Spin indicator={
                            <LoadingOutlined style={{fontSize: 24}} spin/>
                        }/>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }


        return (
            <Form
                onSubmit={(v) => handleSubmit_bringBack(null, v)}
                initialValues={{
                    q: state.q,
                }}
                validate={handleValidate}
                render={({
                             submitError,
                             handleSubmit,
                             submitting,
                             pristine,
                             valid,
                             error,
                             values
                         }) => {


                    return <React.Fragment>
                        <div className="form_body">
                            <form onSubmit={handleSubmit}>

                                <Field parse={_.bs.identity} name='q'>
                                    {
                                        ({input, meta}) => (
                                            <React.Fragment>

                                                <NForm.Item
                                                >
                                                    <Input.Group compact className='col2'>
                                                        {/* <NForm.Item {...input}
                                                            name="sterilization"

                                                        >
                                                            <Select mode="tags" style={{ width: '100%' }} placeholder="選擇分類" >
                                                                <Option value="抗發炎">抗發炎</Option>
                                                                <Option value="營養補充">營養補充</Option>
                                                                <Option value="手術">手術</Option>
                                                                <Option value="寄生蟲">寄生蟲</Option>
                                                                <Option value="營養">營養</Option>
                                                                <Option value="術後">術後</Option>
                                                            </Select>
                                                        </NForm.Item> */}
                                                        <NForm.Item {...input}
                                                                    name="sterilizationdate"

                                                        >
                                                            <Search placeholder="輸入品牌、品名" enterButton/>

                                                        </NForm.Item>

                                                    </Input.Group>
                                                </NForm.Item>
                                            </React.Fragment>
                                        )
                                    }
                                </Field>
                                <div className="searchresult">
                                    <Field parse={_.bs.identity} name="selected">
                                        {
                                            ({input, meta}) => (
                                                <Table
                                                    rowSelection={{
                                                        type: 'radio',
                                                        onChange: (idx, rows) => {
                                                            if (rows.length > 0) {
                                                                input.onChange(rows.map((r: any) => r.entity));
                                                            }
                                                        }
                                                    }}
                                                    columns={assistColumns}
                                                    dataSource={DataSource_用藥範本}
                                                    size='small'
                                                    locale={{
                                                        emptyText: <Empty description={
                                                            <span>沒有搜尋到相關資料～</span>
                                                        }/>
                                                    }}
                                                />
                                            )
                                        }
                                    </Field>


                                </div>
                                {/*<div className="infocontent graybk col_th">*/}
                                {/*    <div className="infobox">*/}
                                {/*    <div className="info">*/}
                                {/*        <div className="th">品名:</div>*/}
                                {/*        <div className="content">品名描述</div>*/}
                                {/*    </div>*/}
                                {/*    <div className="info">*/}
                                {/*        <div className="th">品牌名稱:</div>*/}
                                {/*        <div className="content">品牌名稱</div>*/}
                                {/*    </div>*/}
                                {/*    <div className="info">*/}
                                {/*        <div className="th">說明:</div>*/}
                                {/*        <div className="content">說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字</div>*/}
                                {/*    </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                <Field parse={_.bs.identity} name="用量">
                                    {
                                        ({input, meta}) => (
                                            <React.Fragment>
                                                <NForm.Item {...input} {...layout}
                                                            name="用量"
                                                            label="用量:"
                                                            rules={[{required: true, message: '必填'}]}
                                                >

                                                    <Input {...input} placeholder="請輸入用量"/>
                                                    {meta.touched && meta.error &&
                                                    <div className="error">
                                                        {meta.error}
                                                    </div>}
                                                </NForm.Item>
                                            </React.Fragment>
                                        )
                                    }
                                </Field>

                                <Field parse={_.bs.identity} name="份量">
                                    {
                                        ({input, meta}) => (
                                            <React.Fragment>
                                                <NForm.Item {...input} {...layout}
                                                            name="份量"
                                                            label="份量:"
                                                            rules={[{required: true, message: '必填'}]}
                                                >

                                                    <InputNumber min={1} defaultValue={1} {...input} />
                                                    {meta.touched && meta.error &&
                                                    <div className="error">
                                                        {meta.error}
                                                    </div>}
                                                </NForm.Item>
                                            </React.Fragment>
                                        )
                                    }
                                </Field>

                                <Field parse={_.bs.identity} name="藥劑處置">
                                    {
                                        ({input, meta}) => (
                                            <React.Fragment>
                                                <NForm.Item {...input} {...layout}
                                                            name="藥劑處置"
                                                            label="藥劑處置:"
                                                            //rules={[{required: true, message: '必填'}]}
                                                >

                                                    <TextArea {...input} rows={6} placeholder="輸入給藥師的藥劑處置" />
                                                    {meta.touched && meta.error &&
                                                    <div className="error">
                                                        {meta.error}
                                                    </div>}
                                                </NForm.Item>
                                            </React.Fragment>

                                        )
                                    }
                                </Field>

                                {/* <Field parse={_.bs.identity} name="醫囑">
                                    {
                                        ({ input, meta }) => (
                                            <React.Fragment>
                                                <NForm.Item {...input} {...layout}
                                                    name="醫囑"
                                                    label="醫囑:"

                                                >

                                                    <TextArea rows={1} placeholder="輸入給飼主的醫囑" />

                                                </NForm.Item>
                                            </React.Fragment>
                                        )
                                    }
                                </Field> */}


                            </form>

                            <React.Fragment>
                                <NForm.Item>
                                    <div className="form_footer">
                                        <Button type="primary" htmlType="submit" size="large" onClick={handleSubmit}
                                        >添加</Button>
                                    </div>
                                </NForm.Item>
                            </React.Fragment>

                        </div>
                    </React.Fragment>;
                }}
            />
        );
    };

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_MedicalRecord__Modal_PrescribeAdd_Items.displayName = 'Partial_MedicalRecord__Modal_PrescribeAdd_Items';
