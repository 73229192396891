import React, {useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {
    Form as NForm,
    Popover,
    Button,
    Modal,
    InputNumber,
    message,
    Tag,
    Table,
    Empty,
    Input,
    Select,
    Space,
    Tabs,
    Tooltip,
    Steps
} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';


import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";
import {PlusCircleOutlined, DeleteFilled, EditOutlined, EyeFilled, CloseOutlined} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";
import UseCompState from "../hooks/UseCompState";
import Ln_CommaListRender from "./Ln_CommaListRender";


var moment = require('moment');
const {Option} = Select;
const {Search} = Input;
const onSearch = value => console.log(value);
const {TabPane} = Tabs;
const {Step} = Steps;

type ViewModelProps = {
    refModal: any,

    住院籠子No: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any,

    viewModel: ViewModelProps,
};
export default function Partial_inHospital__Modal_ViewRoom(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_inHospital__Modal_ViewRoom';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel = props.viewModel;

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion


    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

    }
    //#endregion

    //#region Boot
    let _refDoLoadPartialData: any = useRef<{ alwaysLoad?: boolean }>(null);
    useEffect(() => {
        const _loadPartialData = async (): Promise<void> => {

            // VALIDATE INPUT
            //const id : any = this.props.match.params.id;
            const p1 = viewModel.住院籠子No;
            if (!p1) {
                alert('p1');
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/partial_inhospital/modal_viewroom');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        let changeShard = {
                            [PrefixTarget]: {
                                ...payload,
                            }
                        }
                        pageDataManager.merge(changeShard);
                        pageDataManager.apply();

                        forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPartialData = async ({alwaysLoad = false}): Promise<void> => {
            // if (!pageDataManager.refPageData.current.Root) {
            //     return;
            // }

            /// do not dynamically load data if condition fits.
            if (!alwaysLoad) {
                if (pageDataManager.isZoneExist(PrefixTarget)) {
                    return;
                }
            }

            await _loadPartialData();
        }

            _refDoLoadPartialData.current = doLoadPartialData;
            _refDoLoadPartialData.current({});
    }, [pageDataManager.get(PrefixTarget)]);
    //#endregion


    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };

    const Body = () => {
        const RegionEmptyShow = true;
        const RegionLoadingShow = true;

        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;

        let Item_住院籠子 = pageDataManager.get(`${PrefixTarget}.Item_住院籠子`);


        //#region Handlers
        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            if (!values.username) {
                errors.username = '↑ 欄位為必填';
            } else {
            }

            if (!values.password) {
                errors.password = '↑ 欄位為必填';
            } else {
            }

            return errors;
        };

        const handleClose = (e) => {
            if (e) {
                e.preventDefault();
            }

            if (viewModel.refModal || viewModel.refModal.current) {
                viewModel.refModal.current.destroy();
            }
        };


        //#endregion
        if (!PassPrefixTargetLoaded) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <div>Loading.</div>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        if (Item_住院籠子 === null) {
            if (RegionEmptyShow) {
                return <React.Fragment>
                    <div className="d3">
                        <div>Empty.</div>
                    </div>
                </React.Fragment>;
            }
        }

        return (
            <div className="model_wrapper">
                <div className="modal_title">
                    <div className="title_info">
                        <h2>籠子檢視</h2>
                    </div>
                    <div className="info_bar"></div>
                    <div className="control_bar">
                        <Tooltip placement="top" title={'關閉'}>
                            <Button type="text" icon={<CloseOutlined/>} onClick={handleClose}/>
                        </Tooltip>
                    </div>
                </div>
                <div className="modal_content">
                    <div className="content_box">
                        <div className="modal_content">
                            <div className="content_box">
                                <div className="boxcontent">
                                    <div className="systable col_4">
                                        <div className="str cops">
                                            <div className="th">區域</div>
                                            <div className="td">{Item_住院籠子.區域籠號}</div>
                                        </div>
                                        {/*<div className="str cops">*/}
                                        {/*    <div className="th">標籤</div>*/}
                                        {/*    <div className="td">*/}
                                        {/*        {Ln_CommaListRender({*/}
                                        {/*            照片組: Item_票劵.籠號,*/}
                                        {/*            render: (tag) => (*/}
                                        {/*                <Tag color="orange" key={tag}>{tag}</Tag>*/}
                                        {/*            )*/}
                                        {/*        })}*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal_footer"></div>
            </div>
        );

        return null;
    };

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_inHospital__Modal_ViewRoom.displayName = 'Partial_inHospital__Modal_ViewRoom';
