import { OnInitialize } from 'overmind';


export const onInitialize: OnInitialize = (
  { state, actions, effects }
) => {
  state.colorList = effects.loginApi.getPetColorData();
  state.specieList = effects.speciestorage.importData();
  state.breedList = effects.breedstorage.importData();
};

// 有session. 先確認DB是否有此人, 是否是是第一次登入?
// => 如果沒有  則需請院長新增 
// => 如果有 => 是第一次登入(可能是院長建立的) 先跳到設定基本資料那
//          => 不是第一次登入