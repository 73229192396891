import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {
    Form as NForm,
    Popover,
    Button,
    Modal,
    message,
    Upload,
    Input,
    Space,
    Select,
    Radio,
    DatePicker,
    InputNumber
} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";

import {
    LoadingOutlined,
    LockOutlined,
    PlusOutlined,
    RedoOutlined,
    UploadOutlined,
    UserOutlined
} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_Appointment__Modal_Preview from "./Partial_Appointment__Modal_Preview";
import update from "immutability-helper";
import Ln_AvatorImgHref from "./Ln_AvatorImgHref";
import ImgCrop from "antd-img-crop";
import UseCompState from "../hooks/UseCompState";

var moment = require('moment');
const {Option} = Select;

type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any,

    onDone?: any,
    onRequestClose?: any,
};
const dateFormat = 'YYYY/MM/DD';

export default function Partial_Appointment__Pane_AddPet(props: Props) {
    const PrefixTarget: string = props.PrefixTarget || 'Partial_Appointment__Pane_AddOwner';
    const pageDataManager: PageDataManager = props.pageDataManager;
    const sessionManager: SessionManager = props.sessionManager;
    const navigate: NavigateFunction = props.navigate;

    const layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 16},
    };

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

    }
    //#endregion

    //#region Boot
    useEffect(() => {
        const _load = async (): Promise<void> => {
            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">讀取中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            //const id : any = this.props.match.params.id;
            // const id = 1;
            // if (!id) {
            //     return; //ERROR INPUTS
            // }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                //p1: 1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/partial_appointment/pane_addpet');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        let changeShard = {
                            [PrefixTarget]: {
                                ...payload,
                            }
                        }
                        pageDataManager.merge(changeShard);
                        pageDataManager.apply();

                        forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const _doLoadPartialData = async (): Promise<void> => {
            if (!pageDataManager.refPageData.current.Root) {
                return;
            }

            /// do not dynamically load data if condition fits.
            if (pageDataManager.isZoneExist(PrefixTarget)) {
                return;
            }

            // if (pageDataManager.refPageData.current[PrefixTarget_TabControl].activeTabName === 'MyCollectionTab') {
            //     await _loadPageData();
            // }
            await _load();
        }
        _doLoadPartialData();
    }, []);
    //#endregion

    const Body = () => {
        const PassPageDataInitalized = pageDataManager.refPageData.current != null;
        const PassHasComponentData = pageDataManager.get(`${PrefixTarget}`) !== null;
        if (!PassPageDataInitalized) {
            return null;
        }
        if (!PassHasComponentData) {
            return null;
        }

        const List_醫生 = pageDataManager.get(`${PrefixTarget}.List_醫生`);
        let docOptions: any = [];
        {
            if (List_醫生) {
                docOptions = List_醫生.map((r) => {
                    return {
                        key: r.醫生No,
                        value: r.醫生No,
                        label: `${r.名稱} 醫師`,
                    }
                });
                docOptions.unshift({
                    key: '0',
                    value: '',
                    label: '不指定',
                })
            }
        }

        // const props = {
        //     name: 'file',
        //     action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        //     headers: {
        //         authorization: 'authorization-text',
        //     },
        //     onChange(info) {
        //         if (info.file.status !== 'uploading') {
        //             console.log(info.file, info.fileList);
        //         }
        //         if (info.file.status === 'done') {
        //             message.success(`${info.file.name} file uploaded successfully`);
        //         } else if (info.file.status === 'error') {
        //             message.error(`${info.file.name} file upload failed.`);
        //         }
        //     },
        // };

        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            // if (!values.ownername) {
            //     errors.ownername = '↑ 欄位為必填';
            // } else {
            // }
            //
            // if (!values.cellphone) {
            //     errors.cellphone = '↑ 欄位為必填';
            // } else {
            // }

            return errors;
        };


        const handleSubmit_keepPet = (e: any, v: any) => {
            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            //#region Handle SaveUserInfo
            {
                // VALIDATE INPUT
                //const id : any = this.props.match.params.id;
                const p1 = v;
                if (!p1) {
                    _._debug.error(p1);
                    return; //ERROR INPUTS
                }

                // WAITING SCREEN
                loadingManager.changeLoadingCount(+1);
                //StaticEvent.LoadingEvent.next(true);

                // POST
                let postData = {
                    p1,
                    timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
                };
                const postUrl = ApiService.urlMapping('~/pet_api/1/_pg_appointment/keeppet');
                const post$ = Static.apiService.post(postUrl, postData);
                post$.subscribe(async (res: any) => {
                        hide();


                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);

                        let data = res.data;
                        let error = data.error || null;
                        let result = error == null;

                        if (result) { // SUCEESS
                            let payload = data.payload;
                            _._debug.warn(payload);

                            let carryData = Object.assign({},
                                {
                                    [PrefixTarget]: payload
                                }
                            );
                            if (props.onDone) {
                                const ret = props.onDone(carryData);
                            } else {
                                alert('onDone not defined');
                            }

                        } else {
                            // AUTH USER EXPIRED
                            if (error.code == 2) {
                                //alert('帳號被登出，請重新登入');
                                //window.location.href = '/login';
                                //this.props.history.push('/login')
                                await sessionManager.setLogout();
                                pageDataManager.clear();
                                pageDataManager.reloadAsync({alwaysLoad: true});
                                alert('時間逾時已被登出');
                                return;
                            }

                            _._debug.error(error);
                            alert(error.message);
                        }
                    }, async (err) => {
                        hide();

                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);
                        _._debug.warn(err);
                        alert('網路連線有問題');
                    }
                );
                return lastValueFrom(
                    post$
                );
            }
            //#endregion
        }


        //#region :Comp nsUploader2 :VER-1
        if (!state.nsUploader2) {
            const obj: any = state.nsUploader2 = {};
            obj.getBase64 = (file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = error => reject(error);
                });
            }

            obj.uploadButton = ({text}) => (
                <div>
                    {false ? <LoadingOutlined/> : <PlusOutlined/>}
                    <div style={{marginTop: 8}}>{text}</div>
                </div>
            );

            // obj.handleCancel = () => {
            //     zoneState.state = {
            //         ...zoneState.state,
            //         ...{previewVisible: false}
            //     };
            // }

            obj.beforeUpload = (file) => {
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJpgOrPng) {
                    message.error('You can only upload JPG/PNG file!');
                }
                const isLt2M = file.size / 1024 / 1024 < 20;
                if (!isLt2M) {
                    message.error('Image must smaller than 20MB!');
                }
                return isJpgOrPng && isLt2M;
            }

            // obj.previewFile= (file) => {
            //     debugger;
            // };

            obj.handlePreview = async file => {
                let urls = obj.compState.files.filter(r => r.id != file.id).map(r => ApiService.urlImageMapping("~/" + r.url));
                urls.unshift(ApiService.urlImageMapping("~/" + file.url));
                Static.App.setImagesAndOpen(urls);

                //
                // if (!file.url && !file.preview) {
                //     file.preview = await getBase64(file.originFileObj);
                // }
                //
                // obj.compState = {
                //     ...obj.compState,
                //     ...{
                //         previewImage: file.url || file.preview,
                //         previewVisible: true,
                //         previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
                //     }
                // };

                // const onPreview = async file => {
                //     let src = file.url;
                //     if (!src) {
                //         src = await new Promise(resolve => {
                //             const reader = new FileReader();
                //             reader.readAsDataURL(file.originFileObj);
                //             reader.onload = () => resolve(reader.result);
                //         });
                //     }
                //     const image = new Image();
                //     image.src = src;
                //     const imgWindow = window.open(src);
                //     if (imgWindow) {
                //         imgWindow.document.write(image.outerHTML);
                //     } else {
                //         window.location.href = src;
                //     }
                // };
            };

            obj.handleChange = ({fileList, wrapperOnChange, ...rest}) => {
                obj.compState = {
                    ...obj.compState,
                    ...{
                        fileList
                    }
                };

                //debugger;

                try {
                    if (rest.file.status === 'done') {
                        if (rest.file.response.error) {
                            throw rest.file.response.error;
                        }

                        let serverFileInfo = rest.file.response.files[0];
                        let antFileInfo = {
                            url: serverFileInfo.url,
                            filename: serverFileInfo.name,
                            size: serverFileInfo.size,
                            id: serverFileInfo.id,
                            type: serverFileInfo.type,
                            thumbUrl: serverFileInfo.url,
                        };
                        obj.compState.files = [...obj.compState.files, antFileInfo];
                        //Object.assign(fileList.filter(r=>r.uid == rest.file.uid)[0], antFileInfo);
                        //forceRender();
                        fileList = fileList.map(file => {
                            if (file.response) {
                                // Component will show file.url as link
                                file.url = file.response?.files?.[0]?.url;
                            }
                            return file;
                        });

                        wrapperOnChange(obj.compState.files.map(r => r.id).join(','));
                    }

                    if (rest.file.status === 'removed') {
                        obj.compState.files = obj.compState.files.filter(item => item.id != rest.file.id)
                        wrapperOnChange(obj.compState.files.map(r => r.id).join(','));
                    }
                } catch (error) {
                    // AUTH USER EXPIRED T:2
                    if (error.code == 2) {
                        (async () => {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                        })();
                    } else {
                        alert(error.message);
                    }
                }

                forceRender();
            }

            obj.addData = (file) => {
                return {a: '123'};
            };


            let compState: any = obj.compState = {};
            compState = Object.assign(compState, {
                files: [],
                previewVisible: false,
                previewImage: '',
                previewTitle: '',
                hasSetFileList: false,
                fileList: [
                    // {
                    //     uid: '-1',
                    //     name: 'image.png',
                    //     status: 'done',
                    //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                    // },
                    // {
                    //     uid: '-2',
                    //     name: 'image.png',
                    //     status: 'done',
                    //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                    // },
                    // {
                    //     uid: '-3',
                    //     name: 'image.png',
                    //     status: 'done',
                    //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                    // },
                    // {
                    //     uid: '-4',
                    //     name: 'image.png',
                    //     status: 'done',
                    //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                    // },
                    // {
                    //     uid: '-xxx',
                    //     percent: 50,
                    //     name: 'image.png',
                    //     status: 'uploading',
                    //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                    // },
                    // {
                    //     uid: '-5',
                    //     name: 'image.png',
                    //     status: 'error',
                    // },
                ] as any,
            });


            // if (viewModel.carryData) {
            //     if (viewModel.carryData.type == 'pictures') {
            //         //0: {url: "/FileTransferHandler.ashx?f=4915a7dd3f1c420b9175f38c2a76e4ac.jpg", filename: "4915a7dd3f1c420b9175f38c2a76e4ac.jpg", size: 159626, id: "/Upload/4915a7dd3f1c420b9175f38c2a76e4ac.jpg", type: "image/jpeg"}
            //
            //
            //         let count = 0;
            //         let files: any = [];
            //         state.files = viewModel.carryData.data.pictures;
            //         zoneState.state.fileList = state.files.map((it) => {
            //             count++;
            //
            //             // minimum requirement for antFileInfos [
            //             //     {
            //             //         uid: '-1',
            //             //         name: 'image.png',
            //             //         status: 'done',
            //             //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
            //             //     },
            //             // ];
            //
            //             let antFileInfo = {
            //                 uid: it.id,
            //                 status: 'done',
            //
            //                 url: it.url,
            //                 name: it.filename,
            //                 size: it.size,
            //                 id: it.id,
            //                 type: it.type,
            //             };
            //             return antFileInfo;
            //         });
            //     }
            // }
        }
        //#endregion

        return (
            <Form
                onSubmit={(v) => handleSubmit_keepPet(null, v)}
                initialValues={{}}
                validate={handleValidate}
                render={({
                             submitError,
                             handleSubmit,
                             submitting,
                             pristine,
                             valid,
                             error,
                             values
                         }) => {

                    return <React.Fragment>

                        <div className="form_body col_3">
                            <form
                                onSubmit={handleSubmit}
                                {...layout}>
                                <div className="col_group">
                                    <div className="col">
                                        <Field parse={_.bs.identity} name="寵物名稱">
                                            {
                                                ({input, meta}) => (
                                                    <React.Fragment>
                                                        <NForm.Item {...input} {...layout}
                                                                    name="寵物名稱"
                                                                    label="寵物名稱:"
                                                                    rules={[{required: true, message: '請輸入姓名'}]}
                                                        >

                                                            <Input {...input} placeholder="請輸入姓名" allowClear/>
                                                            {meta.touched && meta.error &&
                                                            <div className="error">
                                                                {meta.error}
                                                            </div>}
                                                        </NForm.Item>
                                                    </React.Fragment>
                                                )
                                            }
                                        </Field>

                                        <Field parse={_.bs.identity} name="種類">
                                            {
                                                ({input, meta}) => (
                                                    <React.Fragment>
                                                        <NForm.Item {...input} {...layout}
                                                                    name="種類"
                                                                    label="種類:"
                                                                    rules={[{required: true, message: '請輸入正確格式'}]}
                                                        >

                                                            <Select {...input} placeholder="請選擇種類">
                                                                <Option value="狗">狗</Option>
                                                                <Option value="貓">貓</Option>
                                                            </Select>
                                                            {meta.touched && meta.error &&
                                                            <div className="error">
                                                                {meta.error}
                                                            </div>}
                                                        </NForm.Item>
                                                    </React.Fragment>
                                                )
                                            }
                                        </Field>

                                        <Field parse={_.bs.identity} name="性別">
                                            {
                                                ({input, meta}) => (
                                                    <React.Fragment>
                                                        <NForm.Item {...input} {...layout}
                                                                    name="性別"
                                                                    label="性別:"
                                                                    rules={[{required: true, message: '請選擇'}]}
                                                        >

                                                            <Radio.Group {...input} defaultValue="公"
                                                                         buttonStyle="solid">
                                                                <Radio.Button value="公">公</Radio.Button>
                                                                <Radio.Button value="母">母</Radio.Button>
                                                            </Radio.Group>
                                                            {meta.touched && meta.error &&
                                                            <div className="error">
                                                                {meta.error}
                                                            </div>}
                                                        </NForm.Item>
                                                    </React.Fragment>
                                                )
                                            }
                                        </Field>

                                        <Field parse={_.bs.identity} name="毛色">
                                            {
                                                ({input, meta}) => (
                                                    <React.Fragment>
                                                        <NForm.Item {...input} {...layout}
                                                                    name="毛色"
                                                                    label="毛色:"

                                                        >
                                                            <Select {...input} placeholder="請選擇毛色">
                                                                <Option value="白色">白色</Option>
                                                                <Option value="黑色">黑色</Option>
                                                                <Option value="灰色">灰色</Option>
                                                                <Option value="橘色">橘色</Option>
                                                                <Option value="褐色">褐色</Option>
                                                                <Option value="虎斑">虎斑</Option>
                                                                <Option value="褐色及白色">褐色及白色</Option>
                                                                <Option value="灰色及白色">灰色及白色</Option>
                                                                <Option value="黑色及白色">黑色及白色</Option>
                                                                <Option value="乳黃色">乳黃色</Option>
                                                                <Option value="淡褐色">淡褐色</Option>
                                                                <Option value="淡紫色">淡紫色</Option>
                                                                <Option value="紅褐色">紅褐色</Option>
                                                            </Select>
                                                            {meta.touched && meta.error &&
                                                            <div className="error">
                                                                {meta.error}
                                                            </div>}
                                                        </NForm.Item>
                                                    </React.Fragment>
                                                )
                                            }
                                        </Field>

                                        <Field parse={_.bs.identity} name="品種">
                                            {
                                                ({input, meta}) => (
                                                    <React.Fragment>
                                                        <NForm.Item {...input} {...layout}
                                                                    name="品種"
                                                                    label="品種:"

                                                        >
                                                            <Select {...input} placeholder="請選擇品種">
                                                                {values.種類 === '狗' && <React.Fragment>
                                                                    <Option value="米客思">米客思</Option>
                                                                    <Option value="貴賓犬">貴賓犬</Option>
                                                                    <Option value="柴犬">柴犬</Option>
                                                                    <Option value="柯基犬">柯基犬</Option>
                                                                    <Option value="約克夏犬">約克夏犬</Option>
                                                                    <Option value="邊界獵犬">邊界獵犬</Option>
                                                                    <Option value="邊界牧羊犬">邊界牧羊犬</Option>
                                                                    <Option value="美國可卡犬">美國可卡犬</Option>
                                                                    <Option value="美國獵狐犬">美國獵狐犬</Option>
                                                                    <Option value="巴吉生犬">巴吉生犬</Option>
                                                                    <Option value="巴吉度獵犬">巴吉度獵犬</Option>
                                                                    <Option value="伯恩山犬">伯恩山犬</Option>
                                                                    <Option value="比雄犬">比雄犬</Option>
                                                                </React.Fragment>}
                                                                {values.種類 === '貓' && <React.Fragment>
                                                                    <Option value="美國捲耳短毛貓">美國捲耳短毛貓</Option>
                                                                    <Option value="美國短毛貓">美國短毛貓</Option>
                                                                    <Option value="美國硬毛貓">美國硬毛貓</Option>
                                                                    <Option value="安哥拉貓">安哥拉貓</Option>
                                                                    <Option value="土耳其梵貓">土耳其梵貓</Option>
                                                                    <Option value="峇里貓">峇里貓</Option>
                                                                    <Option value="孟加拉貓">孟加拉貓</Option>
                                                                    <Option value="伯曼貓">伯曼貓</Option>
                                                                    <Option value="英國短毛貓">英國短毛貓</Option>
                                                                    <Option value="挪威森林貓">挪威森林貓</Option>
                                                                    <Option value="暹邏貓">暹邏貓</Option>
                                                                    <Option value="孟買貓">孟買貓</Option>
                                                                </React.Fragment>
                                                                }
                                                            </Select>
                                                            {meta.touched && meta.error &&
                                                            <div className="error">
                                                                {meta.error}
                                                            </div>}
                                                        </NForm.Item>
                                                    </React.Fragment>
                                                )
                                            }
                                        </Field>

                                        <Field parse={_.bs.identity} name="出生日">
                                            {
                                                ({input, meta}) => (
                                                    <React.Fragment>
                                                        <NForm.Item {...input} {...layout}
                                                                    name="出生日"
                                                                    label="出生日:"

                                                        >
                                                            <DatePicker format={dateFormat}
                                                                        placeholder="請選擇日期" {
                                                                            ...{
                                                                                onChange: (moment) => {
                                                                                    if (moment) {
                                                                                        input.onChange(moment.toDate());
                                                                                    }
                                                                                }
                                                                            }
                                                                        } />
                                                            {meta.touched && meta.error &&
                                                            <div className="error">
                                                                {meta.error}
                                                            </div>}
                                                        </NForm.Item>
                                                    </React.Fragment>
                                                )
                                            }
                                        </Field>
                                    </div>
                                    <div className="col">
                                        <Field parse={_.bs.identity} name="口述體重">
                                            {
                                                ({input, meta}) => (
                                                    <React.Fragment>
                                                        <NForm.Item {...input} {...layout}
                                                                    name="口述體重"
                                                                    label="口述體重:"

                                                        >
                                                            <InputNumber min={1} {...input}
                                                                         placeholder="體重"/>
                                                            <span> Kg</span>
                                                            {meta.touched && meta.error &&
                                                            <div className="error">
                                                                {meta.error}
                                                            </div>}
                                                        </NForm.Item>
                                                    </React.Fragment>
                                                )
                                            }
                                        </Field>
                                        <Field parse={_.bs.identity} name="節育">
                                            {
                                                ({input, meta}) => (
                                                    <React.Fragment>
                                                        <NForm.Item {...input} {...layout}
                                                                    name="節育"
                                                                    label="節育:"

                                                        >
                                                            <Select {...input} placeholder="請選擇類別">
                                                                <Option value="已節育">已節育</Option>
                                                                <Option value="未節育">未節育</Option>
                                                            </Select>
                                                            {meta.touched && meta.error &&
                                                            <div className="error">
                                                                {meta.error}
                                                            </div>}
                                                        </NForm.Item>
                                                    </React.Fragment>
                                                )
                                            }
                                        </Field>
                                        <Field parse={_.bs.identity} name="晶片種類">
                                            {
                                                ({input, meta}) => (
                                                    <React.Fragment>
                                                        <NForm.Item {...input} {...layout}
                                                                    name="晶片種類"
                                                                    label="晶片種類:"

                                                        >
                                                            <Select {...input}
                                                                    placeholder="請選擇晶片種類">
                                                                <Option value="第一種類">第一種類</Option>
                                                                <Option value="第二種類">第二種類</Option>
                                                            </Select>
                                                            {meta.touched && meta.error &&
                                                            <div className="error">
                                                                {meta.error}
                                                            </div>}
                                                        </NForm.Item>
                                                    </React.Fragment>
                                                )
                                            }
                                        </Field>
                                        <Field parse={_.bs.identity} name="晶片號碼">
                                            {
                                                ({input, meta}) => (
                                                    <React.Fragment>
                                                        <NForm.Item {...input} {...layout}
                                                                    name="晶片號碼"
                                                                    label="晶片號碼:"

                                                        >
                                                            <Input {...input} placeholder="請輸入晶片號碼"
                                                                   allowClear/>
                                                            {meta.touched && meta.error &&
                                                            <div className="error">
                                                                {meta.error}
                                                            </div>}
                                                        </NForm.Item>
                                                    </React.Fragment>
                                                )
                                            }
                                        </Field>

                                        <Field parse={_.bs.identity} name="醫生No_喜愛醫師">
                                            {
                                                ({input, meta}) => (
                                                    <React.Fragment>
                                                        <NForm.Item {...input} {...layout}
                                                                    name="喜愛醫師名稱"
                                                                    label="喜愛醫師:"

                                                        >
                                                            <Select {...input}
                                                                    placeholder="請選擇指定醫師">
                                                                {docOptions.map((it => {
                                                                    return <Option
                                                                        value={it.value}>{it.label}</Option>
                                                                }))}
                                                            </Select>
                                                            {meta.touched && meta.error &&
                                                            <div className="error">
                                                                {meta.error}
                                                            </div>}
                                                        </NForm.Item>
                                                    </React.Fragment>
                                                )
                                            }
                                        </Field>

                                    </div>
                                    <div className="col v_line">
                                        <div className="photo">
                                            <Field parse={_.bs.identity} name='寵物照片組'>
                                                {
                                                    ({input, meta}) => {
                                                        if (!state.nsUploader2.compState.hasSetFileList) {
                                                            state.nsUploader2.compState.hasSetFileList = true;

                                                            let count: number = 0;

                                                            if (input.value && input.value !== '') {
                                                                state.nsUploader2.compState.fileList = (input.value).split(',').map((it) => {
                                                                    count++;

                                                                    // minimum requirement for antFileInfos [
                                                                    //     {
                                                                    //         uid: '-1',
                                                                    //         name: 'image.png',
                                                                    //         status: 'done',
                                                                    //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                                                                    //     },
                                                                    // ];

                                                                    let url = Ln_AvatorImgHref({
                                                                        照片組: it,
                                                                        testMode: false,
                                                                    });
                                                                    let antFileInfo = {
                                                                        uid: count,
                                                                        status: 'done',
                                                                        url: url,
                                                                        name: it,
                                                                        thumbUrl: url,

                                                                        id: it,
                                                                    };
                                                                    return antFileInfo;
                                                                });

                                                                state.nsUploader2.compState.files = (input.value ?? '').split(',').map((it) => {
                                                                    count++;

                                                                    // minimum requirement for antFileInfos [
                                                                    //     {
                                                                    //         uid: '-1',
                                                                    //         name: 'image.png',
                                                                    //         status: 'done',
                                                                    //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                                                                    //     },
                                                                    // ];
                                                                    let url = Ln_AvatorImgHref({
                                                                        照片組: it,
                                                                        testMode: false,
                                                                    });
                                                                    let antFileInfo = {
                                                                        uid: count,
                                                                        status: 'done',
                                                                        url: url,
                                                                        name: it,
                                                                        thumbUrl: url,

                                                                        id: it,
                                                                    };
                                                                    return antFileInfo;
                                                                });
                                                            }
                                                        }

                                                        return <React.Fragment>
                                                            <Upload
                                                                action={ApiService.urlMapping('~/pet_api/1/_memberupload')}
                                                                withCredentials={true}
                                                                listType="picture"
                                                                fileList={state.nsUploader2.compState.fileList}
                                                                beforeUpload={state.nsUploader2.beforeUpload}
                                                                onPreview={state.nsUploader2.handlePreview}
                                                                //previewFile={state.nsUploader.previewFile}
                                                                onChange={(params) => state.nsUploader2.handleChange({
                                                                    ...params,
                                                                    wrapperOnChange: input.onChange
                                                                })}
                                                                multiple={false}
                                                                data={state.nsUploader2.addData}
                                                                className="upload-list-inline"
                                                            >
                                                                {state.nsUploader2.compState.fileList?.length === 0 ?
                                                                    <img
                                                                        src="../static/img/defaultavatar.png"
                                                                        alt="Please upload pet avatar"/>
                                                                    : null
                                                                }
                                                            </Upload>
                                                        </React.Fragment>;
                                                    }
                                                }
                                            </Field>
                                        </div>
                                        <div className="notecube">
                                            <Field parse={_.bs.identity} name="寵物勳章">
                                                {
                                                    ({input, meta}) => (
                                                        <React.Fragment>
                                                            <NForm.Item {...input} {...layout}
                                                                        name="寵物勳章"
                                                                        label=""
                                                                        rules={[{required: true, message: '請輸入正確格式'}]}
                                                            >
                                                                <h5>寵物註記</h5>
                                                                {/*<div className="group">*/}
                                                                {/*    <Space>*/}
                                                                {/*        <Button><img src="/static/img/caty.png"*/}
                                                                {/*                     alt="tree"/></Button>*/}
                                                                {/*        <Button><img src="/static/img/tree.png"*/}
                                                                {/*                     alt="tree"/></Button>*/}
                                                                {/*        <Button><img src="/static/img/ill.png"*/}
                                                                {/*                     alt="tree"/></Button>*/}
                                                                {/*        <Button><img src="/static/img/eye.png"*/}
                                                                {/*                     alt="tree"/></Button>*/}
                                                                {/*    </Space>*/}
                                                                {/*</div>*/}
                                                                <Select {...input} placeholder="請選擇種類">
                                                                    <Option value="">不指定</Option>
                                                                    <Option value="友善">😺 友善</Option>
                                                                    <Option value="特別友善">😸 特別友善</Option>
                                                                    <Option value="易受驚嚇">🙀 易受驚嚇</Option>
                                                                    <Option value="兇暴">😾 兇暴</Option>
                                                                    <Option value="容易哀嚎">😿 容易哀嚎</Option>
                                                                </Select>
                                                                {meta.touched && meta.error &&
                                                                <div className="error">
                                                                    {meta.error}
                                                                </div>}
                                                            </NForm.Item>
                                                        </React.Fragment>
                                                    )
                                                }
                                            </Field>
                                        </div>

                                    </div>
                                </div>

                                <React.Fragment>
                                    <NForm.Item>
                                        <div className="form_footer">
                                            {/*<Button size="large" onClick={props.onRequestClose}>上一步</Button>*/}
                                            <Button type="primary" htmlType="submit" size="large"
                                                    onClick={handleSubmit}>建立</Button>

                                        </div>
                                    </NForm.Item>
                                </React.Fragment>

                            </form>
                        </div>
                    </React.Fragment>;
                }}
            />
        );
    };

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_Appointment__Pane_AddPet.displayName =
    'Partial_Appointment__Pane_AddPet';

