import React, {useCallback, useRef, useState} from 'react';
import {BrowserRouter, Routes, Route, Link} from 'react-router-dom';

import logo from './logo.svg';
import './App.scss';
import './pages/antd.scss';
import {Static} from './_/Static';

import Pg_JumpLogin from "./pages/Pg_JumpLogin";
import Pg_Login from "./pages/Pg_Login";
import Pg_ForgetPassword from "./pages/Pg_ForgetPassword";
import Pg_Root from "./pages/Pg_Root";

import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import Pg_Reservation from "./pages/Pg_Reservation";
import Pg_Waitingroom from "./pages/Pg_Waitingroom";
import Pg_Appointment from "./pages/Pg_Appointment";
import Pg_Basicdata from "./pages/Pg_Basicdata";
import Pg_Medicalreport from "./pages/Pg_Medicalreport";
import Pg_MedicalRecord from "./pages/Pg_MedicalRecord";
import Pg_Pharmacy from "./pages/Pg_Pharmacy";
import Pg_Charge from "./pages/Pg_Charge";
import Pg_Manage_Agency from "./pages/Pg_Manage_Agency";
import Pg_Manage_Staff from "./pages/Pg_Manage_Staff";
import Pg_Manage_Hours_ from "./pages/Pg_Manage_Hours_";
import Pg_Manage_Shifts_ from "./pages/Pg_Manage_Shifts_";
import Pg_InHospital from "./pages/Pg_InHospital";
import Pg_Cosmetic from "./pages/Pg_Cosmetic";
import Pg_Manage_Campaign from "./pages/Pg_Manage_Campaign";
import Pg_ClientManage from "./pages/Pg_ClientManage";
import Pg_OperationStatus from "./pages/Pg_OperationStatus";


import Box_Sidemenu from './pages/Box_Sidemenu';
import ClinicName from './pages/Box_Pagetitle';
import ImageViewer from "./hooks/ImageViewer/ImageViewer";
import {useApp} from "./overmind";
import Pg_Manage_Hospital from "./pages/Pg_Manage_Hospital";
import Pg_Manage_Hours from "./pages/Pg_Manage_Hours";
import Pg_Manage_Shifts from "./pages/Pg_Manage_Shifts";

function App() {


    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [images, setImages] = useState([]);
    // const images = [
    //     'http://placeimg.com/1200/800/nature',
    //     'http://placeimg.com/800/1200/nature',
    //     'http://placeimg.com/1920/1080/nature',
    //     'http://placeimg.com/1500/500/nature',
    // ];

    //const {state:appState, actions} = useApp();
    const refDoLoadPageData = useRef(null);
    //actions.setRefDoLoadPageData(refDoLoadPageData);
    Static.refDoLoadPageData = refDoLoadPageData;

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);

        if (window) {
            document.body.style.overflow = 'auto';
        }
    };


    Static.App.setImagesAndOpen = function (images) {
        setImages(images);
        setCurrentImage(0);
        setIsViewerOpen(true);

        if (window) {
            document.body.style.overflow = 'hidden';
        }
    }

    let imageViewer = <React.Fragment>
        <div> { /*{images.map((src, index) => (*/} { /*    <img*/} { /*        src={ src }*/} { /*        onClick={ () => openImageViewer(index) }*/} { /*        width="300"*/} { /*        key={ index }*/} { /*        style={{ margin: '2px' }}*/} { /*        alt=""/>*/} { /*))}*/}
            {
                isViewerOpen && (<
                        ImageViewer src={images}
                                    currentIndex={currentImage}
                                    onClose={closeImageViewer}
                    />
                )
            } </div>
    </React.Fragment>;

    return (< BrowserRouter> {imageViewer} <Routes>
            <Route exact path="/"
                   element={< Pg_Root/>}
            />

            <Route exact path="/jumplogin"
                   element={< Pg_JumpLogin RoutePathname=""/>}
            />

            <Route exact path="/login"
                   element={< Pg_Login RoutePathname="/login"/>}
            />

            <Route exact path="/forgetpassword"
                   element={< Pg_ForgetPassword RoutePathname="/forgetpassword"/>}
            />

        </Routes>

            <Routes>
                <div className="App">
                    <Routes>
                        <Route exact path="/waitingroom"
                               element={< Box_Sidemenu RoutePathname="/waitingroom"
                                                       MenuTemplateId="2"/>
                               }
                        />
                        <Route exact path="/appointment"
                               element={< Box_Sidemenu RoutePathname="/appointment"
                                                       MenuTemplateId="3"/>
                               }
                        />
                        <Route exact path="/reservation"
                               element={< Box_Sidemenu RoutePathname="/reservation"
                                                       MenuTemplateId="4"/>
                               }
                        />
                        <Route exact path="/pharmacy"
                               element={< Box_Sidemenu RoutePathname="/pharmacy"
                                                       MenuTemplateId="5"/>
                               }
                        />
                        <Route exact path="/charge"
                               element={< Box_Sidemenu RoutePathname="/charge"
                                                       MenuTemplateId="9"/>
                               }
                        />
                        <Route exact path="/medicalrecord/:No"
                               element={< Box_Sidemenu RoutePathname="/medicalrecord/:No"
                                                       MenuTemplateId="16"/>
                               }
                        />
                        <Route exact path="/agency"
                               element={< Box_Sidemenu RoutePathname="/agency"
                                                       MenuTemplateId="13"/>
                               }
                        />
                        <Route exact path="/staff"
                               element={< Box_Sidemenu RoutePathname="/staff"
                                                       MenuTemplateId="14"/>
                               }
                        />
                        <Route exact path="/hours"
                               element={< Box_Sidemenu RoutePathname="/hours"
                                                       MenuTemplateId="15"/>
                               }
                        />
                        <Route exact path="/shifts"
                               element={< Box_Sidemenu RoutePathname="/shifts"
                                                       MenuTemplateId="17"/>
                               }
                        />
                        <Route exact path="/inhospital"
                               element={< Box_Sidemenu RoutePathname="/inhospital"
                                                       MenuTemplateId="7"/>
                               }
                        />
                        <Route exact path="/cosmetic"
                               element={< Box_Sidemenu RoutePathname="/cosmetic"
                                                       MenuTemplateId="8"/>
                               }
                        />
                        <Route exact path="/campaign"
                               element={< Box_Sidemenu RoutePathname="/campaign"
                                                       MenuTemplateId="18"/>
                               }
                        />

                        <Route exact path="/clientmanage/:No"
                               element={< Box_Sidemenu RoutePathname="/clientmanage/:No"
                                                       MenuTemplateId="10"/>
                               }
                        />
                        <Route exact path="/opertionstatus"
                               element={< Box_Sidemenu RoutePathname="/opertionstatus"
                                                       MenuTemplateId="19"/>
                               }
                        />
                        <Route exact path="/hospital"
                               element={<Box_Sidemenu RoutePathname="/hospital"
                                                      MenuTemplateId="21"/>
                               }
                        />


                    </Routes>
                    <div className="page-wrapper">
                        <ClinicName copmanyname="金旺動物醫院"/>
                        <Routes>
                            <Route exact path="/reservation"
                                   element={< Pg_Reservation RoutePathname="/reservation"
                                                             MenuTemplateId="4"/>
                                   }/>

                            <Route exact path="/waitingroom"
                                   element={< Pg_Waitingroom RoutePathname="/waitingroom"
                                                             MenuTemplateId="2"/>
                                   }
                            />

                            <Route exact path="/appointment"
                                   element={< Pg_Appointment RoutePathname="/appointment"
                                                             MenuTemplateId="3"/>
                                   }
                            />

                            <Route exact path="/basicdata"
                                   element={< Pg_Basicdata RoutePathname="/basicdata"
                                                           MenuTemplateId="3"/>
                                   }
                            />

                            <Route exact path="/medicalreport"
                                   element={< Pg_Medicalreport RoutePathname="/medicalrecord"
                                                               MenuTemplateId="3"/>
                                   }
                            />

                            <Route exact path="/pharmacy"
                                   element={< Pg_Pharmacy RoutePathname="/pharmacy"
                                                          MenuTemplateId="5"/>
                                   }
                            />

                            <Route exact path="/charge"
                                   element={< Pg_Charge RoutePathname="/charge"
                                                        MenuTemplateId="9"/>
                                   }
                            />

                            <Route exact path="/medicalrecord/:No"
                                   element={< Pg_MedicalRecord RoutePathname="/medicalrecord/:No"/>}
                            />


                            <Route exact path="/agency"
                                   element={< Pg_Manage_Agency RoutePathname="/agency"
                                                               MenuTemplateId="13"/>
                                   }
                            />

                            <Route exact path="/staff"
                                   element={< Pg_Manage_Staff RoutePathname="/staff"
                                                              MenuTemplateId="14"/>
                                   }
                            />

                            <Route exact path="/hours"
                                   element={< Pg_Manage_Hours RoutePathname="/hours"
                                                               MenuTemplateId="15"/>
                                   }
                            />

                            <Route exact path="/shifts"
                                   element={< Pg_Manage_Shifts RoutePathname="/shifts"
                                                                MenuTemplateId="17"/>
                                   }
                            />

                            <Route exact path="/inhospital"
                                   element={< Pg_InHospital RoutePathname="/inhospital"
                                                            MenuTemplateId="7"/>
                                   }
                            />

                            <Route exact path="/cosmetic"
                                   element={< Pg_Cosmetic RoutePathname="/cosmetic"
                                                          MenuTemplateId="8"/>
                                   }
                            />

                            <Route exact path="/campaign"
                                   element={< Pg_Manage_Campaign RoutePathname="/campaign"
                                                                 MenuTemplateId="18"/>
                                   }
                            />
                            <Route exact path="/clientmanage/:No"
                                   element={< Pg_ClientManage RoutePathname="/clientmanage/:No"
                                                              MenuTemplateId="10"/>
                                   }
                            />

                            <Route exact path="/opertionstatus"
                                   element={< Pg_OperationStatus RoutePathname="/opertionstatus"
                                                                 MenuTemplateId="19"/>
                                   }
                            />

                            <Route exact path="/hospital"
                                   element={<Pg_Manage_Hospital RoutePathname="/hospital"
                                                                MenuTemplateId="21"/>
                                   }
                            />


                        </Routes>
                    </div>
                </div>
            </Routes>
        </BrowserRouter>
    );

}

export default App;
