import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {
    Form as NForm,
    Tag,
    Button,
    Modal,
    message,
    Checkbox,
    Input,
    Select,
    Space,
    Table,
    Radio,
    Empty,
    InputNumber, Spin
} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";

import {LoadingOutlined, LockOutlined, PlusOutlined, RedoOutlined, UserOutlined} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";
import UseCompState from "../hooks/UseCompState";
import Ln_CommaListRender from "./Ln_CommaListRender";

var moment = require('moment');


type ViewModelProps = {
    refModal: any,

    大分類: any,

    OnDid?: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any;

    viewModel: ViewModelProps,
};
export default function Partial_MedicalRecord__Modal_DealwithAdd_Items(props: Props) {
    const PrefixTarget: string = props.PrefixTarget || ('Partial_MedicalRecord__Modal_DealwithAdd_Items' + props.viewModel.大分類);
    const pageDataManager: PageDataManager = props.pageDataManager;
    const sessionManager: SessionManager = props.sessionManager;
    const navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel = props.viewModel;

    const {Option} = Select;
    const {Search} = Input;
    const onSearch = value => console.log(value);
    const {TextArea} = Input;

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    //#region LoadingManager
    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});
    //#endregion

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

    }
    //#endregion

    //#region Boot
    let _refDoLoadPartialData: any = useRef<{ alwaysLoad?: boolean }>(null);
    useEffect(() => {
        const _loadPartialData = async (): Promise<void> => {

            // VALIDATE INPUT
            const p1 = viewModel.大分類;
            if (!p1) {
                alert('p1');
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/partial_medicalrecord/modal_dealwithadd');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        let changeShard = {
                            [PrefixTarget]: {
                                ...payload,
                            }
                        }
                        pageDataManager.merge(changeShard);
                        pageDataManager.apply();

                        forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPartialData = async ({alwaysLoad = false}): Promise<void> => {
            // if (!pageDataManager.refPageData.current.Root) {
            //     return;
            // }

            /// do not dynamically load data if condition fits.
            if (!alwaysLoad) {
                if (pageDataManager.isZoneExist(PrefixTarget)) {
                    return;
                }
            }

            await _loadPartialData();
        }

        _refDoLoadPartialData.current = doLoadPartialData;
        _refDoLoadPartialData.current({});
    }, [pageDataManager.get(PrefixTarget)]);
    //#endregion

    //#region Set Body
    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };
    const Body = () => {
        const RegionEmptyShow = false;
        const RegionLoadingShow = true;
        const RegionInitialLoadingShow = false;

        const PassPageDataInitalized = pageDataManager.refPageData.current != null;
        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;


        let DataSource_處理範本 = state.DataSource_處理範本 = [];
        let List_處理範本 = pageDataManager.get(`${PrefixTarget}.List_處理範本`);
        if (List_處理範本 !== null) {
            let serialCounter = 0;
            DataSource_處理範本 = List_處理範本.map((Item_處理範本) => {
                serialCounter += 1;

                if (serialCounter == 1) {
                }

                return {
                    entity: Item_處理範本,
                    key: Item_處理範本.處理範本No,

                    大分類: Item_處理範本.大分類,
                    處理方法_中: Item_處理範本.處理方法_中,
                    處理方法_英: Item_處理範本.處理方法_英,
                    分類組: Item_處理範本.分類組,
                    學科組: Item_處理範本.學科組,
                }
            });

            state.DataSource_處理範本 = DataSource_處理範本;
        }

        const List_醫生 = pageDataManager.get(`${PrefixTarget}.List_醫生`);
        let docOptions: any = [];
        {
            if (List_醫生) {
                docOptions = List_醫生.map((r) => {
                    return {
                        key: r.醫生No,
                        value: r.名稱,
                        label: `${r.名稱} 醫師`,
                    }
                });
                docOptions.unshift({
                    key: '0',
                    value: '',
                    label: '請選擇',
                });
            }
        }

        //#region Handlers
        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            // if (!values.username) {
            //     errors.username = '↑ 欄位為必填';
            // } else {
            // }
            //
            // if (!values.password) {
            //     errors.password = '↑ 欄位為必填';
            // } else {
            // }
            //
            // if (!values.selected) {
            //     errors.selected = '↑ 欄位為必填';
            // }

            return errors;
        };

        const mockTags = ['Magenta', 'Lasvono', 'Jeseiuy'];

        const surgeryColumns = [
            {
                title: '處理方法(中)',
                dataIndex: '處理方法_中',
            },
            {
                title: '處理方法(英)',
                dataIndex: '處理方法_英',

            },
            {
                title: '分類',
                dataIndex: '分類組',
                render: (text, record) => Ln_CommaListRender({
                    照片組: text,
                    render: (tag) => (
                        <Tag key={tag}>{tag}</Tag>
                    )
                })
            },
        ];

        interface DataType {
            key: React.Key;
            name: string;
            id: string;
            cellphone: string;
            tel: string;
        }

        const handleSubmit_bringBack = (e: any, v: any) => {
            // let hide = message.loading({
            //     type: 'loading',
            //     className: 'myMessage',
            //     content: <div className="myMessageBody">驗證中...</div>,
            //     key: 'loading',
            //     duration: 0
            // });

            var selected = v.selected;

            if (viewModel.OnDid) {
                const ret = viewModel.OnDid(v);
            } else {
                alert('onDone not defined');
            }

            // //#region Handle SaveUserInfo
            // {
            //     // VALIDATE INPUT
            //     //const id : any = this.props.match.params.id;
            //     const p1 = v.selected;
            //     if (!p1) {
            //         _._debug.error(p1);
            //         return; //ERROR INPUTS
            //     }
            //
            //     // WAITING SCREEN
            //     loadingManager.changeLoadingCount(+1);
            //     //StaticEvent.LoadingEvent.next(true);
            //
            //     // POST
            //     let postData = {
            //         p1,
            //         timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            //     };
            //     const postUrl = ApiService.urlMapping('~/pet_api/1/_pg_appointment/keepowner');
            //     const post$ = Static.apiService.post(postUrl, postData);
            //     post$.subscribe(async (res: any) => {
            //             hide();
            //
            //             // CLOSE WAITING SCREEN
            //             loadingManager.changeLoadingCount(-1);
            //             //StaticEvent.LoadingEvent.next(false);
            //
            //             let data = res.data;
            //             let error = data.error || null;
            //             let result = error == null;
            //
            //             if (result) { // SUCEESS
            //                 let payload = data.payload;
            //                 _._debug.warn(payload);
            //
            //
            //                 let carryData = Object.assign({},
            //                     {
            //                         [PrefixTarget]: payload
            //                     }
            //                 );
            //                 if (viewModel.onDone) {
            //                     const ret = viewModel.onDone(carryData);
            //                 } else {
            //                     alert('onDone not defined');
            //                 }
            //
            //             } else {
            //                 // AUTH USER EXPIRED
            //                 if (error.code == 2) {
            //                     //alert('帳號被登出，請重新登入');
            //                     //window.location.href = '/login';
            //                     //this.props.history.push('/login')
            //                     await sessionManager.setLogout();
            //                     pageDataManager.clear();
            //                     pageDataManager.reloadAsync({alwaysLoad: true});
            //                     alert('時間逾時已被登出');
            //                     return;
            //                 }
            //
            //                 _._debug.error(error);
            //                 alert(error.message);
            //             }
            //         }, async (err) => {
            //             hide();
            //
            //             // CLOSE WAITING SCREEN
            //             loadingManager.changeLoadingCount(-1);
            //             //StaticEvent.LoadingEvent.next(false);
            //             _._debug.warn(err);
            //             alert('網路連線有問題');
            //         }
            //     );
            //     return lastValueFrom(
            //         post$
            //     );
            // }
            // //#endregion
        }

        const handleSubmit_applyNewSearchCondition = (e: any, v: any) => {
            state.q = v.q;

            pageDataManager.emptyZone(PrefixTarget);
            pageDataManager.apply();

            forceRender();
            //pageDataManager.reloadAsync({alwaysLoad: true});
        }
        const layout = {
            labelCol: {span: 3},
            wrapperCol: {span: 25},
        };

        const key = 'updatable';

        const openMessage = () => {
            message.loading({content: '新增中...', key});
            setTimeout(() => {
                message.success({content: '新增成功!', key, duration: 2});
            }, 1000);
        };
        //#endregion

        if (!PassPrefixTargetLoaded) {
            if (RegionInitialLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <div>Loading.</div>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        if (loadingManager.getIsLoading()) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <Spin indicator={
                            <LoadingOutlined style={{fontSize: 24}} spin/>
                        }/>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        return (
            <Form
                onSubmit={(v) => handleSubmit_bringBack(null, v)}
                initialValues={{
                    a: 100,
                }}
                validate={handleValidate}
                render={({
                             submitError,
                             handleSubmit,
                             submitting,
                             pristine,
                             valid,
                             error,
                             values
                         }) => {


                    return <React.Fragment>
                        <div className="form_body">
                            <form onSubmit={handleSubmit}>
                                <Field parse={_.bs.identity} name="醫生No_執行醫生" validate={_.bs.Form.required}>
                                    {
                                        ({input, meta}) => (
                                            <React.Fragment>
                                                <NForm.Item {...input}
                                                            name="醫生No_執行醫生"
                                                            label="執行醫師:"

                                                >
                                                    <Select {...input} placeholder="請選擇執行醫生">
                                                        {docOptions.map((it => {
                                                            return <Option
                                                                value={it.value}>{it.label}</Option>
                                                        }))}
                                                    </Select>
                                                    {meta.touched && meta.error &&
                                                    <div className="error">
                                                        {meta.error}
                                                    </div>}
                                                </NForm.Item>
                                            </React.Fragment>
                                        )
                                    }
                                </Field>
                                {/*<Field parse={_.bs.identity} name="晶片號碼">*/}
                                {/*    {*/}
                                {/*        ({input, meta}) => (*/}
                                {/*            <React.Fragment>*/}
                                {/*                <NForm.Item {...input} {...layout}*/}
                                {/*                            name="晶片號碼"*/}
                                {/*                            label="晶片號碼:"*/}

                                {/*                >*/}
                                {/*                    <Input {...input} placeholder="請輸入晶片號碼" allowClear/>*/}
                                {/*                    {meta.touched && meta.error &&*/}
                                {/*                    <div className="error">*/}
                                {/*                        {meta.error}*/}
                                {/*                    </div>}*/}
                                {/*                </NForm.Item>*/}
                                {/*            </React.Fragment>*/}
                                {/*        )*/}
                                {/*    }*/}
                                {/*</Field>*/}

                                {/*<Field parse={_.bs.identity} name='sterilization'>*/}
                                {/*    {*/}
                                {/*        ({input, meta}) => (*/}
                                {/*            <React.Fragment>*/}

                                {/*                <NForm.Item {...input}*/}
                                {/*                            name="sterilizationdate"*/}

                                {/*                >*/}
                                {/*                    <Search placeholder="輸入處理方法"/>*/}

                                {/*                </NForm.Item>*/}
                                {/*            </React.Fragment>*/}
                                {/*        )*/}
                                {/*    }*/}
                                {/*</Field>*/}
                                <div className="searchresult">
                                    <Field parse={_.bs.identity} name="selected">
                                        {
                                            ({input, meta}) => (
                                                <Table
                                                    rowSelection={{
                                                        type: 'radio',
                                                        onChange: (idx, rows) => {
                                                            if (rows.length > 0) {
                                                                input.onChange(rows.map((r: any) => r.entity));
                                                            }
                                                        }
                                                    }}
                                                    columns={surgeryColumns}
                                                    dataSource={DataSource_處理範本}
                                                    size='small'
                                                    locale={{
                                                        emptyText: <Empty description={
                                                            <span>沒有搜尋到相關資料～</span>
                                                        }/>
                                                    }}
                                                />
                                            )
                                        }
                                    </Field>
                                </div>

                                {/*<div className="infocontent graybk col_th">*/}
                                {/*    <div className="infobox">*/}
                                {/*        <div className="info">*/}
                                {/*            <div className="th">處理方法說明:</div>*/}
                                {/*            <div*/}
                                {/*                className="content">說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明*/}
                                {/*                <div className="tagform">*/}
                                {/*                    <div className="tagstyle">畜牧營運</div>*/}
                                {/*                    <div className="tagstyle">生物解剖學</div>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                {/* <Field parse={_.bs.identity} name="外送">
                                    {
                                        ({ input, meta }) => (
                                            <React.Fragment>
                                                <NForm.Item {...input} {...layout}
                                                    name="外送"
                                                >

                                                    <Checkbox>新增至「外送」列表</Checkbox>
                                                </NForm.Item>
                                            </React.Fragment>
                                        )
                                    }
                                </Field>

                                <Field parse={_.bs.identity} name="回診通知">
                                    {
                                        ({ input, meta }) => (
                                            <React.Fragment>
                                                <NForm.Item {...input} {...layout}
                                                    name="回診通知"

                                                >

                                                    <Checkbox>新增至「回診通知」列表</Checkbox>

                                                </NForm.Item>
                                            </React.Fragment>
                                        )
                                    }
                                </Field> */}


                            </form>

                            <React.Fragment>
                                <NForm.Item>
                                    <div className="form_footer">
                                        <Button type="primary" htmlType="submit" size="large" onClick={handleSubmit}
                                        >新增</Button>
                                    </div>
                                </NForm.Item>
                            </React.Fragment>

                        </div>
                    </React.Fragment>;
                }}
            />
        );
    };
//#endregion

//#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
//#endregion
}
Partial_MedicalRecord__Modal_DealwithAdd_Items.displayName = 'Partial_MedicalRecord__Modal_DealwithAdd_Items';
