import React from 'react';
import './Box_MRbtn.scss';

interface Props {
  iconlink :string;
  name :string;
  className?:string;
}

function Box_MRbtn(props:Props) {
  return (
    <div className={'mr_btn v_btn '}>
      <div className="btn">
        <img src={props.iconlink}  alt="icon"/>
      </div>
      <div className={'btn-name '}>{props.name}</div>
    </div>
  )
}

export default Box_MRbtn;
