import React, {Component} from 'react';
import {useState, useEffect} from 'react';
import {Waypoint} from 'react-waypoint';
import {postTimeDisplayFactory} from '../utils/time';
import _ from "../_";
import {ApiService} from "../_/ApiService";

type Props = {
    照片組?: string;
    testMode?: boolean,

    render: any,
    children?: any,   // show when empty or testmode
};

export default function Ln_CommaListRender(props: Props) {
    const param_照片組 = props['照片組'] || '';
    const param_TestMode = props['testMode'] || false;

    let itemArr = _.bs.getCommaList(param_照片組);

    if (param_TestMode) {
        if (props.children) {
            return <React.Fragment>
                {props.children()}
            </React.Fragment>;
        } else {
            return <span>
                testMode? where is children
            </span>
        }
    }

    if (itemArr.length > 0) {
        let res:any = null;
        res = itemArr.map(r=>{
            return props.render(r);
        });
        return <React.Fragment>
            {res}
        </React.Fragment>;
    } else {
        if (props.children) {
            return <React.Fragment>
                {props.render(null)}
            </React.Fragment>;
        } else {
            return null;
        }
    }
}
