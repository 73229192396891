import React, { useCallback, useEffect, useRef, useState } from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {
    Form as NForm,
    Popover,
    Button,
    Modal,
    message,
    Upload,
    Input,
    Space,
    Select,
    Radio,
    DatePicker,
    InputNumber,
    Switch,
    TimePicker
} from 'antd';

import { ApiService } from "../_/ApiService";
import { Static } from "../_/Static";
import { StaticEvent } from "../_/StaticEvent";
import _ from "../_";

import { Link } from 'react-router-dom';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import { PageDataManager } from "../hooks/UsePageDataManager";

import { Field, Form } from "react-final-form";
import { SessionManager } from "../hooks/UseSessionManager";
import { NavigateFunction } from "react-router";

import { LoadingOutlined, LockOutlined, PlusOutlined, RedoOutlined, UserOutlined } from "@ant-design/icons";
import { lastValueFrom } from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_Appointment__Modal_Preview from "./Partial_Appointment__Modal_Preview";
import update from "immutability-helper";

var moment = require('moment');
const { Option } = Select;

type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,

    onDone?: any,
    onRequestClose?: any,
};
const dateFormat = 'YYYY/MM/DD';
const timeformat = 'HH:mm';
const { RangePicker } = TimePicker;

export default function Partial_addBusinessHours(props: Props) {
    const PrefixTarget: string = props.PrefixTarget || 'Partial_addBusinessHours';
    const pageDataManager: PageDataManager = props.pageDataManager;
    const sessionManager: SessionManager = props.sessionManager;
    const navigate: NavigateFunction = props.navigate;

    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
    };

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const loadingManager = UseLoadingManager({ shouldReRenderOnLoading: false });

    //#region Boot


    //#region LoadPartialData
    useEffect(() => {
        const _load = async (): Promise<void> => {
            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">讀取中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            //const id : any = this.props.match.params.id;
            // const id = 1;
            // if (!id) {
            //     return; //ERROR INPUTS
            // }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                //p1: 1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/partial_appointment/pane_addpet');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                hide();

                // CLOSE WAITING SCREEN
                loadingManager.changeLoadingCount(-1);
                //StaticEvent.LoadingEvent.next(false);

                let data = res.data;
                let error = data.error || null;
                let result = error == null;

                if (result) { // SUCEESS
                    let payload = data.payload;
                    _._debug.warn(payload);

                    pageDataManager.emptyZone(PrefixTarget);
                    let changeShard = {
                        [PrefixTarget]: {
                            ...payload,
                        }
                    }
                    pageDataManager.merge(changeShard);
                    pageDataManager.apply();

                    forceRender();
                } else {
                    // AUTH USER EXPIRED T:1
                    if (error.code == 2) {
                        await sessionManager.setLogout();
                        Static.NavAwayToLogin(pageDataManager, navigate)(null);
                        return;
                    }

                    _._debug.error(error);
                    alert(error.message);
                }
            }, async (err) => {
                // CLOSE WAITING SCREEN
                loadingManager.changeLoadingCount(-1);
                //StaticEvent.LoadingEvent.next(false);
                _._debug.warn(err);
                alert('網路連線有問題');
            }
            );
            return lastValueFrom(
                post$
            );
        }
        const _doLoadPartialData = async (): Promise<void> => {
            if (!pageDataManager.refPageData.current.Root) {
                return;
            }

            /// do not dynamically load data if condition fits.
            if (pageDataManager.isZoneExist(PrefixTarget)) {
                return;
            }

            // if (pageDataManager.refPageData.current[PrefixTarget_TabControl].activeTabName === 'MyCollectionTab') {
            //     await _loadPageData();
            // }
            await _load();
        }
        _doLoadPartialData();
    }, []);
    //#endregion

    //#endregion

    //#region :Popup :createModalPreview
    const createModalPreview_refModal = useRef<any>(null);
    const createModalPreview = (e: any, { carryData, tasks }: {
        carryData?: any,
        tasks?: string[]
    }): void => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_Appointment__Modal_Preview
                PrefixTarget={'Partial_Appointment__Modal_Preview'}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModalPreview_refModal,
                }}
            />,
            okButtonProps: { disabled: true, className: "modal-footer-hiden-button" },
            className: 'myClass myModal',
            width: '60em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModalPreview_refModal.current = Modal.info(config);
    }
    //#endregion

    const Body = () => {
        const PassPageDataInitalized = pageDataManager.refPageData.current != null;
        const PassHasComponentData = pageDataManager.get(`${PrefixTarget}`) !== null;
        if (!PassPageDataInitalized) {
            return null;
        }
        if (!PassHasComponentData) {
            return null;
        }

        const List_醫生 = pageDataManager.get(`${PrefixTarget}.List_醫生`);
        let docOptions: any = [];
        {
            if (List_醫生) {
                docOptions = List_醫生.map((r) => {
                    return {
                        key: r.醫生No,
                        value: r.名稱,
                        label: `${r.名稱} 醫師`,
                    }
                });
                docOptions.unshift({
                    key: '0',
                    value: '',
                    label: '不指定',
                })
            }
        }

        // const props = {
        //     name: 'file',
        //     action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        //     headers: {
        //         authorization: 'authorization-text',
        //     },
        //     onChange(info) {
        //         if (info.file.status !== 'uploading') {
        //             console.log(info.file, info.fileList);
        //         }
        //         if (info.file.status === 'done') {
        //             message.success(`${info.file.name} file uploaded successfully`);
        //         } else if (info.file.status === 'error') {
        //             message.error(`${info.file.name} file upload failed.`);
        //         }
        //     },
        // };

        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            // if (!values.ownername) {
            //     errors.ownername = '↑ 欄位為必填';
            // } else {
            // }
            //
            // if (!values.cellphone) {
            //     errors.cellphone = '↑ 欄位為必填';
            // } else {
            // }

            return errors;
        };


        const handleSubmit_keepPet = (e: any, v: any) => {
            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            //#region Handle SaveUserInfo
            {
                // VALIDATE INPUT
                //const id : any = this.props.match.params.id;
                const p1 = v;
                if (!p1) {
                    _._debug.error(p1);
                    return; //ERROR INPUTS
                }

                // WAITING SCREEN
                loadingManager.changeLoadingCount(+1);
                //StaticEvent.LoadingEvent.next(true);

                // POST
                let postData = {
                    p1,
                    timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
                };
                const postUrl = ApiService.urlMapping('~/pet_api/1/_pg_appointment/keeppet');
                const post$ = Static.apiService.post(postUrl, postData);
                post$.subscribe(async (res: any) => {
                    hide();


                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        let carryData = Object.assign({},
                            {
                                [PrefixTarget]: payload
                            }
                        );
                        if (props.onDone) {
                            const ret = props.onDone(carryData);
                        } else {
                            alert('onDone not defined');
                        }

                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
                );
                return lastValueFrom(
                    post$
                );
            }
            //#endregion
        }

        return (
            <Form
                onSubmit={(v) => handleSubmit_keepPet(null, v)}
                initialValues={{}}
                validate={handleValidate}
                render={({
                    submitError,
                    handleSubmit,
                    submitting,
                    pristine,
                    valid,
                    error,
                    values
                }) => {

                    return <React.Fragment>

                        <div className="form_body col_3">
                            <form
                                onSubmit={handleSubmit}
                                {...layout}>


                                        <Field parse={_.bs.identity} name="shift">
                                            {
                                                ({ input, meta }) => (
                                                    <React.Fragment>
                                                        <NForm.Item {...input} {...layout}
                                                            name="shift"
                                                            label="班制:"
                                                            rules={[{ required: true, message: '請輸入正確格式' }]}
                                                        >

                                                            <Select defaultValue="3班制" placeholder="請選擇班制" >
                                                                <Option value="1班制">1</Option>
                                                                <Option value="2班制">2</Option>
                                                                <Option value="3班制">3</Option>
                                                                <Option value="4班制">4</Option>
                                                                <Option value="5班制">5</Option>
                                                            </Select>
                                                            {meta.touched && meta.error &&
                                                                <div className="error">
                                                                    {meta.error}
                                                                </div>}
                                                        </NForm.Item>
                                                    </React.Fragment>
                                                )
                                            }
                                        </Field>
                                        <div className='pl5'>
                                        <Field parse={_.bs.identity} name='seq01'>
                                            {
                                                ({ input, meta }) => (
                                                    <React.Fragment>

                                                        <Input.Group compact className='flex_h'

                                                        >
                                                            <NForm.Item {...input}
                                                                name="seqnumber1"

                                                            >
                                                                <div className="numdis">
                                                                    1
                                                                </div>
                                                            </NForm.Item>

                                                            <NForm.Item {...input}
                                                                name="shifttitle1"

                                                            >
                                                                <Input placeholder="標題，如早上" defaultValue='早上' />
                                                                {meta.touched && meta.error &&
                                                                    <div className="error">
                                                                        {meta.error}
                                                                    </div>}
                                                            </NForm.Item>
                                                            <NForm.Item {...input}
                                                                name="shifttime1"
                                                                style={{ flex: 1 }}
                                                            >

                                                                <RangePicker
                                                                defaultValue={[moment('09:00', timeformat),moment('12:00', timeformat)]}
                                                                format={timeformat}
                                                                minuteStep={15}/>
                                                                {meta.touched && meta.error &&
                                                                    <div className="error">
                                                                        {meta.error}
                                                                    </div>}
                                                            </NForm.Item>

                                                        </Input.Group>

                                                    </React.Fragment>
                                                )
                                            }
                                        </Field>
                                        <Field parse={_.bs.identity} name='seq02'>
                                            {
                                                ({ input, meta }) => (
                                                    <React.Fragment>

                                                        <Input.Group compact className='flex_h'

                                                        >
                                                            <NForm.Item {...input}
                                                                name="seqnumber2"

                                                            >
                                                                <div className="numdis">
                                                                    2
                                                                </div>
                                                            </NForm.Item>

                                                            <NForm.Item {...input}
                                                                name="shifttitle2"

                                                            >
                                                                <Input placeholder="標題，如早上" defaultValue='下午' />
                                                                {meta.touched && meta.error &&
                                                                    <div className="error">
                                                                        {meta.error}
                                                                    </div>}
                                                            </NForm.Item>
                                                            <NForm.Item {...input}
                                                                name="shifttime2"
                                                                style={{ flex: 1 }}
                                                            >

                                                                <RangePicker
                                                                defaultValue={[moment('13:00', timeformat),moment('18:00', timeformat)]}
                                                                format={timeformat}
                                                                minuteStep={15}/>
                                                                {meta.touched && meta.error &&
                                                                    <div className="error">
                                                                        {meta.error}
                                                                    </div>}
                                                            </NForm.Item>

                                                        </Input.Group>

                                                    </React.Fragment>
                                                )
                                            }
                                        </Field>
                                        <Field parse={_.bs.identity} name='seq03'>
                                            {
                                                ({ input, meta }) => (
                                                    <React.Fragment>

                                                        <Input.Group compact className='flex_h'

                                                        >
                                                            <NForm.Item {...input}
                                                                name="seqnumber3"

                                                            >
                                                                <div className="numdis">
                                                                    3
                                                                </div>
                                                            </NForm.Item>

                                                            <NForm.Item {...input}
                                                                name="shifttitle3"

                                                            >
                                                                <Input placeholder="標題，如早上" defaultValue='晚上' />
                                                                {meta.touched && meta.error &&
                                                                    <div className="error">
                                                                        {meta.error}
                                                                    </div>}
                                                            </NForm.Item>
                                                            <NForm.Item {...input}
                                                                name="shifttime3"
                                                                style={{ flex: 1 }}
                                                            >

                                                                <RangePicker
                                                                defaultValue={[moment('19:00', timeformat),moment('21:00', timeformat)]}
                                                                format={timeformat}
                                                                minuteStep={15}/>
                                                                {meta.touched && meta.error &&
                                                                    <div className="error">
                                                                        {meta.error}
                                                                    </div>}
                                                            </NForm.Item>

                                                        </Input.Group>

                                                    </React.Fragment>
                                                )
                                            }
                                        </Field>
                                        <div className='compact switch'>
                                            <div className="th">提供急診</div>
                                            <div className="con">
                                                <Switch   />
                                            </div>
                                        </div>
                                        <Field parse={_.bs.identity} name='seq01'>
                                            {
                                                ({ input, meta }) => (
                                                    <React.Fragment>

                                                        <Input.Group compact

                                                        >
                                                            <NForm.Item {...input}
                                                                name="seqnumber"

                                                            >
                                                                <div className="numdis">
                                                                    急診時間
                                                                </div>
                                                            </NForm.Item>
                                                            <NForm.Item {...input}
                                                                name="sterilizationdate"
                                                                style={{ flex: 1 }}
                                                            >

                                                                <RangePicker
                                                                 minuteStep={15}
                                                                format={timeformat}
                                                                placeholder={[ '開始時間',' 結束時間']}
                                                                defaultValue={[moment('09:00', timeformat),moment('21:00', timeformat)]}
                                                                />
                                                                {meta.touched && meta.error &&
                                                                    <div className="error">
                                                                        {meta.error}
                                                                    </div>}
                                                            </NForm.Item>

                                                        </Input.Group>

                                                    </React.Fragment>
                                                )
                                            }
                                        </Field>
                                        </div>








                                <React.Fragment>
                                    <NForm.Item>
                                        <div className="form_footer">
                                            {/*<Button size="large" onClick={props.onRequestClose}>上一步</Button>*/}
                                            <Button type="primary" htmlType="submit" size="large"
                                                onClick={handleSubmit}>建立</Button>

                                        </div>
                                    </NForm.Item>
                                </React.Fragment>

                            </form>
                        </div>
                    </React.Fragment>;
                }}
            />
        );
    };

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_addBusinessHours.displayName = 'Partial_addBusinessHours';
