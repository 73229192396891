import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {Form as NForm, Popover, Button, Modal, message, Radio, Input, Space, Select, Row, Col, Table, Spin} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";

import {LoadingOutlined, LockOutlined, PlusOutlined, RedoOutlined, UserOutlined} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_Appointment__Modal_AddPet from "./Partial_Appointment__Modal_AddPet";
import UseCompState from "../hooks/UseCompState";

var moment = require('moment');
const {Option} = Select;

type ViewModelProps = {
    refModal: any,

    Year: any,

    OnDid?: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any;

    viewModel: ViewModelProps,
};
export default function Partial_OperationStatus__Pane_Analysis(props: Props) {
    const PrefixTarget: string = props.PrefixTarget || ('Partial_OperationStatus__Pane_Analysis' + props.viewModel.Year);
    const pageDataManager: PageDataManager = props.pageDataManager;
    const sessionManager: SessionManager = props.sessionManager;
    const navigate: NavigateFunction = props.navigate;

    const viewModel = props.viewModel;

    const layout = {
        labelCol: {span: 7},
        wrapperCol: {span: 17},
    };
    const layout2 = {
        labelCol: {span: 4},
        wrapperCol: {span: 20},
    };

    //#region :Popup :createModalPet
    const createModalPet_refModal = useRef<any>(null);
    const createModalPet = (e: any, {carryData, tasks}: {
        carryData?: any,
        tasks?: string[]
    }): void => {
        if (e) {
            e.preventDefault();
        }
        // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        //
        // if (!PassPageDataInitalized) {
        //     //empty
        // }

        const config = {
            // title: 'test',
            content: <Partial_Appointment__Modal_AddPet
                PrefixTarget={'Partial_Appointment__Modal_AddPet'}
                pageDataManager={pageDataManager}
                sessionManager={sessionManager}
                navigate={navigate}

                viewModel={{
                    refModal: createModalPet_refModal,
                    carryData: carryData,
                }}
            />,
            okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
            className: 'myClass myModal',
            width: '60em',
            height: ' ',
            footer: null,
            //prefixCls: '',
        };

        createModalPet_refModal.current = Modal.info(config);
    }
    //#endregion

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    //#region LoadingManager
    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});
    //#endregion

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

        state.DataSource_Year = null;
    }
    //#endregion

    //#region Boot
    let _refDoLoadPartialData: any = useRef<{ alwaysLoad?: boolean }>(null);
    useEffect(() => {
        const _loadPartialData = async (): Promise<void> => {

            // VALIDATE INPUT
            const p1 = viewModel.Year;
            if (!p1) {
                alert('p1');
                return; //ERROR INPUTS
            }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/partial_operationstatus/pane_analysis');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        let changeShard = {
                            [PrefixTarget]: {
                                ...payload,
                            }
                        }
                        pageDataManager.merge(changeShard);
                        pageDataManager.apply();

                        forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPartialData = async ({alwaysLoad = false}): Promise<void> => {
            // if (!pageDataManager.refPageData.current.Root) {
            //     return;
            // }

            /// do not dynamically load data if condition fits.
            if (!alwaysLoad) {
                if (pageDataManager.isZoneExist(PrefixTarget)) {
                    return;
                }
            }

            await _loadPartialData();
        }

        _refDoLoadPartialData.current = doLoadPartialData;
        _refDoLoadPartialData.current({});
    }, [pageDataManager.get(PrefixTarget)]);
    //#endregion


    const Body = () => {
        const handleClose = (e) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }

            if (viewModel.refModal?.current) {
                viewModel.refModal.current.destroy();
            }
        };

        const RegionEmptyShow = false;
        const RegionLoadingShow = true;
        const RegionInitialLoadingShow = false;

        const PassPageDataInitalized = pageDataManager.refPageData.current != null;
        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;

        let DataSource_Year = state.DataSource_Year;
        if (DataSource_Year === null) {
            let Dict = pageDataManager.get(`${PrefixTarget}.Dict`);
            state.Dict = Dict;

            function range(size, startAt = 0) {
                return [...Array(size).keys()].map(i => i + startAt);
            }

            if (Dict !== null) {
                DataSource_Year = [{
                    item: '營業額',
                    m1: `$${Dict[1].月營業額}`,
                    m2: `$${Dict[2].月營業額}`,
                    m3: `$${Dict[3].月營業額}`,
                    m4: `$${Dict[4].月營業額}`,
                    m5: `$${Dict[5].月營業額}`,
                    m6: `$${Dict[6].月營業額}`,
                    m7: `$${Dict[7].月營業額}`,
                    m8: `$${Dict[8].月營業額}`,
                    m9: `$${Dict[9].月營業額}`,
                    m10: `$${Dict[10].月營業額}`,
                    m11: `$${Dict[11].月營業額}`,
                    m12: `$${Dict[12].月營業額}`,
                },
                    {
                        item: '來客數',
                        m1: `$${Dict[1].月來客數}`,
                        m2: `$${Dict[2].月來客數}`,
                        m3: `$${Dict[3].月來客數}`,
                        m4: `$${Dict[4].月來客數}`,
                        m5: `$${Dict[5].月來客數}`,
                        m6: `$${Dict[6].月來客數}`,
                        m7: `$${Dict[7].月來客數}`,
                        m8: `$${Dict[8].月來客數}`,
                        m9: `$${Dict[9].月來客數}`,
                        m10: `$${Dict[10].月來客數}`,
                        m11: `$${Dict[11].月來客數}`,
                        m12: `$${Dict[12].月來客數}`,
                    },
                    {
                        item: '新客數',
                        m1: `$${Dict[1].月新客數}`,
                        m2: `$${Dict[2].月新客數}`,
                        m3: `$${Dict[3].月新客數}`,
                        m4: `$${Dict[4].月新客數}`,
                        m5: `$${Dict[5].月新客數}`,
                        m6: `$${Dict[6].月新客數}`,
                        m7: `$${Dict[7].月新客數}`,
                        m8: `$${Dict[8].月新客數}`,
                        m9: `$${Dict[9].月新客數}`,
                        m10: `$${Dict[10].月新客數}`,
                        m11: `$${Dict[11].月新客數}`,
                        m12: `$${Dict[12].月新客數}`,
                    }]

                state.DataSource_Year = DataSource_Year;


            } else {
                DataSource_Year = null;
            }
        }

        const yearAnalysisColumn = [
            {
                dataIndex: 'item',
                title: '項目/月份',
            },
            {
                dataIndex: 'm1',
                title: '1月',
            },
            {
                dataIndex: 'm2',
                title: '2月',
            },
            {
                dataIndex: 'm3',
                title: '3月',
            },
            {
                dataIndex: 'm4',
                title: '4月',
            },
            {
                dataIndex: 'm5',
                title: '5月',
            },
            {
                dataIndex: 'm6',
                title: '6月',
            },
            {
                dataIndex: 'm7',
                title: '7月',
            },
            {
                dataIndex: 'm8',
                title: '8月',
            },
            {
                dataIndex: 'm9',
                title: '9月',
            },
            {
                dataIndex: 'm10',
                title: '10月',
            },
            {
                dataIndex: 'm11',
                title: '11月',
            },
            {
                dataIndex: 'm12',
                title: '12月',
            },

        ];

        const yearResultDate = [
            {
                item: '營業額',
                m1: '$151,324',
                m2: '暫無數據',

            },
            {
                item: '來客數',
                m1: '674',
                m2: '暫無數據',

            },
            {
                item: '新客數',
                m1: '12',
                m2: '暫無數據',

            },
        ];

        //#region Handlers

        //#endregion

        if (!PassPrefixTargetLoaded) {
            if (RegionInitialLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <div>Loading.</div>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        if (loadingManager.getIsLoading()) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <Spin indicator={
                            <LoadingOutlined style={{fontSize: 24}} spin/>
                        }/>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        return (
            <Table
                columns={yearAnalysisColumn}
                dataSource={DataSource_Year}
                pagination={false}
            />
        );
    };

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_OperationStatus__Pane_Analysis.displayName = 'Partial_OperationStatus__Pane_Analysis';
