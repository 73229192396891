import {ApiService} from "./ApiService";
import {StorageService} from "./StorageService";
import React from "react";
import {Link} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

const axios = require('axios');

type NavAwayToLogin_MoreParams = {}

export class Static {
    public static refDoLoadPageData: any = null;

    public static App: any = {};

    public static storageService: StorageService = new StorageService();

    public static apiService: ApiService = (() => {
        let apiService = new ApiService();

        axios.interceptors.request.use(async (config) => {
                // Do something before request is sent
                let authArea = (await Static.storageService.getLoggedAsync());

                if (authArea == null) {

                } else {
                    Object.assign(config.data, {'session': authArea.SessionId});
                }
                return config;
            },
            (error) => {
                // Do something with request error
                return Promise.reject(error);
            }
        );

        return apiService;
    })();

    public static NavAwayToLogin = function (pageDataManager: PageDataManager | null, navigate: any, moreParams?: NavAwayToLogin_MoreParams) {
        return (e: any) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }

            if (moreParams) {
                //s = s ? ('&s=' + s) : '';
                //q = q ? ('&q=' + q) : '';

                navigate({
                    pathname: `/login`,
                    //search: `?1=1${s}${q}`,
                    //hash: "#the-hash",
                    //state: {panelSelection: "f1"}
                });
            } else {
                navigate({
                    pathname: `/login`,
                    //search: "?sort=name",
                    //hash: "#the-hash",
                    //state: {panelSelection: "f1"}
                });
            }
        }
    }


    public static NavAwayToAppointment: any = function (navigate: any,
                                                        {
                                                            s,
                                                            q,
                                                        }: {
                                                            s?: any,
                                                            q?: any,
                                                        }
    ) {
        let ret: any = (e: any) => {
            if (e) {
                e.preventDefault();
            }

            if (s || q) {
                s = s ? ('&s=' + s) : '';
                q = q ? ('&q=' + q) : '';

                navigate({
                    pathname: `/appointment`,
                    search: `?1=1${s}${q}`,
                    //hash: "#the-hash",
                    //state: {panelSelection: "f1"}
                });
            } else {
                navigate({
                    pathname: `/appointment`,
                    //search: "?sort=name",
                    //hash: "#the-hash",
                    //state: {panelSelection: "f1"}
                });
            }
        };

        ret.Link = function LinkWrapper(props: any) {
            if (s || q) {
                s = s ? `s=${encodeURIComponent(s)}` : '';
                q = q ? `q=${encodeURIComponent(q)}` : '';

                return (
                    <Link to={{
                        pathname: `/appointment`,
                        search: `?1=1${s}${q}`,
                        //hash: "#the-hash",
                        //state: {panelSelection: "f1"}
                    }}>{props.children}</Link>
                );
            } else {
                return <Link to={{
                    pathname: `/appointment`,
                    //search: "?sort=name",
                    //hash: "#the-hash",
                    //state: {panelSelection: "f1"}
                }}>{props.children}</Link>
            }
        }
        return ret;
    };

    public static NavAwayToRecord: any = function (navigate: any,
                                                   {
                                                       No  //寵物No
                                                   }
    ) {
        let ret: any = (e: any) => {
            if (e) {
                e.preventDefault();
            }

            navigate({
                pathname: `/medicalrecord/${No}`,
                //search: "?sort=name",
                //hash: "#the-hash",
                //state: {panelSelection: "f1"}
            });
        };

        ret.Link = function LinkWrapper(props: any) {
            return (
                <Link to={{
                    pathname: `/medicalrecord/${No}`,
                    //search: "?sort=name",
                    //hash: "#the-hash",
                    //state: {panelSelection: "f1"}
                }}>{props.children}</Link>
            );
        }
        return ret;
    };

    public static NavAwayToClientManage: any = function (navigate: any,
                                                   {
                                                       No
                                                   }
    ) {
        let ret: any = (e: any) => {
            if (e) {
                e.preventDefault();
            }

            navigate({
                pathname: `/clientmanage/${No}`,
                //search: "?sort=name",
                //hash: "#the-hash",
                //state: {panelSelection: "f1"}
            });
        };

        ret.Link = function LinkWrapper(props: any) {
            return (
                <Link to={{
                    pathname: `/clientmanage/${No}`,
                    //search: "?sort=name",
                    //hash: "#the-hash",
                    //state: {panelSelection: "f1"}
                }}>{props.children}</Link>
            );
        }
        return ret;
    };
}


