import React, { useCallback, useEffect, useRef, useState } from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {
    Form as NForm, Popover, Button, Modal, message, Upload,
    Input, Select, Space, Tabs, Tooltip, Steps, DatePicker, TimePicker
} from 'antd';

import { ApiService } from "../_/ApiService";
import { Static } from "../_/Static";
import { StaticEvent } from "../_/StaticEvent";
import _ from "../_";

import { Link } from 'react-router-dom';


import {
    useParams,
    useNavigate
} from "react-router-dom";
import { PageDataManager } from "../hooks/UsePageDataManager";

import { Field, Form } from "react-final-form";
import { SessionManager } from "../hooks/UseSessionManager";
import { NavigateFunction } from "react-router";

import { LoadingOutlined, LockOutlined, PlusOutlined, RedoOutlined, CloseOutlined, UploadOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { lastValueFrom } from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_addOwnerProxy from "./Partial_addOwnerProxy";
import Partial_Search_Or from "./Partial_Search_Or";
import Partial_Search_TimeRangeDoctorFilter from "./Partial_Search_TimeRangeDoctorFilter";
import Partial_ScheduleTable from "./Partial_ScheduleTable";

import moment from 'moment';


const dateFormat = 'YYYY/MM/DD';
const format = 'HH:mm';
const { Option } = Select;
const { TextArea } = Input;

const props = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
        authorization: 'authorization-text',
    },
    onChange(info) {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
};

type ViewModelProps = {
    refModal: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,

    viewModel: ViewModelProps,
};
export default function Partial_Modal_Schedule(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_Modal_Schedule';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);


    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };
    const rs_layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion


    const key = 'updatable';
    const openMessage = () => {
        message.loading({ content: '建立中...', key });
        setTimeout(() => {
            message.success({ content: '預約建立成功!', key, duration: 2 });
        }, 1000);
    };





    const loadingManager = UseLoadingManager({ shouldReRenderOnLoading: false });

    //#region Boot
    const _doLoadPartialData = async (): Promise<void> => {
        if (!pageDataManager.refPageData.current.Root) {
            return;
        }

        /// do not dynamically load data if condition fits.
        if (pageDataManager.isZoneExist(PrefixTarget)) {
            return;
        }
        //await _loadPageData();
    }
    useEffect(() => {
        _doLoadPartialData();
    }, []);
    //#endregion


    const Body = () => {

        const popcontent = (
            <div >
                <div className="popbox">
                    <div className="infobar">
                        <div className="time">09:00~12:00</div>
                        <div className="count">預約人數：5</div>
                    </div>
                    <div className="poplist">
                        <div className="li">
                            <div className="time">09:00~09:15</div>
                            <div className="ntxt">
                                <div className="tp">Mibo</div>
                                <div className="scr">陳孝文</div>
                            </div>
                            <div className="tag prevention">預防</div>
                        </div>
                        <div className="li">
                            <div className="time">09:15~09:30</div>
                            <div className="ntxt">
                                <div className="tp">米來旺</div>
                                <div className="scr">林宜君</div>
                            </div>
                            <div className="tag prevention">預防</div>
                        </div>
                        <div className="li">
                            <div className="time">09:30~09:45</div>
                            <div className="ntxt">
                                <div className="tp">白老大</div>
                                <div className="scr">羅文宣</div>
                            </div>
                            <div className="tag prevention">預防</div>
                        </div>
                        <div className="li">
                            <div className="time">09:45~10:15</div>
                            <div className="ntxt">
                                <div className="tp">莫佛如巴蒂</div>
                                <div className="scr">Jason</div>
                            </div>
                            <div className="tag surgery">手術</div>
                        </div>
                        <div className="li">
                            <div className="time">10:30~11:30</div>
                            <div className="ntxt">
                                <div className="tp">來福</div>
                                <div className="scr">張家驊</div>
                            </div>
                            <div className="tag firstaid">急救</div>
                        </div>
                    </div>
                </div>
            </div>
        );

        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            if (!values.username) {
                errors.username = '↑ 欄位為必填';
            } else {
            }

            if (!values.password) {
                errors.password = '↑ 欄位為必填';
            } else {
            }

            return errors;
        };

        const handleClose = (e) => {
            if (e) {
                e.preventDefault();
            }

            if (viewModel.refModal || viewModel.refModal.current) {
                viewModel.refModal.current.destroy();
            }
        };




        const handleSubmit_clickLogin = (e: any, v: any) => {
            let hide = message.loading({
                type: 'loading',
                class: 'myMessage',
                content: <div className="myMessageBody">現在開始創立世界</div>,
                key: 'loading',
                duration: 0
            });

            //#region Handle SaveUserInfo
            {
                // VALIDATE INPUT
                //const id : any = this.props.match.params.id;
                const id = 1;
                if (!id) {
                    return; //ERROR INPUTS
                }

                // WAITING SCREEN
                loadingManager.changeLoadingCount(+1);
                //StaticEvent.LoadingEvent.next(true);

                // POST
                let postData = {
                    p1: v.p1,
                    p2: v.p2,
                    p3: v.p3,
                    p4: v.p4,
                    p5: v.p5,
                    timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
                };
                const postUrl = ApiService.urlMapping('~/h_api/1/pg_jumplogin/clicklogin');
                const post$ = Static.apiService.post(postUrl, postData);
                post$.subscribe(async (res: any) => {
                    hide();


                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        message.success({
                            type: 'success',
                            class: 'myMessage',
                            content: <div className="myMessageBody">歡迎來到，恐龍世界</div>,
                            key: '',
                            duration: 3
                        });

                        // message.success({
                        //     type: 'success',
                        //     class: 'myMessage',
                        //     content: <div className="myMessageBody">門就開了</div>,
                        //     key: '',
                        //     duration: 3
                        // });


                        await sessionManager.setLoginData(
                            payload.SessionId,
                            payload.AuthData
                        );

                        navigate({
                            pathname: '/wall/newest',
                            //search: "?sort=name",
                            //hash: "#the-hash",
                            //state: {panelSelection: "f1"}
                        });
                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
                );
                return lastValueFrom(
                    post$
                );
            }
            //#endregion
        }


        /*
                                                        <input type="text" {...input} placeholder="username"></input>
                                                {meta.touched && meta.error &&
                                                <div class="error">
                                                    {meta.error}
                                                </div>}

                                                <input type="text" {...input} placeholder="password"></input>
                                                {meta.touched && meta.error &&
                                                <div class="error">
                                                    {meta.error}
                                                </div>}

                                                <input type="submit" {...input} placeholder="asfasdfsad"></input>
                                                {meta.touched && meta.error &&
                                                <div class="error">
                                                    {meta.error}
                                                </div>}

         */
        return (
            <div className="model_wrapper">
                <div className="modal_title">
                    <div className="title_info">
                        <h2>預約總覽</h2>
                    </div>
                    <div className="info_bar"></div>
                    <div className="control_bar">
                        <Tooltip placement="top" title={'關閉'}>
                            <Button type="text" icon={<CloseOutlined/>} onClick={handleClose}/>
                        </Tooltip>
                    </div>
                </div>
                <div className="modal_content">
                    <div className="content_box">
                        <div className="reservationbox">
                            <div className="col s3_2">
                                <div className="schedule">
                                    <Partial_Search_TimeRangeDoctorFilter
                                        PrefixTarget={'Partial_Reservation__Pane_ReservationUncheckedList'}
                                        pageDataManager={pageDataManager}
                                        sessionManager={sessionManager}
                                        navigate={navigate}

                                        viewModel={{
                                            refModal: null,
                                        }}
                                    />

                                    <Partial_ScheduleTable
                                        PrefixTarget={'Partial_ScheduleTable'}
                                        pageDataManager={pageDataManager}
                                        sessionManager={sessionManager}
                                        navigate={navigate}

                                        viewModel={{
                                            refModal: null,
                                        }}
                                    />

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal_footer"></div>
            </div>
        );
    };

    //#region Render
    return <React.Fragment>
        <Body />
    </React.Fragment>;
    //#endregion
}
Partial_Modal_Schedule.displayName = 'Partial_Modal_Schedule';
