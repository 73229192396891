import {Dispatch, MutableRefObject, SetStateAction, useRef, useState} from 'react';
import _ from "../_";
import {Static} from "../_/Static";

var lodash = require('lodash');


export type LoadingManager = {
    readonly getLoadingCount: () => number,
    readonly getIsLoading: () => boolean,
    readonly changeLoadingCount: (offset: number) => number,
}

type Props = {
    shouldReRenderOnLoading?: boolean;
};
export default function UseLoadingManager(props: Props): LoadingManager {
    const shouldReRenderOnLoading = props.shouldReRenderOnLoading !== undefined ? props.shouldReRenderOnLoading : true;

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    const refSelfInstance = useRef<LoadingManager | null>(null);
    if (refSelfInstance.current !== null) {
        return refSelfInstance.current;
    }

    //#region Loading Control
    let _loadingCount: number = 0;

    const _changeLoading = (incrementNum: number) => {
        _loadingCount += incrementNum;
    };

    function getLoadingCount() {
        return _loadingCount;
    }

    function getIsLoading() {
        if (_loadingCount === 0) {
            return false;
        } else if (_loadingCount > 0) {
            return true;
        } else {
            return false;
        }
    }

    function changeLoadingCount(offset: number) {
        _changeLoading(offset);

        if (shouldReRenderOnLoading) {
            if (_loadingCount === 0) {
                forceRender();
            }
            if (_loadingCount === 1) {
                forceRender();
            }
        }
        return _loadingCount;
    }
    //#endregion

    return refSelfInstance.current = {
        getLoadingCount,
        getIsLoading,
        changeLoadingCount,
    } as LoadingManager;
}

