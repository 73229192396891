import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {
    Form as NForm,
    Popover,
    Button,
    Modal,
    InputNumber,
    message,
    Tag,
    Table,
    Upload,
    Input,
    Select,
    Space,
    Tabs,
    Tooltip,
    Steps,
    Empty,
    Spin
} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';


import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";
import {LoadingOutlined, LockOutlined, EditOutlined, PlusOutlined, CloseOutlined} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_Modal_DealwithItem from "./Partial_Modal_DealwithItem";
import Partial_MedicalRecord__Modal_MeasuringAdd from "./Partial_MedicalRecord__Modal_MeasuringAdd";
import UseCompState from "../hooks/UseCompState";


var moment = require('moment');
const {Option} = Select;
const {Search} = Input;
const onSearch = value => console.log(value);
const {TabPane} = Tabs;
const {Step} = Steps;

type ViewModelProps = {
    refModal: any,

    病歷No: any,
    掛號No: any,

    OnDid?: any;
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any,

    viewModel: ViewModelProps,
};
export default function Partial_MedicalRecord__Modal_Measuring(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_MedicalRecord__Modal_Measuring';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel = props.viewModel;


    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion


    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;

        state.forceRender = forceRender;
        state.DataSource_測量 = [];
        state.List_測量 = null;
    }
    //#endregion

    //#region Boot
    let _refDoLoadPartialData: any = useRef<{ alwaysLoad?: boolean }>(null);
    useEffect(() => {
        const _loadPartialData = async (): Promise<void> => {

            // VALIDATE INPUT
            //const id : any = this.props.match.params.id;
            // const p1 = viewModel.住院籠子No;
            // if (!p1) {
            //     return; //ERROR INPUTS
            // }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                //p1,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/partial_medicalrecord/modal_measuring');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        let changeShard = {
                            [PrefixTarget]: {
                                ...payload,
                            }
                        }
                        pageDataManager.merge(changeShard);
                        pageDataManager.apply();

                        forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPartialData = async ({alwaysLoad = false}): Promise<void> => {
            // if (!pageDataManager.refPageData.current.Root) {
            //     return;
            // }

            /// do not dynamically load data if condition fits.
            if (!alwaysLoad) {
                if (pageDataManager.isZoneExist(PrefixTarget)) {
                    return;
                }
            }

            await _loadPartialData();
        }

        _refDoLoadPartialData.current = doLoadPartialData;
        _refDoLoadPartialData.current({});
    }, [pageDataManager.get(PrefixTarget)]);
    //#endregion

    //#region Set Body
    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };
    const isModalContained = () => {
        return !!viewModel.refModal?.current;
    }
    const Body = () => {
        const RegionEmptyShow = false;
        const RegionLoadingShow = true;
        const RegionInitialLoadingShow = false;

        const PassPageDataInitalized = pageDataManager.refPageData.current != null;
        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;

        let DataSource_測量 = state.DataSource_測量;
        let List_測量 = state.List_測量; //pageDataManager.get(`${PrefixTarget}.List_處理`);
        if (List_測量 !== null) {
            let serialCounter = 0;
            DataSource_測量 = List_測量.map((Item_測量) => {
                serialCounter += 1;

                if (serialCounter == 1) {
                }

                return {
                    entity: Item_測量,
                    key: Item_測量.測量範本No,

                    measure_type: Item_測量.大分類,
                    measure_data: Item_測量.紀錄數據,
                    measure_time: Item_測量.時間 ? moment(Item_測量.時間).format('YYYY-MM-DD hh:mm') : '-',
                }
            });

            state.DataSource_測量 = DataSource_測量;
        }

        //#region Handlers
        const handleSubmit_saveRecord = (e: any, v: any) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }

            //#region Handle SaveUserInfo
            {
                // VALIDATE INPUT
                const p1 = v.病歷No;
                if (!p1) {
                    _._debug.error('p1');
                    return; //ERROR INPUTS
                }
                const p2 = v.List_測量;
                if (!p2) {
                    _._debug.error('p2');
                    return; //ERROR INPUTS
                }
                const p3 = v.掛號No;
                if (!p3) {
                    alert('沒有掛號無法寫入')
                    _._debug.error('p3');
                    return; //ERROR INPUTS
                }

                let hide = message.loading({
                    type: 'loading',
                    className: 'myMessage',
                    content: <div className="myMessageBody">驗證中...</div>,
                    key: 'loading',
                    duration: 0
                });

                // WAITING SCREEN
                loadingManager.changeLoadingCount(+1);
                //StaticEvent.LoadingEvent.next(true);

                // POST
                let postData = {
                    p1,
                    p2,
                    p3,
                    timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
                };
                const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_medicalrecord/modal_measuring/save');
                const post$ = Static.apiService.post(postUrl, postData);
                post$.subscribe(async (res: any) => {
                        hide();

                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);

                        let data = res.data;
                        let error = data.error || null;
                        let result = error == null;

                        if (result) { // SUCEESS
                            let payload = data.payload;
                            _._debug.warn(payload);

                            ///handleclose-template
                            message.success({content: '修改完成!', duration: 2});

                            if (isModalContained()) {
                                handleClose(null);
                            } else {
                                //_refDoLoadPartialData.current({alwaysLoad: true});
                            }

                            viewModel?.OnDid?.(payload);
                            //state.parentState?._refDoLoadPartialData?.current({alwaysLoad: true});

                            // Static.NavAwayToAppointment(navigate, {
                            //     s: '手機號碼',
                            //     q: payload.Item_飼主.手機,
                            // })(null);

                            // let carryData = _._u.update(payload,
                            //     {
                            //         $merge: {
                            //             Tokens: payload?.Tokens,
                            //         },
                            //         Item_飼主: {
                            //             $set: payload?.Item_飼主,
                            //         }
                            //     }
                            // );
                            // carryData.Item_飼主 = p1;
                            // if (onDone) {
                            //     const ret = onDone(carryData);
                            //     if (ret === 'demo') {
                            //         createModalPet(null, {
                            //             carryData,
                            //         })
                            //     }
                            // } else {
                            //     alert('onDone not defined');
                            // }

                            //handleClose(null)

                        } else {
                            // AUTH USER EXPIRED
                            if (error.code == 2) {
                                //alert('帳號被登出，請重新登入');
                                //window.location.href = '/login';
                                //this.props.history.push('/login')
                                await sessionManager.setLogout();
                                pageDataManager.clear();
                                pageDataManager.reloadAsync({alwaysLoad: true});
                                alert('時間逾時已被登出');
                                return;
                            }

                            _._debug.error(error);
                            alert(error.message);
                        }
                    }, async (err) => {
                        hide();

                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);
                        _._debug.warn(err);
                        alert('網路連線有問題');
                    }
                );
                return lastValueFrom(
                    post$
                );
            }
            //#endregion
        }

        const handle_deleteRecord = (e: any, v: any) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }

            const p1 = v.測量範本No;
            if (!p1) {
                alert('p1');
                _._debug.error('p1');
            }

            state.List_測量 = state.List_測量.filter(r => {
                return p1 !== r.測量範本No;
            });
            forceRender();
        }

        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            if (!values.username) {
                errors.username = '↑ 欄位為必填';
            } else {
            }

            if (!values.password) {
                errors.password = '↑ 欄位為必填';
            } else {
            }

            return errors;
        };

        //#region :Popup :createModalDealwithItem
        const createModalDealwithItem_refModal = useRef<any>(null);
        const createModalDealwithItem = (e: any, tasks?: string[]): void => {
            if (e) {
                e.preventDefault();
            }
            // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
            //
            // if (!PassPageDataInitalized) {
            //     //empty
            // }

            const config = {
                // title: 'test',
                content: <Partial_Modal_DealwithItem
                    PrefixTarget={'Partial_Modal_DealwithItem'}
                    pageDataManager={pageDataManager}
                    sessionManager={sessionManager}
                    navigate={navigate}

                    viewModel={{
                        refModal: createModalDealwithItem_refModal,
                    }}
                />,
                okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
                className: 'myClass myModal',
                width: '90em',
                height: ' ',
                footer: null,
                //prefixCls: '',
            };

            createModalDealwithItem_refModal.current = Modal.info(config);
        }
        //#endregion

        //#region :Popup :createModalMeasuringTool
        const createModalMeasuringTool_refModal = useRef<any>(null);
        const createModalMeasuringTool = (e: any, tasks?: string[]): void => {
            if (e) {
                e.preventDefault();
            }
            // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
            //
            // if (!PassPageDataInitalized) {
            //     //empty
            // }

            const config = {
                // title: 'test',
                content: <Partial_MedicalRecord__Modal_MeasuringAdd
                    PrefixTarget={'Partial_MedicalRecord__Modal_MeasuringAdd'}
                    pageDataManager={pageDataManager}
                    sessionManager={sessionManager}
                    navigate={navigate}
                    parentState={state}

                    viewModel={{
                        refModal: createModalMeasuringTool_refModal,

                        病歷No: viewModel.病歷No,

                        ///*Add
                        OnDid: () => {
                            //@own state got changed, then
                            forceRender();
                            handleClose(null);
                        },
                    }}
                />,
                okButtonProps: {disabled: true, className: "modal-footer-hiden-button"},
                className: 'myClass myModal',
                width: '90em',
                height: ' ',
                footer: null,
                //prefixCls: '',
            };

            createModalMeasuringTool_refModal.current = Modal.info(config);
        }
        //#endregion

        const mockTags = ['注射', '手術', '預防'];

        const PrescribeColumns = [
            {
                dataIndex: 'time',
                title: '時間',
            },
            {
                dataIndex: 'tag',
                title: '分類',

                render: (text, record) => <Tag>{text}</Tag>,
            },
            {
                dataIndex: 'brand',
                title: '品牌'
            },
            {
                dataIndex: 'drugName',
                title: '藥名',
                width: '20%',
            },
            {
                dataIndex: 'quantity',
                title: '份量',
                render: () => {
                    return <div>
                        <InputNumber min={1} max={10} defaultValue={1}/>
                    </div>
                },
            },
            {
                dataIndex: 'usageAmount',
                title: '用量',
            },
            {
                dataIndex: 'process',
                title: '藥劑處置'
            },
            {
                dataIndex: 'orderInfo',
                title: '醫囑',
                render: (text, record) => <a type="link">醫囑</a>,
            },
            {
                dataIndex: 'management',
                title: '管理',
                render: (text, record) => {

                    return <div>
                        <Space>
                            <Tooltip placement="top" title="編輯">
                                <Button icon={<EditOutlined/>}/>
                            </Tooltip>
                            <Tooltip placement="top" title="刪除">
                                <Button icon={<CloseOutlined/>} onClick={(e) => handle_deleteRecord(e, {
                                    測量範本No: record.entity.測量範本No,
                                })}/>
                            </Tooltip>
                        </Space>
                    </div>
                },
            },
        ];

        const DealDate = [
            {
                time: '15:17',
                tag: mockTags[0],
                brand: 'Linking Park',
                drugName: 'Follow your heart',
                usageAmount: '2ml',
                process: '打斷腿就站起來',
            },
            {
                time: '09:17',
                tag: mockTags[2],
                brand: '輝瑞',
                drugName: '要你命3000',
                usageAmount: '0.5ml',
                process: '千萬要打',
            }
        ];

        const DealwithHistoryColumn = [
            {
                dataIndex: 'date',
                title: '日期',
            },

            {
                dataIndex: 'dealwithItem',
                title: '處理項目'
            },
            {
                dataIndex: 'dealwithDoctor',
                title: '開單醫師',
            },
            {
                dataIndex: 'view',
                title: '查看',
                render: (text, record) => <a type="link" onClick={createModalDealwithItem}>詳情</a>
            }
        ];

        const DealwithHistoryDate = [
            {
                date: '2021-07-21 15:17',
                dealwithItem: '3',
                dealwithDoctor: '林隆璇 醫師',
            },
            {
                date: '2021-04-13 15:45',
                dealwithItem: '9',
                dealwithDoctor: '袁明琦 醫師',
            },
            {
                date: '2020-09-21 15:12',
                dealwithItem: '1',
                dealwithDoctor: '林隆璇 醫師',
            }
        ];

        const measure_statistic_Columns = [
            {
                title: '測量類別',
                dataIndex: 'measure_type',
            },
            {
                title: '測量數據',
                dataIndex: 'measure_data',

            },
            {
                title: '測量時間',
                dataIndex: 'measure_time',
            },
            {
                dataIndex: 'view',
                title: '動作',
                render: (text, record) => {
                    return <div>
                        <Tooltip title='刪除' placement="right">
                            <Button icon={<CloseOutlined/>} type="text" onClick={(e)=>handle_deleteRecord(e, {
                                測量範本No: record.entity.測量範本No,
                            })}></Button>
                        </Tooltip>
                    </div>
                },
            }
        ];

        const measure_statistic_Data = [
            {
                key: '1',
                measure_type: '重量',
                measure_data: '12kg',
                measure_time: '2021-8-21 13:55',
            },
            {
                key: '2',
                measure_type: '體溫',
                measure_data: '42°C',
                measure_time: '2021-8-21 14:10',
            },
            {
                key: '3',
                measure_type: '血氧',
                measure_data: '75%',
                measure_time: '2021-8-21 14:15',
            },
        ]
        //#endregion

        if (!PassPrefixTargetLoaded) {
            if (RegionInitialLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <div>Loading.</div>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        if (loadingManager.getIsLoading()) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <div className="d3">
                        <Spin indicator={
                            <LoadingOutlined style={{fontSize: 24}} spin/>
                        }/>
                    </div>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        return (
            <div className="model_wrapper">
                <div className="modal_title">
                    <div className="title_info">
                        <h2>測量管理</h2>
                    </div>
                    <div className="info_bar"></div>
                    <div className="control_bar">
                        <Tooltip placement="top" title={'關閉'}>
                            <Button type="text" icon={<CloseOutlined/>} onClick={handleClose}/>
                        </Tooltip>
                    </div>
                </div>
                <div className="modal_content">
                    <div className="content_box cd_modal">
                        <div className="topbar_plug">
                            <div className="title">測量項目</div>
                            <div className="func">
                                <Button icon={<PlusOutlined/>} type="primary" ghost
                                        onClick={createModalMeasuringTool}>新增測量項目</Button>
                            </div>
                        </div>
                        {/* <Table
                            columns={DealwithHistoryColumn}
                            dataSource={DealwithHistoryDate}
                        /> */}
                        <Table

                            columns={measure_statistic_Columns}
                            dataSource={DataSource_測量}

                            locale={{
                                emptyText: <Empty description={
                                    <span>沒有搜尋到相關資料～</span>
                                }/>
                            }}
                        />
                    </div>
                </div>
                <div className="modal_footer">
                    <div className="form_footer">
                        <Button onClick={handleClose}>取消</Button>
                        <Button type="primary" htmlType="submit" size="large" onClick={(e) => {
                            handleSubmit_saveRecord(e, {
                                病歷No: viewModel.病歷No,
                                List_測量: state.List_測量,
                                掛號No: viewModel.掛號No,
                            })
                        }}
                        >送出</Button>
                    </div>
                </div>
            </div>
        );
    };

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_MedicalRecord__Modal_Measuring.displayName = 'Partial_MedicalRecord__Modal_Measuring';
