import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import {
    Form as NForm,
    Popover,
    Button,
    Modal,
    message,
    Upload,
    Input,
    Space,
    Select,
    Radio,
    DatePicker,
    InputNumber,
    TimePicker, Spin
} from 'antd';

import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import _ from "../_";

import {Link} from 'react-router-dom';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import {PageDataManager} from "../hooks/UsePageDataManager";

import {Field, Form} from "react-final-form";
import {SessionManager} from "../hooks/UseSessionManager";
import {NavigateFunction} from "react-router";

import {LoadingOutlined, LockOutlined, PlusOutlined, RedoOutlined, UserOutlined} from "@ant-design/icons";
import {lastValueFrom} from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_Appointment__Modal_Preview from "./Partial_Appointment__Modal_Preview";
import update from "immutability-helper";
import UseCompState from "../hooks/UseCompState";

var moment = require('moment');
const {Option} = Select;
const dateFormat = 'YYYY/MM/DD';
const format = 'HH:mm';
const {TextArea} = Input;

const key = 'updatable';
const openMessage = () => {
    message.loading({content: '建立中...', key});
    setTimeout(() => {
        message.success({content: '預約建立成功!', key, duration: 2});
    }, 1000);
};

type ViewModelProps = {
    refModal: any,

    寵物_飼主__關係No?: any,
    掛號No?: any,
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,
    parentState?: any,

    viewModel: ViewModelProps,
};
export default function Partial_Appointment__Pane_AddReservation(props: Props) {
    const PrefixTarget: string = props.PrefixTarget || 'Partial_Appointment__Pane_AddReservation';
    const pageDataManager: PageDataManager = props.pageDataManager;
    const sessionManager: SessionManager = props.sessionManager;
    const navigate: NavigateFunction = props.navigate;

    const viewModel = props.viewModel;
    const isNew = true;

    const layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 16},
    };

    const rs_layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 16},
    };

    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion

    //#region Loading Control
    const loadingManager = UseLoadingManager({shouldReRenderOnLoading: false});
    //#endregion

    //#region :State CompState
    const state = UseCompState({parentState: props.parentState});

    if (!state.hasInit) {
        state.hasInit = true;
    }
    //#endregion

    //#region Boot
    let _refDoLoadPartialData: any = useRef<{ alwaysLoad?: boolean }>(null);
    useEffect(() => {
        const _loadPartialData = async (): Promise<void> => {
            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">讀取中...</div>,
                key: 'loading',
                duration: 0
            });

            // VALIDATE INPUT
            const p1: any = viewModel.寵物_飼主__關係No ?? null;
            // if (!p1) {
            //     return; //ERROR INPUTS
            // }

            //const p2: any = viewModel.掛號No ?? null;
            // if (!p1) {
            //     return; //ERROR INPUTS
            // }

            // WAITING SCREEN
            loadingManager.changeLoadingCount(+1);
            //StaticEvent.LoadingEvent.next(true);

            // POST
            let postData = {
                p1,
                //p2,
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
            };
            const postUrl = ApiService.urlMapping('~/pet_api/1/partial_appointment/pane_addreservation');
            const post$ = Static.apiService.post(postUrl, postData);
            post$.subscribe(async (res: any) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        pageDataManager.emptyZone(PrefixTarget);
                        let changeShard = {
                            [PrefixTarget]: {
                                ...payload,
                            }
                        }

                        pageDataManager.merge(changeShard);
                        pageDataManager.apply();
                        forceRender();
                    } else {
                        // AUTH USER EXPIRED T:1
                        if (error.code == 2) {
                            await sessionManager.setLogout();
                            Static.NavAwayToLogin(pageDataManager, navigate)(null);
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
            );
            return lastValueFrom(
                post$
            );
        }
        const doLoadPartialData = async ({alwaysLoad = false}): Promise<void> => {
            if (!pageDataManager.refPageData.current.Root) {
                return;
            }

            // do not dynamically load data if condition fits.
            if (!alwaysLoad) {
                if (pageDataManager.isZoneExist(PrefixTarget)) {
                    return;
                }
            }

            await _loadPartialData();
            // if (viewModel.掛號No != null) {
            // }
        }

        _refDoLoadPartialData.current = doLoadPartialData;
        _refDoLoadPartialData.current({});
    }, [pageDataManager.get(PrefixTarget)]);
    //#endregion

    //#region Set Body
    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
        }

        if (viewModel.refModal || viewModel.refModal.current) {
            viewModel.refModal.current.destroy();
        }
    };

    const Body = () => {
        const RegionEmptyShow = false;
        const RegionLoadingShow = true;
        let PassPageDataInitalized = pageDataManager.refPageData.current != null;
        const PassPrefixTargetLoaded = pageDataManager.get(`${PrefixTarget}`) !== null;

        if (!PassPrefixTargetLoaded) {
            if (RegionLoadingShow) {
                return <React.Fragment>
                    <Spin indicator={
                        <LoadingOutlined style={{fontSize: 24}} spin/>
                    }/>
                </React.Fragment>;
            } else {
                return null;
            }
        }

        const Item_寵物 = pageDataManager.get(`${PrefixTarget}.Item_寵物`);
        const List_醫生 = pageDataManager.get(`${PrefixTarget}.List_醫生`);
        //const Item_掛號 = pageDataManager.get(`${PrefixTarget}.Item_掛號`) ?? {};

        let docOptions: any = [];
        {
            if (List_醫生) {
                docOptions = List_醫生.map((r) => {
                    return {
                        key: r.醫生No,
                        value: r.醫生No,
                        label: `${r.名稱} 醫師`,
                    }
                });

                // docOptions.unshift({
                //     key: '0',
                //     value: '',
                //     label: '請選擇',
                // })

                // docOptions.unshift({
                //     key: '1',
                //     value: '',
                //     label: '不指定',
                // })

                // docOptions.unshift({
                //     key: '0',
                //     value: '請選擇',
                //     label: '請選擇',
                // })
            }
        }

        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //

            // if (!values.designatedphysician) {
            //     errors.designatedphysician = '↑ 請選擇指定醫師';
            // } else {
            // }
            //
            // if (!values.reservationdate) {
            //     errors.reservationdate = '↑ 請選擇日期';
            // } else {
            // }
            //
            // if (!values.reservationtime) {
            //     errors.reservationtime = '↑ 請選擇時間';
            // } else {
            // }

            return errors;
        };

        const handleSubmit_addreservation = (e: any, v: any) => {
            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">驗證中...</div>,
                key: 'loading',
                duration: 0
            });

            //#region Handle SaveUserInfo
            {
                // VALIDATE INPUT
                //const id : any = this.props.match.params.id;

                const p1 = v;
                if (!p1) {
                    alert(p1);
                    _._debug.error(p1);
                    return; //ERROR INPUTS
                }


                // let pictures = [] as any;
                // state.files.map((v, i) => {
                //     console.log(v)
                //     pictures.push(v.id);
                // });
                // const p2 = pictures.join(',');
                // if (!p2) {
                //     _.debug.error(p1);
                //     return; //ERROR INPUTS
                // }

                // WAITING SCREEN
                loadingManager.changeLoadingCount(+1);
                //StaticEvent.LoadingEvent.next(true);

                // POST
                let postData = {
                    p1,
                    timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
                };
                const postUrl = ApiService.urlMapping('~/pet_api/1/_partial_appointment/pane_addreservation');
                const post$ = Static.apiService.post(postUrl, postData);
                post$.subscribe(async (res: any) => {
                        hide();

                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);

                        let data = res.data;
                        let error = data.error || null;
                        let result = error == null;

                        if (result) { // SUCEESS
                            let payload = data.payload;
                            _._debug.warn(payload);


                            let hide = message.loading({
                                type: 'loading',
                                className: 'myMessage',
                                content: <div className="myMessageBody">新增完成</div>,
                                key: 'loading',
                                duration: 5
                            });

                            handleClose(null);
                            hide();

                            message.success({content: '預約建立成功!', key, duration: 2});
                        } else {
                            // AUTH USER EXPIRED
                            if (error.code == 2) {
                                //alert('帳號被登出，請重新登入');
                                //window.location.href = '/login';
                                //this.props.history.push('/login')
                                await sessionManager.setLogout();
                                pageDataManager.clear();
                                pageDataManager.reloadAsync({alwaysLoad: true});
                                alert('時間逾時已被登出');
                                return;
                            }

                            _._debug.error(error);
                            alert(error.message);
                        }
                    }, async (err) => {
                        hide();

                        // CLOSE WAITING SCREEN
                        loadingManager.changeLoadingCount(-1);
                        //StaticEvent.LoadingEvent.next(false);
                        _._debug.warn(err);
                        alert('網路連線有問題');
                    }
                );
                return lastValueFrom(
                    post$
                );
            }
            //#endregion
        }

        return (
            <Form
                onSubmit={(v) => handleSubmit_addreservation(null, v)}
                initialValues={{
                    飼主No: Item_寵物?._飼主?.飼主No,
                    寵物No: Item_寵物?.寵物No,

                    //...Item_掛號,
                    診別: '非初診',
                    //預約時間: moment(),
                }}
                validate={handleValidate}
                render={({
                             submitError,
                             handleSubmit,
                             submitting,
                             pristine,
                             valid,
                             error,
                             values
                         }) => {

                    return <React.Fragment>

                        <div className="rs_title">
                            <div className="name">{Item_寵物.寵物名稱}・{Item_寵物.種類}</div>
                        </div>
                        <div className="subtitle">
                            <div className="name">{Item_寵物._飼主.主人姓名}</div>
                            <div className="phone"><a href={'tel:' + Item_寵物._飼主.手機}>{Item_寵物._飼主.手機}</a></div>
                        </div>

                        <div className="col_group">
                            <div className="col flexw">
                                <form
                                    onSubmit={handleSubmit}
                                    {...layout}>
                                    <Field parse={_.bs.identity} name="醫生No_指定醫師" validate={_.bs.Form.required}>
                                        {
                                            ({input, meta}) => (
                                                <React.Fragment>
                                                    <NForm.Item {...input} {...rs_layout}
                                                                name="醫生No_指定醫師"
                                                                label="指定醫師:"
                                                                rules={[{required: true}]}
                                                    >
                                                        <Select {...input} placeholder="請選擇指定醫師"
                                                                defaultValue={""}>
                                                            {docOptions.map((it => {
                                                                return <Option
                                                                    value={it.value}>{it.label}</Option>
                                                            }))}
                                                        </Select>
                                                        {meta.touched && meta.error &&
                                                        <div className="error">
                                                            {meta.error}
                                                        </div>}
                                                    </NForm.Item>
                                                </React.Fragment>
                                            )
                                        }
                                    </Field>
                                    <Field parse={_.bs.identity} name="預約日期" validate={_.bs.Form.required}>
                                        {
                                            ({input, meta}) => {
                                                const datepickerProps: any = {};
                                                if (input.value) {
                                                    //props.defaultValue =moment.utc(input.value).format('YYYY-MM-DD');
                                                    datepickerProps.defaultValue = moment(input.value);
                                                } else {
                                                    datepickerProps.defaultValue = moment();
                                                    input.onChange(datepickerProps.defaultValue);
                                                }
                                                datepickerProps.onChange = input.onChange;

                                                return <React.Fragment>
                                                    <NForm.Item {...input} {...rs_layout}
                                                                name="reservationdate"
                                                                label="預約日期:"
                                                                rules={[{required: true, message: '請選擇日期'}]}
                                                    >

                                                        <DatePicker placeholder="選擇日期"
                                                                    format={dateFormat} {...datepickerProps}/>
                                                        {meta.touched && meta.error &&
                                                        <div className="error">
                                                            {meta.error}
                                                        </div>}
                                                    </NForm.Item>
                                                </React.Fragment>;
                                            }
                                        }
                                    </Field>
                                    <Field parse={_.bs.identity} name="預約時間" validate={_.bs.Form.required}>
                                        {
                                            ({input, meta}) => {
                                                let datepickerProps: any = {};
                                                if (input.value) {
                                                    //props.defaultValue =moment.utc(input.value).format('YYYY-MM-DD');
                                                    datepickerProps.defaultValue = moment(input.value);
                                                } else {
                                                    datepickerProps.defaultValue = moment();
                                                    input.onChange(datepickerProps.defaultValue);
                                                }
                                                datepickerProps.onChange = (value) => {
                                                    input.onChange(value);
                                                }

                                                return <React.Fragment>
                                                    <NForm.Item {...datepickerProps} {...rs_layout}
                                                                name="時間"
                                                                label="選擇時間:"
                                                                rules={[{required: true, message: '請選擇時間'}]}
                                                    >

                                                        <TimePicker placeholder="選擇時間"
                                                                    format={format} minuteStep={5}
                                                                    {...datepickerProps}
                                                        />
                                                        {meta.touched && meta.error &&
                                                        <div className="error">
                                                            {meta.error}
                                                        </div>}
                                                    </NForm.Item>
                                                </React.Fragment>;
                                            }
                                        }
                                    </Field>
                                    <Field parse={_.bs.identity} name="診別" validate={_.bs.Form.required}>
                                        {
                                            ({input, meta}) => {
                                                return <React.Fragment>
                                                    <NForm.Item {...rs_layout}
                                                                name="diagnosis"
                                                                label="診別:"
                                                                rules={[{required: true, message: '請選擇診別'}]}
                                                    >

                                                        <Select {...input} placeholder="請選擇診別" defaultValue="非初診">
                                                            <Option value="初診">初診</Option>
                                                            <Option value="非初診">非初診</Option>
                                                        </Select>
                                                        {meta.touched && meta.error &&
                                                        <div className="error">
                                                            {meta.error}
                                                        </div>}
                                                    </NForm.Item>
                                                </React.Fragment>
                                            }
                                        }
                                    </Field>
                                    <Field parse={_.bs.identity} name="主要處置">
                                        {
                                            ({input, meta}) => (
                                                <React.Fragment>
                                                    <NForm.Item {...input} {...rs_layout}
                                                                name="dealwith"
                                                                label="主要處置:"
                                                    >
                                                        <Select {...input} placeholder="請選擇主要處置" defaultValue='不指定'>
                                                            <Option value="不指定">不指定</Option>
                                                            <Option value="手術">手術</Option>
                                                            <Option value="預防">預防</Option>
                                                            <Option value="急救">急救</Option>
                                                        </Select>
                                                        {meta.touched && meta.error &&
                                                        <div className="error">
                                                            {meta.error}
                                                        </div>}
                                                    </NForm.Item>
                                                </React.Fragment>
                                            )
                                        }
                                    </Field>
                                    <Field parse={_.bs.identity} name="看診原因">
                                        {
                                            ({input, meta}) => (
                                                <React.Fragment>
                                                    <NForm.Item {...input} {...rs_layout}
                                                                name="reason"
                                                                label="看診原因:"
                                                    >
                                                        <Input {...input} placeholder="請輸入看診原因限7字"
                                                               allowClear max={7}/>
                                                        {meta.touched && meta.error &&
                                                        <div className="error">
                                                            {meta.error}
                                                        </div>}
                                                    </NForm.Item>
                                                </React.Fragment>
                                            )
                                        }
                                    </Field>

                                    <Field parse={_.bs.identity} name="看診描述">
                                        {
                                            ({input, meta}) => (
                                                <React.Fragment>
                                                    <NForm.Item {...input} {...rs_layout}
                                                                name="description"
                                                                label="看診原因:"
                                                    >
                                                        <TextArea {...input} rows={1}
                                                                  placeholder="請輸入描述" {...input}/>
                                                        {meta.touched && meta.error &&
                                                        <div className="error">
                                                            {meta.error}
                                                        </div>}
                                                    </NForm.Item>
                                                </React.Fragment>
                                            )
                                        }
                                    </Field>
                                </form>
                            </div>
                            <div className="rs_footer">
                                <Space>
                                    <Button onClick={handleClose}>關閉</Button>
                                    <Button type="primary" disabled={submitting}
                                            onClick={handleSubmit}>建立</Button>
                                </Space>
                            </div>
                        </div>

                    </React.Fragment>;
                }}
            />
        );
    };
    //#endregion

    //#region Render
    return <React.Fragment>
        {Body()}
    </React.Fragment>;
    //#endregion
}
Partial_Appointment__Pane_AddReservation.displayName = 'Partial_Appointment__Pane_AddReservation';
