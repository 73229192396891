import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Alert,Checkbox, Result, message} from 'antd';
import './Pg_ForgetPassword.scss';
import { useApp } from "../overmind";
import { UserInfo } from "../global";
import {Static} from "../_/Static";
import {ApiService} from "../_/ApiService";
import _ from "../_";
import { EyeInvisibleOutlined, EyeTwoTone,MailOutlined,ArrowLeftOutlined } from '@ant-design/icons';


type passWordReplace= {
  oldpw: string;
  newpw: string;
}

//
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 25 },
};

const tailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 25 },
};
//

const error = () => {
  message.error('此手機或E-mail尚未註冊，請再次輸入，或聯繫客服');
};

const Demo = () => {
  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };


  return (
    <Form
      {...layout}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        name="email"
        rules={[{ required: true, message: '請輸入正確E-mail' }]}
      >
        <Input size="large" prefix={<MailOutlined />} placeholder="E-mail" allowClear onClick={error}/>
      </Form.Item>

      <Form.Item {...tailLayout}>
        <div className="pt_r">
          <Button type="primary" htmlType="submit" size="large" loading >
            取得驗證碼
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};



type Props = {
  PrefixTarget?: string;
  RoutePathname?: string;
  MenuTemplateId?: string;
};


export default function Pg_Login(props: Props){

  const { state , actions } = useApp();
  const [ register, setRegister] = useState(false);

  const onFinish = (values: UserInfo) => {
    // if values != null or empty
    // 1. fetch to DB to check if this account exist
    // 2. will have return data => CHECKED: TRUE ,FIRSTTIME: FALSE
    // 3.
    // if (CHECKED){
      localStorage.setItem('lguser', JSON.stringify(values));
    //   actions.setSession(values);
    //   console.log('Success:', values);
    //   console.log('Success:', state.userSession);
    // }
    //4.
    // else{
    //   setRegister(true);
    // }
    setRegister(true);
    setLogin(values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  //page-changepw
  const [renewPW, setReNewPW] = useState(false);
  const [login, setLogin] = useState<UserInfo>();

  const verifyPw = (values: passWordReplace) => {
    // post { username, oldpw, newpw} => change pw
    // retrun true: false;
    if(login !== undefined){

      let username = login.username;

      let updateUser: UserInfo  = {
        username: username,
        password: values.newpw
      }

      console.log(values);

      if(true){
        setReNewPW(true);
        setLogin(updateUser);
        localStorage.setItem('lguser', JSON.stringify(updateUser));
      }
      else{}
    }

  }

  const error = () => {
    message.error('This is an error message');
  };


  return (
    <div className="page-sys">
      <div className="wrapper">
        <div className="contentbox">
          <div className="arrow">
          <Link to="../login" >
            <Button icon={<ArrowLeftOutlined />} size="large" ghost  type="primary" ></Button></Link>
          </div>
          <div className="content">
            <h3>重設密碼</h3>
            <div className="infop">
              請輸入您於系統設置的E-mail
            </div>
            <Demo />
          </div>
        </div>
      </div>
    </div>
    
  );
}

Pg_Login.displayName = 'Pg_Login';
