import React, { useCallback, useEffect, useRef, useState } from 'react';

import '../css/reset.css';
import './_Index.scss';
//import styles from './_Index.style';

import { Form as NForm, Popover, Button, Modal, InputNumber, message, Tag, Table, Upload, Input, Select, Space, Tabs, Tooltip, Steps } from 'antd';

import { ApiService } from "../_/ApiService";
import { Static } from "../_/Static";
import { StaticEvent } from "../_/StaticEvent";
import _ from "../_";

import { Link } from 'react-router-dom';


import {
    useParams,
    useNavigate
} from "react-router-dom";
import { PageDataManager } from "../hooks/UsePageDataManager";

import { Field, Form } from "react-final-form";
import { SessionManager } from "../hooks/UseSessionManager";
import { NavigateFunction } from "react-router";
import { LoadingOutlined, LockOutlined, EditOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";
import { lastValueFrom } from "rxjs";
import UseLoadingManager from "../hooks/UseLoadingManager";

import Partial_Appointment__Pane_AddOwner from "./Partial_Appointment__Pane_AddOwner";

import Partial_MedicalRecord__Modal_PrescribeAdd from "./Partial_MedicalRecord__Modal_PrescribeAdd";
import Partial_MedicalRecord__Pane_TransferToHospital from "./Partial_MedicalRecord__Pane_TransferToHospital";


var moment = require('moment');
const { Option } = Select;
const { Search } = Input;
const onSearch = value => console.log(value);
const { TabPane } = Tabs;
const { Step } = Steps;

type ViewModelProps = {
    refModal: any,

    病歷No: any,
    掛號No: any,

    OnDid?: any;
};
type Props = {
    PrefixTarget?: string;
    pageDataManager: PageDataManager
    sessionManager: SessionManager,
    navigate: NavigateFunction,

    viewModel: ViewModelProps,
};
export default function Partial_MedicalRecord__Modal_TransferToHospital(props: Props) {
    let PrefixTarget: string = props.PrefixTarget || 'Partial_MedicalRecord__Modal_TransferToHospital';
    let pageDataManager: PageDataManager = props.pageDataManager;
    let sessionManager: SessionManager = props.sessionManager;
    let navigate: NavigateFunction = props.navigate;

    //const [viewModel, setViewModel] = useState<ViewModelProps>(props.viewModel);
    const viewModel = props.viewModel;


    //#region ForceRender Control
    const [, setForceRender] = useState(false);
    const forceRender = () => {
        setForceRender(s => !s);
    };
    //#endregion


    const loadingManager = UseLoadingManager({ shouldReRenderOnLoading: false });

    //#region Boot
    const _doLoadPartialData = async (): Promise<void> => {
        if (!pageDataManager.refPageData.current.Root) {
            return;
        }

        /// do not dynamically load data if condition fits.
        if (pageDataManager.isZoneExist(PrefixTarget)) {
            return;
        }
        //await _loadPageData();
    }
    useEffect(() => {
        _doLoadPartialData();
    }, []);
    //#endregion

    //#region Set Body
    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (viewModel.refModal?.current) {
            viewModel.refModal.current.destroy();
        }
    };
    const Body = () => {
        const handleValidate = values => {
            const errors: any = {};

            // if (!values.網址) {
            //     errors.網址 = '↑ 欄位為必填';
            // } else {
            //     function isUrlValid(userInput) {
            //         var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            //         if(res == null)
            //             return false;
            //         else
            //             return true;
            //     }
            //
            //     if (!isUrlValid(values.網址)) {
            //         errors.網址 = '↑ 此連結無效，請輸入正確連結';
            //     }
            // }
            //
            if (!values.username) {
                errors.username = '↑ 欄位為必填';
            } else {
            }

            if (!values.password) {
                errors.password = '↑ 欄位為必填';
            } else {
            }

            return errors;
        };

        const handleSubmit_clickLogin = (e: any, v: any) => {
            let hide = message.loading({
                type: 'loading',
                className: 'myMessage',
                content: <div className="myMessageBody">現在開始創立世界</div>,
                key: 'loading',
                duration: 0
            });

            //#region Handle SaveUserInfo
            {
                // VALIDATE INPUT
                //const id : any = this.props.match.params.id;
                const id = 1;
                if (!id) {
                    return; //ERROR INPUTS
                }

                // WAITING SCREEN
                loadingManager.changeLoadingCount(+1);
                //StaticEvent.LoadingEvent.next(true);

                // POST
                let postData = {
                    p1: v.p1,
                    p2: v.p2,
                    p3: v.p3,
                    p4: v.p4,
                    p5: v.p5,
                    timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
                };
                const postUrl = ApiService.urlMapping('~/h_api/1/pg_jumplogin/clicklogin');
                const post$ = Static.apiService.post(postUrl, postData);
                post$.subscribe(async (res: any) => {
                    hide();


                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);

                    let data = res.data;
                    let error = data.error || null;
                    let result = error == null;

                    if (result) { // SUCEESS
                        let payload = data.payload;
                        _._debug.warn(payload);

                        message.success({
                            type: 'success',
                            className: 'myMessage',
                            content: <div className="myMessageBody">歡迎來到，恐龍世界</div>,
                            key: '',
                            duration: 3
                        });

                        // message.success({
                        //     type: 'success',
                        //     className: 'myMessage',
                        //     content: <div className="myMessageBody">門就開了</div>,
                        //     key: '',
                        //     duration: 3
                        // });


                        await sessionManager.setLoginData(
                            payload.SessionId,
                            payload.AuthData
                        );

                        navigate({
                            pathname: '/wall/newest',
                            //search: "?sort=name",
                            //hash: "#the-hash",
                            //state: {panelSelection: "f1"}
                        });
                    } else {
                        // AUTH USER EXPIRED
                        if (error.code == 2) {
                            //alert('帳號被登出，請重新登入');
                            //window.location.href = '/login';
                            //this.props.history.push('/login')
                            await sessionManager.setLogout();
                            pageDataManager.clear();
                            pageDataManager.reloadAsync({alwaysLoad: true});
                            alert('時間逾時已被登出');
                            return;
                        }

                        _._debug.error(error);
                        alert(error.message);
                    }
                }, async (err) => {
                    hide();

                    // CLOSE WAITING SCREEN
                    loadingManager.changeLoadingCount(-1);
                    //StaticEvent.LoadingEvent.next(false);
                    _._debug.warn(err);
                    alert('網路連線有問題');
                }
                );
                return lastValueFrom(
                    post$
                );
            }
            //#endregion
        }

        //#region :Popup :createModalPrescribeTool
        const createModalPrescribeTool_refModal = useRef<any>(null);
        const createModalPrescribeTool = (e: any, tasks?: string[]): void => {
            if (e) {
                e.preventDefault();
            }
            // let PassPageDataInitalized = pageDataManager.refPageData.current != null;
            //
            // if (!PassPageDataInitalized) {
            //     //empty
            // }

            const config = {
                // title: 'test',
                content: <Partial_MedicalRecord__Modal_PrescribeAdd
                    PrefixTarget={'Partial_MedicalRecord__Modal_PrescribeAdd'}
                    pageDataManager={pageDataManager}
                    sessionManager={sessionManager}
                    navigate={navigate}

                    viewModel={{
                        refModal: createModalPrescribeTool_refModal,
                    }}
                />,
                okButtonProps: { disabled: true, className: "modal-footer-hiden-button" },
                className: 'myClass myModal',
                width: '80em',
                height: ' ',
                footer: null,
                //prefixCls: '',
            };

            createModalPrescribeTool_refModal.current = Modal.info(config);
        }
        //#endregion


        const mockTags = ['注射', '手術', '預防'];

        const ChargeColumns = [
            {
                dataIndex: 'chargetype',
                title: '收費分類',

                render: () => {
                    return <div>
                        <Select defaultValue="門診" >
                            <Option value="門診">門診</Option>
                            <Option value="售貨">門診</Option>
                            <Option value="美容">門診</Option>
                            <Option value="急診">急診</Option>
                        </Select>
                    </div>
                },
            },
            {
                dataIndex: 'chargeItem',
                title: '收費名稱',
                width:'25%',
            },
            {
                dataIndex: 'unitPrice',
                title: '單價',
                width:'20%',
                render: (number, record) => {
                    return <div>
                        $ <Input placeholder="請輸入單價"  defaultValue={number} /> X
                    </div>
                },
            },
            {
                dataIndex: 'quantity',
                title: '數量',

                render: (number, record) => {
                    return <div>
                       <InputNumber min={1} max={10} defaultValue={number}  />
                    </div>
                },
            },
            {
                dataIndex: 'subtotal',
                title: '小計',

            },
            {
                dataIndex: 'management',
                title: '管理',
                render: (text, record) => {

                    return <div>
                        <Space>
                            <Tooltip placement="top" title="刪除">
                                <Button icon={<CloseOutlined />} type="text" danger/>
                            </Tooltip>
                        </Space>
                    </div>
                },
            },
        ];

        const ChargeDate = [
            {
                chargeItem: 'ABC',
                unitPrice: 350,
                quantity: 1,
                subtotal: '$350',
            },
            {
                chargeItem: 'ABC',
                unitPrice: 350,
                quantity: 1,
                subtotal: '$350',
            },
            {
                chargeItem: 'ABC',
                unitPrice: 350,
                quantity: 1,
                subtotal: '$350',
            },
            {
                chargeItem: 'ABC',
                unitPrice: 350,
                quantity: 1,
                subtotal: '$350',
            },
            {
                chargeItem: 'ABC',
                unitPrice: 350,
                quantity: 1,
                subtotal: '$350',
            },
            {
                chargeItem: 'ABC',
                unitPrice: 350,
                quantity: 1,
                subtotal: '$350',
            },
            {
                chargeItem: 'ABC',
                unitPrice: 350,
                quantity: 1,
                subtotal: '$350',
            },
            {
                chargeItem: 'ABC',
                unitPrice: 350,
                quantity: 1,
                subtotal: '$350',
            },

        ];

        const PrescribeHistoryColumn = [
            {
                dataIndex: 'date',
                title: '日期',
            },

            {
                dataIndex: 'doctor',
                title: '開藥醫生'
            },
            {
                dataIndex: 'prescribeAmount',
                title: '開藥項目'
            },
            {
                dataIndex: 'stauts',
                title: '狀態',
            },
            {
                dataIndex: 'view',
                title: '查看',
                render: (text, record) => <a type="link" >查看</a>
            }
        ];

        const PrescribeHistoryDate = [
            {
                date: '2021-07-21 15:17',
                doctor: '林隆璇 醫師',
                prescribeAmount: '1',
                stauts: '已配藥',
            },
            {
                date: '2021-08-01 09:30',
                doctor: '王帶鋼 醫師',
                prescribeAmount: '3',
                stauts: '藥局退回',
            },
            {
                date: '2021-08-02 12:42',
                doctor: '游登傑 醫師',
                prescribeAmount: '12',
                stauts: '未配藥',
            },
            {
                date: '2021-08-02 12:42',
                doctor: '游登傑 醫師',
                prescribeAmount: '12',
                stauts: '未配藥',
            },
            {
                date: '2021-08-02 12:42',
                doctor: '游登傑 醫師',
                prescribeAmount: '12',
                stauts: '未配藥',
            },
            {
                date: '2021-08-02 12:42',
                doctor: '游登傑 醫師',
                prescribeAmount: '12',
                stauts: '未配藥',
            },
            {
                date: '2021-08-02 12:42',
                doctor: '游登傑 醫師',
                prescribeAmount: '12',
                stauts: '未配藥',
            },
            {
                date: '2021-08-02 12:42',
                doctor: '游登傑 醫師',
                prescribeAmount: '12',
                stauts: '未配藥',
            },
            {
                date: '2021-08-02 12:42',
                doctor: '游登傑 醫師',
                prescribeAmount: '12',
                stauts: '未配藥',
            }
        ];

        const key = 'updatable';
        const openMessage = () => {
            message.loading({ content: '正送往住院部確認中...', key });
            setTimeout(() => {
                message.success({ content: '住院部確認了你的申請!', key, duration: 2 });
            }, 1000);
        };

        const saveMessage = () => {
            message.loading({ content: '儲存中...', key });
            setTimeout(() => {
                message.success({ content: '儲存成功!', key, duration: 2 });
            }, 1000);
        };

        return (
            <div className="model_wrapper">
                <div className="modal_title">
                    <div className="title_info">
                        <h2>新增住院</h2>
                    </div>
                    <div className="info_bar"></div>
                    <div className="control_bar">
                        <Tooltip placement="top" title={'關閉'}>
                            <Button type="text" icon={<CloseOutlined/>} onClick={handleClose}/>
                        </Tooltip>
                    </div>
                </div>
                <div className="modal_content">
                    <div className="content_box">
                    <Partial_MedicalRecord__Pane_TransferToHospital
                            PrefixTarget={'Partial_MedicalRecord__Pane_TransferToHospital'}
                            pageDataManager={pageDataManager}
                            sessionManager={sessionManager}
                            navigate={navigate}

                            viewModel={{
                                refModal: viewModel.refModal,

                                病歷No: viewModel.病歷No,
                                掛號No: viewModel.掛號No,

                                OnDid: viewModel.OnDid,
                            }}
                        />
                    </div>
                </div>
                <div className="modal_footer">
                </div>
            </div>
        );
    };
    //#endregion

    //#region Render
    return <React.Fragment>
        <Body />
    </React.Fragment>;
    //#endregion
}
Partial_MedicalRecord__Modal_TransferToHospital.displayName = 'Partial_MedicalRecord__Modal_TransferToHospital';
